export const unwrapHtmlElement = (wrapper: Element): void => {
  // place childNodes in document fragment
  if (wrapper) {
    const docFrag = document.createDocumentFragment()
    while (wrapper.firstChild) {
      const child = wrapper.removeChild(wrapper.firstChild)
      docFrag.appendChild(child)
    }
    // replace wrapper with document fragment
    const parentNode = wrapper.parentNode
    if (!parentNode) return
    parentNode.replaceChild(docFrag, wrapper)
  }
}
