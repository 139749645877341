import { MessageState } from './message.types'
import * as msgTypes from './message.constants'
const initialState: MessageState = null

export default (state: MessageState = initialState, action: any): MessageState => {
  if (action.error)
    return {
      type: 'error',
      message: action.message ?? action.payload?.message ?? 'Entschuldigung! Es ist ein Fehler aufgetreten. Wir kümmern uns darum.',
    }
  if (action.message) return { type: 'success', message: action.message }
  if (action.type === msgTypes.SET_APP_MESSAGE) return action.payload
  if (action.type === msgTypes.CLEAR_APP_MESSAGE) return null

  return state
}
