import { styled } from '@material-ui/core/styles'
import { Box, Typography, InputBase, Theme } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { IIcon, IInputEl, IInputGroupContainer } from './TextInputGroup.types'
import { CustomVariant } from 'types'

const iconStyles = (theme: Theme) => ({
  color: theme.colorz.text.light,
  cursor: 'pointer',
  opacity: 0.66,
  transition: 'all .2s',
  height: 14,
  width: 14,
  '&:hover': {
    opacity: 1,
  },
})

export const IconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  position: 'absolute',
  right: 4,
})

export const InputWrapper = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
})

export const VisibilityIcon = styled(Visibility)(({ theme }: IIcon) => iconStyles(theme))
export const VisibilityOffIcon = styled(VisibilityOff)(({ theme }: IIcon) =>
  iconStyles(theme)
)

export const InputGroupContainer = styled(Box)(
  ({ theme, width }: IInputGroupContainer) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    width: width ? width : '100%',
  })
)

export const Label = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}))

const inputStyles = (theme: Theme, type: string, haserror?: number) => {
  if (haserror) return { borderColor: theme.colorz.status.error }
  switch (type) {
    case 'search':
      return {
        borderColor: 'transparent',
        background: 'linear-gradient(rgba(255, 255, 255, .05), rgba(255, 255, 255, .05))',
        color: '#fff',
        '&:focus': {
          borderColor: '#fff',
        },
        '&:hover': {
          background:
            'linear-gradient(rgba(255, 255, 255, .08), rgba(255, 255, 255, .08))',
        },
      }
    default:
      return {}
  }
}

const variantStyles = (
  theme: Theme,
  customvariant?: CustomVariant,
  haserror?: number
) => {
  if (haserror)
    return {
      borderColor: theme.colorz.status.error + ' !importat',
    }

  const defaultStyles = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colorz.secondary[100],
    '&:focus': {
      borderColor: theme.colorz.secondary.main,
    },
  }

  const thickStyles = {
    ...defaultStyles,
    borderWidth: 2,
  }

  const lightStyles = {
    ...defaultStyles,
    borderColor: theme.colorz.secondary[100],
  }

  switch (customvariant) {
    case 'thick':
      return thickStyles
    case 'normal':
      return defaultStyles
    case 'light':
      return lightStyles
    default:
      return defaultStyles
  }
}

export const InputEl = styled(InputBase)(
  ({ theme, type, customvariant, haserror, center }: IInputEl) => ({
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderStyle: 'solid',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    padding: theme.spacing(0, 2),
    color: theme.colorz.secondary.main,
    width: '100%',
    fontSize: 16,
    letterSpacing: 0.4,
    outline: 'none',
    '&::placeholder': {
      color: 'inherit',
      letterSpacing: 0.4,
      fontSize: 'inherit',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    ...variantStyles(theme, customvariant, haserror),
    ...inputStyles(theme, type, haserror),
    '& input': {
      textAlign: center ? 'center' : 'left',
    },
  })
)

export const LabelUrlText = styled(Typography)(({ theme }) => ({
  color: theme.colorz.grey.light,
  width: 'max-content',
  cursor: 'pointer',
  transition: 'all .3s',
  '&:hover': {
    color: theme.colorz.grey.dark,
  },
}))

export const ErrorMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  background: theme.colorz.status.error,
  height: 56,
  paddingLeft: 16,
}))
