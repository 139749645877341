import { TQuestionTypes } from 'types'

export const QUESTION_TYPE_TEXT_BOX = 'QUESTION_TYPE_TEXT_BOX'
export const QUESTION_TYPE_DATE = 'QUESTION_TYPE_DATE'
export const QUESTION_TYPE_NUMBER = 'QUESTION_TYPE_NUMBER'
export const QUESTION_TYPE_CHECKBOX = 'QUESTION_TYPE_CHECKBOX'
export const QUESTION_TYPE_RADIO_LINK = 'QUESTION_TYPE_RADIO_LINK'
export const QUESTION_TYPE_RADIO_REPLACEMENT = 'QUESTION_TYPE_RADIO_REPLACEMENT'

interface IQuestionTypes {
  [key: string]: TQuestionTypes
}

export const questionTypesMap: IQuestionTypes = {
  TEXT_BOX: QUESTION_TYPE_TEXT_BOX,
  CHECKBOX: QUESTION_TYPE_CHECKBOX,
  RADIO_LINK: QUESTION_TYPE_RADIO_LINK,
  RADIO_REPLACEMENT: QUESTION_TYPE_RADIO_REPLACEMENT,
  DATE: QUESTION_TYPE_DATE,
  NUMBER: QUESTION_TYPE_NUMBER,
}

export default questionTypesMap
