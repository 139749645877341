import React from 'react'
import EmailSettings from 'pages/Authenticated/AccountSettings/views/EmailSettings/EmailSettings.component'
import { useUser } from 'queries/user/useUser'

const EmailSettingsPage: React.FC = () => {
  const user = useUser()
  return <EmailSettings email={user?.email!} />
}

export default EmailSettingsPage
