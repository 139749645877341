import React, { useEffect } from 'react'
import { Loader } from 'components/common'
import { useLogout } from 'queries/auth/useLogout'

const LogoutPage: React.FC = () => {
  const logout = useLogout()

  useEffect(() => {
    logout()
  }, [logout])

  return <Loader />
}

export default LogoutPage
