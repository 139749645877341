import { StepProps } from './Steps.types'
import React from 'react'

import { StepContainer, CheckIcon } from './Steps.styles'

const Step: React.FC<StepProps> = ({ step, label, currentStep }) => {
  const labelText = `${step}. ${label}`
  const isCompleted = currentStep > step
  return (
    <StepContainer id={step.toString()} isactive={currentStep === step ? 1 : 0}>
      {!isCompleted ? labelText : <CheckIcon />}
    </StepContainer>
  )
}

export default Step
