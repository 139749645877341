import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import LinearProgressComponent from '@material-ui/core/LinearProgress'
import { status, grey } from 'config/themes/colors'
import { ValidationProps, TextElProps } from './PasswordStrengthMeter.types'

export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  backgroundColor: '#fff',
  border: '1px solid ' + theme.colorz.secondary[200],
  borderBottom: 'none',
  position: 'absolute',
  top: '-91%',
  width: '100%',
  zIndex: 10,
}))

export const Content = styled(Box)({
  width: 276,
})

export const getColorByPasswordStrength = ({
  error,
  strength,
}: ValidationProps): string => {
  if (error) return status.error
  if (strength > 25) return status.success

  return grey.main
}

export const LinearProgress = styled(LinearProgressComponent)(({ theme }) => ({
  height: 9,
  border: '1px solid ' + theme.colorz.brand.main,
  opacity: 0.66,
  width: '95%',
}))

export const CheckContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export const Text = styled(Typography)((props: TextElProps) => ({
  fontSize: 14,
  letterSpacing: 0.2,
  color: getColorByPasswordStrength(props),
}))

export const Status = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: 0.2,
  margin: '10px 0',
})
