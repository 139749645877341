import React, { useState, Fragment } from 'react'
import { IRegisterUserRequestPayload } from 'api/auth.api'
import PasswordStrengthMeter from './PasswordStrengthMeter/PasswordStrengthMeter'
import {
  CustomButton,
  TextWithUrl,
  TextInputGroup,
  CheckboxGroup,
} from 'components/common'
import {
  Form,
  Heading,
  PasswordContainer,
  CheckboxContainer,
  AcceptUrl,
} from './RegisterForm.styles'
import { IRegisterFormErrorState } from './RegisterForm.container'

const CbLabel = (
  <Fragment>
    Ich akzeptiere die
    <AcceptUrl onClick={() => window.open('https://casus.ch/terms')}>
      Nutzungsbedingungen*
    </AcceptUrl>
  </Fragment>
)

interface Props {
  isLoading: boolean
  termsChecked: boolean
  values: IRegisterUserRequestPayload
  errors: IRegisterFormErrorState
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  handleSubmit: React.FormEventHandler<HTMLFormElement>
  setTermsChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const RegisterForm: React.FC<Props> = ({
  isLoading,
  termsChecked,
  values,
  errors,
  handleChange,
  handleSubmit,
  setTermsChecked,
}) => {
  const [strVisible, setStrVisibility] = useState(false)
  const { email, password } = values
  return (
    <Form onSubmit={handleSubmit}>
      <Heading variant="h3">Registrierung</Heading>
      <TextInputGroup
        type="email"
        label="E-Mail-Adresse"
        name="email"
        value={email}
        placeholder="z.B. paul@mueller.ch"
        onChange={handleChange}
        error={errors.email}
        required
      />

      <PasswordContainer>
        <TextInputGroup
          type="password"
          label="Passwort"
          name="password"
          value={password}
          onChange={handleChange}
          onFocus={() => setStrVisibility(true)}
          onBlur={() => setStrVisibility(false)}
          error={errors.password}
          required
        />
        {strVisible || errors.password ? (
          <PasswordStrengthMeter password={password} error={errors.password} />
        ) : null}
      </PasswordContainer>
      <CheckboxContainer>
        <CheckboxGroup
          label={CbLabel}
          checked={termsChecked}
          name="terms"
          handleChange={() => setTermsChecked((prev) => !prev)}
          error={termsChecked ? false : errors.terms}
        />
        {/* <CheckboxGroup
          label='Ich möchte über Neuigkeiten aus der CASUS-Welt per Mail auf dem Laufenden bleiben.'
          checked={subscribed}
          handleChange={() => setSubscribed(!subscribed)}
          multiline
        /> */}
      </CheckboxContainer>
      <CustomButton
        type="submit"
        isLoading={isLoading}
        btntype="primary"
        disabled={isLoading}
        width="100%"
      >
        {!isLoading ? 'Konto erstellen' : 'Einen Moment bitte...'}
      </CustomButton>
      <TextWithUrl
        text="Haben Sie bereits ein Konto? "
        url="/auth/login"
        urlText="Login"
      />
    </Form>
  )
}

export default RegisterForm
