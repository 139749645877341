import React from 'react'

const classes = {
  wrapper: 'Wizard-QuestionGeneration-wrapper',
}

export const QuestionGeneration = React.memo(props => {
  const { shift } = props
  return <section data-shift={shift} className={classes.wrapper}></section>
})

QuestionGeneration.displayName = 'Wizard-QuestionGeneration'

export default QuestionGeneration
