import { styled } from '@material-ui/core'
import React from 'react'
import { TD, Text } from './Table.styles'
import * as assets from './assets'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  transition: 'all .2s',
  flexShrink: 0,
  padding: theme.spacing(5, 6, 5, 7),
  '&:nth-child(odd)': {
    backgroundColor: '#fff',
  },
  '&:nth-child(even)': {
    backgroundColor: theme.colorz.secondary[50],
  },
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}))

const TableItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  '&:not(:last-child)': {
    marginBottom: 10,
  },
}))

const Img = styled('img')({ margin: '0 auto' })

type TAvailbility = boolean | string

export interface ISubscriptionPlanTableItem {
  text: string
  basic: TAvailbility
  team: TAvailbility
  enterprise: TAvailbility
  startup: TAvailbility
}

const renderIcon = (val: TAvailbility) => {
  switch (val) {
    case 'soon':
      return (
        <Text
          variant='body2'
          style={{ color: '#9CA4B5', textAlign: 'center', margin: '0 auto' }}
        >
          Coming Soon
        </Text>
      )
    case true:
      return <Img src={assets.check} />
    case false:
      return <Img src={assets.no} />
    default:
      return (
        <Text
          variant='body1'
          colorz='main'
          style={{ textAlign: 'center', margin: '0 auto' }}
        >
          {val}
        </Text>
      )
  }
}

export const Item: React.FC<ISubscriptionPlanTableItem> = ({
  text,
  basic,
  team,
  startup,
  enterprise,
}) => {
  return (
    <TableItemContainer>
      <TD>
        <Text variant='body1'>{text}</Text>
      </TD>
      <TD>{renderIcon(startup)}</TD>
      <TD>{renderIcon(basic)}</TD>
      <TD>{renderIcon(team)}</TD>
      <TD>{renderIcon(enterprise)}</TD>
    </TableItemContainer>
  )
}

export interface ISubscriptionPlanTableSection {
  items: ISubscriptionPlanTableItem[]
  title: string
}

const TableRowSection: React.FC<ISubscriptionPlanTableSection> = ({ items, title }) => {
  return (
    <Container>
      <Text
        colorz='main'
        variant='h3'
        style={{ fontSize: 18, margin: '4px 0 20px 0', fontWeight: 700 }}
      >
        {title}
      </Text>
      <ContentWrapper>
        {items.map((item, i) => (
          <Item key={i} {...item} />
        ))}
      </ContentWrapper>
    </Container>
  )
}

export default TableRowSection
