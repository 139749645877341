import { Box, Popover, styled, Theme, BoxProps } from '@material-ui/core'
import { Icon } from 'components/common'

interface IBodyItemEl extends BoxProps {
  disabled?: boolean
  theme: Theme
}

export const MenuIcon = styled(Icon)({
  position: 'absolute',
  top: 4,
  right: 4,
  opacity: 0.9,
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },
})

export const Body = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 200,
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  border: `1px solid ${theme.colorz.secondary[100]}`,
}))

export const BodyItem = styled(Box)(({ theme, disabled }: IBodyItemEl) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
  color: theme.colorz.secondary.main,
  opacity: 0.9,
  cursor: 'pointer',
  '&:hover': {
    opacity: 1,
  },
  '&:last-child': {
    marginTop: theme.spacing(1),
    color: theme.colorz.status.error,
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.4 : 1,
  },
}))

export { Popover }
