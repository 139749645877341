import { ProfileSettingsProps } from './ProfileSettings.types'
import React, { useState } from 'react'
import { ISettingsGroupItem } from '../../components/SettingsGroupItem/SettingsGroupItem.types'
import { Container, ItemTitle, ButtonText } from './ProfileSettings.styles'
import ProfileImage from 'components/user/ProfileImage/ProfileImage'
import ProfileImageModal from './ProfileImageModal/ProfileImageModal'
import SettingsGroupItem from '../../components/SettingsGroupItem/SettingsGroupItem'
import { branchesOptions } from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoClient/content'
import * as uT from 'constants/user-types'

const ProfileSettings: React.FC<ProfileSettingsProps> = ({
  user,
  values,
  isLoading,
  handleSaveChange,
  handleDeleteProfile,
  handleConfirmUpload,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { type, imageUrl } = user
  const { firstName, lastName, companyName, field, website } = values

  const generateSettingsItems = (): ISettingsGroupItem[] => {
    const editableCompany =
      (!user.companyRef && type === uT.USER_TYPE_CLIENT_EMPLOYEE) ||
      type === uT.USER_TYPE_CLIENT_ADMIN
    const items: ISettingsGroupItem[] = [
      { name: 'firstName', label: 'Vorname', value: firstName, editable: true },
      { name: 'lastName', label: 'Nachname', value: lastName, editable: true },
      {
        name: 'companyName',
        label: 'Unternehmensname',
        value: companyName,
        editable: editableCompany,
      },
    ]

    let theWebsite: ISettingsGroupItem = {
      name: 'website',
      label: 'Unternehmenswebsite',
      value: website,
      editable: editableCompany,
    }

    if (type === uT.USER_TYPE_CLIENT_ADMIN || !user.companyRef) {
      items.push({
        name: 'field',
        label: 'Branche',
        value: field,
        options: branchesOptions,
        editable: editableCompany,
      })
    }

    if (type === uT.USER_TYPE_LAWYER_ADMIN || !user.companyRef) {
      items[2].label = 'Kanzleiname'
      theWebsite.label = 'Kanzleiwebsite'
    }

    items.push(theWebsite)

    return items
  }

  const handleCloseModal = (): void => {
    setIsOpen(false)
  }

  return (
    <Container>
      <ItemTitle>Profilbild</ItemTitle>
      <ProfileImage
        imageUrl={imageUrl}
        firstName={firstName}
        lastName={lastName}
        size={114}
        style={{ marginBottom: 48, cursor: 'pointer' }}
        handleClick={() => setIsOpen(true)}
      />
      <ItemTitle>Über Sie</ItemTitle>
      {generateSettingsItems().map((settingsItem, i) => (
        <SettingsGroupItem
          key={i}
          item={settingsItem}
          handleSaveChange={settingsItem.editable ? handleSaveChange : undefined}
        />
      ))}
      <ButtonText onClick={handleDeleteProfile}>Konto löschen</ButtonText>
      <ProfileImageModal
        imageUrl={imageUrl}
        isOpen={isOpen}
        handleClose={handleCloseModal}
        firstName={firstName}
        lastName={lastName}
        isLoading={isLoading}
        handleConfirmUpload={handleConfirmUpload}
      />
    </Container>
  )
}

export default ProfileSettings
