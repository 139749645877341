import { Question, OptionType } from 'types'
import React, { useEffect, useCallback, useState, Fragment } from 'react'
import ActionButtons from '../../ActionButtons/ActionButtons'
import SubquestionsComponent from './Subquestions.component'
import {
  deepAddOrEditQuestions,
  deepFindParentFromCurrentQuestion,
  StorageService,
} from 'utils'
import { ScrollWrapper } from '../../../TemplateEdit.styles'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { QUESTION_TYPE_TEXT_BOX } from 'constants/question-types'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'
import { initializeQuestion } from '../../../services'
import { useUser } from 'queries/user/useUser'

const Subquestions: React.FC = () => {
  const {
    set,
    addQuestion,
    onSubquestionDelete,
    onQuestionPreview,
    question,
    questionStep,
    mainQuestion,
    questions,
  } = useTemplateCreation()

  const currentQuestion = question!
  const { options, parentId, group } = currentQuestion

  const [mainIsParent, setMainIsParent] = useState(false)
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  const onQuestionSave = useCallback(
    (q: Question, mainIsParent: boolean): void => {
      set({
        question: q,
        questionStep: 5,
        selectedQuestionType: q.type,
        mainQuestion: !q.parentId || mainIsParent ? null : mainQuestion,
      })
    },
    [set, mainQuestion]
  )

  useEffect(() => {
    if (mainIsParent && mainQuestion) {
      onQuestionSave(mainQuestion, mainIsParent)
      setMainIsParent(false)
    }
  }, [mainIsParent, onQuestionSave, mainQuestion])

  const onSubquestionAdd = (option: OptionType, q?: Question): void => {
    trackIntercomEvent('template-creation-add-subquestion-clicked')
    StorageService.remove('textSelectionHtmlData')
    const theQuestion = q ? q : currentQuestion
    const parentId = docXmicro ? theQuestion.id : theQuestion.id + '--' + theQuestion.parentId
    set({
      questions: deepAddOrEditQuestions(questions, theQuestion, docXmicro),
      mainQuestion: !mainQuestion
        ? theQuestion
        : deepAddOrEditQuestions([mainQuestion], theQuestion, docXmicro)[0],
      question: {
        ...initializeQuestion(),
        parentId,
        optionId: option.id,
        group,
      },
      questionStep: 1,
      selectedQuestionType: QUESTION_TYPE_TEXT_BOX,
    })
  }

  const processCurrentQuestion = (): [Question, boolean] => {
    let _currentQuestion: Question = { ...currentQuestion }
    let returnToMainQ = false

    if (currentQuestion.returnToMainQ !== undefined) {
      if (currentQuestion.returnToMainQ) {
        returnToMainQ = true
      }
      delete _currentQuestion.returnToMainQ
    }
    return [_currentQuestion, returnToMainQ]
  }

  const setParentQuestionFromMain = () => {
    const parentQ = deepFindParentFromCurrentQuestion(mainQuestion!, currentQuestion)
    if (parentQ) return onQuestionSave(parentQ, false)
  }

  const onNext = () => {
    const [_currentQuestion, returnToMainQ] = processCurrentQuestion()
    if (!mainQuestion) return addQuestion(_currentQuestion)
    set({
      questions: deepAddOrEditQuestions(questions, _currentQuestion, docXmicro),
      mainQuestion: mainQuestion
        ? deepAddOrEditQuestions([mainQuestion], _currentQuestion, docXmicro)[0]
        : null,
    })
    if (parentId.startsWith(mainQuestion.id) || returnToMainQ)
      return setMainIsParent(true)
    if (mainQuestion) setParentQuestionFromMain()
  }

  const onBack = (): void => {
    set({ questionStep: questionStep - 1 })
  }

  const onSubquestionEdit = (question: Question) => {
    let returnToMainQ =
      question.parentId && question.parentId.length > 36 && !mainQuestion
    let questionToEdit = returnToMainQ ? { ...question, returnToMainQ } : question
    set({
      question: questionToEdit,
      questionStep: 1,
      selectedQuestionType: questionToEdit.type,
      mainQuestion: !mainQuestion ? currentQuestion : mainQuestion,
    })
  }

  return (
    <Fragment>
      <ScrollWrapper>
        <SubquestionsComponent
          options={options || []}
          handleAddSubquestion={onSubquestionAdd}
          handleDeleteQuestion={onSubquestionDelete}
          handleEditQuestion={onSubquestionEdit}
          handlePreviewQuestion={onQuestionPreview}
          addSubqDisabled={Boolean(
            currentQuestion.parentId && currentQuestion.parentId.length > 152
          )}
        />
      </ScrollWrapper>
      <ActionButtons
        handleBackClick={onBack}
        handleForwardClick={onNext}
        forwardDisabled={false}
        isSubquestion={Boolean(
          currentQuestion && currentQuestion.parentId && mainQuestion
        )}
        lastStep={true}
      />
    </Fragment>
  )
}

export default Subquestions
