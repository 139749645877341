import { User } from 'types'

interface IntercomCompany {
  company_id: string
  name: string
  size?: number
  website?: string
  industry?: string
}

interface IntercomUser {
  user_id?: string
  email?: string
  name?: string
  company?: IntercomCompany
  jobPosition?: string
}

export const userMapToIntercomUser = (currentUser?: User | null): IntercomUser => {
  let obj: IntercomUser = {}
  if (!currentUser) return obj

  let size: number = 0
  const { companyInfo } = currentUser
  const { employeeCount } = companyInfo

  if (employeeCount) {
    if (employeeCount === '100+') {
      size = 100
    } else {
      size = parseInt(employeeCount.split('-')[1])
    }
  }

  const companyObj = {
    company_id: companyInfo.id,
    size,
    name: companyInfo.name,
    website: companyInfo.website,
    industry: companyInfo.field,
  }
  obj.company = companyObj
  obj.jobPosition = currentUser.jobPosition

  const userInfo = {
    user_id: currentUser.id,
    email: currentUser.email,
    name: `${currentUser.firstName} ${currentUser.lastName}`,
  }
  return { ...obj, ...userInfo }
}
