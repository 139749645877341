import React from 'react'

const classes = {
  wrapper: 'Wizard-Configuration-wrapper',
}

export const Configuration = React.memo(props => {
  const { configuring } = props
  return <section data-shift={configuring} className={classes.wrapper}></section>
})

Configuration.displayName = 'Wizard-Configuration'

export default Configuration
