import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  minHeight: 56,
  backgroundColor: theme.colorz.secondary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
