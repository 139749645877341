import { Question, IOption } from 'types'

export const checkIfQuestionHasValues = (question: Question): boolean => {
  if (question.locations && question.locations.length) return true
  if (question.options) {
    const hasLocations = (option: IOption): boolean =>
      Boolean(option.locations && option.locations.length > 0)
    return question.options.some(
      (optionItem) =>
        optionItem.text.length || hasLocations(optionItem)
    )
  }
  return false
}
