import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@material-ui/core";

import { useDocumentGeneration } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'

import { SaveModal } from 'components/features'
import { lastStepContent as content } from '../../content'
import { documentsRoutes } from 'constants/routes'
// import { useEditor } from "config/textEditor/useEditor";

const modalText = 'Benennen und speichern Sie das Dokument.'

const ModalContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '0',
  left: 0,
  right: 0,
  bottom: 0,
  background: theme.colorz.brand.main,
  zIndex: 300,
}))

export const LastStepDocumentGeneration = (props: any) => {
  const { setDirty } = props
  const { set, save, name, step, mode, documentId, isSaving } =
    useDocumentGeneration()
  const { push, replace } = useHistory()

  // const editor = useEditor();

  // const onDownload = (): void => {
  //   if (!editor) return;
  //   editor.execCommand("mcePrint");
  //   push(documentsRoutes.main);
  // };

  // const onRedirect = (): void => {
  //   push(documentsRoutes.main);
  // };

  const onSaveAs = () => {
    setDirty(false)
    save()
    push(documentsRoutes.main)
  }

  const onClose = () => {
    if (mode === 'create') {
      replace(`/dokument/${documentId}/bearbeiten?fromCreate=true`)
    } else {
      set({ step: 2 })
    }
  }

  if (step === 2) return null

  return (
    <ModalContainer>
      <SaveModal
        heading={mode === 'edit' ? content.headingEdited : content.heading}
        primaryBtnText={content.primaryButton}
        // secondaryBtnText={content.secondaryButton}
        primaryButtonDisabled={isSaving}
        isLoading={isSaving}
        text={modalText}
        handlePrimaryClick={onSaveAs}
        // handleSecondaryClick={onRedirect}
        onClose={onClose}
        inputValue={name}
        handleChange={(n: any) => set({ name: n })}
      />
    </ModalContainer>
  )
}
