export const ERR_MSG_DEFAULT =
  'Entschuldigung! Es ist ein Fehler aufgetreten. Wir kümmern uns darum.'
export const ERR_MSG_PASSWORD_CHARACTERS_COUNT =
  'Das Passwort muss mindestens 8 Zeichen haben.'
export const ERR_PASSWORDS_DONT_MATCH =
  'Neues Passwort und bestätigtes Passwort müssen sich überklappen'
export const MESSAGE_INVALID_EMAIL = 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
export const ERR_MSG_NO_USER_WITH_EMAIL = 'Diese Person hat leider noch kein CASUS-Konto.'
export const USER_WITH_EMAIL_ALREADY_EXISTS =
  'Ein Benutzer mit eingegebener E-Mail-Adresse ist bereits vorhanden.'

export const SUCCESS_PASSWORD_CHANGED = 'Ihr Passwort wurde erfolgreich geändert.'

export const ERR_MSG_TO_MANY_PASSWORD_ATTEMPTS =
  'Der Zugriff auf dieses Konto wurde aufgrund vieler fehlgeschlagener Anmeldeversuche vorübergehend deaktiviert. Sie können es sofort wiederherstellen, indem Sie Ihr Passwort zurücksetzen, oder Sie können es später erneut versuchen.'
