import { styled } from '@material-ui/core'

const TableContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  paddingBottom: 60,
})

export default TableContainer
