import { IReminder } from 'types'
import { useMutation, useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import { queryKeys } from 'queries/queryKeys'
import RemindersApi from 'api/reminders'
import { analytics } from 'utils'

export const useReminderCreate = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const mutation = useMutation(queryKeys.DOCUMENT_REMINDERS, RemindersApi.create, {
    onSuccess: (reminder, variables) => {
      queryClient.setQueryData([queryKeys.DOCUMENT_REMINDERS, reminder.id], reminder)
      queryClient.setQueryData<IReminder[]>(queryKeys.DOCUMENT_REMINDERS, data =>
        data ? [reminder, ...data] : [reminder]
      )
      analytics.logEvent('reminder_created', { ...reminder.entity, type: 'document' })
    },
    onError: () => {
      toast('error', 'default')
    },
  })
  return mutation
}
