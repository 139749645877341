export const objectHasPropsWithValue = (
  obj: { [key: string]: any },
  ...props: string[]
): boolean => {
  for (let prop of props) {
    if (!obj[prop]) return false
  }

  return true
}
