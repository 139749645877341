import { Theme } from '@material-ui/core'

export const scrollBarStyles = (theme: Theme): any => ({
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colorz.primary.main,
    outline: 'none',
  },
  'scroll-behavior': 'smooth',
})

export const scrollBarStylesLight = (theme: Theme): any => ({
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colorz.primary.light,
    outline: 'none',
  },
  'scroll-behavior': 'smooth',
})
