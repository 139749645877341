import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
// import back from '../../../assets/icons/arrow-left.svg'

const classes = {
  wrapper: 'Wizard-Footer-wrapper',
  button: {
    wrapper: 'Wizard-Footer-Button-wrapper',
  },
}

const Button = React.memo(
  forwardRef((props, ref) => {
    const { disabled = false, handler, render = '', tabIndex = 0 } = props
    return (
      <button
        ref={ref}
        className={classes.button.wrapper}
        disabled={disabled}
        type="button"
        tabIndex={tabIndex}
        onClick={handler}
        data-disabled={String(disabled)}
      >
        {render}
      </button>
    )
  })
)

Button.displayName = 'Button'

export const Footer = React.memo(props => {
  const [disabled, setDisabled] = useState(false)
  const buttonRef = useRef()
  const { configuringHandler, onFinishHandler, configuring } = props

  const toggleConfiguring = useCallback(
    event => {
      setDisabled(true)
      configuringHandler(prev => !prev)
    },
    [configuringHandler]
  )

  useEffect(() => {
    const refCurrent = buttonRef.current
    if (refCurrent) {
      const transitionHandler = event => setDisabled(false)
      refCurrent.addEventListener('transitionend', transitionHandler)
      return () => refCurrent.removeEventListener('transitionend', transitionHandler)
    }
  }, [])

  return (
    <section data-shift={configuring} className={classes.wrapper}>
      <Button
        ref={buttonRef}
        disabled={disabled}
        handler={toggleConfiguring}
        // render={configuring ? <img src={back} alt="Back!" /> : <span>Configure</span>}
      />
      <Button disabled={disabled} handler={onFinishHandler} tabIndex={!configuring ? -1 : 0} render="Finish" />
    </section>
  )
})

Footer.displayName = 'Wizard-Footer'

export default Footer
