import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useQueryClient } from 'react-query'

import AuthApi from 'api/auth.api'
import { queryKeys } from 'queries/queryKeys'

export const useLogout = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { push } = useHistory()

  const logout = useCallback(async () => {
    push('/logout')
    await AuthApi.logoutUser()
    queryClient.invalidateQueries(queryKeys.USER)
    queryClient.invalidateQueries()
    queryClient.clear()
    localStorage.clear()
    AuthApi.setAuthHeaders(null, null)
    push('/auth/login')
    dispatch({ type: 'LOGOUT' })
  }, [push, dispatch, queryClient])

  return logout
}
