import { IReminder } from 'types'
import { useMutation, useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import { queryKeys } from 'queries/queryKeys'
import RemindersApi from 'api/reminders'

export const useReminderDelete = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const mutation = useMutation(queryKeys.DOCUMENT_REMINDERS, RemindersApi.delete, {
    onSuccess: (id, variables) => {
      queryClient.invalidateQueries([queryKeys.DOCUMENT_REMINDERS, id])
      queryClient.setQueryData<IReminder[]>(queryKeys.DOCUMENT_REMINDERS, data =>
        (data ?? []).filter(r => r.id !== id)
      )
    },
    onError: () => {
      toast('error', 'default')
    },
  })
  return mutation
}
