import { styled, Typography } from '@material-ui/core'

export const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.colorz.status.error,
  marginTop: theme.spacing(3),
  fontWeight: 700,
  opacity: 0.85,
  transition: 'all .2s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-2px)',
    opacity: 1,
  },
}))

export * from '../AccountSettings.styles'
