import { CalendlyBookingUrlItem } from 'types'

import React from 'react'
import { LawFieldContainer, Image, ImageWrapper, Text } from './LawFields.styles'

interface Props {
  onFieldSelect: () => void
  lawField: CalendlyBookingUrlItem
}

const LawField: React.FC<Props> = ({ lawField, onFieldSelect }) => {
  const { name, imageUrl, enabled } = lawField
  if (!enabled) return null
  return (
    <LawFieldContainer onClick={onFieldSelect} boxShadow={1}>
      <ImageWrapper>
        <Image src={imageUrl} />
      </ImageWrapper>
      <Text>{name}</Text>
    </LawFieldContainer>
  )
}

export default LawField
