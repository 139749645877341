import React, { Fragment, useState } from 'react'
import Document from './FileComponent'
import { MenuPopover } from 'components/common'
import { IDocument, ITemplate } from 'types'

import { FolderItem } from 'types'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'

interface Props {
  menuItems: IPopupMenuItem[]
  document: IDocument | ITemplate
  renameActive?: boolean
  isShared?: boolean
  currentFolderType?: FolderItem['type']
  handleLeftClick?: (docId: string) => void
  handleUpdateName?: (docId: string, newName: string) => void
  isDownloadable?: boolean,
  isLoading?: boolean
}

const FileWithMenu: React.FC<Props> = ({
  menuItems,
  document,
  renameActive,
  isShared,
  currentFolderType,
  handleLeftClick,
  handleUpdateName,
  isDownloadable = false,
  isLoading = false
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const rightClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (menuItems.length && !isLoading) setAnchorEl(e.currentTarget)
  }

  const leftClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(null)
    if (handleLeftClick && document.status !== 'trashed' && !isLoading) handleLeftClick(document.id)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Document
        id={document.id}
        title={document.name}
        edited={document.edited}
        renameActive={renameActive}
        templateName={(document as IDocument).templateName}
        type={(document as IDocument).type}
        sharedWith={document.sharedWith}
        sharedAuthor={document.author}
        sharingEnabled={document.sharingEnabled}
        isUpToDateWithTheLatestTemplateVersion={
          (document as IDocument)?.isUpToDateWithTheLatestTemplateVersion
        }
        currentFolderType={currentFolderType}
        isCasus={!!(document as ITemplate)?.isCasus}
        onClick={leftClick}
        onContextMenu={rightClick}
        handleUpdateName={handleUpdateName}
        isDownloadable={isDownloadable}
        isLoading={isLoading}
      />
      <MenuPopover menuItems={menuItems} handleClose={handleClose} anchorEl={anchorEl} />
    </Fragment>
  )
}

export default FileWithMenu
