import { Box, styled, Theme, Typography, TypographyProps } from '@material-ui/core'

const commonStyles = {
  fontSize: 16,
  letterSpacing: 0.4,
  fontWeight: 500,
}

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.colorz.secondary[200],
  ...commonStyles,
}))

interface TextEl extends TypographyProps {
  theme: Theme
  disabled: number
}

export const Text = styled(Typography)(({ theme, disabled }: TextEl) => ({
  ...commonStyles,
  transition: 'all .2s',
  cursor: disabled ? 'auto' : 'pointer',
  color: !disabled ? theme.colorz.secondary[200] : theme.colorz.secondary.main,
  padding: theme.spacing(0, 1),
  '&:first-child': {
    marginLeft: 0,
    paddingLeft: 0,
  },
  '&:last-child': {
    marginLeft: 0,
  },
  '&:hover': {
    color: !disabled ? theme.colorz.secondary[300] : theme.colorz.secondary.main,
    transform: `translateY(-${disabled ? '0' : '1'}px)`,
  },
}))
