import { TypeBoxProps } from './TypeBox.types'
import React from 'react'
import { Container, IconContainer, Typography } from './TypeBox.styles'
import { Icon } from 'components/common'
import { boxTypes } from 'pages/Authenticated/Template/constants/content'

const TypeBox: React.FC<TypeBoxProps> = ({
  questionType,
  selected,
  label,
  handleTypeBoxClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (handleTypeBoxClick) handleTypeBoxClick(e)
  }
  return (
    <Container
      key={questionType}
      selected={questionType === selected ? 1 : 0}
      onClick={handleClick}
      id={`type-box-${questionType}`}
      data-intercom-target={`type-box-${questionType}`}
    >
      <IconContainer>
        {' '}
        <Icon iconName={boxTypes[questionType].icon} />{' '}
      </IconContainer>
      <Typography variant='body2'>
        {label ? label : boxTypes[questionType].label}
      </Typography>
    </Container>
  )
}
export default TypeBox
