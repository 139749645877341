const checklistTeam = [
  { icon: 'check', text: 'Alle Funktionalitäten des BASIC-Abos' },
  { icon: 'plus', text: '20 User' },
  { icon: 'plus', text: 'Eigene Vorlagen erstellen' },
  { icon: 'plus', text: 'Dokumente teilen' },
  { icon: 'plus', text: 'Teammanagement' },
  { icon: 'plus', text: 'Persönliches Onboarding' },
]

const checklistBasic = [
  { icon: 'check', text: '5 User' },
  { icon: 'check', text: 'Unlimitierte anwaltliche Vorlagen' },
  { icon: 'check', text: 'Digitales Dokumentenmanagement' },
  { icon: 'check', text: 'Zugang zu Rechtsberatung durch spezialisierte Anwälte' },
  { icon: 'check', text: 'Technischer Support über Chat und Online-Anruf' },
]

const checklistStartup = [
  { icon: 'check', text: '1 User' },
  { icon: 'check', text: '5 anwaltliche Vorlagen pro Monat' },
  { icon: 'check', text: 'Digitales Dokumentenmanagement' },
  { icon: 'check', text: 'Zugang zu Rechtsberatung durch spezialisierte Anwälte' },
  { icon: 'check', text: 'Technischer Support über Chat und Online-Anruf' },
]

export const startupPlan = {
  id: 'startup',
  heading: 'Starter',
  priceTitle: 'Für Einsteiger & Startups',
  price: {
    month: '14.-',
    year: '12.-',
  },
  priceSubtitle: 'pro Monat',
  checklist: checklistStartup,
  actionButtonText: 'Starter wählen',
  description:
    'Für Einsteiger und Startups. Ideal für User, die einzelne Vertragsdokumente benötigen.',
}

export const basicPlan = {
  id: 'basic',
  heading: 'Basic',
  priceTitle: 'Für kleine Unternehmen',
  price: {
    month: '89.-',
    year: '79.-',
  },
  priceSubtitle: 'pro Monat',
  checklist: checklistBasic,
  actionButtonText: 'Basic wählen',
  description:
    'Für Einsteiger. Das perfekte Abo für kleinere Unternehmen, die unlimitiert anwaltliche Vorlagen benützen und Zugang zur Rechtsberatung haben möchten.',
}

export const teamPlan = {
  id: 'team',
  heading: 'Team',
  priceTitle: 'Für KMU mit grösseren Teams',
  price: {
    month: '179.-',
    year: '144.-',
  },
  priceSubtitle: 'pro Monat',
  checklist: checklistTeam,
  actionButtonText: 'Team wählen',
  description:
    'Für KMU. Unser Abo für alle Unternehmen, die nicht nur auf anwaltliche Vorlagen und Rechtsberatung zugreifen möchten, sondern auch interne Vorlagen digitalisieren wollen.',
}

export const enterprisePlan = {
  id: 'enterprise',
  heading: 'Enterprise',
  priceTitle: 'Für Rechtsabteilungen',
  price: {
    month: `Let’s talk!`,
    year: 'Let’s talk!',
  },
  priceSubtitle: 'pro Monat',
  description: `Das individuelle CASUS-Paket für Ihr Unternehmen, exakt nach Ihren Bedürfnissen. Für Rechtsabteilung und Anwaltskanzleien, damit Ihre Mitarbeitenden internes Rechts-Know-how jederzeit korrekt abrufen können.`,
  actionButtonText: 'Kontaktieren Sie uns',
}

export const freePlan = {
  heading: 'Free',
  priceTitle: 'Für Alle',
  price: {
    month: '0.-',
    year: '0.-',
  },
  priceSubtitle: 'pro Monat',
  checklist: checklistTeam,
  actionButtonText: '',
  description: 'Free plan description',
}

const allContent = {
  basic: basicPlan,
  team: teamPlan,
  enterprise: enterprisePlan,
  startup: startupPlan,
}

export default allContent
