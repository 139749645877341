import React from 'react'
import Header from 'layout/PagesLayout/Header/Header'
import * as T from 'constants/user-types'
import { useMasterData } from 'queries/app/useMasterdata'
import { useUser } from 'queries/user/useUser'

// const selector = (state: State) => {
//   const {
//     app,
//     auth: { user },
//   } = state

//   const teamClientAllowed =
//     Boolean(app?.masterdata?.client.team_members_enabled) &&
//     user?.type === T.USER_TYPE_CLIENT_ADMIN
//   const teamLawyerAllowed =
//     Boolean(app?.masterdata?.lawyer.team_members_enabled) &&
//     user?.type === T.USER_TYPE_LAWYER_ADMIN

//   return {
//     consultingDisabled: !Boolean(app?.masterdata?.legal_stuff.advice_tab_shown),
//     teamPageDisabled: !Boolean(teamClientAllowed || teamLawyerAllowed),
//   }
// }

interface P {
  headerOpacity: number
}

const useData = () => {
  const masterdata = useMasterData()
  const user = useUser()

  const teamClientAllowed =
    Boolean(masterdata?.client.team_members_enabled) &&
    user?.type === T.USER_TYPE_CLIENT_ADMIN
  const teamLawyerAllowed =
    Boolean(masterdata?.lawyer.team_members_enabled) &&
    user?.type === T.USER_TYPE_LAWYER_ADMIN

  return {
    consultingDisabled: !Boolean(masterdata?.legal_stuff.advice_tab_shown && user?.tabs?.bookLawyer),
    teamPageDisabled: !Boolean(teamClientAllowed || teamLawyerAllowed),
  }
}

const HeaderContainer: React.FC<P> = props => {
  const { consultingDisabled, teamPageDisabled } = useData()

  const routes = [
    {
      label: 'Aktenschrank',
      url: '/aktenschrank',
      disabled: false,
    },
    {
      label: 'Vorlagen',
      url: '/vorlagen',
      disabled: false,
    },
    {
      label: 'Rechtsberatung',
      url: '/rechtsberatung',
      disabled: consultingDisabled,
    },
    {
      label: 'Team',
      url: '/team',
      disabled: teamPageDisabled,
    },
    {
      label: 'Logout',
      url: '/logout',
      disabled: false,
    },
  ]

  return <Header routes={routes} {...props} />
}

export default HeaderContainer
