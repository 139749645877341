import { IApiHookOptions } from 'types'
import { useState } from 'react'
import { useToast } from 'hooks/useToast'
import TeamApi, { InviteTeamMemberApi } from 'api/team.api'

export const useInviteTeamMember = () => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const invite = async (data: InviteTeamMemberApi, opts: IApiHookOptions) => {
    try {
      setIsLoading(true)
      await TeamApi.sendInviteEmail(data)
      toast(
        'success',
        `Eine E-Mail mit dem Registrierungslink wurde an ${data.email} gesendet!`
      )
      if (opts.onSuccess) opts.onSuccess()
    } catch (err) {
      if (err?.response?.data?.error_code === 'E0262') {
        toast('error', 'Sie haben die maximale Anzahl an Teammitgliedern erreicht.')
      }
      if (err?.response?.data?.error_code === 'E0508') {
        toast('error', 'Sie haben schon ein Teammitglied mit dieser Email Addresse.')
      }
      if (opts.onError) opts.onError()
    } finally {
      setIsLoading(false)
      if (opts.onSettled) opts.onSettled()
    }
  }

  return { invite, isLoading }
}
