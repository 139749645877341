import { ProfileInfoLawyerProps } from '../../components/ProfileInfo.types'
import React, { useCallback } from 'react'
import {
  Form,
  FormHeader,
  FormBody,
  FormFooter,
  Heading,
  FieldsContainer,
  FormTitle,
  FooterText,
  Modal,
  modalStyle,
} from '../../components/ProfileInfo.styles'
import ProfileInfoLawyerStepOne from './ProfileInfoLawyerStepOne'
import ProfileInfoLawyerStepTwo from './ProfileInfoLawyerStepTwo'
import ProfileInfoLawyerStepThree from './ProfileInfoLawyerStepThree'
import ActionButtons from '../../components/ActionButtons'
import StatusSteps from '../../components/StatusSteps/StatusSteps'
import * as content from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoLawyer/content'
import SignUpProfileImageSetup from '../../components/SignUpProfileImageSetup'

const ProfileInfoLawyer: React.FC<ProfileInfoLawyerProps> = ({
  step,
  values,
  isOpen,
  isLoading,
  imageFile,
  editorRef,
  stripeLoading,
  signUpWithFinancials,
  userType,
  handleChange,
  handleForwardClick,
  handleBackClick,
  handleSubmit,
  setImageFile,
}) => {
  const { firstName, lastName, chancellery, website, bankAccountOwnerName, twoFaEnabled } = values

  const stepOneValues = { chancellery, website, twoFaEnabled }
  const stepTwoValues = { firstName, lastName, twoFaEnabled }

  const checkStepOneIsCompleted = useCallback((): number => {
    return values.chancellery.length
  }, [values.chancellery])

  const checkStepTwoIsCompleted = useCallback((): number => {
    return firstName && lastName ? 1 : 0
  }, [firstName, lastName])

  const checkStepThreeIsCompleted = useCallback((): number => {
    let theName: string | string[] = bankAccountOwnerName
    theName = theName.trim().split(' ')
    return theName.length >= 2 &&
      theName[0].trim().length > 1 &&
      theName[1].trim().length > 1
      ? 1
      : 0
  }, [bankAccountOwnerName])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (step !== 3) return
    handleSubmit(e)
  }

  const renderContent = (): React.ReactNode | null => {
    switch (step) {
      case 0:
        return (
          <ProfileInfoLawyerStepOne
            handleChange={handleChange}
            values={stepOneValues}
            userType={userType}
          />
        )
      case 1:
        return (
          <ProfileInfoLawyerStepTwo handleChange={handleChange} values={stepTwoValues} />
        )
      case 2:
        return (
          <SignUpProfileImageSetup
            imageFile={imageFile}
            setImageFile={setImageFile}
            firstName={firstName}
            lastName={lastName}
            handleForwardClick={handleForwardClick}
            isLoading={isLoading}
            editorRef={editorRef}
          />
        )
      case 3:
        return signUpWithFinancials ? (
          <ProfileInfoLawyerStepThree
            isLoading={isLoading}
            handleChange={handleChange}
            values={{ bankAccountOwnerName }}
          />
        ) : null
      default:
        return null
    }
  }

  const statusBarSteps = [
    {
      name: '1. Über Ihre Kanzlei',
      completed: checkStepOneIsCompleted(),
    },
    {
      name: '2. Über Sie',
      completed: checkStepTwoIsCompleted(),
    },
  ]

  if (signUpWithFinancials) {
    statusBarSteps.push({
      name: '3. Abrechnung',
      completed: checkStepThreeIsCompleted(),
    })
  }

  let theStep = step
  if (step === 2) theStep = 1
  if (step > 2) theStep = step - 1

  return (
    <Modal open={isOpen} style={modalStyle}>
      <Form onSubmit={onSubmit}>
        <FormHeader>
          <FormTitle>{content.formTitle}</FormTitle>
          <StatusSteps steps={statusBarSteps} step={theStep} />
        </FormHeader>
        <FormBody>
          <Heading variant='h4'>{content.headings[step]}</Heading>
          <FieldsContainer>{renderContent()}</FieldsContainer>
          <FormFooter>
            <FooterText variant='body2'>{content.footerText}</FooterText>
            <ActionButtons
              handleBackClick={handleBackClick}
              handleForwardClick={handleForwardClick}
              step={step}
              isLoading={isLoading || stripeLoading}
              nextDisabled={!statusBarSteps[theStep].completed}
            />
          </FormFooter>
        </FormBody>
      </Form>
    </Modal>
  )
}

export default ProfileInfoLawyer
