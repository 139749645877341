import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { ReactComponent as LogoSvg } from 'assets/logoDark.svg'

export const CopySection = styled(Box)(({ theme }) => ({
  width: '50%',
  height: '100%',
  backgroundColor: theme.colorz.brand.light,
  display: 'flex',
  alignItems: 'center',
  paddingRight: '2.6%',
  paddingLeft: '1.36%',
  flexShrink: 0,
}))

export const CopyContent = styled(Box)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  width: 496,
  margin: '0 auto',
}))

export const HeadingContent = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.colorz.secondary.main,
}))

export const Heading = styled(Typography)({
  fontSize: 42,
  lineHeight: 1.5,
  fontFamily: 'Heebo',
  fontWeight: 700,
})

export const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  marginBottom: theme.spacing(6),
}))

export const AuthSection = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  display: 'flex',
})

export const AuthContent = styled(Box)({
  width: 384,
  margin: '0 auto',
  display: 'flex',
  alignItems: 'flex-start',
})

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  backgroundColor: '#fff',
  margin: '0 auto',
  position: 'relative',
}))

export const Logo = styled(LogoSvg)({
  position: 'absolute',
  top: 31,
  left: 35,
  width: 110,
})

export const BulletPoints = styled(Box)({})

export const BulletPoint = styled(Box)(({ theme }) => ({
  fontSize: 16,
  marginBottom: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
}))

export const Image = styled('img')(({ theme }) => ({}))

export const ImageBox = styled(Box)(({ theme }) => ({
  minWidth: 96,
  textAlign: 'center',
  marginRight: theme.spacing(2)
}))
