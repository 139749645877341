import { ISelectGroup } from './SelectGroup.types'
import React, { useRef } from 'react'
import {
  StyledInputBase,
  Label,
  Placeholder,
  Text,
  FormControl,
  Select,
  MenuItem,
} from './SelectGroup.styles'
import { secondary } from 'config/themes/colors'

const SelectGroup: React.FC<ISelectGroup> = ({
  handleChange,
  value,
  options,
  name,
  error,
  label,
  placeholder,
  customvariant = 'normal',
  optionMenuStyles = {},
  LabelProps = {},
  InputElProps = {},
}) => {
  const selectInput = useRef<HTMLDivElement>(null)
  const width = selectInput.current ? selectInput.current.clientWidth : 300
  let defaultValue: string | number = ''
  if (placeholder && !value) defaultValue = 1
  if (value) defaultValue = value

  return (
    <FormControl style={{ width: '100%' }} ref={selectInput}>
      {label && (
        <Label variant='h5' {...LabelProps}>
          {label}
        </Label>
      )}
      <Select
        labelId='demo-customized-select-label'
        value={defaultValue}
        onChange={handleChange}
        input={
          <StyledInputBase
            name={name}
            error={error}
            customvariant={customvariant}
            {...InputElProps}
          />
        }
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              background: '#fff',
              borderRadius: 0,
              marginTop: 3,
              width,
              maxWidth: width,
              border: `${customvariant === 'thick' ? '2' : '1'}px solid ${
                customvariant === 'thick' ? secondary[300] : secondary[100]
              }`,
              borderTop: 'none',
              marginLeft: `-${customvariant === 'thick' ? '2' : '1'}px`,
              padding: '0 16px',
              ...optionMenuStyles,
            },
            elevation: 0,
          },
        }}
      >
        {placeholder && (
          <MenuItem value={1} disabled>
            <Placeholder style={{ opacity: 0.66 }}>{placeholder}</Placeholder>
          </MenuItem>
        )}
        {options.map((opt, i) => (
          <MenuItem key={i} value={opt.value} disabled={opt.disabled}>
            <Text>{opt.label}</Text>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectGroup
