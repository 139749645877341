import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { StatusStepProps } from './StatusSteps.types'

export const Container = styled(Box)({
  display: 'flex',
})

export const Step = styled(Box)(({ theme, completed }: StatusStepProps) => ({
  width: 198,
  paddingBottom: theme.spacing(1),
  borderBottomStyle: 'solid',
  borderBottomWidth: 2,
  borderBottomColor: completed ? theme.colorz.status.success : '#fff',
  display: 'flex',
  justifyContent: 'center',
  '&:not(:last-child)': {
    marginRight: 8,
  },
}))

export const Text = styled(Typography)({
  textAlign: 'center',
  color: '#fff',
})
