import { FolderListComponentProps } from './Folder.types'
import React from 'react'
import { useUser } from 'queries/user/useUser'
import Folder from './Folder'
import { ListContainer } from './Folder.styles'

const FolderListComponent: React.FC<FolderListComponentProps> = ({
  folders,
  ...folderProps
}) => {
  const user = useUser()
  return (
    <ListContainer>
      {folders.map(folderItem => (
        <Folder
          key={folderItem.id}
          folder={folderItem}
          {...folderProps}
          userId={user?.id}
        />
      ))}
    </ListContainer>
  )
}

export default FolderListComponent
