import { TQuestionTypes } from 'types'
import 'rangy/lib/rangy-core'
import 'rangy/lib/rangy-serializer'
import 'rangy/lib/rangy-selectionsaverestore'
import 'rangy/lib/rangy-textrange'
import 'rangy/lib/rangy-classapplier'
import 'rangy/lib/rangy-highlighter'
import rangy from 'rangy'

export const rangyHighlighter = (
  doc: Document,
  qid: string,
  locationId: string,
  parentId: string,
  qtype: TQuestionTypes
) => {
  const selection = rangy.getSelection(doc)

  let classApplier = 'highlight-1'

  const elem = selection.getRangeAt(0).startContainer.parentElement

  if (elem?.className.includes('highlight-4')) classApplier = 'highlight-5'
  if (elem?.className.includes('highlight-3')) classApplier = 'highlight-4'
  if (elem?.className.includes('highlight-2')) classApplier = 'highlight-3'
  if (elem?.className.includes('highlight-1')) classApplier = 'highlight-2'

  const range = selection.getRangeAt(0)
  const { startContainer, endContainer } = range

  const startWholeText = startContainer?.parentNode?.textContent
  const endWholeText = endContainer?.parentNode?.textContent

  const selectionText = selection.toString()

  if (
    !selectionText.startsWith(String(startWholeText)) &&
    !selectionText.endsWith(String(endWholeText)) &&
    startContainer.parentNode !== endContainer.parentNode
  ) {
    alert('Es ist nicht möglich, überschneidende Elemente zu markieren.')
    if (selection.empty) selection.empty()
    return selection.removeAllRanges()
  }

  // @ts-ignore
  let highlighter = rangy.createHighlighter(doc)

  highlighter.addClassApplier(
    // @ts-ignore
    rangy.createClassApplier(classApplier, {
      elementTagName: 'mark',
      elementAttributes: {
        id: locationId,
        'data-parentid': parentId ? parentId : '',
        'data-qid': qid,
        'data-qtype': qtype,
        style:
          'font-size: inherit; font-family:inherit; font-style: inherit; font-weight: inherit',
      },
    })
  )
  highlighter.highlightSelection(classApplier, selection)
  if (selection.empty) selection.empty()
  selection.removeAllRanges()
}
