import { SidebarLinkProps } from './Sidebar.types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LinkItem, LinkText } from './Sidebar.styles'

const SidebarLink: React.FC<SidebarLinkProps> = ({ url, urlName, variant }) => {
  const location = useLocation()
  return (
    <Link to={url}>
      <LinkItem isactive={location.pathname === url ? 1 : 0} sidebarvariant={variant}>
        <LinkText sidebarvariant={variant} isactive={location.pathname === url ? 1 : 0}>
          {urlName}
        </LinkText>
      </LinkItem>
    </Link>
  )
}

export default SidebarLink
