export const PAPER_NAMES = {
  LETTER: 'letter',
  LEGAL: 'legal',
  A3: 'a3',
  A4: 'a4',
  A5: 'a5',
  B4: 'b4',
  B5: 'b5',
  EURO_FANFOLD: 'euro-fanfold',
  EXECUTIVE1: 'executive1',
  EXECUTIVE2: 'executive2',
  EXECUTIVE3: 'executive3',
  FOLIO: 'folio',
  LEDGER: 'ledger',
  TABLOID: 'tabloid',
  QUATRO: 'quatro',
  SHORT: 'short',
  STATEMENT: 'statement',
  STATIONERY: 'stationery',
  NARROW: 'narrow',
  WIDE: 'wide',
  '1403W': '1403w',
  '1403WS': '1403ws',
  '3800N': '3800n',
  '3800NS': '3800ns',
  '3800W': '3800w',
  '3800WS': '3800ws',
  CUT_SHEET: 'cut-sheet',
}

const mmpi = 25.4
export const pxpi = 96

const PAGE_SIZES = {
  [PAPER_NAMES.A5]: { width: 148 / mmpi, height: 210 / mmpi },
  [PAPER_NAMES.B5]: { width: 182 / mmpi, height: 257 / mmpi },
  [PAPER_NAMES.A4]: { width: 210 / mmpi, height: 297 / mmpi },
  [PAPER_NAMES.QUATRO]: { width: 215 / mmpi, height: 275 / mmpi },
  [PAPER_NAMES.EURO_FANFOLD]: { width: 250 / mmpi, height: 340 / mmpi },
  [PAPER_NAMES.B4]: { width: 257 / mmpi, height: 364 / mmpi },
  [PAPER_NAMES.A3]: { width: 297 / mmpi, height: 420 / mmpi },
  [PAPER_NAMES.STATEMENT]: { width: 5.5, height: 8.5 },
  [PAPER_NAMES.EXECUTIVE1]: { width: 7, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE2]: { width: 7.25, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE3]: { width: 7.5, height: 10.5 },
  [PAPER_NAMES['3800NS']]: { width: 7.5, height: 11.5 },
  [PAPER_NAMES['3800WS']]: { width: 7.5, height: 13.5 },
  [PAPER_NAMES.STATIONERY]: { width: 8, height: 10 },
  [PAPER_NAMES['3800N']]: { width: 8.5, height: 10 },
  [PAPER_NAMES.SHORT]: { width: 8.5, height: 10.5 },
  [PAPER_NAMES.LETTER]: { width: 8.5, height: 11 },
  [PAPER_NAMES.FOLIO]: { width: 8.5, height: 13 },
  [PAPER_NAMES['1403WS']]: { width: 8.5, height: 13.5 },
  [PAPER_NAMES.LEGAL]: { width: 8.5, height: 14 },
  [PAPER_NAMES['3800W']]: { width: 10, height: 13.5 },
  [PAPER_NAMES.NARROW]: { width: 10, height: 14 },
  [PAPER_NAMES['1403W']]: { width: 11, height: 13.5 },
  [PAPER_NAMES.WIDE]: { width: 11, height: 14 },
  [PAPER_NAMES.TABLOID]: { width: 11, height: 17 },
  [PAPER_NAMES.CUT_SHEET]: { width: 17, height: 22 },
}

export const PAGE_SIZES_IN_PT = Object.entries(PAGE_SIZES).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: { width: value.width * pxpi, height: value.height * pxpi },
  }),
  {}
)

export const ORIENTATION = {
  horizontal: 'landscape',
  vertical: 'portrait',
}

const sectionSettings1 = {
  id: 'some-id-from-BE-1',
  orientation: ORIENTATION.vertical,
  paper: PAPER_NAMES.A4,
  margins: { top: 1, left: 1.252, bottom: 1, right: 1.252 },
}
const sectionSettings2 = {
  id: 'some-id-from-BE-2',
  orientation: ORIENTATION.horizontal,
  paper: PAPER_NAMES.A4,
  margins: { top: 1, left: 1.252, bottom: 1, right: 1.252 },
}

export const documentSettings = [sectionSettings1, sectionSettings2]
export default documentSettings
