import { IConfirmationPopupContent, IDocument } from 'types'
import React from 'react'
import { Box, styled } from '@material-ui/core'

import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'
import { useHistory } from 'react-router'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useDocumentsUpdate } from 'queries/documents'
import { useToast } from 'hooks/useToast'
import { useEditor } from 'config/textEditor/useEditor'
import { useUser } from 'queries/user/useUser'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

// import { DocumentSettingsModalContainer } from 'components/documentSettings/DocumentSettingsModal'

import PurchaseTemplateModal from 'pages/Authenticated/Templates/components/PurchaseTemplateModal/PurchaseTemplateModal'
import { PdfDocumentView } from './PdfDocumentView/PdfDocumentView'
import Footer from './Footer/Footer.container'
import { DocumentPreviewEditor } from './DocumentPreviewEditor/DocumentPreviewEditor'
import { ButtonSquare, Icon, Loader } from 'components/common'

import { isEditorIsOwner } from 'utils'
import { documentsRoutes } from 'constants/routes'

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  background: theme.colorz.brand.main,
  height: '100vh',
  overflowY: 'hidden',
  position: 'relative',
  paddingTop: theme.spacing(6),
}))

interface Props {
  documentFile: IDocument
}

const POPUP_ON_CLOSE_IF_EDITED: IConfirmationPopupContent = {
  heading: 'Möchten Sie Ihre Änderungen speichern?',
  status: 'warning',
  primaryButtonText: 'Ja',
  secondaryButtonText: 'Nein',
}

const DocumentPreview: React.FC<Props> = ({ documentFile }) => {
  const user = useUser()!
  const toast = useToast()
  const update = useDocumentsUpdate()
  const paymentRequired = useIsTemplatePurchaseRequired(documentFile.templateId)
  const editor = useEditor()

  const { goBack, push } = useHistory()
  const { isEditor } = isEditorIsOwner(user.id, documentFile.authorId)
  const { gotDirty } = useDocumentFileActions()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { isExpired } = useSubscriptionCheck()

  if (!documentFile) return <Loader />

  const {
    type,
    templateId,
    id,
    settings,
    htmlData,
    answers,
    templateContentVersionId,
  } = documentFile

  const onClose = () => {
    const action = () => {
      localStorage.removeItem('initialDocValue')
      localStorage.removeItem('currentDocValue')
      goBack()
      close()
    }

    if (gotDirty()) {
      popup({
        ...POPUP_ON_CLOSE_IF_EDITED,
        onCancel: action,
        onConfirm: () => {
          setIsLoading(true)
          update.mutate(
            {
              id,
              data: {
                isDirty: true,
                htmlData: editor?.getBody()?.innerHTML ?? htmlData,
                settings,
                answers,
                templateContentVersionId,
              },
            },
            {
              onSuccess: action,
              onError: () => toast('error', 'default'),
              onSettled: () => setIsLoading(false),
            }
          )
        },
      })
    } else {
      action()
    }
  }

  let content = paymentRequired ? (
    <PurchaseTemplateModal
      open={paymentRequired}
      templateId={templateId}
      handleClose={() => push(documentsRoutes.main)}
      disableOutsideClickClose
    />
  ) : type === 'regular' ? (
    <DocumentPreviewEditor
      readonly={!isEditor && isExpired}
      documentFile={documentFile}
    />
  ) : type === 'pdf' ? (
    <PdfDocumentView documentFile={documentFile} />
  ) : null

  return (
    <Container>
      {/* {documentFile?.type !== 'pdf' && <DocumentSettingsModalContainer />} */}
      <ButtonSquare
        btntype='white'
        size={40}
        onClick={onClose}
        style={{ position: 'fixed', top: 16, left: 32, zIndex: 200 }}
      >
        <Icon iconName='close' />
      </ButtonSquare>
      {content}
      <Footer documentFile={documentFile} />
    </Container>
  )
}
export default DocumentPreview
