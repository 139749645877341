import React from 'react'
import { useHistory } from 'react-router'

import { documentsRoutes, ROUTE_UPGRADE_SUBSCRIPTION } from 'constants/routes'
import svgString from './illustration'
import { svgStringToDataUrl } from 'utils'
import ModalTemplate from '../ModalTemplate/ModalTemplate'
import { useModal } from 'hooks/useModal'
import { UI_TRIAL_EXPIRED_MODAL } from 'constants/ui-elements'

const image = svgStringToDataUrl(svgString)

const FreeTrialExpiredModal: React.FC = () => {
  const { push } = useHistory()
  const { closeModal } = useModal()

  const onAction = () => {
    push(ROUTE_UPGRADE_SUBSCRIPTION, { from: documentsRoutes.main })
    closeModal()
  }

  return (
    <ModalTemplate
      title='Ihr Testabo ist abgelaufen'
      text='Sie können weiterhin auf Ihre Dateien zugreifen, aber Sie müssen Ihr Abo aktualisieren, um wieder alle Funktionalitäten von CASUS nutzen zu können.'
      actionBtnText='Abo anpassen'
      image={image}
      btnWidth={175}
      onAction={onAction}
      onClose={() => closeModal(UI_TRIAL_EXPIRED_MODAL)}
    />
  )
}

export default FreeTrialExpiredModal
