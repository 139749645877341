import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { useToast } from 'hooks/useToast'

import NewPasswordForm from './NewPasswordForm.component'
import AuthApi from 'api/auth.api'
import {
  ERR_MSG_PASSWORD_CHARACTERS_COUNT,
  ERR_PASSWORDS_DONT_MATCH,
} from 'constants/messages'

const initialState = {
  password: '',
  passwordConfirm: '',
}

export type NewPasswordFormState = typeof initialState

const NewPasswordFormContainer: React.FC = () => {
  const location = useLocation()
  const { push } = useHistory()
  const toast = useToast()
  const [state, setState] = useState<NewPasswordFormState>(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.password !== state.passwordConfirm) {
      return toast('error', ERR_PASSWORDS_DONT_MATCH)
    }
    if (state.password.length < 8) {
      return toast('error', ERR_MSG_PASSWORD_CHARACTERS_COUNT)
    }

    const queries: string[] = location.search.split('&')
    const code = queries[0].split('code=')[1]
    const email = queries[1].split('email=')[1]

    const payload = {
      email,
      code,
      newPassword: state.password,
    }

    try {
      setIsLoading(true)
      await AuthApi.createNewPassword(payload)
      toast('success', 'Ihr Passwort wurde erfolgreich geändert')
      push('/login')
    } catch (err) {
      toast('error', 'default')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <NewPasswordForm
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      values={state}
      isLoading={isLoading}
    />
  )
}

export default NewPasswordFormContainer
