import { IconElProps } from './Icon.types'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { styled } from '@material-ui/core/styles'
import { ReactComponent as ArrowLeftSvg } from 'assets/icons/arrow-left.svg'
import { ReactComponent as ArrowLeftLongSvg } from 'assets/icons/arrow-left-long.svg'
import { ReactComponent as ArrowDownLongSvg } from 'assets/icons/arrow-down-long.svg'
import { ReactComponent as ArrowRightSvg } from 'assets/icons/arrow-right.svg'
import { ReactComponent as CalendarSvg } from 'assets/icons/calendar.svg'
import { ReactComponent as CheckboxSvg } from 'assets/icons/checkbox.svg'
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg'
import { ReactComponent as RadioSvg } from 'assets/icons/radio.svg'
import { ReactComponent as TextSvg } from 'assets/icons/text.svg'
import { ReactComponent as CloudSvg } from 'assets/icons/cloud.svg'
import { ReactComponent as AddSvg } from 'assets/icons/add.svg'
import { ReactComponent as CheckSvg } from 'assets/icons/check.svg'
import { ReactComponent as MoreSvg } from 'assets/icons/more.svg'
import { ReactComponent as TrashSvg } from 'assets/icons/trash.svg'
import { ReactComponent as ArrowDownFilledSvg } from 'assets/icons/arrow-down-filled.svg'
import { ReactComponent as ArrowUpFilledSvg } from 'assets/icons/arrow-up-filled.svg'
import { ReactComponent as NumberSvg } from 'assets/icons/number.svg'
import { ReactComponent as DeleteSvg } from 'assets/icons/delete.svg'
import { ReactComponent as EditSvg } from 'assets/icons/edit.svg'
import { ReactComponent as ShareSvg } from 'assets/icons/share.svg'
import { ReactComponent as PreviewSvg } from 'assets/icons/preview.svg'
import { ReactComponent as DownloadSvg } from 'assets/icons/download.svg'
import { ReactComponent as RestoreSvg } from 'assets/icons/restore.svg'
import { ReactComponent as MoveSvg } from 'assets/icons/move.svg'
import { ReactComponent as PdfSvg } from 'assets/icons/pdf.svg'
import { ReactComponent as WordSvg } from 'assets/icons/word.svg'
import { ReactComponent as DocxSvg } from 'assets/icons/docx.svg'
import { ReactComponent as RenameSvg } from 'assets/icons/rename.svg'
import { ReactComponent as FolderSvg } from 'assets/icons/folder.svg'
import { ReactComponent as AddFolderSvg } from 'assets/icons/add-folder.svg'
import { ReactComponent as SettingsSvg } from 'assets/icons/settings.svg'
import { ReactComponent as uploadSvg } from 'assets/icons/upload.svg'
import { ReactComponent as shared_withSvg } from 'assets/icons/shared_with.svg'
import { ReactComponent as listSvg } from 'assets/icons/list.svg'
import { ReactComponent as errorSvg } from 'assets/icons/error.svg'
import { ReactComponent as reminderSvg } from 'assets/icons/reminder.svg'
import { ReactComponent as duplicateSvg } from 'assets/icons/duplicate.svg'

const iconStyles = (fill: string | undefined): CreateCSSProperties => ({
  path: {
    fill: fill && fill + ' !important',
  },
  '& g': {
    '& path': {
      fill: fill && fill + ' !important',
    },
    '& use': {
      fill: fill && fill + ' !important',
    },
  },
})

export const ArrowLeft = styled(ArrowLeftSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const ArrowLeftLong = styled(ArrowLeftLongSvg)(({ fill }: IconElProps) =>
  iconStyles(fill)
)
export const ArrowDownLong = styled(ArrowDownLongSvg)(({ fill }: IconElProps) =>
  iconStyles(fill)
)
export const Calendar = styled(CalendarSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Checkbox = styled(CheckboxSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Close = styled(CloseSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Radio = styled(RadioSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Text = styled(TextSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const ArrowRight = styled(ArrowRightSvg)(({ fill }: IconElProps) =>
  iconStyles(fill)
)
export const Cloud = styled(CloudSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Add = styled(AddSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Check = styled(CheckSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const More = styled(MoreSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Trash = styled(TrashSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const ArrowDownFilled = styled(ArrowDownFilledSvg)(({ fill }: IconElProps) =>
  iconStyles(fill)
)
export const ArrowUpFilled = styled(ArrowUpFilledSvg)(({ fill }: IconElProps) =>
  iconStyles(fill)
)
export const Number = styled(NumberSvg)(({ fill }: IconElProps) => iconStyles(fill))

export const Delete = styled(DeleteSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Preview = styled(PreviewSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Download = styled(DownloadSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Share = styled(ShareSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Edit = styled(EditSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Restore = styled(RestoreSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Move = styled(MoveSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Rename = styled(RenameSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Folder = styled(FolderSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const AddFolder = styled(AddFolderSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Reminder = styled(reminderSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Duplicate = styled(duplicateSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const Settings = styled(SettingsSvg)(({ fill }: IconElProps) => ({
  ...iconStyles(fill),
  transition: 'all .2s',
  cursor: 'pointer',
  opacity: 0.85,
  '&:hover': {
    opacity: 1,
  },
}))
export const Upload = styled(uploadSvg)(({ fill }: IconElProps) => iconStyles(fill))

export const SharedWith = styled(shared_withSvg)(({ fill }: IconElProps) =>
  iconStyles(fill)
)
export const List = styled(listSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const PDF = styled(PdfSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const DOCX = styled(DocxSvg)(({ fill }: IconElProps) => iconStyles(fill))
export const WORD = styled(WordSvg)(({ fill }: IconElProps) => iconStyles(fill))

export const ErrorI = styled(errorSvg)(({ fill }: IconElProps) => iconStyles(fill))
