import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useMasterData } from 'queries/app/useMasterdata'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import SidebarContainer from 'components/Sidebar/Sidebar.container'

import { StorageService } from 'utils'
import { legalAdviceRoutes } from 'constants/routes'

const ClientSidebar: React.FC = () => {
  const { push } = useHistory()
  const { check } = useSubscriptionCheck()
  const allowBookLawyer = useMasterData()?.client?.lawyer_booking_open

  const [searchValue, setSearchValue] = useState('')

  const handleOpen = (): void => {
    if (!check()) return
    if (allowBookLawyer) {
      StorageService.remove('bookLawyer')
      push(legalAdviceRoutes.bookLawyer)
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSearchValue(e.target.value)

  return (
    <SidebarContainer
      actionButtonText='Neuer Beratungstermin'
      searchValue={searchValue}
      handleActionClick={allowBookLawyer ? handleOpen : undefined}
      handleSearchChange={handleSearchChange}
      isLoading={false}
      baseUrl='/rechtsberatung'
      urlLinks={[
        { url: '', urlName: 'Bevorstehend' },
        { url: '/verlauf', urlName: 'Verlauf' },
      ]}
    />
  )
}

export default ClientSidebar
