import { EventItemActionButtonProps } from './EventItem.types'
import React from 'react'
import { ButtonsWrapper } from './EventItem.styles'
import { CustomButton } from 'components/common'

const ActionButtons: React.FC<EventItemActionButtonProps> = ({
  handlePrimaryClick,
  handleSecondaryClick,
  primaryDisabled,
  open,
}) => {
  return (
    <ButtonsWrapper>
      <CustomButton
        btntype='secondary'
        onClick={handleSecondaryClick}
        disabled={false}
        size='large'
      >
        {!open ? 'Details' : 'Details schliessen'}
      </CustomButton>
      <CustomButton
        btntype='primary'
        onClick={handlePrimaryClick}
        disabled={primaryDisabled}
        size='large'
        data-intercom-target="start-call-intercom"
      >
        Teilnehmen
      </CustomButton>
    </ButtonsWrapper>
  )
}

export default ActionButtons
