import { ImageEditorProps } from './ImageEditor.types'
import React from 'react'
import AvatarEditor from 'react-avatar-editor'

export { AvatarEditor }

const ImageEditor = React.forwardRef(
  (props: ImageEditorProps, ref: React.Ref<AvatarEditor>) => {
    const { image, width, height, zoom, disableBoundaryChecks = false, style } = props
    return (
      <AvatarEditor
        image={image}
        ref={ref}
        width={width}
        height={height}
        border={0}
        color={[255, 255, 255, 0.6]}
        scale={zoom}
        rotate={0}
        disableBoundaryChecks={disableBoundaryChecks}
        style={style}
      />
    )
  }
)

export default ImageEditor
