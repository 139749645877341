import { DocumentSettingsPreviewHeaderComponent } from './HeaderSettings.types'
import React, { useState, useRef, useEffect } from 'react'
import {
  PreviewWrapper,
  ReuploadBtn,
  Column,
  HeaderPreviewWrapper,
} from './HeaderSettings.styles'
import ImageEditorSlider from 'components/common/ImageEditor/ImageEditorSlider'
import { toBase64 } from 'utils'

export const PreviewHeader: React.FC<DocumentSettingsPreviewHeaderComponent> = ({
  file,
  imageUrl,
  zoom,
  setZoom,
  handleReupload,
}) => {
  const imageRef = useRef<HTMLImageElement>(null)
  const [img, setImg] = useState(imageUrl || '')

  useEffect(() => {
    if (file) toBase64(file).then(src => setImg(src))
  }, [file])

  const handleChange = (e: any, newZoom: number | number[]) => {
    if (typeof newZoom === 'number') setZoom(newZoom)
  }

  return (
    <Column>
      <PreviewWrapper>
        <HeaderPreviewWrapper>
          {img && (
            <img
              src={img}
              alt=''
              style={{
                height: zoom,
                maxHeight: 100,
                width: 'auto',
                flexShrink: 0,
              }}
              ref={imageRef}
            />
          )}
        </HeaderPreviewWrapper>
      </PreviewWrapper>
      <ImageEditorSlider
        orientation='horizontal'
        title='Zoom'
        handleChange={handleChange}
        value={zoom}
        style={{ margin: '16px auto' }}
        min={10}
        max={100}
        step={1}
      />
      <ReuploadBtn onClick={handleReupload}>Logo ändern</ReuploadBtn>
    </Column>
  )
}
