import DocsApi from 'api/docs.api'
import { useQuery } from 'react-query'
import { queryKeys } from 'queries/queryKeys'
import { TQueryOptions } from 'queries'
import { IDocument } from 'types'
import { useToast } from 'hooks/useToast'
import { useUser } from 'queries/user/useUser'

export const useDocument = (id: string | null | undefined, options: TQueryOptions<IDocument | null> = {}) => {
  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice
  const toast = useToast()
  const query = useQuery([queryKeys.DOCUMENT, id], () => (id ? DocsApi.getOne(id, docXMicro) : null), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
    onError: () => {
      toast('error', 'default')
    },
  })
  return query
}
