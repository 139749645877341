import { Box, styled } from '@material-ui/core'
import { TextBold } from '../DocumentSettings.styles'

export const Container = styled(Box)(({ theme }) => ({
  background: theme.colorz.secondary[50],
  height: 376,
  maxHeight: 376,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const common = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  borderWidth: 2,
  borderStyle: 'dashed',
  borderRadius: 3,
}

export const UploadLogoContainer = styled(Box)(({ theme }) => ({
  ...common,
  height: 190,
  borderColor: theme.colorz.secondary[100],
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}))

export const PreviewWrapper = styled(Box)(({ theme }) => ({
  ...common,
  height: 'auto',
  flexDirection: 'column',
  borderColor: theme.colorz.secondary[100],
}))

export const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const ReuploadBtn = styled(TextBold)(({ theme }) => ({
  margin: '16px auto 0 auto',
  width: 'max-content',
  cursor: 'pointer',
  opacity: 0.8,
  marginTop: theme.spacing(5),
  transition: 'all .2s',
  '&:hover': {
    opacity: 1,
    transform: 'translateX(-1px)',
  },
}))

export const HeaderPreviewWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: 2,
  height: 114,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderBottom: `2px solid ${theme.colorz.brand.main}`,
  padding: theme.spacing(0, 2),
}))
