import { IConfirmationPopupContent } from 'types'

export const POPUP_CONFIRM_DELETE_PROFILE: IConfirmationPopupContent = {
  heading: 'Sind Sie sicher, dass Sie Ihr Konto endgültig löschen möchten?',
  description:
    'Bitte senden Sie uns ein E-Mail an contact@casus.ch, damit wir uns persönlich um Ihr Anliegen kümmern können.',
  status: 'warning',
  primaryButtonText: 'Okay',
}

export const POPUP_CONFIRM_TRASH_FILE: IConfirmationPopupContent = {
  heading: 'Sind Sie sicher, dass Sie diese Datei in den Papierkorb verschieben möchten?',
  status: 'warning',
  primaryButtonText: 'Ja, verschieben',
  secondaryButtonText: 'Nein, nicht verschieben',
}

export const POPUP_CONFIRM_DELETE_FILE: IConfirmationPopupContent = {
  heading:
    'Sind Sie sicher, dass Sie diese Datei für immer und unwiderruflich löschen möchten?',
  status: 'warning',
  primaryButtonText: 'Ja, endgültig löschen',
  secondaryButtonText: 'Nein, nicht löschen',
}

export const POPUP_CONFIRM_DELETE_ALL_FILES: IConfirmationPopupContent = {
  heading:
    'Sind Sie sicher, dass Sie alle Dateien aus dem Papierkorb für immer und unwiderruflich löschen möchten?',
  status: 'warning',
  primaryButtonText: 'Ja, endgültig löschen',
  secondaryButtonText: 'Nein, nicht löschen',
}

export const POPUP_CONFIRM_DELETE_HEADER: IConfirmationPopupContent = {
  heading:
    'Sind Sie sicher, dass Sie diese Kopfzeile für immer und unwiderruflich löschen möchten?',
  status: 'warning',
  primaryButtonText: 'Ja, endgültig löschen',
  secondaryButtonText: 'Nein, nicht löschen',
}

export const POPUP_BROWSER_VERSION: IConfirmationPopupContent = {
  heading: 'Browser aktualisieren',
  description:
    'Die CASUS-App läuft am besten mit der aktuellsten Browser-Version. Bitte aktualisieren Sie Ihren Browser, um mögliche Probleme zu vermeiden.',
  status: 'warning',
  primaryButtonText: 'Browser updaten',
  secondaryButtonText: 'Nicht jetzt',
}

export const POPUP_TC_KEEP_STYLES_ON_PASTE: IConfirmationPopupContent = {
  heading: 'Textformatierung',
  description: 'Möchten Sie die Formatierung aus Ihrer Originalvorlage beibehalten?',
  status: 'warning',
  primaryButtonText: 'Ja, behalten',
  secondaryButtonText: 'Nicht behalten',
}
