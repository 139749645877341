import {
  ISubscriptionPlanTableItem,
  ISubscriptionPlanTableSection,
} from './TableRowSection'

const item1: ISubscriptionPlanTableItem = {
  text: 'Zugang zu 20+ anwaltlichen CASUS-Vorlagen',
  startup: 'Bis zu 5 pro Monat',
  basic: true,
  team: true,
  enterprise: true,
}

const item1_1: ISubscriptionPlanTableItem = {
  text: 'Laufend neue anwaltliche CASUS-Vorlagen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item2: ISubscriptionPlanTableItem = {
  text: 'Unlimitiert Verträge und andere Rechtsdokumente erstellen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item3: ISubscriptionPlanTableItem = {
  text: 'Unlimitiert Dokumente als PDF herunterladen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item4: ISubscriptionPlanTableItem = {
  text: 'Formatierung mit Editor anpassen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item5: ISubscriptionPlanTableItem = {
  text: 'Updates der CASUS-Vorlagen bei Rechtsänderungen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item6: ISubscriptionPlanTableItem = {
  text: 'Branding mit Unternehmenslogo hinzufügen',
  startup: false,
  basic: true,
  team: true,
  enterprise: true,
}

const row1: ISubscriptionPlanTableSection = {
  title: 'Dokumente erstellen',
  items: [item1, item1_1, item2, item3, item4, item5, item6],
}

const item7: ISubscriptionPlanTableItem = {
  text: 'Unlimitiert eigene Vorlagen erstellen',
  startup: false,
  basic: false,
  team: true,
  enterprise: true,
}

const item8: ISubscriptionPlanTableItem = {
  text: 'Formatierung mit Editor anpassen',
  startup: false,
  basic: false,
  team: true,
  enterprise: true,
}

const item9: ISubscriptionPlanTableItem = {
  text: 'Unlimitiert Dokumente aus eigenen Vorlagen erstellen',
  startup: false,
  basic: false,
  team: true,
  enterprise: true,
}

const row2: ISubscriptionPlanTableSection = {
  title: 'Vorlagen erstellen',
  items: [item7, item8, item9],
}

const item10: ISubscriptionPlanTableItem = {
  text: 'Zugang zu unserem professionellen Anwaltsnetzwerk',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item11: ISubscriptionPlanTableItem = {
  text: 'Persönliche Erstberatung für CHF 40 buchen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item12: ISubscriptionPlanTableItem = {
  text: 'Rechtsberatung, wenn möglich zum Fixpreis, offerieren lassen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const row3: ISubscriptionPlanTableSection = {
  title: 'Rechtsberatung buchen',
  items: [item10, item11, item12],
}

const item13: ISubscriptionPlanTableItem = {
  text: 'Dokumente abspeichern und organisieren',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item14: ISubscriptionPlanTableItem = {
  text: 'Erinnerungen für Termine und Fristen einrichten',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item15: ISubscriptionPlanTableItem = {
  text: 'Dateien teilen',
  startup: false,
  basic: false,
  team: true,
  enterprise: true,
}

const item16: ISubscriptionPlanTableItem = {
  text: 'Externe Dateien hochladen und abspeichern',
  startup: false,
  basic: false,
  team: true,
  enterprise: true,
}

const item17: ISubscriptionPlanTableItem = {
  text: 'Dokumente kommentieren',
  startup: false,
  basic: false,
  team: 'soon',
  enterprise: 'soon',
}

const item18: ISubscriptionPlanTableItem = {
  text: 'Online zusammenarbeiten',
  startup: false,
  basic: false,
  team: 'soon',
  enterprise: 'soon',
}

const item19: ISubscriptionPlanTableItem = {
  text: 'Online Dokumente unterschreiben',
  startup: false,
  basic: false,
  team: 'soon',
  enterprise: 'soon',
}

const row4: ISubscriptionPlanTableSection = {
  title: 'Dokumentenmanagement',
  items: [item13, item14, item15, item16, item17, item18, item19],
}

const item20: ISubscriptionPlanTableItem = {
  text: 'Anzahl Nutzerkonten',
  startup: '1',
  basic: '5',
  team: 'Bis zu 20',
  enterprise: 'Mehr als 20',
}

const item21: ISubscriptionPlanTableItem = {
  text: 'Teammitglieder verwalten',
  startup: false,
  basic: true,
  team: true,
  enterprise: true,
}

const item22: ISubscriptionPlanTableItem = {
  text: 'Bezahlung mit Kreditkarte',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item23: ISubscriptionPlanTableItem = {
  text: 'Bezahlung per Rechnung',
  startup: false,
  basic: false,
  team: false,
  enterprise: true,
}

const row5: ISubscriptionPlanTableSection = {
  title: 'Dokumentenmanagement',
  items: [item20, item21, item22, item23],
}

const item24: ISubscriptionPlanTableItem = {
  text: 'Kostenlose Demo über Online-Anruf buchen',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item25: ISubscriptionPlanTableItem = {
  text: 'Hilfreiche Artikel in unserem CASUS Help Center finden',
  startup: true,
  basic: true,
  team: true,
  enterprise: true,
}

const item26: ISubscriptionPlanTableItem = {
  text: 'Professionelle technische Hilfe über Chat erhalten',
  startup: false,
  basic: true,
  team: true,
  enterprise: true,
}

const item27: ISubscriptionPlanTableItem = {
  text: 'Persönliche technische Hilfe über Online-Anruf erhalten',
  startup: false,
  basic: true,
  team: true,
  enterprise: true,
}

const item28: ISubscriptionPlanTableItem = {
  text: 'Zugang zu themenspezifischen CASUS-Webinaren',
  startup: false,
  basic: false,
  team: true,
  enterprise: true,
}

const item29: ISubscriptionPlanTableItem = {
  text: 'Persönliches Onboarding',
  startup: false,
  basic: false,
  team: true,
  enterprise: true,
}

const item30: ISubscriptionPlanTableItem = {
  text: 'Persönliche*r Support-Agent/in',
  startup: false,
  basic: false,
  team: false,
  enterprise: true,
}

const row6 = {
  title: 'Support',
  items: [item24, item25, item26, item27, item28, item29, item30],
}

const data = [row1, row2, row3, row4, row5, row6]
export default data
