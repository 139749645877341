import { ActionButtonProps } from './ProfileInfo.types'
import React from 'react'
import { ActionButtonsContainer } from './ProfileInfo.styles'
import { ButtonSquare, CustomButton, Icon } from 'components/common'

const ActionButtons: React.FC<ActionButtonProps> = ({
  handleBackClick,
  handleForwardClick,
  step,
  isLoading,
  nextDisabled,
}) => {
  return (
    <ActionButtonsContainer>
      {step > 0 && (
        <ButtonSquare onClick={handleBackClick} btntype='transparent' size={48}>
          <Icon iconName='arrow-left' />
        </ButtonSquare>
      )}
      <CustomButton
        type='submit'
        size='large'
        btntype='primary'
        onClick={() => handleForwardClick()}
        width={140}
        disabled={isLoading || nextDisabled}
      >
        Weiter <Icon iconName='arrow-right' fill='#fff' style={{ marginLeft: 'auto' }} />
      </CustomButton>
    </ActionButtonsContainer>
  )
}

export default ActionButtons
