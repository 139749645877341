import { styled, Theme, Typography } from '@material-ui/core'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,
  boxShadow: '0px 1px 0px #F3EEEA',
  maxWidth: 1024,
  backgroundColor: '#fff',
  height: 64,
  padding: theme.spacing(0, 1.5, 0, 4.5),
  flexShrink: 0,
  position: 'relative',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export const Col = styled('div')({ display: 'flex', flexDirection: 'column', flex: 1 })

interface ITextEl {
  theme: Theme
  colorz: '200' | '300' | 'main'
  entityname?: string
}

export const Text = styled(Typography)(({ theme, colorz }: ITextEl) => ({
  color: theme.colorz.secondary[colorz],
}))

export const EntityName = styled(Typography)(
  ({ theme, colorz, entityname }: ITextEl) => ({
    color: theme.colorz.secondary[colorz],
    whiteSpace: 'nowrap',
    position: 'relative',
    '&::before': {
      display: 'none',
      content: 'attr(entityname)',
      padding: theme.spacing(1, 1.5),
      position: 'absolute',
      top: -40,
      borderRadius: 2,
      boxShadow: '0px 1px 0px #F3EEEA',
      fontSize: 14,
      fontFamily: 'inherit',
      background: '#fff',
    },
    '&:hover': {
      '&::before': {
        display: 'block',
      },
    },
  })
)
