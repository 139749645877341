import React from 'react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { StylesProvider } from '@material-ui/styles'
import theme from './theme'

const AppThemeProvider: React.FC = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StylesProvider>
  )
}

export default AppThemeProvider
