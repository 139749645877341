import * as UpgradeAction from './UpgradeSubscriptionPlanModal'
import Success from './UpgradeSubscriptionSuccess/SubscriptionUpgradedModal'
import Expired from './FreeTrialExpiredModal/FreeTrialExpiredModal'

const components = {
  Basic: UpgradeAction.UpgradeSubscriptionPlanModalBasic,
  NotPaid: UpgradeAction.UpgradeSubscriptionPlanModalNotPaid,
  Success,
  Expired,
  New: UpgradeAction.UpgradeSubscriptionPlanModalNewSubscription,
}

export default components
