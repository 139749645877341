import { IReminder } from 'types'
import { useQuery } from 'react-query'
import RemindersApi from 'api/reminders'
import { queryKeys, TQueryOptions } from 'queries'

export const useLoadReminders = (options: TQueryOptions<IReminder[]> = {}) => {
  const query = useQuery(queryKeys.DOCUMENT_REMINDERS, RemindersApi.get, {
    refetchOnWindowFocus: false,
    ...options,
  })

  return query
}
