import { Question } from 'types'
import { extractLocationsAndSubquestions } from './extractLocationsAndSubquestions'
type ReturnType = Question | undefined

export const deepFindParentFromCurrentQuestion = (
  parentQuestion: Question,
  theQuestion: Question
): ReturnType => {
  // find parent question from the question
  const theParentId = parentQuestion.id
  if (theParentId === theQuestion.parentId) return parentQuestion
  let _theParent: ReturnType = { ...parentQuestion }

  if (theQuestion.parentId) {
    const ids = theQuestion.parentId.split('--')
    let _theId = ids.pop()

    while (_theId !== theParentId && ids.length) {
      _theId = ids.pop()
    }

    _theId = ids.pop()

    while (_theId && _theParent) {
      const [, subquestions] = extractLocationsAndSubquestions(_theParent)
      const idToLookFor = _theId
      _theParent = subquestions.find(subqItem => subqItem.id === idToLookFor)
      _theId = ids.pop()
    }
  }

  return _theParent
}
