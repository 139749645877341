import React, { useState } from 'react'
import { Container, Square } from './SwitchButton.styles'
import { Icon } from 'components/common'

interface SwitchButtonComponentProps {
  style?: React.CSSProperties
  on: boolean
  onChange: (val: boolean) => void
}

export const SwitchButton: React.FC<SwitchButtonComponentProps> = ({
  on,
  style,
  onChange,
}) => {
  const [active, setActive] = useState(on)

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setActive(prev => !prev)
    onChange(!active)
  }

  return (
    <Container on={active ? 1 : 0} onClick={handleClick} style={style}>
      <Square on={active ? 1 : 0}>
        <Icon iconName='check' fill='#fff' style={{ opacity: active ? 1 : 0 }} />
      </Square>
    </Container>
  )
}
