import React, { useState, useEffect } from 'react'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useEditor } from 'config/textEditor/useEditor'

import PopoverActions from './PopoverActions'
import { TemplateCreationEditor } from './TemplateCreationEditor'

import {
  Container,
  DocumentWrapper,
  DocumentText,
  Spinner,
  noTemplateStyles,
} from './EditorView.styles'

export interface BoxPositionConfig {
  x1: number
  x2: number
  y1: number
  y2: number
}

interface Props {
  isLoading: boolean
  withNav: boolean
  questionStep: number
  handleConfirmTextSelection: React.MouseEventHandler<HTMLButtonElement>
  handleCancel: React.MouseEventHandler<HTMLButtonElement>
  handleConfirmDeleteSelection: (locationId: string) => void
}

const DocumentView: React.FC<Props> = props => {
  const { htmlData, textSelectionIsActive } = useTemplateCreation()
  const editor = useEditor()

  const {
    isLoading,
    withNav,
    handleConfirmTextSelection,
    handleCancel,
    handleConfirmDeleteSelection,
  } = props

  const [boxPosition, setBoxPosition] = useState<BoxPositionConfig>({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  })

  const [highlightToDelete, setHighlightToDelete] = useState<string | null>(null)
  const [selectionExists, setSelectionExists] = useState(false)

  const onConfirmDeleteHighlight = (): void => {
    if (!highlightToDelete) return
    handleConfirmDeleteSelection(highlightToDelete)
    setHighlightToDelete(null)
  }

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    setHighlightToDelete(null)
    setSelectionExists(false)
    handleCancel(e)
  }

  const isSomethingSelected = Boolean(
    editor?.selection
      ?.getSel()
      ?.toString()
      .length
  )

  useEffect(() => {
    if (isSomethingSelected && !selectionExists) {
      setSelectionExists(true)
    } else if (!isSomethingSelected && selectionExists) {
      setSelectionExists(false)
    }
  }, [isSomethingSelected, selectionExists])

  const { x1, x2, y1, y2 } = boxPosition
  const greaterX = x1 > x2 ? x1 : x2
  const smallerX = x1 < x2 ? x1 : x2
  const yPosition = y1 >= y2 ? y1 : y2
  const xPosition = smallerX + (greaterX - smallerX) / 2

  let popoverComponent = null

  // console.log("SELECTION EXISTS: ", selectionExists)
  // console.log("HIGHLIGHT TO DELETE: ", highlightToDelete)

  if (textSelectionIsActive)
    if (highlightToDelete)
      popoverComponent = (
        <PopoverActions
          handleCancel={onCancel}
          handleConfirm={onConfirmDeleteHighlight}
          yposition={yPosition < 80 ? yPosition + 45 : yPosition + 32}
          xposition={xPosition}
          variant='delete'
        />
      )
    else if (selectionExists) {
      popoverComponent = (
        <PopoverActions
          handleCancel={onCancel}
          handleConfirm={handleConfirmTextSelection}
          yposition={yPosition < 80 ? yPosition + 100 : yPosition + 90}
          xposition={xPosition}
          variant='selection'
        />
      )
    }

  return (
    <Container nav={withNav ? 1 : 0}>
      {/* {questionStep === 0 && (
        <CustomButton
          btntype='secondary'
          size='small'
          style={{ position: 'absolute', top: 60, right: 4, zIndex: 10 }}
          onClick={() => setPreviewTemplate(prev => !prev)}
        >
          Switch
        </CustomButton>
      )} */}
      {isLoading && <Spinner size={70} />}
      {!isLoading && (
        <DocumentWrapper
          document={htmlData ? 1 : 0}
          selectingisactive={textSelectionIsActive ? 1 : 0}
          style={!htmlData ? noTemplateStyles : {}}
        >
          {popoverComponent}
          <DocumentText data-intercom-target='master-editor'>
            <TemplateCreationEditor
              setBoxPosition={setBoxPosition}
              setHighlightToDelete={setHighlightToDelete}
              setSelectionExists={setSelectionExists}
              highlightToDelete={highlightToDelete}
            />
          </DocumentText>
        </DocumentWrapper>
      )}
    </Container>
  )
}

export default DocumentView
