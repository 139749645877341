import { FolderItemSimple, IPermissions } from 'types'
import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router'
import { useQueryClient } from 'react-query'
import Validator from 'validator'

import { useUser } from 'queries/user/useUser'
import { useFetchFolder, useFolderKeys, useUpdateFolder } from 'queries/folders'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useToast } from 'hooks/useToast'

import { SharingModalComponent } from 'components/files/FileAndFolderActions/share/SharingModal.component'
import FoldersApi from 'api/folders.api'

import {
  MESSAGE_INVALID_EMAIL,
  ERR_MSG_DEFAULT,
  ERR_MSG_NO_USER_WITH_EMAIL,
} from 'constants/messages'

interface Props {
  where: 'templates' | 'docs'
  shareFolderId: string
}

export const FolderSharing: React.FC<Props> = ({ where, shareFolderId }) => {
  const queryClient = useQueryClient()
  const { location, replace } = useHistory()
  const { currentFolder, onEditFolder } = useCurrentFolder(where)

  const { data: folder, isLoading } = useFetchFolder(where, shareFolderId)
  const update = useUpdateFolder(where, currentFolder)

  const editingFolder = currentFolder.children.find(f => f.id === shareFolderId)
  const [many, single] = useFolderKeys(where)

  const userId = useUser()?.id ?? '123'
  const toast = useToast()
  const [addSharingLoading, setAddSharingLoading] = useState(false)
  const [error, setError] = useState(false)

  const setAndInvalidate = (updatedFolder: FolderItemSimple) => {
    queryClient.setQueryData([single, updatedFolder.id], updatedFolder)
    queryClient.setQueryData<FolderItemSimple[]>([many, currentFolder.id], data => {
      return (data || [])?.map(f => (f.id === updatedFolder.id ? updatedFolder : f))
    })
    onEditFolder(updatedFolder)
  }

  const onShare = async (email: string) => {
    if (!folder) return
    try {
      if (error) setError(false)
      const alreadyShared = folder?.sharedWith.find(sh => sh.email === email.trim())
      if (alreadyShared) {
        toast(
          'warning',
          `Sie haben dieses Folder schon mit ${alreadyShared.firstName} ${alreadyShared.lastName} geteilt.`
        )
        return
      }
      setAddSharingLoading(true)
      const res = await FoldersApi.share(where, shareFolderId, email)
      setAndInvalidate(res)
    } catch (err) {
      setError(true)
      let ERROR_MESSAGE = ERR_MSG_DEFAULT
      if (['E0346', 'E0407', 'E0406'].includes(err?.response?.data?.error_code)) {
        ERROR_MESSAGE = ERR_MSG_NO_USER_WITH_EMAIL
      }
      toast('error', ERROR_MESSAGE)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const onSwitch = (val: boolean): void => {
    if (!folder) return
    update.mutate({ id: shareFolderId, data: { sharingEnabled: val } })
  }

  const onPermissionsEdit = async (userId: string, permissions: IPermissions) => {
    if (!folder) return
    try {
      setAddSharingLoading(true)
      const res = await FoldersApi.permissionsUpdate(
        where,
        folder.id,
        userId,
        permissions
      )
      setAndInvalidate(res)
    } catch (err) {
      console.error(err)
      toast('error', ERR_MSG_DEFAULT)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const onPermissionsDelete = async (userId: string) => {
    if (!folder) return
    try {
      setAddSharingLoading(true)
      const res = await FoldersApi.permissionsDelete(where, folder.id, userId)
      setAndInvalidate(res)
    } catch (err) {
      console.error(err)
      toast('error', ERR_MSG_DEFAULT)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const validateEmail = (email: string): boolean => {
    const isEmail = Validator.isEmail(email)
    if (!isEmail) {
      toast('error', MESSAGE_INVALID_EMAIL)
    }
    return isEmail
  }

  if (!shareFolderId) return <Redirect to={location.pathname} />

  return (
    <SharingModalComponent
      open={!!shareFolderId}
      getLoading={isLoading || !folder}
      updateLoading={update.isLoading}
      sharingEnabled={!!editingFolder?.sharingEnabled}
      userId={userId}
      sharedWith={folder?.sharedWith! || []}
      addSharingLoading={addSharingLoading}
      author={folder?.author ?? null}
      error={error}
      validateEmail={validateEmail}
      handleClose={() => replace(location.pathname)}
      handleSwitchChange={onSwitch}
      handleShare={onShare}
      handleFinishClick={() => replace(location.pathname)}
      handleChangePermissions={onPermissionsEdit}
      handleDeletePerson={onPermissionsDelete}
    />
  )
}
