import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { dataStructureType, questionsType } from 'utilities/propTypes'
import { generatePageLayoutString, generateStyleString, parseStructure, updateStateDataStructure } from './helpers'

import { WizardProvider } from './WizardContext'
import { Header, Footer, QuestionGeneration, Questionnaire, Editor, Configuration } from './components'

export const classes = {
  wrapper: 'Wizard-wrapper',
  content: 'Wizard-content',
}

const consoleStyleOrange = 'color: orange; font-size: 14px; font-weight: 700;'
const consoleStyleWhite = 'color: white; font-size: 12px; font-weight: 100;'
const consoleStyleItalic = 'color: rgba(255,255,255,0.7); font-size: 12px; font-weight: 700; font-style: italic;'

export const Wizard = React.memo(props => {
  //
  //
  // ::::::::::::::::::::::::: HOOKS :::::::::::::::::::::::: //
  const [stateDataStructure, setStateDataStructure] = useState(null)
  const [
    stateQuestions,
    // setStateQuestions
  ] = useState(null)
  const [
    stateAnswers,
    // setStateAnswers
  ] = useState(null)
  // const [dirty, setDirty] = useState(false)
  const [configuring, setConfiguring] = useState(null)
  const { push } = useHistory()
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::: //
  //
  //
  //
  // ::::::::::::::::::::::: VARIABLES :::::::::::::::::::::: //
  const {
    dataStructure: initialDataStructure,
    questions: initialQuestions,
    answers: initialAnswers,
    cssData,
    mode = 'template-creation',
  } = props
  const questions = useMemo(() => stateQuestions || initialQuestions || [], [initialQuestions, stateQuestions])
  const answers = useMemo(() => stateAnswers || initialAnswers || [], [initialAnswers, stateAnswers])
  const dataStructure = useMemo(() => {
    if (process.env.NODE_ENV === 'development')
      console.group(`%cWIZARD %c(${mode}) %c[body]`, consoleStyleOrange, consoleStyleWhite, consoleStyleItalic)
    const tempDataStructure = stateDataStructure || initialDataStructure || {}
    const res = parseStructure(tempDataStructure, questions, answers, true)
    if (process.env.NODE_ENV === 'development') console.groupEnd()
    return res
  }, [mode, initialDataStructure, stateDataStructure, questions, answers])
  const { documentSettings } = dataStructure
  const styleSheet = useMemo(() => {
    const pageLayoutString = generatePageLayoutString(documentSettings)
    const styleString = generateStyleString(cssData)
    return [pageLayoutString, styleString].join('\n')
  }, [documentSettings, cssData])
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::: //
  //
  //
  //
  // ::::::::::::::::::::::: CALLBACKS :::::::::::::::::::::: //
  const updateDataStructure = useCallback(
    (segment, field = 'segments') => updateStateDataStructure(setStateDataStructure, dataStructure, segment, field),
    [dataStructure]
  )
  const onCloseHandler = useCallback(
    () => push(mode === 'template-creation' ? '/vorlagen' : '/aktenschrank'),
    [mode, push]
  )
  const onFinishHandler = useCallback(() => console.log('FINISHED!'), [])
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::: //
  //
  //

  const wizardProviderValue = useMemo(
    () => ({
      updateDataStructure,
    }),
    [updateDataStructure]
  )

  const leftSideComponent = useMemo(() => {
    const Component = mode === 'template-creation' ? QuestionGeneration : Questionnaire
    return <Component shift={!!configuring} />
  }, [mode, configuring])

  useEffect(() => {
    const styleElement = document.createElement('style')
    styleElement.setAttribute('type', 'text/css')
    styleElement.innerHTML = styleSheet
    document.head.appendChild(styleElement)
    return () => document.head.removeChild(styleElement)
  }, [styleSheet])

  if (process.env.NODE_ENV === 'development') console.groupEnd()

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.group(`%cWIZARD %c(${mode}) %c[useEffect]`, consoleStyleOrange, consoleStyleWhite, consoleStyleItalic)
      console.groupCollapsed('VARIABLES:')
      console.log('DATA STRUCTURE: ', dataStructure)
      console.groupCollapsed(`QUESTIONS [${questions.length}]:`)
      questions.forEach((question, i) => console.log(`QUESTION-${i}: `, question))
      console.groupEnd()
      console.groupCollapsed(`ANSWERS [${answers.length}]:`)
      answers.forEach((answer, i) => console.log(`ANSWER-${i}: `, answer))
      console.groupEnd()
      console.groupCollapsed('STYLE SHEET:')
      styleSheet.split('\n').forEach(entry => console.log(entry))
      console.groupEnd()
      console.groupEnd()
      console.groupEnd()
    }
  }, [mode, dataStructure, questions, answers, styleSheet])

  return (
    <main className={classes.wrapper}>
      <Header onCloseHandler={onCloseHandler} />
      <section className={classes.content}>
        <WizardProvider value={wizardProviderValue}>
          {leftSideComponent}
          <Editor dataStructure={dataStructure} />
          <Configuration configuring={configuring} />
        </WizardProvider>
      </section>
      <Footer configuring={configuring} configuringHandler={setConfiguring} onFinishHandler={onFinishHandler} />
    </main>
  )
})

Wizard.displayName = 'Wizard'

Wizard.propTypes = {
  dataStructure: dataStructureType(),
  cssData: PropTypes.object,
  questions: questionsType(),
  // answers: PropTypes.arrayOf(PropTypes.shape({
  //   id:
  // }))
  mode: PropTypes.oneOf(['template-creation', 'document-generation']),
}

export default Wizard
