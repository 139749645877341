import { loadStripe, StripeConstructorOptions } from '@stripe/stripe-js'
import React, { useRef } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { useMasterData } from 'queries/app/useMasterdata'
interface Props {
  options?: StripeConstructorOptions
}

const withStripe = (Component: React.FC<Props>) => {
  const Wrapper: React.FC<Props> = ({ options, ...props }) => {
    const test_mode = useMasterData()?.stripe.test_mode
    const stripeKey = test_mode
      ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST!
      : process.env.REACT_APP_STRIPE_PUBLIC_KEY!
    const stripePromise = useRef(loadStripe(stripeKey, options))

    return (
      <Elements stripe={stripePromise.current}>
        <Component {...props} />
      </Elements>
    )
  }
  return Wrapper
}

export default withStripe
