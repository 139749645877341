import { IPagesLayout } from './PagesLayout.types'
import React, { useRef, useState } from 'react'
import HeaderContainer from 'layout/PagesLayout/Header/Header.container'
import { Loader, MenuPopover } from 'components/common'
import { Container, ContentContainer } from './PagesLayout.styles'
import { PageAssetsContainer } from 'pages/Authenticated/Authenticated.page.styles'
import BreadcrumbNavigation from 'components/files/Folder/BreadcrumbNavigation/BreadcrumbNavigation'

const PagesLayout: React.FC<IPagesLayout> = ({
  children,
  isLoading,
  menuItems,
  hideHeader,
  breadcrumbNavProps,
  PageAssetsComponent,
  style,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [x, setX] = useState<number>(0)
  const [y, setY] = useState<number>(0)
  const [headerOpacity, setHeaderOpacity] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!menuItems) return
    e.persist()
    e.preventDefault()

    const children = Array.from(e.currentTarget.childNodes)
    if (!children.includes(e.target as HTMLDivElement)) return

    const width = e.currentTarget.clientWidth
    const rect = e.currentTarget.getBoundingClientRect()
    const xPos = e.clientX - rect.left //x position within the element.
    const yPos = e.clientY - rect.top

    const theX = xPos >= width / 1.5 ? xPos + 140 : xPos + 400
    setX(theX)
    setY(yPos)
    setAnchorEl(e.currentTarget)
  }

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const el = containerRef.current
    if (!el) return
    const y = el.scrollTop
    if (y > 101) {
      setHeaderOpacity(1)
    } else if (y > 80) {
      setHeaderOpacity(0.8)
    } else if (y > 60) {
      setHeaderOpacity(0.6)
    } else if (y > 40) {
      setHeaderOpacity(0.4)
    } else if (y <= 40) {
      setHeaderOpacity(0)
    }
  }

  return (
    <Container style={style} onScroll={onScroll} ref={containerRef}>
      {!hideHeader && <HeaderContainer headerOpacity={headerOpacity} />}
      <ContentContainer onContextMenu={handleContextMenu}>
        <PageAssetsContainer>
          {breadcrumbNavProps && <BreadcrumbNavigation {...breadcrumbNavProps} />}
          {PageAssetsComponent}
        </PageAssetsContainer>
        {isLoading ? <Loader /> : children}
      </ContentContainer>
      {menuItems && menuItems.length && x ? (
        <MenuPopover
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          menuItems={menuItems}
          anchorPosition={{ top: y + 36, left: x }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      ) : null}
    </Container>
  )
}

export default PagesLayout
