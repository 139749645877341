import { QuestionContainerEl } from './Question.types'
import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { Icon } from 'components/common'

export const Text = styled(Typography)(({ theme }) => ({
  '&:first-child': {
    width: '45%',
    flexShrink: 0,
    fontWeight: 400,
  },
  color: theme.colorz.secondary.main,
}))

export const QuestionContainer = styled(Box)(
  ({ theme, disabled }: QuestionContainerEl) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2.5),
    backgroundColor: theme.colorz.brand.main,
    opacity: disabled ? 0.7 : 1,
    minHeight: 48,
    marginBottom: theme.spacing(1.5),
    pointerEvents: disabled ? 'none' : 'auto',
    position: 'relative',
  })
)

export const AnswersWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
})

export const StyledIcon = styled(Icon)({
  opacity: 0.6,
  transition: 'all .2s',
  cursor: 'pointer',
  marginLeft: 'auto',
  '&:hover': {
    opacity: 1,
  },
})

export const Dot = styled(Box)(({ theme }) => ({
  height: 16,
  width: 16,
  borderRadius: '50%',
  backgroundColor: '#ffa773',
  position: 'absolute',
  top: -8,
  right: -8,
  border: '3px solid #fff',
}))
