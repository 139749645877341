import PropTypes from 'prop-types'
import { CHOICE_TYPES, REPLACEMENT_TYPES } from 'TemplateCreation-DocumentGeneration/parsing'

import { SEGMENT_TAGS, SEGMENT_TYPES } from 'utilities/parsing'
import { ORIENTATION, PAPER_NAMES } from './hardcoded/documentSettings'

export const numberingSystemType = () => (props, key, componentName) => {
  // console.log('NUMBERING SYSTEM TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          prefix: PropTypes.string,
          type: PropTypes.string.isRequired,
          suffix: PropTypes.string,
          combined: PropTypes.bool,
          combineString: PropTypes.string,
        })
      )
    ).isRequired,
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const tableRowType = () => (props, key, componentName) => {
  // console.log('TABLE ROW TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.shape({
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          content: segmentsType(),
          class: PropTypes.string,
        })
      ).isRequired,
      class: PropTypes.string,
    }),
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const segmentsType = () => (props, key, componentName) => {
  // console.log('SEGMENTS TYPE: ', props, key, componentName)
  const tempPropStructure = {
    [key]: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(SEGMENT_TYPES)).isRequired,
        tag: PropTypes.oneOf(Object.values(SEGMENT_TAGS)).isRequired,
        class: PropTypes.string,
        textChunks: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            styles: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        tableHeader: PropTypes.arrayOf(tableRowType()),
        tableBody: PropTypes.arrayOf(tableRowType()),
        tableFooter: PropTypes.arrayOf(tableRowType()),
      })
    ).isRequired,
  }
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const dataStructureType = () => (props, key, componentName) => {
  // console.log('DATA STRUCTURE TYPE: ', props, key, componentName)
  const tempPropStructure = {}
  tempPropStructure[key] = PropTypes.shape({
    segments: segmentsType(),
    numberingSystem: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          prefix: PropTypes.string,
          type: PropTypes.string.isRequired,
          suffix: PropTypes.string,
          combined: PropTypes.bool,
          combineString: PropTypes.string,
        })
      )
    ).isRequired,
    documentSettings: PropTypes.arrayOf(
      PropTypes.shape({
        orientation: PropTypes.oneOf(Object.values(ORIENTATION)),
        paper: PropTypes.oneOf(Object.values(PAPER_NAMES)),
        margins: PropTypes.shape({
          top: PropTypes.number,
          left: PropTypes.number,
          bottom: PropTypes.number,
          right: PropTypes.number,
        }),
      })
    ).isRequired,
  }).isRequired
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}
export const locationsType = () => (props, key, componentName) => {
  // console.log('QUESTION TYPE: ', props, key, componentName)
  const tempPropStructure = {}
  tempPropStructure[key] = PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      locationId: PropTypes.string,
      questionId: PropTypes.string,
      start: PropTypes.number,
      length: PropTypes.number,
      end: PropTypes.number,
    })
  )
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

export const questionsType = () => (props, key, componentName) => {
  // console.log('QUESTION TYPE: ', props, key, componentName)
  const tempPropStructure = {}
  tempPropStructure[key] = PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf([...Object.values(REPLACEMENT_TYPES), ...Object.values(CHOICE_TYPES)]).isRequired,
      sort: PropTypes.number.isRequired,
      locations: locationsType(),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          locations: locationsType(),
          subQuestions: questionsType(),
          text: PropTypes.string,
        })
      ),
      tags: PropTypes.arrayOf(PropTypes.string),
      questionText: PropTypes.string,
      description: PropTypes.string,
      group: PropTypes.string,
      answerExample: PropTypes.string,
      optionId: PropTypes.string,
      parentId: PropTypes.string,
    })
  ).isRequired
  PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
}

// export const answersType = () => (props, key, componentName) => {
//   // console.log('QUESTION TYPE: ', props, key, componentName)
//   const tempPropStructure = {}
//   tempPropStructure[key] = PropTypes.shape({
//     segments: segmentsType(),
//     numberingSystem: PropTypes.objectOf(
//       PropTypes.arrayOf(
//         PropTypes.shape({
//           prefix: PropTypes.string,
//           type: PropTypes.string.isRequired,
//           suffix: PropTypes.string,
//           combined: PropTypes.bool,
//           combineString: PropTypes.string,
//         })
//       )
//     ).isRequired,
//   })
//   PropTypes.checkPropTypes(tempPropStructure, props, key, componentName)
// }
