import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { scrollBarStyles } from 'constants/common-styles'

export const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 5),
  flexShrink: 0,
}))

export const QuestionsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 5, 2, 5),
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 140px)',
  marginTop: theme.spacing(2),
  overflowY: 'auto',
  ...scrollBarStyles(theme),
}))

export const TextInputWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 5, 0, 5),
}))

export const FreeText = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(16),
  color: theme.colorz.secondary[300],
}))

export { Typography }
