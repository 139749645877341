import React, { useMemo } from 'react'
import { Loader } from 'components/common'
import { useFetchTemplate } from 'queries/templates'
// import { useUser } from 'queries/user/useUser'
import { useParams } from 'react-router'
import { TemplateCreationWizard } from 'TemplateCreation-DocumentGeneration'
import { CreateTemplateProvider } from './provider/CreateTemplateProvider'
import TemplateEdit from './TemplateEdit'

const TemplateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data = {} as any, isSuccess } = useFetchTemplate(id)
  // const user = useUser()
  // const docXmicro = !!user?.beta?.newDocxMicroservice

  const { questions, htmlData, settings, originalStylesKept, name, cssData, dataStructure } = data

  let renderOld = true
  // renderOld = !docXmicro

  const render = useMemo(() => {
    let component = <TemplateEdit />
    if (!renderOld)
      // @ts-ignore
      component = <TemplateCreationWizard dataStructure={dataStructure} cssData={cssData} questions={questions} />
    return component
  }, [renderOld, dataStructure, cssData, questions])

  if (!isSuccess) return <Loader />

  // const container = document.createElement('div')
  // container.innerHTML = htmlData

  // console.log('::::::: TEP :::::::')
  // console.log(htmlData.slice(350, 600))

  return (
    <CreateTemplateProvider
      initialData={{
        id,
        questions,
        htmlData,
        hyphens: settings?.hyphens,
        originalStylesKept,
        name,
        cssData,
        dataStructure,
      }}
    >
      {render}
    </CreateTemplateProvider>
  )
}

export default TemplateEditPage
