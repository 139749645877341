import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useQueryClient } from 'react-query'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useRootCategoryFolder } from 'hooks/useRootCategoryFolder'
import {
  useFetchFolders,
  useFileKeys,
  useFolderKeys,
  useUpdateFolder,
} from 'queries/folders'
import { useUpdateTemplate, useFetchTemplate, useLoadTemplates } from 'queries/templates'
import FileManager from 'components/files/FileAndFolderActions/move/FileManagerModal.component'

import { FolderItem } from 'types'
import { useDocument, useDocumentsUpdate, useLoadDocuments } from 'queries/documents'

interface Props {
  where: 'templates' | 'docs'
}

const useFiles = (where: 'templates' | 'docs', currentFolder: FolderItem) => {
  const templates = useLoadTemplates(currentFolder, { enabled: where === 'templates' })
  const docs = useLoadDocuments(currentFolder, { enabled: where === 'docs' })
  return where === 'templates' ? templates : docs
}

const useFetchFile = (where: 'templates' | 'docs', id?: string | null) => {
  const templates = useFetchTemplate(where === 'templates' ? id : null)
  const docs = useDocument(where === 'docs' ? id : null)
  return where === 'templates' ? templates : docs
}

const useUpdateFile = (where: 'templates' | 'docs') => {
  const templates = useUpdateTemplate()
  const docs = useDocumentsUpdate()
  return where === 'templates' ? templates : docs
}

const FileManagerModal: React.FC<Props> = ({ where }) => {
  const { location, replace } = useHistory()

  const { currentFolder } = useCurrentFolder(where)
  const rootFolder = useRootCategoryFolder(where, currentFolder.type)
  const [selectedFolder, setSelectedFolder] = useState(currentFolder)

  const params = new URLSearchParams(location.search)

  const fileId = params.get('file')
  const folderId = params.get('folder')
  const action = params.get('action')

  const { data: files } = useFiles(where, selectedFolder)
  const { data: file, isLoading: getFileIsLoading } = useFetchFile(where, fileId)
  const updateFile = useUpdateFile(where)
  const updateFolder = useUpdateFolder(where, currentFolder)

  const queryClient = useQueryClient()

  const { isLoading } = useFetchFolders(where, selectedFolder, {
    enabled: selectedFolder.id !== currentFolder.id,
  })

  const [manyFolders] = useFolderKeys(where)
  const [manyFiles] = useFileKeys(where)

  const handleClose = () => {
    replace(location.pathname)
  }

  const moveFile = (folder: FolderItem) => {
    if (!file) return
    let category: string[] = file.category

    if (category.length) {
      category = category.filter(cat => cat !== currentFolder.id)
    }
    if (['my', 'casus', 'shared'].includes(folder.id)) {
      category = []
    } else {
      category.push(folder.id)
    }

    updateFile.mutate(
      { id: file.id, data: { category: [...new Set(category)] } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            manyFiles,
            { type: currentFolder.type, id: currentFolder.id },
          ])

          handleClose()
        },
      }
    )
  }

  const moveFolder = (folder: FolderItem) => {
    if (!folderId) return
    const isRootCategory = ['my', 'casus', 'trashed', 'shared'].includes(folder.id)
    updateFolder.mutate(
      { id: folderId, data: { parentCategoryId: isRootCategory ? '' : folder.id } },
      {
        onSuccess: updatedFoldler => {
          queryClient.invalidateQueries([manyFolders, currentFolder.id])
          queryClient.invalidateQueries([manyFolders, selectedFolder.id])
        },
      }
    )
  }

  const handleConfirmMove = (folder: FolderItem) => {
    if (file) moveFile(folder)
    if (folderId) moveFolder(folder)
  }

  return (
    <FileManager
      open={!!(fileId || folderId) && action === 'move'}
      rootFolder={rootFolder}
      currentFolder={currentFolder}
      files={files?.pages.flat() ?? []}
      primaryButtonText='Bestätigen'
      primaryButtonDisabled={updateFile.isLoading || updateFolder.isLoading}
      isLoading={isLoading || getFileIsLoading}
      folderToMoveId={folderId}
      handleClose={handleClose}
      handleConfirmMove={handleConfirmMove}
      onFolderChange={setSelectedFolder}
    />
  )
}

export default FileManagerModal
