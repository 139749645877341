import React from 'react'
import { styled } from '@material-ui/core'
import { useHistory } from 'react-router'

import { useUserSubscription } from 'components/subscriptions/hooks/useUserSubscription'

import { TSubscriptionPlan } from 'types'
import { TH, Text } from './Table.styles'
import content from '../../../content'
import { ROUTE_UPGRADE_SUBSCRIPTION } from 'constants/routes'
import { CustomButton } from 'components/common'

const TableHead = styled('div')(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  padding: theme.spacing(3, 6, 3, 7),
  backgroundColor: theme.colorz.secondary[50],
}))

const PlanItemContainer = styled('div')({
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

interface Props {
  type: TSubscriptionPlan['type'] | 'enterprise' | 'starter'
  price: string
  text: string
}

const PlanItem: React.FC<Props> = ({ type, price, text }) => (
  <PlanItemContainer>
    <Text
      colorz='primary'
      style={{
        fontSize: 15,
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: 8,
      }}
    >
      {type}
    </Text>
    <Text colorz='main' style={{ fontSize: 30, fontWeight: 700, lineHeight: 1.1 }}>
      {price}
    </Text>
    <Text
      colorz='200'
      variant='body1'
      style={{
        letterSpacing: 0.1,
        textAlign: 'center',
        whiteSpace: type === 'enterprise' ? 'nowrap' : 'normal',
      }}
    >
      {text}
    </Text>
  </PlanItemContainer>
)

interface ITheadProps {
  onClose: () => void
}

const TableHeadComponent: React.FC<ITheadProps> = ({ onClose }) => {
  const { push, location } = useHistory()
  const { isSubscribed } = useUserSubscription()
  return (
    <TableHead>
      <TH style={{ alignSelf: 'center' }}>
        <CustomButton
          btntype='primary'
          width={190}
          size='large'
          onClick={() => {
            push(ROUTE_UPGRADE_SUBSCRIPTION, { from: location.pathname })
            onClose()
          }}
        >
          {isSubscribed ? 'Abo anpassen' : 'Abo auswählen'}
        </CustomButton>
      </TH>
      <TH>
        <PlanItem
          type='starter'
          price={`CHF ${content.startup.price.month}`}
          text='Für Start-Ups und Kleinstunternehmen'
        />
      </TH>
      <TH>
        <PlanItem
          type='basic'
          price={`CHF ${content.basic.price.month}`}
          text='Für Einsteiger'
        />
      </TH>
      <TH>
        <PlanItem type='team' price={`CHF ${content.team.price.month}`} text='Für KMU' />
      </TH>
      <TH>
        <PlanItem type='enterprise' price='Let’s talk!' text='Für Rechtsabteilungen' />
      </TH>
    </TableHead>
  )
}

export default TableHeadComponent
