import { styled, Theme, Typography } from '@material-ui/core'

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3.5, 4, 4, 4),
  backgroundColor: theme.colorz.brand.light,
  boxShadow: '0px 2px 0px #F3EEEA',
  borderRadius: 4,
}))

interface TextEl {
  theme: Theme
  colorz?: '200' | '300' | 'main'
}

export const Text = styled(Typography)(({ theme, colorz = 'main' }: TextEl) => ({
  color: theme.colorz.secondary[colorz],
}))

export const PlanInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing(4, 0, 6, 0),
}))

export const PriceInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: 200,
  height: '100%',
  flexShrink: 0,
  backgroundColor: '#fff',
  borderRadius: 2,
}))

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const FreeTrialTime = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 2,
  backgroundColor: theme.colorz.brand.light,
  border: `1px solid ${theme.colorz.brand.main}`,
  width: 158,
  height: 97,
  marginTop: theme.spacing(3),
}))
