import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useRegister } from 'pages/Auth/hooks/useRegister'

import Validator from 'validator'
import RegisterForm from './RegisterForm.component'
import {
  UserType,
  USER_TYPE_CLIENT_ADMIN,
  USER_TYPE_LAWYER_ADMIN,
} from 'constants/user-types'
import { RegisterBrowserEndpoint } from 'pages/Auth/RegistrationPage'
import * as msg from 'constants/messages'
import { useToast } from 'hooks/useToast'
import { IRegisterUserRequestPayload } from 'api/auth.api'

export interface IRegisterFormErrorState {
  email: boolean
  password: boolean
  terms: boolean
}

const initialState: IRegisterUserRequestPayload = {
  email: '',
  password: '',
  type: USER_TYPE_CLIENT_ADMIN,
}

const initialErrorState = {
  email: false,
  password: false,
  terms: false,
}

const getInitialState = (param: RegisterBrowserEndpoint): IRegisterUserRequestPayload => {
  const url = new URL(window.location.href)
  const subscriptionPlan = url.searchParams.get('plan')

  if (!param)
    return { ...initialState, subscription: subscriptionPlan ? 'free' : 'premium' }

  if (param === 'anwalt')
    return {
      ...initialState,
      type: USER_TYPE_LAWYER_ADMIN,
      subscription: subscriptionPlan ? 'free' : 'premium',
    }

  // registration is for employee
  const code = url.searchParams.get('code')
  const email = url.searchParams.get('email')
  let userType = url.searchParams.get('type')

  if (code && email && userType) {
    return { email, code, type: userType as UserType, password: '' }
  }

  return { ...initialState, subscription: subscriptionPlan ? 'free' : 'premium' }
}

const RegisterFormContainer: React.FC = () => {
  const { params } = useRouteMatch<{ type: RegisterBrowserEndpoint }>()
  const { mutate: register, isLoading } = useRegister()
  const toast = useToast()

  const [state, setState] = useState<IRegisterUserRequestPayload>(
    getInitialState(params.type)
  )

  const [errors, setErrors] = useState<IRegisterFormErrorState>(initialErrorState)
  const [termsChecked, setTermsChecked] = useState(false)

  const clearErrors = (): void => {
    setErrors(initialErrorState)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    if (state.code && name === 'email') return
    setErrors(prev => ({ ...prev, [name]: value && false }))
    setState(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    clearErrors()
    const newErrors = {
      ...errors,
      terms: !termsChecked,
      email: !Validator.isEmail(state.email),
    }
    setErrors(newErrors)

    if (newErrors.email) toast('error', msg.MESSAGE_INVALID_EMAIL)

    const hasError = Object.values(newErrors).some(err => err === true)
    if (hasError) return
    register(state)
  }

  return (
    <RegisterForm
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      setTermsChecked={setTermsChecked}
      values={state}
      errors={errors}
      termsChecked={termsChecked}
      isLoading={isLoading}
    />
  )
}

export default RegisterFormContainer
