import React from 'react'
import { useHistory, useParams } from 'react-router'

import { useFetchTemplate } from 'queries/templates'
import { DocumentGeneration } from 'components/features/documentGeneration/DocumentGeneration'
import { DocumentGenerationLayout } from 'components/features/documentGeneration/layout/DocumentGenerationLayout'
import { DocumentGenerationProvider } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { initQuestionsToAnswers } from 'components/features/documentGeneration/services/initQuestionsToAnswers'
import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'

import PurchaseTemplateModal from 'pages/Authenticated/Templates/components/PurchaseTemplateModal/PurchaseTemplateModal'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

const Questionnaire: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const { push, goBack } = useHistory()
  const { data } = useFetchTemplate(templateId)
  const { isExpired } = useSubscriptionCheck()

  const requirePayment = useIsTemplatePurchaseRequired(templateId)

  if (requirePayment)
    return (
      <PurchaseTemplateModal
        templateId={templateId}
        open={true}
        handleClose={goBack}
      />
    )

  if (!data)
    return (
      <DocumentGenerationLayout
        step={2}
        heading="Einen Moment bitte..."
        onClose={() => push('/aktenschrank')}
        isLoading={true}
      />
    )

  const { questions, name, htmlData, isCasus } = data

  // console.log(
  //   'GENERATE DOCUMENT PAGE: ',
  //   questions,
  //   name,
  //   initQuestionsToAnswers(questions)
  // )

  return (
    <DocumentGenerationProvider
      initialData={{
        questions,
        name,
        templateHtmlData: htmlData,
        isCasus,
        mode: 'create',
        answers: initQuestionsToAnswers(questions),
        templateId: data.id,
        templateContentVersionId: data.contentVersionId,
        cssData: data.cssData,
        templateDataStructure: data.dataStructure,
        documentDataStructure: data.dataStructure,
      }}
    >
      <DocumentGeneration isExpired={isExpired} />
    </DocumentGenerationProvider>
  )
}

export default Questionnaire
