import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { TextareaElProps } from './TextareaGroup.types'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const Label = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export const TextareaField = styled('textarea')(({ theme }: TextareaElProps) => ({
  resize: 'none',
  padding: theme.spacing(2),
  border: '1px solid ' + theme.colorz.secondary[100],
  color: theme.colorz.secondary.main,
  fontFamily: 'inherit',
  letterSpacing: 0.4,
  height: 106,
  fontSize: 16,
  fontWeight: 'normal',
  '&:focus': {
    outline: 'none',
    border: '1px solid ' + theme.colorz.secondary[100],
  },
  '&::placeholder': {
    color: theme.colorz.secondary[200],
    letterSpacing: 0.4,
  },
}))
