import React from 'react'

import { ChangePassword } from './ChangePassword/ChangePassword.container'

import { Container } from '../AccountSettings.styles'

const PasswordSettings: React.FC = () => {
  return (
    <Container style={{ flexDirection: 'row' }}>
      <ChangePassword />
    </Container>
  )
}

export default PasswordSettings
