import { EventItemScheduledProps } from './EventItem.types'
import React, { useState } from 'react'
import UserInfo from './UserInfo'
import ActionButtons from './ActionButtons'
import {
  Container,
  StrongText,
  Collapse,
  TopContainer,
  BodyContainer,
  RowItem,
  Label,
  Typography,
  ButtonsWrapperFooter,
  ButtonText,
} from './EventItem.styles'
import moment from 'moment'
// import { CustomButton } from 'components/Common'
import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_CLIENT_EMPLOYEE } from 'constants/user-types'

const formatTime = (time: string | number | Date): string => {
  return (
    moment(time).format('D. MMMM YYYY , HH:mm').replace(',', 'um') + ' Uhr'
  )
}

const EventItemScheduled: React.FC<EventItemScheduledProps> = ({
  eventItem,
  userType,
  handleBeginEvent,
  handleRescheduleEvent,
  handleCancelEvent,
}) => {
  const userIsClient = [USER_TYPE_CLIENT_ADMIN, USER_TYPE_CLIENT_EMPLOYEE].includes(
    userType
  )
  const [open, setOpen] = useState(false)
  const { lawyer, client, event, createdTime, startTime, endTime, link } = eventItem
  const person = userIsClient ? lawyer : client
  if (!person) return null
  const duration = new Date(Date.parse(endTime) - Date.parse(startTime)).getMinutes()

  const timeStart = formatTime(startTime)
  const timeEnd = formatTime(endTime)
  const timeCreated = formatTime(createdTime)

  const bodyItemsMap: { [key: number]: { label: string; text: string } } = {}

  bodyItemsMap[0] = {
    label: userIsClient ? 'Thematik' : 'Ort',
    text: userIsClient ? event.name : 'Google Meets',
  }

  bodyItemsMap[1] = {
    label: userIsClient ? 'Datum' : 'Startzeit',
    text: userIsClient
      ? moment(startTime).format('D. MMMM YYYY')
      : timeStart,
  }

  bodyItemsMap[2] = {
    label: userIsClient ? 'Zeit' : 'Endzeit',
    text: userIsClient
      ? moment(startTime).format('HH:mm') + ' Uhr'
      : timeEnd,
  }

  bodyItemsMap[3] = {
    label: userIsClient ? 'Besprechungsdauer' : 'Vereinbart am',
    text: userIsClient ? duration + ' Minuten' : timeCreated,
  }

  bodyItemsMap[4] = {
    label: userIsClient ? 'Anwält/in' : 'Kund/in',
    text: person.name,
  }

  // let month: string | number = new Date(startTime).getMonth() + 1
  // let year = new Date(startTime).getFullYear()
  // month = month > 9 ? String(month) : '0' + month

  // const rescheduleEndpoint = `${client?.id}?month=${year}-${month}`

  const bodyItems: { label: string; text: string }[] = Object.values(bodyItemsMap)

  return (
    <Container>
      <TopContainer>
        <UserInfo user={person} eventType='scheduled' />
        <StrongText>{timeStart}</StrongText>
        <ActionButtons
          handlePrimaryClick={() => handleBeginEvent(link)}
          handleSecondaryClick={() => setOpen(prev => !prev)}
          primaryDisabled={!link}
          open={open}
        />
      </TopContainer>
      <Collapse in={open}>
        <BodyContainer>
          {bodyItems.map(({ label, text }, i) => (
            <RowItem key={i + startTime}>
              <Label variant='body1'>{label}</Label>
              <Typography variant='body1'>{text}</Typography>
            </RowItem>
          ))}
        </BodyContainer>
        <ButtonsWrapperFooter>
          {/* {handleRescheduleEvent && (
            <CustomButton
              btntype='secondary'
              onClick={() => handleRescheduleEvent(rescheduleEndpoint)}
              disabled={false}
              size='large'
              width={182}
              style={{ marginLeft: 80 }}
            >
              Verschieben
            </CustomButton>
          )} */}

          {handleCancelEvent && (
            <ButtonText onClick={() => handleCancelEvent(event.id!)}>
              Termin stornieren
            </ButtonText>
          )}
        </ButtonsWrapperFooter>
      </Collapse>
    </Container>
  )
}

export default EventItemScheduled
