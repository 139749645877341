import { IApiHookOptions, IStripeCardData } from 'types'
import { Stripe } from '@stripe/stripe-js'
import { useState } from 'react'
import { useToast } from 'hooks/useToast'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { analytics } from 'utils'

interface ICargePayload {
  stripe: Stripe
  cardData: IStripeCardData
  clientSecret: string
}

export const useChargeBookLawyer = () => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const { isTrial, isBasic, isTeam } = useSubscriptionCheck()

  const charge = async (
    payload: ICargePayload,
    lawField: string,
    options?: IApiHookOptions
  ) => {
    const { stripe, cardData, clientSecret } = payload
    setIsLoading(true)
    try {
      const res = await stripe.createPaymentMethod(cardData)
      await stripe.confirmCardPayment(clientSecret!, {
        payment_method: res.paymentMethod?.id,
        setup_future_usage: 'off_session',
      })
      toast('success', 'Ihre Zahlung war erfolgreich')
      if (options?.onSuccess) options.onSuccess()

      const key = isTrial
        ? 'trial_lawyer_booked'
        : isTeam || isBasic
        ? 'subsc_lawyer_booked'
        : null
      if (key) analytics.logEvent(key, { category: lawField })
    } catch (err) {
      toast('error', 'default')
      if (options?.onError) options.onError()
    } finally {
      setIsLoading(false)
      if (options?.onSettled) options.onSettled()
    }
  }

  return { charge, isLoading }
}