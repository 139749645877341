import { Box, BoxProps, styled, Theme, Typography } from '@material-ui/core'
import { scrollBarStylesLight } from 'constants/common-styles'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 3, 0, 3),
  width: 500,
  minHeight: 300,
  maxHeight: 300,
  backgroundColor: '#fff',
  overflowY: 'auto',
  ...scrollBarStylesLight(theme),
}))

interface FileItemContainerEl extends BoxProps {
  theme: Theme
  isfolder?: number
  iscurrent?: number
  disabled?: number
}
export const FileItemContainer = styled(Box)(
  ({ theme, isfolder, iscurrent, disabled }: FileItemContainerEl) => ({
    display: 'flex',
    cursor: isfolder && !iscurrent ? 'pointer' : 'auto',
    alignItems: 'center',
    transition: 'all .2s',
    padding: theme.spacing(0.7, 1),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 1,
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.7 : 1,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
    '&:hover': {
      transform: `translateY(-${isfolder ? 1 : 0}px)`,
      backgroundColor: isfolder && iscurrent ? theme.colorz.brand.light : '#fff',
      borderColor: isfolder && iscurrent ? theme.colorz.brand.main : 'transparent',
    },
  })
)

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0.2,
}))

export const CenterWrapper = styled(Box)({
  display: 'flex',
  flex: 1,
  paddingBottom: '15%',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: 0.4,
  marginLeft: theme.spacing(2),
}))

export const TitleWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export const Logo = styled(Box)(({ theme }) => ({
  height: 16,
  width: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colorz.secondary.main,
  color: theme.colorz.brand.main,
  textTransform: 'uppercase',
  fontSize: 13,
  fontWeight: 700,
  borderRadius: 1,
  marginRight: theme.spacing(1.5),
}))
