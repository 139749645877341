import { styled } from '@material-ui/core/styles'
import { Box, Typography, Collapse } from '@material-ui/core'
import { TextInputGroup } from 'components/common'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  margin: theme.spacing(3, 0),
}))

export const NumberInput = styled(TextInputGroup)({
  '&::last-child': {
    marginLeft: 'auto',
  },
})

export const RangesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: theme.spacing(3),
}))

export const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
}))

export { Collapse }
