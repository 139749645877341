import { ICompanyInfo, User } from 'types'
import { NameFieldsSettingsGroup } from 'pages/Authenticated/AccountSettings/components/SettingsGroupItem/SettingsGroupItem.types'
import React, { useState, Fragment } from 'react'
import ProfileSettingsComponent from 'pages/Authenticated/AccountSettings/views/ProfileSettings/ProfileSettings.component'
import * as uT from 'constants/user-types'
import * as confirm from 'constants/confirmation-popups'
import { StatusModal } from 'components/common'
import { useUser } from 'queries/user/useUser'
import { useUpdateUser } from 'queries/user/useUpdateUser'
import { IUpdateUserProfileApiRequest } from 'api/user.api'

export interface ProfileSettingsState {
  firstName: string
  lastName: string
  website: string
  companyName: string
  field: string
}

const userToState = ({
  firstName,
  lastName,
  companyInfo,
}: User): ProfileSettingsState => {
  const { website, name, field } = companyInfo
  return {
    firstName,
    lastName,
    website: website,
    companyName: name,
    field,
  }
}

const ProfileSettings: React.FC = () => {
  const user = useUser()!
  const { mutate, isLoading } = useUpdateUser()
  const [state, setState] = useState(userToState(user!))
  const [showConfirm, setShowConfirm] = useState(false)

  const genUserClientAdminPayload = (
    prop: NameFieldsSettingsGroup,
    value: string
  ): IUpdateUserProfileApiRequest => {
    let obj: IUpdateUserProfileApiRequest = {}
    let userCompany: ICompanyInfo = { ...user.companyInfo }
    switch (prop) {
      case 'companyName':
        obj.companyInfo = { ...userCompany, name: value }
        break
      case 'field':
        obj.companyInfo = { ...userCompany, field: value }
        break
      case 'website':
        obj.companyInfo = { ...userCompany, website: value }
        break
    }
    return obj
  }

  const genUserLawyerAdminPayload = (
    prop: NameFieldsSettingsGroup,
    value: string
  ): IUpdateUserProfileApiRequest => {
    let companyInfo: ICompanyInfo = { ...user.companyInfo }
    switch (prop) {
      case 'companyName':
        companyInfo.name = value
        break
      case 'website':
        companyInfo.website = value
        break
    }
    return { companyInfo }
  }

  const handleSaveChange = (prop: NameFieldsSettingsGroup, value: string): void => {
    let obj: IUpdateUserProfileApiRequest = {}
    setState(prev => ({ ...prev, [prop]: value }))
    if (prop === 'firstName' || prop === 'lastName') {
      obj[prop] = value
    } else {
      switch (user.type) {
        case uT.USER_TYPE_CLIENT_ADMIN:
          obj = genUserClientAdminPayload(prop, value)
          break
        case uT.USER_TYPE_LAWYER_ADMIN:
          obj = genUserLawyerAdminPayload(prop, value)
      }
    }
    mutate(obj)
  }

  const handleDeleteProfile = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setShowConfirm(false)
  }

  const handleConfirmUploadImage = (base64Image: string): void => {
    mutate({ imageData: base64Image })
  }

  return (
    <Fragment>
      <ProfileSettingsComponent
        user={user}
        values={state}
        isLoading={isLoading}
        handleSaveChange={handleSaveChange}
        handleDeleteProfile={() => setShowConfirm(true)}
        handleConfirmUpload={handleConfirmUploadImage}
      />
      <StatusModal
        open={showConfirm}
        handlePrimaryClick={handleDeleteProfile}
        handleClose={() => setShowConfirm(false)}
        {...confirm.POPUP_CONFIRM_DELETE_PROFILE}
      />
    </Fragment>
  )
}

export default ProfileSettings
