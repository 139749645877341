import React, { useState } from 'react'
import Validator from 'validator'

import { useToast } from 'hooks/useToast'

import ForgotPasswordForm from 'pages/Auth/components/ForgotPasswordForm/ForgotPasswordForm.component'
import AuthApi from 'api/auth.api'
import { ERR_MSG_NO_USER_WITH_EMAIL } from 'constants/messages'

const ForgotPasswordFormContainer: React.FC = () => {
  const toast = useToast()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!Validator.isEmail(email)) toast('error', 'Please enter a valid email address')
    try {
      setIsLoading(true)
      await AuthApi.requestResetPasswordEmail(email)
      toast('success', 'Sie haben ein E-Mail mit dem Wiederherstellungslink erhalten.')
    } catch (err) {
      const noUserFound = err?.response?.data?.error_code === 'E0199'
      toast('error', noUserFound ? ERR_MSG_NO_USER_WITH_EMAIL : 'default')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ForgotPasswordForm
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      value={email}
      isLoading={isLoading}
    />
  )
}

export default ForgotPasswordFormContainer
