import React from 'react'

import { useHistory } from 'react-router-dom'

import LastStepComponent from './LastStep.component'
import { legalAdviceRoutes } from 'constants/routes'

const LastStep: React.FC = () => {
  const { push } = useHistory()

  return <LastStepComponent handleClick={() => push(legalAdviceRoutes.main)} />
}

export default LastStep
