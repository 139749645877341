import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
//import { useQueryClient } from 'react-query'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import ViewListIcon from '@material-ui/icons/ViewList'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { Edit, Trash, Settings } from 'components/common/Icons/Icons.styles'

import {
  useUpdateTemplate,
  useLoadTemplates,
  useTemplatesDuplicate,
} from 'queries/templates'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useUser } from 'queries/user/useUser'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useTemplateActions } from 'hooks/useTemplateActions'
//import { useMasterData } from 'queries/app/useMasterdata'

import Container from 'components/files/components/Container/Container'
import DocumentWithMenu from 'components/files/File/FileWithMenu'
import LoadMoreButton from 'components/files/components/LoadMoreButton/LoadMoreButton'
import { NoItemsAction } from 'components/common'
import TrashEmpty from 'components/files/components/TrashEmpty/TrashEmpty'
import {
  POPUP_CONFIRM_DELETE_FILE,
  POPUP_CONFIRM_TRASH_FILE,
} from 'constants/confirmation-popups'
import template_png from 'assets/illustrations/dokumente-vorlagen.svg'
import dummyPreview from '../../../../../assets/icons/dummyPreview.svg'
import loaderAnimation from '../../../../../assets/icons/loader.svg'

import { isEditorIsOwner } from 'utils'
import { templatesRoutes } from 'constants/routes'
import moment from 'moment'
import TableContainer from 'components/files/components/TableContainer/TableContainer'
import { MenuPopover } from 'components/common'

import './style.scss'

export const templatesNoItems = {
  text: 'Es sind noch keine Vorlagen vorhanden',
  actionText: 'Neue Vorlage erstellen',
  heading: 'Vorlagen',
  actionBtn: 'Neue Vorlage',
  image: template_png,
}

const TemplatesList: React.FC = () => {
  const [view, setView] = useState('card')
  const { currentFolder } = useCurrentFolder('templates')
  //const queryClient = useQueryClient()
  const { type, children } = currentFolder

  const { fetchNextPage, data, hasNextPage, isLoading, isFetchingNextPage } =
    useLoadTemplates(currentFolder, { enabled: false })

  const update = useUpdateTemplate()
  const duplicate = useTemplatesDuplicate()
  //const superadmins = useMasterData()?.superadmins ?? []
  const user = useUser()!
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { push, location } = useHistory()
  const [renameId, setRenameId] = useState<null | string>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState<
    IPopupMenuItem[] | []
  >([])
  const { check } = useSubscriptionCheck()
  const { onUseTemplate, onEditTemplate } = useTemplateActions()

  //const isSuperadmin = superadmins.includes(user.email)

  const templates = data?.pages.flat(1) ?? []
  const { subscription, purchases } = user

  const deleteTemplate = useCallback(
    (id: string) => {
      const status = type === 'trashed' ? 'deleted' : 'trashed'
      const POPUP =
        type === 'trashed'
          ? POPUP_CONFIRM_DELETE_FILE
          : POPUP_CONFIRM_TRASH_FILE

      popup({
        ...POPUP,
        onConfirm: () => {
          setIsLoading(true)
          update.mutate(
            { id: id, data: { status } },
            { onSuccess: close, onSettled: () => setIsLoading(false) }
          )
        },
        onCancel: close,
      })
    },
    [close, popup, setIsLoading, type, update]
  )

  const getMenuItems = useCallback(
    (templateItem: any) => {
      const { id, authorId } = templateItem

      const { isOwner, isEditor } = isEditorIsOwner(
        user?.id,
        authorId,
        templateItem.sharedWith
      )

      const hasQuestions = Boolean(templateItem.questions?.length)

      const requirePayment =
        subscription?.type === 'free' &&
        !purchases.templates?.some(
          (t) => t.id === id && ['paid', 'processing'].includes(t.status)
        )

      const menuItems: IPopupMenuItem[] = []

      if (type !== 'trashed' && !requirePayment) {
        menuItems.push({
          icon: 'preview',
          label: hasQuestions ? 'Verwenden' : 'Keine Fragen',
          handleClick: () =>
            onUseTemplate(id, {
              name: templateItem.name,
              isCasus: templateItem.isCasus,
            }),
          disabled: !hasQuestions,
        })
      }

      if (type !== 'trashed' && (isOwner || isEditor)) {
        menuItems.push({
          icon: 'edit',
          label: 'Bearbeiten',
          handleClick: () => onEditTemplate(id),
        })
      }

      if (type !== 'trashed' && isEditor && view === 'card')
        menuItems.push({
          icon: 'rename',
          label: 'Umbenennen',
          handleClick: () => {
            if (check()) setRenameId(id)
          },
        })

      menuItems.push({
        icon: 'duplicate',
        label: 'Duplikat',
        handleClick: () => {
          if (check()) duplicate.mutate(templateItem)
        },
      })

      if (isOwner) {
        if (type !== 'trashed' && authorId === user.id) {
          menuItems.push({
            icon: 'move',
            label: 'Vorlage verschieben',
            handleClick: () => {
              if (check()) push(`${location.pathname}?file=${id}&action=move`)
            },
          })
        }

        if (type !== 'trashed') {
          menuItems.push({
            icon: 'share',
            label: 'Teilen',
            handleClick: () => {
              console.info('sharing template')
              if (check({ isFree: true, isBasic: true, isStartup: true }))
                push(`${location.pathname}?file=${id}&action=share`)
            },
          })
        }

        if (type === 'trashed') {
          menuItems.push({
            icon: 'restore',
            label: 'Wiederherstellen',
            handleClick: () => {
              if (check()) update.mutate({ id: id, data: { status: 'new' } })
            },
          })
        }

        menuItems.push({
          icon: 'trash',
          label: 'Löschen',
          handleClick: () => deleteTemplate(id),
        })
      }

      return menuItems
    },
    [
      deleteTemplate,
      update,
      location.pathname,
      check,
      duplicate,
      onEditTemplate,
      onUseTemplate,
      push,
      type,
      user,
      view,
      purchases.templates,
      subscription,
    ]
  )

  const getTypeIcon = useCallback((template: any) => {
    if (template.isLoading) {
      return (
        <div>
          <img src={loaderAnimation} alt="" className="documentRow-fileType" />
        </div>
      )
    } else {
      return (
        <div>
          <img src={dummyPreview} alt="" className="documentRow-fileType" />
        </div>
      )
    }
  }, [])

  const generateActions = useCallback(
    (template: any) => {
      const leftClick = (e: any) => {
        if (!isLoading) {
          const thisTemplateMenuItem = getMenuItems(template)
          setSelectedMenuItem(thisTemplateMenuItem)
          setAnchorEl(e.currentTarget)
        }
      }
      return (
        <div key={template.id} className="templateRow-actionsWrapper">
          <button
            type="button"
            className="templateRow-actionButton"
            onClick={() => onEditTemplate(template.id)}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') onEditTemplate(template.id)
            }}
          >
            <Edit />
          </button>
          <button
            type="button"
            className="templateRow-actionButton"
            onClick={() => deleteTemplate(template.id)}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') deleteTemplate(template.id)
            }}
          >
            <Trash />
          </button>
          <button
            type="button"
            className="templateRow-actionButton"
            onClick={(event: React.MouseEvent<HTMLElement>) => leftClick(event)}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') leftClick(event)
            }}
          >
            <Settings />
          </button>
        </div>
      )
    },
    [
      deleteTemplate,
      onEditTemplate,
      getMenuItems,
      isLoading,
      setSelectedMenuItem,
    ]
  )

  const render = useMemo(() => {
    switch (view) {
      case 'card':
        return (
          <Container>
            {templates.map((templateItem) => {
              const { id, authorId } = templateItem

              const { isOwner, isEditor } = isEditorIsOwner(
                user?.id,
                authorId,
                templateItem.sharedWith
              )

              const hasQuestions = Boolean(templateItem.questions?.length)
              const isShared = templateItem.sharedWith?.find(
                (sh) => sh.id === user.id
              )

              const requirePayment =
                subscription?.type === 'free' &&
                !purchases.templates?.some(
                  (t) =>
                    t.id === id && ['paid', 'processing'].includes(t.status)
                )

              const updateName = (docId: string = id, newName: string) => {
                setRenameId(null)
                if (newName !== templateItem.name)
                  update.mutate({ id: docId, data: { name: newName } })
              }

              const menuItems: IPopupMenuItem[] = []

              if (type !== 'trashed' && !requirePayment) {
                menuItems.push({
                  icon: 'preview',
                  label: hasQuestions ? 'Verwenden' : 'Keine Fragen',
                  handleClick: () =>
                    onUseTemplate(id, {
                      name: templateItem.name,
                      isCasus: templateItem.isCasus,
                    }),
                  disabled: !hasQuestions,
                })
              }

              if (type !== 'trashed' && (isOwner || isEditor)) {
                menuItems.push(
                  {
                    icon: 'edit',
                    label: 'Bearbeiten',
                    handleClick: () => onEditTemplate(id),
                  },
                  {
                    icon: 'rename',
                    label: 'Umbenennen',
                    handleClick: () => {
                      if (check()) setRenameId(id)
                    },
                  }
                )
              }

              menuItems.push({
                icon: 'duplicate',
                label: 'Duplikat',
                handleClick: () => {
                  if (check()) duplicate.mutate(templateItem)
                },
              })

              if (isOwner) {
                if (type !== 'trashed' && authorId === user.id) {
                  menuItems.push({
                    icon: 'move',
                    label: 'Vorlage verschieben',
                    handleClick: () => {
                      if (check())
                        push(`${location.pathname}?file=${id}&action=move`)
                    },
                  })
                }

                if (type !== 'trashed') {
                  menuItems.push({
                    icon: 'share',
                    label: 'Teilen',
                    handleClick: () => {
                      console.info('sharing template')
                      if (
                        check({ isFree: true, isBasic: true, isStartup: true })
                      )
                        push(`${location.pathname}?file=${id}&action=share`)
                    },
                  })
                }

                if (type === 'trashed') {
                  menuItems.push({
                    icon: 'restore',
                    label: 'Wiederherstellen',
                    handleClick: () => {
                      if (check())
                        update.mutate({ id: id, data: { status: 'new' } })
                    },
                  })
                }

                menuItems.push({
                  icon: 'trash',
                  label: 'Löschen',
                  handleClick: () => deleteTemplate(id),
                })
              }

              return (
                <DocumentWithMenu
                  key={templateItem.id}
                  document={templateItem}
                  menuItems={menuItems}
                  renameActive={renameId === templateItem.id}
                  isShared={
                    !!isShared ||
                    !!(
                      isOwner &&
                      templateItem.sharedWith.length &&
                      type === 'my'
                    )
                  }
                  currentFolderType={type}
                  handleLeftClick={() =>
                    push(
                      `${location.pathname}?vorlage=${templateItem.id}&action=preview`
                    )
                  }
                  handleUpdateName={updateName}
                  isLoading={templateItem.isLoading}
                />
              )
            })}
          </Container>
        )
      case 'table': {
        const createColumn = (
          id?: any,
          label?: any,
          minWidth?: any,
          align?: any
        ) => ({ id, label, minWidth, align })
        const columns = [
          createColumn('type', 'Type', 30, 'left'),
          createColumn('name', 'Name', 300, 'left'),
          // createColumn('template', 'Template', 250, 'left'),
          createColumn('modified', 'Last modified', 150, 'right'),
          createColumn('actions', 'Actions', 150, 'right'),
        ]
        const createRow = (
          id: String,
          name?: any,
          template?: any,
          modified?: any,
          actions?: any,
          type?: any
        ) => ({ id, name, template, modified, actions, type })
        const rows = templates.map((d) =>
          createRow(
            d.id,
            d.name,
            d.name,
            moment.unix(d.edited?._seconds).fromNow(),
            generateActions(d),
            getTypeIcon(d)
          )
        )
        return (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((c) => (
                    <TableCell
                      key={c.id}
                      align={c.align}
                      style={{ minWidth: c.minWidth }}
                    >
                      {c.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((r: any) => (
                  <TableRow key={r.id}>
                    {columns.map((c) => {
                      const key = String(c.id)
                      const value = r[key]
                      return (
                        <TableCell
                          key={`${r.id}-${c.id}`}
                          align={c.align}
                          style={{ minWidth: c.minWidth }}
                        >
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <MenuPopover
              anchorOrigin={{
                vertical: 20,
                horizontal: 'left',
              }}
              menuItems={selectedMenuItem}
              handleClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            />
          </TableContainer>
        )
      }
      default:
        return null
    }
  }, [
    view,
    generateActions,
    check,
    deleteTemplate,
    duplicate,
    templates,
    location.pathname,
    onEditTemplate,
    push,
    renameId,
    type,
    update,
    user,
    onUseTemplate,
    subscription,
    purchases.templates,
    selectedMenuItem,
    anchorEl,
    getTypeIcon,
  ])

  if (type === 'trashed' && !templates.length && !children.length)
    return <TrashEmpty />

  if (!templates.length && !children.length)
    return (
      <NoItemsAction
        {...templatesNoItems}
        handleActionClick={
          type === 'my'
            ? () => {
                if (check({ isFree: true, isStartup: true }))
                  push(templatesRoutes.create)
              }
            : undefined
        }
      />
    )

  if (!templates) return null

  return (
    <>
      <div className="view-buttons-wrapper">
        <button
          className={(view === 'card' && 'active') || ''}
          type="button"
          onClick={() => setView('card')}
          onKeyDown={(event) => event.key === 'ENTER' && setView('card')}
        >
          <InsertDriveFileIcon />
        </button>
        <button
          className={(view === 'table' && 'active') || ''}
          type="button"
          onClick={() => setView('table')}
          onKeyDown={(event) => event.key === 'ENTER' && setView('table')}
        >
          <ViewListIcon />
        </button>
      </div>
      {render}
      {hasNextPage && (
        <LoadMoreButton
          text="Mehr anzeigen"
          isLoading={isLoading || isFetchingNextPage}
          onClick={fetchNextPage}
        />
      )}
    </>
  )
}

export default TemplatesList
