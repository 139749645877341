import { CalendlyBookingUrlItem, TSetter } from 'types'
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react'
import { StorageService } from 'utils'

interface IBookLawyerState {
  step: number
  lawField: CalendlyBookingUrlItem | null
  connectedAccountId?: string
  clientSecret?: string
}

interface Context extends IBookLawyerState {
  set: (data: TSetter<IBookLawyerState>) => void
}

const BookLawyerContext = createContext<Context | undefined>(undefined)

export const BookLawyerProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<IBookLawyerState>({ step: 1, lawField: null })

  const set = useCallback((data: TSetter<IBookLawyerState>) => {
    setState(prev => ({ ...prev, ...data }))
  }, [])

  useEffect(() => {
    StorageService.save('bookLawyer', state)
  }, [state])

  return (
    <BookLawyerContext.Provider value={{ ...state, set }}>
      {children}
    </BookLawyerContext.Provider>
  )
}

export const useBookLawyer = () => {
  const context = useContext(BookLawyerContext)

  if (!context) {
    throw new Error('useBookLawyer must be used within a ConfirmBookLawyerContext')
  }
  return context
}
