import { ImageSliderWrapperEl } from './ImageEditor.types'
import { styled, Box, Slider, Typography } from '@material-ui/core'

export const SliderWrapper = styled(Box)(
  ({ theme, width, orientation }: ImageSliderWrapperEl) => ({
    display: 'flex',
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
    alignItems: orientation === 'vertical' ? 'flex-start' : 'center',
    width,
  })
)

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[200],
  fontSize: 14,
  letterSpacing: 0.14,
  marginBottom: theme.spacing(0.5),
}))

export { Slider }
