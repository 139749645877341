import { styled, Theme } from '@material-ui/core'
import { IbanElement } from '@stripe/react-stripe-js'
import { StripeIbanElementOptions } from '@stripe/stripe-js'
import { formElStyles } from '../common.styles'

export const ibanStyleOptions = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
}

interface IbanElProps {
  options?: StripeIbanElementOptions
  theme: Theme
}

export const IbanEl = styled(IbanElement)(({ theme }: IbanElProps) => ({
  ...formElStyles(theme),
  padding: theme.spacing(1.55, 2),
  width: '100%',
}))
