import { ITeamMember, IApiHookOptions } from 'types'
import { useQueryClient } from 'react-query'
import { useState } from 'react'
import { useToast } from 'hooks/useToast'

import TeamApi from 'api/team.api'
import { queryKeys } from 'queries'

export const useTeamMemberRemove = () => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const remove = async (id: string, opts: IApiHookOptions) => {
    try {
      setIsLoading(true)
      await TeamApi.remove(id)
      queryClient.setQueryData<ITeamMember[]>(queryKeys.TEAM_MEMBERS, data =>
        (data ?? []).filter(teamMember => teamMember.id !== id)
      )
      if (opts.onSuccess) opts.onSuccess()
    } catch (err) {
      toast('error', 'default')
      if (opts.onError) opts.onError()
    } finally {
      setIsLoading(false)
      if (opts.onSettled) opts.onSettled()
    }
  }

  return { remove, isLoading }
}
