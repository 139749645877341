import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react'

import { useParams } from 'react-router'
import { useUpdateTemplate } from 'queries/templates'
import { useEditor } from 'config/textEditor/useEditor'
import TemplatesApi from 'api/templates.api'

import { IUpdateTemplateApiData, Question, TQuestionTypes } from 'types'
import { deepAddOrEditQuestions, deepDeleteQuestion, removeHighlightItemsByQuestionId } from 'utils'
import { StorageService } from 'utils/storageService'
import { deleteSubquestion } from '../question.utils'

import { QUESTION_TYPE_TEXT_BOX } from 'constants/question-types'
import { generateHtml } from 'utilities'
import { useUser } from 'queries/user/useUser'

const selectionData = {
  id: null,
  start: null,
  end: null,
  currentQuestionId: null,
}

type TSet = { [K in keyof ITemplateEditState]?: ITemplateEditState[K] }

interface ICurrentSelection {
  id: string | null
  start: number | null
  end: number | null
  currentQuestionId: string | null
  locationId?: string | null
}

interface InitialData {
  id?: string
  name: string
  questions: Question[]
  htmlData: string
  hyphens: boolean
  originalStylesKept: boolean
  cssData?: any
  dataStructure?: any
}
export interface ITemplateEditState extends InitialData {
  step: number
  filteredQuestions: Question[]
  question: Question | null
  mainQuestion: Question | null
  questionStep: number
  selectionData: ICurrentSelection
  textSelectionIsActive: boolean
  selectedQuestionType: TQuestionTypes
  showQuestionsPreview: boolean
  logo?: string
}

type TQuestionFn = (question: Question) => void

type TCallback = (status: { success?: boolean; error?: boolean }) => void

interface IContext extends ITemplateEditState {
  set: (props: TSet) => void
  addQuestion: TQuestionFn
  onSubquestionDelete: TQuestionFn
  onQuestionPreview: TQuestionFn
  save: (cb: TCallback, data?: IUpdateTemplateApiData) => void
  isTemplateEdited: (htmlData?: string) => boolean
  cssData: any
}

const initialState: ITemplateEditState = {
  step: 2,
  name: '',
  questions: [],
  htmlData: '',
  filteredQuestions: [],
  question: null,
  mainQuestion: null,
  selectedQuestionType: QUESTION_TYPE_TEXT_BOX,
  questionStep: 0,
  textSelectionIsActive: false,
  showQuestionsPreview: false,
  originalStylesKept: false,
  hyphens: false,
  selectionData,
}

const TemplateCreationContext = createContext<IContext | null>(null)

interface Props {
  initialData: InitialData
}

export const CreateTemplateProvider: React.FC<Props> = ({ children, initialData }) => {
  // console.log('INITIAL HTML DATA: ', initialData.htmlData)
  const { id } = useParams<{ id: string }>()
  const update = useUpdateTemplate()
  const editor = useEditor()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  const existingState: ITemplateEditState = StorageService.read('templateCreationState')

  const initialSetState = useMemo(() => {
    if (docXmicro) {
      const { dataStructure, questions } = initialData
      const [htmlData] = generateHtml(dataStructure, questions)
      const initState = { ...initialState, ...initialData, htmlData }
      return initState as any
    } else return { ...initialState, ...initialData }
  }, [docXmicro, initialData])

  const [state, setState] = useState(
    existingState && existingState?.id === id && typeof existingState?.htmlData === 'string'
      ? { ...existingState, id }
      : initialSetState
  )

  const {
    questionStep,
    textSelectionIsActive,
    mainQuestion,
    htmlData,
    questions,
    originalStylesKept,
    hyphens,
    name,
    cssData,
  } = state

  // console.log('::::::: FROM THE STATE :::::::')
  // console.log(state.htmlData.slice(350, 600))

  const set = useCallback(
    (props: TSet) => {
      // console.log('STATE SETTER: ', props)
      if (docXmicro) {
        const propKeys = Object.keys(props)
        if (propKeys.includes('htmlData') || propKeys.includes('question') || propKeys.includes('questions')) {
          //@ts-ignore
          setState(prev => {
            const { dataStructure = {} } = initialData
            const { questions = [], question = {} } = { ...prev, ...props }
            const allQuestions = [...questions]
            if (question) allQuestions.push(question)
            const [htmlData] = generateHtml(dataStructure, allQuestions)
            const newState = { ...prev, ...props, htmlData }
            return newState
          })
        } else {
          //@ts-ignore
          setState(prev => {
            const updatedState = {
              ...prev,
              ...props,
            }
            return updatedState
          })
        }
        //@ts-ignore
      } else {
        setState((prev: any) => {
          const updatedState = {
            ...prev,
            ...props,
            htmlData: props.htmlData ?? prev.htmlData,
          }
          return updatedState
        })
      }
    },
    [docXmicro, initialData]
  )

  const addQuestion = (question: Question) => {
    const payload = {
      questions: deepAddOrEditQuestions(questions, question, docXmicro),
      questionStep: 0,
      selectedQuestionType: QUESTION_TYPE_TEXT_BOX,
      question: null,
    } as any
    if (!docXmicro) payload.htmlData = editor?.getBody().innerHTML
    else if (!question.parentId) TemplatesApi.addQuestion({ id, question })
    set(payload)
    StorageService.remove('textSelectionHtmlData')
  }

  const onSubquestionDelete = (subquestion: Question) => {
    if (!editor) return
    removeHighlightItemsByQuestionId(subquestion.id, editor)
    const payload = {
      question: deleteSubquestion(state.question, subquestion),
      questions: deepDeleteQuestion(state.questions, subquestion),
      mainQuestion: mainQuestion ? deepDeleteQuestion([mainQuestion], subquestion)[0] : null,
    } as any
    if (!docXmicro) payload.htmlData = editor?.getBody().innerHTML
    set(payload)
  }

  const onQuestionPreview = (question: Question) => {
    set({
      filteredQuestions: [question],
      showQuestionsPreview: true,
    })
  }

  const save = (callback: TCallback, data?: IUpdateTemplateApiData) => {
    update.mutate(
      {
        id,
        data: {
          htmlData,
          questions,
          originalStylesKept,
          settings: { hyphens },
          name: state.name,
          ...data,
        },
      },
      {
        onSuccess: () => callback({ success: true }),
        onError: () => callback({ error: true }),
      }
    )
  }

  const isTemplateEdited = (providedHtmlData?: string) => {
    const current = JSON.stringify({
      htmlData: providedHtmlData ?? htmlData,
      hyphens,
      questions,
      name,
    })
    const initial = JSON.stringify({
      htmlData: initialData.htmlData ?? '',
      hyphens: initialData.hyphens,
      questions: initialData.questions ?? [],
      name: initialData.name,
    })

    return current !== initial
  }

  useEffect(() => {
    StorageService.save('templateCreationState', state)
  }, [state])

  useEffect(() => {
    if (existingState?.id !== id) StorageService.remove('templateCreationState')
    if (!existingState) StorageService.save('templateCreationState', state)
  }, [existingState, state, id])

  useEffect(() => {
    if ([2, 4].includes(questionStep) && textSelectionIsActive) {
      set({ textSelectionIsActive: false })
    }
  }, [questionStep, textSelectionIsActive, set])

  return (
    <TemplateCreationContext.Provider
      value={{
        ...state,
        set,
        addQuestion,
        onSubquestionDelete,
        onQuestionPreview,
        save,
        isTemplateEdited,
        cssData,
      }}
    >
      {children}
    </TemplateCreationContext.Provider>
  )
}

export const useTemplateCreation = (): IContext => {
  const context = useContext(TemplateCreationContext)

  if (!context) throw new Error('useTemplateCreation must be used within a TemplateCreationContext')

  return context
}
