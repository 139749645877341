import { Editor as IEditor } from 'tinymce'
import React, { Dispatch, Fragment, SetStateAction, useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useParams } from 'react-router'
import { useQueryClient } from 'react-query'

import { useEditor } from 'config/textEditor/useEditor'
import { useMasterData } from 'queries/app/useMasterdata'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useToast } from 'hooks/useToast'
import { useTextEditorHandlers } from 'config/textEditor/useTextEditorEventHandlers'

import { BoxPositionConfig } from './EditorView.component'

import { renderEditorContent } from 'config/textEditor/services/text-editor'

import theme from 'config/themes/theme'

import { primary } from 'config/themes/colors'

import * as config from 'config/textEditor/textEditor.config'
import TemplatesApi from 'api/templates.api'
import { queryKeys } from 'queries/queryKeys'
import { ITemplate } from 'types'
import { StorageService } from 'utils'
import { useUser } from 'queries/user/useUser'

export const backdropStyle = {
  background: theme.colorz.secondary[100],
  opacity: 0.8,
}

interface Props {
  highlightToDelete: string | null
  setBoxPosition: Dispatch<SetStateAction<BoxPositionConfig>>
  setHighlightToDelete: Dispatch<SetStateAction<string | null>>
  setSelectionExists: Dispatch<SetStateAction<boolean>>
}

// const initialStep3Data = localStorage.getItem('textSelectionHtmlData')

export const TemplateCreationEditor: React.FC<Props> = ({
  highlightToDelete,
  setBoxPosition,
  setHighlightToDelete,
  setSelectionExists,
}) => {
  const masterdata = useMasterData()
  const editor = useEditor()
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const queryState = queryClient.getQueryState<ITemplate>([queryKeys.TEMPLATE, id])

  const {
    set,
    htmlData,
    hyphens,
    questionStep,
    textSelectionIsActive,
    question,
    selectionData,
    cssData = {},
  } = useTemplateCreation()

  // console.log('TEMPLATE CREATION EDITOR (CSS): ', cssData)
  // console.log('TEMPLATE CREATION EDITOR (HTML): ', htmlData)

  const {
    onBackspaceDown,
    onEnterDown,
    onAnyCharDown,
    onMouseUp,
    onPaste,
    onChange,
    onBeforeExecCommand,
    onAfterExecCommand,
    setTextSelected,
    backspaceTracker,
  } = useTextEditorHandlers()

  const toast = useToast()
  const [contentSelected, setContentSelected] = useState('')

  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  const docXmicroInitValue = React.useRef(htmlData)
  // const oldInitValue = React.useRef(questionStep !== 0 && initialStep3Data ? initialStep3Data : htmlData)
  const oldInitValue = React.useRef(htmlData)
  const initValue = docXmicro ? docXmicroInitValue : oldInitValue

  const onInit = (e: any, editor: IEditor): void => {
    // editor.focus()
    const body = editor.getBody()
    body.style.hyphens = hyphens ? 'auto' : 'none'
    body.setAttribute('lang', 'de_DE')
    if (!docXmicro)
      body.querySelectorAll('mark').forEach(m => {
        m.removeAttribute('style')
        m.setAttribute('contenteditable', 'false')
        // for refresh
        // if (initialStep3Data) {
        //   m.removeAttribute('style')
        //   if (m?.dataset?.qid === question?.id) {
        //     m.setAttribute(
        //       'style',
        //       `background-color: ${getMarkElementColor(m)}`
        //     )
        //   }
        // }
      })
  }

  const onKeyDown = (e: any, editor: IEditor): void => {
    if (docXmicro) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (protectMarkElChange(editor)) return

    if (questionStep === 0) {
      const selectionIncludesMarkElement = contentSelected.includes('<mark id=') || contentSelected.includes('</mark>')
      if (selectionIncludesMarkElement) {
        editor.setContent(htmlData)
        if (questionStep === 0) {
          editor
            .getBody()
            .querySelectorAll('mark')
            .forEach(el => el.setAttribute('contenteditable', 'false'))
        }
        toast('warning', 'Text mit verbundener Frage kann nicht gelöscht werden')
        return
      }
    }

    const selectedElements = editor.selection.getSelectedBlocks()
    const currentNode = selectedElements[0]
    if (!currentNode) return

    const handleBackSpaceClick = (): void => {
      if (
        questionStep !== 3 &&
        !textSelectionIsActive &&
        backspaceTracker?.includes('<mark id=') &&
        !editor.selection.getNode().innerHTML.includes('<mark id=')
      ) {
        editor.setContent(htmlData)
        editor
          .getBody()
          .querySelectorAll('mark')
          .forEach(el => el.setAttribute('contenteditable', 'false'))
        return
      }

      onBackspaceDown(e, editor)
    }

    switch (e.key) {
      case 'Enter':
        onEnterDown(e, editor)
        break
      case 'Backspace':
        handleBackSpaceClick()
        break
      default:
        onAnyCharDown(e, editor)
        break
    }
    setTextSelected('')
  }

  const handleMouseUp = (e: any, editor: IEditor) => {
    if (docXmicro) {
      e.preventDefault()
      e.stopPropagation()
    }
    onMouseUp(e, editor)
    const content = editor.selection.getContent()

    if (questionStep === 3) {
      const { yPos, xPos } = getXYBoxCoordinates(e, editor)
      setBoxPosition(prev => ({ ...prev, y2: yPos, x2: xPos }))
    }
    setContentSelected(content)
  }

  const onMouseDown = (e: any, editor: IEditor) => {
    // console.log("MOUSE DOWN EVENT! E: ", e)
    // console.log("QUESTION STEP: ", questionStep)
    if (questionStep !== 3) return
    setSelectionExists(true)
    const { yPos, xPos } = getXYBoxCoordinates(e, editor)

    let theNode = e.target as HTMLElement

    if (['STRONG', 'EM'].includes(theNode?.nodeName) && theNode?.parentElement?.nodeName === 'MARK') {
      theNode = theNode.parentElement
    }

    setBoxPosition(prev => ({ ...prev, y1: yPos, x1: xPos }))
    if (theNode.nodeName !== 'MARK' && highlightToDelete) {
      setHighlightToDelete(null)
    }
    // console.log("NODE NAME: ", theNode.nodeName)
    if (theNode.nodeName === 'MARK') {
      const getPropsFromMark = (e: React.MouseEvent<HTMLElement>): { id?: string; dataset?: DOMStringMap } => {
        // console.log("GETTING PROPS FROM MARK!")
        let node = e.target as HTMLElement
        let level = 0
        let nodeIsMark = node.nodeName === 'MARK'

        while (level < 3 && !nodeIsMark) {
          level += 1
          const parentElement = (e.target as HTMLElement).parentElement
          if (parentElement) node = parentElement
          nodeIsMark = node.nodeName === 'MARK'
        }
        if (!nodeIsMark) return { id: undefined, dataset: undefined }
        const { id: nodeId, dataset } = node
        const returnValue = { id: !docXmicro ? nodeId : dataset.lid, dataset }
        // console.log("ID: ", returnValue.id)
        // console.log("DATASET: ", returnValue.dataset)
        return returnValue
      }

      const { id, dataset } = getPropsFromMark(e)
      if (!id || !dataset || dataset.qid !== question?.id) {
        setHighlightToDelete(null)
        return
      }
      // console.log("SET HIGHLIGHT TO DELETE: ", id)
      setHighlightToDelete(id)
    }
  }

  const onCut = (e: ClipboardEvent, editor: IEditor) => {
    const content = editor.selection.getContent()
    if (content.includes('<mark id=') || content.includes('</mark>')) {
      editor.setContent(htmlData)
      if (questionStep === 0) {
        editor
          .getBody()
          .querySelectorAll('mark')
          .forEach(el => el.setAttribute('contenteditable', 'false'))
      }
      toast('warning', 'Text mit verbundener Frage kann nicht gelöscht werden')
      return
    }
    renderEditorContent(editor)
    onSave(editor)
  }

  const handleChange = (e: any, editor: IEditor) => {
    onChange(e, editor)
    // onSave(editor)
  }

  const onBeforePaste = (e: any, editor: IEditor) => {
    keepStylesWordPaste(editor)
  }

  const handleAfterExecCommand = (e: any, editor: IEditor) => {
    onAfterExecCommand(e, editor)
    onSave(editor)
  }

  const onSave = (editor?: IEditor) => {
    if (!editor || !queryState || queryState?.isFetching) return
    const body = editor.getBody()
    if (questionStep === 0 && !docXmicro) set({ htmlData: body.innerHTML })
  }

  const getXYBoxCoordinates = (e: any, editor: IEditor): { xPos: number; yPos: number } => {
    const body = editor?.getBody()
    const html = editor?.getDoc().firstElementChild
    if (!body || !html) return { xPos: 0, yPos: 0 }
    const rect = body.getBoundingClientRect()
    const xPos = e.clientX - rect.left //x position within the element.
    const yPos = e.clientY - rect.top - html.scrollTop
    return { xPos, yPos }
  }

  const keepStylesWordPaste = (editor?: IEditor) => {
    if (!editor) return
    const content = editor.getContent()
    editor.setContent(content)
  }

  const onHyphenChange = (editor: IEditor) => {
    if (!editor) return
    const body = editor.getBody()
    const currentHyphens = body.style.hyphens
    const newHyphens = currentHyphens === 'auto' ? 'none' : 'auto'
    set({ hyphens: newHyphens === 'auto' ? true : false })
    body.style.hyphens = newHyphens
  }

  const onLogoUpload = async (imageData: string): Promise<string> => {
    if (!id) return imageData
    const imgSrc = await TemplatesApi.uploadLogo(id, imageData)
    return imgSrc
  }

  const protectMarkElChange = (editor: IEditor): boolean => {
    const selection = editor?.selection.getSel()
    let protect = false
    if (!editor || questionStep !== 0) return protect
    const content = editor.selection.getContent()

    if (content.includes('<mark id=') || content.includes('</mark>')) {
      editor.setContent(htmlData)
      if (questionStep === 0) {
        editor
          .getBody()
          .querySelectorAll('mark')
          .forEach(el => el.setAttribute('contenteditable', 'false'))
      }
      toast('warning', 'Text mit verbundener Frage kann nicht gelöscht werden')
      return true
    }

    const range = selection?.getRangeAt(0)

    // prevent editing within an mark element
    if (range) {
      const { startContainer, endContainer } = range
      if (
        (startContainer.parentElement?.nodeName === 'MARK' || endContainer.parentElement?.nodeName === 'MARK') &&
        startContainer.parentElement === endContainer.parentElement
      ) {
        protect = true
      }
    }

    if (protect) {
      editor.setContent(htmlData)
      if (questionStep === 0) {
        editor
          .getBody()
          .querySelectorAll('mark')
          .forEach(el => el.setAttribute('contenteditable', 'false'))
      }
      toast('warning', 'Text mit verbundener Frage kann nicht gelöscht werden')
    }

    return protect
  }

  const getMarkElementColor = (markEl: Element): string => {
    let color = primary.main
    const parentElement = markEl.parentElement
    const parentIsMark = parentElement?.nodeName === 'MARK'
    if (parentIsMark && parentElement) {
      if (parentElement.dataset.qid === question?.id) {
        color = primary.dark
      }
      if (parentElement.parentElement?.dataset.qid === question?.id) {
        color = primary.dark2
      }
    }
    return color
  }

  useEffect(() => {
    const body = editor?.getBody()
    if (!body) return

    const markElements = body.querySelectorAll('mark')

    if (!docXmicro && questionStep > 0) markElements.forEach(mEl => mEl.removeAttribute('contenteditable'))

    if (questionStep === 0) {
      markElements.forEach(mEl => {
        mEl.setAttribute('contenteditable', 'false')
        mEl.removeAttribute('style')
      })
    }

    if (questionStep === 3) {
      if (!docXmicro) {
        body.setAttribute('contenteditable', 'true')
        markElements.forEach(el => {
          const qid = el.getAttribute('data-qid')
          const isThisQuestion = qid === question?.id
          el.setAttribute('contenteditable', isThisQuestion.toString())
        })
      } else if (textSelectionIsActive) {
        body.setAttribute('contenteditable', 'true')
        markElements.forEach(el => el.setAttribute('contenteditable', 'true'))
        body.style.userSelect = ''
        body.style.pointerEvents = ''
      } else {
        body.setAttribute('contenteditable', 'false')
        markElements.forEach(el => el.setAttribute('contenteditable', 'false'))
        body.style.userSelect = 'none'
        body.style.pointerEvents = 'none'
      }
    }

    if (questionStep !== 3) {
      body.setAttribute('contenteditable', 'false')
      if (docXmicro) {
        markElements.forEach(el => el.setAttribute('contenteditable', 'false'))
        body.style.userSelect = 'none'
        body.style.pointerEvents = 'none'
      }
      markElements.forEach(el => el.setAttribute('contenteditable', 'false'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionStep, textSelectionIsActive])

  useEffect(() => {
    const body = editor?.getBody()
    if (!body) return
    // Allow text editing only while on the question overview
    const isEditable = body.getAttribute('contenteditable')
    const showToolbar = questionStep === 0 || (questionStep === 3 && !textSelectionIsActive)

    const contenteditable = questionStep === 0 || questionStep === 3
    if (contenteditable.toString() !== isEditable) {
      if (!docXmicro) {
        body.setAttribute('contenteditable', contenteditable.toString())
        body.style.userSelect = contenteditable ? '' : 'none'
        body.style.pointerEvents = contenteditable ? '' : 'none'
      }
    }
    const toolbarEl = window.document.querySelector('.tox-editor-header')
    if (toolbarEl) toolbarEl.setAttribute('style', `display: ${showToolbar ? 'block' : 'none'} !important;`)

    const markElements = body.querySelectorAll(`mark`)
    markElements.forEach(m => m.classList.remove('marker-clickable'))
    const marks = Array.from(markElements).filter(m => m.dataset.qid === question?.id)
    if (marks.length)
      marks.forEach((m: any) => {
        m.classList.add('marker-clickable')
      })
    if (questionStep === 3) {
      if (textSelectionIsActive) {
        if (!docXmicro)
          markElements.forEach(el => {
            el.setAttribute('contenteditable', 'true')
          })
      } else if (!docXmicro)
        markElements.forEach(el => {
          const qid = el.getAttribute('data-qid')
          const isThisQuestion = qid === question?.id
          el.setAttribute('contenteditable', isThisQuestion.toString())
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionStep, textSelectionIsActive])

  useEffect(() => {
    const body = editor?.getBody()
    if (!body) return
    // Allow text editing only while on the question overview
    if (question && [0, 1].includes(questionStep) && !docXmicro)
      body.querySelectorAll(`mark`).forEach(markEl => {
        markEl.removeAttribute('style')

        if (markEl?.dataset?.qid === question?.id) {
          markEl.setAttribute('style', `background-color: ${getMarkElementColor(markEl)}`)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, questionStep, textSelectionIsActive])

  useEffect(() => {
    const body = editor?.getBody()
    if (!body) return
    // Add correct background to new selected mark element
    if (selectionData?.id && !docXmicro)
      body.querySelectorAll(`mark[id="${selectionData.id}"]`).forEach(markEl => {
        markEl.setAttribute('style', `background-color: ${getMarkElementColor(markEl)}`)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionData])

  const theHeight = window.innerHeight - 152

  useEffect(() => {
    if (htmlData === undefined) {
      StorageService.remove('templateCreationState')
      StorageService.remove('textSelectionHtmlData')
      window.location.reload()
    } else if (docXmicro) editor?.setContent(htmlData)
  }, [htmlData, editor, docXmicro])

  if (!queryState || queryState?.isFetching || !queryState.data || htmlData === undefined) return null

  // const cssData = { _cssData, PLAVABOJA: "color: rgba(0, 0, 255, 0.5)" }

  const stylesFromCssData = Object.entries(cssData).reduce((acc, [key, value]) => {
    return [acc, `${key} { ${value} }`].join(' ')
  }, '')

  const content_style = [
    config.getStyles(masterdata!, 'template-creation', {
      hyphens,
    }),
    stylesFromCssData,
  ].join(' ')

  // console.log(content_style)

  // const container = document.createElement('DIV')
  // container.innerHTML = htmlData
  // console.log('TEMPLATE CREATION EDITOR: ', container)
  // if (editor) {
  //   console.log(editor)
  //   editor.setContent(htmlData)
  //   console.log(editor.getBody())
  // }

  return (
    <Fragment>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY!}
        initialValue={initValue.current}
        onInit={onInit}
        onKeyDown={onKeyDown}
        onMouseUp={handleMouseUp}
        onMouseDown={onMouseDown}
        onPaste={(e, editor) =>
          onPaste(e, editor, () => {
            set({ originalStylesKept: true })
            onSave(editor)
          })
        }
        onCut={onCut}
        onChange={handleChange}
        onBlur={(e, editor) => onSave(editor)}
        // onKeyPress={(e, editor) => onSave(editor)}
        onBeforePaste={onBeforePaste}
        onBeforeExecCommand={onBeforeExecCommand}
        onExecCommand={handleAfterExecCommand}
        init={{
          menubar: false,
          height: theHeight,
          width: 725,
          plugins: config.tc_plugins,
          toolbar: config.tc_toolbar,
          extended_valid_elements: 'span[*] *[*]',
          pagebreak_separator: '<div class="" style="page-break-before: always; visibility: hidden">',
          // paste_as_text: true,
          paste_as_text: false,
          noneditable_noneditable_class: 'mceNonEditable',
          advlist_number_styles: 'default,lower-alpha,lower-roman',
          language: 'de',
          body_id: 'template-creation',
          paste_preprocess: (plugin: any, args: any) => {
            args.content = args.content.replaceAll('<br />', '</p><p>')
          },
          paste_postprocess: (plugin: any, args: any) => {},
          // formats: {
          //   hilitecolor: {
          //     inline: 'mark',
          //     classes: 'hilitecolor',
          //     styles: {},
          //     attributes: {},
          //   },
          // },
          setup: (editor: IEditor) =>
            config.setup(editor, {
              onSave,
              onHyphenChange,
              onLogoUpload,
            }),
          content_style,
          automatic_uploads: true,
          style_formats: [],
          contextmenu: false,
          branding: false,
          fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
          font_formats: config.font_formats,
          resize: false,
          statusbar: false,
          body_class: 'tiny-mce-body-class',
          paste_block_drop: true,
          visual: false,
        }}
      />
    </Fragment>
  )
}
