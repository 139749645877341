import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { useMasterData } from 'queries/app/useMasterdata'
import { useUser } from 'queries/user/useUser'
import { useLegalAdviceEvents } from '../../../hooks/useLegalAdviceEvents'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import EventsListScheduled from 'pages/Authenticated/LegalAdvice/components/EventsList/EventListScheduled'
import TipsChecklist from 'pages/Authenticated/LegalAdvice/components/TipsChecklist/TipsChecklist'
import NoItemsAction from 'components/common/NoItemsAction/NoItemsAction'

import * as content from '../content'
import events_svg from 'assets/icons/book-lawyer/events.svg'
import no_event_svg from '../../../assets/events-empty.svg'
import { status } from 'config/themes/colors'
import { USER_TYPE_CLIENT_ADMIN } from 'constants/user-types'
import { legalAdviceRoutes } from 'constants/routes'

const UpcomingEventsPage: React.FC = () => {
  const allowBookLawyer = useMasterData()?.client.lawyer_booking_open
  const user = useUser()
  const { check } = useSubscriptionCheck()
  const { upcomingEvents } = useLegalAdviceEvents()
  const { push } = useHistory()

  const handleBeginEvent = (link: string | null): void => {
    if (link) window.open(link)
  }

  const handleOpenBookLawyer = (): void => {
    if (!allowBookLawyer) return
    if (!check()) return
    push(legalAdviceRoutes.bookLawyer)
  }

  const actionProps: { actionText?: string; handleActionClick?: () => void } = {}
  if (user?.type === USER_TYPE_CLIENT_ADMIN) {
    actionProps.actionText = 'Terminanfrage erstellen'
    actionProps.handleActionClick = handleOpenBookLawyer
  }

  return (
    <Fragment>
      {upcomingEvents.length ? (
        <Fragment>
          <EventsListScheduled
            eventItems={upcomingEvents}
            handleBeginEvent={handleBeginEvent}
            // handleCancelEvent={handleCancelEvent}
            userType={USER_TYPE_CLIENT_ADMIN}
          />
          <TipsChecklist
            heading={content.checkListHeading}
            headText={content.checkListHeadText}
            bodyText={content.checkListBodyText}
            listItems={content.checkListItems}
            image={events_svg}
            icon={{ name: 'check', fill: status.success }}
          />
        </Fragment>
      ) : (
        <NoItemsAction
          text='Sie haben noch keine geplanten Beratungstermine'
          image={no_event_svg}
          {...actionProps}
        />
      )}
    </Fragment>
  )
}

export default UpcomingEventsPage