import { fade, styled, Theme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
interface P {
  theme: Theme
  bgopacity: number
}

export const HeaderContainer = styled(Box)(({ theme, bgopacity }: P) => ({
  padding: theme.spacing(0, 6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 56,
  flexShrink: 0,
  width: '100%',
  backgroundColor: fade(theme.colorz.brand.dark, bgopacity),
  position: 'sticky',
  top: 0,
  zIndex: 100,
}))
