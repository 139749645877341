import React from 'react'
import { TemplateAuthor } from 'types'
import { AuthorContainer, AuthorDetails, Typography } from './Author.styles'
import ProfileImage from 'components/user/ProfileImage/ProfileImage'

interface Props {
  author: TemplateAuthor
}

const Author: React.FC<Props> = ({ author }) => {
  const { firstName, lastName, companyName, imageUrl, jobPosition } = author
  return (
    <AuthorContainer>
      <ProfileImage
        imageUrl={imageUrl}
        firstName={firstName}
        lastName={lastName}
        size={40}
        style={{ borderRadius: '50%' }}
      />
      <AuthorDetails>
        <Typography variant='h5'>{`${firstName} ${lastName}`}</Typography>
        {jobPosition && (
          <Typography variant='subtitle2' style={{ fontWeight: 500 }}>
            {jobPosition}
          </Typography>
        )}
        <Typography variant='subtitle2' style={{ whiteSpace: 'nowrap' }}>
          {companyName}
        </Typography>
      </AuthorDetails>
    </AuthorContainer>
  )
}

export default Author
