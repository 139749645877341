import { TQuestionTypes } from 'types'
import React, { Fragment } from 'react'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useEditor } from 'config/textEditor/useEditor'

import ActionButtons from '../../ActionButtons/ActionButtons'
import QuestionTypesComponent from './QuestionTypes.component'
import questionTypes from 'constants/question-types'
import { checkIfQuestionHasValues, removeHighlightItemsByQuestionId } from 'utils'
import {
  btnContent,
  forms as content,
} from 'pages/Authenticated/Template/constants/content'
import { ScrollWrapper } from '../../../TemplateEdit.styles'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'
import { generateQuestionPropsByType } from '../../../services'

const { TEXT_BOX, DATE, CHECKBOX, RADIO_LINK, NUMBER } = questionTypes

export const QuestionTypesContainer: React.FC = () => {
  const editor = useEditor()
  const { set, question, selectedQuestionType } = useTemplateCreation()

  const { type } = question!

  const checkIfCompatible = (): boolean => {
    const compatibleQuestions = [TEXT_BOX, DATE]
    return (
      compatibleQuestions.includes(type) &&
      compatibleQuestions.includes(selectedQuestionType)
    )
  }

  const onQuestionTypeChange = (type: TQuestionTypes) => {
    const isCompatible = checkIfCompatible()
    if (!question) return

    let updatedQuestion: any = { ...question }
    updatedQuestion.type = type
    let requireConfirm = false

    if (!isCompatible) {
      requireConfirm = checkIfQuestionHasValues(question)
      updatedQuestion = {
        ...question,
        ...generateQuestionPropsByType(type),
      }
    }

    if ([DATE, TEXT_BOX, NUMBER].includes(type)) {
      delete updatedQuestion.options
    }

    if (type !== NUMBER) {
      delete updatedQuestion.rangeFrom
      delete updatedQuestion.rangeTo
      delete updatedQuestion.rangeStep
    }

    if ([RADIO_LINK, CHECKBOX].includes(type)) {
      delete updatedQuestion.locations
    }

    if (requireConfirm) {
      if (!window.confirm(content.confirmText)) {
        return set({ selectedQuestionType: question.type })
      }
      removeHighlightItemsByQuestionId(question?.id, editor!)
    }

    trackIntercomEvent(`template-creation-question-type=${updatedQuestion.type}`)

    set({ question: updatedQuestion, questionStep: 3 })
  }

  const onNext = () => {
    if (selectedQuestionType !== type) {
      return onQuestionTypeChange(selectedQuestionType)
    }

    trackIntercomEvent(`template-creation-question-type=${type}`)

    set({ questionStep: 3 })
  }

  const onQuestionTypeSelect = (questionType: TQuestionTypes) =>
    set({ selectedQuestionType: questionType })

  return (
    <Fragment>
      <ScrollWrapper>
        <QuestionTypesComponent
          selectedQuestionType={selectedQuestionType}
          handleSelectQuestionType={onQuestionTypeSelect}
        />
      </ScrollWrapper>
      <ActionButtons
        handleBackClick={() => set({ questionStep: 1 })}
        handleForwardClick={onNext}
        forwardDisabled={!selectedQuestionType}
        btnNextText={btnContent.next}
      />
    </Fragment>
  )
}

export default QuestionTypesContainer
