import { styled } from '@material-ui/core'
import React, { useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'

import { useUserSubscription } from 'components/subscriptions/hooks/useUserSubscription'

import withStripe from 'hoc/withStripe'

import PlanItem from './PlanItem'
import TabItems from './TabItems'

import * as content from '../../../../../../components/subscriptions/content'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 3 * 324 + 60,
})

const Row = styled('div')({
  display: 'flex',
  '&:not(:last-child)': { marginBottom: 30 },
})

const PlanItems: React.FC = () => {
  const stripe = useStripe()

  const { subscribe, redirectToPortal, isLoading, isSubscribed, subscriptionDetails } =
    useUserSubscription(stripe)

  const [interval, setInterval] = useState(subscriptionDetails?.interval ?? 'month')
  const isMonthly = interval === 'month'

  return (
    <>
      <TabItems currentTab={interval} onChange={val => setInterval(val)} />
      <Container>
        <Row>
          <PlanItem
            {...content.startupPlan}
            interval={interval}
            isLoading={isLoading}
            selected={
              isSubscribed
                ? subscriptionDetails?.type === 'startup' &&
                  subscriptionDetails.interval === interval
                : null
            }
            isSubscribed={isSubscribed}
            onAction={() =>
              isSubscribed
                ? redirectToPortal()
                : subscribe(
                    isMonthly ? 'subscription_startup_month' : 'subscription_startup_year'
                  )
            }
          />
          <PlanItem
            {...content.basicPlan}
            interval={interval}
            isLoading={isLoading}
            selected={
              isSubscribed
                ? subscriptionDetails?.type === 'basic' &&
                  subscriptionDetails.interval === interval
                : null
            }
            isSubscribed={isSubscribed}
            onAction={() =>
              isSubscribed
                ? redirectToPortal()
                : subscribe(
                    isMonthly ? 'subscription_basic_month' : 'subscription_basic_year'
                  )
            }
          />
          <PlanItem
            {...content.teamPlan}
            interval={interval}
            isLoading={isLoading}
            selected={isSubscribed ? subscriptionDetails?.type === 'team' : null}
            isSubscribed={isSubscribed}
            onAction={() =>
              isSubscribed
                ? redirectToPortal()
                : subscribe(
                    isMonthly ? 'subscription_team_month' : 'subscription_team_year'
                  )
            }
          />
        </Row>
        <PlanItem
          {...content.enterprisePlan}
          interval={interval}
          isLoading={isLoading}
          selected={false}
          isSubscribed={isSubscribed}
          onAction={() => window.open('https://casus.ch/kontakt.html', '_blank')}
        />
      </Container>
    </>
  )
}

export default withStripe(PlanItems)
