import { TipsChecklistProps } from './TipsChecklist.types'
import React, { useState } from 'react'
import {
  Container,
  Content,
  Head,
  Body,
  Heading,
  Text,
  Image,
  ActionText,
  Collapse,
} from './TipsChecklist.styles'
import CheckList from './CheckList'

const TipsChecklist: React.FC<TipsChecklistProps> = ({
  heading,
  headText,
  bodyText,
  image,
  icon,
  listItems,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Container data-intercom-target="prepare-for-meeting-intercom">
      <Content>
        <Head>
          <Heading>{heading}</Heading>
          <Text>{headText}</Text>
        </Head>
        <Collapse in={open} timeout={{ exit: 200, enter: 200 }}>
          <Body>
            <Text>{bodyText}</Text>
            <CheckList icon={icon} items={listItems} />
          </Body>
        </Collapse>
        <ActionText onClick={() => setOpen(prev => !prev)}>
          {!open ? 'Checkliste anzeigen' : 'Checkliste schliessen'}
        </ActionText>
      </Content>
      <Image src={image} alt='image' />
    </Container>
  )
}

export default TipsChecklist
