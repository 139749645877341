import { IAnswer, Question } from 'types'
import { deepFindQuestion } from 'utils'

export const validateAnswers = (answers: IAnswer[], questions: Question[]): IAnswer[] => {
  const checkedAnswers: IAnswer[] = []
  for (let answer of answers) {
    const question = deepFindQuestion(questions, answer.questionId)
    if (question) checkedAnswers.push(answer)
  }
  return checkedAnswers
}
