import { styled, Typography, Box } from '@material-ui/core'
import theme from 'config/themes/theme'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}))

export const ItemTitle = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fontWeight: 700,
  fontSize: 18,
  letterSpacing: 0.45,
  marginBottom: theme.spacing(5),
}))

export const labelStyles = {
  color: theme.colorz.secondary[300],
  fontSize: 16,
  marginBottom: theme.spacing(1),
}
