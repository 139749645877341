import { Box, styled, Typography } from '@material-ui/core'
import theme from 'config/themes/theme'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  width: 864,
  padding: theme.spacing(2),
  minHeight: 324,
}))

export const labelStyle: React.CSSProperties = {
  color: theme.colorz.secondary[300],
  fontSize: 14,
  letterSpacing: 0.2,
}

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  marginBottom: theme.spacing(1),
  letterSpacing: 0.5,
  fontWeight: 500,
  textAlign: 'center',
}))
