import { StepsProps } from './Steps.types'
import React from 'react'
import Step from './Step'
import { StepsContainer } from './Steps.styles'

const Steps: React.FC<StepsProps> = ({ steps, currentStep }) => {
  return (
    <StepsContainer>
      {steps.map((step, i) => (
        <Step key={i} currentStep={currentStep} {...step} />
      ))}
    </StepsContainer>
  )
}

export default Steps
