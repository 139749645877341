import customPallete, { primary, secondary, status } from './colors'
import createMyTheme from './createMyTheme'

const theme = createMyTheme({
  palette: {
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
    },
    secondary: {
      main: secondary.main,
      light: secondary[200],
    },
    error: {
      main: status.error,
    },
    success: {
      main: status.success,
    },
    warning: {
      main: status.warning,
    },
    info: {
      main: status.info,
    },
  },
  colorz: customPallete,
  typography: {
    fontFamily: 'Heebo, sans-serif',
    h3: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0.4,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 0.45,
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: 0.4,
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0.4,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.3,
    },
    subtitle2: {
      fontSize: 13,
      letterSpacing: 0.18,
      fontWeight: 400,
    },
  },
  spacing: factor => `${8 * factor}px`,
})

theme.shadows[20] = '0 2px 14px rgba(218,213,210,.5)'

export default theme
