import { RootFolder, FolderItem, FolderItemSimple, TemplateType } from 'types'

type ReturnData = [RootFolder, FolderItem]

export class FileManager {
  public data: RootFolder
  public current: FolderItem
  public constructor(public folder: RootFolder, public currentFolder: FolderItem) {
    this.data = { ...folder }
    this.current = { ...currentFolder }
  }

  private update(main: FolderItem | RootFolder, changedFolder: FolderItem): void {
    let found = false
    if (main.id === changedFolder.id) {
      found = true
      // remove duplicates
      main = changedFolder
      return
    }
    for (let childFolder of main.children) {
      if (found) break
      this.update(childFolder, changedFolder)
    }
  }

  private updateDataWithCurrent(): void {
    if (this.data.type !== 'root') {
      this.update(this.data, this.current)
    }

    if (this.data.type === 'root') {
      this.data.children.forEach(childFolder => {
        if (childFolder.type === this.current.type) this.update(childFolder, this.current)
      })
    }
  }

  public transformFolderFromDB(folderFromDB: FolderItemSimple): FolderItem {
    const { id, name, authorId, sharedWith, sharingEnabled, author } = folderFromDB
    const { type } = this.current
    const path = [...this.current.path, { id: this.current.id, name: this.current.name }]

    return {
      id,
      name,
      type,
      path,
      authorId,
      sharedWith,
      sharingEnabled,
      author,
      children: [],
    }
  }

  private setPathAndTypeFromParent(
    parentFolder: FolderItem,
    childFolder: FolderItem
  ): FolderItem {
    const path = [...parentFolder.path, { id: parentFolder.id, name: parentFolder.name }]
    return { ...childFolder, path, type: parentFolder.type }
  }

  private remapChildrenAfterMove(folder: FolderItem) {
    folder.children.forEach(childFolder => {
      childFolder = this.setPathAndTypeFromParent(folder, childFolder)
      this.remapChildrenAfterMove(childFolder)
    })
  }

  public pushFoldersFromDB(foldersFromDB: FolderItemSimple[]): ReturnData {
    let newFolders = foldersFromDB.map(this.transformFolderFromDB.bind(this))
    // this.current.children.forEach(child => (map[child.id] = true))
    newFolders = newFolders.map(newFolder => {
      const existing = this.current.children.find(f => f.id === newFolder.id)
      if (existing) {
        return { ...newFolder, children: existing.children }
      }
      return newFolder
    })
    this.current.children.splice(0, this.current.children.length)
    this.current.children.push(...newFolders)
    // newFolders = newFolders.filter(newFolderItem => map[newFolderItem.id] === undefined)
    this.updateDataWithCurrent()

    return [this.data, this.current]
  }

  public addFolderFromDBToNestedFolder(folderFromDB: FolderItemSimple) {}

  public addNewFolder(folderFromDB: FolderItemSimple): ReturnData {
    this.current.children.push(this.transformFolderFromDB(folderFromDB))
    this.updateDataWithCurrent()
    return [this.data, this.current]
  }

  public editFolder(editedFolder: FolderItem): ReturnData {
    const index = this.current.children.findIndex(f => f.id === editedFolder.id)
    if (index > -1) {
      this.current.children.splice(index, 1, editedFolder)
    }
    this.updateDataWithCurrent()
    return [this.data, this.current]
  }

  public deleteFolder(folderId: string): ReturnData {
    const index = this.current.children.findIndex(f => f.id === folderId)
    if (index > -1) {
      this.current.children.splice(index, 1)
    }
    this.updateDataWithCurrent()
    return [this.data, this.current]
  }

  public moveToRoot(folderId: string, root: TemplateType) {
    const theFolder = this.current.children.find(
      childFolder => childFolder.id === folderId
    )
    const destinationFolder = this.data.children.find(
      childFolder => childFolder.type === root
    )
    if (!theFolder || !destinationFolder) return console.error('Folder not found')
    this.moveFolder(theFolder, destinationFolder)
  }

  public restoreFolder(folderFromDb: FolderItemSimple) {
    if (!folderFromDb.parentCategoryId) return this.moveToRoot(folderFromDb.id, 'my')
    const previousParent = this.findFolderById(folderFromDb.parentCategoryId)
    if (!previousParent) this.moveToRoot(folderFromDb.id, 'my')

    const index = this.current.children.findIndex(f => f.id === folderFromDb.id)
    if (index > -1) {
      this.current.children.splice(index, 1)
    }
    if (!previousParent) return

    const restoredFolder = this.transformFolderFromDB(folderFromDb)
    let updatedFolder = this.setPathAndTypeFromParent(previousParent, restoredFolder)
    this.remapChildrenAfterMove(updatedFolder)
    previousParent.children.push(updatedFolder)
    this.update(this.data, previousParent)
    this.updateDataWithCurrent()
  }

  public findFolderById(folderId: string): FolderItem | undefined {
    let foldersToCheck = [...this.data.children]
    let theF = foldersToCheck.pop()
    while (theF) {
      if (theF.id === folderId) return theF
      foldersToCheck.push(...theF.children)
      theF = foldersToCheck.pop()
    }
  }

  public moveFolder(folderToMove: FolderItem, destinationFolder: FolderItem): ReturnData {
    let folderToMoveIndex = this.current.children.findIndex(
      childFolder => childFolder.id === folderToMove.id
    )
    if (folderToMoveIndex === -1) return [this.data, this.current]

    this.current.children.splice(folderToMoveIndex, 1)
    const updatedFolder = this.setPathAndTypeFromParent(destinationFolder, folderToMove)
    // TODO UPDATE PATH FOR ALL CHILDREN
    destinationFolder.children.push(updatedFolder)

    this.updateDataWithCurrent()
    this.update(this.data, destinationFolder)

    return [this.data, this.current]
  }
}
