import { auth, firebase } from 'config/firebase'
import Api from 'api/api'
import UserApi from 'api/user.api'
import { AuthCalendlyResponseData, User } from 'types'
import { UserType } from 'constants/user-types'
import { StorageService } from 'utils'

export interface IRegisterUserRequestPayload {
  email: string
  password: string
  type: UserType
  code?: string
  subscription?: 'free' | 'premium'
}

interface ICreatePasswordApi {
  email: string
  code: string
  newPassword: string
}

interface IUpdatePasswordApi {
  passwordCurrent: string
  passwordNew: string
}

class AuthApi extends Api {
  private auth: firebase.auth.Auth
  public constructor(firebaseAuth: firebase.auth.Auth) {
    super('/auth')
    this.auth = firebaseAuth
  }

  public setAuthHeaders(token: string | null, expirationTime: string | null) {
    if (token && expirationTime) {
      localStorage.setItem('casusToken', token)
      localStorage.setItem('casusExpiresIn', expirationTime)
    } else {
      localStorage.removeItem('casusToken')
      localStorage.removeItem('casusExpiresIn')
    }
  }

  public authenticate = async (
    firebaseUser: firebase.User,
    user?: User
  ): Promise<User> => {
    const { token, expirationTime } = await firebaseUser.getIdTokenResult(true)
    this.setAuthHeaders(token, expirationTime)
    return user && firebaseUser.uid === user.id ? user : await UserApi.getMe()
  }

  public setCalendlyAuth(data: AuthCalendlyResponseData | null) {
    if (!data) {
      StorageService.remove('calendlyAuth')
    } else {
      StorageService.save('calendlyAuth', data)
    }
  }

  public getCalendlyAuthFromLS(): AuthCalendlyResponseData | null {
    const calendlyAuthDataString = localStorage.getItem('calendlyAuth')
    if (!calendlyAuthDataString) return null
    return JSON.parse(calendlyAuthDataString) as AuthCalendlyResponseData
  }

  // public processFirebaseUser = async (user: firebase.User) => {}
  public registerUser = async (data: IRegisterUserRequestPayload) => {
    await this.api().post<void>('/register', data)
    await this.loginUser(data.email, data.password)
  }

  public loginUser = async (email: string, password: string) => {
    return await auth.signInWithEmailAndPassword(email, password)
  }

  public loginUserWithPhone = async (phoneNumber: string, applicationVerifier: firebase.auth.ApplicationVerifier) => {
    return await auth.signInWithPhoneNumber(phoneNumber, applicationVerifier)
  }

  public requestResetPasswordEmail = async (email: string) => {
    return await this.api().post('/request/email/reset', { email })
  }

  public createNewPassword = async (data: ICreatePasswordApi) => {
    return await this.api().post('/confirm/email/reset', data)
  }

  public updatePassword = async (data: IUpdatePasswordApi) => {
    const user: firebase.User | null = auth.currentUser
    if (!user) return (window.location.href = '/logout')

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email!,
      data.passwordCurrent
    )
    const reAuthRes = await user.reauthenticateWithCredential(credential)
    if (!reAuthRes.user)
      return console.error('X0003', 'Error while reauthenticating user')
    await auth.currentUser?.updatePassword(data.passwordNew)
  }

  public logoutUser = async (): Promise<void> => {
    return await auth
      .signOut()
      .catch(e => {
        // An error happened.
        console.error('E0002', e)
      })
      .finally(() => {
        this.setAuthHeaders(null, null)
      })
  }
}

const api = new AuthApi(auth)

export default api
