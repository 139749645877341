import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Layout from './layout/Layout'
import TeamMembersList from './components/TeamMembersList/TeamMembersList.container'
import AddNewTeamMember from './components/AddNewTeamMember/AddNewTeamMember.container'
import { useLoadTeamMembers } from 'pages/Authenticated/Team/hooks/useLoadTeamMembers'

const TeamPage: React.FC = () => {
  const { data } = useLoadTeamMembers()
  return (
    <Layout isLoading={!data}>
      <Switch>
        <Route exact path={'/team'} component={TeamMembersList} />
        <Redirect to='/team' />
      </Switch>
      <AddNewTeamMember />
    </Layout>
  )
}

export default TeamPage
