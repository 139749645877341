import { SettingsCardItemProps } from './SettingsCardItem.types'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import React, { useState, useRef } from 'react'
import parser from 'html-react-parser'
import { MenuPopover, Icon } from 'components/common'
import { Container, CardWrapper, iconStyles } from './SettingsCardItem.styles'
import { CardFooterActions } from './CardFooterActions'
import { CardHeaderActions } from './CardHeaderActions'
import { createFooterHtml, createHeaderHtml } from 'config/textEditor/services/text-editor'

export const SettingsCardItem: React.FC<SettingsCardItemProps> = ({
  item,
  style,
  HeaderActionProps,
  FooterActionProps,
  handleDelete,
  handleEdit,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null)
  const containerEl = useRef<HTMLDivElement>(null)

  const menuItems: IPopupMenuItem[] = []
  if (handleDelete)
    menuItems.push({
      label: 'Löschen',
      handleClick: () => handleDelete(item?.id!),
      icon: 'delete',
    })

  if (handleEdit)
    menuItems.push({
      label: 'Bearbeiten',
      handleClick: () => handleEdit(item?.id!),
      icon: 'edit',
    })

  const parseContent = () => {
    if (!item.imageURL && item.text) {
      return parser(createFooterHtml('1'))
    }

    if (item.imageURL) {
      return parser(createHeaderHtml(item.imageURL, item.imageHeight))
    }

    return null
  }

  return (
    <Container ref={containerEl} style={style}>
      <CardWrapper>{parseContent()}</CardWrapper>
      {FooterActionProps && <CardFooterActions item={item} {...FooterActionProps} />}
      {HeaderActionProps && <CardHeaderActions item={item} {...HeaderActionProps} />}

      {menuItems.length ? (
        <Icon
          iconName='more'
          style={iconStyles}
          onClick={() => setAnchorEl(containerEl.current)}
        />
      ) : null}
      {anchorEl && (
        <MenuPopover
          menuItems={menuItems}
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
    </Container>
  )
}
