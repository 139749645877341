import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useCalendlyAuthData } from 'pages/Authenticated/LegalAdvice/hooks/useCalendlyAuthData'
import { useUser } from 'queries/user/useUser'

import UserApi from 'api/user.api'
import AuthApi from 'api/auth.api'
import { documentsRoutes, legalAdviceRoutes } from 'constants/routes'

export const useAuthenticateCalendly = () => {
  const { location, replace } = useHistory()
  const data = useCalendlyAuthData()
  const user = useUser()
  const [checked, setChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { pathname, search } = location

  const authenticate = useCallback(async () => {
    if (checked) return
    setChecked(true)
    let calendlyCode: null | string = null
    if (pathname === '/') {
      calendlyCode = search.split('?code=')[1]
    } else {
      calendlyCode = null
    }

    const code = !!calendlyCode && !data && !!user && calendlyCode
    if (code) {
      try {
        setIsLoading(true)
        const calendlyAuthData = await UserApi.authenticateCalendly(code)
        AuthApi.setCalendlyAuth(calendlyAuthData as any)
        replace(legalAdviceRoutes.main)
      } catch (err) {
        replace(documentsRoutes.main)
      } finally {
        setIsLoading(false)
      }
    }
  }, [pathname, search, replace, checked, data, user])

  useEffect(() => {
    authenticate()
  }, [authenticate])

  return { isLoading }
}
