import React from 'react'
import { CASUS_CLASSES } from 'utilities/parsing'

export const classes = {
  page: 'Wizard-Editor-Page',
  content: 'Wizard-Editor-content',
}

export const Page = React.memo(props => {
  const { children } = props

  const contentClassName = [classes.content, CASUS_CLASSES.pageContentRoot].join(' ')

  return (
    <div className={classes.page}>
      <div className={contentClassName}>{children}</div>
    </div>
  )
})

Page.displayName = 'Wizard-Editor-Page'

export default Page
