import React from 'react'
import {StandardModal, CustomButton, TextInputGroup} from 'components/common'
import {Container, Heading, InputWrapper} from './SmsVerifyModal.styles'

interface Props {
    open?: boolean
    smsCode: string
    setSmsCode: (code: string) => void
    onAction: () => void
    onClose: () => void
}

const SmsVerifyModal: React.FC<Props> = ({
                                             open = true,
                                             smsCode,
                                             setSmsCode,
                                             onAction,
                                             onClose,
                                         }) => {

    return (
        <React.Fragment>
            <div id={'sign-in-button'}></div>
            <StandardModal open={open} handleClose={onClose} RootModalProps={{withBg: true}}>
                <Container>
                    <Heading variant='h3'>{'Bestätigungscode eingeben'}</Heading>
                    <InputWrapper>
                        <TextInputGroup
                            type='text'
                            label=''
                            name='sms-verify'
                            value={smsCode}
                            placeholder='SMS Bestätigungscode'
                            onChange={(event) => {
                                // @ts-ignore
                                setSmsCode(event.target.value)
                            }}
                            required
                        />
                    </InputWrapper>
                    <CustomButton
                        btntype='primary'
                        size='large'
                        width={208}
                        onClick={onAction}
                    >
                        Bestätigen
                    </CustomButton>
                </Container>
            </StandardModal>
        </React.Fragment>
    )
}

export default SmsVerifyModal
