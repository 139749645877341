import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const ScreenContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  backgroundColor: theme.colorz.brand.light,
  position: 'relative',
}))

export const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto',
  height: 'calc(100vh - 112px)',
  paddingBottom: '10vh',
})

export const InputGroupContainer = styled('div')({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '10vh',
  marginTop: '10vh',
})

export const ButtonsContainer = styled(Box)({
  width: '100%',
  display: 'flex',
})
