import React, { useState } from 'react'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import withStripe, { StripeContainerComponentProps } from 'hoc/withStripeCard'
import StripeCardComponent from 'components/stripe/components/StripeCard/StripeCard.component'
import { useToast } from 'hooks/useToast'

const StripeCard: React.FC<StripeContainerComponentProps> = props => {
  const stripe = useStripe()
  const toast = useToast()
  const elements = useElements()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!stripe) return
    const cardElement = elements?.getElement(CardNumberElement)!

    if (name.split(' ').length < 2 && cardElement)
      return toast('error', 'Please enter full name of the card owner.')

    props.handleStripeSubmit(stripe, {
      type: 'card',
      card: cardElement,
      billing_details: { name },
    })
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    if (!value) setNameError(true)
    if (value && nameError) setNameError(false)
    setName(value)
  }

  return (
    <StripeCardComponent
      name={name}
      error={nameError}
      stripe={stripe}
      isLoading={false}
      charging={!props?.charging}
      {...props}
      handleSubmit={handleSubmit}
      handleNameChange={handleNameChange}
    />
  )
}

export default withStripe(StripeCard)
