import React from 'react'
import cSign from './c-sign.svg'

import { Box, styled, BoxProps } from '@material-ui/core'
import { stringToColor } from 'utils'

const Wrapper = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  marginRight: theme.spacing(1),
}))

interface CSignElProps extends BoxProps {
  logocolor: string
}

const CSign = styled(Box)(({ logocolor }: CSignElProps) => ({
  backgroundColor: logocolor && logocolor,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 20,
  borderRadius: 2,
}))

const Logo = styled('img')({
  width: 12,
})

interface Props {
  category: string
}

const C_Logo: React.FC<Props> = ({ category = '123' }) => (
  <Wrapper>
    <CSign logocolor={stringToColor(category)}>
      <Logo src={cSign} alt='CASUS' />
    </CSign>
  </Wrapper>
)

export default C_Logo
