import { OptionListProps } from './OptionList.types'
import React, { Fragment } from 'react'
import Option from './Option/Option'

const OptionList: React.FC<OptionListProps> = props => {
  const { options, values, ...otherProps } = props
  return (
    <Fragment>
      {options.map((opt, i) => {
        const val = values && values[opt.id] ? values[opt.id] : { id: '', text: '' }
        return (
          <Option
            key={opt.id}
            label={'Option ' + (i + 1)}
            value={otherProps.subQuestions ? opt : val}
            option={opt}
            {...otherProps}
          />
        )
      })}
    </Fragment>
  )
}

export default OptionList
