import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const AnswerTypesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  marginTop: theme.spacing(2),
}))

export { Typography }
