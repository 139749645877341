import { useQueryClient } from 'react-query'
import { queryKeys } from 'queries/queryKeys'
import { ITemplateExtended } from 'types'
import { useCurrentFolder } from 'hooks/useCurrentFolder'

interface Data {
  pageParams: [string | undefined]
  pages: ITemplateExtended[][]
}

export const useTemplates = (): ITemplateExtended[] | undefined => {
  const {
    currentFolder: { type, id },
  } = useCurrentFolder('templates')
  const queryClient = useQueryClient()
  const keys = [queryKeys.TEMPLATES, { type, folderId: id }]
  const data = queryClient?.getQueryData<Data>(keys)
  return data?.pages.flat()
}
