import { styled, Box, Typography, Collapse } from '@material-ui/core'

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  padding: 14,
  display: 'flex',
  boxShadow: '0 2px 0 #F3EEEA',
  borderRadius: 4,
  position: 'relative',
  overflow: 'hidden',
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  border: '1px solid ' + theme.colorz.brand.main,
  padding: theme.spacing(7, 0, 7, 5),
  overflow: 'hidden',
}))

export const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Body = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 0, 6, 0),
}))

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.colorz.secondary.main,
  letterSpacing: 0.45,
  marginBottom: theme.spacing(1.5),
  position: 'relative',
  zIndex: 3,
}))

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  width: 440,
}))

export const Image = styled('img')({
  position: 'absolute',
  top: 30,
  right: 56,
  width: 280,
})

export const ActionText = styled(Typography)(({ theme }) => ({
  color: theme.colorz.primary.main,
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0.35,
  textTransform: 'uppercase',
  cursor: 'pointer',
  transition: 'all .2s',
  marginTop: theme.spacing(3),
  '&:hover': {
    color: theme.colorz.primary.dark,
  },
}))

// CHECKLIST

export const ChecklistContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'column',
}))

export const CheckItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1.5),
  },
}))

export const ChecklistItemText = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.4,
}))

export { Collapse }
