import { DocumentSettingsUploadLogoComponent } from './HeaderSettings.types'
import React from 'react'
import { CustomButton, FilePicker } from 'components/common'
import { UploadLogoContainer } from './HeaderSettings.styles'

export const UploadLogo: React.FC<DocumentSettingsUploadLogoComponent> = ({
  setFile,
}) => {
  return (
    <UploadLogoContainer>
      <FilePicker accept='image/*' setFile={setFile}>
        <CustomButton btntype='secondary' width={216}>
          Logo auswählen
        </CustomButton>
      </FilePicker>
    </UploadLogoContainer>
  )
}
