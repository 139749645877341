import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import DocumentsList from './views/DocumentsList/DocumentsList.page'
import DocumentSettings from './views/DocumentSettings/DocumentSettings.page'
import Reminders from './views/Reminders/Reminders.page'

import { DocumentsLayout } from './layout/Layout'
import { documentsRoutes } from 'constants/routes'

const DocumentsPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Aktenschrank | CASUS'
  }, [])

  return (
    <DocumentsLayout>
      <Switch>
        <Route path={documentsRoutes.reminders} component={Reminders} />
        <Route path={documentsRoutes.settings} component={DocumentSettings} />
        <Route exact path={documentsRoutes.main} component={DocumentsList} />
        <Redirect to={documentsRoutes.main} />
      </Switch>
    </DocumentsLayout>
  )
}

export default DocumentsPage
