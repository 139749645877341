const str = `<svg width="176" height="176" viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="46" y="63" width="128" height="104" fill="#F3EEEA"/>
<path d="M25.4448 126.477C24.0236 126.477 22.8715 127.63 22.8715 129.053V130.787C22.8715 132.209 24.0236 133.363 25.4448 133.363C26.866 133.363 28.0182 132.209 28.0182 130.787V129.053C28.0182 127.63 26.866 126.477 25.4448 126.477Z" fill="#F25817"/>
<path d="M25.4448 152.09C24.0236 152.09 22.8715 153.243 22.8715 154.666V156.4C22.8715 157.822 24.0236 158.976 25.4448 158.976C26.866 158.976 28.0182 157.822 28.0182 156.4V154.666C28.0182 153.243 26.866 152.09 25.4448 152.09Z" fill="#F25817"/>
<path d="M33.8886 136.86C34.5467 136.86 35.2048 136.609 35.7071 136.106L36.933 134.88C37.9387 133.875 37.9394 132.244 36.9351 131.237C35.9305 130.231 34.301 130.23 33.296 131.235L32.0701 132.461C31.0644 133.467 31.0637 135.098 32.068 136.104C32.5703 136.608 33.2295 136.86 33.8886 136.86Z" fill="#F25817"/>
<path d="M15.1826 149.346L13.9567 150.572C12.951 151.577 12.9503 153.209 13.9546 154.215C14.4573 154.719 15.1161 154.971 15.7752 154.971C16.4333 154.971 17.0914 154.72 17.5937 154.217L18.8196 152.991C19.8253 151.986 19.826 150.355 18.8217 149.348C17.8174 148.342 16.1879 148.341 15.1826 149.346Z" fill="#F25817"/>
<path d="M39.1197 140.15H37.3856C35.9644 140.15 34.8123 141.303 34.8123 142.726C34.8123 144.149 35.9644 145.302 37.3856 145.302H39.1197C40.5409 145.302 41.6931 144.149 41.6931 142.726C41.6931 141.303 40.5409 140.15 39.1197 140.15Z" fill="#F25817"/>
<path d="M16.0771 142.726C16.0771 141.303 14.9249 140.15 13.5038 140.15H11.7697C10.3485 140.15 9.19629 141.303 9.19629 142.726C9.19629 144.149 10.3485 145.302 11.7697 145.302H13.5038C14.9253 145.302 16.0771 144.149 16.0771 142.726Z" fill="#F25817"/>
<path d="M35.7071 149.346C34.7014 148.341 33.072 148.342 32.068 149.348C31.0637 150.355 31.0644 151.986 32.0701 152.991L33.296 154.217C33.7983 154.72 34.4564 154.971 35.1145 154.971C35.7733 154.971 36.4328 154.719 36.9351 154.215C37.9394 153.208 37.9387 151.577 36.933 150.572L35.7071 149.346Z" fill="#F25817"/>
<path d="M17.5937 131.235C16.588 130.23 14.9586 130.231 13.9546 131.237C12.9503 132.244 12.951 133.875 13.9567 134.88L15.1826 136.106C15.6849 136.609 16.343 136.86 17.0011 136.86C17.6599 136.86 18.3194 136.608 18.8217 136.104C19.826 135.097 19.8253 133.467 18.8196 132.461L17.5937 131.235Z" fill="#F25817"/>
<path d="M144.614 1.23254C143.192 1.23254 142.04 2.38594 142.04 3.80863V5.54217C142.04 6.96485 143.192 8.11825 144.614 8.11825C146.035 8.11825 147.187 6.96485 147.187 5.54217V3.80863C147.187 2.38594 146.035 1.23254 144.614 1.23254Z" fill="#F25817"/>
<path d="M144.614 26.845C143.192 26.845 142.04 27.9984 142.04 29.4211V31.155C142.04 32.5777 143.192 33.7311 144.614 33.7311C146.035 33.7311 147.187 32.5777 147.187 31.155V29.4211C147.187 27.9984 146.035 26.845 144.614 26.845Z" fill="#F25817"/>
<path d="M153.057 11.6152C153.715 11.6152 154.373 11.3641 154.876 10.8616L156.102 9.63573C157.107 8.63037 157.108 6.99919 156.104 5.9928C155.099 4.98641 153.47 4.98538 152.465 5.99074L151.239 7.21662C150.233 8.22198 150.232 9.85316 151.237 10.8595C151.739 11.3634 152.398 11.6152 153.057 11.6152Z" fill="#F25817"/>
<path d="M134.351 24.1017L133.125 25.3275C132.12 26.3329 132.119 27.9641 133.123 28.9705C133.626 29.4744 134.285 29.7261 134.944 29.7261C135.602 29.7261 136.26 29.475 136.762 28.9729L137.988 27.747C138.994 26.7416 138.995 25.1105 137.991 24.1041C136.986 23.097 135.357 23.0963 134.351 24.1017Z" fill="#F25817"/>
<path d="M158.288 14.9057H156.554C155.133 14.9057 153.981 16.0591 153.981 17.4818C153.981 18.9045 155.133 20.0579 156.554 20.0579H158.288C159.71 20.0579 160.862 18.9045 160.862 17.4818C160.862 16.0591 159.71 14.9057 158.288 14.9057Z" fill="#F25817"/>
<path d="M135.246 17.4818C135.246 16.0591 134.094 14.9057 132.672 14.9057H130.938C129.517 14.9057 128.365 16.0591 128.365 17.4818C128.365 18.9045 129.517 20.0579 130.938 20.0579H132.672C134.094 20.0579 135.246 18.9045 135.246 17.4818Z" fill="#F25817"/>
<path d="M154.875 24.1017C153.87 23.0963 152.241 23.0973 151.236 24.1041C150.232 25.1108 150.233 26.7416 151.239 27.747L152.465 28.9729C152.967 29.475 153.625 29.7261 154.283 29.7261C154.942 29.7261 155.601 29.4743 156.104 28.9705C157.108 27.9637 157.107 26.3329 156.102 25.3275L154.875 24.1017Z" fill="#F25817"/>
<path d="M134.351 10.8616C134.854 11.3641 135.512 11.6152 136.17 11.6152C136.829 11.6152 137.488 11.3634 137.99 10.8596C138.995 9.85282 138.994 8.22198 137.988 7.21662L136.762 5.99075C135.757 4.98539 134.128 4.98608 133.123 5.99281C132.119 6.99955 132.12 8.63038 133.125 9.63574L134.351 10.8616Z" fill="#F25817"/>
<path d="M89.8924 1.1219L95.5946 11.5479C95.7925 11.9099 96.09 12.2073 96.4519 12.4053L106.878 18.1075C108.374 18.9256 108.374 21.074 106.878 21.8922L96.4523 27.5947C96.0903 27.7926 95.7929 28.0901 95.5949 28.452L89.8927 38.8781C89.0745 40.374 86.9262 40.374 86.108 38.8781L80.4054 28.452C80.2075 28.0901 79.91 27.7926 79.5481 27.5947L69.1219 21.8925C67.626 21.0743 67.626 18.926 69.1219 18.1078L79.5481 12.4056C79.91 12.2077 80.2075 11.9102 80.4054 11.5483L86.1076 1.12224C86.9262 -0.374024 89.0742 -0.374024 89.8924 1.1219Z" fill="#F9DE76"/>
<path d="M106.899 18.1077L96.6694 12.4055C96.3143 12.2076 96.0224 11.9101 95.8282 11.5482L90.233 1.12193C89.4302 -0.373977 87.3223 -0.373977 86.5195 1.12193L84 5.81696L85.7198 9.02198C86.7991 11.0334 88.4217 12.687 90.3952 13.787C95.2142 16.4731 95.2142 23.5269 90.3952 26.213C88.4217 27.313 86.7991 28.9666 85.7198 30.978L84 34.183L86.5195 38.8781C87.3223 40.374 89.4302 40.374 90.233 38.8781L95.8279 28.4521C96.0221 28.0902 96.3139 27.7928 96.6691 27.5948L106.899 21.8927C108.367 21.0742 108.367 18.9258 106.899 18.1077Z" fill="#F6D36B"/>
<path d="M21.8924 31.1219L27.5946 41.5479C27.7925 41.9099 28.09 42.2073 28.4519 42.4053L38.8781 48.1075C40.374 48.9256 40.374 51.074 38.8781 51.8922L28.4523 57.5947C28.0903 57.7926 27.7929 58.0901 27.5949 58.452L21.8927 68.8781C21.0745 70.374 18.9262 70.374 18.108 68.8781L12.4058 58.452C12.2078 58.0901 11.9103 57.7926 11.5484 57.5947L1.12195 51.8925C-0.373983 51.0743 -0.373983 48.926 1.12195 48.1078L11.5481 42.4056C11.91 42.2077 12.2075 41.9102 12.4054 41.5483L18.1076 31.1222C18.9262 29.626 21.0745 29.626 21.8924 31.1219Z" fill="#F9DE76"/>
<path d="M38.8992 48.1077L28.6692 42.4055C28.3141 42.2076 28.0223 41.9101 27.828 41.5482L22.2327 31.1219C21.43 29.626 19.322 29.626 18.5196 31.1219L16 35.817L17.7198 39.022C18.7992 41.0334 20.4217 42.687 22.3953 43.787C27.2144 46.4731 27.2144 53.5269 22.3953 56.213C20.4217 57.313 18.7992 58.9666 17.7198 60.978L16 64.183L18.5196 68.8781C19.3223 70.374 21.4303 70.374 22.2327 68.8781L27.8277 58.4521C28.0219 58.0902 28.3138 57.7928 28.6689 57.5948L38.8989 51.8927C40.367 51.0742 40.367 48.9258 38.8992 48.1077Z" fill="#F6D36B"/>
<path d="M157.892 45.1219L163.595 55.5481C163.793 55.91 164.09 56.2075 164.452 56.4054L174.878 62.1076C176.374 62.9258 176.374 65.0742 174.878 65.8924L164.452 71.5946C164.09 71.7925 163.793 72.09 163.595 72.4519L157.892 82.8781C157.074 84.374 154.926 84.374 154.108 82.8781L148.405 72.4519C148.207 72.09 147.91 71.7925 147.548 71.5946L137.122 65.8924C135.626 65.0742 135.626 62.9258 137.122 62.1076L147.548 56.4054C147.91 56.2075 148.207 55.91 148.405 55.5481L154.108 45.1219C154.926 43.626 157.074 43.626 157.892 45.1219Z" fill="#F9DE76"/>
<path d="M174.899 62.1073L164.669 56.4052C164.314 56.2072 164.022 55.9098 163.828 55.5479L158.233 45.1219C157.43 43.626 155.322 43.626 154.52 45.1219L152 49.817L153.72 53.022C154.799 55.0334 156.422 56.687 158.395 57.787C163.215 60.4731 163.215 67.5269 158.395 70.213C156.422 71.313 154.799 72.9666 153.72 74.978L152 78.183L154.52 82.8781C155.322 84.374 157.43 84.374 158.233 82.8781L163.828 72.4521C164.022 72.0902 164.314 71.7928 164.669 71.5948L174.899 65.8927C176.367 65.0735 176.367 62.9251 174.899 62.1073Z" fill="#F6D36B"/>
<path d="M103.43 175.721C103.827 167.283 106.836 134.563 129.473 88.595C131.323 84.8399 136.155 83.7547 139.411 86.3772C142.037 88.4925 142.629 92.2682 140.767 95.0832C133.34 106.312 115.333 136.041 103.951 175.804C103.864 176.11 103.415 176.039 103.43 175.721Z" fill="#F13555"/>
<path d="M75.1593 175.715C74.0715 161.341 68.4801 104.93 45.9551 70.5145C43.9045 67.3814 39.64 66.6418 36.6733 68.9211C33.9164 71.0395 33.3349 74.9744 35.3721 77.7956C43.2513 88.7074 61.5513 118.638 74.6348 175.791C74.7088 176.112 75.1844 176.044 75.1593 175.715Z" fill="#F13555"/>
<path d="M87.303 175.747L81.3544 63.0312C81.158 59.3105 84.1134 56.1857 87.8286 56.1857C91.5554 56.1857 94.5153 59.3294 94.301 63.0611L87.8341 175.749C87.8149 176.085 87.3208 176.084 87.303 175.747Z" fill="#F13555"/>
</svg>
`

export default str