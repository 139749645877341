import { useUser } from 'queries/user/useUser'
import { useModal } from './useModal'

import {
  UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC,
  UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID,
} from 'constants/ui-elements'
import React, { useCallback } from 'react'

interface ICheck {
  isFree?: boolean
  isBasic?: boolean
  isStartup?: boolean
}

export const useSubscriptionCheck = () => {
  const user = useUser()
  const { openModal } = useModal()

  const isExpired = user?.subscription.status === 'not_paid'
  const isBasic = user?.subscription.type === 'basic' && !isExpired
  const isFree = user?.subscription.type === 'free'
  const isTeam = user?.subscription.type === 'team' && !isExpired
  const isTrial = user?.subscription.status === 'trial'
  const isStartup = user?.subscription?.type === 'startup' && !isExpired
  const isPublic = !user

  const check = useCallback(
    (check?: ICheck) => {
      if (isExpired) {
        openModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID)
        return false
      }

      

      if (check?.isFree && isFree) {
        openModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID)
        return false
      }

      if (check?.isBasic && isBasic) {
        openModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC)
        return false
      }

      if (check?.isStartup && isStartup) {
        openModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC)
        return false
      }

      return true
    },
    [isBasic, isExpired, isFree, isStartup, openModal]
  )

  return React.useMemo(
    () => ({ check, isExpired, isBasic, isFree, isTeam, isTrial, isPublic, isStartup }),
    [check, isExpired, isBasic, isFree, isTeam, isTrial, isPublic, isStartup]
  )
}
