import { styled } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'
import { INavLinkContainer, IText } from './Navigation.types'

export const Nav = styled(Box)({
  display: 'flex',
  width: '100%',
})

export const NavLinkContainer = styled(Box)(
  ({ theme, active, orientation }: INavLinkContainer) => ({
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: active ? theme.colorz.secondary.main : 'transparent',
    padding: '4px',
    width: 'max-content',
    '&:not(:last-child)': {
      marginRight: theme.spacing(orientation === 'horizontal' ? 6 : 0),
      marginBottom: theme.spacing(orientation === 'vertical' ? 4 : 0),
    },
    '&:last-child': {
      marginLeft: orientation === 'horizontal' ? 'auto' : 0,
    },
  })
)

export const Text = styled(Typography)(({ theme, active }: IText) => ({
  fontSize: 14,
  letterSpacing: 0.8,
  lineHeight: 1,
  color: !active ? theme.colorz.secondary[300] : theme.colorz.secondary.main,
  fontWeight: 700,
  textTransform: 'uppercase',
}))
