class DB {
  save(key: string, value: any) {
    return localStorage.setItem(key, JSON.stringify(value))
  }

  read(key: string) {
    const serializedValue = localStorage.getItem(key)
    return serializedValue === null || serializedValue === 'undefined'
      ? null
      : JSON.parse(serializedValue)
  }

  remove(key: string) {
    localStorage.removeItem(key)
  }

  has(key: string) {
    return localStorage.getItem(key) !== null
  }
}

export const StorageService = new DB()
