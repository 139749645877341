import { EventItemPastProps } from './EventItem.types'
import React from 'react'
import LawyerInfo from './UserInfo'
import {
  ContainerPast,
  PastDataWrapper,
  Text,
  PastDataStatusWrapper,
  StrongText,
} from './EventItem.styles'
import moment from 'moment'

const EventItemScheduled: React.FC<EventItemPastProps> = ({ eventItem }) => {
  const { startTime, client, lawyer } = eventItem
  const person = client ?? lawyer
  if (!person) return null

  const time =
    moment(startTime)
      .format('D. MMMM YYYY , HH:mm')
      .replace(',', 'um') + ' Uhr'

  return (
    <ContainerPast>
      <LawyerInfo user={person} eventType='past' />
      <PastDataWrapper>
        <Text style={{ width: 230 }}>{time}</Text>
        <Text>CHF 40.00</Text>
        <PastDataStatusWrapper>
          <StrongText style={{ fontSize: 14 }}></StrongText>
          <StrongText style={{ opacity: 0.4, fontSize: 14, letterSpacing: 0.2 }}>
            Storniert
          </StrongText>
        </PastDataStatusWrapper>
      </PastDataWrapper>
    </ContainerPast>
  )
}

export default EventItemScheduled
