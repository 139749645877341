import React from 'react'
import { styled } from '@material-ui/core'

import { BookLawyerProvider, useBookLawyer } from './provider/BookLawyerProvider'

import Layout from './layout/Layout'
import BookAndPay from './views/BookAndPay/BookAndPay'
import LastConsultingStepContainer from './views/LastStep/LastStep.container'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'fit-content',
  overflowY: 'auto',
  position: 'relative',
}))

const BookLawyerComponent: React.FC = () => {
  const { step } = useBookLawyer()

  const renderContent = () => {
    switch (step) {
      case 1:
      case 2:
        return <BookAndPay />
      case 3:
        return <LastConsultingStepContainer />
      default:
        return null
    }
  }

  return (
    <Layout>
      <Container>{renderContent()}</Container>
    </Layout>
  )
}

const BookLawyer: React.FC = () => (
  <BookLawyerProvider>
    <BookLawyerComponent />
  </BookLawyerProvider>
)

export default BookLawyer
