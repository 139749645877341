import { styled } from '@material-ui/core/styles'
import { Box, BoxProps, Typography, Theme } from '@material-ui/core'

interface IDatePickerEl extends BoxProps {
  isdisabled: number
  theme: Theme
  variant: 'strong' | 'light'
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export const PickerContainer = styled(Box)(
  ({ theme, isdisabled, variant }: IDatePickerEl) => ({
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 220,
    borderRadius: 2,
    border: `2px solid ` + theme.colorz.secondary[variant === 'strong' ? 300 : 100],
    '&:focus': {
      border:
        `2px solid ` + theme.colorz.secondary[variant === 'strong' ? 'main' : '200'],
    },
    '&:hover': {
      border:
        `2px solid ` + theme.colorz.secondary[variant === 'strong' ? 'main' : '200'],
    },
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    pointerEvents: isdisabled ? 'none' : 'auto',
  })
)

export const ToolbarContainer = styled('div')(({ theme }) => ({
  height: 100,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.colorz.brand.main,
  paddingLeft: theme.spacing(2),
}))

export const Year = styled(Typography)(({ theme }) => ({
  color: theme.colorz.primary.light,
  cursor: 'pointer',
  width: 'max-content',
  padding: '2px 4px',
  borderRadius: 2,
  transition: 'all .2s',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
}))

export const DateText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,
  width: 'max-content',
  padding: '4px',
  borderRadius: 2,
  transition: 'all .2s',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
}))

export { Typography }
