import { Stripe } from '@stripe/stripe-js'
import { useState, useCallback } from 'react'

import { useToast } from 'hooks/useToast'

import { IApiHookOptions, IStripeCardData } from 'types'
import FinancialsApi, { TChargeStripeProduct, IChargeMetadata } from 'api/financials.api'

interface IChargeData {
  priceId: string
  metadata: IChargeMetadata
}

export const useStripePurchase = () => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const charge = useCallback(
    async (
      stripe: Stripe,
      cardData: IStripeCardData,
      data: IChargeData,
      product: TChargeStripeProduct,
      options?: IApiHookOptions
    ) => {
      try {
        setIsLoading(true)
        const { metadata, priceId } = data
        const pmRes = await stripe.createPaymentMethod(cardData)
        const tokenRes = await stripe.createToken(cardData.card)
        if (tokenRes.error) throw new Error('Error while creating a token')
        if (pmRes.error) throw new Error('Error while creating payment method')
        const payload = { token: tokenRes.token.id, priceId, metadata }
        await FinancialsApi.charge(payload, product)
        if (options?.onSuccess) options.onSuccess()
        toast('success', 'Ihre Zahlung war erfolgreich')
      } catch (err) {
        toast('error', 'default')
        if (options?.onError) options.onError()
      } finally {
        if (options?.onSettled) options.onSettled()
        setIsLoading(false)
      }
    },
    [toast]
  )

  return {
    isLoading,
    charge,
  }
}
