import React, {useState} from 'react'
import {ItemTitle, labelStyles} from './TwoFaSettings.styles'
import {CustomButton, TextInputGroup} from "components/common";
import {Column, Form} from "../PasswordSettings/ChangePassword/ChangePassword.styles";

interface Props {
    user: any,
    onChangeTwoFa: any,
    onSubmitPhoneNumber: any,
    isLoading2FA: boolean,
    isLoadingPhone: boolean,
}

const TwoFaSettings: React.FC<Props> = ({
                                            user,
                                            onChangeTwoFa,
                                            onSubmitPhoneNumber,
                                            isLoading2FA,
                                            isLoadingPhone,
                                        }) => {

    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)

    const handleTwoFactorAuthorizationChange = (status: boolean) => {
        onChangeTwoFa(status)
    }

    const handlePhoneNumberSubmitChange = () => {
        onSubmitPhoneNumber(phoneNumber)
    }

    return (
        <Column>
            <Form>
                <ItemTitle>2-Faktor Authentifizierung (2FA)</ItemTitle>
                {user.phoneNumber ? (<React.Fragment>
                    {user.twoFaEnabled ? (
                        <CustomButton
                            onClick={() => handleTwoFactorAuthorizationChange(false)}
                            btntype='error'
                            size='large'
                            disabled={isLoading2FA}
                            width={250}
                            style={{marginBottom: 16}}
                        >
                            {!isLoading2FA ? '2FA deaktivieren' : 'Einen Moment bitte...'}
                        </CustomButton>
                    ) : (
                        <CustomButton
                            onClick={() => handleTwoFactorAuthorizationChange(true)}
                            btntype='success'
                            size='large'
                            disabled={isLoading2FA}
                            width={250}
                            style={{marginBottom: 16}}
                        >
                            {!isLoading2FA ? '2FA aktivieren' : 'Einen Moment bitte...'}
                        </CustomButton>
                    )}
                    <ItemTitle>&nbsp;</ItemTitle>
                </React.Fragment>) : (<span>&nbsp;</span>)}
                <ItemTitle>Handynummer</ItemTitle>
                <TextInputGroup
                    disabled={!user.twoFaEnabled && user.phoneNumber !== ''}
                    type='text'
                    key={'phoneNumber'}
                    name={'Phone Number'}
                    value={phoneNumber}
                    placeholder={'z. Bsp.: +41 79 123 45 67'}
                    onChange={(event) => {
                        // @ts-ignore
                        setPhoneNumber(event.target.value)
                    }}
                    label={'Handynummer einfügen um 2FA zu aktivieren'}
                    LabelProps={{
                        style: labelStyles,
                    }}
                />
                {!user.phoneNumber ? (
                    <CustomButton
                        disabled={phoneNumber?.length === 0}
                        btntype='primary'
                        size='large'
                        onClick={() => handlePhoneNumberSubmitChange()}
                        isLoading={false}
                        width={250}
                        style={{marginTop: 1}}
                    >
                        {!isLoadingPhone ? 'Speichern und aktivieren' : 'Einen Moment bitte...'}
                    </CustomButton>
                ) : (
                    <CustomButton
                        disabled={!user.twoFaEnabled}
                        type='submit'
                        btntype='primary'
                        size='large'
                        isLoading={false}
                        width={250}
                        style={{marginTop: 1}}
                    >
                        {!isLoadingPhone ? 'Handynummer aktualisieren' : 'Einen Moment bitte...'}
                    </CustomButton>
                )}
            </Form>
        </Column>
    )
}

export default TwoFaSettings
