import React from 'react'
import { Form, Column, labelStyles, ItemTitle } from './ChangePassword.styles'
import { CustomButton, TextInputGroup } from 'components/common'
import { TUpdatePasswordState } from './ChangePassword.container'

type ChangePasswordFields = 'passwordCurrent' | 'passwordNew' | 'passwordNewConfirm'

interface ChangePasswordField {
  name: ChangePasswordFields
  label: string
}

const fields: ChangePasswordField[] = [
  { name: 'passwordCurrent', label: 'Bisheriges Passwort' },
  { name: 'passwordNew', label: 'Neues Passwort' },
  { name: 'passwordNewConfirm', label: 'Neues Passwort bestätigen' },
]

interface Props {
  values: TUpdatePasswordState
  isLoading: boolean
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const ChangePasswordComponent: React.FC<Props> = ({
  onChange,
  onSubmit,
  values,
  isLoading,
}) => {
  return (
    <Column>
      <Form onSubmit={onSubmit}>
        <ItemTitle>Passwort ändern</ItemTitle>
        {fields.map(({ name, label }) => (
          <TextInputGroup
            type='password'
            key={name}
            name={name}
            value={values[name]}
            onChange={onChange}
            label={label}
            LabelProps={{
              style: labelStyles,
            }}
          />
        ))}
        <CustomButton
          type='submit'
          btntype='primary'
          size='large'
          disabled={isLoading}
          isLoading={isLoading}
          width={216}
          style={{ marginTop: 16 }}
        >
          {!isLoading ? 'Änderungen speichern' : 'Einen Moment bitte...'}
        </CustomButton>
      </Form>
    </Column>
  )
}
