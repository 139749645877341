import { ITextInputEl } from './QuestionText.types'
import { styled } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'

export const TextInput = styled(InputBase)(({ theme, error }: ITextInputEl) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: error ? theme.colorz.status.error: theme.colorz.secondary[300],
  minHeight: 48,
  padding: theme.spacing(2),
  '&:focus': {
    borderColor: theme.colorz.secondary.main,
    outline: 'none',
  },

  '& textarea': {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    color: theme.colorz.secondary.main,
    width: '100%',
    fontSize: 16,
    letterSpacing: 0.4,
    fontFamily: 'inherit',
    fontStyle: 'inherit',

    '&::placeholder': {
      color: theme.colorz.secondary[300],
      letterSpacing: 0.4,
      fontSize: 16,
    },
  },
}))
