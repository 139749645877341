import { TextWithUrlProps } from './TextWithUrl.types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Text, URL } from './TextWithUrl.styles'

const TextWithUrl: React.FC<TextWithUrlProps> = ({
  url,
  urlText,
  text,
  style,
  handleUrlClick,
}) => (
  <Text align='center' style={style}>
    {text}{' '}
    {url ? (
      <Link to={url}>
        <URL>{urlText}</URL>
      </Link>
    ) : (
      <URL onClick={handleUrlClick}>{urlText}</URL>
    )}
  </Text>
)

export default TextWithUrl
