import { IOptionNameInput } from '../OptionList.types'
import { styled } from '@material-ui/core/styles'
import { Box, InputBase, Typography } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 2, 2, 2),
  position: 'relative',
  border: '1px solid ' + theme.colorz.secondary[100],
  borderRadius: 2,
  flexShrink: 0,
  marginBottom: theme.spacing(4),
}))

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.4,
  marginBottom: theme.spacing(1),
}))

export const OptionNameInput = styled(InputBase)(
  ({ theme, isdisabled }: IOptionNameInput) => ({
    color: theme.colorz.secondary.main,
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
    fontSize: 16,
    lineHeight: 0,
    border: '2px solid' + theme.colorz.secondary[200],
    pointerEvents: isdisabled ? 'none' : 'auto',

    '&:focus': {
      outline: 'none',
      border: '2px solid ' + theme.colorz.primary.main,
    },
  })
)

export const ActionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexShrink: 0,
})
