import { useMutation, useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import { queryKeys } from 'queries/queryKeys'
import templateApi from 'api/templates.api'
// import { useUser } from 'queries/user/useUser'

export const useFetchTemplateHtml = (id) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  // const user = useUser()
  const mutation = useMutation(
    [queryKeys.TEMPLATE, id, 'html'],
    (payload) => templateApi.fetchHtml(payload.id, payload.data),
    {
      onSuccess: (res) => {
        queryClient.setQueryData([queryKeys.TEMPLATE, id, 'html'], res)
      },
      onError: () => {
        toast('error', 'default')
      },
    }
  )
  return mutation
}
