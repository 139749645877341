import React from 'react'

import PagesLayout from 'layout/PagesLayout/PagesLayout'
import Sidebar from './TeamPageSidebar'
import { Container } from '../../Authenticated.page.styles'

interface Props {
  isLoading: boolean
}
const Layout: React.FC<Props> = ({ isLoading, children }) => {
  return (
    <Container>
      <Sidebar />
      <PagesLayout isLoading={isLoading}>{children}</PagesLayout>
    </Container>
  )
}

export default Layout
