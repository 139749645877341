import { QuestionProps, QuestionSortProps } from './Question.types'
import React, { useState } from 'react'
import {
  QuestionContainer,
  QuestionNumber,
  QuestionNumberWithSortContainer,
  QuestionText,
  Text,
  NumberInput,
} from './Question.styles'
import MoreDropdown from '../../../MoreDropdown/MoreDropdown'
import { Icon } from 'components/common'
import { secondary } from 'config/themes/colors'

const QuestionSorter: React.FC<QuestionSortProps> = ({
  sort,
  handleSortChange,
  handleBlurSortInput,
  handleSortArrowClick,
  handleFocusSortInput,
  handleEnterPress,
}) => {
  const handleInc = (): void => {
    let val: number | string = sort
    if (sort === '') val = 0
    if (typeof val === 'string') {
      val = parseInt(val)
    }
    val = val + 1
    handleSortChange(val)
    handleSortArrowClick(val)
  }

  const handleDec = (): void => {
    let val: number | string = sort
    if (sort === '') val = 0
    if (typeof val === 'string') {
      val = parseInt(val)
    }
    val = val > 1 ? (val = val - 1) : (val = 1)
    handleSortChange(val)
    handleSortArrowClick(val)
  }

  return (
    <QuestionNumberWithSortContainer>
      <Icon
        iconName='arrow-up-filled'
        fill={secondary.main}
        onClick={handleDec}
        style={{ cursor: 'pointer' }}
      />
      <NumberInput
        onChange={e => handleSortChange(e.target.value)}
        onBlur={handleBlurSortInput}
        onFocus={handleFocusSortInput}
        onKeyDown={handleEnterPress}
        min={1}
        value={sort}
      />
      <Icon
        iconName='arrow-up-filled'
        fill={secondary.main}
        onClick={handleInc}
        style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
      />
    </QuestionNumberWithSortContainer>
  )
}

const Question: React.FC<QuestionProps> = ({
  index,
  question,
  handleDeleteQuestion,
  handlePreviewQuestion,
  handleEditQuestion,
  handleUpdateSort,
  noIndex,
}) => {
  const [sort, setSort] = useState<number | string>(question.sort)
  const [changed, setChanged] = useState(false)
  const [showSorter, setShowSorter] = useState(false)

  const deleteClick = (): void => {
    if (handleDeleteQuestion) handleDeleteQuestion(question)
  }

  const editClick = (): void => {
    if (handleEditQuestion) handleEditQuestion(question)
  }

  const previewClick = (): void => {
    if (handlePreviewQuestion) handlePreviewQuestion(question)
  }

  const handleSortChange = (num: string | number): void => {
    let theNum = num
    if (typeof num === 'string' && num !== '' && num !== '-') theNum = parseInt(num)
    setSort(theNum)
    if (!changed) setChanged(true)
  }

  const handleBlurSortInput = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (handleUpdateSort && sort !== '')
      handleUpdateSort(question.id, typeof sort === 'string' ? parseFloat(sort) : sort)
  }

  const handleSortArrowClick = (sort: number): void => {
    if (handleUpdateSort) handleUpdateSort(question.id, sort)
  }

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!showSorter) {
      setShowSorter(true)
      if (question.sort !== sort) setSort(question.sort)
    }
  }

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>): void => {
    let theSort = sort
    if (sort === '') {
      theSort = question.sort
      setSort(theSort)
    }
    if (changed && handleUpdateSort) {
      if (typeof theSort === 'string') theSort = parseInt(theSort)
      handleUpdateSort(question.id, theSort)
      setChanged(false)
    }
    if (showSorter) setShowSorter(false)
  }

  const handleFocusSortInput = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (!showSorter) setShowSorter(true)
  }

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode !== 13 || !handleUpdateSort) return
    let theSort = sort
    if (sort === '') {
      theSort = question.sort
      setSort(question.sort)
    }
    if (changed && handleUpdateSort) {
      if (typeof theSort === 'string') theSort = parseInt(theSort)
      handleUpdateSort(question.id, theSort)
      setChanged(false)
    }
  }

  const numberEl =
    handleUpdateSort && showSorter ? (
      <QuestionSorter
        handleSortChange={handleSortChange}
        handleBlurSortInput={handleBlurSortInput}
        handleSortArrowClick={handleSortArrowClick}
        handleFocusSortInput={handleFocusSortInput}
        handleEnterPress={handleEnterPress}
        sort={sort}
      />
    ) : (
      <QuestionNumber>{question.sort}</QuestionNumber>
    )

  return (
    <QuestionContainer onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MoreDropdown
        handleDeleteClick={deleteClick}
        handleEditClick={editClick}
        handlePreviewClick={previewClick}
      />
      {!noIndex && !isNaN(Number(index)) ? numberEl : null}
      <QuestionText>
        <Text variant='body1'>{question.tags[0] || question.questionText}</Text>
      </QuestionText>
    </QuestionContainer>
  )
}

export default Question
