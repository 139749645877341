import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useUser } from 'queries/user/useUser'

import { Loader } from 'components/common'
import { ROUTE_UPGRADE_SUBSCRIPTION } from 'constants/routes'

export const EmailSubscriptionRedirect: React.FC = () => {
  const user = useUser()
  const { location, replace } = useHistory()
  const { pathname } = location

  useEffect(() => {
    if (!user) {
      const encodedUrl = encodeURIComponent(pathname)
      return replace(`/auth/login?redirect=${encodedUrl}`)
    }
    if (user) replace(ROUTE_UPGRADE_SUBSCRIPTION)
  }, [user, replace, pathname])

  return <Loader />
}
