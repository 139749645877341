import { DocumentCreateHeaderComponentProps } from './HeaderSettings.types'
import React, { useState, useEffect, useCallback } from 'react'

import { UploadLogo } from './UploadLogo'
import { PreviewHeader } from './PreviewHeader'
import { Container } from './HeaderSettings.styles'
import { toBase64 } from 'utils'

export const CreateHeader: React.FC<DocumentCreateHeaderComponentProps> = ({
  headerToEdit,
  base64,
  zoom,
  disabled,
  setZoom,
  setBase64,
  setDisabled,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(headerToEdit ? 1 : 0)
  const [file, setFile] = useState<File | null>(null)
  const [imgSrc, setImgSrc] = useState(
    headerToEdit?.imageURL ? headerToEdit.imageURL : ''
  )

  const handleReupload = useCallback((): void => {
    setFile(null)
    setIsLoading(false)
    setStep(0)
    setBase64(null)
    setImgSrc('')
    setZoom(100)
  }, [setBase64, setZoom])

  useEffect(() => {
    if (setDisabled && step === 0) {
      if (!file && !disabled) {
        setDisabled(true)
      }

      if (file && disabled) setDisabled(false)
    }
  }, [file, disabled, setDisabled, step])

  useEffect(() => {
    if (file && !isLoading && !base64) {
      try {
        toBase64(file).then(str => setBase64(str))
        setStep(1)
      } catch (err) {
        console.error(err)
        handleReupload()
      }
    }
  }, [file, isLoading, handleReupload, setBase64, base64])

  return (
    <Container>
      {!step ? (
        <UploadLogo setFile={setFile} />
      ) : (
        <PreviewHeader
          imageUrl={imgSrc}
          file={file}
          zoom={zoom}
          setZoom={setZoom}
          handleReupload={handleReupload}
        />
      )}
    </Container>
  )
}
