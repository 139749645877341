import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const StatusBarContainer = styled(Box)(({ theme }) => ({
  height: theme.spacing(7),
  width: '100%',
  boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.05)',
  display: 'flex',
  alignItems: 'center',
  zIndex: 2,
  paddingLeft: theme.spacing(2),
  backgroundColor: '#fff'
}))

export const Heading = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2),
}))
