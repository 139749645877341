import React from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import RegisterFormContainer from 'pages/Auth/components/RegisterForm/RegisterForm.container'
import { useMasterData } from 'queries/app/useMasterdata'

export type RegisterBrowserEndpoint = '' | 'anwalt' | 'employee'

const RegistrationPage: React.FC = () => {
  const { client, lawyer } = useMasterData()!
  const { params } = useRouteMatch<{ type: RegisterBrowserEndpoint }>()

  const allowClient = client.registration_open
  const allowLawyer = lawyer.registration_open
  const { type } = params

  if (!allowClient && !allowLawyer) return <Redirect to='/auth/login' />
  if (type === 'anwalt' && !allowLawyer) return <Redirect to='/auth/login' />
  if (!type && !allowClient) return <Redirect to='/auth/login' />

  if (type === 'employee') {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    const email = url.searchParams.get('email')
    const userType = url.searchParams.get('type')
    if (!code || !email || !userType) return <Redirect to='/auth/register' />
  }

  return <RegisterFormContainer />
}

export default RegistrationPage
