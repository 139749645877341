import { IDocument } from 'types'
import React, { useState, useCallback, useEffect } from 'react'
import { Document, pdfjs, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import DocsApi from 'api/docs.api'

import { Container } from './PdfDocumentView.styles'
import { useToast } from 'hooks/useToast'
import { Loader } from 'components/common'
import { useUser } from 'queries/user/useUser'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  documentFile: IDocument
}

export const PdfDocumentView: React.FC<Props> = ({ documentFile }) => {
  const [numPages, setNumPages] = useState<null | number[]>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<null | File>(null)
  const toast = useToast()

  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice

  const onLoadSuccess = ({ numPages, ...rest }: any) => {
    //pdfjs.PDFDocumentProxy
    const pages: number[] = []
    for (let i = 1; i <= numPages; i++) {
      pages.push(i)
    }
    setNumPages(pages)
  }

  const getPdf = useCallback(async () => {
    if (documentFile) {
      try {
        setIsLoading(true)
        const { data } = await DocsApi.download(documentFile.id, 'pdf', docXMicro)
        setFile(new File([data], documentFile.name))
      } catch (err) {
        toast('error', 'default')
      } finally {
        setIsLoading(false)
      }
    }
  }, [documentFile, toast, docXMicro])

  useEffect(() => {
    if (!file && !isLoading && documentFile?.type === 'pdf') getPdf()
  }, [getPdf, documentFile, isLoading, file])

  if (isLoading || !file) return <Loader />

  return (
    <Container>
      <Document file={file} onLoadSuccess={onLoadSuccess}>
        {numPages?.map((p, i) => (
          <div key={i} style={{ marginBottom: 24 }}>
            <Page pageNumber={p} />
          </div>
        ))}
      </Document>
    </Container>
  )
}
