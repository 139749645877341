import { AnswerTypes } from 'types'

export const ANSWER_TYPE_TEXT_BOX: AnswerTypes = 'ANSWER_TYPE_TEXT_BOX'
export const ANSWER_TYPE_DATE: AnswerTypes = 'ANSWER_TYPE_DATE'
export const ANSWER_TYPE_NUMBER: AnswerTypes = 'ANSWER_TYPE_NUMBER'
export const ANSWER_TYPE_CHECKBOX: AnswerTypes = 'ANSWER_TYPE_CHECKBOX'
export const ANSWER_TYPE_RADIO_LINK: AnswerTypes = 'ANSWER_TYPE_RADIO_LINK'
export const ANSWER_TYPE_RADIO_REPLACEMENT: AnswerTypes = 'ANSWER_TYPE_RADIO_REPLACEMENT'

interface IAnswerTypesMap {
  [key: string]: AnswerTypes
}

export const answerTypesMap: IAnswerTypesMap = {
  TEXT_BOX: ANSWER_TYPE_TEXT_BOX,
  CHECKBOX: ANSWER_TYPE_CHECKBOX,
  RADIO_LINK: ANSWER_TYPE_RADIO_LINK,
  RADIO_REPLACEMENT: ANSWER_TYPE_RADIO_REPLACEMENT,
  DATE: ANSWER_TYPE_DATE,
  NUMBER: ANSWER_TYPE_NUMBER,
}

export default answerTypesMap
