import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { scrollBarStyles } from 'constants/common-styles'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#fff',
  width: 462,
  flexShrink: 0,
  borderRight: '1px solid' + theme.colorz.brand.main,
}))

export const Content = styled(Box)({
  width: '100%',
  maxWidth: 500,
  height: 'calc(100vh - 112px)',
  maxHeight: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
})

export const ActionButtonsContainer = styled(Box)({
  display: 'flex',
  marginTop: 40,
})

export const ContainerStep3 = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const CTContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  backgroundColor: theme.colorz.brand.light,
}))

export const ScrollWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 5, 2, 5),
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  marginBottom: theme.spacing(3),
  ...scrollBarStyles(theme),
}))

export const SelectWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 5, 0, 5),
  backgroundColor: theme.colorz.secondary[50],
}))

export { Typography, Box }
