import { ITeamMember } from 'types'
import Api from './api'

export interface InviteTeamMemberApi {
  name: string
  email: string
}

class TeamApi extends Api {
  public constructor() {
    super('/teamMembers')
  }

  public get = async (): Promise<ITeamMember[]> => {
    const res = await this.api().get('/')
    return res.data.data
  }

  public sendInviteEmail = async (data: InviteTeamMemberApi) => {
    await this.api().post('/', data)
  }

  public remove = async (teamMemberId: string) => {
    return await this.api().post(`/${teamMemberId}/delete`)
  }

  public acceptInviteExisting = async (code: string, email: string) => {
    await this.api().post(`/accept/invite`, { code, email })
  }
}

const teamApi = new TeamApi()

export default teamApi
