import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 112px)',
  overflowY: 'auto',
  width: '70%',
  maxWidth: 978,
  backgroundColor: '#fff',
}))

export const TopContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0, 2, 2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  backgroundColor: '#fff',
  zIndex: 2,
  boxShadow: '0 0 10px 10px #fff',
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  letterSpacing: 0.45,
  marginLeft: theme.spacing(3),
}))

export const GroupTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0.4,
  fontWeight: 700,
  marginBottom: theme.spacing(3),
}))

export const QuestionsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}))

export const GroupContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: theme.spacing(4),
}))
