const content = {
  heading: ['Registrieren.'],
  text: 'CASUS bietet Ihnen ganz neue Möglichkeiten für den einfachen Zugang zu Recht.',
  bulletpoint1:
    'Mit CASUS automatisieren Sie Ihre Vertragsvorlagen und erstellen automatisiert juristische Dokumente. Ganz einfach und in hoher Qualität.',
  bulletpoint2:
    'Dank CASUS sind Ihre Rechtsdokumente organisiert, sicher gespeichert und digital überwacht. Damit Sie immer den Durchblick haben.',
  bulletpoint3:
    'Mit CASUS bietet die Rechtsabteilung dem Business echten Self Service. So steht juristisches Know-how jederzeit und für alle relevanten Mitarbeitenden zur Verfügung.',
}

export default content
