import { Question } from 'types'

export type DeepFindParentType = [Question | undefined, Question[]]

export const deepFindParentFromQuestions = (
  questions: Question[],
  parentId: string,
  docXmicro?: boolean,
): DeepFindParentType => {
  if (!docXmicro) {

    let _questions = [...questions]
    let ids = parentId.split('--')
    let idToLookFor = ids.pop()
    let _parentQuestion = _questions.find(
      questionItem => questionItem.id === idToLookFor
    )

    while (ids.length) {
      idToLookFor = ids.pop()
      let found: boolean = false
      if (_parentQuestion && _parentQuestion.options) {
        for (let option of _parentQuestion.options) {
          if (found) break
          for (let subquestion of option.subquestions) {
            if (subquestion.id === idToLookFor) {
              _parentQuestion = subquestion
              found = true
              break
            }
          }
        }
      } else {
        const qId = idToLookFor
        _parentQuestion = _questions.find(questionItem => questionItem.id === qId)
      }
    }

    return [_parentQuestion, _questions]
  }
  const parentQuestion = questions.reduce((acc: any, cur: any) => {
    if (acc) return acc
    if (cur.id === parentId) return cur
    if (cur.options?.length) return cur.options.map((option: any) => {
      if (option.subquestions?.length) return deepFindParentFromQuestions(option.subquestions, parentId, true)[0]
      return null
    }).filter((e: any) => e)[0]
    return null
  }, null)

  return [parentQuestion, [...questions]]
}
