import { useHistory } from 'react-router'
import { useMutation, useQueryClient } from 'react-query'

import { useRootFolder } from 'hooks/useRootFolder'

import { FolderItem, FolderItemSimple } from 'types'
import FoldersApi, { IUpdateFolderApiProps } from 'api/folders.api'
import { FileManager } from 'utils'
import { AxiosError } from 'axios'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useFolderKeys } from './useFolderKeys'

export const useUpdateFolder = (
  where: 'templates' | 'docs',
  currentFolder: FolderItem
) => {
  const queryClient = useQueryClient()
  const { replace, location } = useHistory()

  const { rootFolder, setRootFolder } = useRootFolder(where)
  const { setCurrentFolder } = useCurrentFolder(where)

  const [many, single] = useFolderKeys(where)

  const keys = [many, currentFolder.id]

  const mutation = useMutation<
    FolderItemSimple,
    AxiosError,
    IUpdateFolderApiProps,
    FolderItemSimple
  >(keys, p => FoldersApi.update({ type: where, ...p }), {
    onSuccess: (updatedFolder, variables, context) => {
      const { id, data } = variables
      const { name, status, sharingEnabled } = data
      let fileManager = new FileManager(rootFolder, currentFolder)

      if (status === 'new') fileManager.restoreFolder(updatedFolder)
      if (status === 'trashed') fileManager.moveToRoot(id, 'trashed')
      if (status === 'deleted') fileManager.deleteFolder(id)
      if (status) queryClient.invalidateQueries(many)

      if (!status) {
        const folderToUpdate = currentFolder.children.find(f => f.id === updatedFolder.id)
        if (!folderToUpdate) return alert('useFolderUpdate hook failed')
        if (name !== undefined || typeof sharingEnabled === 'boolean') {
          if (name) folderToUpdate.name = name
          if (typeof sharingEnabled === 'boolean')
            folderToUpdate.sharingEnabled = sharingEnabled
          fileManager.editFolder(folderToUpdate)
        }

        if (data?.parentCategoryId) {
          const destinationFolder = fileManager.findFolderById(data.parentCategoryId)
          if (!destinationFolder) return alert('Error while changing parentcategoryId')
          const [root] = fileManager.deleteFolder(folderToUpdate.id)
          fileManager = new FileManager(root, destinationFolder)
          fileManager.addNewFolder(updatedFolder)
          replace(location.pathname)
        }
      }

      setRootFolder(fileManager.data)
      setCurrentFolder(fileManager.current)
      queryClient.setQueryData([single, updatedFolder.id], updatedFolder)
    },
  })
  return mutation
}
