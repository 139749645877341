import React from 'react'
import Intercom from 'react-intercom'
import { useIntercomAttributes } from './useIntercomAttributes'

const IntercomWrapper: React.FC = () => {
  const attributes = useIntercomAttributes()
  return <Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID!} {...attributes} />
}

export default IntercomWrapper
