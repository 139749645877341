import { IStripeCardData } from 'types'
import React, { useCallback } from 'react'
import { Stripe } from '@stripe/stripe-js'
import { styled } from '@material-ui/core'
import { useQueryClient } from 'react-query'

import { useMasterData } from 'queries/app/useMasterdata'
import { useUser } from 'queries/user/useUser'
import { useFetchTemplate } from 'queries/templates'

import { StripeCard, useStripePurchase } from 'components/stripe'

import UserApi from 'api/user.api'
import StandardModal from 'components/common/Modals/StandardModal/StandardModal'

import theme from 'config/themes/theme'

import { StandardModalProps } from 'components/common/Modals/StandardModal/StandardModal.types'

import { Loader } from 'components/common'
import { queryKeys } from 'queries/queryKeys'
import { analytics } from 'utils'
import { IChargeStripeRequestApi } from 'api/financials.api'

const ContentWrapper = styled('div')({
  padding: '40px 60px',
})

const PurchaseTemplateModal: React.FC<StandardModalProps & {
  templateId: string
}> = ({ open, templateId, handleClose, ...props }) => {
  const queryClient = useQueryClient()
  const prices = useMasterData()?.prices.single_template_tiers
  const user = useUser()
  const purchase = useStripePurchase()
  const { data: template } = useFetchTemplate(templateId)

  const [isLoading, setIsLoading] = React.useState(false)

  const price = prices && template?.priceTier ? prices[template.priceTier] : null
  const priceId = price?.id

  const getCurrentUser = useCallback(async () => {
    setIsLoading(true)
    try {
      const freshUser = await UserApi.refreshUser()
      queryClient.setQueryData(queryKeys.USER, freshUser)
      setIsLoading(false)
      handleClose()
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }, [handleClose, queryClient])

  const onCharge = (stripe: Stripe, cardData: IStripeCardData) => {
    if (!priceId || !template) return

    const payload: IChargeStripeRequestApi = {
      priceId,
      metadata: {
        templateID: template.id,
        userId: user?.id,
        type: 'single_template',
      },
    }

    purchase.charge(stripe, cardData, payload, 'single-template', {
      onSuccess: () => {
        analytics.logEvent('public_template_purchase', {
          id: template?.id,
          name: template?.name,
        })
        getCurrentUser()
      },
    })
  }

  if (!open) return null

  return (
    <StandardModal
      handleClose={handleClose}
      open={open}
      title='Vorlage kaufen'
      RootModalProps={{
        style: { marginBottom: '15vh' },
        backdropStyle: { background: theme.colorz.secondary[100], opacity: 0.8 },
      }}
      {...props}
    >
      <ContentWrapper>
        {!template ? (
          <Loader />
        ) : (
          <StripeCard
            handleStripeSubmit={onCharge}
            amount={price?.amount ?? 0}
            heading='Kaufen Sie Ihre CASUS-Vorlage'
            descriptionText='Sobald Sie die Vorlage gekauft haben, können Sie damit so viele Dokumente erstellen, bearbeiten und herunterladen, wie Sie möchten. – Herzlichen Dank für Ihr Vertrauen!'
            charging={isLoading || purchase.isLoading}
          />
        )}
      </ContentWrapper>
    </StandardModal>
  )
}
export default PurchaseTemplateModal
