import React, { Fragment, useState } from 'react'

import { useDocumentGeneration } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'

import DocumentGenerationResultComponent from './DocumentGenerationResult.component'
// import { DocumentSettingsModalContainer } from 'components/documentSettings/DocumentSettingsModal'

export const DocumentGenerationResult: React.FC = () => {
  const doc = useDocumentGeneration()
  const { set, name, htmlData } = doc

  const [updatedName, setName] = useState(name || 'Unbenanntes Dokument')

  return (
    <Fragment>
      <DocumentGenerationResultComponent
        onNameChange={(e) => setName(e.target.value)}
        onNameBlur={() => set({ name: updatedName })}
        htmlData={htmlData}
        name={updatedName}
      />
      {/* <DocumentSettingsModalContainer /> */}
    </Fragment>
  )
}
