import { Question, IOptionWLoc } from 'types'
import React, { Fragment } from 'react'
import { useParams } from 'react-router'
import { v4 as uuid } from 'uuid'
import { useIsFetching } from 'react-query'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useEditor } from 'config/textEditor/useEditor'

import EditorViewComponent from './EditorView.component'
import { rangyHighlighter, removeHighlightItemsByLocations } from 'utils'

import { queryKeys } from 'queries/queryKeys'
import { useUser } from 'queries/user/useUser'
import { generateOffsets } from 'utilities'

const DocumentViewContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const isLoading = useIsFetching([queryKeys.TEMPLATE, id])
  const editor = useEditor()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  const { set, step, questionStep, question } = useTemplateCreation()

  const currentQuestion = question!

  const removeSelection = () => {
    const selection = editor?.selection.getSel()
    if (!selection) return
    if (selection.removeAllRanges) selection.removeAllRanges()
    if (selection.empty) selection.empty()
  }

  const handleCancel = () => {
    removeSelection()
  }

  const handleConfirmTextSelection = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const doc = editor?.getDoc()

    if (docXmicro) {
      const selection = editor?.selection.getSel() as any
      // @ts-ignore
      const { parentId: id, startOffset: start, endOffset: end } = generateOffsets(selection) || []
      const currentQuestionId = question?.id || null
      if (id) {
        const selectionData = {
          id: id,
          start,
          end,
          currentQuestionId,
          locationId: uuid(),
        }
        // console.log('SELECTION DATA: ', selectionData)
        set({ selectionData })
      }
      removeSelection()
    } else {
      if (!question || !editor || !doc) return
      const id = uuid()
      const qid = question.id
      const parentId = question.parentId

      rangyHighlighter(doc, qid, id, parentId, question.type)
      set({ selectionData: { id, start: 1, end: 2, currentQuestionId: qid } })

      removeSelection()
      // const html = editor.getBody().innerHTML
      // set({ htmlData: html })
      // localStorage.setItem('textSelectionHtmlData', html)
    }
  }

  const handleConfirmDeleteSelection = (locationId?: string): void => {
    if (!locationId || !editor) return
    const id = docXmicro ? 'locationId' : 'id'
    let _currentQuestion: Question = { ...currentQuestion }
    const { options, locations } = _currentQuestion
    if (locations?.length) {
      _currentQuestion.locations = locations.filter(loc => loc[id] !== locationId)
    }
    if (docXmicro) {
      if (options?.length) {
        _currentQuestion.options = (options as IOptionWLoc[]).map(opt => {
          opt.locations = opt.locations.filter(l => l[id] !== locationId)
          return opt
        })
      }
    } else {
      if (options?.length) {
        const filterFromOptions = (options: any = []) => {
          options.forEach((o: any) => {
            o.locations = o.locations.filter((loc: any) => loc[id] !== locationId)
            if (o.subquestions?.length) {
              o.subquestions.forEach((sq: any) => {
                if (sq.options.length) filterFromOptions(sq.options)
              })
            }
          })
        }
        filterFromOptions(_currentQuestion.options)
      }
    }

    set({ question: _currentQuestion })
    if (locationId)
      //@ts-ignore
      removeHighlightItemsByLocations([{ [id]: String(locationId), start: 0, end: 0 }], editor!)
    // const html = editor.getBody().innerHTML
    // localStorage.setItem('textSelectionHtmlData', html)
  }

  return (
    <Fragment>
      <EditorViewComponent
        isLoading={!!isLoading}
        withNav={step !== 1}
        questionStep={questionStep}
        handleConfirmTextSelection={handleConfirmTextSelection}
        handleCancel={handleCancel}
        handleConfirmDeleteSelection={handleConfirmDeleteSelection}
      />
    </Fragment>
  )
}

export default DocumentViewContainer
