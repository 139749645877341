import { StandardModalProps, ModalTitleProps } from './StandardModal.types'
import React, { useRef } from 'react'
import {
  TitleContainer,
  ContentContainer,
  ActionsContainer,
  Typography,
  ModalContent,
} from './StandardModal.styles'
import { ButtonSquare, Icon, CustomButton } from 'components/common'
import ModalElement from '../RootModal'
import { BtnTypes } from 'components/common/CustomButtonV2/CustomButton.types'

const ModalTitle: React.FC<ModalTitleProps> = ({
  children,
  onClose,
  disableOutsideClickClose,
}) => {
  const ref = useRef<HTMLButtonElement>(null)

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!disableOutsideClickClose) {
      onClose(e)
    } else {
      if (e.currentTarget === ref.current) onClose(e) // TODO
    }
  }

  return (
    <TitleContainer disableTypography>
      <Typography variant='h5'>{children}</Typography>
      <ButtonSquare ref={ref} btntype='secondary' onClick={handleClose}>
        <Icon iconName='close' />
      </ButtonSquare>
    </TitleContainer>
  )
}

const StandardModal: React.FC<StandardModalProps> = ({
  open,
  primaryButtonDisabled,
  primaryButtonText,
  secondaryButtonDisabled,
  secondaryButtonText,
  title,
  children,
  buttonWidth = 200,
  status,
  disableOutsideClickClose,
  RootModalProps,
  contentContainerStyles,
  handleClose,
  handlePrimaryClick,
  handleSecondaryClick,
}) => {
  const hasActions = Boolean(handlePrimaryClick) || Boolean(handleSecondaryClick)

  return (
    <ModalElement open={open} onClose={handleClose} {...RootModalProps}>
      <ModalContent boxShadow={2} status={status} style={contentContainerStyles}>
        <ModalTitle
          onClose={handleClose}
          disableOutsideClickClose={disableOutsideClickClose}
        >
          {title ? title : ''}
        </ModalTitle>
        <ContentContainer>{children}</ContentContainer>
        {hasActions && (
          <ActionsContainer>
            {handleSecondaryClick && secondaryButtonText && (
              <CustomButton
                btntype={status ? 'primary' : 'secondary'}
                size={status ? 'medium' : 'large'}
                onClick={handleSecondaryClick}
                disabled={secondaryButtonDisabled}
                width={buttonWidth}
              >
                {secondaryButtonText}
              </CustomButton>
            )}
            <CustomButton
              btntype={status ? (`outlined-${status}` as BtnTypes) : 'primary'}
              size={status ? 'medium' : 'large'}
              autoFocus
              disabled={primaryButtonDisabled}
              onClick={handlePrimaryClick}
              width={buttonWidth}
            >
              {primaryButtonText}
            </CustomButton>
          </ActionsContainer>
        )}
      </ModalContent>
    </ModalElement>
  )
}

export default StandardModal
