import questionTypes from '../../../../constants/question-types'
import { IStep } from 'types'
import { IconNames } from 'components/common/Icons/Icon.types'
import { ITabItem } from 'components/common/Tabs/Tabs.types'
const { RADIO_REPLACEMENT, RADIO_LINK, CHECKBOX, TEXT_BOX, DATE, NUMBER } = questionTypes

export const steps: IStep[] = [
  { step: 1, label: 'Dokument hochladen' },
  { step: 2, label: 'Komponenten auswählen' },
  { step: 3, label: 'Vorlage verwenden' },
]

export const onBoardingSteps: IStep[] = [
  { step: 1, label: 'Vorlagendokument hochladen' },
  { step: 2, label: 'Veränderbare Komponenten auswählen' },
  { step: 3, label: 'Speichern und als Vorlage verwenden' },
]

export const onBoardingHeading = 'Digitalisieren Sie Ihre Vertragsvorlagen'
export const heading = 'Vorlage erstellen'

export const lastStepContent = {
  heading: 'Ihre Vorlage wurde erfolgreich erstellt',
  primaryButton: 'Vorlage testen',
  primaryButtonDisabled: 'Keine Fragen',
  secondaryButton: 'Zu den Vorlagen',
}

export const descriptionContent = {
  heading:
    'Optional: Fügen Sie Ihrer Frage ein Label, Praxistipps und ein Antwortbeispiel hinzu.',
}

export const btnContent = {
  next: 'Weiter',
  save: 'Speichern',
  saveFinish: 'Speichern und abschliessen',
  lookup: 'Durchsuchen',
  uploading: 'Hochladen...',
  newOption: 'Neue Option hinzufügen',
  addSubquestion: 'Unterfrage hinzufügen',
  markText: 'Text markieren',
  addQuestion: '+ Frage hinzufügen',
  preview: 'Vorschau',
}

export const uploadContent = {
  headingTop: 'Vorlage hochladen oder mit leerer Vorlage beginnen',
  headingUpload: 'Laden Sie Ihr Vorlagendokument hoch',
  headingEmpty: 'Beginnen Sie mit einer leeren Vorlage',
  descriptionUpload:
    'Durchsuchen Sie Word-Dokumente auf Ihrem Computer und wählen Sie eines als Vorlage aus.',
  descriptionEmpty:
    'Benützen Sie unseren Texteditor, um eine Vorlage von Grund auf zu erstellen',
  subtitle: 'Unterstützte Formate: .docx, .doc',
}

export const radioFormTabs = [
  { label: 'Textabschnitt verknüpfen', section: questionTypes.RADIO_LINK },
  { label: 'Textstelle ersetzen', section: questionTypes.RADIO_REPLACEMENT },
]

const numberFormTabs: ITabItem[] = [
  { label: 'Zahl', section: 'no-range' },
  { label: 'Zahlenbereich', section: 'with-range' },
]

export const forms = {
  heading: 'Fügen Sie Antwortoptionen hinzu:',
  subquestionsHeading: 'Optional: Fügen Sie Unterfragen hinzu',
  createQuestionLabel: 'Erstellen Sie eine Frage für die veränderbare Textkomponente:',
  dateHeading:
    'Markieren Sie die Textstellen im Dokument, die Sie mit diesem Datum ersetzen möchten.',
  textBoxHeading:
    'Markieren Sie die Textstellen im Dokument, die Sie mit diesem Textfeld ersetzen möchten.',
  numberHeading:
    'Markieren Sie die Texstellen im Dokument, die Sie mit dieser Zahl ersetzen möchten',
  labelLabel: 'Label *',
  groupLabel: 'Gruppe',
  labelPlaceholder: 'Titel dieser Frage',
  groupPlaceholder: 'Table of content',
  descriptionLabel: 'Praxistipps',
  answerExampleLabel: 'Antwortbeispiel',
  answerExamplePlaceholder: 'Antwortbeispiel',
  descriptionPlaceholder: 'Praxistipps',
  questionTypesHeading: 'Wählen Sie einen Antworttyp:',
  confirmText:
    'Wenn Sie den Antworttyp ändern, werden bereits markierte Textstellen gelöscht. Wollen Sie weiterfahren?',
  confirmTextOption:
    'Alle Daten dieser Option inklusive Unterfragen werden gelöscht. Wollen Sie weiterfahren?',
  radioFormTabs,
  numberFormTabs,
}

export const moreDropdownContent = {
  preview: 'Vorschau',
  edit: 'Bearbeiten',
  delete: 'Löschen',
}

export const questionsOverviewContent = {
  noQuestionsText: 'Noch keine Fragen erstellt.',
  isLoadingText: 'Einen Moment bitte...'
}

interface IBoxType {
  label: string
  icon: IconNames
}
interface IBoxTypes {
  [key: string]: IBoxType
}

export const boxTypes: IBoxTypes = {
  [TEXT_BOX]: { label: 'Text', icon: 'text' },
  textField: { label: 'Textfeld', icon: 'text' },
  [DATE]: { label: 'Datum', icon: 'date' },
  [CHECKBOX]: { label: 'Mehrfachauswahl', icon: 'checkbox' },
  [RADIO_LINK]: { label: 'Einfachauswahl', icon: 'radio' },
  [RADIO_REPLACEMENT]: { label: 'Einfachauswahl', icon: 'radio' },
  [NUMBER]: { label: 'Zahl', icon: 'number' },
}
