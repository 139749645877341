
import { TabsProps } from './Tabs.types'
import React from 'react';
import { Container } from './Tabs.styles';
import TabItem from './TabItem/TabItem';

const Tabs: React.FC<TabsProps> = ({ tabItems, ...otherProps }) => {
  return (
    <Container>
      {tabItems.map((item) => (
        <TabItem
          key={item.section}
          {...item}
          {...otherProps}
        />
      ))}
    </Container>
  );
};

export default Tabs;
