import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  margin: theme.spacing(3, 0),
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))
