import { IPermissions, ITemplate, ITemplateExtended, IUpdateTemplateApiData, Question, TemplateType } from 'types'
import Api from './api'
import { parseBEData } from 'utilities'
export interface ICreateTemplateApi {
  category?: string[]
  questions?: Question[]
  htmlData?: string
  originalStylesKept?: boolean
  base64data?: string
  hyphens?: boolean
  name?: string
  filename?: string
  new?: boolean
}

export interface ISaveQuestionsApi {
  htmlData: string
  questions: Question[]
}

export interface IGetTemplatesApi {
  type: TemplateType
  lastItemFetched?: string
  limit?: number
  folderId?: string
}

export interface IUpdateTemplateApi {
  id: string
  data: IUpdateTemplateApiData
  new?: boolean
}

export interface IDuplicateTemplateApi {
  id: string
}

export interface IUploadTemplateApi {
  base64data: string
  originalStylesKept: boolean
}

class TemplateApi extends Api {
  public constructor() {
    super('/templates', true)
  }

  public create = async (data: ICreateTemplateApi): Promise<ITemplate> => {
    const isEmpty = !Object.keys(data).filter(e => e !== 'new').length
    let res = {} as any
    if (!isEmpty && data.new) {
      const response = await this.api().post('/v2/templates/upload/docx', {
        base64data: data.base64data,
      })
      res = await this.api().post('/v2/templates', {
        category: data.category ?? [],
        originalStylesKept: data.originalStylesKept ?? false,
        questions: data.questions ?? [],
        storageId: String(response.data.data.storageId) || '',
        hyphens: data.hyphens ?? false,
        name: data.name ?? 'Unbenannte Vorlage',
      })
    } else {
      const createData: ICreateTemplateApi = {
        category: data.category ?? [],
        htmlData: data.htmlData ?? '',
        base64data: data.base64data ?? '',
        originalStylesKept: data.originalStylesKept ?? false,
        hyphens: data.hyphens ?? false,
        name: data.name ?? 'Unbenannte Vorlage',
        questions: data.questions ?? [],
      }
      res = await this.api().post('/v1/templates', createData)
    }
    return res.data.data
  }

  public addQuestion = async (props: any) => {
    const question = {
      ...props.question,
      locations: props.question?.locations?.map((l: any) => ({
        ...l,
        id: Number(l.id),
      })),
      options: props.question.options?.map((o: any) => ({
        ...o,
        locations: o.locations?.map((l: any) => ({
          ...l,
          id: Number(l.id),
        })),
      })),
    }
    const res = await this.api().post(`/v2/templates/${props.id}/questions`, question)
    return res.data.data
  }

  public get = async (data: IGetTemplatesApi): Promise<ITemplateExtended[]> => {
    const { type, lastItemFetched, limit, folderId } = data
    let url = `/list/${type}`
    if (folderId) {
      url = `/list/${type}/category`
    }
    const params = new URLSearchParams()
    if (limit) params.set('limit', limit.toString())
    if (lastItemFetched) params.set('startAfter', lastItemFetched)
    if (!folderId) url = url + '?' + params.toString()
    try {
      const res = !folderId
        ? await this.api().get(`/v1/templates${url}`)
        : await this.api().post(`/v1/templates${url}`, { category: folderId })

      return res.data.data.templates.map((template: ITemplate) => ({
        ...template,
        rootFolder: type,
      }))
    } catch (err) {
      throw err
    }
  }

  public getOne = async (templateId: string, isPublic?: boolean, newDocxMicroservice?: boolean): Promise<ITemplate> => {
    const version = newDocxMicroservice ? 'v2' : 'v1'
    const res = await this.api().get(`/${version}/templates/${isPublic ? 'public/' : ''}${templateId}`)
    const data = res.data.data
    let beData = [] as any
    if (newDocxMicroservice) beData = parseBEData(data.dataStructure, data.cssData, data.questions)
    const [frontEndParsedStructure, frontEndEnrichedCSS] = beData
    const result = {
      ...data,
      questions: [
        ...(data.questions?.map((q: any) => ({
          ...q,
          locations: [
            ...(q.locations?.map((l: any) => ({
              ...l,
              id: String(l.id),
            })) || []),
          ],
        })) || []),
      ],
    }
    if (data.dataStructure?.storageId) frontEndParsedStructure.storageId = data.dataStructure.storageId
    if (frontEndParsedStructure) result.dataStructure = frontEndParsedStructure
    // if (frontEndGeneratedHTML) result.htmlData = frontEndGeneratedHTML
    if (frontEndEnrichedCSS) result.cssData = frontEndEnrichedCSS
    return result
  }

  public fetchHtml = async (templateId: string, data: any): Promise<any> => {
    // const res = await this.api().post(
    //   `/v2/templates/${templateId}/answered/preview`,
    //   { answers: [...data] }
    // )
    // return res.data.data
    return null
  }

  public update = async (props: IUpdateTemplateApi): Promise<ITemplate> => {
    const version = props.new ? 'v2' : 'v1'
    let res = {} as any
    const payload = {
      status: props.data.status,
      name: props.data.name,
      originalStylesKept: props.data.originalStylesKept,
      settings: props.data.settings,
      characteristics: props.data.characteristics,
      sharingEnabled: props.data.sharingEnabled,
      category: props.data.category
    } as any
    if (props.new) payload.questions = props.data.questions
    if (version === 'v2') {
      // res = await this.api().post(`/${version}/templates/${props.id}`, {
      res = await this.api().post(`/${version}/templates/${props.id}`, payload)
    } else
      res = await this.api().post(`/${version}/templates/${props.id}`, {
        ...props.data,
      })

    const data = res.data.data
    let beData = [] as any
    if (props.new) beData = parseBEData(data.dataStructure, data.cssData, data.questions)
    const [frontEndParsedStructure, frontEndEnrichedCSS] = beData
    const result = {
      ...data,
      questions: [
        ...(data.questions?.map((q: any) => ({
          ...q,
          locations: [
            ...(q.locations?.map((l: any) => ({
              ...l,
              id: String(l.id),
            })) || []),
          ],
        })) || []),
      ],
    }
    if (frontEndParsedStructure) result.dataStructure = frontEndParsedStructure
    // if (frontEndGeneratedHTML) result.htmlData = frontEndGeneratedHTML
    if (frontEndEnrichedCSS) result.cssData = frontEndEnrichedCSS

    return result
  }

  public uploadLogo = async (templateId: string, imageData: string): Promise<string> => {
    const res = await this.api().post(`/v1/templates/${templateId}/logo/upload`, {
      imageData,
    })
    return res.data.data.url
  }

  public getTemplateVersion = async (templateId: string, version: string, docXMicro: boolean): Promise<ITemplate> => {
    const res = await this.api().get(`/${docXMicro ? 'v2' : 'v1'}/templates/${templateId}/version/${version}`)
    const data = res.data.data
    let beData = [] as any
    if (docXMicro) beData = parseBEData(data.dataStructure, data.cssData, data.questions)
    const [frontEndParsedStructure, frontEndEnrichedCSS] = beData
    const result = {
      ...data,
      questions: [
        ...(data.questions?.map((q: any) => ({
          ...q,
          locations: [
            ...(q.locations?.map((l: any) => ({
              ...l,
              paragraphId: String(l.paragraphId),
            })) || []),
          ],
        })) || []),
      ],
    }
    if (data.dataStructure?.storageId) frontEndParsedStructure.storageId = data.dataStructure.storageId
    if (frontEndParsedStructure) result.dataStructure = frontEndParsedStructure
    // if (frontEndGeneratedHTML) result.htmlData = frontEndGeneratedHTML
    if (frontEndEnrichedCSS) result.cssData = frontEndEnrichedCSS

    return result
  }

  public duplicate = async ({ id }: IDuplicateTemplateApi, docXMicro: Boolean = false): Promise<void> => {
    const version = docXMicro ? 'v2' : 'v1'
    await this.api().post(`/${version}/templates/${id}/duplicate`)
  }

  public emptyTrashed = async () => {
    return await this.api().post('/v1/templates/empty/trashed')
  }

  public share = async (templateId: string, email: string): Promise<ITemplate> => {
    const res = await this.api().post(`/v1/templates/${templateId}/share`, {
      email,
      permissions: { read: true, write: false },
    })
    return res.data.data
  }

  public permissionsEdit = async (
    templateId: string,
    personId: string,
    permissions: IPermissions
  ): Promise<ITemplate> => {
    const res = await this.api().patch(`/v1/templates/${templateId}/share/${personId}`, {
      permissions,
    })
    return res.data.data
  }

  public permissionsDelete = async (templateId: string, personId: string): Promise<ITemplate> => {
    const res = await this.api().delete(`/v1/templates/${templateId}/share/${personId}`)
    return res.data.data
  }

  // public upload = async (payload: IUploadTemplateApi): Promise<string> => {
  //   const asposeRes = await this.api().post('/v1/templates/', payload)
  //   const htmlData = asposeRes.data.data
  //   return htmlData
  // }
}

const templateApi = new TemplateApi()
export default templateApi
