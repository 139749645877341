import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  backgroundColor: theme.colorz.brand.light,
  zIndex: 500,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}))
