import { UserInfoProps } from './EventItem.types'
import React from 'react'
import { UserWrapper, UserInfoWrapper, StrongText, Text } from './EventItem.styles'
import ProfileImage from 'components/user/ProfileImage/ProfileImage'

const UserInfo: React.FC<UserInfoProps> = ({ user, eventType }) => {
  const officeName = typeof user.office === 'string' ? user.office : user.office._name
  const [firstName, lastName] = user.name.split(' ')
  return (
    <UserWrapper>
      <ProfileImage
        imageUrl={user.avatarUrl}
        size={eventType === 'scheduled' ? 56 : 48}
        firstName={firstName}
        lastName={lastName}
      />
      <UserInfoWrapper>
        <StrongText>{user.name}</StrongText>
        {eventType === 'scheduled' && <Text>{officeName ?? ''}</Text>}
      </UserInfoWrapper>
    </UserWrapper>
  )
}

export default UserInfo
