import { FolderItem, RootFolder } from 'types'

export const SET_ROOT_FOLDER_TEMPLATES = 'SET_ROOT_FOLDER_TEMPLATES'
export const SET_CURRENT_FOLDER_TEMPLATES = 'SET_CURRENT_FOLDER_TEMPLATES'

export const setRootFolderTemplates = (
  rootFolder: RootFolder
): SetRootFolderTemplatesAction => ({
  type: SET_ROOT_FOLDER_TEMPLATES,
  payload: rootFolder,
})

export const setCurrentFolderTemplates = (
  folder: FolderItem
): SetCurrentFolderTemplatesAction => ({
  type: SET_CURRENT_FOLDER_TEMPLATES,
  payload: folder,
})

interface SetRootFolderTemplatesAction {
  type: typeof SET_ROOT_FOLDER_TEMPLATES
  payload: RootFolder
}

interface SetCurrentFolderTemplatesAction {
  type: typeof SET_CURRENT_FOLDER_TEMPLATES
  payload: FolderItem
}

export type TemplatesActions =
  | SetRootFolderTemplatesAction
  | SetCurrentFolderTemplatesAction
