import {
  ITime,
  IDocumentFormat,
  IDocumentAuthor,
  SharedWithItem,
  FolderItem,
} from "types";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import moment from "moment";

import C from "./C_Logo/C_Logo";
import { Icon, Loader } from "components/common";
import { secondary } from 'config/themes/colors'
import {
  Body,
  Preview,
  PreviewWrapper,
  Cover,
  Info,
  Details,
  Title,
  Time,
  TemplateName,
  SharedThrough,
  TitleWrapper,
  LoadingText,
  CoverLoading,
  // Dot,
} from "./FileComponent.styles";
import dummyPreview from "../../../assets/icons/dummyPreview.svg";
import { limitLongString } from "utils";
import { Download } from "components/common/Icons/Icons.styles";
import { Button } from "@material-ui/core";
import { useDocumentFileActions } from "hooks/useDocumentFileActions/useDocumentFileActions";
import { useEditor } from 'config/textEditor/useEditor'

import "./style.css"

interface Props {
  title: string;
  id: string;
  edited: ITime;
  renameActive?: boolean;
  templateName?: string;
  type?: IDocumentFormat;
  sharedAuthor?: IDocumentAuthor | null;
  isUpToDateWithTheLatestTemplateVersion?: boolean;
  sharedWith?: SharedWithItem[];
  currentFolderType?: FolderItem["type"];
  sharingEnabled?: boolean;
  isCasus?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onContextMenu?: React.MouseEventHandler<HTMLDivElement>;
  handleUpdateName?: (docId: string, newName: string) => void;
  isDownloadable?: boolean;
  isLoading?: boolean;
}

const FileComponent: React.FC<Props> = ({
  id,
  title,
  edited,
  renameActive,
  templateName,
  type,
  sharedAuthor,
  sharedWith,
  isUpToDateWithTheLatestTemplateVersion,
  currentFolderType,
  sharingEnabled,
  isCasus,
  onClick,
  onContextMenu,
  handleUpdateName,
  isDownloadable = false,
  isLoading = false
}) => {
  moment.locale("de");
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(title);
  const [focused, setFocused] = useState(false);
  const [showShared, setShowShared] = useState(false);
  const inputEl = useRef<HTMLTextAreaElement>(null);
  const { onDownload } = useDocumentFileActions()
  const editor = useEditor()

  useEffect(() => {
    if (title) setName(title);
  }, [title]);
  // const showDot = isUpToDateWithTheLatestTemplateVersion === false

  const handleFocusEl = useCallback(() => {
    if (inputEl.current) {
      setTimeout(() => {
        if (inputEl.current) {
          inputEl.current.focus();
          setFocused(true);
        }
      }, 1);
    }
  }, [inputEl]);

  useEffect(() => {
    if (renameActive) handleFocusEl();
  }, [handleFocusEl, renameActive]);

  useEffect(() => {
    if (focused && inputEl.current) {
      inputEl.current.select();
    }
  }, [focused]);

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!onContextMenu) return true;
    e.preventDefault();
    onContextMenu(e);
  };

  const handleNameBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    if (handleUpdateName) handleUpdateName(id, name);
    inputEl.current?.scroll(0, 0);
    setFocused(false);
  };

  const handleEnterClick = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ): void => {
    if (e.keyCode === 13 && handleUpdateName) handleUpdateName(id, name);
    setFocused(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (renameActive) return;
    onClick(e);
  };

  const onIconHover = () => {
    if (!showShared && sharedAuthor) setShowShared(true);
  };

  const onIconOut = () => {
    if (showShared) setShowShared(false);
  };

  return (
    <Body
      boxShadow={0}
      isdocument={templateName || type ? 1 : 0}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      id="document-item"
      onMouseLeave={() => {
        setOpen(false)
      }}
    >
      {/* {showDot && <Dot />} */}
      <Preview>
        <PreviewWrapper>
          {!isLoading ? (
              <Cover>
                <img src={dummyPreview} alt="" />
              </Cover>
            ) : (
              <CoverLoading>
                <Loader />
              </CoverLoading>
            )
          }
        </PreviewWrapper>
      </Preview>
      <Info>
        <TitleWrapper>
          <Title
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleNameBlur}
            onKeyDown={handleEnterClick}
            disabled={!renameActive}
            ref={inputEl}
            autoFocus
            rows={2}
          ></Title>
          {isDownloadable ?
            <Button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setOpen(!open)
              }}
              style={{
                width: "2.3em",
                minWidth: "0",
                height: "fit-content",
                padding: "4px",
              }}
            >
              <Download />
              <div className={`DownloadButton-optionsDiv${open ? " open" : ""}`}>
                {/* <Button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onDownload(id, 'pdf', title, editor)
                  }}
                  style={{
                    width: "2em",
                    minWidth: "0",
                height: "fit-content",
                    padding: "4px",
                  }}
                >
                  P
                </Button> */}
                <div
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onDownload(id, 'docx', title, editor)
                  }}
                  style={{
                    width: "2em",
                    minWidth: "0",
                    height: "fit-content",
                    padding: "4px",
                  }}
                >
                  <Icon key={'docx'} iconName={'docx'} fill={secondary[200]} />
                </div>
              </div>
            </Button> : null
          }
        </TitleWrapper>
        <Details>
          {templateName ? (
            <TemplateName>
              Vorlage: {limitLongString(templateName, 20)}
            </TemplateName>
          ) : type ? (
            <TemplateName></TemplateName>
          ) : null}
          <br />
          {type === "pdf" ? (
            <Icon iconName="pdf" style={{ marginRight: 8 }} />
          ) : (
            <C category="" />
          )}
          {edited && !isCasus && !isLoading && (
            <Time>{moment.unix(edited._seconds).fromNow()}</Time>
          )}
          {isLoading && (
            <LoadingText>Wir erstellen die Datei</LoadingText>
          )}
        </Details>
      </Info>
      {currentFolderType === "shared" ||
        (currentFolderType === "my" && sharedWith?.length && sharingEnabled) ? (
        <Fragment>
          {showShared && sharedAuthor && currentFolderType === "shared" && (
            <SharedThrough>
              Geteilt durch:
              <br />
              {`${sharedAuthor.firstName} ${sharedAuthor.lastName}`}
            </SharedThrough>
          )}
          {showShared && !!sharedWith?.length && currentFolderType === "my" && (
            <SharedThrough>
              Geteilt mit:
              <br />
              {sharedWith.map((sh, i) => (
                <Fragment key={i}>
                  {`${sh.firstName} ${sh.lastName}`}
                  {i < sharedWith.length - 1 ? <br /> : null}
                </Fragment>
              ))}
            </SharedThrough>
          )}
          <Icon
            iconName="shared_with"
            style={{ position: "absolute", bottom: 16, right: 16 }}
            onMouseEnter={onIconHover}
            onMouseLeave={onIconOut}
          />
        </Fragment>
      ) : null}
    </Body>
  );
};

export default FileComponent;
