import { StripeIbanElementOptions, StripeElementStyle } from '@stripe/stripe-js'

import React from 'react'
import { IbanEl, ibanStyleOptions } from './Iban.styles'

interface Props {
  style?: StripeElementStyle
}

const IBAN_ELEMENT_OPTIONS: StripeIbanElementOptions = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'CH',
  style: ibanStyleOptions,
}

const Iban: React.FC<Props> = ({ style }) => {
  return <IbanEl options={IBAN_ELEMENT_OPTIONS} />
}

export default Iban
