const root = '/aktenschrank'

export const documentsRoutes = {
  main: root,
  reminders: `${root}/erinnerungen`,
  settings: `${root}/einstellungen`,
}

const legalAdvice = '/rechtsberatung'

export const legalAdviceRoutes = {
  main: legalAdvice,
  pastEvents: `${legalAdvice}/verlauf`,
  bookLawyer: `${legalAdvice}/beratungstermin`,
}

export const ROUTE_UPGRADE_SUBSCRIPTION = '/abo'

const templates = '/vorlagen'

export const templatesRoutes = {
  main: templates,
  create: '/vorlage/neu',
}
