import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  maxWidth: 650,
  maxHeight: 450,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(8,2,2),
  backgroundColor: theme.colorz.secondary.main,
  position: 'relative',
}))

export const ActionBtnContainer = styled(Box)({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  justifyContent: 'space-around',
  margin: 'auto 0 56px 0',
})

export const Heading = styled(Typography)({
  color: '#fff',
  textAlign: 'center',
  fontWeight: 500,
  fontSize: 18,
  marginBottom: 32,
})

export const Text = styled(Typography)(({ theme }) => ({ 
  color: '#fff', 
  textAlign: 'center', 
  marginBottom: theme.spacing(5),
  padding: theme.spacing(0,7),
}))
