import DocsApi from 'api/docs.api'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useToast } from 'hooks/useToast'
import { queryKeys } from 'queries/queryKeys'
import { useMutation, useQueryClient } from 'react-query'
import { IDocument } from 'types'

interface Data {
  pageParams: [string | undefined] | never[]
  pages: IDocument[][]
}

export const useDocumentsDuplicate = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { currentFolder } = useCurrentFolder('docs')
  const { type } = currentFolder

  const mutation = useMutation(queryKeys.DOCUMENT, DocsApi.duplicate, {
    onMutate: async (newDocument: IDocument) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([
        queryKeys.DOCUMENTS,
        { type, folderId: currentFolder.id },
      ])

      // Snapshot the previous value
      const previousDocs = queryClient.getQueryData<Data>([
        queryKeys.DOCUMENTS,
        { type, folderId: currentFolder.id },
      ])

      let tmpNewDocument = {
        ...newDocument,
        id: "dummy",
        isLoading: true,
        name: `Kopie von ${newDocument.name}`,
      }

      // Optimistically update to the new value
      if (previousDocs) {
        queryClient.setQueryData<Data>(
          [queryKeys.DOCUMENTS, { type, folderId: currentFolder.id }],
          (data) => {
            const pages = data?.pages ?? []
            const pageOne = pages[0] ?? []
            pageOne.unshift(tmpNewDocument)
            pages[0] = pageOne
            return data ? { ...data, pages } : { pageParams: [], pages: [] }
          }
        )
      }

      // Return a context object with the snapshotted value
      return { previousDocs }
    },
    onSuccess: (docFile, variables) => {
      queryClient.invalidateQueries([
        queryKeys.DOCUMENTS,
        { type, folderId: currentFolder.id },
      ]).then(() => toast('success', 'Sie haben das Dokument erfolgreich dupliziert'))
    },
    onError: () => {
      toast('error', 'default')
    },
  })
  return mutation
}
