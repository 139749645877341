import React, { useState } from 'react'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  DatePicker as MuiDatePicker,
} from '@material-ui/pickers/'
import {
  PickerContainer,
  Typography,
  ToolbarContainer,
  Year,
  DateText,
} from './DatePicker.styles'
import moment from 'moment'
import 'moment/locale/de'
import { Icon } from 'components/common'
import './picker.css'
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker'
import { secondary } from 'config/themes/colors'

type TPickerVariant = 'strong' | 'light'

interface IDatePickerProps {
  value: Date | null
  variant?: TPickerVariant
  disabled?: boolean
  pickerStyles?: React.CSSProperties
  disablePast?: boolean
  handleChange: (date?: Date | null) => void
}

interface IPickerProps {
  date: Date | null
  variant?: TPickerVariant
  style?: React.CSSProperties
  disabled: boolean
  handleOpen: React.MouseEventHandler<HTMLDivElement>
}

const Picker: React.FC<IPickerProps> = ({
  date,
  disabled,
  variant = 'strong',
  style,
  handleOpen,
}) => {
  moment.updateLocale('de', null)
  const dateMom = moment(date)
  return (
    <PickerContainer
      onClick={handleOpen}
      isdisabled={disabled ? 1 : 0}
      variant={variant}
      style={style}
    >
      <Typography
        variant={variant === 'strong' ? 'h5' : 'body1'}
        style={{ color: variant === 'strong' ? secondary.main : secondary[300] }}
      >
        {date ? dateMom.format('D. MMMM YYYY') : 'Datum auswählen'}
      </Typography>
      <Icon iconName='date' style={{ opacity: variant === 'strong' ? 1 : 0.5 }} />
    </PickerContainer>
  )
}

interface Props extends ToolbarComponentProps {}

const Toolbar: React.FC<Props> = ({ date, setOpenView, openView }) => {
  moment.updateLocale('de', null)
  const theDate = moment(date as IPickerProps['date'])
  const dateYear = theDate.year()
  const dateText = theDate.format('dd., Do  MMMM')

  return (
    <ToolbarContainer>
      {date && (
        <>
          <Year onClick={() => setOpenView('year')}>{dateYear}</Year>
          <DateText onClick={() => setOpenView('month')}>{dateText}</DateText>
        </>
      )}
    </ToolbarContainer>
  )
}

const DatePicker: React.FC<IDatePickerProps> = ({
  handleChange,
  value,
  disabled,
  variant,
  pickerStyles,
  disablePast = false,
}) => {
  const locale = moment.updateLocale('de', null)

  const [open, setOpen] = useState(false)

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} libInstance={moment}>
      <MuiDatePicker
        onClose={() => setOpen(false)}
        open={open}
        lang='de'
        margin='normal'
        id='date-picker-dialog'
        value={value}
        onChange={date => handleChange(new Date((date as any)?._d))}
        views={['date']}
        ToolbarComponent={props => <Toolbar date={value} {...props} />}
        inputVariant='outlined'
        disablePast={disablePast}
        okLabel='Bestätigen'
        cancelLabel='Abbrechen'
        TextFieldComponent={() => (
          <Picker
            date={value}
            handleOpen={() => setOpen(true)}
            disabled={!!disabled}
            variant={variant}
            style={pickerStyles}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
