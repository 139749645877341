import { State } from 'types'
import React from 'react'
import { useSelector } from 'react-redux'
import * as Ui from 'constants/ui-elements'
import UpgradeSubscriptionPlan from 'components/subscriptions/components/Modals'

const selector = (state: State) => {
  return {
    modals: state.ui.showUiElement,
  }
}

// Record<Ui.TUiElement, React.FC>

const modalMap: any = {
  [Ui.UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC]: UpgradeSubscriptionPlan.Basic,
  [Ui.UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID]: UpgradeSubscriptionPlan.NotPaid,
  [Ui.UI_SUPSCRIPTION_UPGRADE_SUCCESS_MODAL]: UpgradeSubscriptionPlan.Success,
  [Ui.UI_TRIAL_EXPIRED_MODAL]: UpgradeSubscriptionPlan.Expired,
  [Ui.UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NEW_SUBSCRIPTION]: UpgradeSubscriptionPlan.New,
}

const renderModals = (modals: Ui.TUiElement[]) => {
  const modalsOpen: React.FC[] = []

  for (const modal of modals) {
    const Component: React.FC = modalMap[modal]
    if (modalMap[modal]) {
      modalsOpen.push(Component)
    }
  }
  return modalsOpen
}

export const useModalComponents = () => {
  const { modals } = useSelector(selector)
  const ModalComponents = React.useMemo(() => {
    return renderModals(modals)
  }, [modals])

  return ModalComponents
}
