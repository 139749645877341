import { RootModal } from 'components/common'
import React from 'react'
import theme from 'config/themes/theme'

import Table from './Table/Table'

interface Props {
  open: boolean
  onClose: () => void
}

const SubscriptionPlansModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <RootModal
      open={open}
      onClose={onClose}
      backdropStyle={{ background: theme.colorz.secondary[100], opacity: 0.9 }}
      style={{ marginBottom: 0 }}
    >
      <Table onClose={onClose} />
    </RootModal>
  )
}

export default SubscriptionPlansModal
