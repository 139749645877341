import { CheckboxGroupProps } from './CheckboxGroup.types'
import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { Label } from './CheckboxGroup.styles'
import { secondary } from '../../../config/themes/colors'

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  label,
  checked = false,
  multiline,
  marginBottom,
  handleChange,
  error,
  ...otherProps
}) => {
  const { disabled } = otherProps
  const checkboxStyles = {
    color: secondary.main,
    alignSelf: multiline ? 'flex-start' : 'center',
    transform: `translateY(${multiline ? '-5px' : '0'})`,
    opacity: 1,
  }

  if (error) {
    checkboxStyles.color = 'red'
  } else if (checked) {
    checkboxStyles.color = secondary.main
  }

  if (otherProps.disabled) checkboxStyles.opacity = 0.4

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          color='primary'
          style={checkboxStyles}
          {...otherProps}
        />
      }
      label={<Label style={{ opacity: disabled ? 0.4 : 1 }}>{label}</Label>}
      style={{ marginBottom: marginBottom === undefined ? '15px' : marginBottom }}
    />
  )
}

export default CheckboxGroup
