import React from 'react'
import { useHistory } from 'react-router'
import Validator from 'validator'
import { useReminder, useReminderCreate, useReminderUpdate } from 'queries/reminders'
import { useToast } from 'hooks/useToast'

import { SetReminderModalComponent } from './SetReminderModal.component'
import { IReminderRequestApi } from 'api/reminders'

export const SetReminderModal: React.FC = () => {
  const { location, replace } = useHistory()
  const toast = useToast()
  const params = new URLSearchParams(location.search)
  const entityId = params.get('entity')
  const action = params.get('action')
  const reminderParam = params.get('mahnung')

  const { data: reminder, isLoading } = useReminder(reminderParam)
  const create = useReminderCreate()
  const update = useReminderUpdate()

  const onSubmit = (data: Omit<IReminderRequestApi, 'entityId'>) => {
    const { reminderName, deadlineTime, alert } = data

    const finalAlert = { ...alert }
    if (!Validator.isNumeric(alert.at.toString())) finalAlert.at = 0

    if (!reminderName || !deadlineTime) {
      return toast('error', 'Sie müssen alle Felder ausfüllen.')
    }
    if (!Validator.isNumeric(finalAlert.at.toString()))
      return toast('error', `Das Feld "Tage" muss eine Nummer sein.`)

    if (reminder) {
      update.mutate(
        {
          id: reminder.id,
          reminderName,
          deadlineTime,
          alert: finalAlert,
        },
        { onSuccess: () => replace(location.pathname) }
      )
      return
    }
    if (entityId) {
      create.mutate(
        {
          entityId,
          reminderName,
          deadlineTime,
          alert: finalAlert,
        },
        { onSuccess: () => replace(location.pathname) }
      )
    }
  }

  const open = action === 'mahnung' && !!(entityId || reminderParam)

  return open ? (
    <SetReminderModalComponent
      open={open}
      reminder={reminder}
      isLoading={isLoading}
      isSaving={create.isLoading || update.isLoading}
      onSubmit={onSubmit}
      handleClose={() => replace(location.pathname)}
    />
  ) : null
}
