import { AdjustProfileImageComponentProps } from './ProfileImageModal.types'
import React, { Fragment } from 'react'
import ImageEditor from 'components/common/ImageEditor/ImageEditor'
import ImageEditorSlider from 'components/common/ImageEditor/ImageEditorSlider'
import { StrongText } from 'pages/Authenticated/LegalAdvice/components/EventsList/EventItem/EventItem.styles'

const AdjustProfileImage: React.FC<AdjustProfileImageComponentProps> = ({
  imageUrl,
  handleNewUploadClick,
  editorRef,
}) => {
  const [zoom, setZoom] = React.useState<number>(1)

  const handleChange = (event: any, newValue: number | number[]) => {
    if (typeof newValue === 'number') setZoom(newValue)
  }

  return (
    <Fragment>
      <ImageEditor
        ref={editorRef}
        image={imageUrl}
        zoom={zoom}
        width={251}
        height={251}
      />
      <ImageEditorSlider
        value={zoom}
        handleChange={handleChange}
        title='Zoom'
        style={{ margin: '32px 0' }}
      />
      <StrongText style={{ cursor: 'pointer' }} onClick={handleNewUploadClick}>
        Neues hochladen
      </StrongText>
    </Fragment>
  )
}

export default AdjustProfileImage
