import { Box, styled } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  padding: theme.spacing(3),
}))

export const NavWrapper = styled(Box)(({ theme }) =>({
  flexShrink: 1,
  padding: theme.spacing(0,3),
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
}))

export const SettingsContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0 10%',
})