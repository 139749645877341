import { useMutation, useQueryClient } from 'react-query'
import { useRootFolder } from 'hooks/useRootFolder'
import { useFolderKeys } from './useFolderKeys'

import { FolderItem, FolderItemSimple } from 'types'
import FoldersApi, { ICreateFolderApiProps } from 'api/folders.api'
import { AxiosError } from 'axios'
import { FileManager } from 'utils'

export const useCreateFolder = (
  where: 'templates' | 'docs',
  currentFolder: FolderItem
) => {
  const queryClient = useQueryClient()
  const { rootFolder, setRootFolder } = useRootFolder(where)
  const [many] = useFolderKeys(where)
  const keys = [many, currentFolder.id]

  const mutation = useMutation<
    FolderItemSimple,
    AxiosError,
    ICreateFolderApiProps,
    FolderItemSimple
  >(keys, p => FoldersApi.create({ type: where, ...p }), {
    onSuccess: newFolder => {
      queryClient.setQueryData<FolderItemSimple[]>(keys, data => {
        return [newFolder, ...(data || [])]
      })
      const fileManager = new FileManager(rootFolder, currentFolder)
      fileManager.addNewFolder(newFolder)
      setRootFolder(fileManager.data)
    },
  })

  return mutation
}
