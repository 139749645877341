import React, { useState, useEffect } from 'react'
import { CheckboxGroup, CustomButton, FilePicker, Loader } from 'components/common'
import {
  Container,
  Heading,
  Description,
  SubtitleDescription,
  Content,
  UploadOptionsContainer,
  Col,
  Header,
  Footer,
  Img,
  BackBtn,
} from './UploadTemplate.styles'
import {
  btnContent,
  uploadContent as content,
} from 'pages/Authenticated/Template/constants/content'
import upload_svg from './assets/upload_template.svg'
import no_template_svg from './assets/no_template.svg'

interface Props {
  isLoading: boolean
  onCreate: (file: File | null, keepStyles: boolean) => void
  onBack: () => void
}

const UploadTemplateComponent: React.FC<Props> = ({ isLoading, onCreate, onBack }) => {
  const [file, setFile] = useState<File | null>(null)
  const [keepStyles, setKeepStyles] = useState(false)

  let btnText = btnContent.lookup
  if (isLoading) btnText = btnContent.uploading

  const reset = () => {
    setFile(null)
    setKeepStyles(false)
  }

  useEffect(() => {
    if (file) {
      onCreate(file, keepStyles)
      reset()
    }
  }, [file, keepStyles, onCreate])

  let theContent = null

  if (isLoading) {
    theContent = <Loader />
  } else {
    theContent = (
      <Content>
        <Header>
          <Heading variant='h3'>{content.headingTop}</Heading>
        </Header>
        <UploadOptionsContainer>
          <Col>
            <Img src={upload_svg} alt='Upload document illustration' />
            <Heading variant='h4'>{content.headingUpload}</Heading>
            <Description variant='body1'>{content.descriptionUpload} </Description>
            <FilePicker accept='.doc, .docx' setFile={setFile}>
              <CustomButton
                id='template-creation-upload-btn'
                width={180}
                btntype='primary'
                disabled={isLoading}
                size='medium'
                style={{ flexShrink: 0 }}
              >
                {btnText}
              </CustomButton>
            </FilePicker>
            <CheckboxGroup
              label='Format beibehalten'
              checked={keepStyles}
              handleChange={() => setKeepStyles(prev => !prev)}
              style={{ marginBottom: 0 }}
            />
            <SubtitleDescription>{content.subtitle}</SubtitleDescription>
          </Col>
          <Col>
            <Img src={no_template_svg} alt='Upload document illustration' />
            <Heading variant='h4'>{content.headingEmpty}</Heading>
            <Description variant='body1'>{content.descriptionEmpty} </Description>
            <CustomButton
              id='template-creation-upload-empty-btn'
              width={180}
              btntype='primary'
              disabled={isLoading || keepStyles}
              size='medium'
              style={{ flexShrink: 0, marginTop: 12 }}
              onClick={() => onCreate(null, false)}
            >
              Leere Vorlage
            </CustomButton>
          </Col>
        </UploadOptionsContainer>
        <Footer>
          <BackBtn onClick={onBack}>Zurück</BackBtn>
        </Footer>
      </Content>
    )
  }

  return <Container>{theContent}</Container>
}

export default UploadTemplateComponent
