import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import DocumentCreate from './views/DocumentCreate/DocumentCreate.page'
import DocumentEdit from './views/DocumentEdit/DocumentEdit.page'
import DocumentPreview from './views/DocumentPreview/DocumentPreview.page'

const DocumentFile: React.FC = () => {
  return (
    <Switch>
      <Route path='/dokument/neu' component={DocumentCreate} />
      <Route exact path='/dokument/:id/bearbeiten' component={DocumentEdit} />
      <Route exact path='/dokument/:id' component={DocumentPreview} />
      <Redirect to='/aktenschrank' />
    </Switch>
  )
}

export default DocumentFile