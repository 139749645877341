import { ButtonSquareProps } from './ButtonSquare.types'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { ButtonContainer } from './ButtonSquare.styles'

const ButtonSquare = React.forwardRef(
  ({ children, ...props }: ButtonSquareProps, ref?: React.Ref<HTMLButtonElement>) => {
    return (
      <ButtonContainer {...props} buttonRef={ref}>
        {children ? children : <CloseIcon />}
      </ButtonContainer>
    )
  }
)

export default ButtonSquare
