import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const LoaderContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 25,
  letterSpacing: 1,
  paddingBottom: 96,
  color: theme.colorz.secondary.main,
}))
