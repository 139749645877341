import { CommonSidebarProps } from 'types'
import React from 'react'
import Sidebar from 'components/Sidebar/Sidebar'
import { useUser } from 'queries/user/useUser'

const SidebarContainer: React.FC<CommonSidebarProps> = props => {
  const user = useUser()!
  return <Sidebar user={user} {...props} />
}

export default SidebarContainer
