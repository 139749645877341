import React from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@material-ui/core'

import FeaturesLayout from 'layout/FeaturesLayout/FeaturesLayout'

const steps = [
  { step: 1, label: 'Auswahl des Abos' },
  { step: 2, label: 'Zahlungsinformationen' },
  { step: 3, label: 'Konto aktualisiert' },
]

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  padding: '48px 0',
  maxHeight: 'calc(100vh - 56px)',
  overflowY: 'auto',
})

const Layout: React.FC = ({ children }) => {
  const { push, location } = useHistory<{ from?: string }>()
  const onClose = () => {
    if (location?.state?.from) return push(location.state.from)
    push('/einstellungen/abo')
  }
  return (
    <FeaturesLayout
      heading='Konto-Upgrade'
      steps={steps}
      handleClose={onClose}
      isLoading={false}
      currentStep={1}
    >
      <Container>{children}</Container>
    </FeaturesLayout>
  )
}

export default Layout
