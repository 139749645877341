import { ActionCEl } from './NoItemsAction.types'
import { styled, Box, Typography } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

const textStyles: CreateCSSProperties = {
  textAlign: 'center',
  fontWeight: 700,
  letterSpacing: 0.4,
}

export const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
})

export const Image = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  height: 120,
  width: 'auto',
}))

export const ActionText = styled(Typography)(({ theme }) => ({
  ...textStyles,
  cursor: 'pointer',
  color: theme.colorz.primary.main,
  textDecoration: 'underline',
  margin: theme.spacing(1, 0),
  '&:hover': {
    color: theme.colorz.primary.light,
  },
}))

export const ActionsContainer = styled(Box)(({ feature }: ActionCEl) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: feature ? '15vh 10% 0 0' : '0 3% 7% 0',
}))

export const Text = styled(Typography)(textStyles)
