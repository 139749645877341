import { Question } from 'types'
import { v4 as uuidv4 } from 'uuid'
import { QUESTION_TYPE_TEXT_BOX} from 'constants/question-types'

export const initializeQuestion = (sort?: number): Question => ({
  id: uuidv4(),
  questionText: '',
  tags: [''],
  description: '',
  type: QUESTION_TYPE_TEXT_BOX,
  optionId: null,
  parentId: '',
  locations: [],
  sort: sort ?? 1,
  answerExample: '',
  group: ''
})
