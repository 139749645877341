import React, { useState } from 'react'
import { IQuestionRadio } from 'types'
import {
  RadioButton,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from './QuestionRadio.styles'

interface Props {
  question: IQuestionRadio
  value: string[]
  disabled: boolean
  handleChange: (value: string[]) => void
}

const QuestionRadio: React.FC<Props> = ({ question, handleChange, value, disabled }) => {
  const [selectedRadio, setSelectedRadio] = useState(
    value.length ? value[0] : question.options[0].id
  )

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value: targetValue } = e.target
    setSelectedRadio(targetValue)
    handleChange([targetValue])
  }

  return (
    <FormControl
      component='fieldset'
      style={{ marginBottom: 24, pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <RadioGroup name={question.id} value={selectedRadio} onChange={handleRadioChange}>
        {question.options.map((opt, i) => (
          <FormControlLabel
            id={opt.id}
            key={opt.id}
            value={opt.id}
            labelPlacement='end'
            style={i !== question.options.length - 1 ? { marginBottom: 16 } : undefined}
            control={
              <RadioButton
                color='default'
                style={{ pointerEvents: disabled ? 'none' : 'auto' }}
              />
            }
            label={opt.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default QuestionRadio
