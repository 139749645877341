import { BreadcrumbNavigationComponentProps } from './BreadcrumbNavigation.types'
import React, { Fragment } from 'react'
import { Container, Text } from './BreadcrumbNavigation.styles'

const BreadcrumbNavigation: React.FC<BreadcrumbNavigationComponentProps> = ({
  folder,
  handlePathClick,
}) => {
  return (
    <Container>
      {folder.path.map((pathItem, i) => (
        <Fragment key={pathItem.id}>
          <Text disabled={0} onClick={() => handlePathClick(pathItem.id)}>
            {pathItem.name}
          </Text>
          /
        </Fragment>
      ))}
      <Text disabled={1}>{folder.name}</Text>
    </Container>
  )
}

export default BreadcrumbNavigation
