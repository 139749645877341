import { TabItemContainerProps } from '../Tabs.types'
import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
})

export const TabItemContainer = styled(Box)(
  ({ theme, isactive }: TabItemContainerProps) => ({
    paddingBottom: theme.spacing(0.5),
    borderBottom:
      '1px solid ' +
      (!isactive ? theme.colorz.secondary[300] : theme.colorz.secondary.main),
    color: !isactive ? theme.colorz.secondary[300] : theme.colorz.secondary.main,
    textAlign: 'center',
    transition: 'all .2s',
    flexGrow: 1,
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    '&:hover': {
      color: theme.colorz.secondary.main,
      borderColor: theme.colorz.secondary.main,
    },
  })
)

export { Typography }
