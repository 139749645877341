import { TextAreaGroupProps } from './TextareaGroup.types'
import React from 'react'
import { Container, Label, TextareaField } from './TextareaGroup.styles'

const TextareaGroup: React.FC<TextAreaGroupProps> = ({ label, error, style, ...otherProps }) => {
  return (
    <Container style={style}>
      {label && <Label variant='h5'>{label}</Label>}
      <TextareaField
        spellCheck='false'
        error={error ? 1 : 0}
        {...otherProps}
      ></TextareaField>
    </Container>
  )
}

export default TextareaGroup
