import { IContainerEl } from './TypeBox.types'
import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled('div')(({ theme, selected }: IContainerEl) => ({
  height: theme.spacing(6),
  minWidth: 140,
  width: '47%',
  padding: theme.spacing(0, 2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  borderWidth: 1,
  borderBottomWidth: !selected ? 2 : 1,
  borderStyle: 'solid',
  borderColor: !selected ? theme.colorz.brand.main : theme.colorz.brand.dark,
  backgroundColor: selected ? theme.colorz.brand.main : '#fff',
  cursor: 'pointer',
  transition: 'all .2s',
  fontWeight: 'normal',
  '&:hover': {
    borderColor: theme.colorz.brand.dark,
  },
}))

export const IconContainer = styled(Box)({
  marginRight: 14,
  opacity: 0.7,
})

export { Typography }
