import React, { Fragment } from 'react'

import { useLegalAdviceEvents } from '../../../hooks/useLegalAdviceEvents'

import EventsListPast from 'pages/Authenticated/LegalAdvice/components/EventsList/EventListPast'

import NoItemsAction from 'components/common/NoItemsAction/NoItemsAction'
import no_event_svg from '../../../assets/events-empty.svg'

const PastEventsClient: React.FC = () => {
  const { pastEvents } = useLegalAdviceEvents()

  return (
    <Fragment>
      {pastEvents.length ? (
        <EventsListPast eventItems={pastEvents} />
      ) : (
        <NoItemsAction
          text='Sie haben noch keine vergangenen Beratungstermine'
          image={no_event_svg}
        />
      )}
    </Fragment>
  )
}

export default PastEventsClient
