export const hasSameValues = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) return false
  const compare: { [key: string]: boolean } = {}
  for (let s1 of arr1) {
    compare[s1] = true
  }
  for (let s2 of arr2) {
    if (!compare[s2]) return false
  }
  return true
}
