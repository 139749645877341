import Api from './api'
import {
  IUpdateFolderRequestProps,
  IPermissions,
  FolderItemSimple,
  FolderItem,
} from 'types'

type FolderType = 'docs' | 'templates'

export interface IUpdateFolderApiProps {
  id: string
  data: IUpdateFolderRequestProps
}
interface IUpdateFolderApi extends IUpdateFolderApiProps {
  type: FolderType
}

export interface ICreateFolderApiProps {
  data: { name: string; parentCategoryId: string }
}
interface ICreateFolderApi extends ICreateFolderApiProps {
  type: FolderType
}

class FoldersApi extends Api {
  public constructor() {
    super('/')
  }

  public get = async (
    where: 'templates' | 'docs',
    currentFolder: FolderItem
  ): Promise<FolderItemSimple[]> => {
    const { id, type } = currentFolder
    const apiType = where === 'templates' ? 'template' : 'document'

    const isRootCategory = id === type
    const baseUrl = `/${apiType}Categories`

    let url = `${baseUrl}/list/${type}`

    const res = isRootCategory
      ? await this.api().get(url)
      : await this.api().post(url + '/category', { category: id })

    return res.data.data.folders.map((f: FolderItemSimple) => ({
      ...f,
      parentCategoryId: type === id ? type : f.parentCategoryId,
    }))
  }

  public getOne = async (
    where: FolderType,
    folderId: string
  ): Promise<FolderItemSimple> => {
    const apiType = where === 'templates' ? 'template' : 'document'
    const url = `/${apiType}Categories/${folderId}`
    const res = await this.api().get(url)
    return res.data.data
  }

  public create = async (props: ICreateFolderApi): Promise<FolderItemSimple> => {
    const { type, data } = props
    const apiType = type === 'templates' ? 'template' : 'document'
    const res = await this.api().post(`/${apiType}Categories`, data)
    return res.data.data
  }

  public update = async (props: IUpdateFolderApi) => {
    const { type, id, data } = props
    const apiType = type === 'templates' ? 'template' : 'document'
    const res = await this.api().post(`/${apiType}Categories/${id}`, data)
    return res.data.data
  }

  public share = async (
    where: FolderType,
    folderId: string,
    email: string
  ): Promise<FolderItemSimple> => {
    const apiType = where === 'templates' ? 'template' : 'document'
    const res = await this.api().post(`/${apiType}Categories/${folderId}/share`, {
      email,
      permissions: { read: true, write: false },
    })
    return res.data.data
  }

  public permissionsUpdate = async (
    where: FolderType,
    folderId: string,
    personId: string,
    permissions: IPermissions
  ): Promise<FolderItemSimple> => {
    const apiType = where === 'templates' ? 'template' : 'document'
    const res = await this.api().patch(
      `/${apiType}Categories/${folderId}/share/${personId}`,
      {
        permissions,
      }
    )

    return res.data.data
  }

  public permissionsDelete = async (
    where: FolderType,
    folderId: string,
    personId: string
  ): Promise<FolderItemSimple> => {
    const apiType = where === 'templates' ? 'template' : 'document'
    const res = await this.api().delete(
      `/${apiType}Categories/${folderId}/share/${personId}`
    )
    return res.data.data
  }
}

const foldersApi = new FoldersApi()

export default foldersApi
