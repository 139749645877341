import { RootFolder, TemplateType, TSubscriptionPlan } from 'types'

export class FolderStructureAdapter {
  static adaptRootChildrenToUserSubscriptionType = (
    rootFolder: RootFolder,
    subscriptionType: TSubscriptionPlan['type'] | undefined,
    where: 'docs' | 'templates',
    casusTemplatesEnabled: Boolean = false,
    excludeChildren?: TemplateType[]
  ): RootFolder => {
    let _rootFolder = JSON.parse(JSON.stringify(rootFolder)) as RootFolder
    let children = _rootFolder.children

    if (subscriptionType && ['basic', 'free'].includes(subscriptionType)) {
      const allowed = [] as Array<String>
      if (where === 'docs') allowed.push('my', 'trashed')
      // const allowed = where === 'docs' ? ['my', 'trashed'] : ['casus']
      if (where === 'templates') allowed.push('casus')
      children = children.filter((child) => allowed.includes(child.id))
    }

    if (!casusTemplatesEnabled)
      children = children.filter((child) => child.id !== 'casus')

    if (excludeChildren)
      children = children.filter((ch) => !excludeChildren.includes(ch.type))

    return { ..._rootFolder, children }
  }
}
