import { FolderComponentProps } from './Folder.types'
import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react'

import { MenuPopover, Icon } from 'components/common'
import { Container, TextInput, SharedThrough } from './Folder.styles'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import { isEditorIsOwner } from 'utils'

const Folder: React.FC<FolderComponentProps> = ({
  userId,
  folder,
  disableRightClick,
  handleClickFolder,
  handleRenameFolder,
  handleDeleteFolder,
  handleMoveFolder,
  handleRestoreFolder,
  handleShareFolder,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [name, setName] = useState(folder.name)
  const [focused, setFocused] = useState(false)
  const [showShared, setShowShared] = useState(false)
  const inputEl = useRef<HTMLInputElement>(null)

  const handleFocusEl = useCallback(() => {
    if (inputEl.current) {
      inputEl.current.focus()
      setFocused(true)
    }
  }, [inputEl])

  useEffect(() => {
    if (focused && inputEl.current) {
      inputEl.current.select()
    }
  }, [focused])

  const rightClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disableRightClick) return
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }

  const leftClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (handleClickFolder) handleClickFolder(folder)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuItems: IPopupMenuItem[] = []

  const { isOwner, isEditor: canEdit } = isEditorIsOwner(
    userId!,
    folder?.authorId,
    folder?.sharedWith
  )

  const isEditor = folder?.type === 'shared' && canEdit

  if (handleClickFolder)
    menuItems.push({
      icon: 'preview',
      label: 'Öffnen',
      handleClick: () => handleClickFolder(folder),
    })

  if (isOwner || isEditor) {
    if (handleRenameFolder)
      menuItems.push({
        icon: 'rename',
        label: 'Umbenennen',
        handleClick: handleFocusEl,
      })
  }

  if (isOwner) {
    if (handleMoveFolder)
      menuItems.push({
        icon: 'restore',
        label: 'Ordner verschieben',
        handleClick: () => handleMoveFolder(folder),
      })

    if (handleShareFolder)
      menuItems.push({
        icon: 'share',
        label: 'Teilen',
        handleClick: () => handleShareFolder(folder.id),
      })

    if (handleRestoreFolder)
      menuItems.push({
        icon: 'restore',
        label: 'Wiederherstellen',
        handleClick: () => handleRestoreFolder(folder.id),
      })

    if (handleDeleteFolder)
      menuItems.push({
        icon: 'trash',
        label: 'Löschen',
        handleClick: () => handleDeleteFolder(folder.id),
      })
  }

  const updateName = (): void => {
    if (!handleRenameFolder) return
    let theName = name.trim()
    if (theName === folder.name) return
    if (!theName.length) theName = 'Unbenannter Ordner'
    handleRenameFolder(folder.id, theName)
    setFocused(false)
  }

  const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>): void =>
    isOwner ? updateName() : undefined

  const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && isOwner) updateName()
  }

  const onIconHover = () => {
    if (!showShared) setShowShared(true)
  }

  const onIconOut = () => {
    if (showShared) setShowShared(false)
  }

  const renderIcon = () => {
    if (!folder.sharingEnabled) return null
    if (folder.type === 'shared' || (folder.type === 'my' && folder.sharedWith.length)) {
      return (
        <Fragment>
          {showShared && folder.type === 'shared' && (
            <SharedThrough>
              Geteilt durch:
              <br />
              {`${folder?.author?.firstName} ${folder?.author?.lastName}`}
            </SharedThrough>
          )}
          {showShared && folder.type === 'my' && (
            <SharedThrough>
              Geteilt mit:
              <br />
              {folder.sharedWith.map((sh, i) => (
                <Fragment key={i}>
                  {`${sh.firstName} ${sh.lastName}`}
                  {i < folder.sharedWith.length - 1 ? <br /> : null}
                </Fragment>
              ))}
            </SharedThrough>
          )}
          <Icon
            iconName='shared_with'
            style={{ position: 'absolute', bottom: 6, right: 6 }}
            onMouseEnter={onIconHover}
            onMouseLeave={onIconOut}
          />
        </Fragment>
      )
    }
  }

  return (
    <Fragment>
      <Container onClick={leftClick} onContextMenu={rightClick}>
        <Icon iconName='folder' />{' '}
        <TextInput
          value={name}
          onChange={e => setName(e.target.value)}
          onBlur={handleNameBlur}
          onKeyDown={handleEnterClick}
          disabled={!focused}
          ref={inputEl}
          autoFocus
        />
        {renderIcon()}
      </Container>
      <MenuPopover
        menuItems={menuItems}
        handleClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </Fragment>
  )
}

export default Folder
