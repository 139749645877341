import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

export const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column'
});

export const ActionButtonsContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4)
}))

export { Typography }