import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Nav, NavLinkContainer, Text } from './Navigation.styles'
import { INavLink, NavigationProps } from './Navigation.types'

const comparePathname = (pathname: string, path: string): boolean =>
  pathname.startsWith(path) && path.length > 1

export const NavLink: React.FC<INavLink> = ({ to, label, orientation, handleLogout }) => {
  const { pathname } = useLocation()
  const isActive = comparePathname(pathname, to)
  return (
    <NavLinkContainer
      active={isActive ? 1 : 0}
      orientation={orientation}
      onClick={handleLogout}
    >
      <Link to={to}>
        <Text active={isActive ? 1 : 0} orientation={orientation}>
          {label}
        </Text>
      </Link>
    </NavLinkContainer>
  )
}

const Navigation: React.FC<NavigationProps> = ({
  routes,
  orientation = 'horizontal',
}) => {
  return (
    <Nav
      component='nav'
      style={{ flexDirection: orientation === 'horizontal' ? 'row' : 'column' }}
    >
      {routes.map(route =>
        !route.disabled ? (
          <NavLink
            key={route.url}
            to={route.url}
            label={route.label}
            orientation={orientation}
          />
        ) : null
      )}
    </Nav>
  )
}

export default Navigation
