import { ITemplate } from 'types'
import React, { useState, useEffect, Fragment } from 'react'
import Author from './Author/Author'
import {
  ContentContainer,
  LeftContent,
  RightContent,
  RightContentWrapper,
  BottomContent,
  ContentWrapper,
  Typography,
  DummyDoc,
  DocName,
  CharacteristicsTextArea,
  AuthorContent,
  CharacteristicsText,
  CharTextWrapper,
} from './TemplatePreviewModal.styles'
import dummyPreview from 'assets/icons/dummyPreview.svg'
import StandardModal from 'components/common/Modals/StandardModal/StandardModal'
import { Loader } from 'components/common'
import PurchaseTemplateModal from '../PurchaseTemplateModal/PurchaseTemplateModal'

interface Props {
  isOpen: boolean
  template?: ITemplate | null
  isLoading: boolean
  actionBtnDisabled?: boolean
  editingDisabled?: boolean
  requirePayment?: boolean
  noQuestions?: boolean
  onClose: () => void
  onUseTemplate: (
    templateId: string,
    additionalData: { name: string; isCasus: boolean }
  ) => void
  onEditTemplate?: (templateId: string) => void
  onUpdateCharacteristics?: (templateId: string, chars: string) => void
}

const TemplatePreviewModal: React.FC<Props> = ({
  isOpen,
  template,
  actionBtnDisabled,
  editingDisabled,
  isLoading,
  requirePayment,
  noQuestions,
  onUseTemplate,
  onEditTemplate,
  onClose,
  onUpdateCharacteristics,
}) => {
  const [chars, setChars] = useState(template?.characteristics ?? '')
  const [show, setShow] = useState(false)

  const handleSecondaryClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (onEditTemplate && template) onEditTemplate(template.id)
  }

  const handlePrimaryClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (requirePayment) return setShow(true)
    if (!actionBtnDisabled && template)
      onUseTemplate(template.id, { name: template.name, isCasus: template.isCasus })
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    if (onUpdateCharacteristics && chars !== template?.characteristics && template)
      onUpdateCharacteristics(template.id, chars)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.target
    setChars(value)
  }

  useEffect(() => {
    if (template) setChars(template.characteristics)
  }, [template])

  const charDisabled = !onUpdateCharacteristics || editingDisabled
  return (
    <StandardModal
      handleClose={onClose}
      open={isOpen}
      title={template?.name ?? ''}
      handlePrimaryClick={handlePrimaryClick}
      handleSecondaryClick={editingDisabled ? undefined : handleSecondaryClick}
      primaryButtonText={
        requirePayment
          ? 'Vorlage kaufen'
          : actionBtnDisabled && !isLoading && noQuestions
            ? 'Keine Fragen'
            : 'Verwenden'
      }
      secondaryButtonText={editingDisabled ? '' : 'Bearbeiten'}
      primaryButtonDisabled={actionBtnDisabled || isLoading}
      secondaryButtonDisabled={isLoading}
    >
      <ContentWrapper>
        <ContentContainer>
          {!isLoading && template ? (
            <Fragment>
              <LeftContent>
                {/* <Document title={document.name} /> */}
                <DummyDoc>
                  <img width='100%' src={dummyPreview} alt='' />
                  <DocName>{template.name}</DocName>
                </DummyDoc>
                <BottomContent>
                  <AuthorContent>
                    <Typography variant='h5'>Vorlagenautor/in</Typography>
                    <Author author={template.displayAuthor!} />
                  </AuthorContent>
                </BottomContent>
              </LeftContent>
              <RightContent>
                <RightContentWrapper>
                  <Typography variant='h5'>Merkmale der Vorlage</Typography>
                  {!charDisabled ? (
                    <CharacteristicsTextArea
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        editingDisabled && !chars
                          ? 'Dieser Vertrag hat keine Merkmale'
                          : chars
                      }
                      disabled={charDisabled}
                      placeholder='Typbeschreibung der Vorlage'
                      spellCheck={false}
                    />
                  ) : (
                    <CharTextWrapper>
                      <CharacteristicsText>
                        {(chars?.split(/\r?\n/) ?? []).map((p, i, arr) => (
                          <Fragment key={i}>
                            {p}
                            {i < arr.length - 1 ? <br /> : null}
                          </Fragment>
                        ))}
                      </CharacteristicsText>
                    </CharTextWrapper>
                  )}
                </RightContentWrapper>
              </RightContent>
            </Fragment>
          ) : (
            <Loader style={{ padding: 0, height: 500 }} />
          )}
        </ContentContainer>
      </ContentWrapper>
      {requirePayment && show && template && (
        <PurchaseTemplateModal
          open={!!requirePayment && show}
          templateId={template?.id}
          handleClose={() => setShow(false)}
        />
      )}
    </StandardModal>
  )
}

export default TemplatePreviewModal
