import { Box, styled, Theme } from '@material-ui/core'

export const Container = styled(Box)(({ theme, on }: WithOn) => ({
  height: 32,
  width: 64,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: on ? 'rgba(102,204,134,.15)' : theme.colorz.secondary[100],
  flexShrink: 0,
  cursor: 'pointer',
  transition: 'all .4s',
}))

interface WithOn {
  on: number
  theme: Theme
}

export const Square = styled(Box)(({ theme, on }: WithOn) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: on ? theme.colorz.status.success : theme.colorz.secondary[50],
  flexShrink: 0,
  borderRadius: 3,
  width: 26,
  height: 26,
  position: 'absolute',
  left: on ? 35 : 3,
  transition: 'all .4s',
}))
