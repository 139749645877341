import { UiState, UiAction } from './ui.types'
import * as uiTypes from './ui.constants'

const initialState: UiState = {
  showUiElement: [],
}

export default (state: UiState = initialState, action: UiAction): UiState => {
  switch (action.type) {
    case uiTypes.OPEN_UI_ELEMENT:
      return {
        ...state,
        showUiElement: [...new Set([...state.showUiElement, action.payload])],
      }
    case uiTypes.CLOSE_UI_ELEMENT:
      return {
        ...state,
        showUiElement: state.showUiElement.filter(uiEl => action.payload !== uiEl),
      }
    case uiTypes.CLOSE_ALL_UI_ELEMENTS:
      return initialState
    default:
      return state
  }
}