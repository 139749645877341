import { Question } from 'types'
import { findSubquestionById } from './findSubquestionById'

export const deepFindQuestionByIds = (
  questions: Question[],
  theQuestionId: string,
  theQuestionParentId: string
): Question | undefined => {
  if (!theQuestionParentId)
    return questions.find(questionItem => questionItem.id === theQuestionId)
  const ids = [theQuestionId, ...theQuestionParentId.split('--')]
  let _theId = ids.pop()
  let _theQ = questions.find(questionItem => questionItem.id === _theId)

  while (ids.length) {
    _theId = ids.pop()
    if (_theQ && _theId && _theQ.options) {
      _theQ = findSubquestionById(_theQ, _theId)
    }
  }

  return _theQ
}

