import React, { Fragment, useState, useCallback } from 'react'
import { Container, Content, SelectWrapper } from '../../TemplateEdit.styles'
import { SelectGroup } from 'components/common'

import DocumentViewContainer from '../EditorView/EditorView.container'

import TextSelectionContainer from './TextSelection/TextSelection'
import CreateQuestion from './CreateQuestion/CreateQuestion.container'
import LabelAndDescriptionContainer from './LabelAndDescription/LabelAndDescription.container'
import QuestionTypes from './QuestionTypes/QuestionTypes.container'
import QuestionsOverview from './QuestionsOverview/QuestionsOverview.container'
import SubQuestionsContainer from './Subquestions/Subquestions.container'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { handleTCDisableState } from '../../services'

const QuestionCreationContainer: React.FC = () => {
  const { set, question, questionStep } = useTemplateCreation()

  const { selectOptions, disabledSteps } = handleTCDisableState(question!)
  const [isDisabled, setIsDisabled] = useState(false)

  const onStepSelect = (e: React.ChangeEvent<{ value: number }>): void => {
    const { value } = e.target
    set({ questionStep: value })
  }

  const setDisabled = useCallback((val: boolean) => {
    setIsDisabled(val)
  }, [])

  const renderContent = () => {
    switch (questionStep) {
      case 0:
        return <QuestionsOverview />
      case 1:
        return <CreateQuestion />
      case 2:
        return <QuestionTypes />
      case 3:
        return (
          <TextSelectionContainer setIsDisabled={setDisabled} nextDisabled={disabledSteps.description || isDisabled} />
        )
      case 4:
        return <LabelAndDescriptionContainer />
      case 5:
        return <SubQuestionsContainer />
      default:
        return null
    }
  }

  return (
    <Fragment>
      <Container>
        <Content>
          {questionStep > 0 && (
            <SelectWrapper>
              <SelectGroup
                handleChange={onStepSelect}
                name="selectedQuestion"
                value={selectOptions[questionStep - 1].value}
                options={selectOptions}
              />
            </SelectWrapper>
          )}
          {renderContent()}
        </Content>
      </Container>
      <DocumentViewContainer />
    </Fragment>
  )
}

export default QuestionCreationContainer
