import { ProfileInfoClientProps } from '../../components/ProfileInfo.types'
import React, { useCallback } from 'react'
import {
  Form,
  FormHeader,
  FormBody,
  FormFooter,
  Heading,
  FieldsContainer,
  FormTitle,
  FooterText,
  Modal,
  modalStyle,
} from '../../components/ProfileInfo.styles'
import ProfileInfoClientStepOne from './ProfileInfoClientStepOne'
import ProfileInfoClientStepTwo from './ProfileInfoClientStepTwo'
import ActionButtons from '../../components/ActionButtons'
import StatusSteps from '../../components/StatusSteps/StatusSteps'
import SignUpProfileImageSetup from '../../components/SignUpProfileImageSetup'
import * as content from './content'

const ProfileInfoClient: React.FC<ProfileInfoClientProps> = ({
  step,
  values,
  isOpen,
  isLoading,
  imageFile,
  userType,
  editorRef,
  editingDisabled,
  handleChange,
  handleSelectChange,
  handleForwardClick,
  handleBackClick,
  setImageFile,
}) => {
  const { firstName, lastName, jobPosition, twoFaEnabled } = values
  const stepTwoValues = { firstName, lastName, jobPosition, twoFaEnabled }

  const checkStepOneIsCompleted = useCallback((): number => {
    const { name, employeeCount, field } = values.companyInfo
    return name && employeeCount && field ? 1 : 0
  }, [values.companyInfo])

  const checkStepTwoIsCompleted = useCallback((): number => {
    return firstName && lastName && jobPosition ? 1 : 0
  }, [firstName, lastName, jobPosition])

  const renderContent = (): React.ReactNode | null => {
    switch (step) {
      case 0:
        return (
          <ProfileInfoClientStepOne
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
            values={values.companyInfo}
            userType={userType}
            editingDisabled={editingDisabled}
          />
        )
      case 1:
        return (
          <ProfileInfoClientStepTwo
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
            values={stepTwoValues}
          />
        )
      case 2:
        return (
          <SignUpProfileImageSetup
            imageFile={imageFile}
            setImageFile={setImageFile}
            firstName={firstName}
            lastName={lastName}
            handleForwardClick={handleForwardClick}
            isLoading={isLoading}
            editorRef={editorRef}
          />
        )
      default:
        return null
    }
  }

  const statusBarSteps = [
    {
      name: '1. Über Ihr Unternehmen',
      completed: checkStepOneIsCompleted(),
    },
    {
      name: '2. Über Sie',
      completed: checkStepTwoIsCompleted(),
    },
  ]

  let theStep = step
  if (step === 2) theStep = 1
  if (step > 2) theStep = step - 1

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    return
  }

  return (
    <Modal open={isOpen} style={modalStyle}>
      <Form onSubmit={handleSubmit}>
        <FormHeader>
          <FormTitle>{content.formTitle}</FormTitle>
          <StatusSteps steps={statusBarSteps} step={theStep} />
        </FormHeader>
        <FormBody>
          <Heading variant='h4'>{content.headings[step]}</Heading>
          <FieldsContainer>{renderContent()}</FieldsContainer>
          <FormFooter>
            <FooterText variant='body2'>{content.footerText}</FooterText>
            <ActionButtons
              handleBackClick={handleBackClick}
              handleForwardClick={handleForwardClick}
              step={step}
              isLoading={isLoading}
              nextDisabled={!statusBarSteps[theStep].completed}
            />
          </FormFooter>
        </FormBody>
      </Form>
    </Modal>
  )
}

export default ProfileInfoClient
