import React, { useState, useCallback, useRef, useEffect } from 'react'
import ProfileInfoLawyer from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoLawyer/ProfileInfoLawyer.component'
import AvatarEditor from 'react-avatar-editor'
import { useMasterData } from 'queries/app/useMasterdata'
import { useUser } from 'queries/user/useUser'
import { useUpdateUser } from 'queries/user/useUpdateUser'
import { IUpdateUserProfileApiRequest } from 'api/user.api'
import { objectHasPropsWithValue } from 'components/user/services/objectHasPropsWithValue'

const initialState = {
  firstName: '',
  lastName: '',
  twoFaEnabled: false,
  chancellery: '',
  website: '',
  bankAccountOwnerName: '',
}

export type SignupSettingsLawyerState = typeof initialState

const ProfileInfoContainer: React.FC = () => {
  const { mutate, isLoading } = useUpdateUser()

  const user = useUser()!
  const [showModal, setShowModal] = useState(false)

  const signUpWithFinancials = useMasterData()?.lawyer.registration_financials_required

  const [step, setStep] = useState(0)

  const [state, setState] = useState<SignupSettingsLawyerState>({
    ...initialState,
    chancellery: user.companyInfo.name,
    website: user.companyInfo.website,
  })

  const [canva, setCanva] = useState<AvatarEditor | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const editorRef = useRef<AvatarEditor>(null)

  useEffect(() => {
    if (step === 2 && imageFile) {
      setCanva(editorRef.current)
    }

    if (step === 2 && !imageFile) {
      setCanva(null)
    }
  }, [step, imageFile])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target
    if (!name) return
    setState(prev => ({ ...prev, [name]: value }))
  }

  const checkIfUserHasPersonalData = useCallback((): boolean => {
    if (!user) return false
    let obj: any = { ...user, ...user.companyInfo }
    const propsToCheck = ['firstName', 'lastName', 'name']
    const userHasPersonalData = objectHasPropsWithValue(obj, ...propsToCheck)
    return userHasPersonalData
  }, [user])

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>): void =>
    setStep(prev => prev - 1)

  const handleForwardClick = (sm?: string) => {
    if (sm === 'skip') {
      setCanva(null)
      setImageFile(null)
    }
    const { firstName, lastName, chancellery, website, twoFaEnabled } = state

    let theData: IUpdateUserProfileApiRequest = {
      firstName,
      lastName,
      twoFaEnabled,
      companyInfo: { ...user.companyInfo, name: chancellery, website },
    }

    const theStep = signUpWithFinancials ? 3 : 2
    if (step < theStep) setStep(prev => prev + 1)
    if (canva) {
      const canvasScaled = canva.getImageScaledToCanvas()
      const base64Data = canvasScaled.toDataURL('png', 0.5)
      const base64String = base64Data.split('base64,')[1].trim()
      theData.imageData = base64String
    }

    if (step === 2) {
      if (signUpWithFinancials) setShowModal(true)
      mutate(theData)
    }
  }

  return (
    <ProfileInfoLawyer
      isLoading={isLoading}
      imageFile={imageFile}
      step={step}
      values={state}
      editorRef={editorRef}
      stripeLoading={false}
      signUpWithFinancials={Boolean(signUpWithFinancials)}
      userType={user.type}
      handleChange={handleChange}
      handleBackClick={handleBackClick}
      handleForwardClick={handleForwardClick}
      setImageFile={setImageFile}
      handleSubmit={() => {}}
      isOpen={!checkIfUserHasPersonalData() || showModal}
    />
  )
}

export default ProfileInfoContainer
