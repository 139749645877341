import React from 'react'
import { dataStructureType } from 'utilities/propTypes'
// import { generateOffsets } from 'utilities'
// import { debounceFunction } from 'utilities/helpers'
// import { isSelectionWithinNode, parseDataStructure } from './helpers'

import { Section } from './components'

const classes = {
  wrapper: 'Wizard-Editor-wrapper',
  section: 'Wizard-Editor-section',
  page: 'Wizard-Editor-page',
  content: 'Wizard-Editor-content',
  selectionHandlerDiv: 'Wizard-Editor-selectionHandlerDiv',
  selectionHandlerButton: 'Wizard-Editor-selectionHandlerButton',
}

// const selectionKeyboardKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'End', 'Home', 'PageUp', 'PageDow']

export const Editor = React.memo(props => {
  // const [node, setNode] = useState(null)
  // const [range, setRange] = useState(null)
  // const ref = useCallback(node => {
  //   if (node) setNode(node)
  // }, [])

  const { dataStructure } = props
  const { documentSettings: sections } = dataStructure
  // const sections = useMemo(() => documentSettings.map(section => ({ ...section, id: uuid() })), [documentSettings])

  // console.log('FROM THE STATE: ', range)

  // const createMarkerHandler = useCallback(type => {
  //   console.log(type)
  // }, [])

  // const removeSelectionHandler = useCallback(() => {
  //   document.getSelection().removeAllRanges()
  //   setRange(null)
  // }, [])

  // const selectionHandler = useCallback(
  //   (event, type) => {
  //     // console.log('HANDLER CALLED!')
  //     if (type === 'keyboard' && !selectionKeyboardKeys.includes(event.key)) {
  //       if (event.key === 'Escape') removeSelectionHandler()
  //       return
  //     }
  //     const documentSelection = document.getSelection()
  //     const selectionWithingSection = isSelectionWithinNode(documentSelection, node)
  //     // console.log('WITHIN SECTION: ', selectionWithingSection)
  //     if (!selectionWithingSection || documentSelection.isCollapsed) removeSelectionHandler()
  //     else {
  //       const offsets = generateOffsets(documentSelection)
  //       // console.log('SETTING THE STATE!')
  //       setRange(prev => {
  //         // console.log('::::::::::::::::::::::::::::::')
  //         // console.log('PREV: ', prev)
  //         // console.log('OFFSETS: ', offsets)
  //         if (prev && offsets) {
  //           const areSame = Object.entries(prev).every(([key, value]) => offsets[key] === value)
  //           console.log('ARE SAME: ', areSame)
  //           return areSame ? prev : offsets
  //         }
  //         return offsets
  //       })
  //     }
  //   },
  //   [node, removeSelectionHandler]
  // )

  // useEffect(() => {
  //   const mouseHandler = debounceFunction(event => selectionHandler(event, 'mouse'))
  //   const keyboardHandler = debounceFunction(event => selectionHandler(event, 'keyboard'))
  //   document.addEventListener('mouseup', mouseHandler)
  //   document.addEventListener('keydown', keyboardHandler)
  //   return () => {
  //     document.removeEventListener('mouseup', mouseHandler)
  //     document.removeEventListener('keydown', keyboardHandler)
  //   }
  // }, [selectionHandler])

  return (
    <section
      // ref={ref}
      className={classes.wrapper}
    >
      {sections.map(section => (
        <Section
          key={section.id}
          settings={section}
          dataStructure={dataStructure} // divide into sections when implemented
        />
      ))}
      {/* {range ? (
        <div className={classes.selectionHandlerDiv}>
          <button
            className={classes.selectionHandlerButton}
            type="button"
            tabIndex={-1}
            onClick={() => createMarkerHandler('CHOICE!')}
          >
            Choice
          </button>
          <button
            className={classes.selectionHandlerButton}
            type="button"
            tabIndex={-1}
            onClick={() => createMarkerHandler('REPLACEMENT!')}
          >
            Replacement
          </button>
          <button
            className={[classes.selectionHandlerButton, 'remove'].join(' ')}
            type="button"
            tabIndex={-1}
            onClick={removeSelectionHandler}
          >
            <img src={close} alt="Close!" />
          </button>
        </div>
      ) : null} */}
    </section>
  )
})

Editor.displayName = 'Wizard-Editor'

Editor.propTypes = {
  dataStructure: dataStructureType(),
}

export default Editor
