import { CheckItemProps, CheckListProps } from './TipsChecklist.types'
import React from 'react'
import {
  ChecklistContainer,
  CheckItemContainer,
  ChecklistItemText,
} from './TipsChecklist.styles'
import { Icon } from 'components/common'

const CheckItem: React.FC<CheckItemProps> = ({ text, icon }) => (
  <CheckItemContainer>
    <Icon
      iconName={icon.name}
      fill={icon.fill}
      style={{ marginRight: 12, height: 22, width: 'auto' }}
    />
    <ChecklistItemText>{text}</ChecklistItemText>
  </CheckItemContainer>
)

const CheckList: React.FC<CheckListProps> = ({ items, icon }) => {
  return (
    <ChecklistContainer>
      {items.map((text, i) => (
        <CheckItem key={i} text={text} icon={icon} />
      ))}
    </ChecklistContainer>
  )
}

export default CheckList
