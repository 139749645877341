import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { useCreateFolder } from 'queries/folders'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import CreateFolderModalComponent from 'components/files/FileAndFolderActions/createFolder/CreateFolder.component'

interface Props {
  where: 'templates' | 'docs'
}
const CreateFolder: React.FC<Props> = ({ where }) => {
  const { replace, location } = useHistory()
  const [folderName, setFolderName] = useState('Unbenannter Ordner')
  const { currentFolder } = useCurrentFolder(where)
  const createFolder = useCreateFolder(where, currentFolder)
  const { check } = useSubscriptionCheck()

  const onClose = () => {
    setFolderName('Unbenannter Ordner')
    replace(location.pathname)
  }

  const onCreate = () => {
    if (!check({ isFree: true })) return
    let parentCategoryId = currentFolder.type === currentFolder.id ? '' : currentFolder.id
    let theName = folderName
    const folderNameExists = currentFolder.children.some(
      f => f.name.trim() === folderName
    )
    if (folderNameExists) theName = folderName + '.Kopie'
    createFolder.mutate(
      { data: { parentCategoryId, name: theName } },
      { onSuccess: onClose }
    )
  }

  return (
    <CreateFolderModalComponent
      open={true}
      folderName={folderName}
      primaryButtonText='Erstellen'
      primaryButtonDisabled={!folderName.length || createFolder.isLoading}
      handleClose={onClose}
      setFolderName={setFolderName}
      handlePrimaryClick={onCreate}
    />
  )
}

export default CreateFolder
