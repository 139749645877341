import { Box, InputBase, InputBaseProps, styled, Theme } from '@material-ui/core'
import { scrollBarStyles } from 'constants/common-styles'

interface IDocumentNameInput extends InputBaseProps {
  theme: Theme
}

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.colorz.brand.light,
  flex: 1,
  maxHeight: 'calc(100vh - 112px)',
  width: '90%',
  overflowY: 'auto',
  padding: theme.spacing(0, 7, 7, 7),
  margin: '0 auto',
  ...scrollBarStyles(theme),
}))

export const QWrapper = styled(Box)({
  display: 'flex',
  margin: '0 auto',
})

export const QDocViewContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
})

export const markTagStyles = (theme: Theme) => ({
  '& mark': {
    color: '#fff',
    backgroundColor: theme.colorz.primary.main,
    '& mark': {
      backgroundColor: theme.colorz.primary.dark,
      '& mark': {
        backgroundColor: theme.colorz.primary.dark2,
      },
    },
  },
})

export const MiniViewWrapper = styled(Box)({
  minWidth: 318,
  width: 318,
  height: 'max-content',
  position: 'relative',
  overflow: 'hidden',
})

export const MiniViewContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  backgroundColor: '#fff',
  padding: theme.spacing(2),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colorz.brand.main,
  transform: 'scale(0.425)',
  transformOrigin: 'top left',
  width: 750,
  ...markTagStyles(theme),
}))

export const HeadingContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 0),
}))

export const DocumentNameInput = styled(InputBase)(
  ({ theme, error }: IDocumentNameInput) => ({
    padding: theme.spacing(1, 0),
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: !error ? theme.colorz.secondary.main : theme.colorz.status.error,
    borderRadius: 2,
    backgroundColor: 'transparent',
    transition: 'all .2s',
    fontSize: 18,
    fontWeight: 700,
    width: 318,
    '&:focus': {
      backgroundColor: theme.colorz.brand.main,
    },
    '&::placeholder': {
      color: theme.colorz.secondary[300],
      fontSize: 18,
      fontWeight: 700,
    },
  })
)
