import React from 'react'
import { StatusBar } from 'components/features'
import { Container } from './FeaturesLayout.styles'
import { Loader } from 'components/common'
import { StatusBarProps } from './StatusBar/StatusBar.types'
import { IStep } from 'types'

interface Props extends StatusBarProps {
  onBoardingSteps?: IStep[]
  onBoardingHeading?: string
  isLoading?: boolean
}

const FeatureLayout: React.FC<Props> = ({
  children,
  onBoardingSteps,
  onBoardingHeading,
  isLoading,
  ...otherProps
}) => {
  return (
    <Container>
      <StatusBar {...otherProps} />
      {isLoading ? <Loader /> : children}
    </Container>
  )
}

export default FeatureLayout
