import { useHistory } from 'react-router'
import { analytics, StorageService } from 'utils'
import { useSubscriptionCheck } from './useSubscriptionCheck'

export const useTemplateActions = () => {
  const { push, replace, location } = useHistory()
  const { check, isBasic, isFree, isTeam, isTrial } = useSubscriptionCheck()

  const onUseTemplate = (
    templateId: string,
    additionalData: { name: string; isCasus: boolean }
  ) => {
    if (!check()) return
    replace(location.pathname)
    push(`/dokument/neu/${templateId}`)
    if (isFree) return
    const { name, isCasus } = additionalData
    let key = ''
    if (isCasus) {
      key = isTrial ? 'trial_casus_template_used' : 'subsc_casus_template_used'
    } else {
      key = isTrial ? 'subsc_template_used' : 'trial_template_used'
    }
    if (key)
      analytics.logEvent(key, {
        id: templateId,
        name,
        subscriptionPlan: isBasic ? 'basic' : isTeam ? 'team' : undefined,
      })
  }

  const onEditTemplate = (templateId: string): void => {
    if (!check()) return
    replace(location.pathname)
    push(`/vorlage/${templateId}/bearbeiten`)
    StorageService.remove('templateCreationState')
  }

  return { onUseTemplate, onEditTemplate }
}
