import {
  ANSWER_TYPE_CHECKBOX,
  ANSWER_TYPE_DATE,
  ANSWER_TYPE_NUMBER,
  ANSWER_TYPE_RADIO_LINK,
  ANSWER_TYPE_RADIO_REPLACEMENT,
  ANSWER_TYPE_TEXT_BOX,
} from 'constants/answer-types'
import {
  QUESTION_TYPE_CHECKBOX,
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_NUMBER,
  QUESTION_TYPE_RADIO_LINK,
  QUESTION_TYPE_RADIO_REPLACEMENT,
  QUESTION_TYPE_TEXT_BOX,
} from 'constants/question-types'
import { AnswerTypes, IAnswer, Question } from 'types'

export const initQuestionsToAnswers = (questions: Question[]): IAnswer[] => {
  const answers: IAnswer[] = []
  for (let _question of questions) {
    let value: string | string[] | undefined
    let type: AnswerTypes | undefined

    switch (_question.type) {
      case QUESTION_TYPE_TEXT_BOX:
        type = ANSWER_TYPE_TEXT_BOX
        value = ''
        break
      case QUESTION_TYPE_DATE:
        type = ANSWER_TYPE_DATE
        value = ''
        break
      case QUESTION_TYPE_NUMBER:
        type = ANSWER_TYPE_NUMBER
        value = ''
        break
      case QUESTION_TYPE_CHECKBOX:
        type = ANSWER_TYPE_CHECKBOX
        value = []
        break
      case QUESTION_TYPE_RADIO_REPLACEMENT:
        type = ANSWER_TYPE_RADIO_REPLACEMENT
        value = [_question.options[0].id]
        break
      case QUESTION_TYPE_RADIO_LINK:
        type = ANSWER_TYPE_RADIO_LINK
        value = [_question.options[0].id]
        break
    }

    if (type === undefined || value === 'undefined') {
        console.error('Error while generating initial answers')
        return []
    }

    const _answer: IAnswer = {
      questionId: _question.id,
      type,
      value,
    }
    
    answers.push(_answer)
  }

  return answers
}
