import { ITemplate } from "types";
import React from "react";
import { useHistory } from "react-router-dom";
import { useIsFetching, useQueryClient } from "react-query";

import { useTemplateCreation } from "pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider";
import { useTemplateActions } from "hooks/useTemplateActions";

import { SaveModal } from "components/features";
import { ContainerStep3 } from "../TemplateEdit.styles";
import { lastStepContent as content } from "pages/Authenticated/Template/constants/content";
import { StorageService } from "utils/storageService";
import { queryKeys } from "queries/queryKeys";

const LastStepContainer: React.FC = () => {
  const { set, questions, id, name } = useTemplateCreation();
  const queryClient = useQueryClient();
  const { onUseTemplate } = useTemplateActions();
  const { push } = useHistory();
  const isLoading = useIsFetching([queryKeys.TEMPLATE, id]);
  const data = queryClient.getQueryData<ITemplate>([queryKeys.TEMPLATE, id]);

  const onRedirect = () => {
    push("/vorlagen");
    StorageService.remove("templateCreationState");
  };

  return (
    <ContainerStep3>
      <SaveModal
        heading={content.heading}
        primaryBtnText={
          questions.length
            ? content.primaryButton
            : content.primaryButtonDisabled
        }
        text="Beginnen Sie jetzt damit, Ihre Vorlage zu verwenden und Dokumente zu erstellen."
        primaryButtonDisabled={questions.length === 0 || !!isLoading}
        secondaryBtnText="Zu den Vorlagen"
        secondaryButtonDisabled={!!isLoading}
        handlePrimaryClick={() =>
          onUseTemplate(id!, { name, isCasus: !!data?.isCasus })
        }
        handleSecondaryClick={onRedirect}
        onClose={() => set({ step: 2 })}
        isLoading={false}
      />
    </ContainerStep3>
  );
};

export default LastStepContainer;
