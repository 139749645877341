import React from 'react'
import { styled, Typography } from '@material-ui/core'

import { useMasterData } from 'queries/app/useMasterdata'

import LawFields from 'pages/Authenticated/LegalAdvice/views/LegalAdviceClient/components/BookLawyer/views/SelectLawField/LawFields/LawFields'
import { useBookLawyer } from '../../provider/BookLawyerProvider'

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  maxHeight: 640,
}))

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: 0.4,
  color: theme.colorz.secondary.main,
}))

const useLawFields = () => {
  const booking_urls = useMasterData()?.calendly.booking_urls
  return booking_urls
    ? [...booking_urls].sort((a, b) => (a.order < b.order ? -1 : 1))
    : []
}

const SelectLawField: React.FC = () => {
  const lawFields = useLawFields()
  const { set } = useBookLawyer()

  return (
    <Container>
      <Heading>Wählen Sie einen Themenbereich</Heading>
      <LawFields lawFields={lawFields} onFieldSelect={lawField => set({ lawField })} />
    </Container>
  )
}

export default SelectLawField
