import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
})

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 26,
  fontWeight: 500,
  letterSpacing: 0.4,
  textAlign: 'center',
  maxWidth: 600,
}))
