import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as A from 'redux/actions'
import * as Ui from 'constants/ui-elements'

export const useModal = () => {
  const dispatch = useDispatch()

  const openModal = useCallback(
    (el: Ui.TUiElement) => {
      dispatch(A.openUiElement(el))
    },
    [dispatch]
  )

  const closeModal = useCallback(
    (el?: Ui.TUiElement) => {
      dispatch(!el ? A.closeAllUiElements() : A.closeUiElement(el))
    },
    [dispatch]
  )

  return {
    openModal,
    closeModal,
  }
}
