import axios, { AxiosRequestConfig } from 'axios'

abstract class Api {
  protected BASE_URL?: string
  protected constructor(
    protected endPoint: string,
    protected versionless?: boolean
  ) {
    if (versionless)
      this.BASE_URL = process.env.REACT_APP_BASE_API_URL_VERSIONLESS
    else this.BASE_URL = process.env.REACT_APP_BASE_API_URL + endPoint
    // this.BASE_URL = 'http://192.168.1.245:5000/casus-949bf/europe-west1/api/v1' + endPoint
  }

  protected api(options?: AxiosRequestConfig) {
    const instance = axios.create({
      baseURL: this.BASE_URL,
      ...options,
    })
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        throw error
      }
    )
    instance.interceptors.request.use(async (req) => {
      return req
    })
    const token = localStorage.casusToken
    if (token)
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + token

    return instance
  }
}

export default Api
