import React from 'react'

import { Container, Heading } from './Header.styles'
import { Text } from '../common.styles'

interface Props {
  heading?: string
  descriptionText?: string
}

const Header: React.FC<Props> = ({ heading, descriptionText }) => {
  if (!heading && !descriptionText) return null
  return (
    <Container>
      {heading ? <Heading>{heading}</Heading> : null}
      {descriptionText ? (
        <Text variant='body1' align='center'>
          {descriptionText}
        </Text>
      ) : null}
    </Container>
  )
}

export default Header
