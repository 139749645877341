import { IReminder } from 'types'
import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import React, { useState, useRef } from 'react'
import moment from 'moment'
import { reminderAlertSelectorTranslator } from './services/reminderAlertSelectorTranslator'
import { MenuPopover, Icon } from 'components/common'

import { Container, Col, Text, EntityName } from './ReminderItem.styles'

import { limitLongString } from 'utils'

interface Props extends IReminder {
  onEdit: () => void
  onDelete: () => void
}

export const ReminderItem: React.FC<Props> = ({
  id,
  name,
  entity,
  deadlineTime,
  alert,
  onEdit,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  moment.locale('de')

  const menuItems: IPopupMenuItem[] = [
    {
      label: 'Bearbeiten',
      handleClick: onEdit,
      icon: 'edit',
    },
    {
      label: 'Löschen',
      handleClick: onDelete,
      icon: 'delete',
    },
  ]

  return (
    <Container ref={ref}>
      <Col>
        <Text variant='h5' colorz='main'>
          {name}
        </Text>
      </Col>
      <Col>
        <EntityName
          variant='body1'
          colorz='300'
          entityname={entity.name.length > 23 ? entity.name : undefined}
        >
          {limitLongString(entity.name, 23)}
        </EntityName>
        <Text variant='body2' colorz='200'>
          Dokument
        </Text>
      </Col>
      <Col>
        <Text variant='body1' colorz='300'>
          {moment.unix(deadlineTime._seconds).format('D. MMMM YYYY')}
        </Text>
        <Text variant='body2' colorz='200'>
          Deadline
        </Text>
      </Col>
      <Col>
        <Text variant='body1' colorz='300'>
          {`${alert.at} ${reminderAlertSelectorTranslator(alert)} vorher`}
        </Text>
        <Text variant='body2' colorz='200'>
          Erinnerung
        </Text>
      </Col>
      <MenuPopover
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        menuItems={menuItems}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
      <Icon
        iconName='more'
        style={{ position: 'absolute', top: 8, right: 8, cursor: 'pointer' }}
        onClick={() => setAnchorEl(ref.current)}
      />
    </Container>
  )
}
