import { User } from 'types'
import React, { useEffect, useState } from 'react'
import { Redirect, Switch, useHistory } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import * as Sentry from '@sentry/react'

import { useWatchSubscriptions } from 'components/subscriptions/hooks/useWatchSubscriptions'
import { useUser } from 'queries/user/useUser'
import { useMasterData } from 'queries/app/useMasterdata'
import { useLogout } from 'queries/auth/useLogout'
import { useModalComponents } from 'hooks/useModalComponents'

import Dashboard from './Dashboard/Dashboard'
import Documents from './Documents/Documents.page'
import DocumentFile from './DocumentFile/DocumentFile.page'
import Templates from './Templates/Templates.page'
import Constulting from './LegalAdvice/LegalAdvice.page'
import AccountSettings from './AccountSettings/AccountSettings.page'
import Team from './Team/TeamPage'
import Template from './Template/Template.page'
import Logout from './Logout/LogoutPage'
import ProfileInfoContainer from 'components/user/AfterSignUpProfileSetup/AfterSignUpProfileSetup'
import SubscriptionPlans from './SubscriptionPlans/SubscriptionPlans.page'

import PrivateRoute from 'hoc/PrivateRoute'
import { Redirects } from 'components/helpers/redirects/Redirects'
import { Container } from './Authenticated.page.styles'
import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'
import { isDev } from 'config'
import AuthApi from 'api/auth.api'
import { auth } from 'utils'
import { SetReminderModal } from 'components/files/FileAndFolderActions/reminders/SetReminderModal.container'

const useData = () => {
  const masterdata = useMasterData()
  const user = useUser()
  const teamClientAllowed =
    Boolean(masterdata?.client.team_members_enabled) &&
    user?.type === USER_TYPE_CLIENT_ADMIN
  const teamLawyerAllowed =
    Boolean(masterdata?.lawyer.team_members_enabled) &&
    user?.type === USER_TYPE_LAWYER_ADMIN

  return {
    user,
    teamPageAllowed: teamClientAllowed || teamLawyerAllowed,
    consultingPageAllowed: Boolean(masterdata?.legal_stuff.advice_tab_shown),
    dataFetched: !!user && !!masterdata,
  }
}

const useAuthRefresh = (user?: User | null) => {
  const [intervalId, setIntervalId] = useState<null | NodeJS.Timeout>(null)

  useEffect(() => {
    if (user && !intervalId) {
      const interval = setInterval(async () => {
        const firebaseUser = auth.currentUser
        if (firebaseUser) {
          await AuthApi.authenticate(firebaseUser)
        }
      }, 1000 * 60 * 45)
      setIntervalId(interval)
    }
  }, [user, intervalId])
}

const Main: React.FC = () => {
  const { location } = useHistory()
  const { consultingPageAllowed, teamPageAllowed } = useData()
  const user = useUser()
  const logout = useLogout()
  useAuthRefresh(user)
  const modalComponents = useModalComponents()

  useWatchSubscriptions()

  useEffect(() => {
    if (user && !isDev) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
      })
    }
  }, [user])

  if (!user) return <Redirect to='/auth/login' />

  // @ts-ignore
  return (
    <IdleTimer timeout={1000 * 60 * 60 * 3} onIdle={() => logout()}>
      <Container>
        <Switch location={location}>
          <PrivateRoute
            path='/redirect'
            component={Redirects}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            exact
            path='/'
            component={Dashboard}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            path='/aktenschrank'
            component={Documents}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            path='/dokument'
            component={DocumentFile}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            path='/vorlage'
            component={Template}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            path='/vorlagen'
            component={Templates}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            path='/rechtsberatung'
            component={Constulting}
            isAuthenticated={Boolean(user)}
            isAllowed={consultingPageAllowed}
          />
          <PrivateRoute
            path='/einstellungen'
            component={AccountSettings}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            path='/team'
            component={Team}
            isAuthenticated={Boolean(user)}
            isAllowed={teamPageAllowed}
          />
          <PrivateRoute
            path='/abo'
            component={SubscriptionPlans}
            isAuthenticated={Boolean(user)}
          />
          <PrivateRoute
            exact
            path='/logout'
            component={Logout}
            isAuthenticated={Boolean(user)}
          />
          <Redirect to='/aktenschrank' />
        </Switch>
        <ProfileInfoContainer />
        {modalComponents.map((Component, i) => (
          <Component key={i} />
        ))}
      </Container>
      <SetReminderModal />
    </IdleTimer>
  )
}

export default Main
