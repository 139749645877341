import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setRootFolderDocuments, setRootFolderTemplates } from 'redux/actions'
import { RootFolder, State } from 'types'

export const useRootFolder = (where: 'templates' | 'docs') => {
  const dispatch = useDispatch()

  const rootFolder = useSelector((state: State) => {
    return state[where].rootFolder
  })

  const setRootFolder = useCallback(
    (rootFolder: RootFolder) => {
      const fn = where === 'templates' ? setRootFolderTemplates : setRootFolderDocuments
      dispatch(fn(rootFolder))
    },
    [dispatch, where]
  )

  return { rootFolder, setRootFolder }
}
