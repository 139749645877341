import { StripeCardProps } from '../../stripe.types2'
import React, { Fragment } from 'react'

import ActionButtons from '../ActionButtons/ActionButtons'
import { TextInputGroup } from 'components/common'

import {
  StripeWrapper,
  CardNumberEl,
  CardExpiryEl,
  CvcEl,
  stripeElOptionStyle,
} from './Card.styles'
import { Text } from '../common.styles'

import { secondary } from 'config/themes/colors'

const StripeCard: React.FC<StripeCardProps> = ({
  stripe,
  name,
  hasPaymentMethods,
  charging,
  error,
  amount,
  children,
  handleNameChange,
  handleBackClick,
  handleSubmit,
}) => {
  return (
    <Fragment>
      <Text variant='body1'>Kreditkartenangaben</Text>
      <CardNumberEl options={{ style: stripeElOptionStyle }} />
      <StripeWrapper>
        <CardExpiryEl options={{ style: stripeElOptionStyle }} />
        <CvcEl options={{ style: stripeElOptionStyle }} />
      </StripeWrapper>
      <Text variant='body1'>Karteninhaber/in</Text>
      <TextInputGroup
        value={name}
        name='cardName'
        onChange={handleNameChange}
        placeholder=''
        InputGroupContainerProps={{
          style: { backgroundColor: '#fff', marginBottom: 32 },
        }}
        style={{
          border: '1px solid ' + secondary[100],
          color: '#000',
        }}
        error={error}
      />
      {children}
      <ActionButtons
        disabled={!stripe || charging}
        hasPaymentMethods={hasPaymentMethods}
        charging={charging}
        amount={amount}
        handleBackClick={handleBackClick}
        handleSubmit={handleSubmit}
      />
    </Fragment>
  )
}

export default StripeCard
