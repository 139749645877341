import moment from 'moment'

export const calcDateDifference = (
  unitOfTime: moment.unitOfTime.Diff,
  dates: { date1: Date | moment.Moment; date2: Date | moment.Moment }
): number => {
  const { date1, date2 } = dates
  const a = moment(date1)
  const b = moment(date2)
  return a.diff(b, unitOfTime)
}
