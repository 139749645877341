import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import ViewListIcon from '@material-ui/icons/ViewList'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import {
  Download,
  Edit,
  Settings,
  Trash,
} from 'components/common/Icons/Icons.styles'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useUser } from 'queries/user/useUser'
import { useDocumentsUpdate, useLoadDocuments } from 'queries/documents'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'

import Container from 'components/files/components/Container/Container'
import DocumentWithMenu from 'components/files/File/FileWithMenu'
import LoadMoreButton from 'components/files/components/LoadMoreButton/LoadMoreButton'
import NoDocs from 'components/common/NoItemsAction/NoItemsAction'
import doc_png from 'assets/illustrations/aktenschrank.svg'
import TrashEmpty from 'components/files/components/TrashEmpty/TrashEmpty'
import {
  POPUP_CONFIRM_DELETE_FILE,
  POPUP_CONFIRM_TRASH_FILE,
} from 'constants/confirmation-popups'
import { isEditorIsOwner } from 'utils'
import moment from 'moment'
import dummyPreview from '../../../../../assets/icons/dummyPreview.svg'
import loaderAnimation from '../../../../../assets/icons/loader.svg'

import './style.scss'
import { useDocumentsDuplicate } from 'queries/documents/useDocumentDuplicate'
import TableContainer from 'components/files/components/TableContainer/TableContainer'
import { MenuPopover } from 'components/common'

const noDocsProps = {
  text: 'Es sind noch keine Dokumente vorhanden',
  actionText: 'Neues Dokument erstellen',
  heading: 'Aktenschrank',
  actionBtn: 'Neues Dokument',
  image: doc_png,
}

const DocumentsList: React.FC = () => {
  const [view, setView] = useState('card')
  // const queryClient = useQueryClient()
  const { currentFolder } = useCurrentFolder('docs')
  const { type, children } = currentFolder

  const { fetchNextPage, data, hasNextPage, isLoading, isFetchingNextPage } =
    useLoadDocuments(currentFolder, { enabled: false })
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState<
    IPopupMenuItem[] | []
  >([])
  const update = useDocumentsUpdate()
  const duplicate = useDocumentsDuplicate()
  const user = useUser()!
  // const superadmins = useMasterData()?.superadmins ?? []
  const { onEdit, onOpen, onDownload } = useDocumentFileActions()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { push, location } = useHistory()
  const { check } = useSubscriptionCheck()
  const [renameId, setRenameId] = useState<null | string>(null)

  // const isSuperadmin = superadmins.includes(user.email)

  const documentFiles = data?.pages.flat(1) ?? []

  const deleteDocument = useCallback(
    (id: string) => {
      const status = type === 'trashed' ? 'deleted' : 'trashed'
      const POPUP =
        type === 'trashed'
          ? POPUP_CONFIRM_DELETE_FILE
          : POPUP_CONFIRM_TRASH_FILE

      popup({
        ...POPUP,
        onConfirm: () => {
          setIsLoading(true)
          update.mutate(
            { id: id, data: { status } },
            { onSuccess: close, onSettled: () => setIsLoading(false) }
          )
        },
        onCancel: close,
      })
    },
    [close, popup, setIsLoading, type, update]
  )

  const getMenuItems = useCallback(
    (doc: any) => {
      const { id, authorId, sharedWith } = doc

      const { isOwner, isEditor } = isEditorIsOwner(
        user?.id,
        authorId,
        sharedWith
      )

      const menuItems: IPopupMenuItem[] = []

      if (type !== 'trashed') {
        menuItems.push({
          icon: 'preview',
          label: 'Vorschau',
          handleClick: () => onOpen(id),
        })
      }

      if (type !== 'trashed' && (isEditor || isOwner) && doc.type !== 'pdf') {
        menuItems.push({
          icon: 'edit',
          label: 'Bearbeiten',
          handleClick: () => {
            if (check()) onEdit(doc)
          },
        })
      }

      menuItems.push({
        icon: 'reminder',
        label: 'Erinnerung',
        handleClick: () => {
          if (check({ isFree: true }))
            push(`${location.pathname}?entity=${id}&action=mahnung`)
        },
      })

      if (type === 'my' && isOwner) {
        menuItems.push({
          icon: 'move',
          label: 'Dokument verschieben',
          handleClick: () => {
            if (check({ isFree: true }))
              push(`${location.pathname}?file=${id}&action=move`)
          },
        })

        if (type === 'my') {
          menuItems.push({
            icon: 'share',
            label: 'Teilen',
            handleClick: () => {
              console.info('sharing doc')
              if (check({ isFree: true, isBasic: true, isStartup: true }))
                push(`${location.pathname}?file=${id}&action=share`)
            },
          })
        }
      }

      menuItems.push({
        icon: 'download',
        label: 'Herunterladen',
        handleClick: () => { },
        subItems: [
          {
            icon: 'docx',
            label: 'DOCX',
            handleClick: () => onDownload(id, 'docx'),
          },
        ],
      })

      if (type !== 'trashed' && isEditor && view === 'card')
        menuItems.push({
          icon: 'rename',
          label: 'Umbenennen',
          handleClick: () => {
            if (check()) setRenameId(id)
          },
        })

      menuItems.push({
        icon: 'duplicate',
        label: 'Duplikat',
        handleClick: () => {
          if (check()) duplicate.mutate(doc)
        },
      })

      if (type === 'trashed' && isOwner) {
        menuItems.push({
          icon: 'restore',
          label: 'Wiederherstellen',
          handleClick: () => {
            if (check()) update.mutate({ id: id, data: { status: 'new' } })
          },
        })
      }

      if (type !== 'shared' && isOwner)
        menuItems.push({
          icon: 'trash',
          label: 'Löschen',
          handleClick: () => deleteDocument(id),
        })

      return menuItems
    },
    [
      deleteDocument,
      update,
      location.pathname,
      check,
      duplicate,
      onDownload,
      onEdit,
      onOpen,
      push,
      type,
      user,
      view,
    ]
  )

  const getTypeIcon = useCallback((doc: any) => {
    if (doc.isLoading) {
      return (
        <div>
          <img src={loaderAnimation} alt="" className="documentRow-fileType" />
        </div>
      )
    } else {
      return (
        <div>
          <img src={dummyPreview} alt="" className="documentRow-fileType" />
        </div>
      )
    }
  }, [])

  const generateActions = useCallback(
    (doc: any) => {
      const leftClick = (e: any) => {
        if (!isLoading) {
          const thisDocMenuItem = getMenuItems(doc)
          setSelectedMenuItem(thisDocMenuItem)
          setAnchorEl(e.currentTarget)
        }
      }
      return (
        <div key={doc.id} className="documentRow-actionsWrapper">
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={() => onDownload(doc.id, 'docx')}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') onDownload(doc.id, 'docx')
            }}
          >
            <Download />
          </button>
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={() => onEdit(doc)}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') onEdit(doc)
            }}
          >
            <Edit />
          </button>
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={() => deleteDocument(doc.id)}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') deleteDocument(doc.id)
            }}
          >
            <Trash />
          </button>
          <button
            type="button"
            className="documentRow-actionButton"
            onClick={(event: React.MouseEvent<HTMLElement>) => leftClick(event)}
            onKeyDown={(event) => {
              if (event.key === 'ENTER') leftClick(event)
            }}
          >
            <Settings />
          </button>
        </div>
      )
    },
    [deleteDocument, onEdit, onDownload, getMenuItems, isLoading]
  )

  const render = useMemo(() => {
    switch (view) {
      case 'card':
        return (
          <Container>
            {documentFiles?.map((docItem) => {
              const { id, authorId, sharedWith } = docItem

              const { isOwner, isEditor } = isEditorIsOwner(
                user?.id,
                authorId,
                sharedWith
              )

              const isShared =
                docItem.sharedWith?.some((sh) => sh.id === user?.id) ||
                (isOwner && !!docItem.sharedWith.length)

              const updateName = (docId: string = id, newName: string) => {
                setRenameId(null)
                if (newName !== docItem.name)
                  update.mutate({ id: docId, data: { name: newName } })
              }

              const menuItems: IPopupMenuItem[] = []

              if (type !== 'trashed') {
                menuItems.push({
                  icon: 'preview',
                  label: 'Vorschau',
                  handleClick: () => onOpen(id),
                })
              }

              if (
                type !== 'trashed' &&
                (isEditor || isOwner) &&
                docItem.type !== 'pdf'
              ) {
                menuItems.push({
                  icon: 'edit',
                  label: 'Bearbeiten',
                  handleClick: () => {
                    if (check()) onEdit(docItem)
                  },
                })
              }

              menuItems.push({
                icon: 'reminder',
                label: 'Erinnerung',
                handleClick: () => {
                  if (check({ isFree: true }))
                    push(`${location.pathname}?entity=${id}&action=mahnung`)
                },
              })

              if (type === 'my' && isOwner) {
                menuItems.push({
                  icon: 'move',
                  label: 'Dokument verschieben',
                  handleClick: () => {
                    if (check({ isFree: true }))
                      push(`${location.pathname}?file=${id}&action=move`)
                  },
                })

                if (type === 'my') {
                  menuItems.push({
                    icon: 'share',
                    label: 'Teilen',
                    handleClick: () => {
                      console.info('sharing doc')
                      if (
                        check({ isFree: true, isBasic: true, isStartup: true })
                      )
                        push(`${location.pathname}?file=${id}&action=share`)
                    },
                  })
                }
              }

              menuItems.push({
                icon: 'download',
                label: 'Herunterladen',
                handleClick: () => { },
                subItems: [
                  {
                    icon: 'docx',
                    label: 'DOCX',
                    handleClick: () => onDownload(id, 'docx'),
                  },
                ],
              })

              if (type !== 'trashed' && isEditor)
                menuItems.push({
                  icon: 'rename',
                  label: 'Umbenennen',
                  handleClick: () => {
                    if (check()) setRenameId(id)
                  },
                })

              menuItems.push({
                icon: 'duplicate',
                label: 'Duplikat',
                handleClick: () => {
                  if (check()) duplicate.mutate(docItem)
                },
              })

              if (type === 'trashed' && isOwner) {
                menuItems.push({
                  icon: 'restore',
                  label: 'Wiederherstellen',
                  handleClick: () => {
                    if (check())
                      update.mutate({ id: id, data: { status: 'new' } })
                  },
                })
              }

              if (type !== 'shared' && isOwner)
                menuItems.push({
                  icon: 'trash',
                  label: 'Löschen',
                  handleClick: () => deleteDocument(id),
                })

              return (
                <DocumentWithMenu
                  key={id}
                  document={docItem}
                  menuItems={menuItems}
                  renameActive={renameId === id}
                  isShared={
                    !!isShared ||
                    !!(isOwner && sharedWith.length && type === 'my')
                  }
                  currentFolderType={type}
                  handleLeftClick={() => push(`/dokument/${id}`)}
                  handleUpdateName={updateName}
                  isDownloadable={true}
                  isLoading={docItem.isLoading}
                />
              )
            })}
          </Container>
        )
      case 'table': {
        const createColumn = (
          id?: any,
          label?: any,
          minWidth?: any,
          align?: any
        ) => ({ id, label, minWidth, align })
        const columns = [
          createColumn('type', 'Type', 30, 'left'),
          createColumn('name', 'Name', 250, 'left'),
          createColumn('template', 'Template', 250, 'left'),
          createColumn('modified', 'Last modified', 150, 'right'),
          createColumn('actions', 'Actions', 200, 'right'),
        ]
        const createRow = (
          id: String,
          name?: any,
          template?: any,
          modified?: any,
          actions?: any,
          type?: any
        ) => ({ type, id, name, template, modified, actions })
        const rows = documentFiles.map((d) => {
          return createRow(
            d.id,
            d.name,
            d.templateName,
            moment.unix(d.edited?._seconds).fromNow(),
            generateActions(d),
            getTypeIcon(d)
          )
        })
        return (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((c) => (
                    <TableCell
                      key={c.id}
                      align={c.align}
                      style={{ minWidth: c.minWidth }}
                    >
                      {c.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((r: any) => {
                  return (
                    <TableRow key={r.id}>
                      {columns.map((c) => {
                        const key = String(c.id)
                        const value = r[key]

                        return (
                          <TableCell
                            key={`${r.id}-${c.id}`}
                            align={c.align}
                            style={{ minWidth: c.minWidth }}
                          >
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
                <MenuPopover
                  anchorOrigin={{
                    vertical: 20,
                    horizontal: 'left',
                  }}
                  menuItems={selectedMenuItem}
                  handleClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                />
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      default:
        return null
    }
  }, [
    view,
    generateActions,
    check,
    deleteDocument,
    duplicate,
    documentFiles,
    location.pathname,
    onDownload,
    onEdit,
    onOpen,
    push,
    renameId,
    type,
    update,
    user,
    selectedMenuItem,
    getTypeIcon,
    anchorEl,
  ])

  if (type === 'trashed' && !documentFiles.length && !children.length) {
    return <TrashEmpty />
  }

  if (!documentFiles.length && !children.length) {
    return (
      <NoDocs
        {...noDocsProps}
        handleActionClick={
          type === 'my'
            ? () => {
              if (check()) push('/dokument/neu')
            }
            : undefined
        }
      />
    )
  }

  // const onDuplicate = async (id: string) => {
  //   await DocsApi.duplicate(id)
  //   queryClient.invalidateQueries([
  //     queryKeys.DOCUMENTS,
  //     {type, folderId: currentFolder.id },
  //   ])
  // }

  return (
    <>
      <div className="view-buttons-wrapper">
        <button
          className={(view === 'card' && 'active') || ''}
          type="button"
          onClick={() => setView('card')}
          onKeyDown={(event) => event.key === 'ENTER' && setView('card')}
        >
          <InsertDriveFileIcon />
        </button>
        <button
          className={(view === 'table' && 'active') || ''}
          type="button"
          onClick={() => setView('table')}
          onKeyDown={(event) => event.key === 'ENTER' && setView('table')}
        >
          <ViewListIcon />
        </button>
      </div>
      {render}
      {hasNextPage && (
        <LoadMoreButton
          text="Mehr anzeigen"
          isLoading={isLoading || isFetchingNextPage}
          onClick={fetchNextPage}
        />
      )}
    </>
  )
}

export default DocumentsList
