import { styled } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 26,
  fontWeight: 700,
  lineHeight: 1.23,
  letterSpacing: 0.2,
  color: theme.colorz.secondary.main,
  marginBottom: 49,
}))

export const Form = styled('form')({
  width: '100%',
  paddingBottom: 20,
})

export const CheckboxContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

export const PasswordContainer = styled(Box)({
  position: 'relative',
  width: '100%',
})

export const AcceptUrl = styled('span')({
  textDecoration: 'underline',
  marginLeft: 3,
  color: 'inherit',
  fontFamily: 'inherit',
  transition: 'all .2s',
  '&:hover': {
    color: 'rgb(45, 45, 45)',
  },
})
