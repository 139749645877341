import { styled, Theme, Typography } from '@material-ui/core'
import { CustomButton, Checklist } from 'components/common'
import React from 'react'
import { TSubscriptionInterval } from 'types'

interface WithEnterprise {
  isenterprise: number
}
interface T extends WithEnterprise {
  theme: Theme
  isselected: number
}
const Container = styled('div')(({ theme, isselected, isenterprise }: T) => ({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: isenterprise ? '100%' : 324,
  height: isenterprise ? 382 : 568,
  flexShrink: 0,
  flexGrow: 0,
  opacity: isselected ? 0.6 : 1,
  pointerEvents: isselected ? 'none' : 'auto',
  '&:not(:last-child)': {
    marginRight: 30,
  },
}))

const Centered = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Header = styled(Centered)(({ theme }) => ({
  backgroundColor: theme.colorz.brand.main,
  height: 60,
}))

const PriceContainer = styled(Centered)(({ theme }) => ({
  flexDirection: 'column',
  paddingTop: theme.spacing(1),
  height: 148,
  marginBottom: theme.spacing(1),
}))

const TextContent = styled('div')(({ isenterprise }: WithEnterprise) => ({
  width: isenterprise ? 'auto' : 222,
  margin: '0 auto',
  padding: '0 32px',
}))

interface TEl {
  theme: Theme
  light?: 0 | 1
}

const Text = styled(Typography)(({ theme, light }: TEl) => ({
  color: theme.colorz.secondary[light ? 300 : 'main'],
  lineHeight: 1.6,
}))

export interface Props {
  id: string
  heading: string
  priceTitle: string
  price: { month: string; year: string }
  priceSubtitle?: string
  checklist?: { icon: string; text: string }[]
  description?: string
  actionButtonText: string
  isLoading?: boolean
  interval: TSubscriptionInterval
  selected?: boolean | null
  isSubscribed?: boolean | null
  onAction: () => void
}

const PlanItem: React.FC<Props> = ({
  id,
  heading,
  priceTitle,
  price,
  interval,
  priceSubtitle,
  checklist,
  description,
  actionButtonText,
  isLoading,
  selected,
  isSubscribed,
  onAction,
}) => {
  const btnStyles: React.CSSProperties = {
    position: 'absolute',
    bottom: 32,
    left: '50%',
    transform: 'translate(-50%)',
  }
  if (selected) btnStyles.border = 'none'
  return (
    <Container isselected={selected ? 1 : 0} isenterprise={id === 'enterprise' ? 1 : 0}>
      <Header>
        <Text
          style={{
            lineHeight: 1.2,
            letterSpacing: 1.4,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {heading}
        </Text>
      </Header>
      <PriceContainer>
        <Text light={1} style={{ lineHeight: 1, marginBottom: 8 }}>
          {priceTitle}
        </Text>
        <Text
          style={{
            fontSize: 48,
            letterSpacing: -3,
            fontWeight: 700,
            lineHeight: 1,
            marginBottom: 8,
          }}
        >
          {id === 'enterprise' ? 'Let’s talk!' : `CHF ${price[interval]}`}
        </Text>
        <Text light={1} style={{ lineHeight: 1 }}>
          {priceSubtitle ?? '\u200D'}
        </Text>
      </PriceContainer>
      {checklist ? (
        <Checklist
          checklist={checklist}
          containerStyles={{ padding: '0px 25px', marginLeft: 36 }}
        />
      ) : (
        <TextContent isenterprise={id === 'enterprise' ? 1 : 0}>
          <Text light={1} style={{ letterSpacing: 0.118519 }}>
            {description}
          </Text>
        </TextContent>
      )}
      <CustomButton
        btntype={id === 'enterprise' || selected ? 'secondary' : 'primary'}
        size='large'
        width={id === 'enterprise' ? '91.5%' : 260}
        disabled={isLoading}
        style={btnStyles}
        onClick={onAction}
      >
        {selected ? 'Ihr bisheriges Abo' : actionButtonText}
      </CustomButton>
    </Container>
  )
}

export default PlanItem
