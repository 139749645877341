import React from 'react'
import { Container, Title, Subtitle, Image } from './TrashEmpty.styles'
import trash_svg from './trash.svg'

const TrashEmpty: React.FC = () => {
  return (
    <Container>
      <Image src={trash_svg} alt='trash img' />
      <Title variant='h4' align='center'>
        Ihr Papierkorb ist leer.
      </Title>
      <Subtitle>
        Verschieben Sie Dateien, die Sie nicht mehr benötigen, in den Papierkorb.
      </Subtitle>
    </Container>
  )
}

export default TrashEmpty
