import { styled, Box, Typography, Theme, Collapse, Button } from '@material-ui/core'

const commonStyles = (theme: Theme) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fff',
  borderRadius: 4,
  boxShadow: '0 2px 0 #F3EEEA',
  display: 'flex',
  flexShrink: 0,
})

export const Container = styled(Box)(({ theme }) => ({
  ...commonStyles(theme),
  flexDirection: 'column',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export const TopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 78,
}))

export const BodyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 240,
  padding: theme.spacing(3, 2, 3, 0),
}))

export const RowItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
}))

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[200],
  width: '25%',
  minWidth: 300,
  paddingLeft: theme.spacing(10),
}))

export const ContainerPast = styled(Box)(({ theme }) => ({
  ...commonStyles(theme),
  alignItems: 'center',
  height: 64,
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}))

export const PastDataWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
})

export const PastDataStatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '20%',
  flexShrink: 0,
}))

export const UserWrapper = styled(Box)({
  width: '25%',
  minWidth: 300,
  display: 'flex',
  flexShrink: 0,
})

export const UserInfoWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 24,
})

export const StrongText = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fontWeight: 600,
  letterSpacing: 0.4,
  fontSize: 16,
}))

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
}))

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  '& > *:first-child': {
    marginRight: theme.spacing(1),
  },
  '& > *': {
    width: 182,
  },
}))

export const ButtonsWrapperFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  height: 48,
  '& > *:first-child': {
    marginLeft: theme.spacing(10),
  },
  '& > *:last-child': {
    marginLeft: 'auto',
  },
  '& > *': {
    width: 182,
  },
}))

export const ButtonText = styled(Button)(({ theme }) => ({
  color: theme.colorz.status.error,
  textTransform: 'none',
}))

export { Collapse, Typography }
