import { FeaturesLayout } from 'layout'
import React from 'react'
import { useHistory } from 'react-router'

import { useBookLawyer } from '../provider/BookLawyerProvider'

import { StorageService } from 'utils'
import { legalAdviceRoutes } from 'constants/routes'

const featureSteps = [
  { step: 1, label: 'Termin vorschlagen' },
  { step: 2, label: 'Zahlung' },
  { step: 3, label: 'Sich beraten lassen' },
]

const Layout: React.FC = ({ children }) => {
  const { step } = useBookLawyer()
  const { push } = useHistory()

  const onClose = () => {
    push(legalAdviceRoutes.main)
    StorageService.remove('bookLawyer')
  }

  return (
    <FeaturesLayout
      currentStep={step}
      onBoardingSteps={[]}
      steps={featureSteps}
      handleClose={onClose}
      heading='Beratungstermin'
      isLoading={false}
    >
      {children}
    </FeaturesLayout>
  )
}

export default Layout
