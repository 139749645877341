import { MessageType } from 'types'
import * as A from './message.types'
import * as msgTypes from './message.constants'

export const setAppMessage = (
  type: MessageType,
  message: string,
  duration?: number
): A.SetAppMessage => ({
  type: msgTypes.SET_APP_MESSAGE,
  payload: {
    type,
    message,
    duration,
  },
})

export const clearAppMessage = (): A.ClearAppMessage => ({
  type: msgTypes.CLEAR_APP_MESSAGE,
  payload: null,
})
