import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useQueryClient } from 'react-query'
import { useModal } from 'hooks/useModal'

import { svgStringToDataUrl } from 'utils'
import { documentsRoutes } from 'constants/routes'
import ModalTemplate from '../ModalTemplate/ModalTemplate'

import svgString from './illustration'
import { queryKeys } from 'queries'

const image = svgStringToDataUrl(svgString)

const UpgradeSubscriptionSuccess: React.FC = () => {
  const { push } = useHistory()
  const { closeModal } = useModal()
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries(queryKeys.USER)
  }, [queryClient])

  const onAction = () => {
    push(documentsRoutes.main)
    closeModal()
  }

  return (
    <ModalTemplate
      title='Abo erfolgreich gekauft!'
      text='Wir freuen uns, dass Sie hier sind. Frohes Schaffen!'
      image={image}
      actionBtnText={`Los geht's`}
      onClose={onAction}
      onAction={onAction}
    />
  )
}

export default UpgradeSubscriptionSuccess
