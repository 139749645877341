const str = `<svg width="112" height="139" viewBox="0 0 112 139" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="30" width="82" height="104" fill="#F3EEEA"/>
<path d="M54.1668 59.1176V33H5.83325V59.1176C5.83325 68.7653 11.4102 77.0965 19.4894 81.0203C21.3146 81.9066 22.5 83.7401 22.5 85.7832V86.0996C22.5 88.1426 21.3146 89.9757 19.4894 90.8625C11.4102 94.7863 5.83325 103.117 5.83325 112.765V138.882H54.1668V112.765C54.1668 103.117 48.5899 94.7859 40.5106 90.8621C38.6855 89.9757 37.5 88.1422 37.5 86.0991V85.7828C37.5 83.7397 38.6855 81.9066 40.5106 81.0199C48.5899 77.0965 54.1668 68.7657 54.1668 59.1176Z" fill="#EED8D5"/>
<path d="M49.9998 132.02V128.731C49.9998 117.648 41.0581 108.63 30.0666 108.63H29.9329C18.9419 108.63 9.99969 117.648 9.99969 128.731V132.02H49.9998Z" fill="#738EA0"/>
<path d="M57.4897 42.3137H2.51029C1.12368 42.3137 0 41.1896 0 39.8034V35.5805C0 34.1938 1.12412 33.0702 2.51029 33.0702H57.4897C58.8763 33.0702 60 34.1943 60 35.5805V39.8034C60 41.1896 58.8759 42.3137 57.4897 42.3137Z" fill="#D2866E"/>
<path d="M2.51029 129.569H57.4897C58.8763 129.569 60 130.693 60 132.079V136.302C60 137.689 58.8759 138.812 57.4897 138.812H2.51029C1.12368 138.812 0 137.688 0 136.302V132.079C0 130.693 1.12412 129.569 2.51029 129.569Z" fill="#D2866E"/>
</svg>
`

export default str
