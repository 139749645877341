import React from 'react'
import { PopoverContainer } from './EditorView.styles'
import { ButtonSquare, Icon } from 'components/common'

interface Props {
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>
  handleCancel: React.MouseEventHandler<HTMLButtonElement>
  variant: 'delete' | 'selection'
  xposition: number
  yposition: number
}

const PopoverActions: React.FC<Props> = ({
  handleConfirm,
  handleCancel,
  variant,
  xposition,
  yposition,
}) => {
  return (
    <PopoverContainer xposition={xposition} yposition={yposition}>
      <ButtonSquare
        size={32}
        onClick={handleConfirm}
        btntype={variant === 'delete' ? 'white' : 'primary'}
      >
        <Icon
          iconName={variant === 'delete' ? 'trash' : 'check'}
          fill={variant === 'delete' ? '#001640' : '#fff'}
        />
      </ButtonSquare>
      <ButtonSquare
        size={32}
        onMouseDown={handleCancel}
        onClick={() => {}}
        btntype='white'
      >
        <Icon iconName='close' />
      </ButtonSquare>
    </PopoverContainer>
  )
}

export default PopoverActions
