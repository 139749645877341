import { IReminder } from 'types'
import React, { Fragment } from 'react'
import { ReminderItem } from '../ReminderItem/ReminderItem'
import reminders_svg from './reminders.svg'
import { NoItemsAction } from 'components/common'

interface Props {
  reminders: IReminder[]
  onDelete: (reminderId: string) => void
  onEdit: (reminderId: string) => void
}

export const RemindersListComponent: React.FC<Props> = ({
  reminders,
  onEdit,
  onDelete,
}) => {
  if (!reminders.length)
    return (
      <NoItemsAction image={reminders_svg} text='Sie haben noch keine Erinnerungen' />
    )
  return (
    <Fragment>
      {reminders.map(reminderItem => (
        <ReminderItem
          key={reminderItem.id}
          {...reminderItem}
          onEdit={() => onEdit(reminderItem.id)}
          onDelete={() => onDelete(reminderItem.id)}
        />
      ))}
    </Fragment>
  )
}
