import { queryKeys } from 'queries'
import { useQueryClient } from 'react-query'
import { IGetLegalAdviceEvents } from 'api/user.api'

export const useLegalAdviceEvents = () => {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<IGetLegalAdviceEvents>(
    queryKeys.LEGAL_ADVICE_EVENTS
  )

  return (
    data ?? {
      upcomingEvents: [],
      pastEvents: [],
    }
  )
}
