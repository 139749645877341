import React from 'react'
import PropTypes from 'prop-types'

import { dataStructureType } from 'utilities/propTypes'

import Wizard from './Wizard'
import './style.scss'

const TemplateCreationWizard = React.memo(({ dataStructure, cssData, questions }) => (
  <Wizard dataStructure={dataStructure} cssData={cssData} questions={questions} mode="template-creation" />
))
TemplateCreationWizard.displayName = 'TemplateCreation-Wizard'
TemplateCreationWizard.propTypes = {
  dataStructure: dataStructureType(),
  cssData: PropTypes.object,
}

const DocumentGenerationWizard = React.memo(({ dataStructure, cssData, questions, answers }) => (
  <Wizard
    dataStructure={dataStructure}
    cssData={cssData}
    questions={questions}
    answers={answers}
    mode="template-creation"
  />
))
DocumentGenerationWizard.displayName = 'DocumentGeneration-Wizard'
DocumentGenerationWizard.propTypes = {
  dataStructure: dataStructureType(),
  cssData: PropTypes.object,
}

export { TemplateCreationWizard, DocumentGenerationWizard }
