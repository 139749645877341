import { Box, styled } from '@material-ui/core'
import { scrollBarStyles } from 'constants/common-styles'
import theme from 'config/themes/theme'

export const Container = styled(Box)(({ theme }) => ({
  displat: 'flex',
  flexDirection: 'column',
  width: 651,
  padding: theme.spacing(5, 4.5, 5, 5),
  height: 484,
  overflowY: 'auto',
  background: theme.colorz.secondary[50],
  ...scrollBarStyles(theme),
}))

export const backdropStyle = { background: theme.colorz.secondary[100], opacity: 0.8 }
