import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLoadUser } from 'queries/user/useLoadUser'

import { queryKeys } from 'queries/queryKeys'

import AuthApi from 'api/auth.api'
import { auth } from 'config/firebase'

const authToken = localStorage.getItem('casusToken')
const tokenExpiresAt = localStorage.getItem('casusExpiresIn')

const isTokenExpired = () => {
  return !!authToken && !!tokenExpiresAt && new Date() >= new Date(tokenExpiresAt)
}

if (!isTokenExpired()) {
  AuthApi.setAuthHeaders(authToken, tokenExpiresAt)
} else {
  AuthApi.setAuthHeaders(null, null)
}

export const useAuthenticateUser = () => {
  const { data: getMeUser } = useLoadUser()
  const queryCache = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const tokenExpired = isTokenExpired()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      const initLoading = tokenExpired
      try {
        setIsLoading(initLoading)
        if (firebaseUser) {
          const user = await AuthApi.authenticate(firebaseUser, getMeUser)
          queryCache.setQueryData(queryKeys.USER, user)
        } else {
          queryCache.setQueryData(queryKeys.USER, null)
          AuthApi.setAuthHeaders(null, null)
        }
      } catch (err) {
        AuthApi.setAuthHeaders(null, null)
      } finally {
        setIsLoading(false)
      }
    })
    return () => unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading
}
