import React, { useState, useEffect } from 'react'
import { styled } from '@material-ui/core'

import { Prompt, useHistory } from 'react-router-dom'

import { useDocumentGeneration } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useModal } from 'hooks/useModal'

import { FooterNavigation } from 'components/features'
import { Questionnaire } from './views/Questionnaire/Questionnaire.container'
import { DocumentGenerationResult } from './views/DocumentGenerationResult/DocumentGenerationResult.container'
import { DocumentGenerationLayout } from './layout/DocumentGenerationLayout'
import { LastStepDocumentGeneration } from './views/LastStep/LastStep'

import { StorageService } from 'utils/storageService'

import {
  POPUP_ON_CLOSE_IF_CREATED_DG,
  POPUP_ON_CLOSE_IF_EDITED_DG,
  headingEdit,
  headingCreate,
} from './content'
import { UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID } from 'constants/ui-elements'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  backgroundColor: theme.colorz.brand.light,
}))

interface Props {
  isExpired?: boolean
}

export const DocumentGeneration: React.FC<Props> = ({ isExpired }) => {
  const {
    set,
    save,
    wasDocumentEdited,
    step,
    filteredQuestions,
    mode,
    answers,
    isSaving,
    isDirty,
  } = useDocumentGeneration()

  const history = useHistory()
  const [dirty, setDirty] = useState(true)
  const { goBack, push, replace, location } = history
  const [finish, setFinish] = useState(false)
  const { popup, close } = useConfirmPopup()
  const { openModal } = useModal()
  const searchParams = new URLSearchParams(location.search)
  const fromCreate = searchParams.get('fromCreate')

  useEffect(() => {
    if (isExpired) openModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID)
  }, [isExpired, openModal])

  const onFinish = (): void => {
    if (isExpired) return
    if (step === 1) return setFinish(true)
    if (step === 2) {
      // if (mode === 'create_public') {
      //   return save()
      // }
      // if (
      //   isDirty ||
      //   (mode === 'edit' && wasDocumentEdited()) ||
      //   mode === 'create'
      // ) {
      //   return save(() => {
      //     set({ step: 3 })
      //     setDirty(false)
      //   })
      // }

      set({ step: 3 })
      setDirty(false)
    }
  }

  useEffect(() => {
    if (finish) StorageService.save('currentAnswers', answers)
  }, [finish, answers])

  const action = () => {
    if (fromCreate) {
      replace(location.pathname)
      push('/aktenschrank')
    } else {
      goBack()
    }
    close()
  }

  const onClose = () => {
    // TODO ASK IF EDITED ETC...
    setDirty(false)
    switch (mode) {
      case 'create_public':
        goBack()
        break
      case 'edit':
        if ((!wasDocumentEdited() && !isDirty) || isExpired) return action()
        popup({
          ...POPUP_ON_CLOSE_IF_EDITED_DG,
          onConfirm: () => {
            save(action)
          },
          onCancel: action,
        })
        break
      case 'create':
        if (isExpired) return push('/aktenschrank')
        popup({
          ...POPUP_ON_CLOSE_IF_CREATED_DG,
          onConfirm: () => {
            save(() => {
              close()
              push('/aktenschrank')
            })
          },
          onCancel: () => {
            close()
            goBack()
          },
        })
        break
    }
  }

  const onBack = (): void => {
    if (filteredQuestions.length && step === 1) {
      return set({ step: 2, filteredQuestions: [] })
    }

    if (step === 1) {
      return action()
    }
    if (step > 1) set({ step: step - 1 })
  }

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <Questionnaire
            finish={finish}
            isExpired={isExpired}
            setFinish={setFinish}
          />
        )
      case 2:
      case 3:
        return (
          <>
            <DocumentGenerationResult />
            <LastStepDocumentGeneration setDirty={setDirty} />
          </>
        )
      default:
        return null
    }
  }

  const getHeading = () => {
    if (mode === 'edit') {
      if (!isSaving) return headingEdit
      if (isSaving) return 'Änderungen werden gespeichert...'
    }

    if (isSaving) return 'Dokument wird erstellt'
    return headingCreate
  }

  useEffect(() => {
    if (dirty) {
      const warn = (event: any) => {
        const message = 'Going somewhere?'
        ;(event || window.event).returnValue = message
        alert(message)
        return message
      }
      window.addEventListener('beforeunload', warn)
      return () => {
        window.removeEventListener('beforeunload', warn)
      }
    }
  }, [dirty])

  return (
    <DocumentGenerationLayout
      heading={getHeading()}
      step={step + 1}
      onClose={onClose}
      isLoading={false}
    >
      <Container>{renderContent()}</Container>
      <Prompt
        when={dirty}
        message="Changes you made may not be saved."
      ></Prompt>
      {[1, 2].includes(step) && (
        <FooterNavigation
          handleBackClick={onBack}
          handleForwardClick={onFinish}
          isLoading={isSaving}
        />
      )}
    </DocumentGenerationLayout>
  )
}
