import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'flex',
  maxWidth: 760,
})

export const ActionBtn = styled(Typography)({
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.2,
  transition: 'all .2s',
  cursor: 'pointer',
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    opacity: 1,
    transform: 'translateY(-1px)',
  },
})

export * from '../DocumentSettings.styles'
