import { ConnectSectionProps } from './RadioStandardForm.types'
import React, { Fragment } from 'react'
import OptionsList from '../../components/OptionList/OptionList'
import { CustomButton } from 'components/common'
import { btnContent } from 'pages/Authenticated/Template/constants/content'

const ConnectSection: React.FC<ConnectSectionProps> = ({
  handleAddOption,
  ...otherProps
}) => {
  return (
    <Fragment>
      <OptionsList actions={true} {...otherProps} />
      <CustomButton btntype='white' onClick={handleAddOption} style={{ marginTop: 8 }}>
        {btnContent.newOption}
      </CustomButton>
    </Fragment>
  )
}

export default ConnectSection
