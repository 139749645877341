import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Container, Content, NavWrapper } from './DocumentSettings.styles'
import Navigation from 'layout/PagesLayout/Header/Navigation/Navigation'
import HeaderSettings from './HeaderSettings'
import FooterSettings from './FooterSettings'

const baseUrl = '/aktenschrank/einstellungen'
const headerUrl = `${baseUrl}/kopfzeile`
const footerUrl = `${baseUrl}/fusszeile`

const routes = [
  {
    label: 'Kopfzeile',
    url: headerUrl,
    disabled: false,
  },
  {
    label: 'Fusszeile',
    url: footerUrl,
    disabled: false,
  },
]

const DocumentSettingsPage: React.FC = () => {
  // const fetchDocumentSettings = useCallback(() => {
  //   dispatch(A.getDocumentSettingsRequest())
  // }, [dispatch])

  // useEffect(() => {
  //   if (!hasBeenFetched) fetchDocumentSettings()
  // }, [fetchDocumentSettings, hasBeenFetched])

  return (
    <Container>
      <NavWrapper>
        <Navigation routes={routes} orientation='vertical' />
      </NavWrapper>
      <Content>
        <Switch>
          <Route exact path={baseUrl} render={() => <Redirect to={headerUrl} />} />
          <Route exact path={headerUrl} component={HeaderSettings} />
          <Route exact path={footerUrl} component={FooterSettings} />
          <Redirect to={headerUrl} />
        </Switch>
      </Content>
    </Container>
  )
}

export default DocumentSettingsPage
