import React from 'react'

import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import UpcomingEventsPage from './components/UpcomingEvents'
import PastEvents from './components/PastEvents'
import BookALawyer from './components/BookLawyer/BookLawyer'
import { legalAdviceRoutes } from 'constants/routes'

const ConsultingPageClient: React.FC = () => {
  const location = useLocation()

  return (
    <Switch location={location}>
      <Route exact path={legalAdviceRoutes.bookLawyer} component={BookALawyer} />
      <Route exact path={legalAdviceRoutes.main} component={UpcomingEventsPage} />
      <Route exact path={legalAdviceRoutes.pastEvents} component={PastEvents} />
      <Redirect to={legalAdviceRoutes.main} />
    </Switch>
  )
}

export default ConsultingPageClient
