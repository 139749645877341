import { useSelector } from 'react-redux'
import { State, TemplateType, FolderItem } from 'types'

export const useRootCategoryFolder = (
  where: 'templates' | 'docs',
  type: TemplateType
) => {
  const rootFolder = useSelector((state: State) => state[where].rootFolder)
  return rootFolder.children.find(f => f.type === type)! as FolderItem
}
