import { ITeamMember } from 'types'
import { useQuery } from 'react-query'
import { queryKeys, TQueryOptions } from 'queries'

import TeamApi from 'api/team.api'

export const useLoadTeamMembers = (options?: TQueryOptions<ITeamMember[]>) => {
  return useQuery(queryKeys.TEAM_MEMBERS, TeamApi.get, {
    ...options,
  })
}
