import React from 'react'
import {
  Container,
  Content,
  LinearProgress,
  CheckContainer,
  Text,
  Status,
  getColorByPasswordStrength,
} from './PasswordStrengthMeter.styles'
import { Icon } from 'components/common'
import { CheckElProps, PasswordStrengthMeterProps } from './PasswordStrengthMeter.types'

const CheckEl: React.FC<CheckElProps> = ({ label, strength, error }) => {
  const color = getColorByPasswordStrength({ error, strength })
  return (
    <CheckContainer>
      <Icon iconName='check' fill={color} />
      <Text strength={strength} error={error ? 1 : 0}>
        {label}
      </Text>
    </CheckContainer>
  )
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
  error,
}) => {
  const strength = password.length > 7 ? 100 : 25
  return (
    <Container>
      <Content>
        <CheckEl label='Mindestens 8 Zeichen' strength={strength} error={error} />
        <Status>{strength < 26 ? 'Schwaches Passwort' : 'Sehr starkes Passwort'}</Status>
        <LinearProgress variant='determinate' value={strength} />
      </Content>
    </Container>
  )
}

export default PasswordStrengthMeter
