import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  backgroundColor: theme.colorz.brand.light,
  width: 651,
  height: 570,
}))

export const StrongText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  letterSpacing: 0.4,
  margin: theme.spacing(1, 0),
  textAlign: 'center',
  color: theme.colorz.secondary.main,
}))

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  fontSize: 16,
  letterSpacing: 0.4,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}))
