import { EventListScheduledProps } from './EventsList.types'
import React from 'react'
import EventItemScheduled from './EventItem/EventItemScheduled'
import { Container } from './EventsList.styles'

const EventListScheduled: React.FC<EventListScheduledProps> = ({
  eventItems,
  userType,
  handleBeginEvent,
  handleCancelEvent,
  handleRescheduleEvent,
}) => {
  return (
    <Container>
      {eventItems.map((eventItem, i) => {
        return (
          <EventItemScheduled
            key={i}
            eventItem={eventItem}
            userType={userType}
            handleBeginEvent={handleBeginEvent}
            handleCancelEvent={handleCancelEvent}
            handleRescheduleEvent={handleRescheduleEvent}
          />
        )
      })}
    </Container>
  )
}

export default EventListScheduled
