import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useUser } from 'queries/user/useUser'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useRootCategoryFolder } from 'hooks/useRootCategoryFolder'

export const EmailSharedFileRedirect: React.FC = () => {
  const { type, id } = useParams<{ type: 'document' | 'template'; id: string }>()
  const where = type === 'template' ? 'templates' : 'docs'
  const { setCurrentFolder } = useCurrentFolder(where)
  const sharedFolder = useRootCategoryFolder(where, 'shared')
  const {
    replace,
    location: { pathname },
  } = useHistory()
  const user = useUser()

  const redirectUrl =
    type === 'template' ? `/vorlagen?vorlage=${id}&action=preview` : `/dokument/${id}`

  useEffect(() => {
    if (!user) {
      const encodedUrl = encodeURIComponent(pathname)
      replace(`/auth/login?redirect=${encodedUrl}`)
    } else {
      setCurrentFolder(sharedFolder)
      replace(redirectUrl)
    }
  }, [user, type, id, redirectUrl, sharedFolder, pathname, replace, setCurrentFolder])

  return null
}
