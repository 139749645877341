import { Box, styled } from '@material-ui/core'
import { scrollBarStylesLight } from 'constants/common-styles'

export const Container = styled(Box)(({ theme }) => ({
  width: 256,
  height: 48,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  cursor: 'pointer',
  position: 'relative',
  backgroundColor: theme.colorz.brand.main,
  borderRadius: 2,
  border: `2x solid ${theme.colorz.brand.dark}`,
  marginBottom: theme.spacing(4),
  marginRight: theme.spacing(4),
  padding: theme.spacing(0, 2),
  transition: 'all .2s',
}))

export const TextInput = styled('input')(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.4,
  fontFamily: 'inherit',
  color: theme.colorz.secondary.main,
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  cursor: 'pointer',
  '&:focus': {
    cursor: 'auto',
  },
}))

export const ListContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
})

export const SharedThrough = styled(Box)(({ theme }) => ({
  lineHeight: 1.2,
  letterSpacing: 0.19,
  boxShadow: '0px 4px 28px rgba(0, 0, 0, 0.1)',
  borderRadius: 2,
  padding: theme.spacing(1, 1.5),
  position: 'absolute',
  bottom: 20,
  right: -30,
  maxHeight: 200,
  overflowY: 'auto',
  color: theme.colorz.secondary[300],
  backgroundColor: '#fff',
  ...scrollBarStylesLight(theme),
}))
