import Api from './api'

interface IConvertApi {
  from?: 'docx'
  to?: 'html'
  base64data: string
}

class Conversion extends Api {
  constructor() {
    super('/convert')
  }

  convert = async ({
    from = 'docx',
    to = 'html',
    base64data,
  }: IConvertApi): Promise<string> => {
    const res = await this.api().post(`/${from}/${to}`, { base64data })
    return res.data.data.htmlData._html
  }
}

const conversionApi = new Conversion()

export default conversionApi
