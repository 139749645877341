import { FormStepTwoLawyerProps } from '../../components/ProfileInfo.types'
import React, { Fragment } from 'react'
import { stepTwo as fields } from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoLawyer/content'
import { TextInputGroup } from 'components/common'
import { labelStyle, Row, Item } from '../../components/ProfileInfo.styles'

const ProfileInfoLawyerStepTwo: React.FC<FormStepTwoLawyerProps> = ({
  handleChange,
  values,
}) => {
  const renderTextFields = () =>
    fields.map((field, i) => (
      <Row key={i}>
        <Item>
          <TextInputGroup
            value={values[field.name]}
            onChange={handleChange}
            LabelProps={{ style: labelStyle, variant: 'body2' }}
            InputGroupContainerProps={{ style: { marginBottom: 0 } }}
            customvariant='light'
            {...field}
          />
        </Item>
      </Row>
    ))

  return <Fragment>{renderTextFields()}</Fragment>
}

export default ProfileInfoLawyerStepTwo
