import { styled } from '@material-ui/core/styles'
import { Box, Typography, Modal } from '@material-ui/core'
import { grey } from 'config/themes/colors'

export const Form = styled('form')({
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 616,
  maxWidth: 946,
  outline: 'none',
})

export const FormHeader = styled(Box)(({ theme }) => ({
  height: 124,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 6),
  position: 'relative',
  backgroundColor: theme.colorz.secondary.main,
  flexShrink: 0,
}))

export const FormBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 6, 0, 6),
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  backgroundColor: '#fff',
  minHeight: 492,
}))

export const FormTitle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  position: 'absolute',
  left: theme.spacing(6),
  paddingTop: 10,
}))

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))

export const FieldsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}))

export const FormFooter = styled(Box)(({ theme }) => ({
  height: 48,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  margin: theme.spacing(6, 0),
}))

export const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.colorz.grey.light,
  width: 400,
}))

export const labelStyle = {
  color: grey.main,
}

export const selectInputElStyle = {
  backgroundColor: 'transparent',
  marginBottom: 0,
}

export const Row = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  flexWrap: 'wrap',
})

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '48%',
}))

export const Item = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '48%',
  marginBottom: theme.spacing(3),
}))

export const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& > *:last-child': {
    marginLeft: theme.spacing(2),
  },
}))

export const ImageEditorWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 400,
  marginBottom: 32,
})

export const SliderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const ProfileImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
}))

export const ActionText = styled(Typography)(({ theme }) => ({
  transition: 'all .2s',
  cursor: 'pointer',
  margin: theme.spacing(2, 0),
  '&:hover': {
    opacity: 0.9,
    transform: 'translateY(-2px)',
  },
}))

export const SkipText = styled(Typography)(({ theme }) => ({
  transition: 'all .2s',
  cursor: 'pointer',
  color: theme.colorz.primary.main,
  opacity: 0.7,
  '&:hover': {
    opacity: 1,
    transform: 'translateY(-2px)',
  },
}))

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.colorz.grey.main,
  marginBottom: theme.spacing(2),
}))

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0.4,
  color: theme.colorz.secondary.main,
}))

export const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
  '&:focus': {
    outline: 'none !important',
  },
  '&:active': {
    outline: 'none',
  },
}

export const LabelStripeEl = styled(Typography)(({ theme }) => ({
  ...labelStyle,
  fontSize: 14,
  letterSpacing: 0.3,
  marginBottom: theme.spacing(2),
}))

export { Modal, Typography }
