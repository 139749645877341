import { MessagePopupProps } from './MessagePopup.types'
import React, { useCallback } from 'react'
import * as A from 'redux/actions'
import { useDispatch } from 'react-redux'
import { Snackbar, Portal } from '@material-ui/core'
import { Alert, AlertProps } from '@material-ui/lab'

const AlertMessage: React.FC<AlertProps> = props => (
  <Alert elevation={6} variant='filled' {...props} />
)

const MessagePopup: React.FC<MessagePopupProps> = ({ message }) => {
  const dispatch = useDispatch()

  const handleClose = useCallback((): void => {
    dispatch(A.clearAppMessage())
  }, [dispatch])

  return (
    <Portal>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={message?.duration ?? 4000}
        onClose={handleClose}
        transitionDuration={{ enter: 400, exit: 0 }}
      >
        <AlertMessage onClose={handleClose} severity={message?.type} elevation={0}>
          {message?.message}
        </AlertMessage>
      </Snackbar>
    </Portal>
  )
}

export default MessagePopup
