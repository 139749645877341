import { Question } from 'types'
import React, { useEffect, useCallback, useState } from 'react'
import NumberTypeForm from './QuestionNumber.component'

import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { addLocationToQuestion } from 'pages/Authenticated/Template/views/TemplateEdit/question.utils'
import { generateNewLocationObject } from 'pages/Authenticated/Template/views/TemplateEdit/services'
import { useUser } from 'queries/user/useUser'

export interface IRangeState {
  rangeFrom: string
  rangeTo: string
}

const rangesToState = ({ rangeFrom, rangeTo }: Question): IRangeState => ({
  rangeFrom: +rangeFrom! === 0 && +rangeTo! === 0 ? '' : String(rangeFrom),
  rangeTo: +rangeFrom! === 0 && +rangeTo! === 0 ? '' : String(rangeTo),
})

const getTabType = ({ rangeFrom, rangeTo }: Question): 'with-range' | 'no-range' => {
  if (rangeFrom !== 0 && rangeTo !== 0) {
    return 'with-range'
  }
  return 'no-range'
}

const emptyState: IRangeState = {
  rangeFrom: '',
  rangeTo: '',
}

const NumberTypeContainer: React.FC<{
  setIsDisabled: (val: boolean) => void
}> = ({ setIsDisabled }) => {
  const { set, textSelectionIsActive, selectionData, question } = useTemplateCreation()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice
  const { id, start, end, currentQuestionId: qid, locationId } = selectionData as any

  const [ranges, setRanges] = useState(rangesToState(question!))
  const [currentTab, setCurrentTab] = useState(getTabType(question!))

  const { rangeFrom, rangeTo } = ranges

  const addSelectedLocation = useCallback(
    (id: string, start: number, end: number, qid?: string, locId?: string) => {
      set({
        question: addLocationToQuestion(
          question,
          generateNewLocationObject(id, start, end, qid, locId, docXmicro)
        ),
        selectionData: { id: null, start: null, end: null, currentQuestionId: null, locationId: null },
      })
    },
    [set, question, docXmicro]
  )

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setRanges(prev => ({ ...prev, [name]: value }))
    if (isNaN(Number(rangeTo)) && isNaN(Number(rangeFrom))) return
    const updatedQuestion: any = { ...question, [name]: +value }
    set({ question: updatedQuestion })
  }

  useEffect(() => {
    if ((rangeFrom === '' || rangeTo === '') && currentTab === 'with-range') {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [rangeFrom, rangeTo, currentTab, setIsDisabled])

  useEffect(() => {
    if (start !== null && end && id) {
      addSelectedLocation(id, start, end, qid, locationId)
    }
  }, [id, start, end, qid, locationId, addSelectedLocation])

  const onTextSelectionToggle = (): void => {
    set({ textSelectionIsActive: !textSelectionIsActive })
  }

  const handleTabChange = (value: string): void => {
    if (currentTab === 'with-range') {
      set({ question: { ...question!, rangeTo: 0, rangeFrom: 0 } })
      setRanges(emptyState)
    }
    if (value === 'with-range' || value === 'no-range') setCurrentTab(value)
    if (value === 'no-range') setIsDisabled(false)
  }

  return (
    <NumberTypeForm
      textSelectionIsActive={textSelectionIsActive}
      toggleTextSelection={onTextSelectionToggle}
      handleRangeChange={handleRangeChange}
      ranges={ranges}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  )
}

export default NumberTypeContainer
