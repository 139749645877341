import { EventListPastProps } from './EventsList.types'
import React from 'react'
import EventItemPast from './EventItem/EventItemPast'
import { Container } from './EventsList.styles'

const EventListPast: React.FC<EventListPastProps> = ({ eventItems }) => {
  return (
    <Container>
      {eventItems.map((eventItem, i) => (
        <EventItemPast key={i} eventItem={eventItem} />
      ))}
    </Container>
  )
}

export default EventListPast
