import React, { useState } from 'react'
import moment from 'moment'

import { Checklist, Loader } from 'components/common'
import ActionButtons from './ActionButtons'
import {
  Container,
  Wrapper,
  FreeTrialTime,
  Text,
  PriceInfoWrapper,
  PlanInfoWrapper,
} from './ProfileSettingsSelectedPlan.styles'
import { basicPlan, teamPlan, freePlan, startupPlan } from 'components/subscriptions/content'
import { useUserSubscription } from 'components/subscriptions/hooks/useUserSubscription'
import { calcDateDifference } from 'utils'
import SubscriptionPlansModal from 'components/subscriptions/components/SubscriptionPlansModal/SubscriptionPlansModal'
import { useUser } from 'queries/user/useUser'

const plans = {
  basic: basicPlan,
  team: teamPlan,
  free: freePlan,
  startup: startupPlan
}

const ProfileSettingsSelectedPlan: React.FC = () => {
  const { isLoading } = useUserSubscription()
  const [modalOpen, setModalOpen] = useState(false)
  const user = useUser()

  const renderFreeUser = () => {
    return (
      <Wrapper>
        <Text
          style={{
            fontWeight: 700,
            fontSize: 18,
            letterSpacing: 0.45,
            marginBottom: 4,
          }}
        >
          Noch kein Abo
        </Text>
        <Text variant='body1' colorz='300' style={{ maxWidth: 700 }}>
          Sie sind noch für kein CASUS-Abo angemeldet. Registrieren Sie sich jetzt für ein
          Abo, um Zugang zu allen CASUS-Vorlagen und vielen weiteren Funktionalitäten zu
          erhalten.
        </Text>
        <ActionButtons hideCancel />
      </Wrapper>
    )
  }

  const renderTrialUser = () => {
    if (!user?.subscription.subscriptionTrialEndAt) return null
    const { subscriptionTrialEndAt } = user.subscription

    const expiresIn = subscriptionTrialEndAt
      ? calcDateDifference('days', {
          date1: moment.unix(subscriptionTrialEndAt._seconds),
          date2: new Date(),
        })
      : null

    return (
      <Wrapper>
        <Text
          style={{
            fontWeight: 700,
            fontSize: 18,
            letterSpacing: 0.45,
            marginBottom: 4,
          }}
        >
          Free Trial
        </Text>
        <Text variant='body1' colorz='300'>
          Nutzen Sie alle Funktionen kostenlos während 14 Tagen.
        </Text>
        {typeof expiresIn === 'number' && (
          <FreeTrialTime>
            <Text
              style={{
                fontWeight: 700,
                fontSize: 24,
                letterSpacing: 0.6,
                lineHeight: 1.4,
              }}
            >
              {expiresIn + 1}
            </Text>
            <Text
              variant='body1'
              style={{ fontSize: 14, textAlign: 'center' }}
              colorz='300'
            >
              {expiresIn + 1 === 1 ? 'Tag' : 'Tage'} übrig
            </Text>
          </FreeTrialTime>
        )}
        <ActionButtons hideCancel />
      </Wrapper>
    )
  }

  const renderPaidUser = () => {
    if (!user?.subscription) return null
    const { interval, type, nextChargeDate, status } = user.subscription
    return (
      <Container>
        <Text
          style={{
            fontWeight: 700,
            fontSize: 18,
            letterSpacing: 0.45,
            marginBottom: 4,
            textTransform: 'uppercase',
          }}
        >
          {plans[type].heading}
        </Text>
        <Text variant='body1' colorz='300'>
          {plans[type].description}
        </Text>
        <PlanInfoWrapper>
          <Checklist checklist={plans[type].checklist} />
          {interval && (
            <PriceInfoWrapper>
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: 50,
                  letterSpacing: -3,
                  lineHeight: 0.7,
                }}
              >
                {interval ? plans[type].price[interval] : '0.-'}
              </Text>
              <Text
                variant='body1'
                style={{ fontSize: 14, marginBottom: 26 }}
                colorz='300'
              >
                pro Monat
              </Text>
              <Text
                variant='body1'
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setModalOpen(true)}
              >
                Abos vergleichen
              </Text>
            </PriceInfoWrapper>
          )}
        </PlanInfoWrapper>
        {nextChargeDate || status === 'not_paid' ? (
          <Text
            colorz='200'
            style={{
              width: 362,
              textAlign: 'center',
              margin: '0 auto',
              letterSpacing: 0.17,
              fontSize: 14,
            }}
          >
            {status === 'not_paid' && 'Abo abgelaufen'}
            {status !== 'not_paid' &&
              nextChargeDate &&
              `Die automatische Aboerneuerung ist eingeschaltet. Die nächste Abrechnung
            erfolgt am ${moment.unix(nextChargeDate._seconds).format('DD. MMMM YYYY')}`}
          </Text>
        ) : null}
        <ActionButtons hideCancel={status === 'not_paid'} />
      </Container>
    )
  }

  const getContent = () => {
    if (isLoading) return <Loader style={{ minHeight: 560 }} />
    if (user?.subscription.type === 'free') return renderFreeUser()
    if (user?.subscription?.status === 'trial') return renderTrialUser()
    return renderPaidUser()
  }

  return (
    <>
      {getContent()}{' '}
      <SubscriptionPlansModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  )
}

export default ProfileSettingsSelectedPlan
