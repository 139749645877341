export const checkListHeading =
  'Wie können Sie das Beste aus Ihrem Beratungsgespräch herausholen?'
export const checkListHeadText =
  'Um Ihr Beratungsgespräch so erfolgreich wie möglich zu gestalten, ist es hilfreich, einige Dinge vorzubereiten.'

export const checkListBodyText =
  'Diese Checkliste hilft Ihnen dabei, sich gut vorzubereiten, damit Sie das Beste aus dem Gespräch mit Ihrer Anwältin oder Ihrem Anwalt herausholen können.'

export const checkListItems = [
  'Kennen Sie den Sachverhalt?',
  'Wann laufen allfällige Fristen ab?',
  'Haben Sie alle relevanten Dokumente zur Hand?',
  'Welche Fragen möchten Sie klären?',
]
