import { ProfileImageProps } from './ProfileImage.types'
import React from 'react'
import { Image, NoImage } from './ProfileImage.styles'

const ProfileImage: React.FC<ProfileImageProps> = ({
  firstName,
  lastName,
  imageUrl,
  size,
  style,
  handleClick,
}) => {
  if (imageUrl)
    return <Image image={imageUrl} size={size} style={style} onClick={handleClick} />
  const initials = firstName && lastName ? `${firstName[0] + lastName[0]}` : 'NN'
  return (
    <NoImage size={size} style={style} onClick={handleClick}>
      {initials}
    </NoImage>
  )
}

export default ProfileImage
