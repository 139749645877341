import { useMutation, useQueryClient } from 'react-query'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { queryKeys } from 'queries/queryKeys'
import { useToast } from 'hooks/useToast'

import DocsAPi from 'api/docs.api'
import { IDocument } from 'types'

interface Data {
  pageParams: [string | undefined] | never[]
  pages: IDocument[][]
}

export const useDocumentsUpdate = () => {
  const {
    currentFolder: { type, id },
  } = useCurrentFolder('docs')
  const toast = useToast()

  const keys = [queryKeys.DOCUMENTS, { type, folderId: id }]
  const queryClient = useQueryClient()
  const mutation = useMutation(queryKeys.DOCUMENT, DocsAPi.update, {
    onMutate: async (documentToUpdate) => {
      await queryClient.cancelQueries(keys)

      const previousDocuments = queryClient.getQueryData<Data>(keys)

      const fullDocument = queryClient.getQueryData<IDocument>([
        queryKeys.DOCUMENT,
        documentToUpdate.id
      ])

      let tmpDocumentToUpdate:any = {
        ...fullDocument,
        ...documentToUpdate.data,
        isLoading: true,
      }

      if (previousDocuments) {
        queryClient.setQueryData<Data>(keys, (data) => {
          const pages = data?.pages ?? []
          const pageOne = pages[0] ?? []
          const index = pageOne.findIndex((e) => e.id === documentToUpdate.id)
          pageOne.splice(index, 1)
          pageOne.unshift(tmpDocumentToUpdate)
          pages[0] = pageOne
          return data ? { ...data, pages } : { pageParams: [], pages: [] }
        })
      }

      return { previousDocuments }
    },
    onSuccess: (updatedDoc, variables) => {
      queryClient.invalidateQueries(keys).then(() =>
      toast('success', 'Sie haben das Dokument erfolgreich bearbeitet')
      )
      queryClient.setQueryData([queryKeys.DOCUMENT, updatedDoc.id], updatedDoc)
    },
    onError: () => {
      toast('error', 'default')
    },
  })

  return mutation
}
