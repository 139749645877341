import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { StatusModal } from 'components/common'
import { IConfirmationPopupContent } from 'types'

interface Context {
  close: () => void
  popup: (data: ModifiedProps) => void
  setIsLoading: (val: boolean) => void
  isLoading: boolean
}

const PopupContext = createContext<Context | undefined>(undefined)

interface ModifiedProps extends IConfirmationPopupContent {
  onClose?: () => void
  onConfirm?: () => void
  onCancel?: () => void
  buttonWidth?: number
}

export const ConfirmPopupProvider: React.FC = ({ children }) => {
  const [popupData, setPopupData] = useState<undefined | ModifiedProps>(undefined)
  const [loading, setLoading] = useState(false)

  const close = useCallback(() => {
    setPopupData(undefined)
    setLoading(false)
  }, [])

  const renderPopup = useCallback(() => {
    if (!popupData) return null
    const { onClose, onConfirm, onCancel, ...rest } = popupData

    const handleClose = () => {
      if (onClose) onClose()
      setPopupData(undefined)
      setLoading(false)
    }

    return (
      <StatusModal
        open={true}
        handleClose={handleClose}
        handlePrimaryClick={onConfirm}
        handleSecondaryClick={onCancel}
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        {...rest}
      />
    )
  }, [popupData, loading])

  const popup = useCallback((props: ModifiedProps) => {
    setPopupData(props)
  }, [])

  const setIsLoading = useCallback((load: boolean) => {
    setLoading(load)
  }, [])

  useEffect(() => {
    setLoading(false)
    return () => setLoading(false)
  }, [])

  return (
    <PopupContext.Provider value={{ popup, setIsLoading, close, isLoading: loading }}>
      {children}
      {renderPopup()}
    </PopupContext.Provider>
  )
}

export const useConfirmPopup = () => {
  const context = useContext(PopupContext)

  if (!context) {
    throw new Error('useConfirmPopup must be used within a ConfirmPopupContext')
  }
  return context
}
