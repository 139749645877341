import { SettingsCardItemListProps } from './SettingsCardItem.types'
import React, { Fragment } from 'react'
import { SettingsCardItem } from './SettingsCardItem'
import { NoItemsAction } from 'components/common'
import { NoItemsWrapper } from './SettingsCardItem.styles'

export const SettingsCardItemList: React.FC<SettingsCardItemListProps> = ({
  documentSettingsItems,
  noItemsActionProps,
  ...otherProps
}) => {
  if (!documentSettingsItems.length) {
    return (
      <NoItemsWrapper>
        <NoItemsAction {...noItemsActionProps} />
      </NoItemsWrapper>
    )
  }

  return (
    <Fragment>
      {documentSettingsItems.map(item => (
        <SettingsCardItem key={item.id} item={item} {...otherProps} />
      ))}
    </Fragment>
  )
}
