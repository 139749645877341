import { styled, Box, Typography } from '@material-ui/core'

export const PageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.colorz.secondary[300],
  gridColumn: '1 / -1',
}))

export const LawFieldContainer = styled(Box)(({ theme }) => ({
  width: 304,
  height: 88,
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(4),
  cursor: 'pointer',
  transition: 'all .3s',
  borderRadius: 2,
  boxShadow: '0 2px 0 #F3EEEA',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
  '&:nth-child(-n+4)': {
    width: 304,
    height: 144,
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& img': {
      width: 40,
      marginBottom: theme.spacing(1),
    },
  },
}))

export const ImageWrapper = styled(Box)({})

export const Image = styled('img')({
  width: 32,
  marginRight: 24,
})

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  fontSize: 16,
  letterSpacing: 0.4,
  fontWeight: 500,
  position: 'relative',
}))

export const LawFieldsContainer = styled(Box)({})

export const FieldsWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '304px 304px 304px',
  gridTemplateRows: '10px 144px 10px 88px 88px 88px 88px',
  gridGap: '32px',
}))
