import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  FormControlLabel,
  FormControl,
  FormGroup
} from '@material-ui/core'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { primary, secondary } from 'config/themes/colors'

export const CheckboxButton = withStyles({
  root: {
    color: secondary.main,
    '&$checked': {
      color: primary.main,
    },
  },
  checked: {},
})((props: CheckboxProps)=> <Checkbox color='default' size='medium' {...props} />)

export { FormControlLabel, FormControl, FormGroup }
