import { POPUP_TC_KEEP_STYLES_ON_PASTE } from 'constants/confirmation-popups'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useState } from 'react'
import { Editor as IEditor } from 'tinymce'
import { unwrapHtmlElement } from 'utils'
import { getMostCommonStyles, renderEditorContent } from './services/text-editor'
import {
  ATTR_LIST_HEADING,
  ATTR_LIST_TYPE,
  classes,
  LIST_TYPE_HEADING,
} from './textEditor.constants'

export const useTextEditorHandlers = () => {
  const [deleteListOnNextBackSpace, setDeleteListOnNextBackSpace] = useState(false)
  const [backspaceTracker, setBackspaceTracker] = useState('')
  const [textSelected, setTextSelected] = useState('')
  const [backupStyles, setBackupStyles] = useState<string | null>(null)
  const { popup, close } = useConfirmPopup()

  const onBackspaceDown = (e: any, editor: IEditor): void => {
    const selectedElements = editor.selection.getSelectedBlocks()
    const currentNode = selectedElements[0]
    if (!currentNode) return
    const prevElement = currentNode.previousElementSibling
    const selectionContent = editor.selection.getSel()
    const listType = currentNode.getAttribute(ATTR_LIST_TYPE)

    // handle removal of marked element
    setBackspaceTracker(editor.selection.getNode().innerHTML)

    if (!listType && ['p', 'h1', 'h2', 'h3', 'h4'].includes(currentNode.nodeName)) {
      const currentIsEmpty = currentNode.textContent?.length === 0
      const nextElement = currentNode.nextElementSibling
      const prevElIsCustom = Boolean(prevElement?.getAttribute(ATTR_LIST_TYPE))
      const nextElIsCustom = Boolean(nextElement?.getAttribute(ATTR_LIST_TYPE))
      const prevOrNextIsCustom = prevElIsCustom || nextElIsCustom
      if (currentIsEmpty && prevOrNextIsCustom) renderEditorContent(editor)
      return
    }

    // CHECK IF ON NEXT CLICK ELEMENT WILL BE EMPTY
    const isEmptyWithoutSpan =
      currentNode.firstElementChild?.classList.contains(classes.list_item_span) &&
      currentNode.firstElementChild.textContent === currentNode?.textContent

    const wholeParagraphSelected =
      selectionContent &&
      currentNode.textContent &&
      selectionContent.toString() === currentNode.textContent

    const charetPositionedBeforeSpanElement = textSelected && deleteListOnNextBackSpace

    let listParagraphWillBeEmpty =
      wholeParagraphSelected ||
      isEmptyWithoutSpan ||
      (charetPositionedBeforeSpanElement && isEmptyWithoutSpan)

    if (listParagraphWillBeEmpty) {
      // REMAP LOWER LEVELS IF UPPER LEVEL IS DELETED
      if (listType === LIST_TYPE_HEADING) {
        const doc = editor.getDoc()
        const listElements = doc.querySelectorAll(`[${ATTR_LIST_HEADING}]`)
        let currentReached = false
        const removedElType = currentNode.getAttribute(ATTR_LIST_HEADING)

        for (let i = 0; i < listElements.length; i++) {
          const currentEl = listElements[i]
          if (currentEl === currentNode) {
            currentReached = true
            continue
          }
          if (!currentReached) continue
          if (currentEl.getAttribute(ATTR_LIST_HEADING) === removedElType) break
          const currElType = currentEl.getAttribute(ATTR_LIST_HEADING)
          const currInt = currElType ? parseInt(currElType) : 0
          if (!currInt || currInt === 1) break
          currentEl.setAttribute(ATTR_LIST_HEADING, String(currInt - 1))
        }
      }
      currentNode.remove()
      renderEditorContent(editor)
      // move caret to prev element
      if (prevElement) {
        editor.selection.select(prevElement, true)
        editor.selection.collapse(false)
      }
    } else if (
      // handle click before list number
      charetPositionedBeforeSpanElement &&
      !isEmptyWithoutSpan &&
      currentNode.firstElementChild?.classList.contains(classes.list_item_span)
    ) {
      const copyNode = document.createElement('div')
      copyNode.insertAdjacentHTML('beforeend', currentNode.innerHTML)
      if (copyNode.firstChild) copyNode.removeChild(copyNode.firstChild)
      const content = copyNode.innerHTML
      currentNode.remove()
      editor.insertContent(`<p>${content}</p>`)
      renderEditorContent(editor)
      setDeleteListOnNextBackSpace(false)
    } else {
      const selection = editor.selection.getSel()
      if (selection) {
        editor.selection.setContent('')
      }
    }
  }

  const onEnterDown = (e: any, editor: IEditor): void => {
    if (e.key !== 'Enter') return

    const selectedElements = editor.selection.getSelectedBlocks()
    const currentNode = selectedElements[0]
    if (!currentNode) return
    const prevElement = currentNode.previousElementSibling
    const prevIsCustom = prevElement?.hasAttribute(ATTR_LIST_TYPE)

    if (prevElement && prevIsCustom) {
      const container = document.createElement('div')
      container.insertAdjacentHTML('beforeend', prevElement.outerHTML)
      const copyPrevElement = container.firstElementChild!
      const spanEl = copyPrevElement.querySelector(`.${classes.list_item_span}`)
      const spanElTextContent = spanEl?.textContent

      const isPrevElementEmpty =
        spanEl &&
        typeof spanElTextContent === 'string' &&
        spanElTextContent === copyPrevElement?.textContent

      copyPrevElement.innerHTML = ''

      if (isPrevElementEmpty) prevElement.remove()

      let contentToInsert = copyPrevElement.outerHTML

      if (isPrevElementEmpty) {
        // get the font styles from the pasted document
        const cs = getMostCommonStyles(editor)

        if (cs.fontSize || cs.fontFamily || cs.marginLeft) {
          const el = window.document.createElement('p')
          el.style.fontFamily = cs.fontFamily ? cs.fontFamily : ''
          el.style.fontSize = cs.fontSize ? cs.fontSize : ''
          el.style.marginLeft = cs.marginLeft ? cs.marginLeft : ''
          contentToInsert = el.outerHTML
        }
      }

      editor.insertContent(contentToInsert)

      renderEditorContent(editor)
      const currEl = editor.selection.getNode()

      if (
        currEl?.getAttribute(ATTR_LIST_TYPE) ===
        copyPrevElement.getAttribute(ATTR_LIST_TYPE) &&
        currEl?.getAttribute(ATTR_LIST_HEADING) ===
        copyPrevElement.getAttribute(ATTR_LIST_HEADING)
      ) {
        editor.selection.select(currEl, true)
        editor.selection.collapse(false)
      }
    }
  }

  const onAnyCharDown = (e: any, editor: IEditor): void => {
    const selection = editor.selection.getSel()
    const selectedElements = editor.selection.getSelectedBlocks()
    const currentNode = selectedElements[0]
    if (!currentNode) return
    const currentIsCustom = currentNode?.hasAttribute(ATTR_LIST_TYPE)
    if (selection && currentIsCustom && e.key.length === 1) {
      const wholeParagraphIsSelected = selection.toString() === currentNode.textContent
      if (wholeParagraphIsSelected) {
        if (currentNode.firstElementChild?.classList.contains(classes.list_item_span)) {
          currentNode.remove()
          editor.insertContent(`<p></p>`)
          renderEditorContent(editor)
        }
      }
    }
  }

  const onMouseUp = (e: any, editor: IEditor): void => {
    const selection = editor.selection.getSel()
    const textSelected = selection?.toString()

    setTextSelected(textSelected ?? '')

    if (textSelected) {
      setBackspaceTracker(editor.selection.getNode().innerHTML)
    }
    if (selection) {
      const shouldDelete = selection.anchorOffset === 0 && selection.focusOffset === 0
      if (shouldDelete !== deleteListOnNextBackSpace) {
        setDeleteListOnNextBackSpace(shouldDelete)
      }
    }
  }

  const onTrimStyles = (editor: IEditor) => {
    if (!editor) return
    const body = editor.getBody()
    editor.selection.select(body)
    editor.execCommand('RemoveFormat')
    close()
  }

  const onModalClose = (editor: IEditor) => {
    if (!editor) return
    const body = editor.getBody()
    const html = body.innerHTML
    editor.setContent(html)
    close()
  }

  const onKeepStyles = (editor: IEditor) => {
    if (!editor) return
    const body = editor.getBody()

    const getElement = (parent: Element | null, type: 'ol' | 'p') => {
      if (!parent) return null

      switch (type) {
        case 'ol':
          const lastEl = parent.lastElementChild
          return lastEl && lastEl.nodeName === 'OL' ? lastEl : null
        case 'p':
          const firstEl = parent.firstElementChild
          return firstEl && ['P', 'H1', 'H2', 'H3', 'H4', 'H5'].includes(firstEl.nodeName)
            ? firstEl
            : null
        default:
          return null
      }
    }

    const modifyParagraph = (pEl: HTMLElement, level: string): Element => {
      pEl.childNodes.forEach(node => !node.textContent?.length && node.remove())
      const elInside = pEl.firstElementChild as HTMLElement

      const { paddingTop, paddingBottom } = pEl.style

      pEl.style.padding = ''

      if (paddingTop) pEl.style.paddingTop = paddingTop
      if (paddingBottom) pEl.style.paddingBottom = paddingBottom
      if (elInside) {
        if (elInside?.style?.fontSize) pEl.style.fontSize = elInside.style.fontSize
        if (elInside?.style?.fontFamily) pEl.style.fontFamily = elInside.style.fontFamily
        if (elInside?.style?.fontWeight) pEl.style.fontWeight = elInside.style.fontWeight
      }

      pEl.setAttribute(ATTR_LIST_TYPE, LIST_TYPE_HEADING)
      pEl.setAttribute(ATTR_LIST_HEADING, level)
      pEl.setAttribute('data-powerpaste', 'true')
      return pEl
    }

    const isPageBreak = (element: HTMLElement): boolean => {
      const nextSibling = element.nextElementSibling
      const nextSiblingChild = nextSibling?.firstElementChild
      const nextSiblingChildChild = nextSibling?.firstElementChild?.firstElementChild

      return (
        element?.textContent?.length === 0 &&
        nextSibling?.textContent?.length === 0 &&
        nextSiblingChild?.nodeName === 'SPAN' &&
        nextSiblingChildChild?.nodeName === 'SPAN' &&
        nextSiblingChildChild.children.length === 0 &&
        !!nextSiblingChildChild.getAttribute('style')?.endsWith('position:relative;')
      )
    }

    const replaceWithPagebreak = (element: HTMLElement) => {
      const nextSibling = element.nextElementSibling
      if (nextSibling) {
        const pageBreakEl = document.createElement('img')
        const div = document.createElement('div')
        pageBreakEl.classList.add('mce-pagebreak')
        pageBreakEl.src =
          'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
        pageBreakEl.setAttribute('data-mce-placeholder', '')
        pageBreakEl.setAttribute('data-mce-selected', '1')
        div.setAttribute('style', 'page-break-before: always;')
        div.appendChild(pageBreakEl)
        nextSibling.replaceWith(div)
      }
      element.remove()
    }

    const allLi = body.querySelectorAll<HTMLElement>('li[aria-level]')

    const level1: HTMLElement[] = []
    const level2: HTMLElement[] = []
    const level3: HTMLElement[] = []

    allLi.forEach(li => {
      const parent = li.parentElement
      const isValid =
        (parent?.childElementCount === 1 &&
          parent.firstChild &&
          ['OL', 'LI'].includes(parent?.firstChild?.nodeName)) ||
        (parent?.childElementCount === 2 &&
          parent.firstChild?.nodeName === 'LI' &&
          parent.lastChild?.nodeName === 'OL')

      if (li.parentElement?.nodeName === 'OL' && isValid) {
        const ariaLevel = li.getAttribute('aria-level')
        if (ariaLevel === '1') level1.push(li)
        if (ariaLevel === '2') level2.push(li)
        if (ariaLevel === '3') level3.push(li)
      }
    })

    const allLevels = [level3, level2, level1]

    allLevels.forEach((level, i) =>
      level.forEach(li => {
        let pElement = li.firstElementChild
        if (pElement)
          pElement = modifyParagraph(pElement as HTMLElement, (3 - i).toString())
        unwrapHtmlElement(li)
      })
    )

    const allOls = Array.from(body.children).filter(
      childNode =>
        childNode.nodeName === 'OL' && childNode.parentElement?.nodeName === 'BODY'
    )

    const allPels = body.querySelectorAll('p')

    allOls.forEach(childNode => {
      const headingElements = []

      const olLvl_1 = childNode
      const level1HeadingEl = getElement(olLvl_1, 'p')
      if (level1HeadingEl) headingElements.push(level1HeadingEl)

      const olLvl_2 = getElement(olLvl_1, 'ol')
      const level2HeadingEl = getElement(olLvl_2, 'p')
      if (level2HeadingEl) headingElements.push(level2HeadingEl)

      const olLvl_3 = getElement(olLvl_2, 'ol')
      const level3HeadingEl = getElement(olLvl_3, 'p')
      if (level3HeadingEl) headingElements.push(level3HeadingEl)

      olLvl_1.replaceWith(...headingElements)
    })

    allPels.forEach(pEl => {
      if (!pEl.textContent?.length && pEl.firstChild?.nodeName === 'B') {
        pEl.style.marginTop = '0'
      }

      if (isPageBreak(pEl)) {
        replaceWithPagebreak(pEl)
      }
    })

    const allSpanEls = body.querySelectorAll('span')

    allSpanEls.forEach(spanEl => {
      // Let empty space filling spanElements keep styles
      if (spanEl.textContent && spanEl.textContent.length === 0) {
        const nextSibling = spanEl.nextElementSibling
        const parentElement = spanEl.parentElement
        let styles = ''
        if (nextSibling && nextSibling.nodeName === 'SPAN') {
          styles = nextSibling.getAttribute('style') ?? ''
        } else {
          styles = parentElement?.getAttribute('style') ?? ''
        }

        if (styles) spanEl.setAttribute('style', styles)
      }
    })

    renderEditorContent(editor)
    onModalClose(editor)
    close()
  }

  const onPaste = (e: any, editor: IEditor, cb?: () => void) => {
    const body = editor.getBody()
    const bodyTextContent = body.textContent
    const selectedContent = editor.selection.getContent()

    if (!bodyTextContent?.length || editor.getContent() === selectedContent) {
      return popup({
        ...POPUP_TC_KEEP_STYLES_ON_PASTE,
        onClose: () => onModalClose(editor),
        onCancel: () => onTrimStyles(editor),
        onConfirm: () => onKeepStyles(editor),
      })
    }

    const getElement = (parent: Element | null, type: 'ol' | 'p') => {
      if (!parent) return null
      switch (type) {
        case 'ol':
          const lastEl = parent.lastElementChild
          return lastEl && lastEl.nodeName === 'OL' ? lastEl : null
        case 'p':
          const firstEl = parent.firstElementChild?.firstElementChild
          return firstEl && firstEl.nodeName === 'P' ? firstEl : null
        default:
          return null
      }
    }

    const allOls = body.querySelectorAll<HTMLOListElement>('ol')

    allOls.forEach(childNode => {
      if (childNode.nodeName === 'OL' && childNode?.parentElement?.nodeName === 'BODY') {
        const headingElements = []

        const olLvl_1 = childNode
        const level1HeadingEl = getElement(olLvl_1, 'p')
        if (level1HeadingEl) headingElements.push(level1HeadingEl)

        const olLvl_2 = getElement(olLvl_1, 'ol')
        const level2HeadingEl = getElement(olLvl_2, 'p')
        if (level2HeadingEl) headingElements.push(level2HeadingEl)

        const olLvl_3 = getElement(olLvl_2, 'ol')
        const level3HeadingEl = getElement(olLvl_3, 'p')
        if (level3HeadingEl) headingElements.push(level3HeadingEl)

        olLvl_1.remove()
      }
    })
  }

  const onChange = (e: any, editor: IEditor) => {
    const olInserted = e?.originalEvent?.command === 'InsertOrderedList'

    if (olInserted) {
      const cssProps = {
        fontFamily: 'inherit',
        fontSize: 'inherit',
      }

      const currentNode = editor.selection.getNode()
      const selectedBlocks = editor.selection.getSelectedBlocks()

      if (['UL', 'OL'].includes(currentNode.nodeName)) {
        const spanEls = currentNode.querySelectorAll('span')
        const spanEl = spanEls.length ? spanEls[spanEls.length - 1] : null
        if (spanEl) {
          cssProps.fontFamily = spanEl.style.fontFamily
          cssProps.fontSize = spanEl.style.fontSize
        }
        currentNode.querySelectorAll('li').forEach(liItem => {
          liItem.style.fontFamily = cssProps.fontFamily
          liItem.style.fontSize = cssProps.fontSize
        })
        if (backupStyles) {
          currentNode.setAttribute('data-backup-styles', backupStyles)
          setBackupStyles(null)
        }
      } else if (backupStyles) {
        selectedBlocks.forEach(el => el.setAttribute('style', backupStyles))
        setBackupStyles(null)
      }
    }
  }

  const onBeforeExecCommand = (e: any, editor: IEditor) => {
    const olInserted = e.command === 'InsertOrderedList'
    const selectedBlocks = editor.selection.getSelectedBlocks()
    const currentNode = editor.selection.getNode()

    if (olInserted && selectedBlocks.length) {
      const convertingFromListToP = ['UL', 'OL'].includes(currentNode.nodeName)
      const currentNStyles = currentNode.getAttribute('data-backup-styles')
      const backupStyles = convertingFromListToP
        ? currentNStyles
        : selectedBlocks[0].getAttribute('style')
      setBackupStyles(backupStyles)
    }
  }

  const onAfterExecCommand = (e: any, editor: IEditor) => {
    if (e.command === 'mceInsertContent') {
      const node = editor.selection.getNode() as HTMLElement

      if (
        node?.classList.contains('mce-pagebreak') ||
        node?.firstElementChild?.classList.contains('mce-pagebreak') ||
        node?.firstElementChild?.firstElementChild?.classList.contains('mce-pagebreak')
      )
        node.style.pageBreakAfter = 'auto'
    }
  }

  return {
    deleteListOnNextBackSpace,
    backspaceTracker,
    textSelected,
    backupStyles,
    setBackupStyles,
    setDeleteListOnNextBackSpace,
    setBackspaceTracker,
    setTextSelected,
    onBackspaceDown,
    onEnterDown,
    onAnyCharDown,
    onMouseUp,
    onPaste,
    onChange,
    onBeforeExecCommand,
    onAfterExecCommand,
  }
}
