import { DocumentSettingsPageHeaderProps } from './DocumentSettingsPageHeader.types'
import React from 'react'
import { Container, TextBold, ActionBtn } from './DocumentSettingsPageHeader.styles'

export const DocumentSettingsPageHeader: React.FC<DocumentSettingsPageHeaderProps> = ({
  title,
  actionText,
  handleActionClick,
}) => {
  return (
    <Container>
      <TextBold>{title}</TextBold>
      {Boolean(handleActionClick && actionText) && (
        <ActionBtn onClick={handleActionClick}>+ {actionText}</ActionBtn>
      )}
    </Container>
  )
}
