import { Question } from 'types'

export const findSubquestionById = (
  question: Question,
  subquestionId: string
): Question | undefined  => {
  if (!question.options) return undefined
  let _theSubquestion: Question | undefined = undefined
    for (let option of question.options) {
      if (_theSubquestion) break
      for (let subquestion of option.subquestions) {
        if (subquestion.id === subquestionId) {
          _theSubquestion = subquestion
          break
        }
      }
    }
  
  return _theSubquestion
}
