import { styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const Text = styled(Typography)(({ theme }) => ({
  lineHeight: 1.57,
  letterSpacing: 0.2,
  fontSize: 14,
  color: theme.colorz.text.light,
  textAlign: 'center',
  marginTop: 30,
}))

export const URL = styled('span')(({ theme }) => ({
  color: theme.colorz.primary.main,
  fontWeight: 500,
  marginLeft: 2,
  fontFamily: 'inherit',
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    color: theme.colorz.primary.dark,
  },
}))
