import React from 'react'
import parser from 'html-react-parser'
import {
  Container,
  HeadingContainer,
  DocumentNameInput,
  QWrapper,
  MiniViewWrapper,
  MiniViewContainer,
  QDocViewContainer,
} from './DocumentGenerationResult.styles'
import { DocumentGenerationEditor } from 'components/features/documentGeneration/views/DocumentGenerationResult/DocumentGenerationEditor'

interface Props {
  onNameChange: React.ChangeEventHandler<HTMLInputElement>
  onNameBlur: () => void
  htmlData: string
  name: string
}

const DocumentGenerationResultComponent: React.FC<Props> = ({
  onNameChange,
  onNameBlur,
  htmlData,
  name,
}) => {
  const parsedHtml = parser(htmlData)
  return (
    <Container>
      <HeadingContainer>
        <DocumentNameInput
          value={name}
          onChange={onNameChange}
          onBlur={onNameBlur}
          name="name"
          error={!name.length}
          placeholder="Dokument benennen"
        />
      </HeadingContainer>
      <QWrapper>
        <MiniViewWrapper>
          <MiniViewContainer>{parsedHtml}</MiniViewContainer>
        </MiniViewWrapper>
        <QDocViewContainer>
          <DocumentGenerationEditor />
        </QDocViewContainer>
      </QWrapper>
    </Container>
  )
}

export default DocumentGenerationResultComponent
