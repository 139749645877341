import TemplateApi from 'api/templates.api'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useToast } from 'hooks/useToast'
import { queryKeys } from 'queries/queryKeys'
import { useUser } from 'queries/user/useUser'
import { useMutation, useQueryClient } from 'react-query'
import { ITemplate } from 'types'

interface Data {
  pageParams: [string | undefined] | never[]
  pages: ITemplate[][]
}

export const useTemplatesDuplicate = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { currentFolder } = useCurrentFolder('templates')
  const { type } = currentFolder
  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice

  const mutation = useMutation(queryKeys.TEMPLATES, (payload: any) => TemplateApi.duplicate({id: payload.id}, docXMicro), {
    onMutate: async (newTemplate: ITemplate) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([
        queryKeys.TEMPLATES,
        { type, folderId: currentFolder.id },
      ])

      // Snapshot the previous value
      const previousTemplates = queryClient.getQueryData<Data>([
        queryKeys.TEMPLATES,
        { type, folderId: currentFolder.id },
      ])

      let tmpNewTemplate = {
        ...newTemplate,
        id: `tmp_${newTemplate.id}`,
        isLoading: true,
        name: `Kopie von ${newTemplate.name}`,
      }

      // Optimistically update to the new value
      if (previousTemplates) {
        queryClient.setQueryData<Data>(
          [queryKeys.TEMPLATES, { type, folderId: currentFolder.id }],
          (data) => {
            const pages = data?.pages ?? []
            const pageOne = pages[0] ?? []
            pageOne.unshift(tmpNewTemplate)
            pages[0] = pageOne
            return data ? { ...data, pages } : { pageParams: [], pages: [] }
          }
        )
      }

      // Return a context object with the snapshotted value
      return { previousTemplates }
    },
    onSuccess: (docFile, variables) => {
       queryClient
        .invalidateQueries([
          queryKeys.TEMPLATES,
          { type, folderId: currentFolder.id },
        ])
        .then(() =>
        toast('success', 'Sie haben das Vorlagen erfolgreich dupliziert')
        )
    },
    onError: (docFile, variables) => {
      queryClient
        .invalidateQueries([
          queryKeys.TEMPLATES,
          { type, folderId: currentFolder.id },
        ])
        .then(() => toast('error', 'default'))
    },
  })
  return mutation
}
