import { IQuestionLocation } from 'types'
import { unwrapHtmlElement } from './unwrapHtmlElement'
import { removeHighlightItemByLocationId } from './removeHighlightItemsByLocations'
import { Editor } from 'tinymce'

export const removeHighlightItemsByQuestionId = (
  qid: string,
  editor: Editor
): void => {
  if (!editor) return
  const dom = editor.getBody()
  const spans = dom.querySelectorAll(`[data-qid="${qid}"]`)
  const spanParent = dom.querySelectorAll(`[data-parentid*="${qid}"]`)
  for (let s of [...spans, ...spanParent]) {
    unwrapHtmlElement(s)
  }
}

type ArgType = IQuestionLocation | string

export const removeHighlightItemsByQuestions = (
  questions: ArgType[],
  editor: Editor
): void =>
  questions.forEach((qItem) => {
    const id = typeof qItem === 'string' ? qItem : (qItem.id as string)
    removeHighlightItemByLocationId(id, editor)
  })
