import { IReminder } from 'types'
import Api from './api'

export interface IReminderRequestApi {
  id?: string
  alert: IReminder['alert']
  deadlineTime: Date
  reminderName: string
  reminderMessage?: string
  entityId?: string
}

class RemindersApi extends Api {
  constructor() {
    super('/reminders/documents')
  }

  get = async (): Promise<IReminder[]> => {
    const res = await this.api().get('/')
    return res.data.data
  }

  getOne = async (id: string): Promise<IReminder> => {
    const res = await this.api().get(`/${id}`)
    return res.data.data
  }

  create = async (data: IReminderRequestApi): Promise<IReminder> => {
    const res = await this.api().post('/', { ...data, reminderMessage: '' })
    return res.data.data
  }

  update = async (data: IReminderRequestApi): Promise<IReminder> => {
    const { id, ...rest } = data
    if (!id) return Promise.reject('id is required')
    const res = await this.api().patch(`/${id}`, rest)
    return res.data.data
  }

  delete = async (id: string): Promise<string> => {
    await this.api().delete(`/${id}`)
    return id
  }
}

const remindersApi = new RemindersApi()
export default remindersApi
