import { styled, Typography } from '@material-ui/core'
import { CustomButton } from 'components/common'
import React from 'react'
import image from './image.png'

const Container = styled('div')({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  background: '#E5E5E5',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 3000,
})

const ContentWrapper = styled('div')({
  width: '90vw',
  maxWidth: 300,
  display: 'flex',
  flexDirection: 'column',
})

const Img = styled('img')({
  marginRight: 'auto',
  alignSelf: 'flex-start',
  marginBottom: 36,
})

const Text = styled(Typography)({
  fontWeight: 500,
  fontFamily: 'Heebo',
  fontSize: 18,
  letterSpacing: '0.004em',
  lineHeight: 1.5,
  textAlign: 'left',
  width: '100%',
  marginBottom: '5vh',
})

const MobileInfoScreen: React.FC = () => {
  const [show, setShow] = React.useState(window.innerWidth < 500)
  //   const { push } = useHistory()

  if (!show) return null

  return (
    <Container>
      <ContentWrapper>
        <Img src={image} />
        <Text>
          Bitte verwenden Sie Ihren Computer, um die CASUS-App zu öffnen. Wir arbeiten
          zurzeit an der Verbesserung der Mobile-Ansicht.
        </Text>
        <CustomButton
          btntype='primary'
          style={{ marginBottom: 16 }}
          onClick={() => (window.location.href = 'https://casus.ch')}
        >
          Zur Webseite
        </CustomButton>
        <CustomButton btntype='secondary' onClick={() => setShow(false)}>
          Zur Desktop-App
        </CustomButton>
      </ContentWrapper>
    </Container>
  )
}

export default MobileInfoScreen
