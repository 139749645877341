import { ICompanyInfo, User, UserResponse } from 'types'

export const prettyUser = (user: UserResponse): User => {
  const { companyInfo, jobPosition } = user
  const { id, name, website, employeeCount, field } = companyInfo
  const company: ICompanyInfo = {
    id,
    name: name ?? '',
    website: website ?? '',
    employeeCount: employeeCount ?? '',
    field: field ?? '',
  }
  return {
    ...user,
    jobPosition: jobPosition ?? '',
    companyInfo: company,
  }
}
