import { styled, Theme, Typography } from '@material-ui/core'
import React from 'react'
import check_svg from './check.svg'
import plus_svg from './plus.svg'

interface TEl {
  theme: Theme
  light?: 0 | 1
}

const Text = styled(Typography)(({ theme, light }: TEl) => ({
  color: theme.colorz.secondary[light ? 300 : 'main'],
  lineHeight: 1.6,
}))

const UL = styled('ul')({ margin: 0 })

interface LiEl {
  icon: string // 'check' | 'plus'
}
const LI = styled('li')(({ icon }: LiEl) => ({
  listStyleImage: `url('${icon === 'check' ? check_svg : plus_svg}')`,
  '&:not(:last-child)': { marginBottom: 8 },
}))

export interface Props {
  checklist: { icon: 'check' | 'plus' | string; text: string }[]
  containerStyles?: React.CSSProperties
  textStyles?: React.CSSProperties
}

const Checklist: React.FC<Props> = ({
  checklist,
  containerStyles = { paddingLeft: 25 },
  textStyles,
}) => {
  return (
    <UL style={containerStyles}>
      {checklist.map((c, i) => (
        <LI key={i} icon={c.icon}>
          <Text
            light={c.icon === 'check' ? 1 : 0}
            style={{ fontWeight: c.icon === 'check' ? 400 : 500, ...textStyles }}
          >
            {c.text}
          </Text>
        </LI>
      ))}
    </UL>
  )
}

export default Checklist
