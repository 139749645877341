import React from 'react'
import { FooterNavigationProps } from './FooterNavigation.types'
import { Container } from './FooterNavigation.styles'
import { CustomButton, ButtonSquare, Icon } from '../../../components/common'

const FooterNavigation: React.FC<FooterNavigationProps> = ({
  handleForwardClick,
  handleBackClick,
  isLoading,
}) => {
  return (
    <Container>
      <ButtonSquare
        btntype='transparent'
        onClick={handleBackClick}
        size={40}
        style={{ marginRight: 16, color: '#fff' }}
        disabled={isLoading}
      >
        <Icon iconName='arrow-left' fill='#fff' />
      </ButtonSquare>
      <CustomButton
        onClick={handleForwardClick}
        btntype='primary'
        disabled={isLoading}
        width={182}
      >
        {isLoading ? 'Speichern...' : 'Abschliessen'}
      </CustomButton>
    </Container>
  )
}

export default FooterNavigation
