import React, { useEffect, useCallback } from 'react'
import QuestionTextComponent from './QuestionText.component'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { addLocationToQuestion } from 'pages/Authenticated/Template/views/TemplateEdit/question.utils'
import { generateNewLocationObject } from 'pages/Authenticated/Template/views/TemplateEdit/services'
import { useUser } from 'queries/user/useUser'

const QuestionText: React.FC = () => {
  const { set, textSelectionIsActive, selectionData, question } = useTemplateCreation()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice
  const { id, start, end, currentQuestionId: qid, locationId } = selectionData

  const addSelectedLocation = useCallback(
    (id: string, start: number, end: number, qid: string, locId?: string) => {
      set({
        question: addLocationToQuestion(
          question,
          generateNewLocationObject(id, start, end, qid, locId, docXmicro)
        ),
        selectionData: { id: null, start: null, end: null, currentQuestionId: null, locationId: null },
      })
    },
    [set, question, docXmicro]
  )

  useEffect(() => {
    if (start !== null && end && id && qid) {
      //@ts-ignore
      addSelectedLocation(id, start, end, qid, locationId)
    }
  }, [id, start, end, qid, locationId, addSelectedLocation])

  const onTextSelectionToggle = (): void => {
    set({ textSelectionIsActive: !textSelectionIsActive })
  }

  return (
    <QuestionTextComponent
      textSelectionIsActive={textSelectionIsActive}
      toggleTextSelection={onTextSelectionToggle}
    />
  )
}

export default QuestionText
