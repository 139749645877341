import { ProfileImageModalComponent } from './ProfileImageModal.types'
import React, { useState, useEffect, useRef } from 'react'
import Modal from 'components/common/Modals/StandardModal/StandardModal'
import SettingsUploadImage from './SettingsUploadImage'
import AdjustProfileImage from './AdjustProfileImage'
import { AvatarEditor } from 'components/common/ImageEditor/ImageEditor'
import { Container } from './ProfileImageModal.styles'

const ProfileImageModal: React.FC<ProfileImageModalComponent> = ({
  imageUrl,
  isOpen,
  handleClose,
  firstName,
  lastName,
  isLoading,
  handleConfirmUpload,
}) => {
  let theComponent: React.ReactNode
  const [showImageEditor, setShowImageEditor] = useState(Boolean(imageUrl))
  const [file, setFile] = useState<File | null>(null)
  const ref = useRef<AvatarEditor>(null)

  const actionBtnText = 'Bestätigen'

  useEffect(() => {
    if (file && !showImageEditor) setShowImageEditor(true)
  }, [file, showImageEditor])

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (!ref.current) return
    const canvasScaled = ref.current.getImageScaledToCanvas()
    const base64Data = canvasScaled.toDataURL('png', 0.5)
    handleConfirmUpload(base64Data.split('base64,')[1].trim())
  }

  const handleNewUploadClick = (): void => {
    setShowImageEditor(false)
    if (file) setFile(null)
  }

  const closeModal = () => {
    handleClose()
    setFile(null)
  }

  if (!file) {
    theComponent = (
      <SettingsUploadImage
        firstName={firstName}
        lastName={lastName}
        imageUrl={imageUrl}
        setFile={setFile}
      />
    )
  } else {
    theComponent = (
      <AdjustProfileImage
        imageUrl={file ? file : imageUrl}
        handleNewUploadClick={handleNewUploadClick}
        editorRef={ref}
      />
    )
  }

  return (
    <Modal
      open={isOpen}
      handleClose={closeModal}
      title='Profilbild hochladen'
      handlePrimaryClick={handleConfirm}
      primaryButtonText={actionBtnText}
      primaryButtonDisabled={!showImageEditor || isLoading}
    >
      <Container>{theComponent}</Container>
    </Modal>
  )
}

export default ProfileImageModal
