import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { useDocumentsCreate } from 'queries/documents'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import SidebarContainer from 'components/Sidebar/Sidebar.container'
import FolderTree from './SidebarFolderTree'

const DocumentsSidebar: React.FC = () => {
  const { push } = useHistory()
  const create = useDocumentsCreate()
  const { check } = useSubscriptionCheck()

  const [searchValue, setSearchValue] = useState('')

  const onCreateDocument = (): void => {
    if (check()) push('/dokument/neu')
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSearchValue(e.target.value)

  return (
    <SidebarContainer
      actionButtonText='Neues Dokument'
      searchValue={searchValue}
      isLoading={create.isLoading}
      handleActionClick={onCreateDocument}
      handleSearchChange={handleSearchChange}
    >
      <FolderTree />
    </SidebarContainer>
  )
}

export default DocumentsSidebar
