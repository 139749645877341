interface FormFieldCommon {
  label: string
  placeholder: string
  type: string
  required: boolean
}

interface FormFieldStepOne extends FormFieldCommon {
  name: 'chancellery' | 'website'
}

interface FormFieldStepTwo extends FormFieldCommon {
  name: 'firstName' | 'lastName'
}

interface FormFieldStepThree extends FormFieldCommon {
  name: 'bankAccountOwnerName'
}

export const stepOne: FormFieldStepOne[] = [
  {
    label: 'Name Ihrer Kanzlei *',
    placeholder: 'z.B. Kanzlei Casus',
    name: 'chancellery',
    type: 'text',
    required: true,
  },
  {
    label: 'Webseite Ihrer Kanzlei',
    placeholder: 'www.beispiel.ch',
    name: 'website',
    type: 'text',
    required: true,
  },
]

export const stepTwo: FormFieldStepTwo[] = [
  {
    label: 'Ihr Vorname*',
    placeholder: 'z.B. Marina',
    name: 'firstName',
    type: 'text',
    required: true,
  },
  {
    label: 'Ihr Nachname*',
    placeholder: 'z.B. Wagner',
    name: 'lastName',
    type: 'text',
    required: true,
  },
]

export const stepThree: FormFieldStepThree[] = [
  {
    label: 'Name und Nachname des Kontoinhabers*',
    placeholder: 'z.B. Wagner',
    name: 'bankAccountOwnerName',
    type: 'text',
    required: true,
  },
]

export const footerText =
  'Wir interessieren uns für diese Informationen, um Ihnen die ersten Schritte mit CASUS zu erleichtern.'

export const formTitle = 'Registrierung'

interface Iheadings {
  [key: string]: string
}

export const headings: Iheadings = {
  '0': 'Erzählen Sie uns von Ihrem Unternehmen',
  '1': 'Erzählen Sie uns von sich',
  '2': 'Profilbild hochladen',
  '3': 'Fügen Sie Ihre Bankverbindung hinzu',
}
