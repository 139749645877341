import { StripeWithoutCardSelectionComponentProps } from '../../stripe.types2'
import React from 'react'
import Card from '../Card/Card'

import Header from '../Header/Header'
import Spinner from '../Spinner/Spinner'

import { TextWithUrl } from 'components/common'
import { Container } from '../common.styles'

const StripeCard: React.FC<StripeWithoutCardSelectionComponentProps> = ({
  stripe,
  name,
  isLoading,
  charging,
  error,
  amount,
  heading,
  descriptionText,
  children,
  handleSubmit,
  handleNameChange,
  handleBackClick,
}) => {
  const handleUrlClick = () => {
    window.open('https://casus.ch/privacy-policy.html', '_blank')
  }

  if (isLoading) return <Spinner />

  return (
    <Container>
      <Header heading={heading} descriptionText={descriptionText} />
      <Card
        stripe={stripe}
        name={name}
        charging={charging}
        error={error}
        amount={amount}
        children={children}
        handleNameChange={handleNameChange}
        handleSubmit={handleSubmit}
        handleBackClick={handleBackClick}
      />
      <TextWithUrl
        url='/'
        urlText='Datenschutzerklärung'
        text='Mehr über Zahlungen und Sicherheit finden Sie in unserer'
        style={{ marginTop: 16 }}
        handleUrlClick={handleUrlClick}
      />
    </Container>
  )
}

export default StripeCard
