import {
  ILinkItemEl,
  ISidebarContainerEl,
  ILinkTextEl,
  SidebarVariants,
} from './Sidebar.types'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Box, Theme, Typography, styled } from '@material-ui/core'

export const SidebarContainer = styled(Box)(
  ({ theme, variant }: ISidebarContainerEl) => ({
    width: 336,
    backgroundColor:
      variant === 'main' ? theme.colorz.secondary.main : theme.colorz.brand.main,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    height: '100vh',
    position: 'fixed',
    paddingRight: 2,
  })
)

export const PaddingWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 5),
}))

export const Container = styled(Box)(
  ({ theme, sidebarvariant }: { theme: Theme; sidebarvariant?: SidebarVariants }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(sidebarvariant === 'main' ? 2 : 3, 5),
    marginBottom: theme.spacing(4),
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '.3em',
      backgroundColor: theme.colorz.secondary.main,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colorz.secondary[100],
      outline: 'none',
      borderRadius: 400,
    },
    'scroll-behavior': 'smooth',
  })
)

export const UserCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  width: 336,
  backgroundColor: theme.colorz.secondary.main,
  alignItems: 'center',
  padding: theme.spacing(2.5, 4),
  borderTop: '1px solid #1A2D53 ',
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    opacity: 1,
  },
}))

export const UserInfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[100],
  '&:last-child': {
    opacity: 0.8,
  },
}))

export const Logo = styled('img')({
  width: 110,
  height: 24,
  marginBottom: 32,
  cursor: 'pointer',
})

const getLinkItemStyles = (
  theme: Theme,
  isactive: number,
  sidebarvariant: SidebarVariants
): CSSProperties => {
  switch (sidebarvariant) {
    case 'main':
      return {
        borderBottom: '2px solid transparent',
        borderBottomColor: isactive ? theme.colorz.primary.main : 'transparent',
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      }
    default:
      return {}
  }
}

export const LinkItem = styled(Box)(
  ({ theme, isactive, sidebarvariant }: ILinkItemEl) => ({
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    width: 'max-content',
    ...getLinkItemStyles(theme, isactive, sidebarvariant),
  })
)

const getLinkTextStyles = (
  theme: Theme,
  sidebarvariant: SidebarVariants,
  isactive: number
): CSSProperties => {
  let style = {}
  if (sidebarvariant === 'main')
    return {
      color: theme.colorz.brand.light,
    }
  if (sidebarvariant === 'beige' && isactive) {
    return {
      color: theme.colorz.secondary.main,
    }
  }
  if (sidebarvariant === 'beige' && !isactive) {
    return {
      color: theme.colorz.primary.main,
      '&:hover': {
        color: theme.colorz.secondary.main,
      },
    }
  }
  return style
}

export const LinkText = styled(Typography)(
  ({ theme, sidebarvariant, isactive }: ILinkTextEl) => ({
    fontWeight: 700,
    ...getLinkTextStyles(theme, sidebarvariant, isactive),
  })
)
