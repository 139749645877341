import React, {
  useMemo,
  // useContext, useEffect
} from 'react'
import { v4 as uuid } from 'uuid'
// import { PAPER_NAMES } from 'utilities/hardcoded/documentSettings'

// import { WizardContext } from 'TemplateCreation-DocumentGeneration/WizardContext'

import Page from './Page'

const classes = {
  section: 'Wizard-Editor-Section',
}

export const Section = React.memo(props => {
  // const wizardContext = useContext(WizardContext)

  // const {
  //   // updateDataStructure,
  //   // parseDataStructure,
  // } = wizardContext
  const { settings, dataStructure } = props
  const { id } = settings

  const pageStructures = useMemo(() => [{ structure: dataStructure, key: uuid() }], [dataStructure]) // divide into pages when implemented

  return (
    <div id={`section-${id}`} className={classes.section}>
      {pageStructures.map(({ key, structure }) => (
        <Page key={key}>{/* {structure} */}</Page>
      ))}
    </div>
  )
})

Section.displayName = 'Wizard-Editor-Section'

export default Section
