import { IConfirmationPopupContent, IStep } from "types";

export const steps: IStep[] = [
  { step: 1, label: "Dokument auswählen" },
  { step: 2, label: "Fragen beantworten" },
  { step: 3, label: "Dokument verwenden" },
];

export const onBoardingSteps: IStep[] = [
  { step: 1, label: "Wählen Sie ein Dokument aus" },
  { step: 2, label: "Beantworten Sie Schritt für Schritt Fragen" },
  { step: 3, label: "Downloaden und teilen Sie Ihr Dokument" },
];

export const onBoardingHeading = "Erstellen Sie professionelle Rechtsdokumente";
export const headingCreate = "Dokument erstellen";
export const headingEdit = "Dokument bearbeiten";
export const headingSaving = "Dokument wird gespeichert";

export const lastStepContent = {
  heading: "Ihr Dokument wurde erfolgreich erstellt.",
  headingEdited: "Ihr Dokument wurde erfolgreich geändert.",
  headingDownload: "Dokument wird heruntergeladen...",
  // primaryButton: 'Vorschau & Herunterladen',
  primaryButton: "Speichern",
  secondaryButton: "Zum Aktenschrank",
};

export const POPUP_ON_CLOSE_IF_EDITED_DG: IConfirmationPopupContent = {
  heading: "Möchten Sie Ihre Änderungen speichern?",
  status: "warning",
  primaryButtonText: "Ja",
  secondaryButtonText: "Nein",
};

export const POPUP_ON_CLOSE_IF_CREATED_DG: IConfirmationPopupContent = {
  heading: "Möchten Sie ein neues Dokument mit bisherigen Antworten erstellen?",
  status: "warning",
  primaryButtonText: "Ja",
  secondaryButtonText: "Nein",
};
