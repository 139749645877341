import React from 'react'

import { CustomButton } from 'components/common'
import { Container, Image, Heading, Subtitle } from './LastStep.styles'

import last_step_svg from 'assets/icons/book-lawyer/last_step.svg'

interface Props {
  handleClick: React.MouseEventHandler<HTMLButtonElement>
}

const LastConsultStep: React.FC<Props> = ({ handleClick }) => {
  return (
    <Container>
      <Image src={last_step_svg} alt='svg image' />
      <Heading>Ihr Beratungstermin ist bestätigt!</Heading>
      <Subtitle variant='body1'>
        Wir haben Ihnen ein E-Mail mit allen Informationen geschickt.
      </Subtitle>
      <CustomButton onClick={handleClick} btntype='secondary' width={194}>
        Zur Rechtsberatung
      </CustomButton>
    </Container>
  )
}

export default LastConsultStep
