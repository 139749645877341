import { IReminder } from 'types'
import { useMutation, useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import { queryKeys } from 'queries/queryKeys'
import RemindersApi from 'api/reminders'

export const useReminderUpdate = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const mutation = useMutation(queryKeys.DOCUMENT_REMINDERS, RemindersApi.update, {
    onSuccess: (reminder, variables) => {
      queryClient.setQueryData([queryKeys.DOCUMENT_REMINDERS, reminder.id], reminder)
      queryClient.setQueryData<IReminder[]>(queryKeys.DOCUMENT_REMINDERS, data =>
        (data ?? []).map(r => (r.id === reminder.id ? reminder : r))
      )
    },
    onError: () => {
      toast('error', 'default')
    },
  })
  return mutation
}