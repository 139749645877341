import { IAnswer, Question } from 'types'

export interface QuestionFlattedMap {
  orderAnswered: string[]
  order: string[]
  questions: { [key: string]: Question }
}

export const createQuestionMap = (
  questions: Question[],
  answers: IAnswer[]
): QuestionFlattedMap => {
  const questionMap: QuestionFlattedMap = {
    orderAnswered: [],
    order: [],
    questions: {},
  }

  const map = (_question: Question) => {
    const subqs: Question[] = []
    questionMap.order.push(_question.id)
    questionMap.questions[_question.id] = _question
    questionMap.orderAnswered.push(_question.id)

    if (_question.options) {
      const isAnswered = answers.find(a => a.questionId === _question.id)
      _question.options.forEach(opt => {
        subqs.push(...opt.subquestions)
        if (isAnswered && isAnswered.value.includes(opt.id)) {
          questionMap.orderAnswered.push(...opt.subquestions.map(s => s.id))
        }
      })
    }

    while (true) {
      const theQ = subqs.pop()
      if (!theQ) break
      const parentId = theQ.parentId.split('--')[0]
      if (parentId) {
        const indexOfParent = questionMap.order.findIndex(qid => qid === parentId)
        if (indexOfParent > -1) {
          questionMap.order.splice(indexOfParent + 1, 0, theQ.id)
        }
      }

      questionMap.questions[theQ.id] = theQ

      if (theQ.options) {
        const _isAnswered = answers.find(a => a.questionId === theQ.id)
        theQ.options.forEach(opt => {
          subqs.push(...opt.subquestions)
          if (_isAnswered && _isAnswered.value.includes(opt.id)) {
            questionMap.orderAnswered.push(...opt.subquestions.map(s => s.id))
          }
        })
      }
    }
  }

  questions.forEach(map)
  return questionMap
}
