import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10vh',
})

export const Image = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export const Heading = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fontSize: 20,
  letterSpacing: 0.45,
  marginBottom: theme.spacing(0.5),
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  marginBottom: theme.spacing(6),
}))
