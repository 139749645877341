import React from 'react'
import { styled } from '@material-ui/core'
import TabItem from 'components/common/Tabs/TabItem/TabItem'
import { TSubscriptionInterval } from 'types'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '3vh',
  position: 'relative',
}))

// const Info = styled('div')(({ theme }) => ({
//   padding: '4px 6px',
//   fontWeight: 700,
//   color: theme.colorz.status.success,
//   backgroundColor: 'rgba(102, 204, 134, 0.07)',
//   position: 'absolute',
//   top: -10,
//   right: -105,
//   borderRadius: 4,
//   fontSize: 14,
//   letterSpacing: 0.35,
// }))

const tabItems: Array<{ label: string; section: TSubscriptionInterval }> = [
  { label: 'Monatliche Abrechnung', section: 'month' },
  { label: 'Jährliche Abrechnung', section: 'year' },
]

interface Props {
  currentTab: TSubscriptionInterval
  onChange: (section: TSubscriptionInterval) => void
}

const TabItems: React.FC<Props> = ({ currentTab, onChange }) => {
  return (
    <Container>
      {/* <Info>480.- sparen</Info> */}
      {tabItems.map((tabItem, i) => (
        <TabItem
          key={i}
          {...tabItem}
          currentTab={currentTab}
          handleTabChange={() => onChange(tabItem.section)}
          containerStyles={{ marginRight: i === 0 ? 40 : 0 }}
        />
      ))}
    </Container>
  )
}

export default TabItems
