import { IQuestionLocation } from 'types'

export const generateNewLocationObject = (
  id: string,
  start: number,
  end: number,
  qid?: string,
  locationId?: string,
  docXmicro?: boolean
): IQuestionLocation => {
  let obj = {
    id,
    start,
    end,
    questionId: qid,
    locationId,
  } as any
  if (docXmicro) obj.length = end - start
  return obj
}
