import { Question } from 'types'

export const addOrEditQuestion = (
  questions: Question[],
  newQuestion: Question
): Question[] => {
  let exists: boolean = false
  const result = questions.map(q => {
    if (q.id === newQuestion.id) {
      exists = true
      return newQuestion
    }
    return q
  })
  return exists ? result : [...questions, newQuestion]
}
