import { CreateDocumentHeaderModalComponentProps } from './Modals.types'
import React, { useState } from 'react'
import { StandardModal } from 'components/common'
import { CreateHeader } from '../HeaderSettings/CreateHeader'
import { Container, backdropStyle } from './Modals.styles'
import Validator from 'validator'

export const CreateDocumentHeaderModal: React.FC<CreateDocumentHeaderModalComponentProps> = ({
  open,
  isLoading,
  headerToEdit,
  handleClose,
  handleAddHeader,
  handleEditHeader,
}) => {
  const [base64, setBase64] = useState<string | null>(null)
  const [disabled, setDisabled] = useState(false)

  const getInitialHeight = (): number => {
    if (!headerToEdit) return 100
    const height = headerToEdit?.imageHeight?.split('px')[0] ?? '100'
    if (!Validator.isNumeric(height)) return 100
    return parseInt(height)
  }

  const [zoom, setZoom] = useState(getInitialHeight())

  const handlePrimaryClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (headerToEdit) {
      handleEditHeader(base64, zoom)
      return
    }
    if (base64) handleAddHeader(base64, zoom)
  }

  return (
    <StandardModal
      open={open}
      primaryButtonDisabled={isLoading || (!headerToEdit && !base64) || disabled}
      primaryButtonText={headerToEdit ? 'Kopfzeile bearbeiten' : 'Kopfzeile erstellen'}
      title={headerToEdit ? 'Kopfzeile bearbeiten' : 'Kopfzeile erstellen'}
      RootModalProps={{
        backdropStyle,
      }}
      handlePrimaryClick={handlePrimaryClick}
      handleClose={handleClose}
    >
      <Container>
        <CreateHeader
          headerToEdit={headerToEdit}
          base64={base64}
          zoom={zoom}
          disabled={disabled}
          setDisabled={setDisabled}
          setZoom={setZoom}
          setBase64={setBase64}
        />
      </Container>
    </StandardModal>
  )
}
