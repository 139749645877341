import { SaveModalProps } from "./SaveModal.types";
import React from "react";
import { ButtonSquare, CustomButton, Icon } from "../../common";
import {
  Container,
  ActionBtnContainer,
  Heading,
  Text,
} from "./SaveModal.styles";
import { Input } from "@material-ui/core";

const SaveModal: React.FC<SaveModalProps> = ({
  heading,
  text,
  primaryBtnText,
  secondaryBtnText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  isLoading,
  handlePrimaryClick,
  handleSecondaryClick,
  onClose,
  inputValue,
  handleChange,
}) => {
  // const inputRef = useRef();
  // useEffect(() => {
  //   // @ts-ignore: Unreachable code error
  //   inputRef?.current?.focus();
  // }, [inputRef]);
  return (
    <Container>
      {onClose && !(primaryButtonDisabled && secondaryButtonDisabled) && (
        <ButtonSquare
          onClick={onClose}
          btntype="secondary-static"
          size={32}
          style={{ position: "absolute", top: 8, right: 8 }}
        >
          <Icon iconName="close" fill="#fff" />
        </ButtonSquare>
      )}
      <Heading>{heading}</Heading>
      {text && <Text>{text}</Text>}
      <ActionBtnContainer>
        {(handleChange && (
          <>
            <Input
              // ref={inputRef}
              value={inputValue}
              onChange={(event) => handleChange(event.target.value)}
              color="primary"
              style={{
                width: "45%",
                height: "40px",
                backgroundColor: "white",
                padding: "6px 8px",
                borderRadius: "2px",
              }}
            />
            <CustomButton
              onClick={handlePrimaryClick}
              btntype="primary"
              style={{ width: "45%" }}
              disabled={isLoading || primaryButtonDisabled}
            >
              {primaryBtnText}
            </CustomButton>
          </>
        )) || (
          <>
            <CustomButton
              onClick={handlePrimaryClick}
              btntype="primary"
              style={{ width: "45%" }}
              disabled={isLoading || primaryButtonDisabled}
            >
              {primaryBtnText}
            </CustomButton>
            <CustomButton
              onClick={handleSecondaryClick}
              btntype="outlined-white"
              disabled={secondaryButtonDisabled}
              style={{ width: "45%" }}
            >
              {secondaryBtnText}
            </CustomButton>
          </>
        )}
      </ActionBtnContainer>
    </Container>
  );
};

export default SaveModal;
