import { Question, OptionType } from 'types'
import { addOrEditQuestion } from './addOrEditQuestion'
import { deepFindParentFromQuestions } from './deepFindParentFromQuestions'

export const deepAddOrEditQuestions = (
  questions: Question[],
  theQuestion: Question,
  docXmicro?: boolean
): Question[] => {
  if (!questions) return []
  const { parentId, optionId } = theQuestion
  if (!parentId) return addOrEditQuestion(questions, theQuestion)

  let [_parentQuestion, _questions] = deepFindParentFromQuestions(questions, parentId, docXmicro)

  let ownerOption: OptionType | undefined
  if (_parentQuestion && _parentQuestion.options) {
    ownerOption = _parentQuestion.options.find(
      (optionItem: OptionType) => optionItem.id === optionId
    )
  }
  if (ownerOption) {
    ownerOption.subquestions = addOrEditQuestion(ownerOption.subquestions, theQuestion)
  }

  return _questions
}
