import { styled, Typography, Theme, Box } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  marginBottom: theme.spacing(2),
}))

export const formElStyles = (theme: Theme): CreateCSSProperties => ({
  backgroundColor: '#fff',
  display: 'block',
  width: '50%',
  position: 'initial',
  padding: theme.spacing(1.7, 2),
  fontSize: 16,
  fontFamily: 'Heebo',
  color: theme.colorz.secondary.main,
  border: '1px solid ' + theme.colorz.secondary[100],
})

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  width: 414,
  // paddingTop: theme.spacing(7),
  margin: '0 auto',
}))
