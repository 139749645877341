import { IAnswer, DownloadFormats, IUpdateDocumentProps, DocumentType, IPermissions, IDocument, ITemplate } from 'types'
import Api from './api'
import TemplatesApi from 'api/templates.api'
import { analytics, StorageService } from 'utils'
import templateApi from 'api/templates.api'
// import { generateDocumentDataStructure } from 'utilities/dataStructureGeneration'
import { generateHtml } from 'utilities'

export interface ICreateDocumentPropsApi {
  templateId: string
  answers: IAnswer[]
  htmlData: string
  category: string[]
  new?: boolean
  name?: string
}

export interface IUploadDocumentPropsApi {
  category: string[]
  filename: string
  base64data: string
}

export interface IGetDocumentsApi {
  type: DocumentType
  lastItemFetched?: string
  limit?: number
  folderId?: string
}

interface IUpdateDocument {
  id: string
  data: IUpdateDocumentProps
  isPublic?: boolean
}

interface IDuplicateDocument {
  id: string
}

class DocsApi extends Api {
  public constructor() {
    super('/documents', true)
  }

  public create = async (data: ICreateDocumentPropsApi, isPublic?: boolean): Promise<IDocument> => {
    let res = {} as any
    if (data.new) {
      const { templateId, category, answers, name } = data
      res = await this.api().post(`/v2/documents/${isPublic ? 'public' : ''}`, {
        templateId,
        category,
        answers,
        name,
      })
    } else {
      res = await this.api().post(`/v1/documents/${isPublic ? 'public' : ''}`, data)
    }
    return res.data.data
  }

  public update = async ({ id, data, isPublic }: IUpdateDocument): Promise<IDocument> => {
    const res = await this.api().patch(`/v1/documents/${isPublic ? 'public/' : ''}${id}`, data)
    return res.data.data
  }

  public uploadLogo = async (documentId: string, imageData: string): Promise<string> => {
    const res = await this.api().post(`/v1/documents/${documentId}/logo/upload`, { imageData })
    return res.data.data.url
  }

  public getOne = async (documentId: string, newDocxMicroservice?: boolean): Promise<IDocument> => {
    const version = newDocxMicroservice ? 'v2' : 'v1'
    const res = await this.api().get(`/${version}/documents/${documentId}`)
    return res.data.data
  }

  public fetchEditDocumentData = async (
    documentId: string,
    useLatestTemplateVersion?: boolean
  ): Promise<{ documentFile: IDocument; template: ITemplate }> => {
    const documentFile = await this.getOne(documentId)
    const contentVersionId = documentFile.templateContentVersionId
    const useLatest = useLatestTemplateVersion || !contentVersionId
    const template = useLatest
      ? await TemplatesApi.getOne(documentFile.templateId)
      : await TemplatesApi.getTemplateVersion(documentFile.templateId, documentFile.templateContentVersionId, true)

    return { documentFile, template }
  }

  public get = async (data: IGetDocumentsApi): Promise<IDocument[]> => {
    const { type, lastItemFetched, limit, folderId } = data
    let url = `/v1/documents/list/${type}`
    if (folderId) {
      url = `v1/documents/list/${type}/category`
    }
    const params = new URLSearchParams()
    if (limit) params.set('limit', limit.toString())
    if (lastItemFetched) params.set('startAfter', lastItemFetched)
    if (!folderId) url = url + '?' + params.toString()

    try {
      await this.convertPublicDocument()
      const res = !folderId ? await this.api().get(url) : await this.api().post(url, { category: folderId })

      return res.data.data.documents.map((doc: IDocument) => ({
        ...doc,
        rootFolder: type,
      }))
    } catch (err) {
      throw err
    }
  }

  public duplicate = async ({ id }: IDuplicateDocument): Promise<void> => {
    await this.api().post(`/v2/documents/${id}/duplicate`)
  }

  public convertPublicDocument = async (): Promise<void> => {
    const publicTemplatePurchase = StorageService.read('publicTemplatePurchase')
    if (!publicTemplatePurchase) return
    const id = publicTemplatePurchase?.documentId
    const templateId = publicTemplatePurchase?.documentId
    const templateName = publicTemplatePurchase?.templateName

    if (id) {
      await this.api().patch(`/v1/documents/public/${id}/mine`)
      analytics.logEvent('public_document_created', {
        templateId,
        templateName,
      })
      StorageService.remove('publicTemplatePurchase')
    }
  }

  public emptyTrashed = async () => {
    await this.api().post('/v1/documents/empty/trashed')
  }

  public share = async (templateId: string, email: string): Promise<IDocument> => {
    const res = await this.api().post(`/v1/documents/${templateId}/share`, {
      email,
      permissions: { read: true, write: false },
    })
    return res.data.data
  }

  public permissionsEdit = async (
    templateId: string,
    personId: string,
    permissions: IPermissions
  ): Promise<IDocument> => {
    const res = await this.api().patch(`/v1/documents/${templateId}/share/${personId}`, {
      permissions,
    })
    return res.data.data
  }

  public permissionsDelete = async (templateId: string, personId: string): Promise<IDocument> => {
    const res = await this.api().delete(`/v1/documents/${templateId}/share/${personId}`)
    return res.data.data
  }

  public async download(documentId: string, format: DownloadFormats, newDocxMicroservice?: boolean) {
    const version = newDocxMicroservice ? 'v2' : 'v1'

    const docRes = await this.getOne(documentId)

    let res

    if (version === 'v1') {
      res = await this.api({ responseType: 'blob' }).get(`${version}/documents/${documentId}/export/${format}`)
    } else {
      const answers = docRes.answers
      const templateId = docRes.templateId

      const template = await templateApi.getOne(templateId, false, newDocxMicroservice)
      // const dataStructure = generateDocumentDataStructure(
      //     template.dataStructure,
      //     template.questions,
      //     answers
      // )

      const dataStructure = generateHtml(template.dataStructure, template.questions, answers)[1] as any

      const keyMap = {
        tableHeader: 'header',
        tableBody: 'body',
        tableFooter: 'footer',
        customStyle: 'styleName',
      } as any
      const keyFilter = ['tag', 'start', 'length'] as any
      const keyIgnore = ['id', 'styles'] as any

      const parseForBE = (segments = [] as any) =>
        segments.map((segment: any) =>
          Object.entries(segment).reduce((acc, [key, value]) => {
            if (keyFilter.includes(key)) return acc
            // @ts-ignore
            if (keyIgnore.includes(key)) acc[key] = value
            else {
              const newKey = (keyMap[key] || key) as String
              const newValue = Array.isArray(value) ? parseForBE(value) : value
              // @ts-ignore
              if (!newKey) acc[key] = value
              // @ts-ignore
              else acc[newKey] = newValue as any
            }
            return acc
          }, {})
        )

      res = await this.api({ responseType: 'blob' }).post(`${version}/documents/${documentId}/export/${format}`, {
        storageId: dataStructure.storageId,
        paragraphs: parseForBE(dataStructure.segments),
      })
    }

    if (format === 'docx') {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(res.headers['content-disposition'])
      const filename = matches ? matches[1] : ''

      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = window.document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      window.document.body.appendChild(link)
      link.click()
      window.document.body.removeChild(link)
    }

    return res
  }
}

class DocsUploadApiWrapper extends Api {
  constructor() {
    super('/', true)
  }

  public uploadPDF = async (data: IUploadDocumentPropsApi): Promise<IDocument> => {
    const res = await this.api().post(`/v1/documents/documentsPDF`, data)
    return res.data.data
  }
}

export const DocsUploadApi = new DocsUploadApiWrapper()

const documentsApi = new DocsApi()

export default documentsApi
