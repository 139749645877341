import React from 'react'
import Navigation from './Navigation/Navigation'
import { HeaderContainer } from './Header.styles'

import { IRoute } from './Navigation/Navigation.types'
interface HeaderProps {
  routes: IRoute[]
  headerOpacity: number
}

const Header: React.FC<HeaderProps> = ({ routes, headerOpacity }) => {
  return (
    <HeaderContainer component='header' bgopacity={headerOpacity}>
      <Navigation routes={routes} />
    </HeaderContainer>
  )
}

export default Header
