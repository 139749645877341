import { IDocument, IPermissions, ITemplate } from 'types'
import React, { useState, useEffect } from 'react'
import Validator from 'validator'

import { useUser } from 'queries/user/useUser'
import { useUpdateTemplate, useFetchTemplate } from 'queries/templates'
import { useToast } from 'hooks/useToast'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { queryKeys } from 'queries/queryKeys'

import { SharingModalComponent } from 'components/files/FileAndFolderActions/share/SharingModal.component'

import TemplatesApi from 'api/templates.api'
import DocumentsApi from 'api/docs.api'

import {
  MESSAGE_INVALID_EMAIL,
  ERR_MSG_DEFAULT,
  ERR_MSG_NO_USER_WITH_EMAIL,
} from 'constants/messages'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useDocument, useDocumentsUpdate } from 'queries/documents'

interface Props {
  where: 'templates' | 'docs'
  shareFileId: string
  onClose?: () => void
}

const useFetchFile = (where: 'templates' | 'docs', id: string) => {
  const templateQuery = useFetchTemplate(where === 'templates' ? id : null)
  const docQuery = useDocument(where === 'docs' ? id : null)
  return where === 'templates' ? templateQuery : docQuery
}

const useUpdate = (where: 'templates' | 'docs', id: string) => {
  const updateTemplate = useUpdateTemplate()
  const updateDocument = useDocumentsUpdate()
  return where === 'templates' ? updateTemplate : updateDocument
}

export const FileSharing: React.FC<Props> = ({ where, shareFileId, onClose }) => {
  return where === 'templates' ? FileSharingTemplate({ where, shareFileId, onClose }) : FileSharingDocument({ where, shareFileId, onClose })
}

export const FileSharingTemplate: React.FC<Props> = ({ where, shareFileId, onClose }) => {
  const userId = useUser()?.id ?? '123'
  const queryClient = useQueryClient()
  const { currentFolder } = useCurrentFolder(where)
  const { location, replace } = useHistory()
  const update = useUpdate(where, shareFileId)
  const { data: template, isLoading } = useFetchFile(where, shareFileId)
  const toast = useToast()
  const [addSharingLoading, setAddSharingLoading] = useState(false)
  const [error, setError] = useState(false)
  const [sharingEnabled, setSharingEnabled] = useState(!!template?.sharingEnabled)

  useEffect(() => {
    if (template) setSharingEnabled(template.sharingEnabled)
  }, [template])


  // Breaking point template vs document

  const setAndInvalidate = (updatedFolder: ITemplate) => {
    queryClient.setQueryData([queryKeys.TEMPLATE, updatedFolder.id], updatedFolder)
    queryClient.invalidateQueries([
      queryKeys.TEMPLATES,
      { type: currentFolder.type, folderId: currentFolder.id },
    ])
  }

  const onShare = async (email: string) => {
    if (!shareFileId || !template) return
    try {
      if (error) setError(false)
      const alreadyShared = template?.sharedWith.find(sh => sh.email === email.trim())
      if (alreadyShared) {
        toast(
          'warning',
          `Sie haben diese Vorlage schon mit ${alreadyShared.firstName} ${alreadyShared.lastName} geteilt.`
        )
        return
      }
      setAddSharingLoading(true)
      const res = await TemplatesApi.share(shareFileId, email)
      setAndInvalidate(res)
    } catch (err) {
      setError(true)
      let ERROR_MESSAGE = ERR_MSG_DEFAULT
      // @ts-ignore
      if (['E0346', 'E0400', 'E0401'].includes(err.response.data.error_code)) {
        ERROR_MESSAGE = ERR_MSG_NO_USER_WITH_EMAIL
      }
      console.error(err)
      toast('error', ERROR_MESSAGE)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const onSwitch = (val: boolean): void => {
    if (!shareFileId || !template) return
    update.mutate({ id: template.id, data: { sharingEnabled: val } })
  }

  const onPermissionEdit = async (userId: string, permissions: IPermissions) => {
    if (!template) return
    try {
      setAddSharingLoading(true)
      const res = await TemplatesApi.permissionsEdit(template.id, userId, permissions)
      setAndInvalidate(res)
    } catch (err) {
      console.error(err)
      toast('error', ERR_MSG_DEFAULT)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const onPermissionDelete = async (userId: string) => {
    if (!template) return
    try {
      setAddSharingLoading(true)
      const res = await TemplatesApi.permissionsDelete(template.id, userId)
      setAndInvalidate(res)
    } catch (err) {
      console.error(err)
      toast('error', ERR_MSG_DEFAULT)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const validateEmail = (email: string): boolean => {
    const isEmail = Validator.isEmail(email)
    if (!isEmail) toast('error', MESSAGE_INVALID_EMAIL)
    return isEmail
  }

  const close = () => {
    if (onClose) {
      onClose()
    } else {
      replace(location.pathname)
    }
  }

  if (!shareFileId) return null

  return (
    <SharingModalComponent
      open={!!shareFileId}
      getLoading={isLoading}
      updateLoading={update.isLoading}
      addSharingLoading={addSharingLoading}
      sharingEnabled={sharingEnabled}
      userId={userId}
      sharedWith={template?.sharedWith! || []}
      author={template?.author ? template.author : null}
      error={error}
      validateEmail={validateEmail}
      handleClose={close}
      handleSwitchChange={onSwitch}
      handleShare={onShare}
      handleFinishClick={close}
      handleChangePermissions={onPermissionEdit}
      handleDeletePerson={onPermissionDelete}
    />
  )
}

export const FileSharingDocument: React.FC<Props> = ({ where, shareFileId, onClose }) => {
  const userId = useUser()?.id ?? '123'
  const queryClient = useQueryClient()
  const { currentFolder } = useCurrentFolder(where)
  const { location, replace } = useHistory()
  const update = useUpdate(where, shareFileId)
  const { data: document, isLoading } = useFetchFile(where, shareFileId)
  const toast = useToast()
  const [addSharingLoading, setAddSharingLoading] = useState(false)
  const [error, setError] = useState(false)
  const [sharingEnabled, setSharingEnabled] = useState(!!document?.sharingEnabled)

  useEffect(() => {
    if (document) setSharingEnabled(document.sharingEnabled)
  }, [document])


  // Breaking point template vs document

  const setAndInvalidate = (updatedFolder: IDocument) => {
    queryClient.setQueryData([queryKeys.DOCUMENT, updatedFolder.id], updatedFolder)
    queryClient.invalidateQueries([
      queryKeys.DOCUMENTS,
      { type: currentFolder.type, folderId: currentFolder.id },
    ])
  }

  const onShare = async (email: string) => {
    if (!shareFileId || !document) return
    try {
      if (error) setError(false)
      const alreadyShared = document?.sharedWith.find(sh => sh.email === email.trim())
      if (alreadyShared) {
        toast(
          'warning',
          `Sie haben diese Dokument schon mit ${alreadyShared.firstName} ${alreadyShared.lastName} geteilt.`
        )
        return
      }
      setAddSharingLoading(true)
      const res = await DocumentsApi.share(shareFileId, email)
      setAndInvalidate(res)
    } catch (err) {
      setError(true)
      let ERROR_MESSAGE = ERR_MSG_DEFAULT
      // @ts-ignore
      if (['E0346', 'E0400', 'E0401'].includes(err.response.data.error_code)) {
        ERROR_MESSAGE = ERR_MSG_NO_USER_WITH_EMAIL
      }
      console.error(err)
      toast('error', ERROR_MESSAGE)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const onSwitch = (val: boolean): void => {
    if (!shareFileId || !document) return
    update.mutate({ id: document.id, data: { sharingEnabled: val } })
  }

  const onPermissionEdit = async (userId: string, permissions: IPermissions) => {
    if (!document) return
    try {
      setAddSharingLoading(true)
      const res = await DocumentsApi.permissionsEdit(document.id, userId, permissions)
      setAndInvalidate(res)
    } catch (err) {
      console.error(err)
      toast('error', ERR_MSG_DEFAULT)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const onPermissionDelete = async (userId: string) => {
    if (!document) return
    try {
      setAddSharingLoading(true)
      const res = await DocumentsApi.permissionsDelete(document.id, userId)
      setAndInvalidate(res)
    } catch (err) {
      console.error(err)
      toast('error', ERR_MSG_DEFAULT)
    } finally {
      setAddSharingLoading(false)
    }
  }

  const validateEmail = (email: string): boolean => {
    const isEmail = Validator.isEmail(email)
    if (!isEmail) toast('error', MESSAGE_INVALID_EMAIL)
    return isEmail
  }

  const close = () => {
    if (onClose) {
      onClose()
    } else {
      replace(location.pathname)
    }
  }

  if (!shareFileId) return null

  return (
    <SharingModalComponent
      open={!!shareFileId}
      getLoading={isLoading}
      updateLoading={update.isLoading}
      addSharingLoading={addSharingLoading}
      sharingEnabled={sharingEnabled}
      userId={userId}
      sharedWith={document?.sharedWith! || []}
      author={document?.author ? document.author : null}
      error={error}
      validateEmail={validateEmail}
      handleClose={close}
      handleSwitchChange={onSwitch}
      handleShare={onShare}
      handleFinishClick={close}
      handleChangePermissions={onPermissionEdit}
      handleDeletePerson={onPermissionDelete}
    />
  )
}
