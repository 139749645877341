import React from 'react'
import { NewPasswordFormState } from './NewPasswordForm.container'

import { CustomButton, TextInputGroup, TextWithUrl } from 'components/common'
import { Heading } from '../common-styles'
import { Form } from './NewPasswordForm.styles'

interface Props {
  isLoading: boolean
  handleChange: React.ChangeEventHandler
  handleSubmit: React.FormEventHandler
  values: NewPasswordFormState
}

const NewPasswordForm: React.FC<Props> = ({
  isLoading,
  handleChange,
  handleSubmit,
  values,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Heading variant='h3'>Neues Passwort einrichten</Heading>
      <TextInputGroup
        type='password'
        label='Neues Passwort'
        name='password'
        value={values.password}
        placeholder=''
        onChange={handleChange}
        required
      />
      <TextInputGroup
        type='password'
        label='Passwort bestätigen'
        name='passwordConfirm'
        value={values.passwordConfirm}
        placeholder=''
        onChange={handleChange}
        required
      />
      <CustomButton
        type='submit'
        isLoading={isLoading}
        btntype='primary'
        size='large'
        disabled={isLoading}
        width='100%'
      >
        {!isLoading ? 'Passwort zurücksetzen' : 'Einen Moment bitte...'}
      </CustomButton>
      <TextWithUrl text='Zurück zum' urlText='Login' url='/auth/login' />
    </Form>
  )
}

export default NewPasswordForm
