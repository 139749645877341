import { Question, IQuestionLocation } from 'types'

export type ExtractLocAndSubqReturnType = [IQuestionLocation[], Question[]]

export const extractLocationsAndSubquestions = (
  question: Question
): ExtractLocAndSubqReturnType => {
  let _locations = []
  let _subquestions = []
  if (question.locations) {
    _locations.push(...question.locations)
  }
  if (question.options) {
    for (let option of question.options) {
      if (option.locations) {
        _locations.push(...option.locations)
      }
      _subquestions.push(...option.subquestions)
    }
  }
  return [_locations, _subquestions]
}
