import { IPermissions, SharedWithItem } from 'types'
import React, { useState, useRef } from 'react'
import {
  PersonWrapper,
  StrongText,
  LightText,
  ArrowIcon,
  PopoverItem,
  Button,
} from './Sharing.styles'
import { Icon, MenuPopover } from 'components/common'
import { secondary, status } from 'config/themes/colors'

interface Props extends SharedWithItem {
  owner?: boolean
  updateLoading?: boolean
  handleChangePermissions?: (userId: string, permissions: IPermissions) => void
  handleDeletePerson?: (userId: string) => void
}

export const SharingPersonItem: React.FC<Props> = ({
  id,
  firstName,
  lastName,
  email,
  owner,
  permissions,
  updateLoading,
  handleChangePermissions,
  handleDeletePerson,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null)
  const ref = useRef<SVGSVGElement>(null)
  const Component = owner ? LightText : Button
  const styles: React.CSSProperties = {
    position: 'absolute',
    right: 0,
    top: '30%',
    fontWeight: 400,
  }

  if (!owner) {
    styles.opacity = updateLoading ? 0.8 : 1
    styles.pointerEvents = updateLoading ? 'none' : 'auto'
  }

  const onPermissionChange = (perm: 'read' | 'write') => {
    if (!handleChangePermissions) return
    let updatedPermissions = { ...permissions }
    if (perm === 'read') {
      updatedPermissions = { read: true, write: false }
    }
    if (perm === 'write') {
      updatedPermissions = { read: true, write: true }
    }
    handleChangePermissions(id, updatedPermissions)
    setAnchorEl(null)
  }

  const renderPermission = () => {
    if (owner) return 'Eigentümer/in'
    const { read, write } = permissions
    if (!read && !write) return 'Keine Berechtigungen'
    if (write && read) return 'Bearbeiter/in'
    if (!write && read) return 'Betrachter/in'
  }

  return (
    <PersonWrapper {...props}>
      <StrongText>{`${firstName} ${lastName}`}</StrongText>
      <LightText>{email}</LightText>
      <Component
        anchorel={anchorEl ? 1 : 0}
        style={styles}
        onClick={!owner ? () => setAnchorEl(ref.current) : undefined}
      >
        {renderPermission()}
        {!owner && <ArrowIcon ref={ref} />}
      </Component>
      {anchorEl && (
        <MenuPopover
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <PopoverItem
            onClick={() => onPermissionChange('read')}
            style={{
              pointerEvents: permissions.read && !permissions.write ? 'none' : 'auto',
            }}
          >
            <Icon
              iconName='check'
              fill={secondary.main}
              style={{
                opacity: permissions.read && !permissions.write ? 1 : 0,
                marginRight: 12,
              }}
            />
            Betrachter/in
          </PopoverItem>
          <PopoverItem
            onClick={() => onPermissionChange('write')}
            style={{ pointerEvents: permissions.write ? 'none' : 'auto' }}
          >
            <Icon
              iconName='check'
              fill={secondary.main}
              style={{ opacity: !permissions.write ? 0 : 1, marginRight: 12 }}
            />
            Bearbeiter/in
          </PopoverItem>
          {handleDeletePerson && (
            <PopoverItem
              onClick={() => handleDeletePerson(id)}
              style={{ color: status.error }}
            >
              <Icon iconName='delete' fill={status.error} style={{ marginRight: 12 }} />
              Löschen
            </PopoverItem>
          )}
        </MenuPopover>
      )}
    </PersonWrapper>
  )
}
