import { queryKeys } from 'queries/queryKeys'
import { useHistory } from 'react-router'
import { useMutation, useQueryClient } from 'react-query'

import { useToast } from 'hooks/useToast'

import AuthApi from 'api/auth.api'
import { AxiosError } from 'axios'
import { USER_WITH_EMAIL_ALREADY_EXISTS } from 'constants/messages'
import { documentsRoutes } from 'constants/routes'
import { StorageService } from 'utils'

export const useRegister = () => {
  const { push } = useHistory()
  const toast = useToast()
  const queryClient = useQueryClient()

  const mutation = useMutation(queryKeys.REGISTER, AuthApi.registerUser, {
    onMutate: () => {
      const publicTemplatePurchase = StorageService.read('publicTemplatePurchase')
      localStorage.clear()
      StorageService.save('publicTemplatePurchase', publicTemplatePurchase)
      queryClient.invalidateQueries()
    },
    onSuccess: () => {
      toast('success', 'Die Registrierung war erfolgreich')
      push(documentsRoutes.main)
    },
    onError: (err: AxiosError) => {
      const emailInUse = err?.response?.data?.error_code === 'E0006'
      if (!emailInUse) return console.error('Registration Error', err)
      toast('error', USER_WITH_EMAIL_ALREADY_EXISTS)
    },
  })
  return mutation
}
