import {
  USER_TYPE_CLIENT_EMPLOYEE,
  USER_TYPE_LAWYER_EMPLOYEE,
} from 'constants/user-types'
import { useUser } from 'queries/user/useUser'

export const useIsTemplatePurchaseRequired = (templateId?: string | null) => {
 
  const user = useUser()!
  if (!templateId) return

  const isEmployee = [USER_TYPE_CLIENT_EMPLOYEE, USER_TYPE_LAWYER_EMPLOYEE].includes(
    user?.type
  )

  if (isEmployee) return false

  const isFreeUser = user?.subscription.type === 'free'
  return (
    isFreeUser &&
    !user?.purchases.templates.some(
      t => t.id === templateId && ['paid', 'processing'].includes(t.status)
    )
  )
}
