import React, { useState } from 'react'
import SidebarContainer from 'components/Sidebar/Sidebar.container'

const SidebarLawyer: React.FC = () => {
  const [searchValue, setSearchValue] = useState('')

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSearchValue(e.target.value)

  return (
    <SidebarContainer
      searchValue={searchValue}
      handleSearchChange={handleSearchChange}
      isLoading={false}
      baseUrl='/rechtsberatung'
      urlLinks={[{ url: '', urlName: 'Geplante Ereignisse' }]}
    />
  )
}

export default SidebarLawyer
