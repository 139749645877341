import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { useLoadLegalAdviceEvents } from './hooks/useLoadLegalAdviceEvents'
import { useUser } from 'queries/user/useUser'
import { useMasterData } from 'queries/app/useMasterdata'

import LegalAdviceClient from './views/LegalAdviceClient/LegalAdviceClient.page'
import LegalAdviceLawyer from './views/LegalAdviceLawyer/LegalAdviceLawyer.page'
import Layout from './layout/Layout'
import * as userTypes from 'constants/user-types'

const ConsultingPage: React.FC = () => {
  const user = useUser()
  const allowConsultingPage = useMasterData()?.legal_stuff.advice_tab_shown
  const { data } = useLoadLegalAdviceEvents()

  useEffect(() => {
    document.title = 'Rechtsberatung | CASUS'
  }, [])

  if (!allowConsultingPage) return <Redirect to='/aktenschrank' />

  const renderPages = (): React.ReactNode => {
    switch (user?.type) {
      case userTypes.USER_TYPE_CLIENT_ADMIN:
      case userTypes.USER_TYPE_CLIENT_EMPLOYEE:
        return <LegalAdviceClient />
      case userTypes.USER_TYPE_LAWYER_ADMIN:
      case userTypes.USER_TYPE_LAWYER_EMPLOYEE:
        return <LegalAdviceLawyer />
      default:
        return null
    }
  }

  return <Layout isLoading={!data}>{renderPages()}</Layout>
}

export default ConsultingPage
