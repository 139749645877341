import { SettingsHeaderCardItemActionsComponentProps } from './SettingsCardItem.types'
import React from 'react'
import { ActionsWrapper } from './SettingsCardItem.styles'
import { CheckboxGroup, CustomButton } from 'components/common'

export const CardHeaderActions: React.FC<SettingsHeaderCardItemActionsComponentProps> = ({
  item,
  activeItem,
  checked,
  handleChange,
  handleActivityBtnClick,
}) => {
  const isActive = activeItem?.id === item.id
  const defaultChecked = item.startAt === 1 && item.endAt === 1
  return (
    <ActionsWrapper>
      <CheckboxGroup
        checked={isActive ? checked : defaultChecked}
        label='Nur auf erster Seite anzeigen'
        handleChange={() => handleChange(isActive ? !checked : defaultChecked)}
        disabled={!isActive}
        marginBottom={0}
      />
      <CustomButton
        btntype={isActive ? 'success' : 'secondary'}
        width={110}
        size='small'
        onClick={() => handleActivityBtnClick(isActive ? null : item?.id!)}
      >
        {isActive ? 'Aktiv' : 'Aktivieren'}
      </CustomButton>
    </ActionsWrapper>
  )
}
