import { IAnswer, IOption, IQuestionLocation, Question } from 'types'
import { ICustomListCounters } from 'config/textEditor/TextEditor.types'
import moment from 'moment'
import 'moment/locale/de'
import { deepFindQuestion } from 'utils'
import {
  ATTR_LIST_HEADING,
  ATTR_LIST_TYPE,
  LIST_TYPE_HEADING,
  LIST_TYPE_PARENTHESES,
  classes,
} from 'config/textEditor/textEditor.constants'
import { insertListNumber } from 'config/textEditor/services/text-editor'
import {
  QUESTION_TYPE_CHECKBOX,
  QUESTION_TYPE_DATE,
  QUESTION_TYPE_NUMBER,
  QUESTION_TYPE_RADIO_LINK,
  QUESTION_TYPE_RADIO_REPLACEMENT,
  QUESTION_TYPE_TEXT_BOX,
} from 'constants/question-types'
import { ANSWER_TYPE_NUMBER, ANSWER_TYPE_DATE } from 'constants/answer-types'
moment.updateLocale('de', null)

const initialCounters: ICustomListCounters = {
  list_heading_1_counter: 0,
  list_heading_2_counter: 0,
  list_heading_3_counter: 0,
  list_parentheses_counter: 0,
}

export const generateDocumentHtmlData = (
  htmlData: string,
  questions: Question[],
  answers: IAnswer[],
  docXmicro?: boolean
): string | undefined => {
  const dom = document.createRange().createContextualFragment(htmlData)

  const isCustomListSpan = (element: Element | null | undefined): boolean => {
    return Boolean(
      element &&
      element.nodeName === 'SPAN' &&
      element?.className.includes(classes.list_item_span)
    )
  }

  const parentWillBeEmptyAfterRemoval = (
    parentElement: Element,
    elementToRemove: Element
  ): boolean => {
    if (keepTableCell(parentElement)[0]) return false

    const isListSpan = elementToRemove.parentElement
      ? isCustomListSpan(elementToRemove.parentElement)
      : false
    const emptyIt =
      isListSpan &&
      elementToRemove?.parentElement?.textContent ===
      parentElement?.textContent
    return (
      parentElement.textContent ===
      elementToRemove.textContent ||
      parentElement.textContent === elementToRemove.textContent ||
      emptyIt
    )
  }

  const getElementsToRemove = (
    questionOptions: IOption[],
    answerOptions: IAnswer
  ): Element[] => {
    const locationsToRemove: IQuestionLocation[] = []
    const locationsNotToRemove: IQuestionLocation[] = []
    const elementsToRemove: Element[] = []

    questionOptions.forEach((optionItem) => {
      if (!answerOptions.value.includes(optionItem.id)) {
        if (optionItem.locations)
          locationsToRemove.push(...optionItem.locations)
      } else {
        if (optionItem.locations)
          locationsNotToRemove.push(...optionItem.locations)
      }
    })

    for (let _location of locationsToRemove as any) {
      const elements = dom.querySelectorAll<HTMLElement>(
        `#${CSS.escape(_location.id)}`
      )
      elementsToRemove.push(...elements)
    }

    // handle table char
    for (let _location of locationsNotToRemove as any) {
      const elements = dom.querySelectorAll<HTMLElement>(
        `#${CSS.escape(_location.id)}`
      )
      elements.forEach((el) => {
        const TDEl = el.closest('td')
        if (TDEl?.textContent?.trimEnd().endsWith('#')) {
          let html = TDEl.innerHTML

          for (let i = html.length - 1; i > 0; i--) {
            if (html[i] === '#') {
              html = html.slice(0, i) + html.slice(i + 1, html.length)
              TDEl.innerHTML = ''
              TDEl.insertAdjacentHTML('beforeend', html)
            }
          }
        }
      })
    }

    return elementsToRemove
  }

  const keepTableCell = (element: Element): [boolean, Element | null] => {
    let level = 0
    let theElement: Element | null = element
    let keepTable = false

    while (true) {
      if (!theElement || theElement.nodeName === 'TD') break
      if (level > 5) break
      theElement = theElement.parentElement
      level += 1
    }

    const keep =
      theElement?.nodeName === 'TD' && theElement.textContent === '#'

    if (keep && theElement) {
      theElement.textContent = ' '
      keepTable = true
    }
    return [keepTable, keep ? theElement : null]
  }

  const insertAnswers = (docXmicro?: boolean): void => {
    const removeNotSelectedOptions = (
      questionOptions: IOption[],
      answers: IAnswer,
      docXmicro?: boolean
    ): void => {
      let elementsToRemove = getElementsToRemove(questionOptions, answers)
      for (let markElement of elementsToRemove) {
        const parentElement = markElement.parentElement
        const isListSpan = isCustomListSpan(parentElement)
        if (!parentElement) break
        if (isListSpan) {
          parentElement.parentElement?.remove()
        } else {
          const parentWillBeEmpty = parentWillBeEmptyAfterRemoval(
            parentElement,
            markElement
          )
          if (parentWillBeEmpty) {
            let theParent = parentElement.parentElement
            parentElement.remove()

            while (true) {
              if (theParent?.nodeName === 'BODY') break
              if (!theParent) break

              const [keepTD, theEl] = keepTableCell(theParent)

              const shouldKeepTD = keepTD && theEl

              if (theEl) {
                theEl.textContent = ' '
              }

              if (theParent.textContent?.length || shouldKeepTD) break
              const oldParent = theParent
              theParent = theParent.parentElement
              oldParent.remove()
            }
          } else {
            parentElement.removeChild(markElement)
            const [, theEl] = keepTableCell(parentElement)
            if (theEl) theEl.textContent = ' '
          }
        }
      }
    }

    const replaceAnswerContent = (
      question: Question,
      answer: IAnswer
    ): void => {
      let answerValue = answer.value
      if (answer.type === ANSWER_TYPE_DATE) {
        answerValue = moment(answer.value).format('D. MMMM YYYY')
      }
      if (question.type === QUESTION_TYPE_RADIO_REPLACEMENT) {
        const opt = question.options.find((opt) => opt.id === answerValue[0])
        if (opt) answerValue = opt.text
      }
      const content = answerValue.toString().split(/\r?\n/)
      let finalHtml = content[0]
      if (!content.length) finalHtml = '{ANTWORT FÜR DIESE FRAGE FEHLT}'
      if (content.length > 1) {
        finalHtml = content.reduce((prev, curr) => prev + '<br>' + curr)
      }

      if (answer.type === ANSWER_TYPE_NUMBER) {
        finalHtml = finalHtml.toString()
        const endsWithDash = finalHtml.endsWith('-')
        const theNum = finalHtml.replace('-', '').split('.')
        finalHtml = Number(theNum[0]).toLocaleString('de-CH')
        if (theNum[1]) finalHtml = `${finalHtml}.${theNum[1]}`
        if (endsWithDash) finalHtml = finalHtml + '-'
      }

      const locations = question.locations ? question.locations : []
      locations.forEach((locItem: any) => {
        const markEl = dom.querySelectorAll<HTMLElement>(
          `#${CSS.escape(locItem.id)}`
        )
        markEl.forEach((mEl, i) => {
          mEl.removeAttribute('style')
          if (i === 0) {
            if (
              mEl.firstElementChild &&
              ['STRONG', 'EM'].includes(mEl.firstElementChild.nodeName)
            ) {
              mEl.firstElementChild.innerHTML = finalHtml
            } else {
              mEl.innerHTML = finalHtml
            }
          } else {
            mEl.remove()
          }
        })
      })
    }

    for (let _answer of answers) {
      const _question = deepFindQuestion(questions, _answer.questionId)
      if (!_question) {
        // alert('Something went wrong')
        // question got deleted
        continue
      }

      switch (_question.type) {
        case QUESTION_TYPE_CHECKBOX:
        case QUESTION_TYPE_RADIO_LINK:
          removeNotSelectedOptions(_question.options, _answer, docXmicro)
          break
        case QUESTION_TYPE_TEXT_BOX:
        case QUESTION_TYPE_DATE:
        case QUESTION_TYPE_NUMBER:
        case QUESTION_TYPE_RADIO_REPLACEMENT:
          replaceAnswerContent(_question, _answer)
          break
      }
    }
  }

  const updateHtml = (docXmicro?: boolean): void => {
    let counters: ICustomListCounters = { ...initialCounters }
    let listItems = dom.querySelectorAll<HTMLElement>(`p[${ATTR_LIST_TYPE}]`)

    const isEmpty = (listItem: Element): boolean => {
      const firstChild = listItem.firstElementChild

      if (!firstChild && !listItem.textContent?.length) {
        return true
      }

      if (!firstChild) return false
      const childTextContent = firstChild.textContent
      const parentContent = listItem.textContent

      if (
        firstChild.classList.contains(classes.list_item_span) &&
        childTextContent === parentContent
      ) {
        return true
      }

      return false
    }

    listItems.forEach((listItem, i) => {
      let headingType = listItem.getAttribute(ATTR_LIST_HEADING)
      const listItemType = listItem.getAttribute(ATTR_LIST_TYPE)
      if (!listItemType) return console.error('No List Item Type')

      const {
        list_heading_1_counter,
        list_heading_2_counter,
        list_heading_3_counter,
        list_parentheses_counter,
      } = counters

      // HANDLE HEADING LISTS
      if (listItemType === LIST_TYPE_HEADING && !isEmpty(listItem)) {
        switch (headingType) {
          case '1':
            counters = {
              ...initialCounters,
              list_heading_1_counter: list_heading_1_counter + 1,
            }
            break
          case '2':
            counters = {
              ...counters,
              list_heading_2_counter: list_heading_2_counter + 1,
              list_heading_3_counter: 0,
              list_parentheses_counter: 0,
            }
            break
          case '3':
            counters = {
              ...counters,
              list_heading_3_counter: list_heading_3_counter + 1,
              list_parentheses_counter: 0,
            }
            break
        }
      }

      if (listItemType === LIST_TYPE_PARENTHESES && !isEmpty(listItem)) {
        counters = {
          ...counters,
          list_parentheses_counter: list_parentheses_counter + 1,
        }
      }

      if (!isEmpty(listItem))
        insertListNumber(listItem, counters, listItemType, headingType)
      if (isEmpty(listItem)) {
        listItem.remove()
      }
    })

    if (!docXmicro) {
      const spanElementsWithContentEditable =
        dom.querySelectorAll<HTMLElement>(`.mceNonEditable`)
      spanElementsWithContentEditable.forEach((spanEl) => {
        spanEl.removeAttribute('contenteditable')
      })
    }

    const listElements = dom.querySelectorAll<HTMLElement>('li')

    listElements.forEach((liElement) => {
      if (!liElement.textContent || !liElement.textContent.length) {
        liElement.remove()
      }

      // TODO: remove nested items
      // const firstChild = liElement.firstChild
      // const prevSibling = liElement.previousElementSibling
      // if (
      //   firstChild &&
      //   prevSibling &&
      //   prevSibling.textContent &&
      //   !prevSibling.textContent.length &&
      //   ['OL', 'UL'].includes(firstChild.nodeName)
      // ) {
      //   liElement.remove()
      //   firstChild.remove()
      // }
    })
  }

  insertAnswers(docXmicro)
  if (!docXmicro) updateHtml()

  const el = document.createElement('div')
  el.appendChild(dom)
  return el.innerHTML
}
