import { useCallback, useEffect } from 'react'
import { useMasterData } from 'queries/app/useMasterdata'
import { useToast } from './useToast'

export const useMaintenanceCheck = () => {
  const masterdata = useMasterData()
  const toast = useToast()

  const maintenanceStart =
    masterdata?.maintenance && masterdata?.maintenance?.blockedOn
      ? masterdata?.maintenance?.blockedStartTime?._seconds * 1000
      : null
  const maintenanceWarningMessage = masterdata?.maintenance?.warningMessage

  const showMaintenanceMessage = useCallback(() => {
    if (!maintenanceStart) return
    if (+new Date(maintenanceStart) > +new Date()) {
      toast('warning', maintenanceWarningMessage ?? '', 400000)
    }
  }, [toast, maintenanceStart, maintenanceWarningMessage])

  useEffect(() => {
    showMaintenanceMessage()
  }, [showMaintenanceMessage])

  return maintenanceStart && +new Date(maintenanceStart) < +new Date()
}
