import React from 'react'
import { ButtonEl, SpinnerContainer, CircularProgress } from './CustomButton.styles'
import { ICustomButton } from './CustomButton.types'

const CustomButton: React.FC<ICustomButton> = ({
  children,
  isLoading,
  disabled,
  btntype,
  size,
  width,
  textselectionactive,
  type,
  style = {},
  id,
  onClick,
}) => (
  <ButtonEl
    id={id}
    type={type}
    btntype={btntype}
    size={size}
    width={width}
    onClick={onClick}
    textselectionactive={textselectionactive}
    disabled={disabled}
    style={style}
    data-intercom-target={id}
  >
    {children}
    {isLoading && (
      <SpinnerContainer>
        <CircularProgress size={20} />
      </SpinnerContainer>
    )}
  </ButtonEl>
)

export default CustomButton
