import React, { useState } from 'react'
import { useToast } from 'hooks/useToast'
import * as M from 'constants/messages'
import AuthApi from 'api/auth.api'
import { ChangePasswordComponent } from './ChangePassword.component'

const initialState = {
  passwordCurrent: '',
  passwordNew: '',
  passwordNewConfirm: '',
}

export type TUpdatePasswordState = typeof initialState

export const ChangePassword: React.FC = () => {
  const [state, setState] = useState<TUpdatePasswordState>(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setState(prev => ({ ...prev, [name]: value }))
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const { passwordNew, passwordNewConfirm, passwordCurrent } = state
    e.preventDefault()
    if (passwordNew !== passwordNewConfirm)
      return toast('error', M.ERR_PASSWORDS_DONT_MATCH)

    if (passwordNew.length < 8) return toast('error', M.ERR_MSG_PASSWORD_CHARACTERS_COUNT)

    try {
      setIsLoading(true)
      await AuthApi.updatePassword({ passwordCurrent, passwordNew })
      toast('success', M.SUCCESS_PASSWORD_CHANGED)
      setState(initialState)
    } catch (err) {
      if (err?.code === 'auth/wrong-password') {
        return toast('error', 'Das eingegebene Passwort ist nicht gültig.')
      }

      if (err?.code === 'auth/too-many-requests') {
        return toast('error', M.ERR_MSG_TO_MANY_PASSWORD_ATTEMPTS)
      }

      toast('error', 'default')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ChangePasswordComponent
      onChange={onChange}
      onSubmit={onSubmit}
      values={state}
      isLoading={isLoading}
    />
  )
}
