import { ButtonElProps, ButtonSquareTypes } from './ButtonSquare.types'
import { styled, Theme } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'

const getBtnStyles = (theme: Theme, btntype: ButtonSquareTypes) => {
  const style = {
    backgroundColor: 'transparent',
    border: '2px solid ' + theme.colorz.secondary[100],
    '&:hover': {
      border: '2px solid ' + theme.colorz.secondary.main,
    },
  }

  if (['white', 'secondary'].includes(btntype)) {
    return style
  }

  if (btntype === 'secondary-static') {
    return { ...style, border: '2px solid ' + theme.colorz.secondary.main }
  }

  if (btntype === 'transparent') {
    return { ...style, border: '2px solid ' + theme.colorz.secondary[100] }
  }

  if (btntype === 'primary') {
    return {
      backgroundColor: theme.colorz.primary.main,
      border: '2px solid ' + theme.colorz.secondary.main,
      color: '#000',
      fill: '#000',
      '&:hover': { opacity: 0.8 },
    }
  }
}

export const ButtonContainer = styled(ButtonBase)(
  ({ theme, size, btntype }: ButtonElProps) => ({
    width: size ? size : 32,
    height: size ? size : 32,
    borderRadius: 2,
    cursor: 'pointer',
    flexShrink: 0,
    ...getBtnStyles(theme, btntype),
  })
)
