import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { useUser } from 'queries/user/useUser'
import { useCalendlyAuthData } from './useCalendlyAuthData'
import { useMasterData } from 'queries/app/useMasterdata'

import UserApi from 'api/user.api'
import { queryKeys } from 'queries'
import { USER_TYPE_LAWYER_ADMIN, USER_TYPE_LAWYER_EMPLOYEE } from 'constants/user-types'

export const useLoadLegalAdviceEvents = () => {
  const user = useUser()
  const calendlyData = useCalendlyAuthData()
  const calendlyAuthUrl = useMasterData()?.calendly.auth_url
  const isLawyer = [USER_TYPE_LAWYER_ADMIN, USER_TYPE_LAWYER_EMPLOYEE].includes(
    user?.type!
  )

  const disabled = isLawyer && !calendlyData

  useEffect(() => {
    if (disabled && calendlyAuthUrl) {
      window.location.href = calendlyAuthUrl
    }
  }, [disabled, calendlyAuthUrl])

  const query = useQuery(
    queryKeys.LEGAL_ADVICE_EVENTS,
    () => UserApi.getCalendlyEvents(user!, calendlyData),
    { enabled: !disabled }
  )

  return query
}
