import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#D9DCE2',
}))

export const Text = styled(Typography)(() => ({
  fontSize: 25,
  fontWeight: 500,
  width: 500,
  textAlign: 'center',
  color: '#001640',
}))
