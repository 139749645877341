import React from 'react'
// import { useUser } from 'queries/user/useUser'
import SettingsSidebar from 'components/Sidebar/SettingsSidebar'
import { PageAssetsContainer } from '../../Authenticated.page.styles'
// import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'

const AccountSettingsSidebar: React.FC = () => {
  // const user = useUser()
  const urlLinks = [
    { urlName: 'Profil', url: '' },
    { urlName: 'E-Mail', url: '/email' },
    { urlName: 'Passwort', url: '/passwort' },
    { urlName: 'Sicherheit', url: '/2fa' },
  ]
  // if (user?.type === USER_TYPE_CLIENT_ADMIN || user?.type === USER_TYPE_LAWYER_ADMIN) {
  //   urlLinks.push({ urlName: 'Abo', url: '/abo' })
  // }
  return (
    <SettingsSidebar baseUrl='/einstellungen' urlLinks={urlLinks}>
      <PageAssetsContainer> </PageAssetsContainer>
    </SettingsSidebar>
  )
}

export default AccountSettingsSidebar
