import { styled, Theme, Typography } from '@material-ui/core'

export const TH = styled('div')(({ theme }) => ({
  display: 'flex',
  '&:nth-of-type(1)': {
    flexBasis: '40%',
  },
  '&:nth-of-type(2)': {
    flexBasis: '15%',
    justifyContent: 'center',
  },
  '&:nth-of-type(3)': {
    flexBasis: '15%',
    justifyContent: 'center',
  },
  '&:nth-of-type(4)': {
    flexBasis: '15%',
    justifyContent: 'center',
  },
  '&:nth-of-type(5)': {
    flexBasis: '15%',
    justifyContent: 'center',
  },
}))

export const TD = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&:nth-of-type(1)': {
    flexBasis: '40%',
  },
  '&:nth-of-type(2)': {
    flexBasis: '15%',
  },
  '&:nth-of-type(3)': {
    flexBasis: '15%',
  },
  '&:nth-of-type(4)': {
    flexBasis: '15%',
  },
  '&:nth-of-type(5)': {
    flexBasis: '15%',
  },
}))

interface P {
  theme: Theme
  colorz?: '200' | '300' | 'main' | 'primary'
}
export const Text = styled(Typography)(({ theme, colorz }: P) => ({
  color:
    colorz === 'primary'
      ? theme.colorz.primary.main
      : theme.colorz.secondary[colorz ?? 300],
}))
