import { Box, styled } from '@material-ui/core'

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 414,
  minWidth: 414,
  marginLeft: 32,
})

export const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  flexShrink: 0,
})

export * from '../../AccountSettings.styles'
