import Api from './api'
import { CalendlyBookingUrlItem, ITime, TTemplatePriceTier } from 'types'

interface IFontStyles {
  fontSize: string
  margin: string
  lineHeight: string
  indent: string
  padding?: string
}

interface BodyStyles {
  margin: string
}

interface IDocumentStyles {
  body: BodyStyles
  heading_1: IFontStyles
  heading_2: IFontStyles
  heading_3: IFontStyles
  heading_4: IFontStyles
  paragraph: IFontStyles
  numbered_list: IFontStyles
}

export interface IPrices {
  book_a_lawyer: string
  single_template_tiers: Record<TTemplatePriceTier, { id: string; amount: number }>
  subscription_basic_month: string
  subscription_basic_year: string
  subscription_team_month: string
  subscription_team_year: string
  subscription_startup_month: string
  subscription_startup_year: string
}

export interface IMasterData {
  stripe: {
    test_mode: boolean
  }
  client: {
    registration_open: boolean
    lawyer_booking_open: boolean
    team_members_enabled: boolean
  }
  lawyer: {
    registration_open: boolean
    registration_financials_required: boolean
    team_members_enabled: boolean
  }
  legal_stuff: {
    advice_tab_shown: boolean
  }
  folderStructure: {
    [key: string]: {
      name: string
      order: number
    }
  }
  calendly: {
    auth_url: string
    booking_urls: CalendlyBookingUrlItem[]
  }
  superadmins: string[]
  prices: IPrices
  sharing: {
    documents: {
      enabled: boolean
    }
    templates: {
      enabled: boolean
    }
  }
  documentStyles: IDocumentStyles
  maintenance: {
    warningOn: boolean
    blockedOn: boolean
    warningMessage: string
    blockedStartTime: ITime
    blockedMessage: string
  }
}

class AppApi extends Api {
  public constructor() {
    super('/')
  }

  public getMasterdata = async (): Promise<IMasterData> => {
    const res = await this.api().get('/masterdata')
    return res.data.data.masterdata.app
  }
}

const appApi = new AppApi()

export default appApi
