import React, { useState, useCallback, useRef } from 'react'
import ProfileInfoClient from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoClient/ProfileInfoClient.component'
import AvatarEditor from 'react-avatar-editor'
import { useUser } from 'queries/user/useUser'
import { useUpdateUser } from 'queries/user/useUpdateUser'
import { IUpdateUserProfileApiRequest } from 'api/user.api'
import { objectHasPropsWithValue } from 'components/user/services/objectHasPropsWithValue'

const initialCompanyState = {
  id: '',
  name: '',
  website: '',
  employeeCount: '',
  field: '',
}

export interface SignupSettingsClientInfoState {
  firstName: string
  twoFaEnabled: boolean
  lastName: string
  jobPosition: string
}

export type SignupSettingsClientCompanyState = typeof initialCompanyState

export interface SignupSettingsClientState extends SignupSettingsClientInfoState {
  companyInfo: SignupSettingsClientCompanyState
}

const ProfileInfoContainer: React.FC = () => {
  const { mutate, isLoading } = useUpdateUser()
  const user = useUser()!
  const [step, setStep] = useState(0)

  const [state, setState] = useState<SignupSettingsClientInfoState>({
    firstName: user.firstName,
    lastName: user.lastName,
    jobPosition: user.jobPosition,
    twoFaEnabled: user.twoFaEnabled,
  })

  const [companyState, setCompanyState] = useState<SignupSettingsClientCompanyState>({
    ...user.companyInfo,
  })

  const [imageFile, setImageFile] = useState<File | null>(null)
  const editorRef = useRef<AvatarEditor>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target
    if (!name) return
    if (step === 0) {
      return setCompanyState(prev => ({ ...prev, [name]: value }))
    }
    setState(prev => ({ ...prev, [name]: value }))
  }

  const checkIfUserHasPersonalData = useCallback((): boolean => {
    if (!user) return false
    let obj: any = { ...user, ...user.companyInfo }
    const propsToCheck = [
      'firstName',
      'lastName',
      'jobPosition',
      'name',
      'employeeCount',
      'field',
    ]
    const userHasPersonalData = objectHasPropsWithValue(obj, ...propsToCheck)
    return userHasPersonalData
  }, [user])

  const handleSelectChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const { value, name } = e.target
    if (!name) return
    if (step === 0) {
      return setCompanyState(prev => ({ ...prev, [name]: value }))
    }
    setState(prev => ({ ...prev, [name]: value }))
  }

  const handleBackClick = (e: React.MouseEvent<HTMLButtonElement>): void =>
    setStep(prev => prev - 1)

  const handleForwardClick = (base64Img?: string) => {
    if (step < 2) return setStep(prev => prev + 1)

    let theData: IUpdateUserProfileApiRequest = { ...state, companyInfo: companyState }
    if (imageFile) {
      if (!editorRef.current) return
      const canvasScaled = editorRef.current.getImageScaledToCanvas()
      const base64Data = canvasScaled.toDataURL('png', 0.5)
      const base64String = base64Data.split('base64,')[1].trim()
      theData.imageData = base64String
    }
    if (step === 2) mutate(theData)
  }

  return (
    <ProfileInfoClient
      step={step}
      values={{ ...state, companyInfo: companyState }}
      isOpen={!checkIfUserHasPersonalData()}
      isLoading={isLoading}
      imageFile={imageFile}
      editorRef={editorRef}
      userType={user.type}
      editingDisabled={!!user.companyRef}
      setImageFile={setImageFile}
      handleChange={handleChange}
      handleSelectChange={handleSelectChange}
      handleBackClick={handleBackClick}
      handleForwardClick={handleForwardClick}
    />
  )
}

export default ProfileInfoContainer
