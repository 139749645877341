import { queryKeys } from 'queries/queryKeys'
import { useQuery } from 'react-query'

import Api from 'api/app.api'

export const useLoadMasterdata = () => {
  const query = useQuery(queryKeys.MASTER_DATA, Api.getMasterdata, {
    refetchOnWindowFocus: false,
    onSuccess: data => {},
  })
  return query
}
