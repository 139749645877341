import React from 'react'
import { useHistory } from 'react-router'

import { useFetchTemplate } from 'queries/templates'

import TemplatePreviewModal from 'pages/Authenticated/Templates/components/TemplatePreviewModal/TemplatePreviewModal.component'
import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'
import { useTemplateActions } from 'hooks/useTemplateActions'

interface Props {
  templateId: string
}

const TemplatePreview: React.FC<Props> = ({ templateId }) => {
  const { location, replace } = useHistory()

  const { data, isLoading } = useFetchTemplate(templateId)
  const { onUseTemplate } = useTemplateActions()
  const requirePayment = useIsTemplatePurchaseRequired(templateId)

  const onClose = () => {
    replace(location.pathname)
  }

  return (
    <TemplatePreviewModal
      requirePayment={requirePayment}
      isOpen={!!templateId}
      template={data!}
      actionBtnDisabled={!data?.questions.length}
      isLoading={!data || isLoading}
      onClose={onClose}
      onUseTemplate={onUseTemplate}
      editingDisabled={true}
      noQuestions={!!data && !data.questions.length}
    />
  )
}

export default TemplatePreview
