import { styled, Typography } from '@material-ui/core'

export const Col = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 247,
  flexShrink: 0,
  '&:not(:last-child)': {
    marginRight: 16,
  },
})

export const Container = styled(Col)(({ theme }) => ({
  padding: theme.spacing(0, 3, 3, 3),
  width: '90%',
  maxWidth: 761,
  minWidth: 640,
  height: 372,
}))

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const TextBold = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: 16,
}))

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  marginBottom: 10,
}))
