import React from 'react'
import { Container, Text } from './Maintenance.styles'
import { useMasterData } from 'queries/app/useMasterdata'

export const MaintenancePage: React.FC = () => {
  const message = useMasterData()?.maintenance?.blockedMessage
  return (
    <Container>
      <Text>
        {message ? message : 'The app is currently in maintenance, will be back soon!'}
      </Text>
    </Container>
  )
}
