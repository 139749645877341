import RemindersApi from 'api/reminders'
import { useQuery } from 'react-query'
import { queryKeys } from 'queries/queryKeys'
import { TQueryOptions } from 'queries'
import { IReminder } from 'types'
import { useToast } from 'hooks/useToast'

export const useReminder = (
  id: string | null | undefined,
  options: TQueryOptions<IReminder | null> = {}
) => {
  const toast = useToast()
  const query = useQuery(
    [queryKeys.DOCUMENT_REMINDERS, id],
    () => (id ? RemindersApi.getOne(id) : null),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
      ...options,
      onError: () => {
        toast('error', 'default')
      },
    }
  )
  return query
}
