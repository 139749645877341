import { styled, Typography } from '@material-ui/core'
import { CustomButton } from 'components/common'
import React from 'react'
import { useHistory } from 'react-router'
import { ROUTE_UPGRADE_SUBSCRIPTION } from 'constants/routes'
import { useUserSubscription } from 'components/subscriptions/hooks/useUserSubscription'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 32,
}))

const Cancel = styled(Typography)(({ theme }) => ({
  letterSpacing: 0.2,
  fontSize: 16,
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    transform: 'translateY(-1px)',
  },
}))

interface Props {
  hideCancel?: boolean
}

const ActionButtons: React.FC<Props> = ({ hideCancel }) => {
  const { push, location } = useHistory()
  const { isLoading, redirectToPortal } = useUserSubscription()

  const onUpdateAbo = () => {
    push(ROUTE_UPGRADE_SUBSCRIPTION, { from: location.pathname })
  }

  const onCancel = () => {
    redirectToPortal()
  }

  return (
    <Wrapper style={{}}>
      <CustomButton
        btntype='primary'
        size='large'
        width={158}
        onClick={onUpdateAbo}
        disabled={isLoading}
      >
        Abo anpassen
      </CustomButton>
      {!hideCancel && (
        <Cancel
          onClick={onCancel}
          style={{
            opacity: isLoading ? 0.6 : 1,
            pointerEvents: isLoading ? 'none' : 'auto',
          }}
        >
          Abo kündigen
        </Cancel>
      )}
    </Wrapper>
  )
}

export default ActionButtons
