import { SharedWithItem } from 'types'

export const isEditorIsOwner = (
  userId?: string,
  authorId?: string,
  sharedWith?: SharedWithItem[]
) => {
  const isOwner = userId && authorId && userId === authorId
  const isEditor =
    isOwner || !!sharedWith?.some(user => user.id === userId && user.permissions.write)
  return { isEditor, isOwner }
}
