import { CalendlyEvent } from 'react-calendly/typings/calendly'
import { CalendlyBookingUrlItem, User } from 'types'
import React from 'react'
import { InlineWidget, CalendlyEventListener } from 'react-calendly'
import { Loader } from 'components/common'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  scheduleEvent: (code: string) => void
  lawField: CalendlyBookingUrlItem
  user: User
}

interface State {
  isLoading: boolean
  code: string
}

class CalendlyComponent extends React.Component<Props, State> {
  state = { isLoading: true, code: uuidv4() }

  shouldComponentUpdate(nextProps: Props, nextState: { isLoading: boolean }) {
    if (this.state.isLoading === true && nextState.isLoading === false) return true
    return false
  }

  isCalendlyEvent = (e: MessageEvent & { data: { event: CalendlyEvent } }): boolean =>
    e.data.event.startsWith('calendly')

  handleEventSheduled = (e: MessageEvent & { data: { event: CalendlyEvent } }): void => {
    const isCalendly = this.isCalendlyEvent(e)
    if (isCalendly && e.data.event === 'calendly.event_scheduled') {
      this.props.scheduleEvent(this.state.code)
    }
  }

  handleProfilePageViewed = (
    e: MessageEvent & { data: { event: CalendlyEvent } }
  ): void => {
    if (this.state.isLoading) this.setState({ isLoading: false })
  }

  render() {
    const { firstName, lastName, email } = this.props.user
    const { isLoading } = this.state
    const { lawField } = this.props

    return (
      <CalendlyEventListener
        onEventScheduled={this.handleEventSheduled}
        onProfilePageViewed={this.handleProfilePageViewed}
      >
        {isLoading && <Loader />}
        <InlineWidget
          url={lawField?.url ?? ''}
          prefill={{
            email,
            firstName,
            lastName,
            name: `${firstName} ${lastName}`,
          }}
          utm={{
            utmSource: this.state.code,
          }}
          styles={{ flex: isLoading ? 0 : 1, minHeight: isLoading ? 0 : 728 }}
        />
      </CalendlyEventListener>
    )
  }
}

export default CalendlyComponent
