import { Box, styled } from '@material-ui/core'
import { scrollBarStylesLight } from 'constants/common-styles'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxHeight: 'calc(100vh - 112px)',
  overflowY: 'auto',
  ...scrollBarStylesLight(theme),
}))
