import React, { Fragment } from 'react'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useFetchFolders } from 'queries/folders'

import { useLoadDocuments } from 'queries/documents'

import DocumentsList from './DocumentsList.component'
import FolderList from '../../../../../components/files/Folder/FolderList.container'
import FileAndFolderActions from 'components/files/FileAndFolderActions/FileAndFolderActions'
import { Loader } from 'components/common'

const DocumentsListPage: React.FC = () => {
  const { currentFolder } = useCurrentFolder('docs')
  const { data: docFiles } = useLoadDocuments(currentFolder)
  const { data: folders } = useFetchFolders('docs', currentFolder)

  return (
    <Fragment>
      {!docFiles || !folders ? (
        <Loader />
      ) : (
        <Fragment>
          <FolderList where='docs' />
          <DocumentsList />
          <FileAndFolderActions where='docs' />
        </Fragment>
      )}
    </Fragment>
  )
}

export default DocumentsListPage
