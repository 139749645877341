import { IDocument, ITemplate } from 'types'
import { ITemplateEditState } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useLocation } from 'react-router'
import { useQueryClient } from 'react-query'

import { queryKeys } from 'queries'
import { useUser } from 'queries/user/useUser'
import { StorageService } from 'utils'
import * as qT from 'constants/question-types'
import { documentsRoutes } from 'constants/routes'
import { userMapToIntercomUser } from './services/userMapToIntercomUser'

export const useIntercomAttributes = () => {
  const queryClient = useQueryClient()
  const user = useUser()
  const { pathname } = useLocation()

  const d = queryClient.getQueryData<{ pages: IDocument[][] }>([
    queryKeys.DOCUMENTS,
    { type: 'my', folderId: 'my' },
  ])
  const t = queryClient.getQueryData<{ pages: ITemplate[][] }>([
    queryKeys.TEMPLATES,
    { type: 'my', folderId: 'my' },
  ])

  const tcState: ITemplateEditState | null = StorageService.read('templateCreationState')

  const templateCreated =
    tcState?.step === 1 &&
    t &&
    t.pages?.flat()?.length === 1 &&
    tcState?.questions?.length === 0

  const documentCreated =
    pathname === documentsRoutes.main && d && d.pages?.flat()?.length === 1

  const question = tcState?.question

  return {
    ...userMapToIntercomUser(user),
    templateCreated,
    tcQuestionText: question?.type === qT.QUESTION_TYPE_TEXT_BOX,
    tcQuestionDate: question?.type === qT.QUESTION_TYPE_DATE,
    tcQuestionRadioLink: question?.type === qT.QUESTION_TYPE_RADIO_LINK,
    tcQuestionRadioReplacement: question?.type === qT.QUESTION_TYPE_RADIO_REPLACEMENT,
    tcQuestionCheckbox: question?.type === qT.QUESTION_TYPE_CHECKBOX,
    tcQuestionNumber: question?.type === qT.QUESTION_TYPE_NUMBER,
    documentCreated,
  }
}
