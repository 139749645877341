import { Question } from 'types'
import React, { useState, useCallback, useEffect, Fragment } from 'react'

import { useMasterData } from 'queries/app/useMasterdata'
import { useUser } from 'queries/user/useUser'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import { useEditor } from 'config/textEditor/useEditor'

import ActionButtons from '../../ActionButtons/ActionButtons'
import LabelAndDescriptionComponent from './LabelAndDescription.component'
import {
  btnContent,
  descriptionContent as content,
} from 'pages/Authenticated/Template/constants/content'
import { ScrollWrapper } from '../../../TemplateEdit.styles'
import {
  deepAddOrEditQuestions,
  deepFindParentFromCurrentQuestion,
  StorageService,
} from 'utils'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'

const initialErrorState: ILabelAndDescriptionErrorState = {
  label: false,
  description: false,
}

export interface ILabelAndDescriptionErrorState {
  label: boolean
  description: boolean
}

export interface ILabelAndDescriptionState {
  label: string
  description: string
  answerExample: string
  group: string
}

export const LabelAndDescriptionContainer: React.FC = () => {
  const editor = useEditor()
  const { set, addQuestion, questionStep, mainQuestion, question, questions } =
    useTemplateCreation()
  const superadmins = useMasterData()?.superadmins
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  const { tags, description, parentId, answerExample, group } = question!

  const [state, setState] = useState<ILabelAndDescriptionState>({
    label: tags[0],
    description,
    answerExample,
    group,
  })

  const [errors, setErrors] = useState(initialErrorState)

  const [mainIsParent, setMainIsParent] = useState(false)

  const hasSubQuestions = Boolean(question && question.options)

  const handleSaveQuestion = useCallback(
    (q: Question, mainIsParent: boolean): void => {
      const obj = {
        question: q,
        questionStep: 5,
        selectedQuestionType: q.type,
        mainQuestion: !q.parentId || mainIsParent ? null : mainQuestion,
      } as any
      if (!docXmicro) obj.htmlData = editor?.getBody().innerHTML

      set(obj)
    },
    [set, mainQuestion, editor, docXmicro]
  )

  useEffect(() => {
    if (mainQuestion && mainIsParent) {
      handleSaveQuestion(mainQuestion, mainIsParent)
      setMainIsParent(false)
    }
  }, [mainIsParent, handleSaveQuestion, mainQuestion])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setState((prev) => ({ ...prev, [name]: value }))
    // TODO IMPROVE
    // if (name === 'label' || name === 'description' || name === 'answerExample') {
    //   set({ [name]: value })
    // }
  }

  const saveLabelAndDescription = (): void => {
    const { label, description, answerExample, group } = state
    let tags = question?.tags ?? []
    tags[0] = label
    set({ question: { ...question!, tags, description, answerExample, group } })
  }

  const processCurrentQuestion = (): [Question, boolean] => {
    let _currentQuestion: Question = { ...question! }
    let returnToMainQ = false

    if (question?.returnToMainQ !== undefined) {
      if (question?.returnToMainQ) {
        returnToMainQ = true
      }
      delete _currentQuestion.returnToMainQ
    }
    return [_currentQuestion, returnToMainQ]
  }

  const setParentQuestionFromMain = () => {
    const parentQ = deepFindParentFromCurrentQuestion(mainQuestion!, question!)
    if (parentQ) return handleSaveQuestion(parentQ, false)
  }

  const onNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    StorageService.remove('textSelectionHtmlData')
    trackIntercomEvent('template-creation-added-description')
    setErrors(initialErrorState) // ADD SOME ERRORHANDLING ???
    saveLabelAndDescription()
    if (hasSubQuestions) return set({ questionStep: 5 })
    const [_currentQuestion, returnToMainQ] = processCurrentQuestion()
    if (!mainQuestion) return addQuestion(_currentQuestion)
    const obj = {
      questions: deepAddOrEditQuestions(questions, _currentQuestion, docXmicro),
      mainQuestion: mainQuestion
        ? deepAddOrEditQuestions([mainQuestion], _currentQuestion, docXmicro)[0]
        : null,
    } as any
    if (!docXmicro) obj.htmlData = editor?.getBody().innerHTML
    set(obj)
    if (parentId.startsWith(mainQuestion.id) || returnToMainQ)
      return setMainIsParent(true)
    if (mainQuestion) setParentQuestionFromMain()
  }

  const onBack = (): void => {
    set({ questionStep: questionStep - 1 })
  }

  return (
    <Fragment>
      <ScrollWrapper>
        <LabelAndDescriptionComponent
          values={state}
          handleChange={handleChange}
          errors={errors}
          heading={content.heading}
          handleBlur={saveLabelAndDescription}
          superadmin={!!superadmins?.includes(user?.email!)}
        />
      </ScrollWrapper>
      <ActionButtons
        handleBackClick={onBack}
        handleForwardClick={onNext}
        forwardDisabled={state.label.length < 3}
        isSubquestion={Boolean(question?.parentId && mainQuestion)}
        btnNextText={btnContent.next}
        lastStep={!hasSubQuestions}
        id={'template-creation-label'}
      />
    </Fragment>
  )
}

export default LabelAndDescriptionContainer
