import { IDocument } from 'types'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useDocumentsUpdate } from 'queries/documents'
import { useToast } from 'hooks/useToast'

import DocumentsApi from 'api/docs.api'

import {
  POPUP_CONFIRM_DOCUMENT_VERSIONING,
  POPUP_CONFIRM_EDIT_CHANGED_DOCUMENT,
} from './confirm-text'
import { Editor } from 'tinymce'
import { useUser } from 'queries/user/useUser'

export const useDocumentFileActions = () => {
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { push } = useHistory()
  const { check } = useSubscriptionCheck()
  const toast = useToast()
  const update = useDocumentsUpdate()

  const user = useUser()
  const docXMicro = !!user?.beta?.newDocxMicroservice

  const clearDocumentLS = useCallback(() => {
    localStorage.removeItem('initialDocValue')
    localStorage.removeItem('currentDocValue')
  }, [])

  const gotDirty = useCallback(() => {
    const initialHtmlData = localStorage.getItem("initialDocValue")
    const currentHtmlData = localStorage.getItem("currentDocValue")
    if (typeof initialHtmlData === 'string' && typeof currentHtmlData === 'string') {
      return initialHtmlData !== currentHtmlData
    }
    return false
  }, [])

  const handleGotDirtyActions = useCallback(
    (id: string, props: { dirty: boolean; cb: () => void }) => {
      const { dirty, cb } = props

      if (!dirty) {
        cb()
        return
      }
      setIsLoading(true)
      update.mutate(
        { id, data: { isDirty: true } },
        {
          onSuccess: () => {
            cb()
          },
          onSettled: () => {
            setIsLoading(false)
          },
        }
      )
    },
    [setIsLoading, update]
  )

  const handleLatestVersion = useCallback(
    (id: string, dirty: boolean, url: string) => {
      popup({
        ...POPUP_CONFIRM_DOCUMENT_VERSIONING,
        onCancel: () => {
          handleGotDirtyActions(id, {
            dirty,
            cb: () => {
              push(url)
              close()
              clearDocumentLS()
            },
          })
        },
        onConfirm: () => {
          handleGotDirtyActions(id, {
            dirty,
            cb: () => {
              clearDocumentLS()
              push(`${url}?useLatestTemplateVersion=true`)
              close()
            },
          })
        },
      })
    },
    [popup, push, clearDocumentLS, close, handleGotDirtyActions]
  )

  const onEdit = (docFile: IDocument) => {
    if (!check()) return
    const url = `/dokument/${docFile.id}/bearbeiten`
    const isLatestTemplateVersion = !!docFile?.isUpToDateWithTheLatestTemplateVersion
    const isDirty = !!docFile?.isDirty

    const gotDirtyByEditing = gotDirty()

    const dirtyCheck = isDirty || gotDirtyByEditing

    const POPUP = dirtyCheck
      ? POPUP_CONFIRM_EDIT_CHANGED_DOCUMENT
      : !isLatestTemplateVersion
        ? POPUP_CONFIRM_DOCUMENT_VERSIONING
        : null

    if (!POPUP) return push(url)

    if (POPUP) {
      popup({
        ...POPUP,
        onCancel: () => {
          close()
          if (dirtyCheck) return
          if (!isLatestTemplateVersion) push(url)
        },
        onConfirm: () => {
          if (dirtyCheck && isLatestTemplateVersion) {
            handleGotDirtyActions(docFile.id, {
              dirty: gotDirtyByEditing,
              cb: () => {
                clearDocumentLS()
                close()
                push(url)
              },
            })
            return
          }

          if (dirtyCheck && !isLatestTemplateVersion) {
            return handleLatestVersion(docFile.id, gotDirtyByEditing, url)
          }

          if (!dirtyCheck && !isLatestTemplateVersion) {
            push(`${url}?useLatestTemplateVersion=true`)
          } else {
            push(url)
          }
          clearDocumentLS()
          close()
        },
      })
    }
  }

  const onOpen = (documentId: string) => {
    clearDocumentLS()
    push(`/dokument/${documentId}`)
  }

  const onDownload = useCallback(
    async (id: string, format: 'docx' | 'pdf', name?: string, editor?: Editor) => {
      if (!check()) return
      if (format === 'pdf' && !editor) return
      if (format === 'pdf' && editor) {
        const prevTitle = document.title
        if (name) document.title = name
        editor.execCommand('mcePrint')
        if (name) document.title = prevTitle
        return
      }
      toast('info', 'Das Dokument wird heruntergeladen, einen Moment bitte...')
      await DocumentsApi.download(id, format, docXMicro)
    },
    [toast, check, docXMicro]
  )

  return { onEdit, onOpen, gotDirty, clearDocumentLS, onDownload }
}
