import React from 'react'
import { Container, Text } from './ErrorPage.styles'

const ErrorPage: React.FC = () => {
  return (
    <Container>
      <a href='/aktenschrank'>Back to Home</a>
      <Text>Something Went Wrong</Text>
    </Container>
  )
}

export default ErrorPage
