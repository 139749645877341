import React from 'react'
import { useParams } from 'react-router'

import { useDocument } from 'queries/documents'

import DocumentPreviewComponent from './components/DocumentPreview.component'
import { Loader } from 'components/common'

const DocumentPreview: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useDocument(id)

  if (!data || isLoading) return <Loader />

  return <DocumentPreviewComponent documentFile={data} />
}

export default DocumentPreview
