import React from 'react'
import { useHistory } from 'react-router'
import { ROUTE_UPGRADE_SUBSCRIPTION } from 'constants/routes'
import image from './image.png'
import { useModal } from 'hooks/useModal'
import ModalTemplate from './ModalTemplate/ModalTemplate'
import {
  UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC,
  UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NEW_SUBSCRIPTION,
  UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID,
} from 'constants/ui-elements'

export const UpgradeSubscriptionPlanModalBasic: React.FC = () => {
  const { push, location } = useHistory()
  const { closeModal } = useModal()

  const onAction = () => {
    push(ROUTE_UPGRADE_SUBSCRIPTION, { from: location.pathname })
    closeModal()
  }

  return (
    <ModalTemplate
      title='Bereit für den Einsatz als Profi?'
      text='Mit dem Team-Abo können Sie alle Premium-Funktionen von CASUS nutzen und Ihren juristischen Workflow auf eine ganz neue Ebene heben.'
      actionBtnText='Upgrade für CHF 350'
      image={image}
      onAction={onAction}
      onClose={() => closeModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC)}
    />
  )
}

export const UpgradeSubscriptionPlanModalNotPaid: React.FC = () => {
  // const { push, location } = useHistory()
  const { closeModal } = useModal()

  const onAction = () => {
    // push(ROUTE_UPGRADE_SUBSCRIPTION, { from: location.pathname })
    closeModal()
  }

  return (
    <ModalTemplate
      title='Sie haben keine aktive Lizenz'
      text='Bitte kontaktieren Sie CASUS um eine aktive Lizenz zu abonnieren. Klicken Sie einfach rechts auf das Chat-Zeichen.'
      actionBtnText=''
      image={image}
      onAction={onAction}
      onClose={() => closeModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID)}
    />
  )
}

export const UpgradeSubscriptionPlanModalNewSubscription: React.FC = () => {
  const { push, location } = useHistory()
  const { closeModal } = useModal()

  const onAction = () => {
    push(ROUTE_UPGRADE_SUBSCRIPTION, { from: location.pathname })
    closeModal()
  }

  return (
    <ModalTemplate
      open={false}
      title='Bitte wählen Sie ein Abo'
      text='Nutzen Sie unsere Vorlagen und Rechtsberatung bereits ab CHF 12.- pro Monat.'
      actionBtnText='Abo auswählen'
      image={image}
      onAction={onAction}
      onClose={() => closeModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NEW_SUBSCRIPTION)}
    />
  )
}
