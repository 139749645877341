import { LoaderProps } from './Loader.types'
import React from 'react'
import { LoaderContainer } from './Loader.styles'
import LoaderAnimation from '../../../assets/icons/loader.svg'

const Loader: React.FC<LoaderProps> = ({ style }) => {
  return (
    <LoaderContainer style={style}>
      <img src={LoaderAnimation} alt='' />
    </LoaderContainer>
  )
}

export default Loader
