import React, {useState} from 'react'
import {useToast} from 'hooks/useToast'
import UserApi from 'api/user.api'
import TwoFaSettings from 'pages/Authenticated/AccountSettings/views/TwoFaSettings/TwoFaSettings.component'
import {useUser} from 'queries/user/useUser'
import {firebase} from "config/firebase";
import SmsVerifyModal from "../../../../Auth/components/LoginForm/SmsVerifyModal/SmsVerifyModal";


let recaptchaVerifier: any = null

const TwoFaSettingsPage: React.FC = () => {
    const user = useUser()

    const [isLoading2FA, setIsLoading2FA] = useState(false)
    const [isLoadingPhone, setIsLoadingPhone] = useState(false)

    const [isPromptSMSVerify, setIsPromptSMSVerify] = useState(false)
    const [smsCode, setSmsCode] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneVerificationId, setPhoneVerificationId] = useState('')

    const toast = useToast()

    const switchTwoFa = async (twoFaEnabled: boolean) => {
        try {
            setIsLoading2FA(true)
            await UserApi.update({twoFaEnabled: twoFaEnabled})
            if (twoFaEnabled === false) {
                // Unlink an auth provider from a user account
                const options = firebase.auth().currentUser?.multiFactor.enrolledFactors
                // Ask user to select from the enrolled options.
                // @ts-ignore
                await firebase.auth().currentUser?.multiFactor.unenroll(options[0])
                    .then(function () {
                        // User successfully unenrolled selected factor.
                        console.info('unenrolled')
                    }).catch((e) => {
                        toast('error', 'Unable to disable phone auth')
                    });
            } else {
                // @ts-ignore
                await onUpdatePhoneNumber(firebase.auth().currentUser?.phoneNumber)
            }
            toast('success', `Two Factor Authorization ${twoFaEnabled ? 'enabled' : 'disabled'}`)
        } catch (err) {
            toast('error', 'default')
        } finally {
            setIsLoading2FA(false)
        }
    }

    const onSubmitPhoneNumber = async (phoneNumberPassed: string) => {

        try {
            setIsLoadingPhone(true)
            setPhoneNumber(phoneNumberPassed)

            // update and verify new phone
            try {
                if (recaptchaVerifier === null) {
                    recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
                        'size': 'invisible',
                        'callback': function (response: any) {
                        }
                    })
                }
            } catch (e) {
            }

            firebase.auth().currentUser?.multiFactor.getSession().then(function (multiFactorSession) {
                const phoneProvider = new firebase.auth.PhoneAuthProvider();
                const phoneInfoOptions = {
                    phoneNumber: phoneNumberPassed,
                    session: multiFactorSession
                }
                phoneProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                    .then(function (verificationId: any) {
                        setPhoneVerificationId(verificationId)
                        setIsPromptSMSVerify(true)
                    })
                    .catch((e: any) => {
                        if (e.code === 'auth/unverified-email') {
                            toast('warning', 'Email not verified, sent one now. Please verify and try again.')
                            UserApi.sendVerificationEmail()
                        } else if (e.code === 'auth/requires-recent-login') {
                            toast('error', 'need to re-login')
                        } else if (e.code === 'auth/second-factor-already-in-use') {
                            toast('error', 'Phone Already in use')
                        } else if (e.code === 'auth/user-token-expired') {
                            toast('error', 'Please re-login to continue')
                        } else {
                            console.error('verifyPhoneNumber', e.code, e.message)
                        }
                    })
                    .finally(() => {
                        setIsLoadingPhone(false)
                        return
                    })
            })


        } catch (e) {
            console.error('EF001', e)
        }
    }

    const onUpdatePhoneNumber = async (phoneNumberPassed: string) => {

        try {
            setIsLoadingPhone(true)
            setPhoneNumber(phoneNumberPassed)

            // update and verify new phone
            try {
                if (recaptchaVerifier === null) {
                    recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
                        'size': 'invisible',
                        'callback': function (response: any) {
                        }
                    })
                }
            } catch (e) {
            }

            firebase.auth().currentUser?.multiFactor.getSession().then(function (multiFactorSession) {
                // const phoneProvider = new firebase.auth.PhoneAuthProvider();
                // const phoneInfoOptions = {
                //     phoneNumber: phoneNumberPassed,
                //     session: multiFactorSession
                // }
                setIsPromptSMSVerify(true)
            })


        } catch (e) {
            console.error('EF001', e)
        }
    }

    const handleConfirmSMSCode = async () => {

        try {
            // setIsLoadingPhoneVerification(true)

            // Ask user for the verification code.
            const cred = firebase.auth.PhoneAuthProvider.credential(phoneVerificationId, smsCode);
            const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
            // Complete enrollment.
            await firebase.auth().currentUser?.multiFactor.enroll(multiFactorAssertion, user?.lastName)
            await UserApi.updatePhoneNumber({phoneNumber: phoneNumber})
            await switchTwoFa(true)
            setIsLoadingPhone(false)
            setIsPromptSMSVerify(false)

            toast('success', 'New Phone Number confirmed!')

        } catch (e) {
            console.error('', e.code, e.message)
            if (e.code === 'auth/invalid-verification-code') {
                toast('error', 'Invalid code')
            } else {
                console.error('EF002')
            }
        } finally {
            // setIsLoadingPhoneVerification(false)
        }
    }

    const handleCloseSMSCodeModal = () => {
        setIsPromptSMSVerify(false)
    }

    return <React.Fragment>
        <TwoFaSettings
            user={user}
            onChangeTwoFa={switchTwoFa}
            onSubmitPhoneNumber={onSubmitPhoneNumber}
            isLoading2FA={isLoading2FA}
            isLoadingPhone={isLoadingPhone}
        />
        <SmsVerifyModal
            open={isPromptSMSVerify}
            smsCode={smsCode}
            setSmsCode={setSmsCode}
            onAction={handleConfirmSMSCode}
            onClose={handleCloseSMSCodeModal}
        />
    </React.Fragment>
}

export default TwoFaSettingsPage
