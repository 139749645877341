import { IReminder, IReminderAlert } from 'types'
import { StandardModalProps } from 'components/common/Modals/StandardModal/StandardModal.types'
import React, { useState, useEffect, Fragment } from 'react'
import Validator from 'validator'

import {
  StandardModal,
  DatePicker,
  TextInputGroup,
  SelectGroup,
  CustomButton,
  Loader,
} from 'components/common'
import { Container, TextBold, Text, Row, Col } from './SetReminderModal.styles'
import { secondary } from 'config/themes/colors'
import { IReminderRequestApi } from 'api/reminders'

interface Props extends StandardModalProps {
  isLoading?: boolean
  isSaving?: boolean
  reminder?: IReminder | null
  onSubmit: (data: Omit<IReminderRequestApi, 'documentId'>) => void
}

const options = [
  { label: 'Tag/e', value: 'day' },
  { label: 'Woche/n', value: 'week' },
  { label: 'Monat/e', value: 'month' },
  { label: 'Jahr/e', value: 'year' },
]

const defaultDate = new Date()

export const SetReminderModalComponent: React.FC<Props> = ({
  isLoading,
  isSaving,
  reminder,
  onSubmit,
  ...props
}) => {
  const [reminderName, setReminderName] = useState('')
  const [deadlineTime, setDeadlineTime] = useState<Date>(defaultDate)
  const [at, setAt] = useState('1')
  const [selector, setSelector] = useState<IReminderAlert['selector']>('day')

  useEffect(() => {
    if (reminder) {
      setReminderName(reminder.name)
      const d = new Date(0)
      d.setUTCSeconds(reminder.deadlineTime._seconds)
      setDeadlineTime(d)
      setAt(reminder.alert.at.toString())
      setSelector(reminder.alert.selector)
    }
  }, [reminder])

  const disabled =
    !reminderName ||
    deadlineTime === defaultDate ||
    !selector ||
    isLoading ||
    isSaving ||
    !Validator.isNumeric(at)

  return (
    <StandardModal
      title={
        reminder || isLoading ? 'Erinnerung bearbeithen' : 'Erstellen Sie eine Erinnerung'
      }
      {...props}
    >
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <TextBold>{reminder ? <br /> : 'Neue Erinnerung'}</TextBold>
            <Row>
              <Col>
                <Text>Titel</Text>
                <TextInputGroup
                  type='text'
                  value={reminderName}
                  onChange={({ target }) => setReminderName(target.value)}
                  style={{ height: 40, borderWidth: 2 }}
                />
              </Col>

              <Col>
                <Text>Deadline</Text>
                <DatePicker
                  value={defaultDate === deadlineTime ? null : deadlineTime}
                  handleChange={val => setDeadlineTime(val ? val : defaultDate)}
                  variant='light'
                  pickerStyles={{ height: 40, width: 247 }}
                  disablePast
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 72 }}>
              <Col>
                <Text>Benachrichtigung im Voraus</Text>
                <Row>
                  <TextInputGroup
                    type='number'
                    name='at'
                    value={at}
                    onChange={({ target }) => setAt(target.value)}
                    style={{ height: 40, borderWidth: 2 }}
                    centerInputValue
                    InputGroupContainerProps={{
                      style: { width: '40%', marginRight: 16 },
                    }}
                  />

                  <SelectGroup
                    value={selector}
                    customvariant='thick'
                    options={options}
                    InputElProps={{
                      style: {
                        height: 40,
                        marginBottom: 24,
                        borderColor: secondary[100],
                      },
                    }}
                    name='selector'
                    handleChange={e =>
                      setSelector(e.target.value as IReminderAlert['selector'])
                    }
                    optionMenuStyles={{ borderColor: secondary[100] }}
                  />
                </Row>
              </Col>
            </Row>
            <CustomButton
              btntype='primary'
              width={211}
              disabled={disabled}
              onClick={() =>
                onSubmit({
                  reminderName,
                  deadlineTime,
                  alert: { at: parseInt(at), selector },
                  reminderMessage: '',
                })
              }
            >
              {reminder ? 'Änderungen speichern' : 'Erinnerung hinzufügen'}
            </CustomButton>
          </Fragment>
        )}
      </Container>
    </StandardModal>
  )
}
