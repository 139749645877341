import { useQueryClient } from 'react-query'
import { FolderItemSimple } from 'types'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useFolderKeys } from './useFolderKeys'

export const useFolders = (
  where: 'templates' | 'docs'
): FolderItemSimple[] | undefined => {
  const {
    currentFolder: { id },
  } = useCurrentFolder(where)
  const [many] = useFolderKeys(where)
  const queryClient = useQueryClient()
  const keys = [many, id]
  const data = queryClient?.getQueryData<FolderItemSimple[]>(keys)

  return data
}
