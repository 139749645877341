import { CASUS_CLASSES } from './parsing'

export const getNodeLength = (node = {}) =>
  node.nodeName === '#text'
    ? node.length
    : Array.from(node.childNodes || []).reduce((acc, cur) => acc + getNodeLength(cur), 0)

const getOffsetWithinSpecificParent = (node = {}, startingOffset = 0, conditionalFunction) => {
  const parentNode = node.parentNode
  if (typeof conditionalFunction === 'function') {
    let accumulatedOffset = startingOffset
    Array.from(parentNode?.childNodes || []).every(cur => {
      if (cur !== node) accumulatedOffset += getNodeLength(cur)
      return cur !== node
    })
    if (conditionalFunction(parentNode)) return [accumulatedOffset, parentNode]
    const [parentOffset, parent] = getOffsetWithinSpecificParent(parentNode, 0, conditionalFunction)
    return [accumulatedOffset + parentOffset, parent]
  }
  return [startingOffset, null]
}

export const findCommonParent = (startArray = [], endArray = []) => {
  let commonParent = null
  startArray.every(sNode => endArray.every(eNode => !(commonParent = sNode === eNode ? sNode : null)))
  return commonParent
}

export const getUpstreamNodes = (node = {}, direction = 'start') => {
  const parentNode = node.parentNode
  if (!parentNode) return []
  const siblings = parentNode.childNodes
  const index = Array.from(siblings).findIndex(n => n === node)
  const lookAhead = index === Number(direction === 'end') * (siblings.length - 1)
  if (lookAhead && parentNode.id !== CASUS_CLASSES.pageContentRoot)
    return [parentNode, ...getUpstreamNodes(parentNode, direction)]
  return [parentNode]
}

// const getDownstreamNodes = (node = {}, offset = 0, direction = 'start') => {
//   const res = [node]
//   const length = node.childNodes?.length
//   if (length && offset <= length - Number(direction === 'start')) {
//     const childNode = node.childNodes[offset - Number(direction === 'end')]
//     const innerOffset = childNode?.childNodes?.length
//     if (childNode) res.push(...getDownstreamNodes(childNode, direction === 'start' ? 0 : innerOffset, direction))
//   }
//   return res
// }

const findNodeWithContentDeep = (node = {}, offset = 0, direction = 'start') => {
  // console.log('DEEP NODE: ', node)
  const isTextNode = node.nodeName === '#text'
  const length = isTextNode ? node.length : node.childNodes.length
  const isOnEdge = offset === 0 - Number(direction === 'end' && !isTextNode) + Number(direction === 'start') * length
  if (length && !isOnEdge) {
    if (isTextNode) return [node, offset]
    else {
      const childNodes = Array.from(node.childNodes || [])
      if (childNodes.length) {
        const firstNodeToCheck = childNodes[offset]
        if (firstNodeToCheck) {
          const isChildTextNode = firstNodeToCheck.nodeName === '#text'
          const innerNodeLength = isChildTextNode
            ? firstNodeToCheck.length
            : Array.from(firstNodeToCheck.childNodes || []).length
          if (innerNodeLength) {
            const offsetWithin = Number(direction === 'end') * (innerNodeLength - Number(!isChildTextNode))
            const res = findNodeWithContentDeep(firstNodeToCheck, offsetWithin, direction)
            return res
          }
        }
      }
      const nextOffset = offset + (-1) ** Number(direction === 'end')
      if (nextOffset >= 0) return findNodeWithContentDeep(node, nextOffset, direction)
    }
  }
  return null
}

const findNodeWithContentShallow = (node = {}, offset = 0, direction = 'start') => {
  // console.log('SHALLOW NODE: ', node)
  const res = findNodeWithContentDeep(node, offset, direction)
  if (res) return res
  let nodeToCheck = node
  let parentNode = null
  let offsetWithinParent = null
  let isOnEdge = true
  do {
    const tempNode = nodeToCheck
    parentNode = tempNode.parentNode
    const siblings = Array.from(parentNode.childNodes)
    offsetWithinParent = siblings.findIndex(n => n === tempNode) + Number(direction === 'start')
    const length = siblings.length
    isOnEdge = offsetWithinParent === Number(direction === 'start') * length
    nodeToCheck = parentNode
  } while (isOnEdge && parentNode.id !== CASUS_CLASSES.pageContentRoot)
  if (isOnEdge) return null
  return findNodeWithContentShallow(nodeToCheck, offsetWithinParent - Number(direction === 'end'), direction)
}

export const getRange = (selection = {}) => {
  const { rangeCount } = selection
  if (rangeCount) {
    if (rangeCount === 1) {
      const { startContainer, startOffset, endContainer, endOffset } = selection.getRangeAt(0)
      return { startContainer, startOffset, endContainer, endOffset }
    }
    const startRange = selection.getRangeAt(0)
    const endRange = selection.getRangeAt(rangeCount - 1)
    const { startContainer, startOffset } = startRange
    const { endContainer, endOffset } = endRange
    return { startContainer, startOffset, endContainer, endOffset }
  }
  return {}
}

export const generateOffsets = (selection = {}) => {
  // console.log('SELECTION: ', selection)

  const range = getRange(selection)
  // console.log('RANGE: ', range)
  // const { startContainer, startOffset, endContainer, endOffset } = range
  // console.log('---------- RANGE: ----------')
  // console.log('START : ', startContainer, startOffset)
  // console.log('END: ', endContainer, endOffset)

  const keys = ['start', 'end']

  const nodeArrays = keys.reduce((acc, key) => {
    const node = range[`${key}Container`]
    const offset = range[`${key}Offset`]

    const res = findNodeWithContentShallow(node, offset, key)

    if (res) {
      const [nodeWithContent, calculatedOffset] = res
      // console.log('CLOSEST NODE WITH CONTENT: ', nodeWithContent, calculatedOffset)

      const nodeParent = nodeWithContent.parentNode
      const length = getNodeLength(nodeParent)
      const [offsetWithinParent] = getOffsetWithinSpecificParent(
        nodeWithContent,
        calculatedOffset,
        parent => parent === nodeParent
      )
      const array = [nodeWithContent, nodeParent]
      const position = offsetWithinParent === 0 || offsetWithinParent === length ? 'edge' : 'middle'
      if (position === 'edge') array.push(...getUpstreamNodes(nodeParent, key))

      // console.log('NODE ARRAY: ', array)
      acc[key] = { array: array, offset: calculatedOffset }
    }
    return acc
  }, {})

  // console.log('NODE ARRAYS: ', nodeArrays)

  const hasArrays = nodeArrays.start && nodeArrays.end

  if (!hasArrays) {
    console.log('%cINVALID SELECTION!', 'color:red')
    return null
  }

  const { array: startArray, offset: startCalculatedOffset } = nodeArrays.start
  const { array: endArray, offset: endCalculatedOffset } = nodeArrays.end

  // console.log('START: ', startArray, startCalculatedOffset)
  // console.log('END: ', endArray, endCalculatedOffset)

  const commonParent = findCommonParent(startArray, endArray)

  if (!commonParent) {
    console.log('%cINVALID SELECTION!', 'color:red')
    return null
  }

  console.log('%cVALID SELECTION!', 'color:green')
  console.log('COMMON PARENT: ', commonParent)

  const startNode = startArray[0]
  const endNode = endArray[0]

  if (startNode !== endNode) {
    const [startOffsetInCommonParent] = getOffsetWithinSpecificParent(
      startNode,
      startCalculatedOffset,
      parent => parent === commonParent
    )

    const [endOffsetInCommonParent] = getOffsetWithinSpecificParent(
      endNode,
      endCalculatedOffset,
      parent => parent === commonParent
    )

    if (commonParent.id) {
      // return [startOffsetInCommonParent, endOffsetInCommonParent, commonParent.id]
      return { parentId: commonParent.id, startOffset: startOffsetInCommonParent, endOffset: endOffsetInCommonParent }
    }

    const [startOffset, startParent] = getOffsetWithinSpecificParent(
      commonParent,
      startOffsetInCommonParent,
      parent => parent.id
    )
    const [endOffset, endParent] = getOffsetWithinSpecificParent(
      commonParent,
      endOffsetInCommonParent,
      parent => parent.id
    )

    if (startParent !== endParent) return null

    // console.log('COMMON ID PARENT: ', startParent)

    // return [startOffset, endOffset, startParent.id]
    return { parentId: startParent.id, startOffset, endOffset }
  }

  const [parentOffset, idParent] = getOffsetWithinSpecificParent(startNode, 0, parent => parent.id)
  // return [startDeepOffset + parentOffset, endDeepOffset + parentOffset, idParent.id]
  return {
    parentId: idParent.id,
    startOffset: startCalculatedOffset + parentOffset,
    endOffset: endCalculatedOffset + parentOffset,
  }
}
