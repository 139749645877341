import React from 'react'
import { useHistory } from 'react-router'
import { useQueryClient } from 'react-query'

import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useRootFolder } from 'hooks/useRootFolder'
import { useTemplates } from 'queries/templates/useTemplates'
import { useFolders } from 'queries/folders/useFolders'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useToast } from 'hooks/useToast'

import PagesLayout from 'layout/PagesLayout/PagesLayout'

import { IPopupMenuItem } from 'components/common/MenuPopover/MenuPopover.types'
import { FileManager } from 'utils'
import { Container } from '../../../Authenticated.page.styles'
import TemplatesSidebar from './TemplatesSidebar/TemplatesSidebar'

import { queryKeys } from 'queries/queryKeys'
import TemplatesApi from 'api/templates.api'
import { POPUP_CONFIRM_DELETE_ALL_FILES } from 'constants/confirmation-popups'

export const TemplatesLayout: React.FC = ({ children }) => {
  const { location, push } = useHistory()
  const { currentFolder, setCurrentFolder } = useCurrentFolder('templates')
  const { rootFolder } = useRootFolder('templates')
  const { check } = useSubscriptionCheck()
  const queryClient = useQueryClient()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const toast = useToast()

  const { type } = currentFolder
  const templates = useTemplates()
  const foldersData = useFolders('templates')

  const onEmptyBin = async () => {
    setIsLoading(true)
    try {
      await TemplatesApi.emptyTrashed()
      queryClient.invalidateQueries([
        queryKeys.TEMPLATES,
        { type: 'trashed', folderId: 'trashed' },
      ])
      queryClient.invalidateQueries([queryKeys.TEMPLATE_FOLDERS, 'trashed'])
      close()
    } catch (err) {
      toast('error', 'default')
    } finally {
      setIsLoading(false)
    }
  }

  const getMenuItems = (): IPopupMenuItem[] => {
    const menuItems: IPopupMenuItem[] = []
    switch (type) {
      case 'my':
        if (type === 'my') {
          menuItems.push({
            label: 'Neuer Ordner',
            icon: 'add-folder',
            handleClick: () => {
              if (check({ isFree: true })) push(`${location.pathname}?createFolder=true`)
            },
          })
        }
        break
      case 'trashed':
        menuItems.push({
          label: 'Papierkorb leeren',
          icon: 'trash',
          handleClick: () =>
            popup({
              ...POPUP_CONFIRM_DELETE_ALL_FILES,
              onConfirm: onEmptyBin,
              onCancel: close,
            }),
        })
    }
    return menuItems
  }

  const onPathClick = (folderId: string) => {
    const fileManager = new FileManager(rootFolder, currentFolder)
    const folder = fileManager.findFolderById(folderId)
    if (folder) setCurrentFolder(folder)
  }

  return (
    <Container>
      <TemplatesSidebar />
      <PagesLayout
        isLoading={!foldersData || !templates}
        menuItems={getMenuItems()}
        breadcrumbNavProps={{
          folder: currentFolder,
          handlePathClick: onPathClick,
        }}
      >
        {children}
      </PagesLayout>
    </Container>
  )
}
