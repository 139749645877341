import { IOption } from 'types'
import { ICheckboxOptionState, QuestionCheckboxProps } from './QuestionCheckbox.types'
import React, { useState } from 'react'
import {
  CheckboxButton,
  FormControlLabel,
  FormControl,
  FormGroup,
} from './QuestionCheckbox.styles'

const optionsToState = (options: IOption[], arrOfIds: string[]) => {
  if (!options || !options.length) return {}

  interface IObj {
    [key: string]: ICheckboxOptionState
  }
  const obj: IObj = {}

  for (let opt of options) {
    obj[opt.id] = { id: opt.id, checked: arrOfIds.includes(opt.id) }
  }

  return obj
}

const QuestionCheckbox: React.FC<QuestionCheckboxProps> = ({
  question,
  value,
  handleChange,
  disabled,
}) => {
  const [state, setState] = useState(optionsToState(question.options, value))
  const [selectedIds, setSelectedIds] = useState([...value])

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target
    const answerValue = checked
      ? [...selectedIds, name]
      : selectedIds.filter(id => id !== name)

    setState(prev => ({ ...prev, [name]: { ...prev[name], checked } }))
    setSelectedIds(answerValue)
    handleChange(answerValue)
  }

  return (
    <FormControl
      component='fieldset'
      style={{ marginBottom: 24, pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <FormGroup>
        {question.options.map((opt, i) => (
          <FormControlLabel
            key={opt.id}
            control={
              <CheckboxButton
                checked={state[opt.id].checked}
                onChange={handleCheckboxChange}
                name={opt.id}
              />
            }
            label={opt.text}
            style={i !== question.options.length - 1 ? { marginBottom: 16 } : undefined}
            // error={error ? 'true' : null}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default QuestionCheckbox
