import { IConfirmationPopupContent } from 'types'
import { useEffect, useState } from 'react'
import Validator from 'validator'
import { useLocation } from 'react-router'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'

const POPUP_BROWSER_VERSION: IConfirmationPopupContent = {
  heading: 'Browser aktualisieren',
  description:
    'Die CASUS-App läuft am besten mit der aktuellsten Browser-Version. Bitte aktualisieren Sie Ihren Browser, um mögliche Probleme zu vermeiden.',
  status: 'warning',
  primaryButtonText: 'Browser updaten',
  secondaryButtonText: 'Nicht jetzt',
}

const getBrowserAndVersion = (): string => {
  let ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ')
}
interface IVersions {
  chrome: number
  safari: number
  firefox: number
  [key: string]: string | number
}
const versions: IVersions = {
  chrome: 89,
  safari: 14,
  firefox: 83,
}

const isBrowserOutdated = (): [boolean, string?, string?] => {
  const browserVersion = getBrowserAndVersion()
  const [_b, version] = browserVersion.split(' ')
  const browser = _b.toLowerCase()
  if (browser && version && Validator.isNumeric(version) && versions[browser]) {
    const userVersion = parseInt(version)
    return [versions[browser] > userVersion, browser, version]
  }
  return [false, browser, version]
}

const [isOutdated, browser] = isBrowserOutdated()

const GOOGLE_URL = 'https://www.google.com/chrome/update/'
const FIREFOX_URL =
  'https://support.mozilla.org/de/kb/firefox-auf-die-letzte-version-aktualisieren'
const SAFARI_URL = 'https://support.apple.com/de-de/HT204416'

export const useBrowserVersionCheck = () => {
  const [seen, setSeen] = useState(false)
  const { pathname } = useLocation()
  const { popup, close } = useConfirmPopup()

  useEffect(() => {
    if (seen || !isOutdated) return
    if (browser && !['chrome', 'firefox', 'safari'].includes(browser)) return
    if (!pathname.startsWith('/vorlage/' || '/dokument/')) return
    if (pathname.endsWith('/pdf')) return
    popup({
      ...POPUP_BROWSER_VERSION,
      onConfirm: () => {
        switch (browser) {
          case 'chrome':
            window.open(GOOGLE_URL)
            break
          case 'firefox':
            window.open(FIREFOX_URL)
            break
          case 'safari':
            window.open(SAFARI_URL)
            break
        }
        close()
        setSeen(true)
      },
      onCancel: close,
    })
  }, [pathname, seen, popup, close])
}
