import { styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 26,
  fontWeight: 500,
  lineHeight: 1.23,
  letterSpacing: 0.2,
  color: theme.colorz.secondary.main,
  marginBottom: 49,
}))
