export const primary = {
  main: '#6699CC',
  dark: '#3F5F7F',
  dark2: '#203346',
  light: '#94B7DB',
}

export const secondary = {
  main: '#001640',
  default: '#001640',
  '50': '#F7F7F9',
  '100': '#D9DCE2',
  '200': '#9CA4B5',
  '300': '#66738D',
}

export const status = {
  error: '#CC6666',
  warning: secondary.main,
  success: '#66CC86',
  info: primary.light,
}

export const brand = {
  main: '#F3EEEA',
  dark: '#DAD5D2',
  light: '#FBFAF9',
}

export const grey = {
  main: '#747782',
  dark: '#393D4C',
  light: '#AFB1B7',
}

export const utils = {
  opaque: 'rgba(217,220,226,.9)',
}

export const text = {
  light: '#888a93',
}

const allColors = {
  primary,
  secondary,
  status,
  brand,
  grey,
  utils,
  text,
}

export default allColors
