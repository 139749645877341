import { Question } from 'types'
import { QuestionProps } from './Question.types'
import React, { Fragment } from 'react'
import QuestionComponent from './Question'
import { SubquestionContainer } from './Question.styles'
import { extractLocationsAndSubquestions } from 'utils'

const renderMoreSubquestions = (subquestions: Question[], props:QuestionProps) => {
  if (subquestions) {
    return subquestions.map(_subqItem => (
      <Subquestion {...props} key={_subqItem.id} question={_subqItem} />
    ))
  }
}

const Subquestion: React.FC<QuestionProps> = props => {
  const [, _subquestions] = extractLocationsAndSubquestions(props.question)
  return (
    <SubquestionContainer style={{width: '95%'}}>
      <QuestionComponent {...props} />
      {renderMoreSubquestions(_subquestions, props)}
    </SubquestionContainer>
  )
}

export const QuestionWithSubquestions:React.FC<QuestionProps> = props => {
  const [, _subquestions] = extractLocationsAndSubquestions(props.question)
  return (
    <Fragment>
      <QuestionComponent {...props} />
      <SubquestionContainer style={{width: '87.5%'}}>
        {renderMoreSubquestions(_subquestions, { ...props, noIndex: true })}
      </SubquestionContainer>
    </Fragment>
  )
}

export default QuestionWithSubquestions
