
import React, { Fragment } from 'react'
import { useLocation } from 'react-router'

import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import CreateFolder from './createFolder/CreateFolder.container'
import FileManagerModal from './move/FileManagerModal.container'
import { FileSharing } from './share/FileSharing'
import { FolderSharing } from './share/FolderSharing'

interface Props {
  where: 'templates' | 'docs'
}

const FileAndFolderActions: React.FC<Props> = ({ where }) => {
  const { search } = useLocation()
  const { currentFolder } = useCurrentFolder(where)
  const { isExpired} = useSubscriptionCheck()
  const params = new URLSearchParams(search)
  const fileId = params.get('file')
  const folderId = params.get('folder')
  const action = params.get('action')
  const showCreateFolder = params.get('createFolder')

  const showFileManager =
    currentFolder.type !== 'shared' &&
    !isExpired &&
    action === 'move'

  const isMy = currentFolder.type === 'my'
  const shareFileId = isMy && action === 'share' && fileId
  const shareFolderId = isMy && action === 'share' && folderId

  return (
    <Fragment>
      {showCreateFolder && <CreateFolder where={where} />}
      {showFileManager && <FileManagerModal where={where} />}
      {shareFileId && <FileSharing where={where} shareFileId={shareFileId} />}
      {shareFolderId && <FolderSharing where={where} shareFolderId={shareFolderId} />}
    </Fragment>
  )
}

export default FileAndFolderActions
