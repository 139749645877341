import { styled, Typography } from '@material-ui/core'
import React from 'react'

const Container = styled('div')(({ theme }) => ({ marginBottom: theme.spacing(5) }))

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: 0.4,
  textAlign: 'center',
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0.32,
  textAlign: 'center',
  color: theme.colorz.secondary[300],
  marginTop: theme.spacing(1),
}))

const TopContent: React.FC = () => {
  return (
    <Container>
      <Heading>Wählen Sie ein Abo</Heading>
      <SubHeading>
        Geben Sie Ihrem Team die Funktionen, die es für seinen Erfolg benötigt.
      </SubHeading>
    </Container>
  )
}

export default TopContent
