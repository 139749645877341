import { ProfileImageEl, NoProfileImageEl } from './ProfileImage.types'
import { styled, CardMedia, Box } from '@material-ui/core'

export const Image = styled(CardMedia)(({ size }: ProfileImageEl) => ({
  height: size,
  width: size,
  flexShrink: 0,
}))

export const NoImage = styled(Box)(({ theme, size }: NoProfileImageEl) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colorz.secondary[100],
  color: theme.colorz.secondary.main,
  fontSize: size / 2.5,
  fontWeight: 700,
  letterSpacing: 0.4,
  height: size,
  width: size,
  flexShrink: 0,
  textTransform: 'uppercase',
}))
