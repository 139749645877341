const employeeCountOptions = [
  { label: '1-10', value: '1-10' },
  { label: '11-50', value: '11-50' },
  { label: '51-100', value: '51-100' },
  { label: '100+', value: '100+' },
]

export const branchesOptions = [
  { label: 'Gewerbe & Handwerk', value: 'Gewerbe & Handwerk' },
  { label: 'Baugewerbe', value: 'Baugewerbe' },
  { label: 'Land- & Forstwirtschaft', value: 'Land- & Forstwirtschaft' },
  { label: 'Industrie', value: 'Industrie' },
  { label: 'Handel', value: 'Handel' },
  { label: 'Logistik', value: 'Logistik' },
  {
    label: 'Finanz- & Versicherungsdienstleistungen',
    value: 'Finanz- & Versicherungsdienstleistungen',
  },
  { label: 'Rechtsdienstleistungen', value: 'Rechtsdienstleistungen' },
  { label: 'Gesundheits- & Sozialwesen', value: 'Gesundheits- & Sozialwesen' },
  { label: 'Software', value: 'Software' },
  { label: 'Dienstleistungen', value: 'Dienstleistungen' },
  { label: 'Anderes', value: 'Anderes' },
]

interface FormFieldCommon {
  label: string
  placeholder: string
  type: string
  required: boolean
}

interface SelectFieldCommon {
  label: string
  placeholder: string
  options: {
    label: any
    value: any
  }[]
}

interface FormFieldStepOne extends FormFieldCommon {
  name: 'name' | 'website'
}

interface FormFieldStepTwo extends FormFieldCommon {
  name: 'firstName' | 'lastName' | 'jobPosition'
}

interface SelectStepOne extends SelectFieldCommon {
  name: 'employeeCount' | 'field'
}

export const profileInfoStepOneTextFields: FormFieldStepOne[] = [
  {
    label: 'Name Ihres Unternehmens*',
    placeholder: 'z.B. Firma AG',
    name: 'name',
    type: 'text',
    required: true,
  },
  {
    label: 'Webseite Ihres Unternehmens',
    placeholder: 'www.beispiel.ch',
    name: 'website',
    type: 'text',
    required: true,
  },
]

export const profileInfoStepOneSelectFields: SelectStepOne[] = [
  {
    label: 'Anzahl Mitarbeitende*',
    name: 'employeeCount',
    placeholder: 'Option auswählen',
    options: employeeCountOptions,
  },
  {
    label: 'Branche*',
    name: 'field',
    placeholder: 'Option auswählen',
    options: branchesOptions,
  },
]

export const profileInfoStepTwoTextFields: FormFieldStepTwo[] = [
  {
    label: 'Ihr Vorname*',
    placeholder: 'z.B. Marina',
    name: 'firstName',
    type: 'text',
    required: true,
  },
  {
    label: 'Ihr Nachname*',
    placeholder: 'z.B. Wagner',
    name: 'lastName',
    type: 'text',
    required: true,
  },
  {
    label: 'Ihre Position*',
    name: 'jobPosition',
    placeholder: 'z.B. Anwalt',
    type: 'text',
    required: true,
  },
]

export const footerText =
  'Wir interessieren uns für diese Informationen, um Ihnen die ersten Schritte mit CASUS zu erleichtern.'

export const formTitle = 'Registrierung'

interface Iheadings {
  [key: string]: string
}

export const headings: Iheadings = {
  '0': 'Erzählen Sie uns von Ihrem Unternehmen',
  '1': 'Erzählen Sie uns von sich',
  '2': 'Profilbild hochladen',
}
