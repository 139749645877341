import React from 'react'
// import close from '../../../assets/icons/close.svg'

const classes = {
  wrapper: 'Wizard-Header-wrapper',
  button: {
    wrapper: 'Wizard-Header-Button-wrapper',
  },
}

const Button = React.memo(props => {
  const { handler } = props
  return (
    <button className={classes.button.wrapper} type="button" onClick={handler}>
      {/* <img src={close} alt="Close!" /> */}
    </button>
  )
})

Button.displayName = 'Button'

export const Header = React.memo(props => {
  const { onCloseHandler } = props
  return (
    <section className={classes.wrapper}>
      <Button handler={onCloseHandler} />
    </section>
  )
})

Header.displayName = 'Wizard-Header'

export default Header
