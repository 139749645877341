import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const ActionButtonsContainer = styled(Box)({
  display: 'flex',
  padding: '0 40px',
})

export const BtnContentLastStep = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export { Typography }
