import { NoItemsActionProps } from './NoItemsAction.types'
import React from 'react'
import {
  Container,
  ActionsContainer,
  Text,
  ActionText,
  Image,
} from './NoItemsAction.styles'

const NoItemsAction: React.FC<NoItemsActionProps> = ({
  handleActionClick,
  text,
  actionText = 'Action Text',
  feature,
  image,
}) => (
  <Container>
    <ActionsContainer feature={feature ? 1 : 0}>
      <Image src={image} alt='documents icon' />
      <Text variant='body1'>{text}</Text>
      {handleActionClick && (
        <ActionText variant='body1' onClick={handleActionClick}>
          {actionText}
        </ActionText>
      )}
    </ActionsContainer>
  </Container>
)

export default NoItemsAction
