import { Box, CircularProgress, styled } from '@material-ui/core'
import React from 'react'

const SpinnerEl = styled(CircularProgress)(({ theme }) => ({
  color: theme.colorz.primary.main,
  margin: '12px auto 24px auto',
}))

const SpinnerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 528.4,
}))

const Spinner: React.FC = () => {
  return (
    <SpinnerWrapper>
      <SpinnerEl />
    </SpinnerWrapper>
  )
}

export default Spinner
