import React, { Fragment } from 'react'
import { CalendlyBookingUrlItem } from 'types'
import LawField from './LawField'
import { LawFieldsContainer, FieldsWrapper, PageTitle } from './LawFields.styles'

interface Props {
  onFieldSelect: (field: CalendlyBookingUrlItem) => void
  lawFields: CalendlyBookingUrlItem[]
}

const LawFields: React.FC<Props> = ({ onFieldSelect, lawFields }) => {
  return (
    <LawFieldsContainer>
      <FieldsWrapper>
        <PageTitle style={{ gridRowStart: 0 }}>{ /* Beliebteste  */}</PageTitle>
        {lawFields.map((lawFieldItem, i) => (
          <Fragment key={lawFieldItem.url + lawFieldItem.name}>
            {i === 3 && (
              <PageTitle style={{ gridRowStart: 3 }}>{ /* Weitere Lebensbereiche */ }</PageTitle>
            )}
            <LawField
              lawField={lawFieldItem}
              onFieldSelect={() => onFieldSelect(lawFieldItem)}
            />
          </Fragment>
        ))}
      </FieldsWrapper>
    </LawFieldsContainer>
  )
}

export default LawFields
