import React from 'react'
import { TextareaGroup } from 'components/common'
import { Container } from './CreateQuestion.styles'
import { forms as content } from 'pages/Authenticated/Template/constants/content'

interface Props {
  error?: boolean
  value: string
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>
}

const CreateQuestionComponent: React.FC<Props> = ({ handleChange, error, value }) => {
  return (
    <Container>
      <TextareaGroup
        label={content.createQuestionLabel}
        name='question'
        onChange={handleChange}
        value={value}
        error={error}
      />
    </Container>
  )
}

export default CreateQuestionComponent
