import { TextInputGroupProps, IPasswordIcon, LabelUrlProps } from './TextInputGroup.types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  InputGroupContainer,
  InputEl,
  Label,
  VisibilityIcon,
  VisibilityOffIcon,
  IconWrapper,
  InputWrapper,
  LabelUrlText,
  ErrorMessage,
} from './TextInputGroup.styles'
import { Icon } from 'components/common'
import { Typography } from '@material-ui/core'

const LabelUrl: React.FC<LabelUrlProps> = ({ url, text }) => (
  <Link to={url} style={{ marginLeft: 'auto' }}>
    <LabelUrlText variant='body2'>{text}</LabelUrlText>
  </Link>
)

const PasswordIcon: React.FC<IPasswordIcon> = ({ type, inputType, handleIconClick }) => {
  if (type !== 'password') return null
  let passwordIcon = null
  if (type === 'password') {
    if (inputType === 'password') {
      passwordIcon = <VisibilityIcon onClick={() => handleIconClick('text')} />
    } else {
      passwordIcon = <VisibilityOffIcon onClick={() => handleIconClick('password')} />
    }
  }
  return <IconWrapper>{passwordIcon}</IconWrapper>
}

const TextInputGroup = React.forwardRef(
  (props: TextInputGroupProps, ref?: React.Ref<HTMLInputElement>) => {
    const {
      label,
      error,
      InputGroupContainerProps,
      LabelProps,
      type = 'text',
      disabled,
      name,
      placeholder,
      customvariant,
      value,
      labelUrl,
      errorMessage,
      icon,
      centerInputValue,
      ...otherProps
    } = props
    const [inputType, setInputType] = useState('password')

    return (
      <InputGroupContainer {...InputGroupContainerProps}>
        {label && (
          <Label variant='h5' {...LabelProps}>
            {label}
            {labelUrl && <LabelUrl {...labelUrl} />}
          </Label>
        )}
        <InputWrapper>
          <InputEl
            name={name}
            type={type !== 'password' ? type : inputType}
            haserror={error ? 1 : 0}
            fullWidth
            disabled={disabled}
            placeholder={placeholder}
            customvariant={customvariant}
            value={value}
            ref={ref}
            center={centerInputValue ? 1 : 0}
            {...otherProps}
          />
          <PasswordIcon
            type={type}
            inputType={inputType}
            handleIconClick={setInputType}
          />
          {((error && errorMessage) || icon) && (
            <Icon
              iconName={error ? 'error' : icon ? icon.name : 'error'}
              style={{
                position: 'absolute',
                right: 16,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              fill={icon?.fill}
            />
          )}
        </InputWrapper>
        {errorMessage && (
          <ErrorMessage>
            <Typography variant='body1' style={{ letterSpacing: 0.48 }}>
              {errorMessage}
            </Typography>
          </ErrorMessage>
        )}
      </InputGroupContainer>
    )
  }
)

export default TextInputGroup
