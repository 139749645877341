import { MenuPopoverItemProps } from './MenuPopover.types'
import React, { useState, Fragment } from 'react'
import { MenuItem, Text, Popover, IconWrapper } from './MenuPopover.styles'
import { Icon } from 'components/common'
import { secondary } from 'config/themes/colors'

const MenuPopoverItem: React.FC<MenuPopoverItemProps> = ({
  menuItem,
  handleCloseParent,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (menuItem.subItems) {
      return setAnchorEl(e.currentTarget)
    }
    menuItem.handleClick()
    setAnchorEl(null)
    handleCloseParent()
  }

  const handleSubItemClick = (subItemAction: () => void): void => {
    setAnchorEl(null)
    subItemAction()
    handleCloseParent()
  }

  const handleClose = (): void => {
    setAnchorEl(null)
    handleCloseParent()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Fragment>
      <MenuItem onClick={handleClick} disabled={menuItem.disabled ? 1 : 0}>
        <IconWrapper>
          <Icon key={menuItem.icon} iconName={menuItem.icon} fill={secondary[200]} />
        </IconWrapper>
        <Text>{menuItem.label}</Text>
      </MenuItem>
      {menuItem.subItems && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          elevation={2}
          PaperProps={{ style: { borderRadius: 0 } }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {menuItem.subItems.map((subItem, i) => (
            <MenuItem
              key={`${i}-subitem`}
              onClick={() => handleSubItemClick(subItem.handleClick)}
              disabled={subItem.disabled ? 1 : 0}
            >
              <IconWrapper>
                <Icon iconName={subItem.icon} fill={secondary[200]} />
              </IconWrapper>
              <Text>{subItem.label}</Text>
            </MenuItem>
          ))}
        </Popover>
      )}
    </Fragment>
  )
}

export default MenuPopoverItem
