import { RootFolder, FolderItem } from 'types'

export const SET_ROOT_FOLDER_DOCUMENTS = 'SET_ROOT_FOLDER_DOCUMENTS'
export const SET_CURRENT_FOLDER_DOCUMENTS = 'SET_CURRENT_FOLDER_DOCUMENTS'

export const setRootFolderDocuments = (
  rootFolder: RootFolder
): { type: typeof SET_ROOT_FOLDER_DOCUMENTS; payload: RootFolder } => ({
  type: SET_ROOT_FOLDER_DOCUMENTS,
  payload: rootFolder,
})

export const setCurrentFolderDocuments = (
  folder: FolderItem
): { type: typeof SET_CURRENT_FOLDER_DOCUMENTS; payload: FolderItem } => ({
  type: SET_CURRENT_FOLDER_DOCUMENTS,
  payload: folder,
})

export type DocsActions =
  | ReturnType<typeof setCurrentFolderDocuments>
  | ReturnType<typeof setRootFolderDocuments>
