import { CustomVariant } from 'types'
import { IInputEl } from './SelectGroup.types'

import { styled } from '@material-ui/core/styles'
import {
  InputBase,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Theme,
} from '@material-ui/core'


export const Label = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  marginBottom: 14,
}))

export const Placeholder = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.colorz.secondary[300],
  letterSpacing: 0.2,
  opacity: 0.85,
}))

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.colorz.secondary.main,
  letterSpacing: 0.2,
}))

const getBorderColor = (error: any, customvariant: CustomVariant, theme: Theme) => {
  if (error) return theme.colorz.status.error
  if (customvariant === 'thick') return theme.colorz.secondary[300]
  return theme.colorz.secondary[100]
}

export const StyledInputBase = styled(InputBase)(
  ({ theme, error, customvariant }: IInputEl) => ({
    backgroundColor: '#fff',
    borderWidth: customvariant === 'thick' ? 2 : 1,
    borderStyle: 'solid',
    borderColor: getBorderColor(error, customvariant, theme),
    color: theme.colorz.secondary.main,
    width: '100%',
    marginBottom: theme.spacing(4),
    height: 48,
    '& .MuiSelect-root': {
      paddingLeft: theme.spacing(2),
    },
    '& .MuiList-root': {
      padding: theme.spacing(0, 2),
    },
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
    '&:focus': {
      border: '1px solid ' + theme.colorz.text.light,
      outline: 'none',
    },
    '&::placeholder': {
      opacity: 0.66,
      letterSpacing: 0.2,
    },
  })
)

export { FormControl, Select, MenuItem }
