import { ActionButtonsProps } from '../../stripe.types2'
import React from 'react'
import { styled } from '@material-ui/core'
import { CustomButton, ButtonSquare, Icon } from 'components/common'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
}))

const ActionButtons: React.FC<ActionButtonsProps> = ({
  disabled,
  charging,
  amount,
  handleBackClick,
  handleSubmit,
}) => {
  return (
    <Container>
      {handleBackClick && (
        <ButtonSquare
          onClick={handleBackClick}
          btntype='secondary'
          size={48}
          disabled={disabled}
          style={{ marginRight: 16 }}
        >
          <Icon iconName='arrow-left' />
        </ButtonSquare>
      )}
      <CustomButton
        onClick={handleSubmit}
        isLoading={charging}
        disabled={disabled}
        btntype='primary'
        size='large'
        style={{ width: handleBackClick ? 'calc(100% - 64px)' : '100%' }}
      >
        {!charging ? `CHF ${amount.toFixed(2)} bezahlen` : 'Einen Moment bitte'}
      </CustomButton>
    </Container>
  )
}

export default ActionButtons
