import { styled, Theme, darken } from '@material-ui/core/styles'
import { Button, Box, CircularProgress } from '@material-ui/core'
import { IButtonEl } from './CustomButton.types'
import { IStatus } from 'types'

const btnHeaderStyles = {
  padding: 0,
  textTransform: 'inherit',
  fontWeight: 400,
}

const primaryButtonStyle = (theme: Theme) => ({
  backgroundColor: theme.colorz.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.colorz.primary.dark,
  },
  '&.Mui-disabled': {
    opacity: 0.3,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.colorz.primary.main,
    },
  },
})

const primaryOutlinedButtonStyle = (theme: Theme) => ({
  backgroundColor: 'transparent',
  color: '#fff',
  border: '2px solid ' + theme.colorz.primary.main,
  '&:hover': {
    backgroundColor: theme.colorz.primary.dark,
  },
})

const primaryInvertedButtonStyle = (theme: Theme) => ({
  backgroundColor: 'transparent',
  color: theme.colorz.primary.main,
  border: '2px solid ' + theme.colorz.primary.main,
  '&:hover': {
    backgroundColor: theme.colorz.primary.dark,
    color: '#fff',
  },
})

const secondaryButtonStyles = (theme: Theme) => ({
  backgroundColor: 'transparent',
  color: theme.colorz.secondary.main,
  border: '2px solid ' + theme.colorz.secondary.main,
  '&:hover': {
    borderColor: theme.colorz.secondary[300],
  },
})

const btnDisabledStyles = (theme: Theme) => ({
  backgroundColor: theme.colorz.primary.main,
  color: '#fff',
  opacity: 0.3,
  // pointerEvents: 'none',
})

const textSelectionActiveStyles = (theme: Theme) => ({
  backgroundColor: '#fff',
  color: theme.colorz.secondary.main,
  border: '1px solid ' + theme.colorz.secondary[100],
})

const outlinedWhiteStyles = (theme: Theme) => ({
  border: '1px solid #fff',
  color: '#fff',
  '&:hover': {
    border: '1px solid ' + theme.colorz.primary.main,
    color: theme.colorz.primary.main,
  },
})

const outlinedPaleStyles = (theme: Theme) => ({
  backgroundColor: 'transparent',
  color: theme.colorz.secondary[300],
  border: '2px solid ' + theme.colorz.secondary[300],
  '&:hover': {
    borderColor: theme.colorz.secondary.main,
    color: theme.colorz.secondary.main,
    backgroundColor: 'transparent',
  },
})

const outlinedStatusStyles = (theme: Theme, status: IStatus) => ({
  border: `2px solid ${theme.colorz.status[status]}`,
  backgroundColor: '#fff',
  color: theme.colorz.status[status],
  '&:hover': {
    backgroundColor: theme.colorz.status[status],
    color: '#fff',
  },
})

const filledStatusStyles = (theme: Theme, status: IStatus) => ({
  border: `1px solid ${theme.colorz.status[status]}`,
  backgroundColor: theme.colorz.status[status],
  color: '#fff',
  '&:hover': {
    backgroundColor: darken(theme.colorz.status[status], 0.2),
  },
})

const whiteStyles = (theme: Theme) => ({
  color: theme.colorz.secondary.main,
  border: '2px solid ' + theme.colorz.secondary.main,
  textTransform: 'none',
})

const getButtonStyles = ({
  theme,
  disabled,
  textselectionactive,
  btntype,
}: IButtonEl) => {
  if (textselectionactive) return textSelectionActiveStyles(theme)
  switch (btntype) {
    case 'primary':
      return primaryButtonStyle(theme)
    case 'primary-outlined':
      return primaryOutlinedButtonStyle(theme)
    case 'primary-inverted':
      return primaryInvertedButtonStyle(theme)
    case 'secondary':
      return secondaryButtonStyles(theme)
    case 'outlined-white':
      return outlinedWhiteStyles(theme)
    case 'outlined-pale':
      return outlinedPaleStyles(theme)
    case 'outlined-error':
    case 'outlined-success':
    case 'outlined-info':
    case 'outlined-warning':
      return outlinedStatusStyles(theme, btntype.split('-')[1] as IStatus)
    case 'error':
    case 'success':
    case 'info':
    case 'warning':
      return filledStatusStyles(theme, btntype)
    case 'white':
      return whiteStyles(theme)
    case 'header-dropdown':
      return btnHeaderStyles
    default:
      return {}
  }
}

const getSize = (
  size: 'large' | 'medium' | 'small' | undefined
): { height: number; fontSize: number } => {
  switch (size) {
    case 'large':
      return {
        height: 48,
        fontSize: 16,
      }
    case 'medium':
      return {
        height: 40,
        fontSize: 16,
      }
    case 'small':
      return {
        height: 32,
        fontSize: 14,
      }
    default:
      return {
        height: 40,
        fontSize: 16,
      }
  }
}

export const ButtonEl = styled(Button)(({ width, size, ...props }: IButtonEl) => ({
  width: width ? width : 'auto',
  fontWeight: 700,
  textTransform: 'inherit',
  borderRadius: 2,
  ...getSize(size),
  border: '2px solid transparent',
  position: 'relative',
  transition: 'all .2s',
  flexShrink: 0,
  '&.Mui-disabled': {
    ...btnDisabledStyles(props.theme),
  },
  ...getButtonStyles(props),
}))

export const ButtonContent = styled(Box)({
  fontSize: 16,
  letterSpacing: 0.4,
  fontWeight: 700,
})

export const SpinnerContainer = styled(Box)({
  position: 'absolute',
  right: 15,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { CircularProgress }
