import { OptionType } from 'types'

export interface IOptionState {
  id: string
  text: string
  checked?: boolean
}

export type TemplateOptionsState = {
  [key: string]: IOptionState
}

export const templateOptionsToState = (arr: OptionType[]): TemplateOptionsState => {
  const obj: TemplateOptionsState = {}
  if (!arr || !arr.length) {
    return obj
  }
  for (let item of arr) {
    const objToAdd: IOptionState = {
      id: item.id,
      text: item.text,
    }

    if (item.locations) {
      objToAdd.checked = Boolean(item.locations.length === 0 && item.text)
    }

    obj[item.id] = objToAdd
  }
  return obj
}
