import { Editor as IEditor } from 'tinymce'
import {
  primary,
  // status
} from 'config/themes/colors'
import {
  ATTR_LIST_HEADING,
  ATTR_LIST_TYPE,
  LIST_TYPE_HEADING,
  LIST_TYPE_PARENTHESES,
} from 'config/textEditor/textEditor.constants'
import { restoreCustomListToParagraph, transformSelectionToList } from './services/text-editor'
import { toBase64 } from 'utils'
import fontLight from 'assets/fonts/Interstate-Light.otf'
import fontBlack from 'assets/fonts/Interstate-Black.otf'
import fontBold from 'assets/fonts/Interstate-Bold.otf'
import fontRegular from 'assets/fonts/Interstate-Regular.otf'
import { IMasterData } from 'api/app.api'
import { CASUS_CLASSES, KEYSTRINGS } from 'utilities/parsing'

type EditorUsed = 'template-creation' | 'document-generation' | 'document-preview'

interface IAdditionalSettings {
  hyphens?: boolean
}

export const getStyles = (
  masterData: IMasterData,
  editor: EditorUsed,
  additional: IAdditionalSettings = {}
): string => {
  const { documentStyles } = masterData
  const { heading_1, heading_2, heading_3, heading_4, paragraph, numbered_list } = documentStyles

  const commonStyles = [
    `@font-face {
      font-family: "Interstate Bold";
      src: url(${fontBold});
      font-weight: normal;
    }`,
    `@font-face {
      font-family: 'Interstate Light';
      src: url(${fontLight});
      font-weight: normal;
    }`,
    `@font-face {
      font-family: 'Interstate Light';
      src: url(${fontBold});
      font-weight: bold;
    }`,
    `@font-face {
      font-family: 'Interstate Black';
      src: url(${fontBlack});
      font-weight: normal;
    }`,
    `@font-face {
      font-family: 'Interstate Regular';
      src: url(${fontRegular});
      font-weight: normal;
    }`,
    `@import url('https://fonts.cdnfonts.com/css/liberation-sans');`,
    `html { scroll-behaviour: smooth }`,
    `html::-webkit-scrollbar { width: .3em; }`,
    `html::-webkit-scrollbar-thumb { outline: none; background-color: ${primary.light} }`,
    `body { margin: 96px; font-family: 'Liberation Sans', sans-serif; word-wrap: break-word; hyphens: ${
      additional.hyphens ? 'manual' : 'none'
    }; }`,
    `header { display: flex; justify-content: flex-end; padding-right: .5rem;}`,
    `footer { display: flex; align-items: flex-end; justify-content: flex-end; position: fixed; bottom: 20px; right: 0; left: 0; padding-right: 24px }`,
    `pre { position: relative; display: block; flex-grow: 1; font-family: inherit; font-size: ${paragraph.fontSize}; margin: 0; line-height: ${paragraph.lineHeight}; min-height: ${paragraph.lineHeight}em; white-space: pre-wrap }`,
    // 'td { border: 1px solid black; }',
    `.${CASUS_CLASSES.counterResetter} { visibility: hidden !important; pointer-events: none !important; height: 0 !important; min-height: 0 !important; max-height: 0 !important; margin: 0 !important; padding: 0 !important; opacity: 0 !important; }`,
    `.${CASUS_CLASSES.counterResetter} > * { display: none; }`,
    `.${KEYSTRINGS.highlight} { background-color: dodgerblue; color: white; }`,
    // `.${KEYSTRINGS.highlight} * { background-color: dodgerblue; color: white; }`,
    `.${KEYSTRINGS.highlight} .${KEYSTRINGS.highlight} { background-color: coral; color: white; }`,
    // `.${KEYSTRINGS.highlight} .${KEYSTRINGS.highlight} * { background-color: coral; color: white; }`,
    `.${KEYSTRINGS.highlight} .${KEYSTRINGS.highlight} .${KEYSTRINGS.highlight} { background-color: darkorchid; color: white; }`,
    // `.${KEYSTRINGS.highlight} .${KEYSTRINGS.highlight} .${KEYSTRINGS.highlight} * { background-color: darkorchid; color: white; }`,
    `*[${ATTR_LIST_HEADING}="1"] { position: relative; font-size: ${heading_1.fontSize}; margin: ${heading_1.margin}; line-height: ${heading_1.lineHeight}; padding-left: ${heading_1.indent}; font-weight: 700; text-indent: 0 !important; }`,
    `*[${ATTR_LIST_HEADING}="2"] { position: relative; font-size: ${heading_2.fontSize}; margin: ${heading_2.margin}; line-height: ${heading_2.lineHeight}; padding-left: ${heading_2.indent}; font-weight: 700; text-indent: 0 !important; }`,
    `*[${ATTR_LIST_HEADING}="3"] { position: relative; font-size: ${heading_3.fontSize}; margin: ${heading_3.margin}; line-height: ${heading_3.lineHeight}; padding-left: ${heading_3.indent}; text-indent: 0 !important; }`,
    `*[${ATTR_LIST_TYPE}="${LIST_TYPE_PARENTHESES}"] { position: relative; font-size: ${heading_4.fontSize}; margin: ${heading_4.margin}; line-height: ${heading_4.lineHeight}; padding-left: ${heading_4.indent}; text-indent: 0 !important; }`,
    `*[${ATTR_LIST_HEADING}="1"] span.custom-list-item-span { position: absolute;  left: 0; }`,
    `*[${ATTR_LIST_HEADING}="2"] span.custom-list-item-span { position: absolute;  left: 0; }`,
    `*[${ATTR_LIST_HEADING}="3"] span.custom-list-item-span { position: absolute;  left: 0; }`,
    `*[${ATTR_LIST_TYPE}="${LIST_TYPE_PARENTHESES}"] span.custom-list-item-span { position: absolute; left: 0; }`,
    `ol li::marker { font-family: inherit; font-size: inherit }`,
    // `.mce-pagebreak { page-break-after: always }`,
    `td > * { margin-left: 0; margin-right: 0; }`,
    `ol li { padding: ${numbered_list.padding || ''}; margin: ${numbered_list.margin}; font-size: ${
      numbered_list.fontSize
    }; }
    ._casus_bold {
      font-weight: bold;
    }
    
    ._casus_underline {
        text-decoration: underline;
    }
    
    ._casus_italic {
        font-style: italic;
    }
    
    ._casus_strikethrough {
        text-decoration: line-through;
    }
    
    ._casus_jr {
        text-align: right;
    }
    
    ._casus_jc {
        text-align: center;
    }
    
    ._casus_jl {
        text-align: left;
    }
    
    ._casus_jj {
        text-align: justify;
    }
    
    ._casus_uppercase {
        text-transform: uppercase;
    }
    
    ._casus_lowercase {
        text-transform: lowercase;
    }
    
    ._casus_capitalize {
        text-transform: capitalize;
    }
    
    ._casus_fsize-5 {
        font-size: 5pt;
    }
    
    ._casus_fsize-6 {
        font-size: 6pt;
    }
    
    ._casus_fsize-7 {
        font-size: 7pt;
    }
    
    ._casus_fsize-8 {
        font-size: 8pt;
    }
    
    ._casus_fsize-9 {
        font-size: 9pt;
    }
    
    ._casus_fsize-10 {
        font-size: 10pt;
    }
    
    ._casus_fsize-11 {
        font-size: 11pt;
    }
    
    ._casus_fsize-12 {
        font-size: 12pt;
    }
    
    ._casus_fsize-14 {
        font-size: 14pt;
    }
    
    ._casus_fsize-16 {
        font-size: 16pt;
    }
    
    ._casus_fsize-18 {
        font-size: 18pt;
    }
    
    ._casus_fsize-20 {
        font-size: 20pt;
    }
    
    ._casus_fsize-22 {
        font-size: 22pt;
    }
    
    ._casus_fsize-24 {
        font-size: 24pt;
    }
    
    ._casus_fsize-26 {
        font-size: 26pt;
    }
    
    ._casus_fsize-28 {
        font-size: 28pt;
    }
    ._casus_ind-0 {
      margin-left: 0pt !important;
    }
    ._casus_ind-1 {
      margin-left: 1pt;
    }
    ._casus_ind-2 {
      margin-left: 2pt;
    }
    ._casus_ind-3 {
      margin-left: 3pt;
    }
    ._casus_ind-4 {
      margin-left: 4pt;
    }
    ._casus_ind-5 {
      margin-left: 5pt;
    }
    ._casus_ind-6 {
      margin-left: 6pt;
    }
    ._casus_ind-7 {
      margin-left: 7pt;
    }
    ._casus_ind-8 {
      margin-left: 8pt;
    }
    ._casus_ind-9 {
      margin-left: 9pt;
    }
    ._casus_ind-10 {
      margin-left: 10pt;
    }
    ._casus_ind-11 {
      margin-left: 11pt;
    }
    ._casus_ind-12 {
      margin-left: 12pt;
    }
    ._casus_ind-13 {
      margin-left: 13pt;
    }
    ._casus_ind-14 {
      margin-left: 14pt;
    }
    ._casus_ind-15 {
      margin-left: 15pt;
    }
    ._casus_ind-16 {
      margin-left: 16pt;
    }
    ._casus_ind-17 {
      margin-left: 17pt;
    }
    ._casus_ind-18 {
      margin-left: 18pt;
    }
    ._casus_ind-19 {
      margin-left: 19pt;
    }
    ._casus_ind-20 {
      margin-left: 20pt;
    }
    ._casus_ind-21 {
      margin-left: 21pt;
    }
    ._casus_ind-22 {
      margin-left: 22pt;
    }
    ._casus_ind-23 {
      margin-left: 23pt;
    }
    ._casus_ind-24 {
      margin-left: 24pt;
    }
    ._casus_ind-25 {
      margin-left: 25pt;
    }
    ._casus_ind-26 {
      margin-left: 26pt;
    }
    ._casus_ind-27 {
      margin-left: 27pt;
    }
    ._casus_ind-28 {
      margin-left: 28pt;
    }
    ._casus_ind-29 {
      margin-left: 29pt;
    }
    ._casus_ind-30 {
      margin-left: 30pt;
    }
    ._casus_ind-31 {
      margin-left: 31pt;
    }
    ._casus_ind-32 {
      margin-left: 32pt;
    }
    ._casus_ind-33 {
      margin-left: 33pt;
    }
    ._casus_ind-34 {
      margin-left: 34pt;
    }
    ._casus_ind-35 {
      margin-left: 35pt;
    }
    ._casus_ind-36 {
      margin-left: 36pt;
    }
    ._casus_ind-37 {
      margin-left: 37pt;
    }
    ._casus_ind-38 {
      margin-left: 38pt;
    }
    ._casus_ind-39 {
      margin-left: 39pt;
    }
    ._casus_ind-40 {
      margin-left: 40pt;
    }
    ._casus_ind-41 {
      margin-left: 41pt;
    }
    ._casus_ind-42 {
      margin-left: 42pt;
    }
    ._casus_ind-43 {
      margin-left: 43pt;
    }
    ._casus_ind-44 {
      margin-left: 44pt;
    }
    ._casus_ind-45 {
      margin-left: 45pt;
    }
    ._casus_ind-46 {
      margin-left: 46pt;
    }
    ._casus_ind-47 {
      margin-left: 47pt;
    }
    ._casus_ind-48 {
      margin-left: 48pt;
    }
    ._casus_ind-49 {
      margin-left: 49pt;
    }
    ._casus_ind-50 {
      margin-left: 50pt;
    }    
    ._casus_spacing-after-1 {
      margin-bottom: 1pt;
    }
    ._casus_spacing-after-2 {
      margin-bottom: 2pt;
    }
    ._casus_spacing-after-3 {
      margin-bottom: 3pt;
    }
    ._casus_spacing-after-4 {
      margin-bottom: 4pt;
    }
    ._casus_spacing-after-5 {
      margin-bottom: 5pt;
    }
    ._casus_spacing-after-6 {
      margin-bottom: 6pt;
    }
    ._casus_spacing-after-7 {
      margin-bottom: 7pt;
    }
    ._casus_spacing-after-8 {
      margin-bottom: 8pt;
    }
    ._casus_spacing-after-9 {
      margin-bottom: 9pt;
    }
    ._casus_spacing-after-10 {
      margin-bottom: 10pt;
    }
    ._casus_spacing-after-11 {
      margin-bottom: 11pt;
    }
    ._casus_spacing-after-12 {
      margin-bottom: 12pt;
    }
    ._casus_spacing-after-13 {
      margin-bottom: 13pt;
    }
    ._casus_spacing-after-14 {
      margin-bottom: 14pt;
    }
    ._casus_spacing-after-15 {
      margin-bottom: 15pt;
    }
    ._casus_spacing-after-16 {
      margin-bottom: 16pt;
    }
    ._casus_spacing-after-17 {
      margin-bottom: 17pt;
    }
    ._casus_spacing-after-18 {
      margin-bottom: 18pt;
    }
    ._casus_spacing-after-19 {
      margin-bottom: 19pt;
    }
    ._casus_spacing-after-20 {
      margin-bottom: 20pt;
    }
    ._casus_spacing-after-21 {
      margin-bottom: 21pt;
    }
    ._casus_spacing-after-22 {
      margin-bottom: 22pt;
    }
    ._casus_spacing-after-23 {
      margin-bottom: 23pt;
    }
    ._casus_spacing-after-24 {
      margin-bottom: 24pt;
    }
    ._casus_spacing-after-25 {
      margin-bottom: 25pt;
    }
    ._casus_spacing-after-26 {
      margin-bottom: 26pt;
    }
    ._casus_spacing-after-27 {
      margin-bottom: 27pt;
    }
    ._casus_spacing-after-28 {
      margin-bottom: 28pt;
    }
    ._casus_spacing-after-29 {
      margin-bottom: 29pt;
    }
    ._casus_spacing-after-30 {
      margin-bottom: 30pt;
    }
    ._casus_spacing-after-31 {
      margin-bottom: 31pt;
    }
    ._casus_spacing-after-32 {
      margin-bottom: 32pt;
    }
    ._casus_spacing-after-33 {
      margin-bottom: 33pt;
    }
    ._casus_spacing-after-34 {
      margin-bottom: 34pt;
    }
    ._casus_spacing-after-35 {
      margin-bottom: 35pt;
    }
    ._casus_spacing-after-36 {
      margin-bottom: 36pt;
    }
    ._casus_spacing-after-37 {
      margin-bottom: 37pt;
    }
    ._casus_spacing-after-38 {
      margin-bottom: 38pt;
    }
    ._casus_spacing-after-39 {
      margin-bottom: 39pt;
    }
    ._casus_spacing-after-40 {
      margin-bottom: 40pt;
    }
    ._casus_spacing-after-41 {
      margin-bottom: 41pt;
    }
    ._casus_spacing-after-42 {
      margin-bottom: 42pt;
    }
    ._casus_spacing-after-43 {
      margin-bottom: 43pt;
    }
    ._casus_spacing-after-44 {
      margin-bottom: 44pt;
    }
    ._casus_spacing-after-45 {
      margin-bottom: 45pt;
    }
    ._casus_spacing-after-46 {
      margin-bottom: 46pt;
    }
    ._casus_spacing-after-47 {
      margin-bottom: 47pt;
    }
    ._casus_spacing-after-48 {
      margin-bottom: 48pt;
    }
    ._casus_spacing-after-49 {
      margin-bottom: 49pt;
    }
    ._casus_spacing-after-50 {
      margin-bottom: 50pt;
    }
    ._casus_spacing-after-51 {
      margin-bottom: 51pt;
    }
    ._casus_spacing-after-52 {
      margin-bottom: 52pt;
    }
    ._casus_spacing-after-53 {
      margin-bottom: 53pt;
    }
    ._casus_spacing-after-54 {
      margin-bottom: 54pt;
    }
    ._casus_spacing-after-55 {
      margin-bottom: 55pt;
    }
    ._casus_spacing-after-56 {
      margin-bottom: 56pt;
    }
    ._casus_spacing-after-57 {
      margin-bottom: 57pt;
    }
    ._casus_spacing-after-58 {
      margin-bottom: 58pt;
    }
    ._casus_spacing-after-59 {
      margin-bottom: 59pt;
    }
    ._casus_spacing-after-60 {
      margin-bottom: 60pt;
    }
    ._casus_spacing-after-61 {
      margin-bottom: 61pt;
    }
    ._casus_spacing-after-62 {
      margin-bottom: 62pt;
    }
    ._casus_spacing-after-63 {
      margin-bottom: 63pt;
    }
    ._casus_spacing-after-64 {
      margin-bottom: 64pt;
    }
    ._casus_spacing-after-65 {
      margin-bottom: 65pt;
    }
    ._casus_spacing-after-66 {
      margin-bottom: 66pt;
    }
    ._casus_spacing-after-67 {
      margin-bottom: 67pt;
    }
    ._casus_spacing-after-68 {
      margin-bottom: 68pt;
    }
    ._casus_spacing-after-69 {
      margin-bottom: 69pt;
    }
    ._casus_spacing-after-70 {
      margin-bottom: 70pt;
    }
    ._casus_spacing-after-71 {
      margin-bottom: 71pt;
    }
    ._casus_spacing-after-72 {
      margin-bottom: 72pt;
    }
    ._casus_spacing-after-73 {
      margin-bottom: 73pt;
    }
    ._casus_spacing-after-74 {
      margin-bottom: 74pt;
    }
    ._casus_spacing-after-75 {
      margin-bottom: 75pt;
    }
    ._casus_spacing-after-76 {
      margin-bottom: 76pt;
    }
    ._casus_spacing-after-77 {
      margin-bottom: 77pt;
    }
    ._casus_spacing-after-78 {
      margin-bottom: 78pt;
    }
    ._casus_spacing-after-79 {
      margin-bottom: 79pt;
    }
    ._casus_spacing-after-80 {
      margin-bottom: 80pt;
    }
    ._casus_spacing-after-81 {
      margin-bottom: 81pt;
    }
    ._casus_spacing-after-82 {
      margin-bottom: 82pt;
    }
    ._casus_spacing-after-83 {
      margin-bottom: 83pt;
    }
    ._casus_spacing-after-84 {
      margin-bottom: 84pt;
    }
    ._casus_spacing-after-85 {
      margin-bottom: 85pt;
    }
    ._casus_spacing-after-86 {
      margin-bottom: 86pt;
    }
    ._casus_spacing-after-87 {
      margin-bottom: 87pt;
    }
    ._casus_spacing-after-88 {
      margin-bottom: 88pt;
    }
    ._casus_spacing-after-89 {
      margin-bottom: 89pt;
    }
    ._casus_spacing-after-90 {
      margin-bottom: 90pt;
    }
    ._casus_spacing-after-91 {
      margin-bottom: 91pt;
    }
    ._casus_spacing-after-92 {
      margin-bottom: 92pt;
    }
    ._casus_spacing-after-93 {
      margin-bottom: 93pt;
    }
    ._casus_spacing-after-94 {
      margin-bottom: 94pt;
    }
    ._casus_spacing-after-95 {
      margin-bottom: 95pt;
    }
    ._casus_spacing-after-96 {
      margin-bottom: 96pt;
    }
    ._casus_spacing-after-97 {
      margin-bottom: 97pt;
    }
    ._casus_spacing-after-98 {
      margin-bottom: 98pt;
    }
    ._casus_spacing-after-99 {
      margin-bottom: 99pt;
    }
    ._casus_spacing-after-100 {
      margin-bottom: 100pt;
    }    
    ._casus_spacing-before-1 {
      margin-top: 1pt;
    }
    ._casus_spacing-before-2 {
      margin-top: 2pt;
    }
    ._casus_spacing-before-3 {
      margin-top: 3pt;
    }
    ._casus_spacing-before-4 {
      margin-top: 4pt;
    }
    ._casus_spacing-before-5 {
      margin-top: 5pt;
    }
    ._casus_spacing-before-6 {
      margin-top: 6pt;
    }
    ._casus_spacing-before-7 {
      margin-top: 7pt;
    }
    ._casus_spacing-before-8 {
      margin-top: 8pt;
    }
    ._casus_spacing-before-9 {
      margin-top: 9pt;
    }
    ._casus_spacing-before-10 {
      margin-top: 10pt;
    }
    ._casus_spacing-before-11 {
      margin-top: 11pt;
    }
    ._casus_spacing-before-12 {
      margin-top: 12pt;
    }
    ._casus_spacing-before-13 {
      margin-top: 13pt;
    }
    ._casus_spacing-before-14 {
      margin-top: 14pt;
    }
    ._casus_spacing-before-15 {
      margin-top: 15pt;
    }
    ._casus_spacing-before-16 {
      margin-top: 16pt;
    }
    ._casus_spacing-before-17 {
      margin-top: 17pt;
    }
    ._casus_spacing-before-18 {
      margin-top: 18pt;
    }
    ._casus_spacing-before-19 {
      margin-top: 19pt;
    }
    ._casus_spacing-before-20 {
      margin-top: 20pt;
    }
    ._casus_spacing-before-21 {
      margin-top: 21pt;
    }
    ._casus_spacing-before-22 {
      margin-top: 22pt;
    }
    ._casus_spacing-before-23 {
      margin-top: 23pt;
    }
    ._casus_spacing-before-24 {
      margin-top: 24pt;
    }
    ._casus_spacing-before-25 {
      margin-top: 25pt;
    }
    ._casus_spacing-before-26 {
      margin-top: 26pt;
    }
    ._casus_spacing-before-27 {
      margin-top: 27pt;
    }
    ._casus_spacing-before-28 {
      margin-top: 28pt;
    }
    ._casus_spacing-before-29 {
      margin-top: 29pt;
    }
    ._casus_spacing-before-30 {
      margin-top: 30pt;
    }
    ._casus_spacing-before-31 {
      margin-top: 31pt;
    }
    ._casus_spacing-before-32 {
      margin-top: 32pt;
    }
    ._casus_spacing-before-33 {
      margin-top: 33pt;
    }
    ._casus_spacing-before-34 {
      margin-top: 34pt;
    }
    ._casus_spacing-before-35 {
      margin-top: 35pt;
    }
    ._casus_spacing-before-36 {
      margin-top: 36pt;
    }
    ._casus_spacing-before-37 {
      margin-top: 37pt;
    }
    ._casus_spacing-before-38 {
      margin-top: 38pt;
    }
    ._casus_spacing-before-39 {
      margin-top: 39pt;
    }
    ._casus_spacing-before-40 {
      margin-top: 40pt;
    }
    ._casus_spacing-before-41 {
      margin-top: 41pt;
    }
    ._casus_spacing-before-42 {
      margin-top: 42pt;
    }
    ._casus_spacing-before-43 {
      margin-top: 43pt;
    }
    ._casus_spacing-before-44 {
      margin-top: 44pt;
    }
    ._casus_spacing-before-45 {
      margin-top: 45pt;
    }
    ._casus_spacing-before-46 {
      margin-top: 46pt;
    }
    ._casus_spacing-before-47 {
      margin-top: 47pt;
    }
    ._casus_spacing-before-48 {
      margin-top: 48pt;
    }
    ._casus_spacing-before-49 {
      margin-top: 49pt;
    }
    ._casus_spacing-before-50 {
      margin-top: 50pt;
    }
    ._casus_spacing-before-51 {
      margin-top: 51pt;
    }
    ._casus_spacing-before-52 {
      margin-top: 52pt;
    }
    ._casus_spacing-before-53 {
      margin-top: 53pt;
    }
    ._casus_spacing-before-54 {
      margin-top: 54pt;
    }
    ._casus_spacing-before-55 {
      margin-top: 55pt;
    }
    ._casus_spacing-before-56 {
      margin-top: 56pt;
    }
    ._casus_spacing-before-57 {
      margin-top: 57pt;
    }
    ._casus_spacing-before-58 {
      margin-top: 58pt;
    }
    ._casus_spacing-before-59 {
      margin-top: 59pt;
    }
    ._casus_spacing-before-60 {
      margin-top: 60pt;
    }
    ._casus_spacing-before-61 {
      margin-top: 61pt;
    }
    ._casus_spacing-before-62 {
      margin-top: 62pt;
    }
    ._casus_spacing-before-63 {
      margin-top: 63pt;
    }
    ._casus_spacing-before-64 {
      margin-top: 64pt;
    }
    ._casus_spacing-before-65 {
      margin-top: 65pt;
    }
    ._casus_spacing-before-66 {
      margin-top: 66pt;
    }
    ._casus_spacing-before-67 {
      margin-top: 67pt;
    }
    ._casus_spacing-before-68 {
      margin-top: 68pt;
    }
    ._casus_spacing-before-69 {
      margin-top: 69pt;
    }
    ._casus_spacing-before-70 {
      margin-top: 70pt;
    }
    ._casus_spacing-before-71 {
      margin-top: 71pt;
    }
    ._casus_spacing-before-72 {
      margin-top: 72pt;
    }
    ._casus_spacing-before-73 {
      margin-top: 73pt;
    }
    ._casus_spacing-before-74 {
      margin-top: 74pt;
    }
    ._casus_spacing-before-75 {
      margin-top: 75pt;
    }
    ._casus_spacing-before-76 {
      margin-top: 76pt;
    }
    ._casus_spacing-before-77 {
      margin-top: 77pt;
    }
    ._casus_spacing-before-78 {
      margin-top: 78pt;
    }
    ._casus_spacing-before-79 {
      margin-top: 79pt;
    }
    ._casus_spacing-before-80 {
      margin-top: 80pt;
    }
    ._casus_spacing-before-81 {
      margin-top: 81pt;
    }
    ._casus_spacing-before-82 {
      margin-top: 82pt;
    }
    ._casus_spacing-before-83 {
      margin-top: 83pt;
    }
    ._casus_spacing-before-84 {
      margin-top: 84pt;
    }
    ._casus_spacing-before-85 {
      margin-top: 85pt;
    }
    ._casus_spacing-before-86 {
      margin-top: 86pt;
    }
    ._casus_spacing-before-87 {
      margin-top: 87pt;
    }
    ._casus_spacing-before-88 {
      margin-top: 88pt;
    }
    ._casus_spacing-before-89 {
      margin-top: 89pt;
    }
    ._casus_spacing-before-90 {
      margin-top: 90pt;
    }
    ._casus_spacing-before-91 {
      margin-top: 91pt;
    }
    ._casus_spacing-before-92 {
      margin-top: 92pt;
    }
    ._casus_spacing-before-93 {
      margin-top: 93pt;
    }
    ._casus_spacing-before-94 {
      margin-top: 94pt;
    }
    ._casus_spacing-before-95 {
      margin-top: 95pt;
    }
    ._casus_spacing-before-96 {
      margin-top: 96pt;
    }
    ._casus_spacing-before-97 {
      margin-top: 97pt;
    }
    ._casus_spacing-before-98 {
      margin-top: 98pt;
    }
    ._casus_spacing-before-99 {
      margin-top: 99pt;
    }
    ._casus_spacing-before-100 {
      margin-top: 100pt;
    }
    ._casus_bottom-border {
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
    ._casus_right-border {
      border-right-width: 2px;
      border-right-style: solid;
    }
    ._casus_left-border {
      border-left-width: 2px;
      border-left-style: solid;
    }
    ._casus_font-family-arial {
      font-family: 'Arial';
    }
    .mce-item-anchor {
      display: none !important;
    }
    `,
    // `@media print { table: { outline: none; } }`,
  ] // for (let i = 0; i <= 10; i++) {
  //   commonStyles.push(
  // `.${CASUS_CLASSES.depthContainer}_{i} { display: flex; flex-flow: wrap; justify-content: center; width: 100%; }`
  // `.${CASUS_CLASSES.depthContainer}_${i} { width: 100%; }`
  //   )
  // }

  if (numbered_list.indent) {
    commonStyles.push(`ol { padding-left: ${numbered_list.indent} }`)
  }

  let finalStyles = commonStyles

  switch (editor) {
    case 'template-creation':
    case 'document-generation':
      finalStyles.push(
        // `mark { background-color: ${status.success}; color: #fff; cursor: pointer; }`,
        // `mark mark { background-color: #318D4E }`,
        // `mark mark mark { background-color: #276139 }`,
        `mark.highlight-1 { background-color: rgba(102, 153, 204, 0.5); }`,
        `mark.highlight-2 { background-color: rgba(49, 141, 78, 0.5); }`,
        `mark.highlight-3 { background-color: rgba(225, 166, 255, 0.5); }`,
        `mark.marker-clickable { border: 1px solid black; cursor: pointer !important; filter: drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.5)); }`
      )
      break
    case 'document-preview':
      finalStyles.push(`mark { background-color: #fff; background: #fff; color: #000}`)
      break
  }

  return finalStyles.join(' ')
}

const toolbar = [
  'undo redo',
  // 'lists',
  // 'fontsizeselect',
  // 'bold italic strikethrough removeformat alignment',
  // 'bullist numlist table',
  // 'pagebreak',
  // 'hyphenation',
  // 'logo',
].join(' | ')

const plugins = [
  // 'advlist autolink lists link charmap preview anchor',
  // 'visualblocks',
  // 'insertdatetime media table wordcount',
  // 'pagebreak',
  'noneditable',
  // 'table',
  // 'advlist',
  // 'print',
]

export const TEXT_EDITOR_WIDTH = 725

export const tc_toolbar = toolbar
export const tc_plugins = plugins
export const dp_toolbar = toolbar
export const dp_plugins = plugins

export const setup = function (
  editor: IEditor,
  actions: {
    onSave: (editor?: IEditor) => void
    onLogoUpload?: (imageData: string) => Promise<string>
    onHyphenChange?: (editor: IEditor) => void
  }
) {
  editor.ui.registry.addMenuButton('lists', {
    text: 'Titel',
    fetch: function (callback: any) {
      const items = [
        {
          type: 'menuitem',
          text: 'Ebene 1',
          style: {
            fontSize: 20,
          },
          onAction: () => {
            let node: HTMLElement | null = editor.selection.getNode() as HTMLElement
            if (node?.nodeName === 'SPAN') node = node.parentElement
            if (node?.getAttribute(ATTR_LIST_HEADING) === '1') {
              restoreCustomListToParagraph(editor)
            } else {
              transformSelectionToList(editor, LIST_TYPE_HEADING, '1')
            }
            actions.onSave(editor)
          },
        },
        {
          type: 'menuitem',
          text: 'Ebene 2',
          onAction: () => {
            let node: HTMLElement | null = editor.selection.getNode() as HTMLElement
            if (node?.nodeName === 'SPAN') node = node.parentElement
            if (node?.getAttribute(ATTR_LIST_HEADING) === '2') {
              restoreCustomListToParagraph(editor)
            } else {
              transformSelectionToList(editor, LIST_TYPE_HEADING, '2')
            }
            actions.onSave(editor)
          },
        },
        {
          type: 'menuitem',
          text: 'Ebene 3',
          onAction: () => {
            let node: HTMLElement | null = editor.selection.getNode() as HTMLElement
            if (node?.nodeName === 'SPAN') node = node.parentElement
            if (node?.getAttribute(ATTR_LIST_HEADING) === '3') {
              restoreCustomListToParagraph(editor)
            } else {
              transformSelectionToList(editor, LIST_TYPE_HEADING, '3')
            }
            actions.onSave(editor)
          },
        },
        {
          type: 'menuitem',
          text: 'Ebene 4',
          onAction: () => {
            let nodes = editor.selection.getSelectedBlocks()
            let parenthesesSelected = false
            let nonParenthesesSelected = false
            nodes.forEach(node => {
              if (node.getAttribute(ATTR_LIST_TYPE) === LIST_TYPE_PARENTHESES) {
                parenthesesSelected = true
              } else {
                nonParenthesesSelected = true
              }
            })

            if (parenthesesSelected && !nonParenthesesSelected) {
              restoreCustomListToParagraph(editor)
            } else {
              transformSelectionToList(editor, LIST_TYPE_PARENTHESES)
            }
            actions.onSave(editor)
          },
        },
      ]
      callback(items)
    },
  })

  editor.ui.registry.addGroupToolbarButton('alignment', {
    icon: 'align-left',
    tooltip: 'Alignment',
    items: 'alignleft aligncenter alignright | alignjustify',
  })

  editor.ui.registry.addButton('hyphenation', {
    icon: 'horizontal-rule',
    tooltip: 'Hyphenation',
    onAction: () => {
      if (actions.onHyphenChange) actions.onHyphenChange(editor)
    },
  })

  editor.ui.registry.addButton('logo', {
    icon: 'image',
    tooltip: 'Logo',
    onAction: async () => {
      if (!actions.onLogoUpload) return
      try {
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')

        input.onchange = function () {
          const file = input?.files ? input.files[0] : null
          if (!file) return
          const reader = new FileReader()
          reader.onload = async () => {
            if (!reader || !editor) return
            const base64 = await toBase64(file)

            if (base64 && actions.onLogoUpload) {
              const imgSrc = await actions.onLogoUpload(base64)
              const id = 'company-logo'
              const body = editor.getBody()
              let logoEl = body.querySelector<HTMLImageElement>('#' + id)
              if (logoEl) logoEl.remove()
              logoEl = document.createElement('img')
              logoEl.id = id
              logoEl.setAttribute('style', 'float: right; height: 85px; width: auto;')
              editor.getBody().insertAdjacentElement('afterbegin', logoEl)

              logoEl.src = imgSrc

              const floatClearEl = document.createElement('span')
              floatClearEl.setAttribute('style', 'clear: both;')
              editor.getBody().insertAdjacentElement('afterbegin', floatClearEl)
              actions.onSave(editor)
            }
          }
          reader.readAsDataURL(file)
        }
        input.click()
      } catch (err) {
        console.error('Error while uploading logo', err)
      }
    },
  })
}

export const font_formats = `'Liberation Sans', sans-serif;`

