import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}))

export { Typography }
