import { Box, styled, Theme } from '@material-ui/core'
import theme from 'config/themes/theme'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElementProps,
} from '@stripe/react-stripe-js'
import { formElStyles } from '../common.styles'

export const StripeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
}))

export const stripeElOptionStyle = {
  base: {
    fontSize: '16px',
    color: theme.colorz.secondary.main,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.4px',
    '::placeholder': {
      color: theme.colorz.grey.light,
      fontSize: 'inherit',
      fontFamily: 'inherit',
      letterSpacing: '0.4px',
    },
  },
  invalid: {
    color: theme.colorz.status.error,
  },
}

interface INumberElement extends CardNumberElementProps {
  theme: Theme
}

export const CardNumberEl = styled(CardNumberElement)(({ theme }: INumberElement) => ({
  ...formElStyles(theme),
  width: '100%',
  borderBottom: 'none',
}))

export const CardExpiryEl = styled(CardExpiryElement)(({ theme }) => ({
  ...formElStyles(theme),
  borderRight: 'none',
}))

export const CvcEl = styled(CardCvcElement)(({ theme }) => ({
  ...formElStyles(theme),
}))
