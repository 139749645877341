import React from 'react'
import TableRowSection from './TableRowSection'
import content from './content'
import TableHead from './TableHead'
import { styled } from '@material-ui/core'
import { ButtonSquare, Icon } from 'components/common'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'auto',
  background: '#fff',
  height: 'calc(100vh - 56px)',
  marginTop: 56,
  minWidth: 1088,
  maxWidth: 1140,
  width: '95vw',
  position: 'relative',
}))

export const TableBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))
interface Props {
  onClose: () => void
}
const TableComponent: React.FC<Props> = ({ onClose }) => {
  return (
    <Container>
      <div style={{ position: 'absolute', top: 8, right: 40 }}>
        <ButtonSquare
          btntype='secondary'
          size={32}
          style={{ position: 'fixed' }}
          onClick={onClose}
        >
          <Icon iconName='close' />
        </ButtonSquare>
      </div>

      <TableHead onClose={onClose} />
      <TableBody>
        {content.map((section, i) => (
          <TableRowSection key={i} {...section} />
        ))}
      </TableBody>
    </Container>
  )
}

export default TableComponent
