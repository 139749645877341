import { styled } from '@material-ui/core/styles'
import { Box, Modal, Typography } from '@material-ui/core'

export const ContentWrapper = styled(Box)(({ theme }) => ({
  background: theme.colorz.brand.main,
}))

export const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  display: 'flex',
  flexShrink: 1,
  border: 'none',
  width: 820,
  position: 'relative',
}))

export const LeftContent = styled(Box)(({ theme }) => ({
  width: '35%',
  padding: theme.spacing(4, 1),
  display: 'flex',
  flexDirection: 'column',
  zIndex: 2,
}))

export const RightContent = styled(Box)(({ theme }) => ({
  width: '65%',
  padding: theme.spacing(4,0,4,4),
  display: 'flex',
}))

export const RightContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 440,
  overflowY: 'auto',
})

export const DummyDoc = styled(Box)(({ theme }) => ({
  background: '#fff',
}))

export const DocName = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4, 3, 4),
  fontWeight: 700,
}))

export const BottomContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0, 0, 2),
}))

export const AuthorContent = styled(Box)(({ theme }) => ({
  color: theme.colorz.secondary[300],
}))

const fontStyles = {
  fontFamily: 'Heebo',
  fontWeight: 400,
  fontSize: 16,
  letterSpacing: 0.16,
  paddingBottom: 24,
}

export const CharacteristicsTextArea = styled('textarea')(({ theme }) => ({
  height: 240,
  width: 460,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  cursor: 'pointer',
  transition: 'all .2s',
  color: theme.colorz.secondary.main,
  resize: 'none',
  backgroundColor: 'transparent',
  outline: 'none',
  lineHeight: 1.8,
  margin: theme.spacing(2, 0),
  position: 'relative',
  ...fontStyles,
  '&:hover': {
    borderColor: theme.colorz.secondary[100],
  },
  '&:focus': {
    borderColor: theme.colorz.secondary[100],
  },
  '&:active': {
    borderColor: theme.colorz.secondary[100],
  },
  '&::placeholder': {
    color: theme.colorz.secondary[200],
    ...fontStyles,
  },
}))

export const CharacteristicsText = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  lineHeight: 1.8,
}))

export const CharTextWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(0,3,4,0),
  width: '100%',
  maxHeight: 386,
  overflowY: 'auto',
  position: 'relative',
  '&::-webkit-scrollbar': {
    width: '.3em',
    backgroundColor: '#E9DED5',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colorz.secondary.main,
    outline: 'none',
    borderRadius: 400,
  },
  'scroll-behavior': 'smooth',
  
}))

export { Typography, Modal }
