import React from 'react'
import { useHistory, useParams } from 'react-router'

import { DocumentGeneration } from 'components/features/documentGeneration/DocumentGeneration'
import { DocumentGenerationLayout } from 'components/features/documentGeneration/layout/DocumentGenerationLayout'
import { DocumentGenerationProvider } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { useLoadEditDocumentData } from './useLoadEditDocumentData'
import { generateDocumentHtmlData } from 'components/features/documentGeneration/services/generateDocumentHtmlData'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useIsTemplatePurchaseRequired } from 'hooks/useIsTemplatePurchaseRequired'
import PurchaseTemplateModal from 'pages/Authenticated/Templates/components/PurchaseTemplateModal/PurchaseTemplateModal'
import { useUser } from 'queries/user/useUser'

const DocumentEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const data = useLoadEditDocumentData(id)
  const { isExpired } = useSubscriptionCheck()
  const isPaymentRequired = useIsTemplatePurchaseRequired(data?.documentFile.templateId)
  const { goBack } = useHistory()

  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  if (!data)
    return (
      <DocumentGenerationLayout
        step={2}
        heading='Einen Moment bitte...'
        onClose={goBack}
        isLoading={true}
      />
    )

  if (isPaymentRequired && data?.documentFile)
    return (
      <PurchaseTemplateModal
        templateId={data.documentFile.templateId}
        open={true}
        handleClose={goBack}
      />
    )

  const { template, documentFile } = data

  const { questions, htmlData, isCasus } = template
  const { answers, name, settings, isDirty } = documentFile

  return (
    <DocumentGenerationProvider
      initialData={{
        questions,
        name,
        templateHtmlData: htmlData,
        isCasus,
        answers,
        settings,
        mode: 'edit',
        templateId: documentFile.templateId,
        templateContentVersionId: template.contentVersionId, // TODO
        documentId: documentFile.id,
        htmlData: isDirty
          ? generateDocumentHtmlData(template.htmlData, questions, answers, docXmicro)
          : documentFile.htmlData,
        isDirty,
      }}
    >
      <DocumentGeneration isExpired={isExpired} />
    </DocumentGenerationProvider>
  )
}

export default DocumentEdit
