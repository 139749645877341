import { Editor as IEditor } from 'tinymce'
import {
  ATTR_LIST_HEADING,
  ATTR_LIST_TYPE,
  LIST_TYPE_HEADING,
  LIST_TYPE_PARENTHESES,
  classes,
  ATTR_POWER_PASTE_STYLE,
} from 'config/textEditor/textEditor.constants'
import { ICustomListCounters } from '../TextEditor.types'
import Validator from 'validator'
import { unwrapHtmlElement } from '../../../utils/text-selection'

const initialCounters: ICustomListCounters = {
  list_heading_1_counter: 0,
  list_heading_2_counter: 0,
  list_heading_3_counter: 0,
  list_parentheses_counter: 0,
}

export const insertListNumber = (
  element: HTMLElement,
  counters: ICustomListCounters,
  type: string,
  listHeadingLvl?: string | null
): void => {
  const createSpanStr = (counters: ICustomListCounters, type: string): string => {
    const {
      list_heading_1_counter,
      list_heading_2_counter,
      list_heading_3_counter,
      list_parentheses_counter,
    } = counters

    let num = ''
    switch (type) {
      case LIST_TYPE_PARENTHESES:
        num = `(${list_parentheses_counter})`
        break
      case LIST_TYPE_HEADING:
        if (list_heading_1_counter) num = list_heading_1_counter + '.'
        if (list_heading_2_counter) num += list_heading_2_counter + '.'
        if (list_heading_3_counter) num += list_heading_3_counter + '.'
        break
    }

    let markEl = null

    const child =
      element.firstChild?.nodeName === 'SPAN' ? element.firstElementChild : null

    if (child) {
      markEl = child.firstChild?.nodeName === 'MARK' ? child.firstElementChild : null
    }

    if (markEl) {
      markEl.innerHTML = num
      num = markEl.outerHTML
    }

    return num
      ? `<span class="mceNonEditable ${classes.list_item_span}" contenteditable="false" style="pointer-events: none; font-style: inherit; font-size: inherit; font-weight: inherit; font-family: inherit;">${num}</span>`
      : ''
  }
  element.querySelector(`.${classes.list_item_span}`)?.remove()
  const spanStr = createSpanStr(counters, type)
  element.insertAdjacentHTML('afterbegin', spanStr)
}

export const renderEditorContent = (editor: IEditor) => {
  const doc = editor.getDoc()
  const listItems = doc.querySelectorAll<HTMLElement>(`*[${ATTR_LIST_TYPE}]`)
  let counters: ICustomListCounters = { ...initialCounters }

  listItems.forEach((listItem, i) => {
    let headingType = listItem.getAttribute(ATTR_LIST_HEADING)
    const listItemType = listItem.getAttribute(ATTR_LIST_TYPE)
    if (!listItemType) return console.error('No List Item Type')

    const {
      list_heading_1_counter,
      list_heading_2_counter,
      list_heading_3_counter,
      list_parentheses_counter,
    } = counters

    // HANDLE HEADING LISTS
    if (listItemType === LIST_TYPE_HEADING) {
      switch (headingType) {
        case '1':
          counters = {
            ...initialCounters,
            list_heading_1_counter: list_heading_1_counter + 1,
          }
          break
        case '2':
          counters = {
            ...counters,
            list_heading_2_counter: list_heading_2_counter + 1,
            list_heading_3_counter: 0,
            list_parentheses_counter: 0,
          }
          break
        case '3':
          counters = {
            ...counters,
            list_heading_3_counter: list_heading_3_counter + 1,
            list_parentheses_counter: 0,
          }
          break
      }
    }

    if (listItemType === LIST_TYPE_PARENTHESES) {
      counters = {
        ...counters,
        list_parentheses_counter: list_parentheses_counter + 1,
      }
    }
    insertListNumber(listItem, counters, listItemType, headingType)
  })
}

interface IExistingHeadingsMap {
  [key: string]: HTMLElement | null
}

export const getSizeAndUnit = (size: string): [string, string] => {
  let numString = ''
  let unitString = ''
  for (let char of size) {
    if (Validator.isNumeric(char) || char === '.') {
      numString += char
    } else {
      unitString += char
    }
  }
  return [numString, unitString]
}

export const transformSelectionToList = (
  editor: IEditor,
  listType: string,
  listHeadingLvl?: string
): void => {
  const transformSelection = (paragraphElement: Element) => {
    const body = editor.getBody()

    const existingPP: IExistingHeadingsMap = {
      1: body.querySelector<HTMLElement>(
        `*[${ATTR_LIST_HEADING}="1"][data-powerpaste="true"]`
      ),
      2: body.querySelector<HTMLElement>(
        `*[${ATTR_LIST_HEADING}="2"][data-powerpaste="true"]`
      ),
      3: body.querySelector<HTMLElement>(
        `*[${ATTR_LIST_HEADING}="3"][data-powerpaste="true"]`
      ),
    }

    const existingNOPP: IExistingHeadingsMap = {
      1: body.querySelector<HTMLElement>(
        `*[${ATTR_LIST_HEADING}="1"]:not([data-powerpaste])`
      ),
      2: body.querySelector<HTMLElement>(
        `*[${ATTR_LIST_HEADING}="2"]:not([data-powerpaste])`
      ),
      3: body.querySelector<HTMLElement>(
        `*[${ATTR_LIST_HEADING}="3"]:not([data-powerpaste])`
      ),
    }

    let alreadyExists = listHeadingLvl ? existingPP[listHeadingLvl] : null

    let done = false

    paragraphElement.querySelectorAll('span').forEach(spanEl => {
      const isCustomListItemSpan = spanEl.classList.contains(classes.list_item_span)
      const spanHasText = !!spanEl.textContent?.trim().length

      if (!spanHasText && !isCustomListItemSpan) {
        spanEl.remove()
      } else if (spanHasText && !isCustomListItemSpan) {
        // check if there is any prev headings without powerpaste styles
        if (listHeadingLvl && existingNOPP[listHeadingLvl]) {
          unwrapHtmlElement(spanEl)
        }
        // if there are previous elements with powerpaste styles
        if (
          // paragraphElement.nodeName !== 'P' &&
          !alreadyExists &&
          !done &&
          !existingNOPP[listHeadingLvl ?? '666']
        ) {
          ;(paragraphElement as HTMLElement).style.fontSize = spanEl.style.fontSize
          ;(paragraphElement as HTMLElement).style.fontWeight = spanEl.style.fontWeight
          ;(paragraphElement as HTMLElement).style.fontFamily = spanEl.style.fontFamily
          ;(paragraphElement as HTMLElement).style.fontStyle = spanEl.style.fontStyle
          ;(paragraphElement as HTMLElement).style.lineHeight = spanEl.style.lineHeight
          // NEEDED only for EMPTY PARAGRAHPS => use data-powerpaste attribute to check for lower list
          // levels if power paste styles are used. In this case use the styles of highler level heading
          // and only reduce font-size
          paragraphElement.setAttribute('data-powerpaste', 'true')
          done = true
        }
      }
    })

    if (alreadyExists && !done) {
      const existingStyles = alreadyExists.getAttribute('style')
      if (existingStyles) paragraphElement.setAttribute('style', existingStyles)
      const textContent = paragraphElement.textContent
      paragraphElement.innerHTML = ''
      paragraphElement.textContent = textContent
      done = true
    } else if (!done) {
      // check if upper level heading exists. Means that heading is applied on empty paragraph
      if (listHeadingLvl === '2' && existingPP[1]) {
        const existingStyles = existingPP[1].getAttribute('style')
        if (existingStyles) paragraphElement.setAttribute('style', existingStyles)
        const textContent = paragraphElement.textContent
        paragraphElement.innerHTML = ''
        paragraphElement.textContent = textContent
        const upperLevelFontSize = existingPP[1].style.fontSize
        const [fontSize, unit] = getSizeAndUnit(upperLevelFontSize)
        const newFontSize = parseFloat(fontSize) * 0.875
        ;(paragraphElement as HTMLElement).style.fontSize = `${newFontSize}${unit}`
        paragraphElement.setAttribute('data-powerpaste', 'true')
        done = true
      }

      if (listHeadingLvl === '3' && existingPP[2]) {
        const existingStyles = existingPP[2].getAttribute('style')
        if (existingStyles) paragraphElement.setAttribute('style', existingStyles)
        const textContent = paragraphElement.textContent
        paragraphElement.innerHTML = ''
        paragraphElement.textContent = textContent
        const upperLevelFontSize = existingPP[2].style.fontSize
        const [fontSize, unit] = getSizeAndUnit(upperLevelFontSize)
        const newFontSize = parseFloat(fontSize) * 0.875
        ;(paragraphElement as HTMLElement).style.fontSize = `${newFontSize}${unit}`
        paragraphElement.setAttribute('data-powerpaste', 'true')
        done = true
      }
    }

    ;(paragraphElement as HTMLElement).style.marginLeft = '0'
    paragraphElement.setAttribute(ATTR_LIST_TYPE, listType)
    if (listHeadingLvl) paragraphElement.setAttribute(ATTR_LIST_HEADING, listHeadingLvl)
  }

  let selectedNodes = editor.selection.getSelectedBlocks()
  selectedNodes = selectedNodes.filter(
    node =>
      ['p', 'h1', 'h2', 'h3', 'h4'].includes(node.nodeName.toLowerCase()) &&
      Boolean(node.textContent?.length)
  )
  selectedNodes.forEach(transformSelection)
  renderEditorContent(editor)
}

export const restoreCustomListToParagraph = (editor: IEditor): void => {
  let nodes = editor.selection.getSelectedBlocks()

  nodes.forEach(node => {
    let theNode: HTMLElement | null = node as HTMLElement
    if (theNode?.nodeName === 'SPAN') theNode = theNode.parentElement

    if (theNode && theNode.hasAttribute(ATTR_LIST_TYPE)) {
      const cs = getMostCommonStyles(editor)
      theNode.removeAttribute(ATTR_LIST_TYPE)
      theNode.removeAttribute(ATTR_LIST_HEADING)
      theNode.removeAttribute(ATTR_POWER_PASTE_STYLE)

      const newNode = document.createElement('p')

      if (theNode.firstElementChild?.classList.contains(classes.list_item_span)) {
        theNode.removeChild(theNode.firstElementChild)
      }

      newNode.innerHTML = theNode.textContent ?? ''
      let style = ''
      if (cs.fontFamily) style += `font-family: ${cs.fontFamily}; `
      if (cs.fontSize) style += `font-size: ${cs.fontSize}; `
      if (cs.marginLeft) style += `margin-left: ${cs.marginLeft}; `
      if (cs.fontWeight) style += `font-weight: ${cs.fontWeight}; `
      if (cs.fontStyle) style += `font-style: ${cs.fontStyle}; `

      newNode.setAttribute('style', style)
      theNode.replaceWith(newNode)
    }
  })
  renderEditorContent(editor)
}

export const createHeaderHtml = (
  imgSrc: string,
  imgHeight: string | undefined
): string => {
  const wrapper = document.createElement('header')
  const imgEl = document.createElement('img')
  wrapper.style.display = 'flex'
  wrapper.style.alignItems = 'center'
  wrapper.style.justifyContent = 'flex-end'
  wrapper.style.flex = '1'
  wrapper.style.overflow = 'hidden'
  wrapper.style.maxHeight = '100%'
  wrapper.style.paddingRight = '16px'
  imgEl.style.maxHeight = '100px'
  imgEl.style.width = 'auto'
  if (imgHeight) imgEl.style.height = imgHeight
  imgEl.setAttribute('src', imgSrc)
  wrapper.appendChild(imgEl)
  return wrapper.outerHTML
}

export const createFooterHtml = (text?: string): string => {
  const wrapper = document.createElement('footer')
  const spanEl = document.createElement('span')
  spanEl.textContent = '1'
  wrapper.style.display = 'flex'
  wrapper.style.alignItems = 'flex-end'
  wrapper.style.justifyContent = 'flex-end'
  wrapper.style.flex = '1'
  wrapper.style.overflow = 'hidden'
  wrapper.style.maxHeight = '100%'

  wrapper.appendChild(spanEl)
  return wrapper.outerHTML
}

interface IMostCommonStylesMap {
  fontFamily: string | null
  fontSize: string | null
  marginLeft: string | null
  fontWeight: string | null
  fontStyle: string | null
}

export const getMostCommonStyles = (editor: IEditor): IMostCommonStylesMap => {
  type TMaps = { [key: string]: number }
  const body = editor.getBody()
  const mostCommonFFamilyMap: TMaps = {}
  const mostCommonFSizeMap: TMaps = {}
  const mostCommonMLeftMap: TMaps = {}
  const mostCommonFWeightMap: TMaps = {}
  const mostCommonFStyleMap: TMaps = {}

  body
    .querySelectorAll<HTMLParagraphElement>(`p:not([${ATTR_LIST_TYPE}])`)
    .forEach(el => {
      const mLeft = el.style?.marginLeft
      if (!mLeft) {
        if (!mostCommonMLeftMap[0]) mostCommonMLeftMap[0] = 0
        mostCommonMLeftMap[0] += 1
      }
      if (!mostCommonMLeftMap[mLeft]) {
        mostCommonMLeftMap[mLeft] = 0
        mostCommonMLeftMap[mLeft] += 1
      }
    })

  body
    .querySelectorAll<HTMLSpanElement>(`span:not(${classes.list_item_span})`)
    .forEach(el => {
      const _fFamily = el?.style?.fontFamily
      const _fSize = el?.style?.fontSize

      if (_fFamily) {
        if (!mostCommonFFamilyMap[_fFamily]) mostCommonFFamilyMap[_fFamily] = 0
        mostCommonFFamilyMap[_fFamily] += 1
      }
      if (_fSize) {
        if (!mostCommonFSizeMap[_fSize]) mostCommonFSizeMap[_fSize] = 0
        mostCommonFSizeMap[_fSize] += 1
      }
    })

  let mostCommonFontSize = null
  let mostCommonFontFamily = null
  let mostCommonMLeft = null
  let mostCommonFontWeight = null
  let mostCommonFontStyle = null

  for (let key in mostCommonFFamilyMap) {
    if (
      !mostCommonFontFamily ||
      mostCommonFFamilyMap[mostCommonFontFamily] < mostCommonFFamilyMap[key]
    )
      mostCommonFontFamily = key
  }

  for (let key in mostCommonFSizeMap) {
    if (
      !mostCommonFontSize ||
      mostCommonFSizeMap[mostCommonFontSize] < mostCommonFSizeMap[key]
    )
      mostCommonFontSize = key
  }

  for (let key in mostCommonMLeftMap) {
    if (!mostCommonMLeft || mostCommonMLeftMap[mostCommonMLeft] < mostCommonMLeftMap[key])
      mostCommonMLeft = key
  }

  for (let key in mostCommonFWeightMap) {
    if (
      !mostCommonFontWeight ||
      mostCommonFWeightMap[mostCommonFontWeight] < mostCommonFWeightMap[key]
    )
      mostCommonFontWeight = key
  }

  for (let key in mostCommonFStyleMap) {
    if (
      !mostCommonFontStyle ||
      mostCommonFStyleMap[mostCommonFontStyle] < mostCommonFStyleMap[key]
    )
      mostCommonFontStyle = key
  }

  return {
    fontFamily: mostCommonFontFamily,
    fontSize: mostCommonFontSize,
    marginLeft: mostCommonMLeft,
    fontWeight: mostCommonFontWeight,
    fontStyle: mostCommonFontStyle,
  }
}

export const previewAndPrint = (htmlData: string): void => {
  const htmlBody = window.document.createElement('div')
  htmlBody.setAttribute('style', 'padding: 60px; width: 609px; zoom: 115%;')
  htmlBody.innerHTML = htmlData
  const iframeEl = window.document.createElement('iframe')
  const body = window.document.querySelector('body')
  body?.appendChild(iframeEl)
  const p = iframeEl.contentWindow
  if (!p) return

  iframeEl.setAttribute('style', 'width: 0; height: 0; position: absolute;')
  p.document.open()
  p.document.write(htmlBody.outerHTML)
  p.document.close()
  p.focus()
  p.print()
  body?.removeChild(iframeEl)
}
