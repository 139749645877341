import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DocumentGeneration } from 'components/features/documentGeneration/DocumentGeneration'
import { useFetchTemplate } from 'queries/templates'
import { DocumentGenerationLayout } from 'components/features/documentGeneration/layout/DocumentGenerationLayout'
import { DocumentGenerationProvider } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import TemplatePreviewModal from 'pages/Authenticated/Templates/components/TemplatePreviewModal/TemplatePreviewModal.component'

const QuestionnairePublic: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const { goBack } = useHistory()
  const { data } = useFetchTemplate(templateId, true)
  const [close, setClose] = useState(false)

  if (!data)
    return <DocumentGenerationLayout step={1} onClose={goBack} isLoading={true} />

  const { id, htmlData, questions, contentVersionId, name, isCasus } = data
  return (
    <DocumentGenerationProvider
      initialData={{
        templateId: id,
        templateHtmlData: htmlData,
        questions,
        templateContentVersionId: contentVersionId,
        name,
        isCasus,
        mode: 'create_public',
      }}
    >
      <DocumentGeneration />
      <TemplatePreviewModal
        isOpen={data && !close}
        onUseTemplate={() => setClose(true)}
        template={data}
        isLoading={!data}
        onClose={() => setClose(true)}
        editingDisabled
      />
    </DocumentGenerationProvider>
  )
}

export default QuestionnairePublic
