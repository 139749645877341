const REPLACEMENT_TYPES = {
  text: 'QUESTION_TYPE_TEXT_BOX',
  date: 'QUESTION_TYPE_DATE',
  number: 'QUESTION_TYPE_NUMBER',
  radio: 'QUESTION_TYPE_RADIO_REPLACEMENT',
}

const CHOICE_TYPES = ['QUESTION_TYPE_CHECKBOX', 'QUESTION_TYPE_RADIO_LINK']

const dataStructure = {
  segments: [
    {
      id: '10001',
      type: 'paragraph',
      tag: 'pre',
      styleName: 'paragraph-class',
      textChunks: [
        {
          styles: ['chunk-style-1', 'chunk-style-2'],
          text: 'sample text',
        },
      ],
    },
    {
      id: '10002',
      type: 'table',
      tag: 'table',
      styleName: 'table-class',
      tableHeader: [
        {
          styleName: 'table-header-class',
          cells: [
            {
              styleName: 'table-cell-class',
              content: [],
            },
          ],
        },
      ],
      tableBody: [
        {
          styleName: 'table-header-class',
          cells: [
            {
              styleName: 'table-cell-class',
              content: [],
            },
          ],
        },
      ],
      tableFooter: [
        {
          styleName: 'table-header-class',
          cells: [
            {
              styleName: 'table-cell-class',
              content: [],
            },
          ],
        },
      ],
    },
  ],
  numberingSystem: {
    'System-name': [
      {
        prefix: '',
        type: 'decimal',
        suffix: '.',
        combined: false,
        combineString: '',
        styleName: 'style-name-1',
      },
      {
        prefix: '',
        type: 'lower-latin',
        suffix: ')',
        combined: true,
        combineString: '',
        styleName: 'style-name-2',
      },
    ],
  },
}

const KEY_BLACKLIST = [
  // 'segments',
  'styles',
]
const MEASURE_RESET_KEYS = ['segments', 'content']

const extractNestedKeys = (structure, set = new Set()) => {
  Object.entries(structure).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (!KEY_BLACKLIST.includes(key)) set.add(key)
      value.forEach(obj => extractNestedKeys(obj, set))
    }
  })
  return set
}

const NESTED_STRUCTURE_KEYS = Array.from(extractNestedKeys(dataStructure))

const KEYSTRINGS = {
  remove: '_casus_remove',
  keep: '_casus_keep',
  highlight: '_casus_highlight',
}

const CASUS_IDS = {
  rootElement: '_casus_root_element',
}

const extractSubQuestions = (questionArray = []) =>
  questionArray.reduce((acc, cur) => {
    if (cur.options?.length)
      cur.options.forEach(option => {
        return acc.push(...extractSubQuestions(option?.subquestions))
      })
    acc.push(cur)
    return acc
  }, [])

const measure = (object = {}, start = 0) => {
  if (object.text && object.text.length) {
    return { ...object, start, length: object.text.length }
  }
  const [result, end] = NESTED_STRUCTURE_KEYS.reduce(
    (acc, key) => {
      if (Object.keys(acc[0]).includes(key) && acc[0][key].length) {
        const calculatedStart = acc[0].id ? 0 : acc[1]
        const [measuredArray, arrayEnd] = acc[0][key].reduce(
          (accumulated, object) => {
            const nestedObject = measure(object, MEASURE_RESET_KEYS.includes(key) ? 0 : accumulated[1])
            accumulated[0].push(nestedObject)
            accumulated[1] += nestedObject.length
            return accumulated
          },
          [[], calculatedStart]
        )
        acc[0][key] = measuredArray
        acc[1] += arrayEnd - calculatedStart
      }
      return acc
    },
    [object, start]
  )
  return { ...result, start, length: end - start }
}

const measureStructureSegments = (structure = {}, start = 0) =>
  structure.segments?.reduce(
    (acc, segment) => {
      const measuredSegment = measure(segment, acc[1])
      acc[0].push(measuredSegment)
      acc[1] += measuredSegment.length
      return acc
    },
    [[], start]
  )[0] || []

const parseQuestionLocations = question =>
  (
    (Object.values(REPLACEMENT_TYPES).includes(question.type)
      ? question?.locations
      : question?.options?.reduce(
          (locations, option) => [
            ...locations,
            ...option.locations.map(location => ({
              ...location,
              optionId: option.id,
            })),
          ],
          []
        )) || []
  ).map(({ id, questionId, locationId, optionId, start, length }) => ({
    segmentId: id,
    questionId,
    locationId,
    optionId,
    start,
    length,
  }))

export {
  REPLACEMENT_TYPES,
  CHOICE_TYPES,
  MEASURE_RESET_KEYS,
  NESTED_STRUCTURE_KEYS,
  KEYSTRINGS,
  CASUS_IDS,
  extractSubQuestions,
  measure,
  measureStructureSegments,
  parseQuestionLocations,
}
