import { Question } from 'types'
import React, { useState } from 'react'

import { useEditor } from 'config/textEditor/useEditor'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'

import QuestionsOverviewComponent from './QuestionsOverview.component'
import {
  deepDeleteQuestion,
  removeHighlightItemsByQuestionId,
  StorageService,
} from 'utils'
import { QUESTION_TYPE_TEXT_BOX } from 'constants/question-types'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'
import { initializeQuestion } from '../../../services'
import { useUser } from 'queries/user/useUser'

export const QuestionsOverview: React.FC = () => {
  const editor = useEditor()
  const {
    set,
    onQuestionPreview,
    questions,
    name,
    showQuestionsPreview,
  } = useTemplateCreation()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice
  const [templateName, setTemplateName] = useState(name || 'Unbenannte Vorlage')

  React.useEffect(() => {
    if (name) setTemplateName(name)
  }, [name])

  const onToggleQuestionsPreview = (): void => {
    set({
      showQuestionsPreview: !showQuestionsPreview,
      filteredQuestions: !showQuestionsPreview ? questions : [],
    })
  }

  const handleAddQuestionClick = (): void => {
    trackIntercomEvent('template-creation-add-question-clicked')
    const payload = {
      question: initializeQuestion(questions.length + 1),
      questionStep: 1,
      selectedQuestionType: QUESTION_TYPE_TEXT_BOX,
    } as any
    if (!docXmicro) payload.htmlData = editor?.getBody().innerHTML
    set(payload)
    StorageService.remove('textSelectionHtmlData')
  }

  const handleDeleteQuestion = (question: Question): void => {
    if (!docXmicro) {
      removeHighlightItemsByQuestionId(question.id, editor!)
    }
    const payload = { questions: deepDeleteQuestion(questions, question, docXmicro) } as any
    if (!docXmicro) payload.htmlData = editor?.getBody().innerHTML
    set(payload)
  }

  const handleEditQuestion = (question: Question): void => {
    const payload = {
      question,
      questionStep: 1,
      selectedQuestionType: question.type,
    } as any
    if (!docXmicro) payload.htmlData = editor?.getBody().innerHTML
    set(payload)
  }

  const handleTemplateNameChange = (value: string): void => {
    setTemplateName(value)
  }

  const saveTemplateName = (): void => {
    set({ name: templateName })
  }

  const handleUpdateSort = (questionId: string, sort: number): void => {
    const _questions = [...questions]
    const maxSort = _questions.length!
    let indexToInsert = sort - 1
    if (sort > maxSort) indexToInsert = maxSort - 1
    if (sort < 1) indexToInsert = 0
    const questionIndex = _questions.findIndex(q => q.id === questionId)
    if (questionIndex === undefined) return console.error('Something went wrong')
    if (questionIndex < 0) return console.error('Question not found')
    const questionToReplace = _questions.splice(questionIndex, 1)[0]!
    _questions.splice(indexToInsert, 0, questionToReplace)
    _questions.forEach((_q, i) => (_q.sort = i + 1))
    set({ questions: _questions })
  }

  return (
    <QuestionsOverviewComponent
      templateName={templateName}
      questions={questions}
      isLoading={false}
      saveTemplateName={saveTemplateName}
      handleTemplateNameChange={handleTemplateNameChange}
      toggleQuestionsPreview={onToggleQuestionsPreview}
      handleAddQuestionClick={handleAddQuestionClick}
      handleDeleteQuestion={handleDeleteQuestion}
      handlePreviewQuestion={onQuestionPreview}
      handleEditQuestion={handleEditQuestion}
      handleUpdateSort={handleUpdateSort}
    />
  )
}

export default QuestionsOverview
