import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const AuthorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}))

export const AuthorDetails = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}))

export { Typography }
