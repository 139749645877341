import { FormStepThreeLawyerProps } from '../../components/ProfileInfo.types'
import React, { Fragment } from 'react'
import { stepThree as fields } from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoLawyer/content'
import { Loader, TextInputGroup } from 'components/common'
import { labelStyle, Row, Item, LabelStripeEl } from '../../components/ProfileInfo.styles'
import StripeIban from 'components/stripe/components/Iban/Iban'

const ProfileInfoLawyerStepThree: React.FC<FormStepThreeLawyerProps> = ({
  handleChange,
  values,
  isLoading,
}) => {
  if (isLoading) return <Loader />
  const renderTextFields = () =>
    fields.map(field => (
      <Item key={field.name}>
        <TextInputGroup
          value={values[field.name]}
          onChange={handleChange}
          LabelProps={{ style: labelStyle, variant: 'body2' }}
          InputGroupContainerProps={{ style: { marginBottom: 0 } }}
          customvariant='light'
          {...field}
        />
      </Item>
    ))

  return (
    <Fragment>
      <Row>
        <Item>
          <LabelStripeEl>IBAN</LabelStripeEl>
          <StripeIban />
        </Item>
        {renderTextFields()}
      </Row>
    </Fragment>
  )
}

export default ProfileInfoLawyerStepThree
