import { OptionType } from 'types'
import { v4 as uuidv4 } from 'uuid'

export const generateNewOptionObject = (withLoc: boolean = true): OptionType => {
  let optObj: any = {
    id: uuidv4(),
    text: '',
    subquestions: [],
    locations: [],
  }

  if (withLoc) {
    optObj.locations = []
  }

  if (!withLoc) {
    delete optObj.locations
  }

  return optObj
}
