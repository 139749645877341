export const UI_TEMPLATE_PREVIEW = 'UI_TEMPLATE_PREVIEW'
export const UI_DOCUMENT_PREVIEW = 'UI_DOCUMENT_PREVIEW'
export const UI_STATUS_MODAL = 'UI_STATUS_MODAL'
export const UI_ADD_FOLDER_MODAL = 'UI_ADD_FOLDER_MODAL'
export const UI_FILE_MANAGER_MODAL = 'UI_FILE_MANAGER_MODAL'
export const UI_DOCUMENT_SETTINGS_MODAL = 'UI_DOCUMENT_SETTINGS_MODAL'

export const UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC =
  'UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC'
export const UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID =
  'UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID'
export const UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NEW_SUBSCRIPTION =
  'UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NEW_SUBSCRIPTION'
export const UI_SUPSCRIPTION_UPGRADE_SUCCESS_MODAL =
  'UI_SUPSCRIPTION_UPGRADE_SUCCESS_MODAL'

export const UI_TRIAL_EXPIRED_MODAL = 'UI_TRIAL_EXPIRED_MODAL'

export type TUiElement =
  | typeof UI_DOCUMENT_PREVIEW
  | typeof UI_TEMPLATE_PREVIEW
  | typeof UI_STATUS_MODAL
  | typeof UI_ADD_FOLDER_MODAL
  | typeof UI_FILE_MANAGER_MODAL
  | typeof UI_DOCUMENT_SETTINGS_MODAL
  | typeof UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_BASIC
  | typeof UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID
  | typeof UI_SUPSCRIPTION_UPGRADE_SUCCESS_MODAL
  | typeof UI_TRIAL_EXPIRED_MODAL
  | typeof UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NEW_SUBSCRIPTION
