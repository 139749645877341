import { Box, styled } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 0, 3),
  width: 382,
  backgroundColor: '#fff',
}))
