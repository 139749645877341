import React, { useEffect, useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useUser } from 'queries/user/useUser'
import { useRootCategoryFolder } from 'hooks/useRootCategoryFolder'
import { useCurrentFolder } from 'hooks/useCurrentFolder'
import { useFetchFolder, useFetchFolders } from 'queries/folders'

import FoldersApi from 'api/folders.api'
import { documentsRoutes } from 'constants/routes'
import { Loader } from 'components/common'

export const EmailSharedFolderRedirect: React.FC = () => {
  const {
    replace,
    location: { pathname },
  } = useHistory()
  const user = useUser()
  const { type, id } = useParams<{ type: 'document' | 'template'; id: string }>()
  const where = type === 'document' ? 'docs' : 'templates'
  const sharedFolder = useRootCategoryFolder(where, 'shared')
  const { data: folderToGo } = useFetchFolder(where, id)
  const [queue, setQueue] = useState([id])
  const { currentFolder, setCurrentFolder } = useCurrentFolder(where)
  const [done, setDone] = useState(false)
  const { isFetching, data } = useFetchFolders(where, sharedFolder, {
    enabled: !!user && !!localStorage.casusToken,
  })

  const { children } = currentFolder
  const redirectUrl = type === 'document' ? documentsRoutes.main : '/vorlagen'

  useEffect(() => {
    if (user && currentFolder.type !== 'shared') setCurrentFolder(sharedFolder)
  }, [user, currentFolder.type, sharedFolder, setCurrentFolder])

  useEffect(() => {
    if (!done || isFetching || !user || !data) return
    const lastItem = queue[queue.length - 1]
    const next = children.find(ch => ch.id === lastItem)
    if (next && queue.length) {
      const updatedQueue = [...queue]
      updatedQueue.pop()
      setCurrentFolder(next)
      setQueue(updatedQueue)
    }

    if (!queue.length || !next) replace(redirectUrl)
  }, [
    currentFolder,
    done,
    queue,
    setCurrentFolder,
    children,
    replace,
    redirectUrl,
    isFetching,
    user,
    data,
  ])

  const fetchFolders = useCallback(async () => {
    if (!folderToGo || !data || !user || done) return
    const theChild = children.find(child => child.id === folderToGo.id)
    if (theChild) {
      setDone(true)
    } else {
      let modified_queue = [id, folderToGo.parentCategoryId]

      while (true) {
        if (!modified_queue.length) {
          setDone(true)
          break
        }
        const lastItemId = modified_queue[modified_queue.length - 1]
        if (!lastItemId) {
          setDone(true)
          break
        }
        const exists = children.find(ch => ch.id === lastItemId)
        if (exists) {
          setDone(true)
          setQueue(modified_queue)
          break
        }
        try {
          const parentFolder = await FoldersApi.getOne(where, lastItemId)
          modified_queue.push(parentFolder.id)
        } catch (err) {
          replace(redirectUrl)
          setDone(true)
        }
      }
    }
  }, [children, folderToGo, id, where, replace, redirectUrl, data, user, done])

  useEffect(() => {
    if (!user) {
      const encodedUrl = encodeURIComponent(pathname)
      return replace(`/auth/login?redirect=${encodedUrl}`)
    }
    if (folderToGo && !done) fetchFolders()
  }, [user, id, pathname, replace, fetchFolders, type, folderToGo, done])

  return <Loader />
}
