import React from 'react'
import { PopupMenuContainer, Popover } from './MenuPopover.styles'
import { MenuPopoverProps } from './MenuPopover.types'
import MenuPopoverItem from './MenuPopoverItem'

const MenuPopover: React.FC<MenuPopoverProps> = ({
  menuItems,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  anchorPosition,
  children,
  handleClose,
}) => {
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  if ((!menuItems?.length || !children) && !anchorEl) return null
  return (
    <Popover
      anchorReference={anchorPosition && 'anchorPosition'}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorPosition={anchorPosition && anchorPosition}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : {
              vertical: 100,
              horizontal: 'right',
            }
      }
      transformOrigin={
        transformOrigin
          ? transformOrigin
          : {
              vertical: 'top',
              horizontal: 'center',
            }
      }
      elevation={20}
    >
      <PopupMenuContainer>
        {menuItems
          ? menuItems.map((menuItem, i) => (
              <MenuPopoverItem
                key={i + menuItem.label}
                menuItem={menuItem}
                handleCloseParent={handleClose}
              />
            ))
          : children}
      </PopupMenuContainer>
    </Popover>
  )
}

export default MenuPopover
