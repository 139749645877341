import React from 'react'
import {Container, TopContainer, Btn} from './NewVersion.styles'
import {svgStringToDataUrl} from "utils";
import svgString from "components/subscriptions/components/Modals/FreeTrialExpiredModal/illustration";
import {
    Heading,
    Image,
    Text
} from "components/subscriptions/components/Modals/ModalTemplate/ModalTemplate.styles";
import {queryKeys} from "queries";
import {useQueryClient} from "react-query";

const image = svgStringToDataUrl(svgString)
const url = `https://${process.env.REACT_APP_DOMAIN_V2}/auth/login`

const NewVersion: React.FC = () => {

    const queryCache = useQueryClient()
    queryCache.setQueryData(queryKeys.USER, null)

    return (
        <TopContainer>
            <Container>
                <Image src={image}/>
                <Heading variant='h3'>Neue Version verfügbar</Heading>
                <Text variant='body1'>Wir arbeiten stets an Verbesserungen für CASUS. Es ist eine neue Version verfügbar. Bitte loggen Sie sich erneut ein, um die Version zu nutzen.</Text>
                <Btn href={url}>Log Out</Btn>
            </Container>
        </TopContainer>
    )
}

export default NewVersion
