export const USER_TYPE_LAWYER_ADMIN = 'USER_TYPE_LAWYER_ADMIN'
export const USER_TYPE_LAWYER_EMPLOYEE = 'USER_TYPE_LAWYER_EMPLOYEE'
export const USER_TYPE_CLIENT_ADMIN = 'USER_TYPE_CLIENT_ADMIN'
export const USER_TYPE_CLIENT_EMPLOYEE = 'USER_TYPE_CLIENT_EMPLOYEE'
export const USER_TYPE_CASUS_ADMIN = 'USER_TYPE_CASUS_ADMIN'

export type UserType =
  | typeof USER_TYPE_LAWYER_ADMIN
  | typeof USER_TYPE_CLIENT_ADMIN
  | typeof USER_TYPE_CLIENT_EMPLOYEE
  | typeof USER_TYPE_LAWYER_EMPLOYEE
