import { enrichCSS, parseSegments } from './parsing'
// import { generateHtml } from './htmlGeneration'
import documentSettings from './hardcoded/documentSettings'

export const parseBEData = (dataStructure = {}, stylesheet = {}, questions = []) => {
  const segments = parseSegments(dataStructure.segments)
  const numberingSystem = dataStructure.numberingSystem
  const structure = { segments, numberingSystem, documentSettings }
  // const html = generateHtml(structure, questions)
  const css = enrichCSS(stylesheet, structure.numberingSystem)
  return [structure, css]
}
