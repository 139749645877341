import { styled } from '@material-ui/core/styles'
import { IStepContainer } from './Steps.types'
import { Box } from '@material-ui/core'
import CheckIconSvg from '@material-ui/icons/Check'

export const StepsContainer = styled(Box)({
  display: 'flex',
  height: '100%',
  marginLeft: 'auto',
})

export const StepContainer = styled(Box)(({ theme, isactive }: IStepContainer) => ({
  flex: 1,
  minWidth: theme.spacing(30),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: isactive ? 700 : 400,
  color: isactive ? theme.colorz.secondary.main : theme.colorz.secondary[300],
  letterSpacing: 0.2,
  '&:not(:last-child)': {
    borderRight: '1px solid ' + theme.colorz.secondary[100],
  },
}))

export const CheckIcon = styled(CheckIconSvg)(({ theme }) => ({
  width: 16,
  height: 13,
  objectFit: 'contain',
  color: theme.colorz.secondary.main,
}))
