import { ModularQuestionProps, QuestionsOverviewProps } from './QuestionsOverview.types'
import React, { Fragment } from 'react'
import {
  ActionButtonsContainer,
  QuestionsContainer,
  Typography,
  TextInputWrapper,
  FreeText,
} from './QuestionsOverview.styles'
import Question from './Question/Question'
import CustomButton from 'components/common/CustomButtonV2/CustomButton'
import {
  btnContent,
  questionsOverviewContent as overviewContent,
} from 'pages/Authenticated/Template/constants/content'
import QuestionWithSubquestions from './Question/QuestionWithSubquestions'
import { TextInputGroup } from 'components/common'

export const ModularQuestions: React.FC<ModularQuestionProps> = ({
  questions,
  ...otherProps
}) => (
  <Fragment>
    {questions?.map((q, i) => {
      let QuestionElement
      if (q.options) {
        QuestionElement = QuestionWithSubquestions
      } else {
        QuestionElement = Question
      }
      return <QuestionElement key={q.id} index={i} question={q} {...otherProps} />
    })}
  </Fragment>
)

const QuestionsOverviewComponent: React.FC<QuestionsOverviewProps> = ({
  toggleQuestionsPreview,
  handleAddQuestionClick,
  handleTemplateNameChange,
  saveTemplateName,
  templateName,
  isLoading,
  ...otherProps
}) => {
  let content
  const { questions } = otherProps

  if (questions && !questions.length) {
    content = (
      <FreeText>
        <Typography variant='body1' align='center'>
          {!isLoading ? overviewContent.noQuestionsText : overviewContent.isLoadingText}
        </Typography>
      </FreeText>
    )
  } else {
    content = <ModularQuestions {...otherProps} />
  }

  return (
    <Fragment>
      {handleTemplateNameChange && (
        <TextInputWrapper>
          <TextInputGroup
            type='text'
            onChange={e => handleTemplateNameChange(e.target.value)}
            value={templateName}
            error={!templateName.length}
            placeholder='Template Name'
            name='templateName'
            disabled={isLoading}
            onBlur={saveTemplateName}
            InputGroupContainerProps={{
              style: {
                borderColor: !templateName.length ? 'red !important' : 'transparent',
                marginBottom: 0,
              },
            }}
          />
        </TextInputWrapper>
      )}
      <ActionButtonsContainer>
        <CustomButton
          id='template-creation-add-new-question-btn'
          btntype='primary'
          disabled={isLoading}
          style={{ flexGrow: 1 }}
          onClick={handleAddQuestionClick}
        >
          {btnContent.addQuestion}
        </CustomButton>
        <CustomButton
          id='template-creation-preview-questions'
          btntype='secondary'
          width={135}
          style={{ marginLeft: 16 }}
          onClick={toggleQuestionsPreview}
          disabled={Boolean(questions && questions.length === 0) || isLoading}
        >
          {btnContent.preview}
        </CustomButton>
      </ActionButtonsContainer>
      <QuestionsContainer>{content}</QuestionsContainer>
    </Fragment>
  )
}

export default QuestionsOverviewComponent
