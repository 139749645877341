import { OptionType, Question } from 'types'
import { extractSubquestionsFromOptions } from './extractSubquestionsFromOptions'

export const deepExtractSubquestionsFromOption = (option: OptionType): Question[] => {
  let _subquestions: Question[] = [...option.subquestions]
  let index = 0

  while (index < _subquestions.length) {
    const _options = _subquestions[index].options
    if (_options) {
      const deeperSubqestions = extractSubquestionsFromOptions(_options)
      _subquestions.push(...deeperSubqestions)
    }
    index++
  }
  return _subquestions
}
