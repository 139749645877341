import React from 'react'
import { Modal, Box } from '@material-ui/core'
import theme from 'config/themes/theme'

interface ModalElementProps {
  onClose: (...props: any) => any
  open: boolean
  style?: React.CSSProperties
  backdropStyle?: React.CSSProperties
  withBg?: boolean
}

const RootModal: React.FC<ModalElementProps> = ({
  open,
  children,
  style,
  backdropStyle,
  withBg,
  onClose,
}) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10vh',
        ...style,
      }}
      BackdropProps={{
        style: {
          background: withBg ? theme.colorz.secondary[100] : 'transparent',
          opacity: withBg ? 0.9 : 1,
          ...backdropStyle,
        },
      }}
    >
      <Box
        style={{
          outline: 'none',
        }}
      >
        {children}
      </Box>
    </Modal>
  )
}

export default RootModal
