import { IConfirmationPopupContent } from 'types'

export const POPUP_CONFIRM_EDIT_CHANGED_DOCUMENT: IConfirmationPopupContent = {
  heading:
    'Der Text dieses Dokuments wurde manuell verändert. Diese Änderungen gehen verloren, wenn Sie den Fragebogen neu beantworten. Möchten Sie mit dem Bearbeiten fortfahren?',
  status: 'warning',
  primaryButtonText: 'Ja, bearbeiten',
  secondaryButtonText: 'Nein, nicht bearbeiten',
}

export const POPUP_CONFIRM_DOCUMENT_VERSIONING: IConfirmationPopupContent = {
  heading:
    'Die Vorlage zu diesem Dokument wurde aktualisiert. Möchten Sie das Dokument aktualisieren?',
  status: 'warning',
  primaryButtonText: 'Ja, aktualisieren',
  secondaryButtonText: 'Nein, nicht aktualisieren',
}
