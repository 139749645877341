// import { DocumentSettingsItem } from 'types'
import { Editor as IEditor } from 'tinymce'
import React, { useEffect, useCallback, useMemo } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { styled } from '@material-ui/core'

import { useDocumentGeneration } from 'components/features/documentGeneration/provider/DocumentGenerationProvider'
import { useMasterData } from 'queries/app/useMasterdata'

import { deepFindQuestion, deepFindQuestionByIds, unwrapHtmlElement } from 'utils'

import * as config from 'config/textEditor/textEditor.config'
import { useEditor } from 'config/textEditor/useEditor'
import { useUser } from 'queries/user/useUser'
// import { generateStructureELEMENT } from 'utilities'
import { useFetchTemplate } from 'queries/templates'
import { generateHtml } from 'utilities'

const Wrapper = styled('div')(({ user }: { user: number }) => ({
  '@media print': {
    display: user ? 'block' : 'none',
  },
  margin: '0 auto',
  paddingTop: 0,
}))

// type T = DocumentSettingsItem | null | undefined
export const DocumentGenerationEditor: React.FC = () => {
  const documentGeneration = useDocumentGeneration()
  const {
    set,
    settings,
    answers,
    questions,
    htmlData: v1htmlData,
    documentDataStructure,
    templateId,
  } = documentGeneration
  const editor = useEditor()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice
  //   const [header, setHeader] = useState<T>(settings?.header)
  //   const [footer, setFooter] = useState<T>(settings?.footer)
  const masterdata = useMasterData()!

  const templateData = useFetchTemplate(templateId).data

  // console.log('TEMPLATE DATA: ', templateData)

  // console.log('DOCUMENT GENERATION EDITOR: ', documentDataStructure)

  const [htmlData, cssData] = useMemo(() => {
    let htmlData = ''
    let cssData = {}
    if (docXmicro && templateData) {
      const { cssData: css }: any = templateData
      const [generatedHTML] = generateHtml(documentDataStructure, questions, answers)
      htmlData = generatedHTML
      cssData = css
    } else htmlData = v1htmlData
    return [htmlData, cssData]
  }, [docXmicro, templateData, documentDataStructure, questions, answers, v1htmlData])

  // console.log('CSS DATA: ', cssData)

  const manageFootersAndHeadersUpdate = useCallback(() => {
    if (!settings || !editor) return

    const body = editor.getBody()
    if (!body) return

    const { footer, header } = settings
    // setFooter(footer)
    // setHeader(header)
    const footerEl = body.querySelector('footer')
    if (footerEl) footerEl.remove()
    if (footer) body.insertAdjacentHTML('beforeend', '<footer>1</footer>')

    const headerEl = body.querySelector('header')
    if (headerEl) headerEl.remove()
    if (header) {
      body.insertAdjacentHTML(
        'afterbegin',
        `<header><img src="${header.imageURL}" alt="logo" style="height: ${header.imageHeight}; width: auto; "/></header>`
      )
    }
  }, [editor, settings])

  useEffect(() => {
    manageFootersAndHeadersUpdate()
  }, [manageFootersAndHeadersUpdate])

  const onInit = async (e: any, theEditor: IEditor) => {
    const body = theEditor.getBody()
    body.setAttribute('lang', 'de_DE')

    body.style.hyphens = settings?.hyphens ? 'auto' : 'none'
    body.setAttribute('contenteditable', 'false')
    if (docXmicro) {
      body.style.userSelect = 'none'
      body.style.pointerEvents = 'none'
    }
    if (!settings) return
    const { footer, header } = settings

    if (footer) body.insertAdjacentHTML('beforeend', '<footer>1</footer>')

    if (header) {
      body.insertAdjacentHTML(
        'afterbegin',
        `<header><img src="${header.imageURL}" alt="logo" style="height: ${header.imageHeight}; width: auto; "/></header>`
      )
    }
  }

  const onClick = (e: any, theEditor: IEditor) => {
    let theTarget = e.target as HTMLElement

    if (['STRONG', 'EM'].includes(theTarget?.nodeName) && theTarget?.parentElement?.nodeName === 'MARK') {
      theTarget = theTarget.parentElement
    }

    if (theTarget.nodeName !== 'MARK') return

    const { dataset, nodeName } = theTarget

    if (!dataset || !nodeName || nodeName !== 'MARK' || !answers || !questions) return
    const { qid, parentid } = dataset
    if (!qid) return console.error('No element qid found')

    const question = parentid ? deepFindQuestionByIds(questions, qid, parentid) : deepFindQuestion(questions, qid)

    if (question) {
      set({ filteredQuestions: [{ ...question, editing: true }], step: 1 })
    }
  }

  const onBeforeExecCommand = (e: any, editor: IEditor) => {
    if (e?.command === 'mcePrint') {
      editor
        .getBody()
        .querySelectorAll('mark')
        .forEach(mEl => unwrapHtmlElement(mEl))
    }
  }

  const height = window.innerHeight - 152

  const stylesFromCssData = Object.entries(cssData).reduce((acc, [key, value]) => {
    return [acc, `${key} { ${value} }`].join(' ')
  }, '')

  const content_style = [
    config.getStyles(masterdata, 'document-generation', {
      hyphens: settings?.hyphens,
    }),
    stylesFromCssData,
  ].join(' ')

  return (
    <Wrapper user={user ? 1 : 0}>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY!}
        initialValue={htmlData}
        onInit={onInit}
        onClick={onClick}
        onBeforeExecCommand={onBeforeExecCommand}
        onSelectionChange={(e, editor) => {
          const sel = editor.selection.getSel()
          sel?.collapse(null)
        }}
        init={{
          menubar: false,
          height: height,
          width: 725,
          plugins: [...config.dp_plugins, 'paste'],
          toolbar: false,
          extended_valid_elements: 'span[*] *[*]',
          pagebreak_separator: `<div class="mcePageBreak" style="page-break-before: always; visibility: hidden">`,
          paste_as_text: true,
          noneditable_noneditable_class: 'mceNonEditable',
          language: 'de',
          font_formats: config.font_formats,
          content_style,
          contextmenu: false,
          branding: false,
          resize: false,
          statusbar: false,
          paste_block_drop: true,
        }}
      />
    </Wrapper>
  )
}
