import { Box, BoxProps, CircularProgress, Theme, styled } from '@material-ui/core'
import { scrollBarStyles } from 'constants/common-styles'

interface IContainer extends BoxProps {
  nav: number
  theme: Theme
}

interface IWrapper extends BoxProps {
  document: number
  selectingisactive: number
  theme: Theme
}

interface IPopoverContainer extends BoxProps {
  theme: Theme
  xposition: number
  yposition: number
}

export const Container = styled(Box)(({ theme, nav }: IContainer) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: `calc(100vh - ${nav ? '112' : '56'}px)`,
  paddingTop: theme.spacing(4),
  overflowY: 'auto',
  position: 'relative',
  ...scrollBarStyles(theme),
}))

export const DocumentWrapper = styled(Box)(
  ({ theme, selectingisactive, document }: IWrapper) => ({
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: selectingisactive ? theme.colorz.primary.main : '#fff',
    position: 'relative',
    boxShadow: '0px 1px 2px rgba(243,238,234,1)',
    maxHeight: 'calc(100vh - 88px)',
  })
)

export const Image = styled('img')({
  alignSelf: 'center',
  width: 61,
  height: 78,
})

export const noTemplateStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
}

export const previewStyles = {
  boxShadow: 'none',
}

export const DocumentText = styled('div')(({ theme }) => ({
  minHeight: '100%',
  overflowX: 'hidden',
  position: 'relative',
  minWidth: 725,
}))

export const Spinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colorz.primary.main,
  position: 'absolute',
  top: '40%',
}))

export const PopoverContainer = styled(Box)(
  ({ theme, xposition, yposition }: IPopoverContainer) => ({
    padding: theme.spacing(1),
    width: 88,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    left: xposition,
    top: yposition,
    backgroundColor: '#fff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: 2,
    zIndex: 1,
  })
)
