import { QuestionNumberProps } from './QuestionNumber.types'
import React, { useState, useEffect } from 'react'
import { TextInputGroup } from 'components/common'
interface IProps {
  [key: string]: number
}

const QuestionNumber: React.FC<QuestionNumberProps> = ({
  question,
  handleChange,
  value,
  error,
}) => {
  const { rangeFrom, rangeTo } = question
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (error) return setErrorMessage('Bitte geben Sie eine Zahl ein.')
    setErrorMessage(null)
  }, [error])

  let props: IProps = {}
  if (
    rangeTo !== undefined &&
    rangeFrom !== undefined &&
    rangeTo !== 0 &&
    rangeFrom !== 0
  ) {
    props = { max: rangeTo, min: rangeFrom }
  }

  return (
    <TextInputGroup
      name={question.id}
      type='text'
      placeholder='Ihre Antwort'
      onChange={e => handleChange(e.target.value)}
      value={value}
      error={!!error || !!errorMessage}
      errorMessage={
        typeof error === 'string' ? error : errorMessage ? errorMessage : null
      }
      customvariant='thick'
      style={{ height: 56 }}
      {...props}
    />
  )
}

export default QuestionNumber
