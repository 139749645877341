import { OptionProps } from '../OptionList.types'
import React from 'react'
import { Container, Label, OptionNameInput, ActionsContainer } from './Option.styles'
import { ModularQuestions } from '../../../QuestionsOverview/QuestionsOverview.component'
import { CustomButton, CheckboxGroup } from 'components/common'
import MoreDropdown from '../../../../MoreDropdown/MoreDropdown'
import { btnContent } from 'pages/Authenticated/Template/constants/content'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'

const Option: React.FC<OptionProps> = ({
  label,
  actions,
  textSelectionIsActive,
  currentSelectionId,
  option,
  value,
  subQuestions,
  addSubqDisabled,
  deleteOptionDisabled,
  handleDeleteOption,
  handleTextChange,
  handleFocusTextInput,
  handleCheckboxChange,
  handleTextSelectClick,
  handleAddSubquestion,
  ...otherProps
}) => {
  const { id, subquestions } = option
  const isActive = currentSelectionId === id && textSelectionIsActive
  const { selectedQuestionType: questionType } = useTemplateCreation()

  const handleActionClick = () => {
    if (!subQuestions && handleTextSelectClick) return handleTextSelectClick(id)
    if (handleAddSubquestion) handleAddSubquestion(option)
  }

  const handleDeleteClick = (): void => {
    if (handleDeleteOption) handleDeleteOption(option)
  }

  const onCheckboxChange = (): void => {
    if (handleCheckboxChange) handleCheckboxChange(id)
  }

  return (
    <Container>
      <Label>{label}</Label>
      {!subQuestions && (
        <MoreDropdown
          handleDeleteClick={handleDeleteClick}
          disabled={deleteOptionDisabled}
        />
      )}
      <OptionNameInput
        name={id}
        value={value.text}
        onChange={handleTextChange}
        onFocus={handleFocusTextInput}
        placeholder={label}
        isdisabled={subQuestions ? 1 : 0}
      />
      {subQuestions && subquestions && subquestions.length ? (
        <ModularQuestions noIndex={true} questions={subquestions} {...otherProps} />
      ) : null}
      {actions || subQuestions ? (
        <ActionsContainer>
          <CustomButton
            btntype={subQuestions ? 'primary-inverted' : 'primary'}
            onClick={handleActionClick}
            width={subQuestions ? 200 : 160}
            disabled={subQuestions ? Boolean(addSubqDisabled) : Boolean(value.checked)}
            textselectionactive={isActive ? 'true' : null}
            id={`template-creation-option-button-${questionType}`}
          >
            {subQuestions ? btnContent.addSubquestion : btnContent.markText}
          </CustomButton>
          {!subQuestions && (
            <CheckboxGroup
              name={id}
              label='Kein Text'
              checked={value.checked}
              handleChange={onCheckboxChange}
              style={{ marginBottom: 0 }}
            />
          )}
        </ActionsContainer>
      ) : null}
    </Container>
  )
}

export default Option
