import { Box, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 96,
})

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  marginBottom: theme.spacing(1),
}))

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
}))

export const Image = styled('img')(({ theme }) => ({
  height: 145,
  width: 'auto',
  marginBottom: theme.spacing(5),
}))
