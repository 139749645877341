import React from 'react'
import { Switch, Route } from 'react-router-dom'

import TemplateCreate from './views/TemplateCreate/TemplateCreate.page'
import TemplateEdit from './views/TemplateEdit/TemplateEdit.page'

const TemplatePage: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/vorlage/neu' component={TemplateCreate} />
      <Route path={'/vorlage/:id/bearbeiten'} component={TemplateEdit} />
    </Switch>
  )
}

export default TemplatePage
