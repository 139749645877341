import { Question } from 'types'
import { extractSubquestionsFromOptions } from './extractSubquestionsFromOptions'

export const deepFindQuestion = (
  questions: Question[],
  questionId: string
): Question | undefined => {
  let questionsToCheck = [...questions]
  let theQ = questionsToCheck.pop()

  while (true) {
    if (!theQ) break
    if (theQ.id === questionId) return theQ
    if (theQ.options) {
      const subQuestions = extractSubquestionsFromOptions(theQ.options)
      questionsToCheck.unshift(...subQuestions)
    }
    theQ = questionsToCheck.pop()
  }
}
