import React, { Fragment, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PagesLayout from 'layout/PagesLayout/PagesLayout'
import AccountSettingsSidebar from './components/AccountSettingsSidebar'
import PasswordSettings from './views/PasswordSettings/PasswordSettings.container'
import ProfileSettings from './views/ProfileSettings/ProfileSettings.container'
import EmailSettings from './views/EmailSettings/EmailSettings.container'
import TwoFaSettings from "./views/TwoFaSettings/TwoFaSettings.container";
import PlansSettings from './views/PlansSettings/PlansSettings'
import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'
import { useUser } from 'queries/user/useUser'

const AccountSettingsPage: React.FC = () => {
  const user = useUser()

  useEffect(() => {
    document.title = 'Einstellungen | CASUS'
  }, [])

  const allowAboPage =
    user?.type === USER_TYPE_CLIENT_ADMIN || user?.type === USER_TYPE_LAWYER_ADMIN

  return (
    <Fragment>
      <AccountSettingsSidebar />
      <PagesLayout>
        <Switch>
          <Route exact path='/einstellungen' component={ProfileSettings} />
          <Route exact path='/einstellungen/passwort' component={PasswordSettings} />
          <Route exact path='/einstellungen/2fa' component={TwoFaSettings} />
          <Route exact path='/einstellungen/email' component={EmailSettings} />
          {allowAboPage && (
            <Route exact path='/einstellungen/abo' component={PlansSettings} />
          )}
          <Redirect to='/einstellungen' />
        </Switch>
      </PagesLayout>
    </Fragment>
  )
}

export default AccountSettingsPage
