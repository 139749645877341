import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 7, 0, 7),
  width: 608,
  backgroundColor: '#fff',
}))

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: 0.42,
  lineHeight: 1.444,
  textAlign: 'center',
  marginBottom: theme.spacing(1),
}))

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0.38,
  lineHeight: 1.625,
  textAlign: 'center',
  margin: theme.spacing(3, 0, 1, 0),
}))
