import { OptionType, IOption, QuestionWithOptions } from 'types'
import React, { useState, useEffect, useCallback } from 'react'

import { useEditor } from 'config/textEditor/useEditor'

import QuestionCheckboxComponent from './QuestionCheckbox.component'
import {
  generateNewLocationObject,
  generateNewOptionObject,
  templateOptionsToState,
} from '../../../../services'
import {
  removeHighlightItemsByLocations,
  removeHighlightItemsByQuestionId,
} from 'utils'
import { forms as content } from 'pages/Authenticated/Template/constants/content'
import { useTemplateCreation } from 'pages/Authenticated/Template/views/TemplateEdit/provider/CreateTemplateProvider'
import {
  addLocationToQuestionOption,
  addOptionToQuestion,
  addTextToQuestionOption,
  deleteQuestionOption,
  editOption,
} from 'pages/Authenticated/Template/views/TemplateEdit/question.utils'
import { useUser } from 'queries/user/useUser'

const CheckboxTypeContainer: React.FC = () => {
  const editor = useEditor()
  const { set, textSelectionIsActive, selectionData, question } =
    useTemplateCreation()
  const user = useUser()
  const docXmicro = !!user?.beta?.newDocxMicroservice

  const {
    id,
    start,
    end,
    currentQuestionId: qid,
    locationId,
  } = selectionData as any
  const options = question?.options!

  const [currentSelectionId, setCurrentSelectionId] = useState<string | null>(
    null
  )

  const [optionsState, setOptionsState] = useState(
    templateOptionsToState(options!)
  )

  const addSelectedLocation = useCallback(
    (
      id: string,
      optId: string,
      start: number,
      end: number,
      qid?: string,
      locId?: string
    ) => {
      const newLocation = generateNewLocationObject(
        id,
        start,
        end,
        qid,
        locId,
        docXmicro
      )
      setOptionsState((prev) => ({
        ...prev,
        [optId]: {
          ...prev[optId],
        },
      }))
      set({
        question: addLocationToQuestionOption(question, {
          id: optId,
          location: newLocation,
        }),
        selectionData: {
          id: null,
          start: null,
          end: null,
          currentQuestionId: null,
          locationId: null,
        },
      })
    },
    [set, question, docXmicro]
  )

  useEffect(() => {
    if (start !== null && end !== null && id && currentSelectionId) {
      addSelectedLocation(id, currentSelectionId, start, end, qid, locationId)
    }
  }, [id, start, end, currentSelectionId, qid, locationId, addSelectedLocation])

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target // name = id
    setOptionsState((prev) => ({
      ...prev,
      [name]: { ...prev[name], text: value },
    }))
    // TODO IMRPOVE
    set({
      question: addTextToQuestionOption(question as QuestionWithOptions, {
        id: name,
        text: value,
      }),
    })
  }

  const handleFocusTextInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target // name = id
    if (name !== currentSelectionId) {
      setCurrentSelectionId(name)
      set({ textSelectionIsActive: false })
    }
  }

  const handleTextSelectClick = (id: string) => {
    if (id === currentSelectionId)
      return set({ textSelectionIsActive: !textSelectionIsActive })
    setCurrentSelectionId(id)
    set({ textSelectionIsActive: true })
  }

  const removeLocationsFromOption = (optId: string, option: IOption) => {
    const newOpt = { ...option, locations: [] }
    removeHighlightItemsByLocations(option.locations!, editor!)
    set({ question: editOption(question, newOpt) })

    if (!editor) return
    // const html = editor.getBody().innerHTML
    // localStorage.setItem('textSelectionHtmlData', html)
  }

  const handleCheckboxChange = (id: string) => {
    const { checked } = optionsState[id]
    const clickedOption = options?.find((opt) => opt.id === id)
    if (!clickedOption) return
    const hasLocations = clickedOption?.locations?.length
    if (!checked && hasLocations) removeLocationsFromOption(id, clickedOption)

    set({ textSelectionIsActive: false })
    setOptionsState((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        checked: !prev[id].checked,
      },
    }))
  }

  const handleAddOption = (): void => {
    const newOption = generateNewOptionObject()
    setOptionsState((prev) => ({
      ...prev,
      [newOption.id]: { ...newOption, checked: false },
    }))
    set({
      question: addOptionToQuestion(question, newOption),
      textSelectionIsActive: false,
    })
  }

  const handleDeleteOption = (opt: OptionType) => {
    if (window.confirm(content.confirmTextOption)) {
      set({
        question: deleteQuestionOption(question, opt.id),
      })
      removeHighlightItemsByLocations(opt.locations!, editor!)
      opt.subquestions.forEach((subqItem) =>
        removeHighlightItemsByQuestionId(subqItem.id, editor!)
      )
    }
  }

  return (
    <QuestionCheckboxComponent
      textSelectionIsActive={textSelectionIsActive}
      options={options!}
      currentSelectionId={currentSelectionId}
      values={optionsState}
      deleteOptionDisabled={options.length < 3}
      handleTextChange={handleTextChange}
      handleAddOption={handleAddOption}
      handleTextSelectClick={handleTextSelectClick}
      handleFocusTextInput={handleFocusTextInput}
      handleCheckboxChange={handleCheckboxChange}
      handleDeleteOption={handleDeleteOption}
    />
  )
}

export default CheckboxTypeContainer
