import React, { Fragment } from 'react'

import { useLegalAdviceEvents } from '../../hooks/useLegalAdviceEvents'

import EventsList from 'pages/Authenticated/LegalAdvice/components/EventsList/EventListScheduled'
import no_event_svg from '../../assets/events-empty.svg'
import NoItemsAction from 'components/common/NoItemsAction/NoItemsAction'
import { USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'

const UpcomingEventsPage: React.FC = () => {
  const { upcomingEvents } = useLegalAdviceEvents()

  const handleBeginEvent = (link: string | null): void => {
    if (link) window.open(link)
  }

  const handleCancelEvent = (eventId: string): void => {
    const url = `https://calendly.com/app/scheduled_events/user/me?trigger_cancel=${eventId}&uuid=${eventId}`
    window.open(url)
  }

  // const handleRescheduleEvent = (eventEndpoint: string): void => {
  //   const url = `https://calendly.com/reschedulings/${eventEndpoint}`
  //   window.open(url)
  // }

  return (
    <Fragment>
      {upcomingEvents.length ? (
        <EventsList
          eventItems={upcomingEvents}
          handleBeginEvent={handleBeginEvent}
          handleCancelEvent={handleCancelEvent}
          // handleRescheduleEvent={handleRescheduleEvent}
          userType={USER_TYPE_LAWYER_ADMIN}
        />
      ) : (
        <NoItemsAction
          text='Sie haben noch keine geplanten Beratungstermine'
          image={no_event_svg}
        />
      )}
    </Fragment>
  )
}

export default UpcomingEventsPage
