import { NumberInputFieldsProps } from './QuestionNumber.types'
import React from 'react'
import { RangesContainer, NumberInput } from './QuestionNumber.styles'
import { IRangeState } from './QuestionNumber.container'

interface IField {
  placeholder: string
  key: keyof IRangeState
}

const fields: IField[] = [
  { placeholder: 'Von', key: 'rangeFrom' },
  { placeholder: 'Bis', key: 'rangeTo' },
]

const NumberInputFields: React.FC<NumberInputFieldsProps> = ({
  ranges,
  handleRangeChange,
}) => {
  return (
    <RangesContainer>
      {fields.map((item, i) => (
        <NumberInput
          key={item.key}
          type='number'
          name={item.key}
          onChange={handleRangeChange}
          value={ranges[item.key]}
          InputGroupContainerProps={{ style: { width: '47%' } }}
          placeholder={item.placeholder}
          style={{ marginBottom: 0 }}
          required
        />
      ))}
    </RangesContainer>
  )
}

export default NumberInputFields
