import { styled, Typography } from '@material-ui/core'

export const Wrapper = styled('div')(({ theme }) => ({
  background: '#fff',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 776,
}))

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
}))

export * from '../AccountSettings.styles'
