import Api from './api'

export type TChargeStripeProduct = 'single-template' | 'subscription'

export interface IConnectPurchaseApiRequest {
  priceId: string
  connectedAccountId: string
  slug: string
}

export interface IChargeMetadata {
  templateID?: string
  type?: 'single_template'
  userId?: string
  slug?: string
}

export interface IChargeStripeRequestApi {
  metadata: IChargeMetadata
  priceId: string
  token?: string
}

class PaymentsApi extends Api {
  public constructor() {
    super('/financials')
    this.getPaymentMethods = this.getPaymentMethods.bind(this)
    this.charge = this.charge.bind(this)
    this.addPaymentMethod = this.addPaymentMethod.bind(this)
    this.getStripeClientSecret = this.getStripeClientSecret.bind(this)
  }

  public async getPaymentMethods() {
    return await this.api().get('/paymentMethods')
  }

  public async getStripePortalUrl(data: { returnUrl: string }): Promise<string> {
    const res = await this.api().post('/portal', data)
    return res?.data?.data?.url
  }

  public async charge(data: IChargeStripeRequestApi, endpoint: TChargeStripeProduct) {
    return await this.api().post(`/purchase/${endpoint}`, data)
  }

  public async addPaymentMethod(paymentMethodId: string) {
    return await this.api().post('/paymentMethods/attach', { paymentMethodId })
  }

  public async getStripeClientSecret() {
    return await this.api().get('/paymentMethods/iban/secret')
  }

  public connectPurchase = async (
    data: IConnectPurchaseApiRequest,
    where: 'book-lawyer'
  ): Promise<{ clientSecret: string }> => {
    const res = await this.api().post(`/purchase/connected/${where}`, data)
    return res.data.data
  }
}

const paymentApi = new PaymentsApi()

export default paymentApi
