import React from 'react'
import { CustomButton } from 'components/common'
import { ActionsContainer, ButtonsContainer } from './Footer.styles'
import { DownloadButton } from './DownloadButton.container'

interface Props {
  onEdit: () => void
  onShare?: () => void | null | boolean
  disabled: {
    edit?: boolean
    download?: boolean
    share?: boolean
  }
  isLoading: boolean
}

const FooterComponent: React.FC<Props> = ({ onEdit, onShare, disabled, isLoading }) => {
  return (
    <ActionsContainer>
      <ButtonsContainer>
        <CustomButton
          btntype='primary'
          autoFocus
          onClick={onEdit}
          width={160}
          disabled={isLoading || disabled.edit}
        >
          Bearbeiten
        </CustomButton>
        <DownloadButton />
        {onShare && (
          <CustomButton
            btntype='primary-outlined'
            onClick={onShare}
            width={120}
            disabled={isLoading || disabled.share}
          >
            Teilen
          </CustomButton>
        )}
      </ButtonsContainer>
    </ActionsContainer>
  )
}

export default FooterComponent
