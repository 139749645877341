import { DocumentSettingsItem } from 'types'

import React, { useState } from 'react'

import { DocumentSettingsPageHeader } from 'components/documentSettings/DocumentSettingsPageHeader/DocumentSettingsPageHeader'
import { SettingsCardItemList } from 'components/documentSettings/SettingsCardItem/SettingsCardItemList'
import { CreateDocumentHeaderModal } from 'components/documentSettings/Modals/CreateDocumentHeaderModal'
import { StatusModal } from 'components/common'
import { SettingsContent } from './DocumentSettings.styles'
import no_headers_svg from 'assets/illustrations/no-headers.svg'
import { POPUP_CONFIRM_DELETE_HEADER } from 'constants/confirmation-popups'

// const selector = (state: State) => {
//   const {
//     docs: {
//       settings: { headers },
//     },
//     meta,
//   } = state
//   return {
//     headers,
//     hasBeenFetched: Boolean(meta?.GET_DOCUMENT_SETTINGS?.hasBeenFetched),
//     isLoading:
//       Boolean(meta?.GET_DOCUMENT_SETTINGS?.isLoading) ||
//       Boolean(meta?.CREATE_DOCUMENT_FOOTER_HEADER?.isLoading) ||
//       Boolean(meta?.UPDATE_DOCUMENT_FOOTER_HEADER?.isLoading),
//   }
// }

const HeaderSettings: React.FC = () => {
  // const { headers, isLoading } = useSelector(selector)
  const headers: any = {}
  // const [compare, setCompare] = useState(JSON.stringify(headers))
  const [open, setOpen] = useState(false)
  // const [saving, setSaving] = useState(false)
  const [headerToEdit, setHeaderToEdit] = useState<DocumentSettingsItem | null>(null)
  const [deleteId, setDeleteId] = useState<null | string>(null)

  const handleDelete = (id: string): void => {
    setDeleteId(id)
  }

  const handleConfirmDelete = (): void => {
    if (!deleteId) return
    // dispatch(A.deleteDocumentFooterHeaderRequest(deleteId, 'header'))
    setDeleteId(null)
  }

  const handleEdit = (id: string): void => {
    const theHeader = headers.find((h: any) => h.id === id)
    if (theHeader) {
      setHeaderToEdit(theHeader)
      setOpen(true)
    }
  }

  const handleAddHeader = (base64: string, imageHeight: number): void => {
    // const data: CreateDocumentFooterHeaderRequestProps = {
    //   startAt: 0,
    //   endAt: null,
    //   text: '',
    //   imageData: base64,
    //   imageHeight: `${imageHeight}px`,
    //   imageWidth: 'auto',
    // }
    // dispatch(A.createDocumentFooterHeaderRequest(data, 'header'))
    // setSaving(true)
  }

  const handleEditHeader = (base64: string | null, imageHeight: number): void => {
    if (!headerToEdit) return
    // const data: UpdateDocumentFooterHeaderRequestProps = {
    //   id: headerToEdit.id,
    // }
    // if (base64) data.imageData = base64
    // if (imageHeight) data.imageHeight = `${imageHeight}px`
    // dispatch(A.updateDocumentFooterHeaderRequest(data, 'header'))
    // setSaving(true)
  }

  // const reset = () => {
  //   setSaving(false)
  //   setOpen(false)
  //   setHeaderToEdit(null)
  // }

  // useEffect(() => {
  //   if (saving && !isLoading) {
  //     const newHeaders = JSON.stringify(headers)
  //     if (newHeaders !== compare) {
  //       setCompare(newHeaders)
  //       reset()
  //     }
  //   }
  // }, [headers, compare, saving, isLoading])

  const closeCreateHeader = (): void => {
    setOpen(false)
    setHeaderToEdit(null)
  }

  return (
    <SettingsContent>
      <DocumentSettingsPageHeader
        title='Verfügbare Kopfzeilen'
        actionText='Kopfzeile hinzufügen'
        handleActionClick={() => setOpen(true)}
      />
      <SettingsCardItemList
        documentSettingsItems={headers}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        noItemsActionProps={{
          image: no_headers_svg,
          text: 'Sie haben keine Kopfzeilen erstellt.',
          actionText: 'Erste Kopfzeile erstellen',
          handleActionClick: () => setOpen(true),
        }}
      />
      {open && (
        <CreateDocumentHeaderModal
          open={open}
          headerToEdit={headerToEdit}
          // isLoading={isLoading || saving}
          handleClose={closeCreateHeader}
          handleAddHeader={handleAddHeader}
          handleEditHeader={handleEditHeader}
        />
      )}
      <StatusModal
        open={Boolean(deleteId)}
        handlePrimaryClick={handleConfirmDelete}
        handleClose={() => setDeleteId(null)}
        {...POPUP_CONFIRM_DELETE_HEADER}
      />
    </SettingsContent>
  )
}

export default HeaderSettings
