import React from 'react'
import { Heading } from '../common-styles'
import { Form } from './LoginForm.styles'
import { TextInputGroup, CustomButton, TextWithUrl } from 'components/common'
import { ILoginFormState } from './LoginForm.container'

interface Props {
  isLoading: boolean
  values: ILoginFormState
  allowClientRegister: boolean
  handleChange: React.ChangeEventHandler
  handleSubmit: React.FormEventHandler
}

const LoginForm: React.FC<Props> = ({
  isLoading,
  allowClientRegister,
  values,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Heading variant='h3'>Login ({process.env.REACT_APP_NODE_ENV})</Heading>
      <TextInputGroup
        type='email'
        label='Ihre E-Mail-Adresse'
        name='email'
        value={values.email}
        placeholder='z.B. marina@meyer.ch'
        onChange={handleChange}
        required
      />

      <TextInputGroup
        type='password'
        label='Passwort'
        name='password'
        value={values.password}
        onChange={handleChange}
        labelUrl={{ text: 'Passwort vergessen?', url: '/auth/passwort-wiederherstellen' }}
        required
      />

      <CustomButton
        type='submit'
        isLoading={isLoading}
        btntype='primary'
        size='large'
        width='100%'
        disabled={isLoading}
      >
        {!isLoading ? 'Login' : 'Einen Moment bitte...'}
      </CustomButton>
      {allowClientRegister && (
        <TextWithUrl
          text='Haben Sie noch kein Konto? '
          url='/auth/register'
          urlText='Registrierung'
        />
      )}
    </Form>
  )
}

export default LoginForm
