import { useQuery, UseQueryOptions } from 'react-query'
import { useRootFolder } from 'hooks/useRootFolder'
import { useFolderKeys } from './useFolderKeys'

import { FileManager } from 'utils'

import FoldersApi from 'api/folders.api'
import { FolderItem, FolderItemSimple } from 'types'
import { AxiosError } from 'axios'
import { useRootCategoryFolder } from 'hooks/useRootCategoryFolder'
import { useCurrentFolder } from 'hooks/useCurrentFolder'

export const useFetchFolders = (
  where: 'templates' | 'docs',
  currentFolder: FolderItem,
  options: UseQueryOptions<FolderItemSimple[], AxiosError<any>, FolderItemSimple[]> = {}
) => {
  const { rootFolder, setRootFolder } = useRootFolder(where)
  const { setCurrentFolder } = useCurrentFolder(where)
  const sharedFolder = useRootCategoryFolder(where, 'shared')
  const [many] = useFolderKeys(where)
  const keys = [many, currentFolder.id]
  let query = useQuery(keys, () => FoldersApi.get(where, currentFolder), {
    onSuccess: folders => {
      const fileManager = new FileManager(rootFolder, currentFolder)
      const [root] = fileManager.pushFoldersFromDB(folders)
      setRootFolder(root)
    },
    onError: err => {
      if (err?.response?.data?.status === 401 && currentFolder.type === 'shared') {
        setCurrentFolder(sharedFolder)
      }
    },
    refetchOnWindowFocus: false,
    ...options,
  })
  return query
}
