import React, { Dispatch, SetStateAction } from 'react'
import StandardModal from '../../../common/Modals/StandardModal/StandardModal'
import { Container } from './CreateFolder.styles'
import TextInputGroup from 'components/common/TextInputGroup/TextInputGroup'
import theme from 'config/themes/theme'

import { StandardModalProps } from '../../../common/Modals/StandardModal/StandardModal.types'

interface Props extends StandardModalProps {
  folderName: string
  setFolderName: Dispatch<SetStateAction<string>>
}

const CreateFolderModalComponent: React.FC<Props> = ({
  folderName,
  setFolderName,
  ...standardModalProps
}) => {
  return (
    <StandardModal
      title='Neuer Ordner'
      {...standardModalProps}
      RootModalProps={{
        style: { marginBottom: '30vh' },
        backdropStyle: { background: theme.colorz.secondary[100], opacity: 0.8 },
      }}
    >
      <Container>
        <TextInputGroup
          name='folderName'
          value={folderName}
          onChange={e => setFolderName(e.target.value)}
          onFocus={e => e.target.select()}
        />
      </Container>
    </StandardModal>
  )
}

export default CreateFolderModalComponent
