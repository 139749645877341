import { FormStepOneClientProps } from '../../components/ProfileInfo.types'
import React, { Fragment } from 'react'
import {
  profileInfoStepOneTextFields as fields,
  profileInfoStepOneSelectFields as selectFields,
} from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoClient/content'
import { TextInputGroup, SelectGroup } from 'components/common'
import { selectInputElStyle, Row, Item, Label, Text } from '../../components/ProfileInfo.styles'
import { USER_TYPE_CLIENT_ADMIN } from 'constants/user-types'

const ProfileInfoClientStepOne: React.FC<FormStepOneClientProps> = ({
  handleChange,
  handleSelectChange,
  values,
  userType,
  editingDisabled,
}) => {
  const renderTextFields = () =>
    fields.map((field, i) => (
      <Item key={field.name + i}>
        <Label variant='body2'>{field.label}</Label>
        {!editingDisabled || USER_TYPE_CLIENT_ADMIN ? (
          <TextInputGroup
            value={values[field.name]}
            onChange={handleChange}
            InputGroupContainerProps={{ style: { marginBottom: 0 } }}
            customvariant='light'
            {...field}
            label={undefined}
          />
        ) : (
          <Text>{values[field.name]}</Text>
        )}
      </Item>
    ))

  const renderSelectFields = () =>
    selectFields.map(field => (
      <Item key={field.name}>
        <Label variant='body2'>{field.label}</Label>
        {userType === USER_TYPE_CLIENT_ADMIN ? (
          <SelectGroup
            value={values[field.name]}
            handleChange={handleSelectChange}
            InputElProps={{ style: selectInputElStyle }}
            {...field}
            label={undefined}
          />
        ) : (
          <Text>{values[field.name]}</Text>
        )}
      </Item>
    ))

  return (
    <Fragment>
      <Row>{renderTextFields()}</Row>
      <Row>{renderSelectFields()}</Row>
    </Fragment>
  )
}

export default ProfileInfoClientStepOne
