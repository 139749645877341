import { styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

export const Label = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  letterSpacing: 0.42,
  fontWeight: 500,
  fontSize: 14,
  color: theme.colorz.secondary[200],
}))
