import React from 'react'

import { FeaturesLayout } from 'layout'

import * as content from '../constants/content'

interface Props {
  step: number
  heading: string
  onClose: () => void
}

const Layout: React.FC<Props> = ({ step, heading, children, onClose }) => {
  return (
    <FeaturesLayout
      currentStep={step}
      onBoardingSteps={content.onBoardingSteps}
      steps={content.steps}
      handleClose={onClose}
      onBoardingHeading={content.onBoardingHeading}
      heading={heading}
      isLoading={false}
    >
      {children}
    </FeaturesLayout>
  )
}

export default Layout
