import { FolderItem, ILazyLoadParams, ITemplate, ITemplateExtended } from 'types'
import { useInfiniteQuery, UseInfiniteQueryOptions, useQueryClient } from 'react-query'
import TemplatesApi from 'api/templates.api'
import { queryKeys } from 'queries/queryKeys'
import { AxiosError } from 'axios'

const getInitialLimit = () => {
  const docHeight = 364
  const docWidht = 256 + 32
  const width = window.innerWidth - 360
  const height = window.innerHeight - 150

  const rows = Math.ceil(height / docHeight)
  const rowItems = Math.floor(width / docWidht)
  return rows * rowItems
}

export const useLoadTemplates = (
  currentFolder: FolderItem,
  options: UseInfiniteQueryOptions<
    ITemplateExtended[],
    AxiosError,
    ITemplateExtended[]
  > = {}
) => {
  const { type, id: folderId } = currentFolder
  const queryClient = useQueryClient()

  const keys = [queryKeys.TEMPLATES, { type, folderId }]
  const data = queryClient.getQueryData<ITemplate[]>(keys)
  const search: ILazyLoadParams = {}

  if (data?.length) search.lastItemFetched = data[data.length - 1].id
  const limit = !data?.length ? getInitialLimit() : 12

  const query = useInfiniteQuery(
    keys,
    ({ pageParam }) =>
      TemplatesApi.get({
        type,
        limit,
        folderId: folderId === type ? '' : folderId,
        lastItemFetched: pageParam,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return undefined
        if (lastPage?.length < limit) return undefined
        const id = lastPage[lastPage.length - 1]?.id
        return id
      },
      refetchOnWindowFocus: false,
      ...options,
    }
  )

  return query
}
