import { IAnswer, AnswerTypes, TQuestionTypes, Question } from 'types'
import questionTypesMap from 'constants/question-types'
import answerTypesMap from 'constants/answer-types'
import { QuestionFlattedMap } from 'components/features/documentGeneration/views/Questionnaire/services/createQuestionMap'
const {
  RADIO_LINK,
  RADIO_REPLACEMENT,
  CHECKBOX,
  TEXT_BOX,
  NUMBER,
  DATE,
} = questionTypesMap

export interface IQuestionnaireQuestionsToState {
  [questionId: string]: IAnswer
}

export const questionToAnswerType = (questionType: TQuestionTypes): AnswerTypes => {
  switch (questionType) {
    case TEXT_BOX:
      return answerTypesMap.TEXT_BOX
    case NUMBER:
      return answerTypesMap.NUMBER
    case DATE:
      return answerTypesMap.DATE
    case CHECKBOX:
      return answerTypesMap.CHECKBOX
    case RADIO_LINK:
      return answerTypesMap.RADIO_LINK
    case RADIO_REPLACEMENT:
      return answerTypesMap.RADIO_REPLACEMENT
    default:
      return answerTypesMap.TEXT_BOX
  }
}

export const questionnaireQuestionsToState = (
  questions: Question[],
  answers: IAnswer[]
): IQuestionnaireQuestionsToState => {
  const obj: IQuestionnaireQuestionsToState = {}

  for (let q of questions) {
    const hasOptions: boolean = [RADIO_LINK, RADIO_REPLACEMENT, CHECKBOX].includes(q.type)
    const isRadio = [RADIO_LINK, RADIO_REPLACEMENT].includes(q.type)
    const optVal = isRadio && q.options ? [q.options[0].id] : []

    const questionAnswer: IAnswer | undefined = answers.find(
      answ => answ.questionId === q.id
    )

    if (questionAnswer) {
      const { questionId, type, value } = questionAnswer
      obj[questionId] = {
        questionId,
        type,
        value,
      }
    } else {
      obj[q.id] = {
        questionId: q.id,
        type: questionToAnswerType(q.type),
        value: !hasOptions ? '' : optVal,
      }
    }
  }

  return obj
}

export const questionToAnswerState = (question: Question): IAnswer => {
  const hasOptions: boolean = [RADIO_LINK, RADIO_REPLACEMENT, CHECKBOX].includes(
    question.type
  )
  const isRadio = [RADIO_LINK, RADIO_REPLACEMENT].includes(question.type)
  const optVal = isRadio && question.options ? [question.options[0].id] : []

  return {
    questionId: question.id,
    type: questionToAnswerType(question.type),
    value: !hasOptions ? '' : optVal,
  }
}

export const questionnaireToStateV2 = (
  questionsMap: QuestionFlattedMap,
  answers: IAnswer[]
): IQuestionnaireQuestionsToState => {
  const stateObj: IQuestionnaireQuestionsToState = {}
  questionsMap.order.forEach(qid => {
    const theQuestion = questionsMap.questions[qid]
    let theAnswer = answers.find(a => a.questionId === qid)
    if (!theAnswer && !theQuestion.parentId) {
      theAnswer = questionToAnswerState(theQuestion)
    }
    if (theAnswer) stateObj[qid] = theAnswer
  })

  return stateObj
}
