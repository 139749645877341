import { loadStripe, Stripe, StripeConstructorOptions } from '@stripe/stripe-js'
import React, { useRef } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { useMasterData } from 'queries/app/useMasterdata'
import { IStripeCardData } from 'types'

export interface StripeContainerComponentProps {
  handleBackClick?: () => void
  handleStripeSubmit: (stripe: Stripe, cardData: IStripeCardData) => void
  amount: number
  heading?: string
  descriptionText?: string
  charging?: boolean
}

interface StripeWrapperProps extends StripeContainerComponentProps {
  options?: StripeConstructorOptions
  [key: string]: any
}

interface Props {
  options?: StripeConstructorOptions
}

export const withStripe = (Component: React.FC<Props>) => {
  const Wrapper: React.FC<Props> = ({ options, ...props }) => {
    const test_mode = useMasterData()?.stripe.test_mode
    const stripeKey = test_mode
      ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST!
      : process.env.REACT_APP_STRIPE_PUBLIC_KEY!
    const stripePromise = useRef(loadStripe(stripeKey, options))

    return (
      <Elements stripe={stripePromise.current}>
        <Component {...props} />
      </Elements>
    )
  }
  return Wrapper
}

const withStripeCard = (Component: React.FC<StripeWrapperProps>) => {
  const Wrapper: React.FC<StripeWrapperProps> = ({ options, ...props }) => {
    const test_mode = useMasterData()?.stripe.test_mode
    const stripeKey = test_mode
      ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST!
      : process.env.REACT_APP_STRIPE_PUBLIC_KEY!
    const stripePromise = useRef(loadStripe(stripeKey, options))

    return (
      <Elements stripe={stripePromise.current}>
        <Component {...props} />
      </Elements>
    )
  }
  return Wrapper
}

export default withStripeCard
