import { useState } from 'react'

import { useToast } from 'hooks/useToast'
import { useMasterData } from 'queries/app/useMasterdata'

import UserApi from 'api/user.api'
import FinancialsApi from 'api/financials.api'
import { useBookLawyer } from './BookLawyerProvider'
import { trackIntercomEvent } from 'components/helpers/intercom/services/trackIntercomEvent'

export const useConnectedAccount = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { set } = useBookLawyer()
  const toast = useToast()
  const priceId = useMasterData()?.prices.book_a_lawyer!
  const [data, setData] = useState<
    { clientSecret: string; connectedAccountId: string } | undefined
  >()

  const fetch = async (code: string) => {
    setIsLoading(true)
    try {
      const calendlyEvent = await UserApi.getCalendlyEventByToken(code)
      const email = calendlyEvent?.lawyerEmail
      const lawyer = await UserApi.getUserByEmail(email)
      const connectedAccountId = lawyer.financialDetails.customerId
      const payload = {
        priceId,
        connectedAccountId,
        slug: code,
      }
      const { clientSecret } = await FinancialsApi.connectPurchase(payload, 'book-lawyer')
      setData({ connectedAccountId, clientSecret })
      set({ step: 2, clientSecret, connectedAccountId })
      trackIntercomEvent('lawyer-booked')
    } catch (err) {
      toast('error', 'default')
    } finally {
      setIsLoading(false)
    }
  }

  return { fetch, isLoading, data }
}
