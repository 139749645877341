import { Box, BoxProps, styled, Theme, Typography } from '@material-ui/core'

interface ContainerEl extends BoxProps {
  theme: Theme
  ref: React.RefObject<HTMLDivElement>
}

export const Container = styled(Box)(({ theme }: ContainerEl) => ({
  display: 'flex',
  height: 110,
  width: '90%',
  maxWidth: 920,
  borderRadius: 4,
  borderBottom: '2px solid' + theme.colorz.brand.main,
  position: 'relative',
  backgroundColor: '#fff',
  '&:not(last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 96,
  height: 110,
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  padding: theme.spacing(0, 4, 0, 1),
}))

export const InfoWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

export const StrongText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: 0.45,
  marginBottom: theme.spacing(0.25),
  color: theme.colorz.secondary.main,
}))

export const LightText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0.2,
  color: theme.colorz.secondary[300],
}))

export const BoxesWrapper = styled(Box)({
  display: 'flex',
  marginLeft: 'auto',
})

export const DataBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 128,
  height: 78,
  paddingLeft: theme.spacing(2),
  borderRadius: 4,
  backgroundColor: theme.colorz.brand.light,
  border: '1px solid' + theme.colorz.brand.main,
  '&:first-child': {
    marginRight: theme.spacing(3),
  },
}))

export const iconStyles: React.CSSProperties = {
  position: 'absolute',
  top: 8,
  right: 8,
  cursor: 'pointer',
}
