import { Box, styled, Typography, Theme, BoxProps } from '@material-ui/core'

interface T extends BoxProps {
  theme: Theme
  active: number
}

export const Container = styled(Box)({
  borderTop: '1px solid rgba(102, 115, 141, 0.27)',
  width: '100%',
  paddingTop: 40,
})

export const InnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
  paddingBottom: 2,
  marginBottom: 8,
  paddingLeft: 3,
}))

export const Text = styled(Typography)(({ theme, active }: T) => ({
  fontSize: 16,
  color: theme.colorz.brand.main,
  fontWeight: 700,
  letterSpacing: 0.4,
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: active ? theme.colorz.primary.main : 'transparent',
}))

export const Icon = styled('img')(({ theme }) => ({
  marginRight: 12,
}))
