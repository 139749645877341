import { IQuestionLocation } from 'types'
import { unwrapHtmlElement } from './unwrapHtmlElement'
import { Editor } from 'tinymce'

export const removeHighlightItemByLocationId = (
  locationId: string,
  editor: Editor
) => {
  if (!editor) return
  const spanEls = editor
    .getBody()
    .querySelectorAll(`#${CSS.escape(locationId)}`)
  spanEls.forEach(unwrapHtmlElement)
}

type ArgType = IQuestionLocation | string

export const removeHighlightItemsByLocations = (
  locations: ArgType[],
  editor: Editor
): void =>
  locations.forEach((locationItem) => {
    const id =
      typeof locationItem === 'string'
        ? locationItem
        : (locationItem.id as string)
    removeHighlightItemByLocationId(id, editor)
  })
