import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentFolderDocuments, setCurrentFolderTemplates } from 'redux/actions'
import { FolderItem, FolderItemSimple, State } from 'types'
import { FileManager } from 'utils'
import { useRootFolder } from './useRootFolder'

export const useCurrentFolder = (where: 'templates' | 'docs') => {
  const dispatch = useDispatch()
  const { rootFolder, setRootFolder } = useRootFolder(where)
  const currentFolder = useSelector((state: State) => state[where].currentFolder)

  const setCurrentFolder = useCallback(
    (folder: FolderItem) => {
      const fn =
        where === 'templates' ? setCurrentFolderTemplates : setCurrentFolderDocuments
      dispatch(fn(folder))
    },
    [dispatch, where]
  )

  const onEditFolder = (updatedFolder: FolderItemSimple) => {
    const fileManger = new FileManager(rootFolder, currentFolder)
    fileManger.editFolder(fileManger.transformFolderFromDB(updatedFolder))
    setRootFolder(fileManger.data)
    setCurrentFolder(fileManger.current)
  }

  return { currentFolder, setCurrentFolder, onEditFolder }
}
