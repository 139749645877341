import { IConfirmationPopupContent } from 'types'
import React from 'react'
import { useHistory } from 'react-router'

import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useTeamMembers } from 'pages/Authenticated/Team/hooks/useTeamMembers'
import { useTeamMemberRemove } from 'pages/Authenticated/Team/hooks/useTeamMemberRemove'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'

import TeamMembersListComponent from './TeamMembersList.component'

const POPUP_CONFIRM_DELETE_TEAM_MEMBER: IConfirmationPopupContent = {
  heading: 'Sind Sie sicher, dass Sie dieses Mitglied aus Ihrem Team löschen möchten?',
  status: 'warning',
  primaryButtonText: 'Ja, endgültig löschen',
  secondaryButtonText: 'Nein, nicht löschen',
}

const TeamMembersList: React.FC = () => {
  const teamMembers = useTeamMembers()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { remove } = useTeamMemberRemove()
  const { check } = useSubscriptionCheck()
  const { push, location } = useHistory()

  const onDelete = (id: string) => {
    if (!check()) return
    popup({
      ...POPUP_CONFIRM_DELETE_TEAM_MEMBER,
      onConfirm: () => {
        remove(id, {
          onSuccess: () => {
            close()
          },
          onSettled: () => {
            setIsLoading(false)
          },
        })
      },
      onCancel: close,
    })
  }

  const onAddTeamMember = () => {
    if (!check({ isFree: true, isStartup: true })) return
    push(`${location.pathname}?action=add`)
  }

  return (
    <TeamMembersListComponent
      teamMembers={teamMembers}
      onDelete={onDelete}
      onAddTeamMember={onAddTeamMember}
    />
  )
}

export default TeamMembersList
