import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'

import { useUser } from 'queries/user/useUser'
import { useToast } from 'hooks/useToast'
import { useMasterData } from 'queries/app/useMasterdata'
import { useModal } from 'hooks/useModal'

import { documentsRoutes } from 'constants/routes'
import * as Ui from 'constants/ui-elements'

export const useWatchSubscriptions = () => {
  const appLoaded = !!useMasterData()
  const user = useUser()
  const { location, replace } = useHistory()
  const toast = useToast()
  const { openModal } = useModal()

  const [expiredSeen, setExpiredSeen] = useState(false)
  const [newSubscriptionSeen, setNewSubscriptionSeen] = useState(false)

  const subscriptionStatus = user?.subscription.status
  const newSubscription = !!user?.subscription?.newSubscription
  // const expiresAt = user?.subscription.subscriptionTrialEndAt
  const url = new URL(window.location.href)
  const subscription = url.searchParams.get('subscription')
  // const sessionId = url.searchParams.get('session_id')
  const isAktenschrank = location.pathname === documentsRoutes.main

  const onSubscriptionFailure = useCallback(() => {
    toast('error', 'Es ist ein Fehler aufgetreten!')
    replace(documentsRoutes.main)
  }, [toast, replace])

  const onSubscriptionExpired = useCallback(() => {
    if (user && subscriptionStatus === 'not_paid' && !newSubscription) {
      setExpiredSeen(true)
      openModal(Ui.UI_TRIAL_EXPIRED_MODAL)
    }
  }, [openModal, subscriptionStatus, newSubscription, user])

  const onSubscriptionSuccess = useCallback(() => {
    openModal(Ui.UI_SUPSCRIPTION_UPGRADE_SUCCESS_MODAL)
    replace(documentsRoutes.main)
  }, [openModal, replace])

  const onSubscriptionNew = useCallback(() => {
    if (user?.firstName && newSubscription) {
      openModal(Ui.UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NEW_SUBSCRIPTION)
      setNewSubscriptionSeen(true)
    }
  }, [openModal, newSubscription, user])

  useEffect(() => {
    if (!expiredSeen) onSubscriptionExpired()
    if (!newSubscriptionSeen) onSubscriptionNew()
  })

  useEffect(() => {
    if (!appLoaded || !user) return
    if (subscription === 'success') onSubscriptionSuccess()
    if (subscription === 'failure' && isAktenschrank) onSubscriptionFailure()
  }, [
    subscription,
    isAktenschrank,
    appLoaded,
    user,
    onSubscriptionFailure,
    openModal,
    onSubscriptionSuccess,
  ])
}
