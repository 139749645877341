import { Loader } from 'components/common'
import { useLoadReminders } from 'queries/reminders'
import React from 'react'
import { RemindersList } from './components/RemindersList/RemindersList.container'

const Reminders: React.FC = () => {
  const { data } = useLoadReminders()
  if (!data) return <Loader />
  return <RemindersList />
}

export default Reminders
