import { QuestionTypesProps } from './QuestionTypes.types'
import React from 'react'
import { Container, Typography, AnswerTypesContainer } from './QuestionTypes.styles'
import TypeBox from '../components/TypeBox/TypeBox'
import questionTypes from 'constants/question-types'
import { forms as content } from 'pages/Authenticated/Template/constants/content'

const { RADIO_REPLACEMENT, RADIO_LINK, CHECKBOX, TEXT_BOX, DATE, NUMBER } = questionTypes

const QuestionTypesComponent: React.FC<QuestionTypesProps> = ({
  selectedQuestionType,
  handleSelectQuestionType,
}) => {
  return (
    <Container>
      <Typography variant='h5'>{content.questionTypesHeading}</Typography>
      <AnswerTypesContainer>
        <TypeBox
          questionType={TEXT_BOX}
          selected={selectedQuestionType}
          handleTypeBoxClick={e => handleSelectQuestionType(TEXT_BOX)}
        />
        <TypeBox
          questionType={
            selectedQuestionType === RADIO_REPLACEMENT ? RADIO_REPLACEMENT : RADIO_LINK
          }
          selected={selectedQuestionType}
          handleTypeBoxClick={e =>
            handleSelectQuestionType(
              selectedQuestionType === RADIO_REPLACEMENT ? RADIO_REPLACEMENT : RADIO_LINK
            )
          }
        />
        <TypeBox
          questionType={NUMBER}
          selected={selectedQuestionType}
          handleTypeBoxClick={e => handleSelectQuestionType(NUMBER)}
        />
        <TypeBox
          questionType={CHECKBOX}
          selected={selectedQuestionType}
          handleTypeBoxClick={e => handleSelectQuestionType(CHECKBOX)}
        />
        <TypeBox
          questionType={DATE}
          selected={selectedQuestionType}
          handleTypeBoxClick={e => handleSelectQuestionType(DATE)}
        />
      </AnswerTypesContainer>
    </Container>
  )
}

export default QuestionTypesComponent
