import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { EmailAcceptTeamMember } from './EmailAcceptTeamMember'
import EmailVerify from './EmailVerify'
import { EmailSharedFileRedirect } from './EmailSharedFileRedirect'
import { EmailSharedFolderRedirect } from './EmailSharedFolderRedirect'
import { EmailSubscriptionRedirect } from './EmailSubscriptionRedirect'
import { EmailReminderRedirect } from './EmailReminderRedirect'

const baseUrl = '/redirect'

export const Redirects: React.FC = () => {
  return (
    <Switch>
      <Route
        path={`${baseUrl}/reminder/:type/:id`}
        component={EmailReminderRedirect}
      />
      <Route
        path={`${baseUrl}/subscription-plans`}
        component={EmailSubscriptionRedirect}
      />
      <Route path={`${baseUrl}/add-team-member`} component={EmailAcceptTeamMember} />
      <Route path={`${baseUrl}/email-verified`} component={EmailVerify} />
      <Route
        path={`${baseUrl}/folder/:type/shared/:id`}
        exact
        component={EmailSharedFolderRedirect}
      />
      <Route
        path={`${baseUrl}/file/:type/:id`}
        exact
        component={EmailSharedFileRedirect}
      />
    </Switch>
  )
}
