import { styled } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

export const QuestionsContainer = styled(Box)({
  minWidth: '100%',
})

export const QuestionContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
  borderRadius: 2,
  position: 'relative',
  overflowX: 'visible',
  flexShrink: 0,
})

export const QuestionNumber = styled(Box)({
  minWidth: 35,
})

export const QuestionNumberWithSortContainer = styled(Box)(({ theme }) => ({
  minWidth: 35,
  maxWidth: 35,
  display: 'flex',
  flexDirection: 'column',
}))

export const QuestionText = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colorz.brand.main,
  padding: theme.spacing(2),
  borderRadius: 2,
  flexGrow: 1,
}))

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
}))

export const SubquestionContainer = styled(Box)({
  marginLeft: 'auto',
})

export const NumberInput = styled('input')(({ theme }) => ({
  border: 'none',
  width: '100%',
  outline: 'none',
  color: theme.colorz.secondary.main,
  margin: theme.spacing(1, 0),
  fontFamily: 'inherit',
  fontSize: 'inherit',
  paddingLeft: 1.5,
}))
