import { styled, Typography } from '@material-ui/core'
import React from 'react'
import SubscriptionPlansModal from 'components/subscriptions/components/SubscriptionPlansModal/SubscriptionPlansModal'
import PlanItems from './PlanItems/PlanItems'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textDecoration: 'underline',
}))

const SelectPlan: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState(false)
  return (
    <Container>
      <PlanItems />
      <Text
        variant='body1'
        onClick={() => setModalOpen(true)}
        style={{ marginTop: 40, cursor: 'pointer' }}
      >
        Pläne vergleichen
      </Text>
      <SubscriptionPlansModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Container>
  )
}

export default SelectPlan
