import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

interface PrivateRouteHOCProps extends RouteProps {
  isAuthenticated: boolean
  isAllowed?: boolean
  component: React.FC<any> | React.ComponentClass<any, any>
}

const PrivateRoute: React.FC<PrivateRouteHOCProps> = ({
  component: Component,
  isAuthenticated,
  isAllowed = true,
  ...rest
}) => {
  let redirectPath = '/auth/login'
  if (!isAllowed) redirectPath = '/'

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={props =>
        isAuthenticated && isAllowed ? (
          // <Component {...props} />
          React.createElement(Component, props)
        ) : (
          <Redirect to={redirectPath} />
        )
      }
    />
  )
}

export default PrivateRoute
