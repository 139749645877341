import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useUser } from 'queries/user/useUser'
import { useQueryClient } from 'react-query'
import { useToast } from 'hooks/useToast'

import TeamApi from 'api/team.api'
import UserApi from 'api/user.api'
import { Loader } from 'components/common'
import { queryKeys } from 'queries'
import { documentsRoutes } from 'constants/routes'

export const EmailAcceptTeamMember: React.FC = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const user = useUser()
  const { location, replace } = useHistory()
  const { pathname, search } = location

  const acceptIntive = useCallback(async () => {
    const params = new URLSearchParams(search)
    const code = params.get('code')
    const email = params.get('email')

    if (!user) {
      const encodedUrl = encodeURIComponent(pathname + search)
      return replace(`/auth/login?redirect=${encodedUrl}`)
    }

    if (code && email && user) {
      try {
        await TeamApi.acceptInviteExisting(code, email)
        const user = await UserApi.getMe()
        queryClient.setQueryData(queryKeys.USER, user)
        toast('success', 'Sie wurden erfolgreich zum Team hinzugefügt')
      } catch (err) {
        toast('error', 'default')
      } finally {
        replace(documentsRoutes.main)
      }
    }
  }, [user, pathname, search, queryClient, replace, toast])

  useEffect(() => {
    acceptIntive()
  }, [acceptIntive])

  return <Loader />
}
