import { Box, Link, styled } from '@material-ui/core'

export const TopContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
})

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  maxHeight: '450px',
})

export const Btn = styled(Link) ({
  padding: '10px 40px',
  color: 'white',
  fontSize: '16px',
  fontWeight: 700,
  backgroundColor: 'rgb(102 153 204)',
  '&:hover': {
    textDecoration: 'none',
  },
  '&:visited': {
    color: 'white',
  },
})

