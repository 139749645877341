import { SettingsUploadImageComponentProps } from './ProfileImageModal.types'
import React, { Fragment, useRef } from 'react'
import ProfileImage from 'components/user/ProfileImage/ProfileImage'
import { StrongText, Text } from './ProfileImageModal.styles'
import { CustomButton } from 'components/common'

const SettingsUploadImage: React.FC<SettingsUploadImageComponentProps> = ({
  firstName,
  lastName,
  imageUrl,
  setFile,
}) => {
  const inputElRef = useRef<HTMLInputElement>(null)

  const handleClick = (): void => {
    if (!inputElRef.current) return
    inputElRef.current.click()
  }

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return
    setFile(e.target.files[0])
  }

  return (
    <Fragment>
      <ProfileImage
        imageUrl={imageUrl}
        firstName={firstName}
        lastName={lastName}
        size={126}
        style={{ marginBottom: 24 }}
      />
      <StrongText>Bitte laden Sie Ihr Profilbild hoch</StrongText>
      <Text>
        Das beste Ergebnis erzielen Sie, wenn Sie ein quadratisches Bild verwenden
      </Text>
      <input
        accept='image/*'
        id='contained-button-file'
        name='document'
        type='file'
        style={{ display: 'none' }}
        ref={inputElRef}
        onChange={handleSelectFile}
      />
      <CustomButton
        onClick={handleClick}
        size='medium'
        btntype='primary-inverted'
        width={160}
      >
        Foto auswählen
      </CustomButton>
    </Fragment>
  )
}

export default SettingsUploadImage
