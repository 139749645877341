import { styled } from '@material-ui/core/styles'
import { Box, Typography, Theme, BoxProps, TypographyProps } from '@material-ui/core'
import { scrollBarStylesLight } from 'constants/common-styles'

interface CommonTab {
  theme: Theme
  active: number
}

interface ITabItemEl extends BoxProps, CommonTab {}

interface ITabItemText extends TypographyProps, CommonTab {}

export const Container = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minWidth: 480,
  maxWidth: 480,
  height: 'calc(100vh - 112px)',
})

export const TopWrapper = styled(Box)(({ theme }) => ({
  height: 226,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colorz.brand.main,
}))

export const BottomWrapper = styled(Box)(({ theme }: WithRef) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  flex: 1,
}))

export const TabsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(4, 5, 0, 5),
  flexShrink: 0,
}))

interface WithRef {
  theme: Theme
  ref: React.RefObject<HTMLDivElement>
}
export const TextWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 6, 5, 6),
  flex: 1,
  overflowY: 'auto',
  height: 'calc(100vh - 405px)',
  maxHeight: 'calc(100vh - 405px)',
  whiteSpace: 'pre-wrap',
  ...scrollBarStylesLight(theme),
}))

const commonTextStyles = {
  lineHeight: 1.5,
  letterSpacing: 0.4,
  fontSize: 16,
}

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  hyphens: 'auto',
  ...commonTextStyles,
}))

export const TabItemContainer = styled(Box)(({ theme, active }: ITabItemEl) => ({
  width: '50%',
  paddingBottom: theme.spacing(1),
  borderBottomWidth: 3,
  borderBottomStyle: 'solid',
  borderBottomColor: !active ? theme.colorz.secondary[100] : theme.colorz.secondary[300],
  cursor: 'pointer',
}))

export const TabItemText = styled(Typography)(({ theme, active }: ITabItemText) => ({
  color: !active ? theme.colorz.secondary[200] : theme.colorz.secondary[300],
  textAlign: 'center',
  ...commonTextStyles,
}))

export const DummyDoc = styled('div')(({ theme }) => ({
  height: 160,
  width: 112,
  backgroundColor: '#fff',
  border: '1px solid ' + theme.colorz.secondary[100],
  borderBottomWidth: '2px',
}))

export const URLText = styled('span')(({ theme }) => ({
  color: theme.colorz.primary.light,
  cursor: 'pointer',
  transition: 'all .2s',
  textDecoration: 'underline',
  hyphens: 'none',
  '&:hover': {
    color: theme.colorz.primary.main,
  },
}))
