import { SignUpProfileImageSetupComponentProps } from './ProfileInfo.types'
import React, { useRef, useState, Fragment, Dispatch, SetStateAction } from 'react'
import {
  ProfileImageContainer,
  ActionText,
  SkipText,
  ImageEditorWrapper,
  SliderWrapper,
} from './ProfileInfo.styles'
import ProfileImage from 'components/user/ProfileImage/ProfileImage'
import ImageEditor from 'components/common/ImageEditor/ImageEditor'
import ImageEditorSlider from 'components/common/ImageEditor/ImageEditorSlider'

const ThePicker: React.FC<{
  setImageFile: Dispatch<SetStateAction<null | File>>
}> = ({ setImageFile }) => {
  const inputElRef = useRef<HTMLInputElement>(null)

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return
    setImageFile(e.target.files[0])
  }

  const handleClick = (): void => {
    if (!inputElRef.current) return
    inputElRef.current.click()
  }

  return (
    <Fragment>
      <ActionText variant='h4' onClick={handleClick}>
        Bild auswählen
      </ActionText>
      <input
        accept='image/*'
        id='contained-button-file'
        name='document'
        type='file'
        style={{ display: 'none' }}
        ref={inputElRef}
        onChange={handleSelectFile}
      />
    </Fragment>
  )
}

const SignUpProfileImageSetup: React.FC<SignUpProfileImageSetupComponentProps> = ({
  imageFile,
  firstName,
  lastName,
  setImageFile,
  handleForwardClick,
  isLoading,
  editorRef,
}) => {
  const [zoom, setZoom] = useState<number>(1)

  const handleZoomChange = (e: any, newVal: number | number[]): void => {
    if (typeof newVal === 'number') setZoom(newVal)
  }

  return (
    <ProfileImageContainer
      style={{
        justifyContent: imageFile ? 'flex-start' : 'center',
        marginTop: imageFile ? -16 : 0,
      }}
    >
      {!imageFile ? (
        <Fragment>
          <ProfileImage
            imageUrl=''
            firstName={firstName}
            lastName={lastName}
            size={160}
          />
          <ThePicker setImageFile={setImageFile} />
        </Fragment>
      ) : (
        <ImageEditorWrapper>
          <ImageEditor
            image={imageFile}
            width={170}
            height={170}
            zoom={zoom}
            ref={editorRef}
          />
          <SliderWrapper>
            <ImageEditorSlider
              value={zoom}
              handleChange={handleZoomChange}
              title='Zoom'
              width={190}
              style={{ marginLeft: 8 }}
            />
            <ActionText variant='h4' onClick={() => setImageFile(null)}>
              Anderes Bild auswählen
            </ActionText>
          </SliderWrapper>
        </ImageEditorWrapper>
      )}
      {!isLoading && (
        <SkipText variant='h5' onClick={() => handleForwardClick('skip')}>
          Überspringen
        </SkipText>
      )}
    </ProfileImageContainer>
  )
}

export default SignUpProfileImageSetup
