import { IConfirmationPopupContent } from 'types'
import React from 'react'

import { useHistory } from 'react-router'
import { useReminderDelete, useReminders } from 'queries/reminders'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import { RemindersListComponent } from './RemindersList.component'

const POPUP_CONFIRM_DELETE_REMINDER: IConfirmationPopupContent = {
  heading:
    'Sind Sie sicher, dass Sie diese Erinnerung für immer und unwiderruflich löschen möchten?',
  status: 'warning',
  primaryButtonText: 'Ja, endgültig löschen',
  secondaryButtonText: 'Nein, nicht löschen',
}

export const RemindersList: React.FC = () => {
  const { push, location } = useHistory()
  const { popup, close, setIsLoading } = useConfirmPopup()
  const reminders = useReminders()
  const _delete = useReminderDelete()

  const onEdit = (id: string) => {
    push(`${location.pathname}?mahnung=${id}&action=mahnung`)
  }

  const onDelete = (id: string) => {
    popup({
      ...POPUP_CONFIRM_DELETE_REMINDER,
      onConfirm: () => {
        setIsLoading(true)
        _delete.mutate(id, {
          onSettled: () => {
            close()
          },
        })
      },
      onCancel: close,
    })
  }

  return (
    <RemindersListComponent reminders={reminders} onEdit={onEdit} onDelete={onDelete} />
  )
}
