import { Box, styled, Typography } from '@material-ui/core'

export const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

export const Heading = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary.main,
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: 0.45,
  textAlign: 'center',
  marginBottom: theme.spacing(0.5),
}))
