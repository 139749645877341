import React, { Fragment } from 'react'
import {
  Footer,
  DisabledSharingTextWrapper,
  StrongText,
  LightText,
  CustomButton,
  SwitchButton,
} from './Sharing.styles'
import { ISharingFooterActions, ISharingLoaders } from './SharingModal.component'

interface SharingFooterComponentProps extends ISharingLoaders, ISharingFooterActions {
  sharingEnabled: boolean
}

export const SharingFooter: React.FC<SharingFooterComponentProps> = ({
  sharingEnabled,
  getLoading,
  addSharingLoading,
  handleSwitchChange,
  handleFinishClick,
}) => {
  let content = (
    <Fragment>
      <StrongText>Freigabe ist deaktiviert</StrongText>
      <LightText>Privat, nicht geteilt</LightText>
    </Fragment>
  )

  if (sharingEnabled) {
    content = (
      <CustomButton
        btntype='primary'
        width={128}
        onClick={handleFinishClick}
        disabled={getLoading || addSharingLoading}
      >
        Fertig
      </CustomButton>
    )
  }

  return (
    <Footer on={sharingEnabled ? 1 : 0}>
      <DisabledSharingTextWrapper>{!getLoading && content}</DisabledSharingTextWrapper>
      <SwitchButton
        on={sharingEnabled}
        onChange={handleSwitchChange}
        style={{
          opacity: sharingEnabled && (addSharingLoading || getLoading) ? 0.7 : 1,
          pointerEvents: sharingEnabled && addSharingLoading ? 'none' : 'auto',
        }}
      />
    </Footer>
  )
}
