import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useCreateTemplate } from 'queries/templates'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'

import SidebarContainer from 'components/Sidebar/Sidebar.container'
import FolderTree from './SidebarFolderTree'
import { templatesRoutes } from 'constants/routes'

const TemplatesSidebar: React.FC = () => {
  const { push } = useHistory()
  const create = useCreateTemplate()
  const { check } = useSubscriptionCheck()

  const [searchValue, setSearchValue] = useState('')

  const onTemplateCreate = (): void => {
    if (!check({ isFree: true, isStartup: true })) return
    push(templatesRoutes.create)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSearchValue(e.target.value)

  return (
    <SidebarContainer
      actionButtonText='Neue Vorlage'
      searchValue={searchValue}
      isLoading={create.isLoading}
      baseUrl={templatesRoutes.main}
      handleActionClick={onTemplateCreate}
      handleSearchChange={handleSearchChange}
    >
      <FolderTree />
    </SidebarContainer>
  )
}

export default TemplatesSidebar
