import React, { useState, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import { Stripe } from '@stripe/stripe-js'

import { useUser } from 'queries/user/useUser'
import { useMasterData } from 'queries/app/useMasterdata'

import FinancialsApi from 'api/financials.api'

import { documentsRoutes } from 'constants/routes'

export const useUserSubscription = (stripe?: Stripe | null) => {
  const prices = useMasterData()?.prices
  const user = useUser()

  const [isLoading, setIsLoading] = useState(false)

  const subscriptionDetails = React.useMemo(() => user?.subscription, [user])

  const thePrices = React.useMemo(
    () => ({
      subscription_basic_year: prices?.subscription_basic_year,
      subscription_team_year: prices?.subscription_team_year,
      subscription_basic_month: prices?.subscription_basic_month,
      subscription_team_month: prices?.subscription_team_month,
      subscription_startup_month: prices?.subscription_startup_month,
      subscription_startup_year: prices?.subscription_startup_year,
    }),
    [prices]
  )

  const subscribe = useCallback(
    async (plan: keyof typeof thePrices) => {
      const priceId = thePrices[plan]
      const baseUrl = window.location.protocol + '//' + window.location.host
      const successUrl = baseUrl + documentsRoutes.main + '?subscription=success'

      if (!priceId || !stripe || !user) return

      const cancelUrl = window.location.href
      setIsLoading(true)
      try {
        const payload = {
          priceId,
          metadata: { userId: user.id, slug: uuid() },
          successUrl,
          cancelUrl,
        }
        const { data } = await FinancialsApi.charge(payload, 'subscription')
        const sessionId = data?.data?.sessionId?.sessionId
        if (!sessionId) throw new Error('No sessionId')

        await stripe?.redirectToCheckout({
          sessionId,
        })
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [stripe, thePrices, user]
  )

  const redirectToPortal = useCallback(async () => {
    setIsLoading(true)
    try {
      const url = await FinancialsApi.getStripePortalUrl({
        returnUrl: window.location.href,
      })

      if (url) {
        window.location.href = url
      } else {
        throw Error('Something went wrong with getting stripe portal url')
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const isSubscribed = React.useMemo(
    () => ['processing', 'paid'].includes(subscriptionDetails?.status ?? 'bla'),
    [subscriptionDetails]
  )

  return React.useMemo(
    () => ({
      isLoading,
      subscriptionDetails: user?.subscription,
      isSubscribed,
      subscribe,
      redirectToPortal,
    }),
    [isLoading, user, isSubscribed, subscribe, redirectToPortal]
  )
}
