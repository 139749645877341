import { PAGE_SIZES_IN_PT, pxpi } from 'utilities/hardcoded/documentSettings'
import { classes as wizardClasses } from './Wizard'
import { classes as pageClasses } from './components/Editor/components/Page'
import {
  CASUS_IDS,
  KEYSTRINGS,
  NESTED_STRUCTURE_KEYS,
  extractSubQuestions,
  measureStructureSegments,
  parseQuestionLocations,
} from './parsing'

const consoleStyle = 'color: yellowgreen; font-size: 14px; font-weight: 100; font-family: sans-serif;'

export const generatePageLayoutString = (sections = []) =>
  sections
    .reduce((acc, { id, paper, orientation, margins: inchMargins }) => {
      const { width: shortSide, height: longSide } = PAGE_SIZES_IN_PT[paper]
      const [
        width,
        // height
      ] = orientation === 'portrait' ? [shortSide, longSide] : [longSide, shortSide]
      const margins = Object.entries(inchMargins).reduce((acc, [key, value]) => ({ ...acc, [key]: value * pxpi }), {})
      const identifier = `#section-${id} .${pageClasses.page}`
      const rules = {
        width: `${width}px`,
        // height: `${height}px`, // enable for page breaks
      }
      const marginPositions = ['top', 'left', 'bottom', 'right']
      marginPositions.forEach(position => (rules[`padding-${position}`] = `${margins[position]}px`))
      const sectionStyle = `${identifier} { ${Object.entries(rules)
        .reduce((acc, [attribute, value]) => {
          acc.push(`${attribute}: ${value};`)
          return acc
        }, [])
        .join(' ')} }`
      acc.push(sectionStyle)
      return acc
    }, [])
    .join('\n')

export const generateStyleString = (cssData = {}) =>
  Object.entries(cssData)
    .reduce(
      (acc, [identifier, rules]) => [
        ...acc,
        `${
          identifier.charAt(0) === '.' ? `.${wizardClasses.wrapper} .${wizardClasses.content} ` : ''
        }${identifier} { ${rules} }`,
      ],
      []
    )
    .join('\n')

export const updateStateDataStructure = (setter, structure = {}, segment, field = 'segments') => {
  console.log(segment, field)
  if (!(setter && typeof setter === 'function') || !segment) return
  const { id } = segment
  const res = { ...structure }
  switch (field) {
    case 'documentSettings':
      res.documentSettings = res.documentSettings.map(section => (section.id === id ? segment : section))
      break
    case 'numberingSystem':
      break
    case 'segments':
    default:
      break
  }
  setter(res)
}

const findSegmentById = (segments = [], id = '') => {
  if (id === CASUS_IDS.rootElement) return { length: segments.reduce((acc, cur) => acc + cur.length, 0) }
  let result = null
  segments.every(segment => {
    if (result) return false
    if (segment.id === id) {
      result = segment
      return false
    }
    let inner = null
    NESTED_STRUCTURE_KEYS.every(key => {
      if (inner) return false
      if (segment[key] && segment[key].length) {
        inner = findSegmentById(segment[key], id)
        return !inner
      }
      return true
    })
    result = inner
    return !inner
  })
  return result
}

// const splitMarkers = (markers = {}) =>
//   Object.entries(markers).reduce(
//     (acc, [id, markerArray]) => {
//       markerArray.forEach(marker => {
//         const index = Number(marker.value !== KEYSTRINGS.highlight)
//         if (!acc[index][id]) acc[index][id] = []
//         acc[index][id].push(marker)
//       })
//       return acc
//     },
//     [{}, {}]
//   )

const filterMarkers = (markers = {}, segments) =>
  Object.entries(markers).reduce((acc, [id, markers]) => {
    const segment = findSegmentById(segments, id)
    if (segment) {
      const { toRemove, toReplace, toHighlight } = markers.reduce(
        (accumulated, marker) => {
          if (!marker.length) return accumulated
          if (marker.value === KEYSTRINGS.highlight) accumulated.toHighlight.push(marker)
          else if (marker.value === KEYSTRINGS.remove) accumulated.toRemove.push(marker)
          else accumulated.toReplace.push(marker)
          return accumulated
        },
        { toRemove: [], toReplace: [], toHighlight: [] }
      )
      const fullSegmentRemovalMarker = toRemove.find(marker => marker.start === 0 && marker.length === segment.length)
      if (fullSegmentRemovalMarker) return { ...acc, [id]: [fullSegmentRemovalMarker] }
      else {
        const toRemoveFiltered = toRemove
          .sort((a, b) => b.length - a.length)
          .reduce((accumulated, current) => {
            const inside = accumulated.some(marker => marker.start <= current.start && marker.end >= current.end)
            if (!inside) accumulated.push(current)
            return accumulated
          }, [])
        const toReplaceFiltered = toReplace.filter(
          marker =>
            (marker.value !== KEYSTRINGS.keep) &
            !toRemoveFiltered.some(s => s.start <= marker.start && s.end >= marker.end)
        )
        const toHighlightFiltered = toHighlight.filter(
          marker => !toRemoveFiltered.some(s => s.start <= marker.start && s.end >= marker.end)
        )
        const resultingMarkers = [...toRemoveFiltered, ...toReplaceFiltered, ...toHighlightFiltered]
        const result = { ...acc }
        if (resultingMarkers.length) result[id] = resultingMarkers
        return result
      }
    }
    return acc
  }, {})

const generateMarkers = (questions = [], answers = []) =>
  questions.reduce((acc, question) => {
    const relativeAnswer = answers.find(({ questionId }) => questionId === question.id)
    const locations = parseQuestionLocations(question)
    const result = locations.reduce((accumulated, { segmentId, questionId, locationId, optionId, start, length }) => {
      const questionType = question.type
      let value = KEYSTRINGS.highlight
      if (relativeAnswer) {
        if (optionId) value = relativeAnswer.value.includes(optionId) ? KEYSTRINGS.keep : KEYSTRINGS.remove
        else value = relativeAnswer.value
      }
      const res = { segmentId, questionId, locationId, start, length, value, questionType }
      if (accumulated[segmentId]) accumulated[segmentId].push(res)
      else accumulated[segmentId] = [res]
      return accumulated
    }, acc)
    return result
  }, {})

export const parseStructure = (tempDataStructure = {}, nestedQuestions = [], answers = [], highlight = false) => {
  const structure = JSON.parse(JSON.stringify(tempDataStructure))

  const questions = extractSubQuestions(nestedQuestions)
  const segments = measureStructureSegments(structure)
  const markers = generateMarkers(questions, answers)
  const filteredMarkers = filterMarkers(markers, segments)
  // const [forHighlighting, forReplacement] = splitMarkers(filteredMarkers)
  // const changes = generateChanges(segments, filteredMarkers)
  // const result = generateResult(structure, changes) // generate resulting dataStructure with answered questions
  // const lastSegment = segments.length ? segments[segments.length - 1] : null
  // const length = lastSegment ? lastSegment.start + lastSegment.length : 0
  // const result = { ...structure, segments, id: CASUS_IDS.rootElement, start: 0, length }
  // return [result, forReplacement, forHighlighting]

  // const parsedDataStructure = populateContainer(root, dataStructure, questions, answers)

  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed('%cPARSING DATA STRUCTURE:', consoleStyle)
    console.groupCollapsed('INPUT:')
    console.log('DATA STRUCTURE: ', structure)
    console.groupCollapsed(`NESTED QUESTIONS [${nestedQuestions.length}]:`)
    nestedQuestions.forEach((question, i) => console.log(`QUESTION-${i}: `, question))
    console.groupEnd()
    console.groupCollapsed(`ANSWERS [${answers.length}]:`)
    answers.forEach((answer, i) => console.log(`ANSWER-${i}: `, answer))
    console.groupEnd()
    console.log('HIGHLIGHTING: ', highlight)
    console.groupEnd()
    console.groupCollapsed('PARSING:')
    console.groupCollapsed(`UNPACKED QUESTIONS [${questions.length}]:`)
    questions.forEach((question, i) => console.log(`QUESTION-${i}: `, question))
    console.groupEnd()
    console.groupCollapsed(`MEASURED SEGMENTS [${segments.length}]:`)
    segments.forEach((segment, i) => console.log(`SEGMENT-${i}: `, segment))
    console.groupEnd()
    console.groupCollapsed(`ALL MARKERS [${Object.keys(markers).length}]:`)
    Object.entries(markers).forEach(([id, locations], i) => {
      console.groupCollapsed(`SEGMENT["${id}"] [${locations.length}]:`)
      locations.forEach((location, i) => console.log(`LOCATION-${i}: `, location))
      console.groupEnd()
    })
    console.groupEnd()
    console.groupCollapsed(`FILTERED MARKERS [${Object.keys(filteredMarkers).length}]:`)
    Object.entries(filteredMarkers).forEach(([id, locations], i) => {
      console.groupCollapsed(`SEGMENT["${id}"] [${locations.length}]:`)
      locations.forEach((location, i) => console.log(`LOCATION-${i}: `, location))
      console.groupEnd()
    })
    console.groupEnd()
    console.groupEnd()
    console.groupEnd()
  }
  return structure
}
