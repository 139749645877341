import PagesLayout from 'layout/PagesLayout/PagesLayout'
import React from 'react'

import { useUser } from 'queries/user/useUser'

import SidebarClient from './SidebarClient'
import SidebarLawyer from './SidebarLawyer'
import { PageTitle, Container } from 'pages/Authenticated/Authenticated.page.styles'
import { USER_TYPE_CLIENT_ADMIN, USER_TYPE_CLIENT_EMPLOYEE } from 'constants/user-types'
import { useLocation } from 'react-router'
import { legalAdviceRoutes } from 'constants/routes'

interface Props {
  isLoading?: boolean
}

const Layout: React.FC<Props> = ({ isLoading, children }) => {
  const user = useUser()
  const { pathname } = useLocation()

  const SidebarComponent = [USER_TYPE_CLIENT_ADMIN, USER_TYPE_CLIENT_EMPLOYEE].includes(
    user?.type!
  )
    ? SidebarClient
    : SidebarLawyer

  const title = pathname === legalAdviceRoutes.main ? 'Bevorstehende Termine' : 'Verlauf'
  return (
    <Container>
      <SidebarComponent />
      <PagesLayout
        isLoading={isLoading}
        PageAssetsComponent={<PageTitle>{title}</PageTitle>}
      >
        {children}
      </PagesLayout>
    </Container>
  )
}

export default Layout
