import React from 'react'
import { SidebarContainer, Container, PaddingWrapper, Logo } from './Sidebar.styles'
import { SettingsSidebarProps } from './Sidebar.types'
import SidebarLink from './SidebarLink'
import logoimg from 'assets/logoDark.svg'
import { useHistory } from 'react-router'
import { documentsRoutes } from 'constants/routes'

const SettingsSidebar: React.FC<SettingsSidebarProps> = ({
  children,
  urlLinks,
  baseUrl,
}) => {
  const { push } = useHistory()
  return (
    <SidebarContainer variant='beige'>
      <PaddingWrapper>
        <Logo src={logoimg} onClick={() => push(documentsRoutes.main)}/>
      </PaddingWrapper>
      <Container sidebarvariant='beige'>
        {urlLinks &&
          urlLinks.map(({ url, urlName }) => (
            <SidebarLink
              key={urlName}
              url={baseUrl + url}
              urlName={urlName}
              variant='beige'
            />
          ))}
        {children}
      </Container>
    </SidebarContainer>
  )
}

export default SettingsSidebar
