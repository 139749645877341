import { Box, BoxProps, styled, Theme, Typography } from '@material-ui/core'

interface ContainerEl extends BoxProps {
  ref: React.RefObject<HTMLDivElement>
  theme: Theme
}

export const Container = styled(Box)(({ theme }: ContainerEl) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: '#fff',
  marginTop: theme.spacing(3),
  maxWidth: 760,
  width: '100%',
  borderBottom: `2px solid ${theme.colorz.brand.main}`,
}))

export const CardWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  height: 115,
  display: 'flex',
}))

export const ActionsWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: `1px solid ${theme.colorz.secondary[100]}`,
}))

export const Row = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
}))

export const NoItemsWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  flex: 1,
})

export const iconStyles: React.CSSProperties = {
  position: 'absolute',
  top: 8,
  right: 8,
  cursor: 'pointer',
}
