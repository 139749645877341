import { StatusBarProps } from './StatusBar.types'
import React from 'react'
import ButtonSquare from '../../../components/common/ButtonSquare/ButtonSquare'
import Steps from './Steps/Steps'
import { StatusBarContainer, Heading } from './StatusBar.styles'

const StatusBar: React.FC<StatusBarProps> = ({
  handleClose,
  heading,
  steps,
  currentStep,
}) => {
  return (
    <StatusBarContainer>
      <ButtonSquare onClick={handleClose} btntype='white' />
      <Heading variant='body1'>{heading}</Heading>
      <Steps steps={steps} currentStep={currentStep} />
    </StatusBarContainer>
  )
}

export default StatusBar
