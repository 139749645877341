import { FormStepTwoClientProps } from '../../components/ProfileInfo.types'
import React, { Fragment } from 'react'
import { profileInfoStepTwoTextFields as fields } from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoClient/content'
import { TextInputGroup } from 'components/common'
import { labelStyle, Row, Item } from '../../components/ProfileInfo.styles'

const ProfileInfoClientStepTwo: React.FC<FormStepTwoClientProps> = ({
  handleChange,
  // handleSelectChange,
  values,
}) => {
  const renderTextFields = () =>
    fields.map(field => (
      <Item key={field.name}>
        <TextInputGroup
          value={values[field.name]}
          onChange={handleChange}
          LabelProps={{ style: labelStyle, variant: 'body2' }}
          InputGroupContainerProps={{ style: { marginBottom: 0 } }}
          customvariant='light'
          {...field}
        />
      </Item>
    ))
  return (
    <Fragment>
      <Row>{renderTextFields()}</Row>
    </Fragment>
  )
}

export default ProfileInfoClientStepTwo
