import React from 'react'
import { Container, Step, Text } from './StatusSteps.styles'
import { StatusStepsProps } from './StatusSteps.types'
import { Icon } from 'components/common'
import { status } from 'config/themes/colors'

const StatusSteps = ({ steps, step }: StatusStepsProps) => {
  return (
    <Container>
      {steps.map((stepItem, i) => {
        const isCompleted = stepItem.completed && step > i
        return (
          <Step key={stepItem.name} completed={isCompleted ? 1 : 0}>
            {!isCompleted ? (
              <Text>{stepItem.name}</Text>
            ) : (
              <Icon iconName='check' fill={status.success} />
            )}
          </Step>
        )
      })}
    </Container>
  )
}

export default StatusSteps
