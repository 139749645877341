import React from 'react'
import { DocumentSettingsPageHeader } from 'components/documentSettings/DocumentSettingsPageHeader/DocumentSettingsPageHeader'
// import { SettingsCardItemList } from 'components/documentSettings/SettingsCardItem/SettingsCardItemList'
import { SettingsContent } from './DocumentSettings.styles'
// import no_headers_svg from 'assets/illustrations/templates.svg'

// const selector = (state: State) => {
//   const {
//     docs: {
//       settings: { footers },
//     },
//     meta,
//   } = state
//   return {
//     footers,
//     hasBeenFetched: Boolean(meta?.GET_DOCUMENT_SETTINGS?.hasBeenFetched),
//     isLoading: Boolean(meta?.GET_DOCUMENT_SETTINGS?.isLoading),
//   }
// }

const FooterSettings: React.FC = () => {
  // const handleDelete = (id: string): void => {}
  // const handleEdit = (id: string): void => {}
  // const handleAddHeaderClick = (): void => {}
  // const { footers } = useSelector(selector)
  return (
    <SettingsContent>
      <DocumentSettingsPageHeader
        title='Verfügbare Fusszeilen'
        actionText='Fusszeile hinzufügen'
        // handleActionClick={handleAddHeaderClick}
      />
      {/* <SettingsCardItemList
        documentSettingsItems={footers}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        noItemsActionProps={{
          image: no_headers_svg,
          text: 'Sie haben keine Fusszeile erstellt.',
          actionText: 'Erste Fusszeile erstellen',
          handleActionClick: handleAddHeaderClick,
        }}
      /> */}
    </SettingsContent>
  )
}

export default FooterSettings
