import { IQuestionLocation, TQuestionTypes, IOption } from 'types'
import questionTypes from 'constants/question-types'
import { generateNewOptionObject } from './generateNewOptionObject'

interface ReturnType {
  type: TQuestionTypes
  options?: IOption[]
  locations?: IQuestionLocation[]
  rangeFrom?: number
  rangeTo?: number
  rangeStep?: number
}

export const generateQuestionPropsByType = (type: TQuestionTypes): ReturnType => {
  let obj: ReturnType = {
    type,
  }
  switch (type) {
    case questionTypes.CHECKBOX:
    case questionTypes.RADIO_LINK:
      return {
        ...obj,
        options: [generateNewOptionObject(), generateNewOptionObject()]!,
      }
    case questionTypes.RADIO_REPLACEMENT:
      return {
        ...obj,
        options: [generateNewOptionObject(false), generateNewOptionObject(false)]!,
        locations: [],
      }
    case questionTypes.NUMBER:
      return { ...obj, rangeFrom: 0, rangeTo: 0, rangeStep: 0, locations: [] }
    default:
      return { ...obj, locations: [] }
  }
}
