import { Question } from 'types'
import React, { useState } from 'react'
import {
  ScreenContainer,
  InputGroupContainer,
  Container,
  ButtonsContainer,
} from '../../../../../../../components/features/QuestionnaireScreen/QuestionnaireScreen.styles'
import { CustomButton, ButtonSquare, Icon } from '../../../../../../../components/common'
import QuestionDescriptionDrawer from '../../../../../../../components/features/QuestionnaireScreen/QuestionDescriptionDrawer/QuestionDescriptionDrawer'
import QuestionComponent from '../../../../../../../components/features/QuestionnaireScreen/Question/Question'
import { secondary } from 'config/themes/colors'

interface Props {
  questions: Question[]
  onClose: () => void
}

export const QuestionsPreview: React.FC<Props> = ({ questions, onClose }) => {
  const [showInfo, setShowInfo] = useState(true)
  const [questionIndex, setQuestionIndex] = useState(0)
  const question = questions[questionIndex]
  const { description, answerExample } = question

  const handleForwardClick = () => {
    if (questionIndex + 1 === questions.length) {
      return onClose()
    }
    setQuestionIndex(prev => prev + 1)
  }

  const handleBackClick = () => {
    if (questionIndex === 0) {
      return onClose()
    }
    setQuestionIndex(prev => prev - 1)
  }

  return (
    <ScreenContainer>
      <Container>
        <CustomButton
          onClick={onClose}
          btntype='secondary'
          width={250}
          style={{
            color: 'black',
            transform: 'translateY(5vh)',
            border: '1px solid black',
          }}
        >
          X Vorschau schliessen
        </CustomButton>
        <InputGroupContainer>
          <QuestionComponent
            key={question.id}
            question={question}
            disabled={true}
            questionIndex={questionIndex}
            handleChange={() => {}}
          />
          <ButtonsContainer>
            <ButtonSquare
              btntype='secondary'
              size={48}
              style={{ marginRight: 16 }}
              onClick={handleBackClick}
            >
              <Icon iconName='arrow-left' fill={secondary.main} />
            </ButtonSquare>
            <CustomButton
              onClick={handleForwardClick}
              btntype='primary'
              size='large'
              width={162}
            >
              Weiter{' '}
              <Icon iconName='arrow-right' fill='#fff' style={{ marginLeft: 'auto' }} />
            </CustomButton>
          </ButtonsContainer>
        </InputGroupContainer>
      </Container>
      {showInfo && (
        <QuestionDescriptionDrawer
          questionType={question.type}
          answerExample={answerExample}
          description={description}
        />
      )}
      <ButtonSquare
        onClick={() => setShowInfo(prev => !prev)}
        size={32}
        btntype='secondary-static'
        style={{ position: 'absolute', top: 16, right: 16 }}
      >
        <Icon iconName={showInfo ? 'arrow-right' : 'arrow-left'} fill={secondary.main} />
      </ButtonSquare>
    </ScreenContainer>
  )
}
