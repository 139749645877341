import React from 'react'
import { useHistory } from 'react-router'

import { useMasterData } from 'queries/app/useMasterdata'
import { useUpdateFolder } from 'queries/folders'
import { useCurrentFolder } from 'hooks/useCurrentFolder'

import Folders from 'components/files/Folder/FolderList.component'
import { FolderItem } from 'types'
import { useConfirmPopup } from 'providers/ConfirmPopupProvider'
import {
  POPUP_CONFIRM_DELETE_FILE,
  POPUP_CONFIRM_TRASH_FILE,
} from 'constants/confirmation-popups'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
interface Props {
  where: 'templates' | 'docs'
}

const FolderList: React.FC<Props> = ({ where }) => {
  const { currentFolder, setCurrentFolder } = useCurrentFolder(where)
  const update = useUpdateFolder(where, currentFolder)
  const sharingEnabled = useMasterData()?.sharing.templates.enabled
  const { push, location } = useHistory()
  const { type, children } = currentFolder
  const { popup, close, setIsLoading } = useConfirmPopup()
  const { check } = useSubscriptionCheck()

  const onDeleteFolder = (id: string) => {
    const status = type === 'trashed' ? 'deleted' : 'trashed'
    update.mutate(
      { id, data: { status } },
      { onSuccess: close, onSettled: () => setIsLoading(false) }
    )
  }

  const onMoveFolder = (folder: FolderItem) => {
    if (!check({ isFree: true })) return
    push(`${location.pathname}?folder=${folder.id}&action=move`)
  }

  const onShareFolder = (folderId: string) => {
    if (!check({ isFree: true, isBasic: true, isStartup: true })) return
    push(`${location.pathname}?folder=${folderId}&action=share`)
  }

  const onRename = (id: string, name: string) => {
    if (!check()) return
    update.mutate({ id, data: { name } })
  }

  const onTrash = (id: string) => {
    const POPUP =
      type === 'trashed' ? POPUP_CONFIRM_DELETE_FILE : POPUP_CONFIRM_TRASH_FILE
    popup({ ...POPUP, onConfirm: () => onDeleteFolder(id), onCancel: close })
  }

  const onRestore = (id: string) => {
    if (!check()) return
    update.mutate({ id, data: { status: 'new' } })
  }

  const allow = !['shared', 'trashed'].includes(type)

  return (
    <Folders
      folders={children}
      handleClickFolder={folder =>
        type === 'trashed' ? () => {} : setCurrentFolder(folder)
      }
      handleMoveFolder={allow ? onMoveFolder : undefined}
      handleRenameFolder={allow ? onRename : undefined}
      handleDeleteFolder={allow ? onTrash : undefined}
      handleShareFolder={sharingEnabled && allow ? onShareFolder : undefined}
      handleRestoreFolder={type === 'trashed' ? onRestore : undefined}
    />
  )
}

export default FolderList
