import React from 'react'
import Validator from 'validator'
import { CustomButton, TextWithUrl, TextInputGroup } from 'components/common'
import { Heading } from '../common-styles'
import { Form } from './ForgotPasswordForm.styles'

interface Props {
  isLoading: boolean
  value: string
  handleChange: React.ChangeEventHandler
  handleSubmit: React.FormEventHandler
}

const ForgotPasswordForm: React.FC<Props> = ({
  isLoading,
  value,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Heading variant='h3'>Passwort zurücksetzen</Heading>
      <TextInputGroup
        type='email'
        label='Ihre E-Mail-Adresse'
        name='email'
        value={value}
        placeholder='z.B. marina@meyer.ch'
        onChange={handleChange}
        required
      />

      <CustomButton
        type='submit'
        isLoading={isLoading}
        btntype='primary'
        size='large'
        disabled={isLoading || !Validator.isEmail(value)}
        width='100%'
      >
        {!isLoading ? 'Anweisungen senden' : 'Einen Moment bitte...'}
      </CustomButton>
      <TextWithUrl text='Zurück zum' urlText='Login' url='/auth/login' />
    </Form>
  )
}

export default ForgotPasswordForm
