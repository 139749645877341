import { styled, Typography, Box } from '@material-ui/core'

export const TextBold = styled(Typography)({
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.4,
})

export const PageAssetsWrapper = styled(Box)({
  display: 'flex',
  flex: 1,
})
