import { TUiElement } from 'constants/ui-elements'
import * as A from './ui.types'
import * as uiTypes from './ui.constants'

export const openUiElement = (elementName: TUiElement): A.OpenUiElementAction => ({
  type: uiTypes.OPEN_UI_ELEMENT,
  payload: elementName,
})

export const closeUiElement = (elementName: TUiElement): A.CloseUiElementAction => ({
  type: uiTypes.CLOSE_UI_ELEMENT,
  payload: elementName,
})

export const closeAllUiElements = (): A.CloseAllUiElementsAction => ({
  type: uiTypes.CLOSE_ALL_UI_ELEMENTS,
  payload: null,
})
