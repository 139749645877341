import { IStripeCardData } from 'types'
import { Stripe } from '@stripe/stripe-js'
import React, { Fragment } from 'react'

import { useBookLawyer } from '../../provider/BookLawyerProvider'
import { useConnectedAccount } from '../../provider/useConnectAccount'
import { useChargeBookLawyer } from '../../provider/useChargeBookLawyer'

import { Loader } from 'components/common'

import CalendlyComponent from './CalendlyComponent'
import SelectLawField from '../SelectLawField/SelectLawField'
import { StripeCard } from 'components/stripe'
import { brand } from 'config/themes/colors'
import { useUser } from 'queries/user/useUser'

const loaderStyles: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: brand.light,
}

const BookAndPay: React.FC = () => {
  const { set, lawField, step, connectedAccountId, clientSecret } = useBookLawyer()
  const connectAccont = useConnectedAccount()
  const charge = useChargeBookLawyer()
  const user = useUser()

  const handleStripeSubmit = (stripe: Stripe, cardData: IStripeCardData) => {
    if (clientSecret)
      charge.charge({ stripe, cardData, clientSecret }, lawField?.name ?? '', {
        onSuccess: () => set({ step: 3 }),
      })
  }

  let component: React.ReactNode = null
  if (connectAccont.isLoading) component = <Loader style={loaderStyles} />

  if (step === 2) {
    if (!connectAccont.isLoading && connectedAccountId) {
      component = (
        <div style={{ paddingTop: 40 }}>
          <StripeCard
            handleStripeSubmit={handleStripeSubmit}
            amount={40}
            heading='Bitte bezahlen Sie Ihren Beratungstermin'
            descriptionText='Ihre Buchung wird erst nach der Bezahlung bestätigt.'
            options={{ stripeAccount: connectedAccountId }}
            charging={charge.isLoading}
          />
        </div>
      )
    }
  }

  return (
    <Fragment>
      {component}
      {!lawField && <SelectLawField />}
      {step < 3 && lawField && (
        <CalendlyComponent
          scheduleEvent={connectAccont.fetch}
          user={user!}
          lawField={lawField}
        />
      )}
    </Fragment>
  )
}

export default BookAndPay
