import React from 'react'
import { useQueryClient } from 'react-query'
import { Redirect } from 'react-router-dom'
import { useUser } from 'queries/user/useUser'
import { queryKeys } from 'queries'
import { User } from 'types'

const EmailVerify: React.FC = () => {
  const queryClient = useQueryClient()
  const user = useUser()
  if (!user) return <Redirect to='/auth/login' />

  queryClient.setQueryData<User | null | undefined>(queryKeys.USER, data => {
    return data ? { ...data, emailVerified: true } : data
  })

  return <Redirect to='/aktenschrank' />
}

export default EmailVerify
