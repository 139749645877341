import React from 'react'

const classes = {
  wrapper: 'Wizard-Questionnaire-wrapper',
}

export const Questionnaire = React.memo(props => {
  const { shift } = props
  return <section data-shift={shift} className={classes.wrapper}></section>
})

Questionnaire.displayName = 'Wizard-Questionnaire'

export default Questionnaire
