import React from 'react'
import { StandardModal, CustomButton } from 'components/common'
import { Container, Image, Heading, Text } from './ModalTemplate.styles'

interface Props {
  open?: boolean
  title: string
  text: string
  actionBtnText?: string
  btnWidth?: number
  image: string
  onAction?: () => void
  onClose: () => void
}

const ModalTemplate: React.FC<Props> = ({
  title,
  text,
  actionBtnText,
  image,
  open = true,
  btnWidth,
  onAction,
  onClose,
}) => {
  return (
    <StandardModal open={open} handleClose={onClose} RootModalProps={{ withBg: true }}>
      <Container>
        <Image src={image} />
        <Heading variant='h3'>{title}</Heading>
        <Text variant='body1'>{text}</Text>
        {onAction && actionBtnText && (
          <CustomButton
            btntype='primary'
            size='large'
            width={btnWidth ?? 208}
            onClick={onAction}
          >
            {actionBtnText}
          </CustomButton>
        )}
      </Container>
    </StandardModal>
  )
}

export default ModalTemplate
