import { Box, styled, Typography } from '@material-ui/core'
import { labelStyles } from '../../views/AccountSettings.styles'

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(3),
}))

export const Label = styled(Typography)(({ theme }) => ({
  ...labelStyles,
  marginBottom: theme.spacing(1.5),
}))

export const ValueWrapper = styled(Box)({
  height: 34,
  display: 'flex',
  alignItems: 'center',
  width: 400,
})

export const Value = styled(Typography)({
  fontSize: 16,
  letterSpacing: 0.4,
})

export * from '../../views/AccountSettings.styles'
