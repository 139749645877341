import { GroupItemEl } from './CasusSidebar.types'
import { Box, styled, Theme, Typography } from '@material-ui/core'
import { scrollBarStylesLight } from 'constants/common-styles'

interface WithProps {
  theme: Theme
  iscasus: 1 | 0
}
export const Container = styled(Box)(({ theme, iscasus }: WithProps) => ({
  width: 198,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: 'calc(100vh - 112px)',
  height: '100%',
  overflowY: 'auto',
  backgroundColor: iscasus ? theme.colorz.brand.main : 'transparent',
  paddingBottom: theme.spacing(2),
}))

export const GroupItem = styled(Typography)(({ theme, active }: GroupItemEl) => ({
  fontSize: 14,
  lineHeight: 1.2,
  letterSpacing: 0.2,
  fontWeight: 500,
  flexShrink: 0,
  color: !active ? theme.colorz.secondary[300] : theme.colorz.secondary.main,
  textAlign: 'center',
}))

export const GroupsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxHeight: 'calc(100vh - 160px)',
  height: '100%',
  overflowY: 'auto',
  padding: theme.spacing(3, 3, 1, 3),
  ...scrollBarStylesLight(theme),
}))
