import { Box, DialogActions, styled } from '@material-ui/core'

export const ActionsContainer = styled(DialogActions)(({ theme }) => ({
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  background: theme.colorz.secondary.main,
  height: 56,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
}))

export const ButtonsContainer = styled(Box)({
  width: 847,
  display: 'flex',
  '& button': {
    marginRight: 16,
  },
})
