import {
  REPLACEMENT_TYPES_ANSWER,
  MEASURE_RESET_KEYS,
  NESTED_STRUCTURE_KEYS as nestedStructureKeySet,
  KEYSTRINGS,
  mergeChunks,
} from './parsing'

const nestedStructureKeys = Array.from(nestedStructureKeySet)

const applyValues = (structure = {}) => {
  const localBlacklist = ['textChunks']
  const localWhitelist = ['segments']
  const localKeys = nestedStructureKeys.filter(key => localBlacklist.indexOf(key) === -1)
  localKeys.unshift(...localWhitelist)
  localKeys.reduce((acc, key) => {
    if (acc[key] && acc[key].length) {
      acc[key] = acc[key]
        .map(segment => {
          const keep = applyValues(segment)
          return keep ? segment : null
        })
        .filter(e => e)
    }
    return acc
  }, structure)
  if (structure.textChunks && structure.textChunks.length) {
    structure.textChunks = mergeChunks(
      structure.textChunks
        .map(chunk => {
          const res = Object.entries(chunk)
            .filter(([key]) => key !== 'text' && key !== 'value')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
          return { ...res, text: chunk.value ?? chunk.text }
        })
        .filter(e => e.text !== KEYSTRINGS.remove)
    )
    if (!structure.textChunks.length) return false
  }
  return structure
}

const replaceSegment = (structure = {}, changes = []) => {
  if (changes.length <= 0) return null
  const changeIndex = changes.findIndex(({ id }) => id === structure.id)
  if (changeIndex !== -1) {
    const change = changes[changeIndex]
    changes.splice(changeIndex, 1)
    return change
  }
  const localBlacklist = ['textChunks']
  const localWhitelist = ['segments']
  const localKeys = nestedStructureKeys.filter(key => localBlacklist.indexOf(key) === -1)
  localKeys.unshift(...localWhitelist)
  const result = { ...structure }
  localKeys.every(key => {
    if (result[key] && result[key].length) {
      result[key] = result[key].map(segment => {
        const res = replaceSegment(segment, changes)
        if (res) {
          return res
        }
        return segment
      })
    }
    return !changes.length <= 0
  })
  return result
}

const generateResult = (structure = {}, changes = []) => {
  const result = replaceSegment(structure, changes)
  if (result) {
    applyValues(result)
    return result
  }
  return structure
}

const generateChangedSegment = (segment = {}, change = {}, propagatedValue = '') => {
  const { start = 0, length = 0 } = segment
  const end = start + length
  const { start: changeStart = 0, length: changeLength = 0 } = change
  const changeEnd = changeStart + changeLength
  if (end <= changeStart) return null
  if (start >= changeEnd) return null
  if (segment.text && segment.text.length) {
    const res = []
    const calculatedStart = Math.max(changeStart - start, 0)
    const calculatedEnd = Math.max(Math.min(changeEnd - start, segment.text.length), 0)
    const calculatedLength = calculatedEnd - calculatedStart
    const leading = {
      ...segment,
      length: calculatedStart,
      text: segment.text.slice(0, calculatedStart),
    }
    const base = {
      ...segment,
      start: Math.max(changeStart, start),
      length: calculatedLength,
      text: segment.text.slice(calculatedStart, calculatedEnd),
      value: propagatedValue || change.value,
    }
    const trailing = {
      ...segment,
      start: Math.min(changeEnd, end),
      length: segment.text.length - calculatedEnd,
      text: segment.text.slice(calculatedEnd),
    }
    if (leading.length && leading.length > 0) res.push(leading)
    if (base.length && base.length > 0) res.push(base)
    if (trailing.length && trailing.length > 0) res.push(trailing)
    return res
  }
  const result = { ...segment }
  let done = false
  let changed = false
  nestedStructureKeys.every(key => {
    if (done) return false
    if (result[key] && result[key].length) {
      result[key] = result[key].reduce((array, object) => {
        if (!done && object.start > changeEnd) done = true
        if (done) {
          array.push(object)
          return array
        }
        const res = generateChangedSegment(object, change, changed ? KEYSTRINGS.remove : '')
        changed = changed || !!res
        if (res) array.push(...res)
        else array.push(object)
        return array
      }, [])
    }
    return true
  })
  return [result]
}

const findSegmentById = (segments = [], id = '') => {
  let result = null
  segments.every(segment => {
    if (result) return false
    if (segment.id === id) {
      result = segment
      return false
    }
    let inner = null
    nestedStructureKeys.every(key => {
      if (inner) return false
      if (segment[key] && segment[key].length) {
        inner = findSegmentById(segment[key], id)
        return !inner
      }
      return true
    })
    result = inner
    return !inner
  })
  return result
}

const generateChanges = (markers = {}, segments) =>
  Object.entries(markers).reduce((acc, [id, changes]) => {
    const segment = findSegmentById(segments, id)
    if (segment)
      acc.push(changes.reduce((accumulated, change) => generateChangedSegment(accumulated, change)[0], segment))
    return acc
  }, [])

const filterMarkers = (markers = {}, segments) =>
  Object.entries(markers).reduce((acc, [id, markers]) => {
    const segment = findSegmentById(segments, id)
    if (segment) {
      const { toRemove, toReplace } = markers.reduce(
        (accumulated, marker) => {
          if (!marker.length) return accumulated
          if (marker.value === KEYSTRINGS.remove) accumulated.toRemove.push(marker)
          else accumulated.toReplace.push(marker)
          return accumulated
        },
        { toRemove: [], toReplace: [] }
      )
      const fullSegmentRemovalMarker = toRemove.find(marker => marker.start === 0 && marker.length === segment.length)
      if (fullSegmentRemovalMarker) return { ...acc, [id]: [fullSegmentRemovalMarker] }
      else {
        const toRemoveFiltered = toRemove
          .sort((a, b) => b.length - a.length)
          .reduce((accumulated, current) => {
            const inside = accumulated.some(marker => marker.start <= current.start && marker.end >= current.end)
            if (!inside) accumulated.push(current)
            return accumulated
          }, [])
        const toReplaceFiltered = toReplace.filter(
          marker =>
            (marker.value !== KEYSTRINGS.keep) &
            !toRemoveFiltered.some(s => s.start <= marker.start && s.end >= marker.end)
        )
        return { ...acc, [id]: [...toRemoveFiltered, ...toReplaceFiltered] }
      }
    }
    return acc
  }, {})

const generateMarkers = (questions, answers) =>
  answers.reduce((acc, cur) => {
    const question = questions.find(q => q.id === cur.questionId)
    const locations = (
      (Object.values(REPLACEMENT_TYPES_ANSWER).indexOf(cur.type) !== -1
        ? question?.locations
        : question?.options?.reduce(
            (locations, option) => [
              ...locations,
              ...option.locations.map(location => ({
                ...location,
                optionId: option.id,
              })),
            ],
            []
          )) || []
    ).map(({ id, start, length, optionId }) => ({
      id,
      start,
      length,
      value: optionId ? (cur.value.indexOf(optionId) === -1 ? KEYSTRINGS.remove : KEYSTRINGS.keep) : cur.value,
    }))
    const result = locations.reduce((accumulated, { id, start, length, value }) => {
      if (accumulated[id]) accumulated[id].push({ start, length, value })
      else accumulated[id] = [{ start, length, value }]
      return accumulated
    }, acc)
    return result
  }, {})

const measure = (structure = {}, start = 0) => {
  if (structure.text && structure.text.length) {
    return { ...structure, start, length: structure.text.length }
  }
  const [result, end] = nestedStructureKeys.reduce(
    (acc, key) => {
      if (Object.keys(acc[0]).indexOf(key) !== -1) {
        const [measuredArray, arrayEnd] = acc[0][key].reduce(
          (accumulated, object) => {
            const nestedObject = measure(object, MEASURE_RESET_KEYS.indexOf(key) === -1 ? accumulated[1] : 0)
            accumulated[0].push(nestedObject)
            accumulated[1] += nestedObject.length
            return accumulated
          },
          [[], acc[1]]
        )
        acc[0][key] = measuredArray
        acc[1] = arrayEnd
      }
      return acc
    },
    [structure, start]
  )
  return { ...result, start, length: end - start }
}

const measureStructureSegments = (structure = {}) => structure?.segments?.map(segment => measure(segment)) || []

const extractSubQuestions = (questionArray = []) =>
  questionArray.reduce((acc, cur) => {
    if (cur.options?.length)
      cur.options.forEach(option => {
        return acc.push(...extractSubQuestions(option?.subquestions))
      })
    acc.push(cur)
    return acc
  }, [])

export const generateDocumentDataStructure = (structure, nestedQuestions, answers) => {
  const questions = extractSubQuestions(nestedQuestions)
  const segments = measureStructureSegments(structure)
  const markers = generateMarkers(questions, answers)
  const filteredMarkers = filterMarkers(markers, segments)
  const changes = generateChanges(filteredMarkers, segments)
  const result = generateResult(structure, changes)
  return result
}
