import { ITeamMember } from 'types'
import React, { Fragment } from 'react'
import TeamMemberComponent from './TeamMember/TeamMember'
import NoItemsAction from 'components/common/NoItemsAction/NoItemsAction'
import img from 'assets/illustrations/dokumente-vorlagen.svg'

interface Props {
  teamMembers: ITeamMember[]
  onDelete: (teamMemberId: string) => void
  onAddTeamMember: () => void
}

const noTeamMembersProps = {
  text: 'Sie haben noch keine Teammitglieder',
  actionText: 'Mitglied hinzufügen',
  image: img,
}

const TemMemberList: React.FC<Props> = ({ teamMembers, onAddTeamMember, onDelete }) => {
  if (!teamMembers.length)
    return <NoItemsAction {...noTeamMembersProps} handleActionClick={onAddTeamMember} />
  return (
    <Fragment>
      {teamMembers.map(teamMember => (
        <TeamMemberComponent key={teamMember.id} {...teamMember} onDelete={onDelete} />
      ))}
    </Fragment>
  )
}

export default TemMemberList
