import React from 'react'
import ProfileInfoClientContainer from './views/ProfileInfoClient/ProfileInfoClient.container'
import ProfileInfoLawyerContainer from './views/ProfileInfoLawyer/ProfileInfoLawyer.container'
import * as T from 'constants/user-types'
import { useUser } from 'queries/user/useUser'

const ProfileInfoContainer: React.FC = () => {
  const user = useUser()!
  switch (user?.type) {
    case T.USER_TYPE_CLIENT_ADMIN:
    case T.USER_TYPE_CLIENT_EMPLOYEE:
      return <ProfileInfoClientContainer />
    case T.USER_TYPE_LAWYER_ADMIN:
    case T.USER_TYPE_LAWYER_EMPLOYEE:
      return <ProfileInfoLawyerContainer />
    default:
      return null
  }
}

export default ProfileInfoContainer
