import { IOption, IQuestionLocation, Question, QuestionWithOptions } from 'types'
import { deepDeleteQuestion } from 'utils'

const addOrEditArrPayloadById = (arr: any[], payload: any): any[] => {
  let exists = false
  const result = arr.map(q => {
    if (q.id === payload.id) {
      exists = true
      return payload
    }
    return q
  })
  return exists ? result : [...arr, payload]
}

type QuestionState = Question | null

export const insertTextToOption = (
  arr: IOption[],
  payload: { id: string; text: string }
): IOption[] =>
  arr.map(opt => {
    if (payload.id === opt.id) {
      return { ...opt, text: payload.text }
    }
    return opt
  })

export const addTextOption = (
  question: QuestionState,
  payload: { id: string; text: string }
): QuestionState => {
  if (!question) return null
  if (!question.options) return question
  return { ...question, options: insertTextToOption(question.options, payload) }
}

export const insertLocationToOption = (
  arr: IOption[],
  payload: {
    id: string
    location: IQuestionLocation
  }
): IOption[] => {
  return arr.map(opt => {
    if (payload.id === opt.id) {
      if (!opt.locations) return opt
      return { ...opt, locations: [...opt.locations, payload.location] }
    }
    return opt
  })
}

export const addOptionToQuestion = (
  question: QuestionState,
  option: IOption
): QuestionState => {
  if (!question) return null
  if (!question.options) return question
  return { ...question, options: [...question.options, option] }
}

export const addLocationToQuestion = (
  question: QuestionState,
  location: IQuestionLocation
): QuestionState => {
  if (!question) return null
  if (!question.locations) return question
  return { ...question, locations: [...question.locations, location] }
}

export const editOption = (
  question: QuestionState,
  editedOption: IOption
): QuestionState => {
  if (!question) return null
  if (!question.options) return question
  return { ...question, options: addOrEditArrPayloadById(question.options, editedOption) }
}

export const setLabelAndDescription = (
  question: QuestionState,
  label: string,
  description: string,
  answerExample: string,
  group: string
): QuestionState => {
  if (!question) return null
  const tags: string[] = [...question.tags]
  tags[0] = label
  return { ...question, tags, description, answerExample, group }
}

export const deleteSubquestion = (
  question: QuestionState,
  subquestionToDelete: Question
): QuestionState => {
  if (!question) return null
  if (!question.options) return question
  return deepDeleteQuestion([question], subquestionToDelete)[0]
}

export const addTextToOption = (
  arr: IOption[],
  payload: { id: string; text: string }
): IOption[] =>
  arr.map(opt => {
    if (payload.id === opt.id) {
      return { ...opt, text: payload.text }
    }
    return opt
  })

export const addTextToQuestionOption = (
  question: QuestionWithOptions,
  payload: { id: string; text: string }
): QuestionWithOptions => {
  return { ...question, options: addTextToOption(question.options, payload) }
}

export const addLocationToOption = (
  arr: IOption[],
  payload: {
    id: string
    location: IQuestionLocation
  }
): IOption[] => {
  return arr.map(opt => {
    if (payload.id === opt.id) {
      if (!opt.locations) return opt
      return { ...opt, locations: [...opt.locations, payload.location] }
    }
    return opt
  })
}

export const deleteQuestionOption = (
  question: QuestionState,
  optionId: string
): QuestionState => {
  if (!question) return null
  if (!question.options) return question
  return { ...question, options: question.options.filter(o => o.id !== optionId) }
}

export const addLocationToQuestionOption = (
  question: QuestionState,
  payload: { id: string; location: IQuestionLocation }
): QuestionState => {
  if (!question) return null
  if (!question.options) return question
  return { ...question, options: addLocationToOption(question.options, payload) }
}
