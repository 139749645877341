import { IConfirmationPopupContent } from 'types'

export const POPUP_ON_TEMPLATE_EDIT_FINISH_WQ: IConfirmationPopupContent = {
  heading:
    'Wenn Sie weiterfahren, wird die aktuelle Frage nicht gespeichert. Alle anderen Änderungen werden gespeichert.',
  status: 'warning',
  primaryButtonText: 'Okay',
  secondaryButtonText: 'Zurück',
}

export const POPUP_ON_TEMPLATE_EDIT_CLOSE: IConfirmationPopupContent = {
  heading: 'Möchten Sie den Vorgang beenden und Ihre Änderungen speichern?',
  status: 'warning',
  primaryButtonText: 'Ja, beenden und speichern',
  secondaryButtonText: 'Nein, nicht speichern',
}
