import { DownloadFormats } from 'types'
import React, { Fragment } from 'react'
import { Box, Popover, styled, BoxProps, Theme } from '@material-ui/core'
import { CustomButton } from 'components/common'

interface IMenuItem extends BoxProps {
  disabled: boolean
  theme: Theme
}

const MenuItem = styled(Box)(({ theme, disabled }: IMenuItem) => ({
  padding: theme.spacing(2, 2),
  fontSize: 13,
  fontWeight: 700,
  color: '#fff',
  textTransform: 'uppercase',
  cursor: 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',
  transition: 'all .2s',
  border: `1px solid transparent`,
  '&:hover': {
    backgroundColor: theme.colorz.brand.dark,
    color: theme.colorz.secondary.main,
  },
}))

const DownloadMenuContainer = styled(Box)(({ theme }) => ({
  width: 160,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.colorz.secondary.main,
}))

interface Props {
  downloading: boolean
  downloadFormats: DownloadFormats[]
  disabled: boolean
  onDownload: (format: DownloadFormats) => void
}

const DownloadButtonComponent: React.FC<Props> = ({
  downloading,
  downloadFormats,
  disabled,
  onDownload,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

  const handleClick = (e: React.SyntheticEvent): void => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const download = (format: DownloadFormats): void => {
    onDownload(format)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Fragment>
      <CustomButton
        btntype='primary-outlined'
        autoFocus
        onClick={handleClick}
        width={160}
        disabled={disabled || downloading}
      >
        {!downloading ? 'Herunterladen' : 'Downloading...'}
      </CustomButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: 0,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <DownloadMenuContainer boxShadow={2}>
          {downloadFormats.map(format => (
            <MenuItem
              key={format}
              id={format}
              onClick={() => download(format)}
              disabled={downloading}
            >
              {format}
            </MenuItem>
          ))}
        </DownloadMenuContainer>
      </Popover>
    </Fragment>
  )
}

export default DownloadButtonComponent
