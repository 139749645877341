import { FormStepOneLawyerProps } from '../../components/ProfileInfo.types'
import React, { Fragment } from 'react'
import { stepOne as fields } from 'components/user/AfterSignUpProfileSetup/views/ProfileInfoLawyer/content'
import { TextInputGroup } from 'components/common'
import { Row, Item, Label, Text } from '../../components/ProfileInfo.styles'
import { USER_TYPE_LAWYER_ADMIN } from 'constants/user-types'

const ProfileInfoLawyerStepOne: React.FC<FormStepOneLawyerProps> = ({
  handleChange,
  values,
  userType,
}) => {
  const renderTextFields = () =>
    fields.map((field, i) => (
      <Item key={field.name + i}>
        <Label variant='body2'>{field.label}</Label>
        {userType === USER_TYPE_LAWYER_ADMIN ? (
          <TextInputGroup
            value={values[field.name]}
            onChange={handleChange}
            InputGroupContainerProps={{ style: { marginBottom: 0 } }}
            customvariant='light'
            {...field}
            label={undefined}
          />
        ) : (
          <Text>{values[field.name]}</Text>
        )}
      </Item>
    ))
  return (
    <Fragment>
      <Row>{renderTextFields()}</Row>
    </Fragment>
  )
}

export default ProfileInfoLawyerStepOne
