import React from 'react'
import OptionsList from '../components/OptionList/OptionList'
import { Container, Heading } from './Subquestions.styles'
import { forms as content } from 'pages/Authenticated/Template/constants/content'
import { OptionType, Question } from 'types'

interface Props {
  options: OptionType[]
  addSubqDisabled: boolean
  handleAddSubquestion: (o: OptionType) => void
  handleDeleteQuestion: (q: Question) => void
  handleEditQuestion: (q: Question) => void
  handlePreviewQuestion: (q: Question) => void
}

const SubQuestionsForm: React.FC<Props> = ({ ...otherProps }) => {
  return (
    <Container>
      <Heading variant='h5'>{content.subquestionsHeading}</Heading>
      <OptionsList {...otherProps} actions={true} subQuestions />
    </Container>
  )
}

export default SubQuestionsForm
