import { IReminderAlert } from 'types'

export const reminderAlertSelectorTranslator = (alert: IReminderAlert): string => {
  const { at, selector } = alert
  switch (selector) {
    case 'day':
      return at === 1 ? 'Tag' : 'Tage'
    case 'week':
      return at === 1 ? 'Woche' : 'Wochen'
    case 'month':
      return at === 1 ? 'Monat' : 'Monate'
    case 'year':
      return at === 1 ? 'Jahr' : 'Jahre'
    default:
      return ''
  }
}
