import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'queries/queryKeys'

import UserApi from 'api/user.api'

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(queryKeys.USER, UserApi.update, {
    onSuccess: data => {
      queryClient.setQueryData(queryKeys.USER, data)
    },
  })
  return mutation
}
