import { styled, Typography } from '@material-ui/core'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  flexGrow: 1,
  width: '90vw',
  maxWidth: 864,
  padding: theme.spacing(3, 3, 10, 3),
}))

export const Image = styled('img')({
  marginBottom: 24,
})

export const Heading = styled(Typography)({
  marginBottom: 12,
  textAlign: 'center',
})

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.colorz.secondary[300],
  width: 604,
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  lineHeight: 1.6,
}))
