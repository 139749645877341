import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  RadioProps,
} from '@material-ui/core'
import { primary, secondary } from 'config/themes/colors'

export const RadioButton = withStyles({
  root: {
    color: secondary.main,
    '&$checked': {
      color: primary.main,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color='default' size='medium' {...props} />)

export { RadioGroup, FormControlLabel, FormControl }
