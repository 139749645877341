import React from 'react'
import Layout from '../../../components/subscriptions/Layout'
import TopContent from './components/TopContent/TopContent'
import SelectPlan from './components/SelectPlan/SelectPlan'
import withStripe from 'hoc/withStripe'

const UpgradeSubscriptionPlan: React.FC = () => {
  return (
    <Layout>
      <TopContent />
      <SelectPlan />
    </Layout>
  )
}

export default withStripe(UpgradeSubscriptionPlan)
