import { ImageEditorSliderComponentProps } from './ImageEditor.types'
import React from 'react'
import { SliderWrapper, Slider, Text } from './ImageEditor.styles'

const ImageEditorSlider: React.FC<ImageEditorSliderComponentProps> = ({
  value,
  title,
  width,
  min = 1,
  max = 6,
  step = 0.1,
  orientation = 'vertical',
  style,
  handleChange,
}) => {
  return (
    <SliderWrapper width={width ? width : 244} orientation={orientation} style={style}>
      {title && (
        <Text style={{ marginRight: orientation === 'vertical' ? 0 : 16 }}>{title}</Text>
      )}
      <Slider
        color='secondary'
        value={value}
        min={min}
        step={step}
        max={max}
        onChange={handleChange}
        aria-labelledby='non-linear-slider'
      />
    </SliderWrapper>
  )
}

export default ImageEditorSlider
