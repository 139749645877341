import { PlansSettingsComponentProps } from './PlansSettings.types'
import React from 'react'
import { Container, ItemTitle, Wrapper, Text } from './PlansSettings.styles'
import ProfileSettingsSelectedPlan from 'pages/Authenticated/AccountSettings/views/PlansSettings/ProfileSettingsSelectedPlan/ProfileSettingsSelectedPlan'

const PlansSettings: React.FC<PlansSettingsComponentProps> = () => {
  return (
    <Container>
      <ItemTitle>Ihr Abo</ItemTitle>
      <Wrapper>
        <Text variant='body1' style={{ marginBottom: 8 }}>
          Aktuelles Abo
        </Text>
        <ProfileSettingsSelectedPlan />
      </Wrapper>
    </Container>
  )
}

export default PlansSettings
