import React from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import EventsList from './EventsListLawyer'

const ConsultingPageLawyer: React.FC = () => {
  const location = useLocation()

  return (
    <Switch location={location}>
      <Route exact path='/rechtsberatung' component={EventsList} />
      <Redirect to='/rechtsberatung' />
    </Switch>
  )
}

export default ConsultingPageLawyer
