import { SettingsFooterCardItemActionsComponentProps } from './SettingsCardItem.types'
import React from 'react'
import { TextInputGroup, CustomButton } from 'components/common'
import { ActionsWrapper, Row, Text } from './SettingsCardItem.styles'

export const CardFooterActions: React.FC<SettingsFooterCardItemActionsComponentProps> = ({
  value,
  item,
  activeItem,
  handleChange,
  handleActivityBtnClick,
}) => {
  const isActive = activeItem?.id === item.id
  const defaultValue = item.startAt
  return (
    <ActionsWrapper>
      <Row>
        <Text variant='body2'>Nummerierung ab Seite</Text>
        <TextInputGroup
          type='number'
          value={isActive ? value : defaultValue}
          disabled={!isActive}
          InputGroupContainerProps={{
            style: { width: 50, marginLeft: 16, marginBottom: 0 },
          }}
          style={{ textAlign: 'center', height: 30, padding: 2 }}
          onChange={handleChange}
        />
      </Row>
      <CustomButton
        btntype={isActive ? 'success' : 'secondary'}
        width={110}
        size='small'
        onClick={() => handleActivityBtnClick(isActive ? null : item?.id!)}
      >
        {isActive ? 'Aktiv' : 'Aktivieren'}
      </CustomButton>
    </ActionsWrapper>
  )
}
